/* eslint-disable max-len */
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { AlbumserviceService } from 'src/app/services/albumservice.service';
import { SupportService } from '../services/support.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { ApiService } from '../services';
import { DatePipe } from '@angular/common';

import { GoogleMapsAPIWrapper } from '@agm/core';
import { AgmInfoWindow } from '@agm/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    providers: [DatePipe],
    styles: [`
    .info-window-container {
        max-width: 320px;
        max-height: 90px;
      }
    `]
})
export class DashboardComponent implements OnInit, OnDestroy {

    @ViewChild('infoWindow') infoWindow: AgmInfoWindow;

    public showProject: boolean;

    public ActivityFeed = [];

    public dashboardCounts: any;

    public dashunsub: any;

    public dashcountunsub: any;

    public selector1 = '.notification-scroll';

    public pageNo = 1;

    public pageRecord = 20;

    public apiWorking = false;

    public stopScroll = true;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public workspacename: any;

    public date: any;

    myProjectList = [];

    latLngArray = [];

    lat: number;
    lng: number;

    zoom: number = 1;

    sortType = ''
    orderBy = ''

    photoFeedList: any;

    public constructor(private datePipe: DatePipe, private workspace: WorkspaceServiceService, private support: SupportService,
        private toastr: ToastrService,
        private workspaceService: WorkspaceServiceService,
        private mapsAPIWrapper: GoogleMapsAPIWrapper,
        private albumserviceService: AlbumserviceService,
        private router: Router, private api: ApiService) {
        this.dashboardCounts = {};
        sessionStorage.removeItem('projectId');
        this.support.projectChange.next('empty');
        this.showProject = sessionStorage.getItem('projectId') !== null;
        this.dashcountunsub = this.support.workspaceChange.subscribe((res): any => {
            this.commonResponseHandler(res);
        });


        this.dashunsub = this.support.projectChange.subscribe((res): any => {
            this.commonResponseHandler(res);
        });
    }

    public commonResponseHandler(res): void {
        if (res) {
            if (res !== 'empty') {
                this.getWorkspaceDashboard();
                this.getActivityFeed(1, this.pageRecord);
                this.support.notiunreadcount.next(0);
            }
        }
    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.getWorkspaceDashboard();
        this.getActivityFeed(this.pageNo, this.pageRecord);
        this.getAllProjects();
        this.workspaceService.emitAllProjects.subscribe((res: any) => {
            if (res.projects) {
                this.getAllProjects();
                this.getWorkspaceDashboard();
            }
        })
        console.log('sd')
    }

    public ngOnDestroy(): any {
        this.dashunsub.unsubscribe();
    }

    public getWorkspaceDashboard(): any {
        this.workspace.getWorkspaceDashboard().subscribe((res): any => {
            if (res && res.entity) {
                this.dashboardCounts = res.entity;
            }
        });
    }

    mapLoaded(event: Event) {
        console.log('mapLoaded event called!');
        this.mapsAPIWrapper.getNativeMap().then((map) => {

        });
    }

    public getActivityFeed(pageNo, pageRecord): void {
        // tslint:disable-next-line: max-line-length
        this.apiWorking = true;
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&pageNumber=${pageNo}&pageRecord=${pageRecord}`;
        this.workspace.getWorkspaceNotification(queryParams).subscribe((res): any => {
            if (pageNo === 1) {
                this.ActivityFeed = [];
            }
            if (res && res.entity) {

                this.apiWorking = false;
                if (this.ActivityFeed.length > 0) {
                    for (let index = 0; index < res.entity.length; index++) {
                        this.ActivityFeed.push(res.entity[index]);
                    }
                } else {
                    this.ActivityFeed = res.entity;
                }
            } else {
                this.apiWorking = false;
                this.stopScroll = false;
            }
        });
    }

    public onScroll(): any {
        this.pageNo += 1;
        this.getActivityFeed(this.pageNo, this.pageRecord);
    }

    public getDateValue(value): any {
        if (value) {
            const datobj = value.split(' ');
            return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
        }
        return '';
    }

    public navigate(feed): any {
        sessionStorage.setItem('projectId', feed.projectId);
        if (feed && feed.notificationType && feed.notificationType.notid === 1) {
            this.router.navigate(['/project/albums'], { queryParams: { ...this.api.getSessionData(), ...{ albumId: feed.albumId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 4) {
            this.router.navigate(['/project/album-details'], { queryParams: { ...this.api.getSessionData(), ...{ albumId: feed.albumId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 10) {
            this.router.navigate(['/project/tag-library'], { queryParams: { ...this.api.getSessionData(), ...{ tagid: feed.refId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 7) {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: feed.albumId,
                    photoId: feed.photoId,
                    photoTagId: feed.photoTagId,
                    prevPage: 'company/dashboard',
                },
            });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 16) {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: feed.albumId,
                    photoId: feed.photoId,
                    photoTagId: feed.photoTagId,
                    photoTagid: feed.photoTagId,
                    prevPage: 'company/dashboard',
                    todo: true,
                },
            });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 12) {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: feed.albumId,
                    photoId: feed.photoId,
                    photoTagId: feed.photoTagId,
                    photoTagid: feed.photoTagId,
                    prevPage: 'company/dashboard',
                    comment: true,
                },
            });
        }
    }

    public getAllProjects(): void {
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.workspaceService.getAllProject(queryParams).subscribe((res): any => {
            if (res.entity === null) {
                this.myProjectList = [];
            } else {
                this.myProjectList = res.entity;
                if (this.myProjectList && this.myProjectList.length) {
                    this.latLngArray = this.myProjectList
                        .filter(element => element.lattitude && element.longitude)
                        .map(element => ({ latitude: element.lattitude, longitude: element.longitude, teamName: element.teamName, teamId: element.teamId }));
                    this.lat = this.myProjectList[0].latitude
                    this.lng = this.myProjectList[0].longitude
                }
            }
        }, (err): any => {
            this.someThing();
        });
    }

    public someThing(): void {
        localStorage.clear();
        this.toastr.error('Something went wrong, Please login again and try. ');
        this.router.navigate(['/login']);
    }

    goToProject(p) {
        this.support.projectChange.next(p.teamId);
        sessionStorage.setItem('projectId', p.teamId);
        if (this.router.url !== '/project/dashboard') {
            this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
        }
    }

    onMouseOver(infoWindow, gm, teamId) {
        if (gm && gm.lastOpen != null) {
            gm.lastOpen.close();
        }
        gm.lastOpen = infoWindow;
        infoWindow.open();
        this.getPhotoFeed(teamId)
    }

    calculatePosition(marker) {
        // Calculate the position based on your requirements
        // For example, you can offset the div to the right and below the marker
        const offsetLeft = 30; // Adjust as needed
        const offsetTop = 30; // Adjust as needed
        marker.left = marker.longitude + offsetLeft;
        marker.top = marker.latitude + offsetTop;
    }

    hideImages(marker) {
        marker.showImages = false;
    }

    public getPhotoFeed(teamId): any {
        this.photoFeedList = []
        this.sortType = 'day';
        this.orderBy = 'desc'
        this.albumserviceService.getPhotoFeedByTeamIdFilter(this.sortType, this.orderBy, teamId).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    this.photoFeedList = res.entity.listview;
                    const combinedAwsKeys = this.photoFeedList.map((item) =>
                        item.photoInfo.map((photo) => `http://d4381dmmtr1wk.cloudfront.net/${photo.media.awskey}`)
                    );
                    this.photoFeedList = [].concat(...combinedAwsKeys);
                } else {
                    this.photoFeedList = undefined;
                }
            },
            (err): any => {
            }
        );
    }
}
