import {
  Component, Input, EventEmitter, SimpleChanges, Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, LoginService, UserService } from '../services';
import { SupportService } from '../services/support.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';

@Component({
  selector: 'app-your-invites-sidemenu',
  templateUrl: './your-invites-sidemenu.component.html',
})
export class YourInvitesSidemenuComponent {
  @Input('toggleSidemenu') public toggleSidemenu: boolean = false;

  @Output('closesidemenu') public closesidemenu: EventEmitter<boolean> = new EventEmitter();

  public filterSidemenu = false;

  public submitted = false;

  public workspacePendingList: any = [];

  public pending = false;

  public accepted = false;

  public rejected = false;

  public acceptreject = true;

  public count = 0;

  public pendingObject: any;

  @Input()
  public set workspacePending(workspacePendingList: any) {
    this.setValue(workspacePendingList);
  }

  constructor(private router: Router, public userService: UserService, private loginService: LoginService,
    private workspaceService: WorkspaceServiceService, private apiService: ApiService, private support: SupportService) { }


  public getallWorkspace(pageNumber, pageRecord): void {
    this.workspaceService.getAllWorkspace(pageNumber, pageRecord).subscribe(
      (res): any => {
        this.count = res.count;
      });
  }

  public setValue(data): void {
    this.workspacePendingList = data;
    this.getallWorkspace(1, 1);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.toggleSidemenu = changes.toggleSidemenu?.currentValue;
  }

  public closeFilter(): void {
    this.toggleSidemenu = false;
    this.closesidemenu.emit(this.toggleSidemenu);
  }

  public closeSidemenus(): void {
    this.filterSidemenu = !this.filterSidemenu;
  }

  public getFirstAndLastLetter(name): string {
    let value = 'Ws';
    if (name) {
      const split = Array.from(name);
      value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
    }
    return value;
  }

  public acceptInvite(pending): void {
    this.submitted = true;
    pending.accepted = true;
    pending.acceptreject = false;
    const payload = {
      password: '',
      workSpaceName: pending.workSpaceName,
      userId: localStorage.getItem('userId'),
      verificationCode: pending.verifyTeamMember,
    };
    this.loginService.setPassword(payload).subscribe(
      (res): any => {
        this.submitted = false;
        if (res.status.status === 200) {
          this.pendingObject = pending;
        }
      },
    );
  }

  public rejectInvite(pending,i,j): void {
    pending.rejected = true;
    pending.acceptreject = false;
    this.submitted = true;
    const payload = {
      userId: localStorage.getItem('userId'),
      workSpaceName: pending.workSpaceName,
      verificationCode: pending.verifyTeamMember,
    };
    console.log(this.workspacePendingList);    
    this.userService.rejectInvite(payload).subscribe((res): any => {
      this.submitted = false;
      if (res && res.status && res.status.status === 200) {
        console.log(this.workspacePendingList);
      }
    });
  }

  public proceedMethod(): void {
    console.log(this.pendingObject);
    
    if (!this.pendingObject) {
      return;
    }
    this.submitted = true;
    if (this.pendingObject.projectId && this.pendingObject.projectId !== 'null') {
      console.log('if');
      sessionStorage.setItem('projectId', this.pendingObject.projectId);
      sessionStorage.setItem('workspacename', this.pendingObject.workSpaceName);
      this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
        (res): any => {
          this.submitted = false;
          if (res && res.entity) {
            if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
              sessionStorage.setItem('usertype', res.entity.roleId);
              setTimeout((): any => {
                if (res.entity.roleId === 3) {
                  this.router.navigate(['/project/dashboard'], { queryParams: this.apiService.getSessionData() });
                } else {
                  this.router.navigate(['/project/feed'], { queryParams: this.apiService.getSessionData() });
                }
              }, 300);
              this.support.allProject.next('all');
              this.support.projectChange.next(this.pendingObject.projectId);
            }
          }
        },
      );
    } else {
      sessionStorage.setItem('workspacename', this.pendingObject.workSpaceName);
      this.workspaceService.getUserTypeService();
    }
  }
}
