<div class="card-body px-0 py-0">
    <div class="d-sm-flex d-block align-items-center justify-content-end px-4">
        <button class="btn btn-blue br25 py-2 px-sm-4 mr-sm-3 fs12" (click)="showNav = true">Associate Workspace</button>
    </div>
    <div class="table-responsive">
        <div class="contact-scroll pb-5 px-3 people-scroll" *ngIf="companyWorkspaceList.length > 0" infiniteScroll
            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollUser()"
            [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">

            <table class="table custom-table" aria-describedby="people-list">
                <thead>
                    <tr>
                        <th scope="col" class="grey-m fs14 fw-600">Workspace Name</th>
                        <th scope="col" class="grey-m fs14 fw-600">Subscription Plan Name</th>
                        <th scope="col" class="grey-m fs14 fw-600">Workspace Locked</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let people of companyWorkspaceList; let mi = index">
                        <td class="grey-i fs15 fw600">
                            {{people?.workSpaceName}}
                        </td>
                        <td class="grey-i fs15 fw600">
                            {{people?.subscriptionPlanName}}
                        </td>
                        <td class="grey-i fs15 fw600">
                            {{people?.workspaceLock === true ? 'Yes' : 'No'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="card-body text-center p-5 m-5" *ngIf="loadingPage || companyWorkspaceList.length === 0">
    <div class="page-loaders text-center m-5 p-2" *ngIf="loadingPage">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45" />
        </svg>
        <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
    </div>

    <div class="pending-invites-bg" *ngIf="!loadingPage && companyWorkspaceList.length === 0">
        <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
        <p class="grey-g fw-600 fs13 mt-3 mb-5">Looks like there are no workspaces available at the
            moment. <br /> Stay tuned for updates or try again later!</p>
    </div>
</div>
<div id="overlay" [ngClass]="{'d-block': showNav}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showNav}">
   <ng-container *ngIf="showNav">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Associate Workspace with Company</h1>
        <p class="lnr lnr-cross close-icon fw-600 fs16 "
            (click)="showNav = false"></p>
    </div>
    <form class="mt-4" name="workspaceForm" #workspaceForm="ngForm" novalidate>
        <div class="form-group input-rounded">
            <select class="form-control input-group-text h-46px" name="role" tabindex=1
                [(ngModel)]="selectedWorkspaceId" #workSpaceName="ngModel"
                [ngClass]="{ 'has-error': workspaceForm.submitted && workSpaceName.invalid }">
                <option value="" disabled>Select</option>
                <option *ngFor="let workspace of workspaceList" value={{workspace?.masterTenant?.tenantId}}>{{workspace?.masterTenant?.workSpaceName}}</option>
            </select>
            <div *ngIf="workSpaceName.invalid && (workSpaceName.dirty || workSpaceName.touched)">
                <div *ngIf="workSpaceName.errors.required" class="text-danger fs12">
                    Workspace name is required.
                </div>
            </div>
        </div>
        <div class="row mt50 px-3">
            <div class="col-4 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                    [disabled]="submitted" (click)="showNav = false">Cancel</button>

            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                    (click)="workspaceForm.form.valid && createWorkspace()"
                    [disabled]="!workspaceForm.valid || submitted">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Associate Workspace</button>
            </div>
        </div>
    </form>
   </ng-container>
</div>