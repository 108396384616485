<ngx-loading-bar [color]="'#007bff'"></ngx-loading-bar>
<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15 pb15">
            <div class="content-card  border-grey shadow-none">
                <div>
                    <div class="breadcrum pt-3" *ngIf='procoreIntegrationDone'>
                        <ul class="list-group list-group-horizontal">
                            <li class="list-group-item p-0 border-0 grey-l fs12 mr-1">Integrations</li>
                            <li class="list-group-item p-0 border-0 grey-l fs12 mr-1">></li>
                            <li class="list-group-item p-0 border-0 grey-l fs12">Procore</li>
                        </ul>                      
                    </div>
                    <div class="d-flex align-items-center p-4" *ngIf='procoreIntegrationDone && integrationMenuShow'>
                        <div class="mr-2 back-arrow c-pointer" (click)='integrationMenuShow=false;'>
                            <img src="./assets/images/back-arrow.svg" alt="back arrow" />
                        </div>
                        <h1 class="grey-g m-0 fs18 font-weight-bold">Procore</h1>
                    </div> 
                    <div class="card-header px-4 mb-5 pb-0 bg-white  border-0">
                        <div class="d-sm-flex d-block align-items-center justify-content-between mt20">
                            <div class="mb-2 mb-sm-0">
                                <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Integrations</h1>
                            </div>
                            <div *ngIf='!integrationMenuShow && procoreIntegrationDone' class=" d-flex justify-content-lg-end align-items-center">
                                    <button class="btn btn-blue-secondry br25 py-2 px-sm-4 mr-sm-3 fs12"
                                        (click)="addIntegrationClick()"><img src="./assets/images/plus.svg" alt="add"
                                            class="mr-1 w11 mb-1">Add Integration</button>
                            </div>
                            </div>
                    </div>                   
                    <div class="card-body px-0 py-0" >
                        <div class="card-body  px-2 py-1" *ngIf='integrationMenuShow'>
                            <ul class="nav nav-pills pills-secondry  mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item mr-4" role="presentation">
                                    <a [class.active]="viewMode=='Projects'" (click)="viewMode='Projects';getProjects();"
                                        class="nav-link fs14 px-4" href="javascript:void(0)">
                                        Projects</a>
                                </li>
                                <li class="nav-item mr-4" role="presentation">
                                    <a [class.active]="viewMode=='Settings'" (click)="viewMode='Settings'"
                                        class="nav-link fs14 px-4" href="javascript:void(0)">
                                        Settings</a>
                                </li>
                            </ul>
                            <div class="tab-content pb-3" [ngSwitch]="viewMode">
                                <div class="tab-pane fade show active" *ngSwitchCase="'Projects'">
                                    <div *ngFor='let project of projectList'>
                                        <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                            <div class="flex-grow-1">
                                                <label class="fs24 grey-m mb-0 fw-600 ls-10">{{project.procoreProjectName}}</label>
                                            </div>
                                        </div>
                                        <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                            <div class="flex-grow-1">
                                                <h1 class="fs15 grey-l mb-0 fw-600 ls-10">Import photos to Pixly</h1>
                                            </div>
            
                                            <div class="custom-switchtoggle">
                                                <ui-switch [disabled]="submitted" size="small" [checked]="project.importPhoto"
                                                (change)="importPhotos(project, $event)"
                                                ></ui-switch>
                                            </div>
                                        </div>
    
                                        <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                            <div class="flex-grow-1">
                                                <h3 class="fs14 fw-600 mb-1 grey-l mb-0  ls-10">Status</h3>
                                            </div>
                                            <div class="custom-switchtoggle" *ngIf='project.importPhoto'>
                                                <p type="button" class="mb-0 ls-8 grey-l fs14" >
                                                    <img src="./assets/images/check.svg" class="tick-img mr-2" alt="check" />
                                                    Success
                                                </p>
                                            </div>
                                            <div class="custom-switchtoggle" *ngIf='!project.importPhoto'>
                                                <p type="button" class="mb-0 ls-8 grey-l fs14">
                                                    <img src="./assets/images/cross-red.svg" class="cross-img mr-2" alt="check" />
                                                    Failure
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                            <div class="flex-grow-1">
                                                <h3 class="fs14 fw-600 mb-1 grey-l mb-0  ls-10">Last Import</h3>
                                            </div>
                                            <div class="custom-switchtoggle">
                                                <p class="mb-0 ls-8 grey-l fs11">{{project.lastImport}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                            <div class="flex-grow-1">
                                                <h3 class="fs14 fw-600 mb-1 grey-l mb-0  ls-10">Action</h3>
                                            </div>
                                            <div class="custom-switchtoggle">
                                                <button class="btn btn-light-blue br25 pt7 pb7 btn-block fs13 fw-600 " (click)='forceUpdate(project)'><em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Force update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="tab-pane fade show active" *ngSwitchCase="'Settings'">
                                   
                                    <div class="companies-list my-4">
                                        <div class="d-flex px-4 companies-list-item align-items-center justify-content-center " *ngFor="let integration of integrationList">
                                            <div class="team-logo grey-i fs12 mr-3 c-pointer d-flex align-items-center justify-content-center" (click)="updateIntegrationMethod()">
                                                {{getFirstAndLastLetter(integration.integrationName)}}
                                            </div>
                                            <div class="flex-grow-1 fs23 fw-600 grey-m my-auto">
                                                <span class="c-pointer" (click)="updateIntegrationMethod()">{{integration.integrationName | ngXtruncate:25:'...'}}</span>
                                            </div>
                                            <div class="custom-switchtoggle" (click)="updateIntegrationMethod()">
                                                <img src="./assets/images/edit-outline.svg" title="integration detail" alt="integration" class="w15 mr-3 c-pointer">
                                            </div>
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="companies-list my-4" *ngIf='!integrationMenuShow && procoreIntegrationDone'>
                            <div (click)="showTabsIntegration()" class="d-flex px-4 companies-list-item align-items-center justify-content-center " *ngFor="let integration of integrationList">
                                <div class="team-logo grey-i fs12 mr-3 c-pointer d-flex align-items-center justify-content-center">
                                    {{getFirstAndLastLetter(integration.integrationName)}}
                                </div>
                                <div class="flex-grow-1 fs23 fw-600 grey-m my-auto">
                                    <span class="c-pointer">{{integration.integrationName | ngXtruncate:25:'...'}}</span>
                                </div>
                                <div class="custom-switchtoggle">
                                    <img src="./assets/images/arrow-right.png" title="Edit Invite" alt="edit-outline" class="w15 mr-3 c-pointer">
                                </div>
                            </div>                        
                        </div>
                        <div class="px-3 pb-4" *ngIf='!procoreIntegrationDone'>
                            <div class="integration-img-border pb-4">
                                <img  src="./assets/images/integrations-image-small.png"
                                        alt="" class="people-img mt-5 py-5 c-pointer mr-4 integration-img">
                                    <div class="text-center">
                                        <h1 class="fs28 font-weight-bold text-black mb6 text-capitalize ls-d ">Add Integration</h1>
                                        <p  class="fs18 font-weight-bold  text-black ls-c col-xl-5 mx-auto">
                                            No Integrations have been set up yet. Create your first one.</p>
                                        <button class="btn btn-blue br25  pt7 pb7 px-3 mt-2 fs18 mr-5" (click)="addIntegrationClick()"><img
                                            src="./assets/images/plus.svg" alt="add" class="mr-2 w11 mb-0">Add Integration</button >
                                    </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- integration side bar -->

<div id="overlay" [ngClass]="{'d-block': showIntegrationSideLayout}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showIntegrationSideLayout}">

        <div class="d-flex justify-content-between">
            <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Integrations</h1>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showIntegrationSideLayout = false"></em>
        </div>

        <div class="pb15 pr15">
            <div class="scroll-content">
                <div class="workspace-list-item" *ngFor="let integration of integrationList; let i = index">
                    <label class=" workspace-list py-2 d-block mb-0" for="check-radio{{i}}">
                        <div class="d-flex align-items-center">
                            <div class="team-logo grey-i fs12 mr-3 c-pointer d-flex align-items-center justify-content-center" (click)="selectedIntegrationMethod(integration)">
                                {{getFirstAndLastLetter(integration.integrationName)}}
                            </div>
                            <div class="flex-grow-1 fs14 fw-600 grey-m my-auto">
                                <span class="c-pointer" (click)="selectedIntegrationMethod(integration)">{{integration.integrationName | ngXtruncate:25:'...'}}</span>
                            </div>
                        </div>
    
                    </label>
    
                </div>
            </div>
        </div>
</div>

<!-- integration settings side bar -->

<div id="overlay" [ngClass]="{'d-block': showIntegrationSettingsSideLayout}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showIntegrationSettingsSideLayout}">
    <div>
        <div class="d-flex justify-content-between">
          <div class="mr-4 c-pointer" (click)='showIntegrationSettingsSideLayout= false'>
              <img src="./assets/images/back-side.svg" alt="back-arrow" class="w13 mb4">
          </div>
           <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ml35 side-pdf-layout">{{selectedIntegrationName}}</h1>
        </div>
     </div>
    
     <div class="d-flex justify-content-between">
        <img src="./assets/images/procore-logo.png" alt="back-arrow" class="ml-5">
     </div>
     <div class="scroll-content">
        <form class="mt-3" name="integrationForm" autocomplete="off"
        (ngSubmit)="onIntegrationSubmit(integrationForm)" #integrationForm="ngForm" novalidate>
        <label class="fs12 grey-j ls-3">Company Id*</label>
        <div class="input-group input-rounded mb-3">
            <input required [(ngModel)]="integrationFormInput.companyId" #companyId="ngModel" name="companyId"
                [ngClass]="{ 'has-error': integrationForm.submitted && companyId.invalid }" [type]="togglePasswordOne ? 'password' : 'text'"
                class="form-control brtlr-8 fw-600 pt22 pb22  border-right-0 " placeholder="Company Id">
            <div class="input-group-append">
                <span class="input-group-text brtlr-8 border-left-0 " (click)="passwordToggle(1)">
                    <img *ngIf="!togglePasswordOne" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordOne" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="companyId.invalid && validation">
            <div *ngIf="companyId.errors.required" class="text-danger fs12">
                Company Id is required.
            </div>
        </div>
        <label class="fs12 grey-j ls-3">Client Id*</label>
        <div class="input-group input-rounded my-0 mb-3">
            <input required name="clientId" 
                [(ngModel)]="integrationFormInput.clientId" #clientId="ngModel"
                [ngClass]="{ 'has-error': integrationForm.submitted && clientId.invalid }" [type]="togglePasswordTwo ? 'password' : 'text'"
                class="form-control br0 fw-600 pt22 pb22  border-right-0 " placeholder="Client Id">
            <div class="input-group-append">
                <span class="input-group-text br0 border-left-0" (click)="passwordToggle(2)">
                    <img *ngIf="!togglePasswordTwo" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordTwo" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="clientId.invalid && validation">
            <div *ngIf="clientId.errors.required" class="text-danger fs12">
               Client Id is required.
            </div>
        </div>
        <label class="fs12 grey-j ls-3">Secret Id*</label>
        <div class="input-group input-rounded mb-3">
            <input required name="secretId" 
                [(ngModel)]="integrationFormInput.secretId" #secretId="ngModel"
                [ngClass]="{ 'has-error': integrationForm.submitted && secretId.invalid }"
                [type]="togglePasswordThree ? 'password' : 'text'" class="form-control brblr-8 br8 fw-600 pt22 pb22  border-right-0 "
                placeholder="Secret Id">
            <div class="input-group-append">
                <span class="input-group-text brblr-8 border-left-0" (click)="passwordToggle(3)">
                    <img *ngIf="!togglePasswordThree" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordThree" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="secretId.invalid && validation">
            <div *ngIf="secretId.errors.required" class="text-danger fs12">
               Secret Id is required.
            </div>
        </div>
        <div class="row mt50 px-3">
            <div class="col-10 mx-auto" *ngIf='updateIntegration'>
                <button type="button" class="btn btn-orange text-white br25 pt7 pb7  btn-block fs13 fw-600 "
                (click)="onIntegrationSubmit(integrationForm)" [disabled]="submitted"><em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Update</button>
            </div>
            <div class="col-5 pr-1" *ngIf='!updateIntegration'>
                <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                (click)="showIntegrationSettingsSideLayout=false;showIntegrationSideLayout=false" [disabled]="submitted">Cancel</button>
            </div>
            <div class="col-7 pl-1" *ngIf='!updateIntegration'>
                <button class="btn btn-orange br25 pt7 pb7 btn-block fs13 fw-600 "
                    [disabled]="submitted">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Submit</button>
            </div>
        
        </div>
        <div class="position-absolute integration-delete-btn"  *ngIf='updateIntegration'>
            <div class="row mt50 px-3">
                <div class="col-10 mx-auto pl-4">
                    <button class="btn btn-red-h br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button" (click)='openModal()'><em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Delete</button>
                </div>        
            </div>
        </div>
        </form>
     </div>
</div>


<ng-template #integrationdeleteModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete integration?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="deleteIntegrationClick()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>