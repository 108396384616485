<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15 pb15">

            <div class="content-card p-0 border-grey shadow-none">
                <div class="card-header bg-white border-0 pb-4  mt-2  px-3 pt-3">
                    <ul class="list-inline pl40">
                        <li class="list-inline-item"><a class="fs11 "><span class="grey-l">People</span></a></li>
                        <li class="list-inline-item"><a class="fs10 "><em class="grey-l fas fa-chevron-right"></em></a>
                        </li>
                        <li class="list-inline-item"><a class="fs11"><span class="grey-l"
                                    *ngIf="userDetails && userDetails.firstName && userDetails.lastName">{{userDetails.firstName}}
                                    {{userDetails.lastName}}</span></a></li>
                    </ul>
                    <div class="d-flex  align-items-center">
                        <div class="back-cirle mr-3 c-pointer" (click)="goBack()">
                            <img src="./assets/images/back-arrow.svg" alt="back-arrow" class="w13">
                        </div>
                        <h1 class="fw-600 fs20 ls-9 grey-g mb-0">People</h1>
                    </div>

                </div>
                <div class="card-body text-center p-0" *ngIf="userDetails">
                    <div class="user-wallpaper mb70">
                        <img *ngIf="!userDetails.backgroundImage" src="./assets/images/nature.png" alt="nature"
                            class="user-wallpaper-img">
                        <img *ngIf="userDetails.backgroundImage" src={{cloudFrontURL}}{{userDetails.backgroundImage}}
                            alt="nature" class="user-wallpaper-img">
                        <img *ngIf="!userDetails.profileImage" src="./assets/images/user1.png" alt="profile "
                            class="user-profile-img">
                        <img *ngIf="userDetails.profileImage" src={{cloudFrontURL}}{{userDetails.profileImage}}
                            alt="profile " class="user-profile-img">
                        <button class="btn btn-blue-secondry br25 pt7 px-3 pb7  edit-details fs12"
                            (click)="showEditProfile()"><img src="./assets/images/edit-white.svg" alt="edit-profile"
                                class="mr-2 w13 mb-1">Edit
                            Profile</button>
                    </div>
                    <h1 class="fs18 font-weight-bold grey-g ls-c" *ngIf="editObj && editObj.firstName && editObj.lastName">
                        {{editObj.firstName}} {{editObj.lastName}}</h1>
                    <div class="d-flex mt40 mb-4 justify-content-around px-sm-5 px-2" *ngIf="viewMode1 === 'project'">
                        <div>
                            <h3 class="fs14 fw-600 blue-g">Photos</h3>
                            <p class="grey-g fs16 font-weight-bold">{{userDetails.photosCount}}</p>
                        </div>
                        <div>
                            <h3 class="fs14 fw-600 blue-g">Completed Tasks</h3>
                            <p class="grey-g fs16 font-weight-bold">{{userDetails.completedTaskCount}}</p>
                        </div>
                        <div>
                            <h3 class="fs14 fw-600 blue-g">Tags</h3>
                            <p class="grey-g fs16 font-weight-bold">{{userDetails.tagsCount}}</p>
                        </div>
                    </div>
                    <ul class="nav nav-pills pills-secondry px-3 mb-2" id="pills-tab" role="tablist">
                        <li class="nav-item mr-md-4" role="presentation" *ngIf="viewMode1 === 'project' && photoList && photoList.length > 0">
                            <a [class.active]="viewMode=='grid'" (click)="viewMode='grid'" class="nav-link fs14 px-3 px-md-4 "
                                href="javascript:void(0)">
                                <img class="active-img w15 mr-1" src="./assets/images/grid-active.svg"
                                    alt="grid-active">
                                <img class="in-active-img w15 mr-1" src="./assets/images/grid-inactive.svg"
                                    alt="grid-inactive">
                                Grid</a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="viewMode1 === 'project' && photoList && photoList.length > 0">
                            <a [class.active]="viewMode=='listimg'" (click)="viewMode='listimg'"
                                class="nav-link fs14 px-3 px-md-4" href="javascript:void(0)">
                                <img class="active-img w15 mr-1" src="./assets/images/list-active.svg"
                                    alt="grid-active">
                                <img class="in-active-img w15 mr-1" src="./assets/images/list-inactive.svg"
                                    alt="grid-inactive">
                                List</a>
                        </li>
                        <li class="nav-item " role="presentation" [ngClass]="{'ml-md-auto' : viewMode1 
                        !== 'company' && projectList && projectList.length !== 0}"
                         *ngIf="viewMode1 === 'company' && projectList.length > 0">
                            <a [class.active]="viewMode=='project'" (click)="viewMode='project'"
                                class="nav-link fs14 px-3 px-md-4 " href="javascript:void(0)">
                                <img class="active-img w15 mr-1" src="./assets/images/noun_tools_1701588.svg"
                                    alt="grid-active">
                                <img class="in-active-img w15 mr-1" src="./assets/images/tools-inactive.svg"
                                    alt="grid-inactive">
                                    Projects</a>
                        </li>
                    </ul>
                    <div class="tab-content pb-3" [ngSwitch]="viewMode">
                        <div class="tab-pane fade show active" *ngSwitchCase="'grid'">
                            <div class="row grid-row mx-minus-5" >
                                <div class="col-4  grid-col pr5 pl5 pb-2 " *ngFor="let photo of photoList">
                                    <img src={{cloudFrontURL}}{{photo.media.awskey}} alt="problem"
                                        class="grid-view-img c-pointer" (click)="goToPhotoTag(photo)">
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'listimg'">
                            <div class="content-card border-0 shadow-none br0 p-0" *ngFor="let photo of photoList">
                                <div class="card-header bg-white border-0 d-flex justify-content-between">
                                    <div class="profile-photo">
                                        <img *ngIf="!userDetails.profileImage" src="./assets/images/user1.png"
                                            alt="user-img">
                                        <img *ngIf="userDetails.profileImage"
                                            src={{cloudFrontURL}}{{userDetails.profileImage}} alt="user-img">
                                    </div>
                                    <div class="flex-grow-1 text-left ml-2">
                                        <h1 class="mb-0 grey-g fs14 fw-600">{{userDetails.firstName}}
                                            {{userDetails.lastName}}</h1>
                                        <p class="grey-r mb-0 fs10 ">{{photo.created | amLocal | amCalendar}}</p>
                                    </div>
                                    <div class="menu-dots">
                                        <em class="fas fa-ellipsis-v grey-g fs12"></em>
                                    </div>
                                </div>
                                <div class="card-body px-2 py-0" (click)="goToPhotoTag(photo)">
                                    <img src={{cloudFrontURL}}{{photo.media.awskey}} alt="user-img"
                                        class="br10 posted-img c-pointer">
                                </div>
                                <div
                                    class="d-flex px-2 border-bottom-grey justify-content-between py-2 align-items-center">
                                    <div class="general-info d-flex pl15 pr15 py-2 align-items-center">
                                        <img src="./assets/images/album-small.svg" alt="album-small" class="mr-2">
                                        <h3 class="blue-e ls-8 fs13 mb-0 font-weight-bold"
                                            *ngIf="photo && photo.albumName">{{photo.albumName}}</h3>
                                    </div>
                                    <div class="d-flex ">
                                        <img src="./assets/images/Share.svg" alt="share"
                                            class="mr-3 mt-1 w17 c-pointer">
                                        <div class="tag-count c-pointer mr-1">
                                            <a class="mb-0 tag text-white">{{photo.tagCount}}</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-header bg-white border-0 d-flex justify-content-between">

                                    <div class="profile-photo">
                                        <img src="./assets/images/user1.png" alt="user-img">
                                    </div>
                
                
                                    <div class="flex-grow-1 text-left ml-2">
                                        <h1 class="mb-0 grey-g fs14 fw-600">Andrea</h1>
                                        <p class="grey-r mb-0 fs10 ">Just now</p>
                                    </div>
                
                                    <div class="menu-dots">
                                        <em class="fas fa-ellipsis-v grey-g fs12"></em>
                                    </div>
                                </div>
                                <div class="card-body px-2 py-0">
                                    <img src="./assets/images/user1.png" alt="user-img" class="br10 posted-img">
                                    
                                </div>
                                <div class="d-flex px-2 border-bottom-grey justify-content-between py-2 align-items-center">
                
                                    <div class="general-info d-flex pl15 pr15 py-2 align-items-center">
                                        <img src="./assets/images/album-small.svg" alt="album-small" class="mr-2">
                                        <h3 class="blue-e ls-8 fs13 mb-0 font-weight-bold">General</h3>
                                    </div>
                                    <div class="d-flex ">
                                        <img src="./assets/images/Share.svg" alt="share" class="mr-3 mt-1 w17 c-pointer">
                                        <div class="tag-count c-pointer mr-1">
                                            <a class="mb-0 tag text-white">23</a>
                                          </div>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'project'">
                            <div class="table-responsive people-table">
                                <table class="table text-left custom-table " aria-describedby="people-list">
                                    <thead>
                                      <tr >
                                        <th scope="col" class="grey-m fs14 fw-600">Project Name</th>
                                        <th scope="col" class="grey-m fs14 fw-600">Role</th>
                                        <th scope="col" class="grey-m fs14 fw-600">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let proj of projectList; let j = index">                                        
                                        <td class="grey-i fs15 fw600 w-55">
                                            <div class="d-flex align-items-center">
                                                <div class="w35 h35 p5 text-center br20 btn-grey-f ">
                                                    <img  src="./assets/images/noun_tools_1701588.svg"
                                                    alt="" class="w15 h20   ">
                                                </div>
                                                <div class="ml-3">
                                                    {{proj.teamName}}
                                                </div>
                                            </div>                                        
                                        </td>
                                        <td class="grey-i fs15 fw600 w-25percent">{{proj.roleName}}</td>
                                        <td class="grey-i fs15 fw600 w-20" *ngIf="proj.roleName !== 'Workspace Admin'"><button  type="button" (click)="openModal(deletePeopleModal, proj, j)"
                                            class="btn btn-red-h br15 pt4 pb4 px-3 fs13 fw-600">Remove</button></td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <aside aria-label="right-sidemenu" class="dashboard-right-sidemenu"
        [ngClass]="{show: showProject, closed: !showProject}">
        <app-layout-project></app-layout-project>
    </aside>
</section>
<!-- EditProfile -->
<div id="overlay" [ngClass]="{'d-block': EditProfile}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : EditProfile}">
    <!-- for invite -->
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Edit Profile</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showEditProfile()"></em>
    </div>
    <form name="profileForm" #profileForm="ngForm" novalidate>
        <label class="grey-j fs12 ">Name</label>

        <div class="row">
            <div class="col-6 pr-1">
                <div class="form-group input-rounded mb-3">
                    <input type="text" name="firstName" required [(ngModel)]="editObj.firstName"
                        #firstName="ngModel" [ngClass]="{ 'has-error': profileForm.submitted && firstName.invalid }"
                        class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter First Name">
                </div>
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.errors.required" class="text-danger fs12">
                        First Name is required.
                    </div>
                </div>
            </div>
            <div class="col-6 pl-1">
                <div class="form-group input-rounded mb-3">
                    <input type="text" name="lastName" required [(ngModel)]="userDetails.lastName"
                        #lastName="ngModel" [ngClass]="{ 'has-error': profileForm.submitted && lastName.invalid }"
                        class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter Last Name">
                </div>
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.errors.required" class="text-danger fs12">
                        Last Name is required.
                    </div>
                </div>
            </div>
        </div>

        <label class="grey-j fs12 ">Mobile Number</label>

        <div class="input-group input-rounded  mb-2">
            <div class="input-group-prepend br8 border-right-0 ">
                <select class="custom-select br8 input-group-text  border-right-0 " (change)="changeMask()"
                    name="phoneCode" [(ngModel)]="userDetails.phoneCode" #phoneCode="ngModel">
                    <option value="" disabled>select</option>
                    <option *ngFor="let c of code" value={{c.code}}>{{c.code}}</option>
                </select>
            </div>
            <input [dropSpecialCharacters]="true" [mask]="phoneMask"
                [readonly]="userDetails.phoneCode!= '' ? false : true" name="phoneNumber"
                [(ngModel)]="userDetails.phoneNumber" #phoneNumber="ngModel" type="text"
                autocomplete="off" class="form-control  br8 fw-600 pt22 pb22 border-left-0 "
                placeholder="Enter Phone Number">
        </div>
        <div *ngIf="phoneCode.invalid">
            <div *ngIf="phoneCode.errors.required" class="text-danger fs12">
               Phone Code and Phone Number is required.
            </div>
        </div>
        <div *ngIf="phoneNumber.invalid">
            <div *ngIf="!phoneCode.invalid && phoneNumber.invalid" class="text-danger fs12">
               Please enter valid Phone number.
            </div>
        </div>

        
        <label class="grey-j fs12 ">Email Id</label>

        <div class="form-group input-rounded mb-3">
            <input type="text" name="email" required [(ngModel)]="editObj.email"
            #email="ngModel" [ngClass]="{ 'has-error': profileForm.submitted && email.invalid }"
            class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter email id" disabled>
        </div>

        <div class="row mt50 justify-content-center px-2">
            <div class="col-4 pr-1">
                <button type="button" [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showEditProfile()">Cancel</button>
            </div>
            <div class="col-6 pl-1">
                <button class="btn btn-blue-secondry br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                [disabled]="submitted" (click)="profileForm.form.valid && onSubmit(profileForm)">
                <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>
                <img src="./assets/images/save.svg" alt="save"
                    class="w10 mr-1 mb-1">
                    Save Changes</button>
            </div>
        </div>
    </form>
</div>
<ng-template #deletePeopleModal>
    <div class="modal-sm">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 ">Are you sure you want to remove the user from this project?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethod()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>