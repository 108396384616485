import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AlbumserviceService } from 'src/app/services/albumservice.service';
import { ApiService } from 'src/app/services';

@Component({
    selector: 'app-share-album-sidemenu',
    templateUrl: './share-album-sidemenu.component.html',
    providers: [DatePipe],
})
export class ShareAlbumSidemenuComponent implements OnInit {
    public Share: any = {};

    public validation = false;

    @Input() public Invite: boolean = false;

    @Input() public albumId: boolean = false;

    @Output() public trigerInvite: EventEmitter<boolean> = new EventEmitter();

    public submitted: boolean;

    public date: any;

    public colorTheme = 'theme-default';

    public datePickerConfig: Partial<BsDatepickerConfig>;

    public constructor(
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private albumservice: AlbumserviceService,
        private apis: ApiService,
    ) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.datePickerConfig = {
            dateInputFormat: 'YYYY-MM-DD',
            minDate: date,
            adaptivePosition: true,
            containerClass: this.colorTheme,
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // changes.prop contains the old and the new value...
        this.Invite = changes.Invite.currentValue;
    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.Share = {
            email: '',
            firstName: '',
            lastName: '',
            expires: '',
        };
    }

    public ResetForm(ShareForm): void {
        ShareForm.form.reset();
        // this.photoId = '';
        this.validation = false;
        this.trigerInvite.emit(false);
    }

    public onSubmit(ShareForm): void {
        this.validation = true;
        if (!ShareForm.form.valid) {
            return;
        }
        const value = this.datePipe.transform(this.Share.expires, 'yyyy-MM-dd');
        if (value < this.date) {
            this.toastr.error('Please dont choose a prior date');
            return;
        }
        this.submitted = true;
        const params = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            email: this.Share.email,
            firstName: this.Share.firstName,
            lastName: this.Share.lastName,
            expires: value,
            albumId: this.albumId,
            photoId: '',
            shareType: 2,
        };
        this.albumservice.ShareImg(params).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    ShareForm.form.reset();
                    this.submitted = false;
                    this.toastr.success(res.status.msg);
                    this.trigerInvite.emit(false);
                    this.validation = false;
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }
}
