<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15">

            <div class="content-card tag-library border-grey shadow-none">
                <div *ngIf="showTagMain">
                    <div class="card-header pb-0 bg-white  border-0">
                        <div class="d-flex align-items-center mt20">
                            <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Tag Library</h1>
                            <div class="flex-grow-1 text-right mr-2 mr-lg-3" >
                                <button class="btn btn-blue-secondry br25 py-2 px-4 fs12" *ngIf="userType && (userType !== '4') && (userType !== '5')"
                                    (click)="viewAddEditTag()"><img src="./assets/images/plus.svg" alt="add"
                                        class="mr-1 w11 mb-1">Add Tag</button>
                            </div>

                            <div dropdown class="tag-menu c-pointer">

                                <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal" class="w23"
                                    id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="basic-link">
                                    <li *ngFor="let choice of items">
                                        <a (click)="getSortlist(choice)" class="dropdown-item text-black fs13 fw-600 mb-1"
                                            href="javascript:void(0)">{{choice}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <form class="mt30 mb10 px-2" *ngIf="Tags && Tags.length > 0">
                            <div class="input-group input-todo">
                                <div class="input-group-prepend">
                                    <span class="input-group-text pr-1" id="basic-addon3"><img
                                            src="./assets/images/search-todo.svg" alt="search" class="w13"></span>
                                </div>
                                <input type="text" class="form-control fw-600 px-1" placeholder="Search Tag"
                                [(ngModel)]="userSearch" [ngModelOptions]="{standalone: true}" 
                                aria-describedby="basic-addon3" (input)="getTags()">
                                <div class="input-group-append" *ngIf="userSearch !== ''">
                                    <span class="input-group-text border-left-0 p-1" (click)="userSearch = '';getTags()">
                                        <span class="lnr lnr-cross fs16"></span>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body pl30 pr30 py-0">
                        <div class="tag-list border-bottom-a py-3 " *ngFor="let tag of Tags">
                            <div class="tag-initial-alphabet mb-3">
                                <h3 class="grey-m fw-600 fs13 mb-0 text-uppercase">{{tag.letter}}</h3>
                            </div>
                            <div class="d-flex align-items-center mb-3" *ngFor="let t of tag.names">

                                <div class="tag-types w26 h26" [ngStyle]="{'background-color': (t.color && t.color.startsWith('#')) ? t.color : (t.color ? '#' + t.color : '#FFFFF')}"
                                [ngClass]="{'blink': t.id === queryData.tagid}">
                                </div>
                               
                                <div class="flex-grow-1 ml-3">
                                    <h1 class="grey-g ls-10 fs15 mb-0 fw-600 text-capitalize">{{t.title}}</h1>
                                    <p class="fs12 grey-l ls-7 fw-600 mb-0">Count - {{t.postcount}}</p>
                                </div>
                                <img *ngIf="t.global" src="./assets/images/globe.png" alt="edit-outline" class="w15 mr-4" title="Global tag">
                                <div *ngIf="userType && (userType === '1' || userType === '2')">

                                    <img src="./assets/images/edit-outline.svg" alt="edit-outline" class="w15 mr-4 c-pointer" (click)="editTagById(t)" title="Edit tag">

                                    <img src="./assets/images/delete.svg" *ngIf="!t.global" alt="delete" class="w15 mr-3 c-pointer" (click)="openModal(t)" title="Delete tag">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!Tags || !Tags === 0">{{errorMessage}}</div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>

<div id="overlay" [ngClass]="{'d-block': viewTag}"></div>
<div #sidenav class="right-sidebar bg-white tag-color-picker" [ngClass]="{'show-side-nav' : viewTag}">
    <!-- for workspace -->


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3" *ngIf="!Id">New Tag</h1>
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3" *ngIf="Id">Edit Tag</h1>

        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="cancleCTA();ResetForm(Tagform)"></em>
    </div>


    <form class="mt-4 " name="Tagform" autocomplete="off" (ngSubmit)="onSubmit(Tagform)" #Tagform="ngForm">
        <div class="form-group input-rounded br8 mb-3">
            <label class="grey-j fs12 ">Tag Name</label>
            <input name="title" maxlength="50" required [(ngModel)]="TagLibrary.title" (keydown.space)="TagLibrary.title.trim().length > 0 ? '': $event.preventDefault();"
                #title="ngModel" [ngClass]="{ 'has-error': Tagform.submitted && title.invalid }" type="text" [disabled]="TagLibrary.global && TagLibrary.title" 
                [ngStyle]="{ 'cursor': (TagLibrary.global && TagLibrary.title) ? 'not-allowed' : 'auto' }"
                class="form-control pt22 pb22 br8 fw-600" placeholder="Enter Tag Name">
            <div *ngIf="title.invalid && validation">
                <div *ngIf="title.errors.required" class="text-danger fs12">
                    Title is required.
                </div>
            </div>
        </div>
        <div class="form-group input-rounded br8 mb-3">
            <label class="grey-j fs12 ">Tag Purpose</label>
            <textarea name="purpose" maxlength="100" [(ngModel)]="TagLibrary.purpose" required #purpose="ngModel"
                [ngClass]="{ 'has-error': Tagform.submitted && purpose.invalid }" type="text" rows="3"
                class="form-control pt22 pb22 fw-600" placeholder="Enter Tag purpose"
                (keydown.space)="TagLibrary.purpose.trim().length > 0 ? '': $event.preventDefault();"></textarea>
            <div *ngIf="purpose.invalid && validation">
                <div *ngIf="purpose.errors.required" class="text-danger fs12">
                    Tag Purpose is required.
                </div>
            </div>
        </div>
        <label class="grey-j fs12 ">Tag Color</label>
        <!-- <div class="input-group input-rounded ">
            <input type="text" [(ngModel)]="color1"  name="color" 
            [(colorPicker)]="color1" required #color="ngModel"
                class="form-control br8 fw-600 pt22 pb22  border-right-0 " placeholder="Enter Tag Color"
                (keydown.space)="color1.trim().length > 0 ? '': $event.preventDefault();">
            <div class="input-group-append">
                <div class="input-group-text  br8 border-left-0 " >
                    <span class="ls-c grey-l fs13 "></span>
                    <p [(colorPicker)]="color1"  [style.background]="color1"
                        class="c-pointer pt15 mb-0 br8 px-3 ml-2 pb15 fw-600"></p>

                </div>

            </div>
        </div> -->
        <div class="input-with-color">
            <input
              maxlength="50"
              type="text"
              name="tagColor"
              required
              [(ngModel)]="color1"
              #color="ngModel"
              class="form-control br8 pt22 pb22 fw-600"
              placeholder="Tag color..."
              (keydown.space)="color1.trim().length > 0 ? '': $event.preventDefault();"
            />
            <input
              type="color"
              [(ngModel)]="color1"
              #color="ngModel"
              [ngModelOptions]="{ standalone: true }"
              (input)="updateColor()"
              id="color"
            />
          </div>
        <div *ngIf="color.invalid && validation">
            <div *ngIf="color.errors.required" class="text-danger fs12">
                Tag color is required.
            </div>
        </div>

        <div class="row mt30 px-3" *ngIf="isCreateNewTag && userType && (userType === '1' || userType === '2')">
            <div class="form-group form-check">
                <input type="checkbox" name="isGlobalTag" id="isGlobalTag" class="form-check-input" [(ngModel)]="TagLibrary.isGlobalTag" #isGlobalTag="ngModel">
                <label for="isGlobalTag" class="form-check-label c-pointer">Add to workspace</label>
            </div>
        </div>
       
        <div class="row mt50 px-3">
            <div class="col-4 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                    (click)="cancleCTA();ResetForm(Tagform)">Cancel</button>

            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" [disabled]="submitted" *ngIf="!Id"><em
                        *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Tag</button>
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" [disabled]="submitted" *ngIf="Id"><em
                            *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Edit Tag</button>
            </div>
        </div>
    </form>




</div>

<!-- //deltemodal -->
<ng-template #exampleModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete this tag?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="DeleteTag()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
