import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { ApiService } from '../services/api_base/api.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-create-workspace',
    templateUrl: './create-workspace.component.html',
})
export class CreateWorkspaceComponent {
    public showNav = false;

    @Output('closesidemenu') public closesidemenu: EventEmitter<boolean> = new EventEmitter();

    public filterSidemenu: boolean;

    public userName = '';

    public adminFlag: boolean = false;

    public projectExist = false;

    public getWorkspace: any = [];

    public showCreate: boolean;

    public datas: any;

    public submitted = false;

    public ownerwscount = -1;

    public workspacePendingList = [];

    public firstTime = false;

    tagNavSwitch = '';

    public companyData: any;

    public constructor(private toastr: ToastrService, private router: Router, private workspaceService: WorkspaceServiceService,
        private api: ApiService, private userService: UserService, private activeRoute: ActivatedRoute) {
        this.activeRoute.queryParams.subscribe((params): any => {
            this.datas = params;
            if (this.datas && this.datas.name) {
                this.projectExist = false;
                this.getAllPendingInvites();
            } else {
                this.projectExist = true;
                this.getallWorkspace(0, 0);
            }
        });

        this.getProfile();

        this.getIsUserIsCompanyOwner();

        if (localStorage.getItem('email')) {
            // localStorage.removeItem('email');
        }

        if (localStorage.getItem('username')) {
            localStorage.removeItem('username');
        }
    }

    public getAllPendingInvites(): void {
        this.submitted = true;
        this.userService.getAllPendingList().subscribe((res): any => {
            if (res && res.status && res.status.status === 200 && res.entity && res.entity.length > 0) {
                this.submitted = false;
                this.workspacePendingList = res.entity;
                for (let i = 0; i < this.workspacePendingList.length; i++) {
                    for (let j = 0; j < this.workspacePendingList[i].pendingList.length; j++) {
                        this.workspacePendingList[i].pendingList[j].rejected = false;
                        this.workspacePendingList[i].pendingList[j].accepted = false;
                        this.workspacePendingList[i].pendingList[j].acceptreject = true;
                    }
                    if (this.workspacePendingList.length === (i + 1)) {
                        this.closeSidemenus();
                        this.getallWorkspace(0, 0);
                    }
                }
            } else {
                this.ownerwscount = 0;
                this.firstTime = true;
                this.getallWorkspace(0, 0);
            }
        });
    }

    public ToggleNav(value): any {
        if (value) {
            this.tagNavSwitch = value;
            this.showNav = !this.showNav;
        } else if (value == '' || value == undefined){
            this.tagNavSwitch = 'workspace';
            this.showNav = false;
        }
    }

    public back(): any {
        window.history.back();
    }

    public getallWorkspace(pageNumber, pageRecord): void {
        console.log('get workspace ');
        
        this.workspaceService.getAllWorkspace(pageNumber, pageRecord).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    console.log('ifffffffffffff');
                    
                    this.ownerwscount = res.wscount;
                    if (this.datas && this.datas.name && +res.wscount === 0) {
                        if (this.workspacePendingList && this.workspacePendingList.length === 0) {
                            this.firstTime = true;
                            this.createWorkspace();
                        }
                    } else {
                        this.getWorkspace = res.entity;
                    }
                } else if (this.datas && this.datas.name) {
                    console.log('else iffffffff');
                    
                    if (this.workspacePendingList && this.workspacePendingList.length === 0) {
                        this.firstTime = true;
                        this.createWorkspace();
                    } else if (!this.filterSidemenu) {
                        this.workspacePendingList = [];
                        this.firstTime = true;
                        this.createWorkspace();
                    }
                } else {
                    console.log('elseeeeeeeeeeeeeee');
                    this.firstTime = true;
                    this.filterSidemenu = false;
                    this.ownerwscount = 0;
                }
            },
            (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public getProfile(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
        };
        this.userService.viewProfile(payload).subscribe(
            (res): any => {
                if (res.entity.firstName) {
                    this.adminFlag = res.entity.adminFlag;
                    this.userName = res.entity.firstName;
                }
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public getIsUserIsCompanyOwner(): void {
        this.api.getIsUserIsCompanyOwner().subscribe(
            (res): any => {
                if (res?.companyId) {
                    this.companyData = res;
                }
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }

    public createWorkspace(): any {
        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: this.datas.name,
        };
        this.toastr.clear();
        this.workspaceService.addWorkspace(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.toastr.success('Thanks, your account setting completed');
                    sessionStorage.setItem('workspacename', res.entity.dbName);
                    sessionStorage.setItem('subscription', '1');
                    sessionStorage.setItem('usertype', '1');
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    public closeSidemenus(): void {
        this.filterSidemenu = !this.filterSidemenu;
        this.ownerwscount = 0;
        if (!this.filterSidemenu) {
            this.getallWorkspace(0,0);
        }
        
    }

    isClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.isClientUser(userEmail);
    }
    
    iskitchenSaverUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return !userEmail.toLowerCase().endsWith('@kitchensaver.com');
    }
    
    isBathSaverUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return !userEmail.toLowerCase().endsWith('@bathsaver.com');
    }
    
    isAllowedClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    }
    
    // shouldShowButton(): boolean {
    //     const userEmail = localStorage.getItem('email');
    
    //     return (
    //         (this.iskitchenSaverUser() && !this.isClientUser() && this.isBathSaverUser()) ||
    //         (this.firstTime && !this.isClientUser() && !this.iskitchenSaverUser() && !this.isAllowedClientUser() && !this.isBathSaverUser()) ||
    //         (!this.firstTime && (this.isClientUser() || this.isBathSaverUser()) && this.isAllowedClientUser())
    //     );
    // }

    shouldShowButton(): boolean {
        const userEmail = localStorage.getItem('email');
    
        const isAllowedClientUser = this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    
        return (
            isAllowedClientUser ||   // If the user is in the allowed lists
            (userEmail && !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !userEmail.toLowerCase().endsWith('@bathsaver.com'))
            // If the user's domain is not kitchensaver.com or bathsaver.com
        );
    }

    navigateCompanyProfile() {
        if (this.adminFlag) {
            this.router.navigate(['/company/profile']);
        } else {
            this.router.navigate(['/company/profile'], { queryParams: { name: this.companyData?.name }});
        }
    }
}
