<app-sub-header></app-sub-header>
<section class="authentication-layout">
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col-lg-7 col-md-6 col-sm-10 my-content mx-auto mx-md-0 order-1 order-md-0">
                <img src="./assets/images/otp-img.svg" alt="signup" class="banner-img-1">
            </div>
            <div class="col-lg-5 col-md-6 col-sm-10 mx-auto mx-md-0 my-content text-md-left">
                <h1 class="grey-a mb-3 font-weight-bold fs28 mb-3">One Time Password Verification</h1>
                <p class="fs11 grey-a ls-1 col-lg-8 col-md-10 pl-0">Enter the four-digit code sent to your {{source}}</p>
                <form name="form" [formGroup]="form" autocomplete="off" class=" mt-4 ">
                    <div class="row">
                        <div class="col-md-10 col-lg-8 col-sm-10 mx-auto mx-md-0  ">
                            <ng-otp-input #ngOtpInput value="otpValue" (onInputChange)="onOtpChange($event)" (keyup.enter)="onSubmit()" [config]="config"></ng-otp-input>
                            <p class="fs11 grey-a ls-1 my-4">Didn't receive OTP?</p>
                            <button [disabled]="submitted1" (click)="reSendOTP()" type="button" class="btn btn-grey-f blue-a  br25 pt7 pb7  fs14 fw-600 my-2 px-4 buttonload">
                                <em *ngIf="submitted1" class="fa fa-spinner fa-spin"></em>
                                Resend OTP</button>
                            <div class="row mt50">
                                <div class="col-lg-3 col-md-4 col-4 pr-1">
                                    <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button" (click)="goToBack()">Back</button>
                                </div>
                                <div class="col-lg-6 col-md-7 col-8 pl-1">
                                    <button (click)="onSubmit()"  class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                                        type="button"><em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>



            </div>
        </div>
    </div>

</section>
<app-sub-footer></app-sub-footer>
<app-reset-password [resetPasswordToggle]="showreset" (showresetevent)="ToggleResetpassword()"></app-reset-password>
