<ngx-loading-bar [color]="'#007bff'"></ngx-loading-bar>
<div class="admin-layout px-4">
    <!-- Header -->
    <app-header></app-header>
    <!-- Header -->
<div class="custom-tagsection container-fluid d-flex bg-dark px-0">
    <div class="tagsection-content w-100">
        <div class="modal-body p-0">

            <div class="modal-main-content position-relative h-90vh">
        
        
                <div class="d-flex align-items-center py-3 px-3 justify-content-between border-bottom-a">
                    <div class="d-flex align-items-center">
                        <div class="bg-white br15 w22 h22 text-center c-pointer mr-4" (click)="goBack()">
                            <img src='./assets/images/arrow-right-dark.svg' alt="arrow-right-dark" class="w10 mb3">
                        </div>
                        <h1 class="mb-0 text-white ls-9 fw-600 fs16" *ngIf="currentImage && currentImage.albumName">{{currentImage.albumName}}</h1>
                    </div>
        
                    <div class="d-flex ">
                        <img src="./assets/images/tags-filter.svg" alt="dark-menu" (click)="showTags()"
                            class="w20 show-modal-sidebar c-pointer mr-3">
                        <div dropdown class="dark-album-menu c-pointer ">
        
                            <div class="menu-dark-modal c-pointer" id="basic-link" dropdownToggle (click)="false"
                                aria-controls="basic-link-dropdown">
                                <img src="./assets/images/Group 8842.svg" alt="dark-menu" class="w17 mb12 ">
                            </div>
                            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu " role="menu"
                                aria-labelledby="basic-link">
                                <li>
                                    <a class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)"
                                        (click)="showPhotoInfo()">
                                        <img src="./assets/images/photo-info.svg" alt="settings" class="w14 mr-3">{{queryData?.mediaType == 'Video' ? 'Video' : 'Photo'}}
                                        Info</a>
                                </li>
                                <li>
                                    <a class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)"
                                        (click)="openModal1(MapviewModal)">
                                        <img src="./assets/images/map-view.svg" alt="map-view" class="w14 mr-3">Map
                                        View</a>
                                </li>
                                <li>
                                    <a (click)="downloadImage()" class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)">
                                        <img src="./assets/images/download-white.svg" alt="download-white"
                                            class="w14 mr-3">Download</a>
                                </li>
                                <li>
                                    <a routerLink="/help" [queryParams]="conCadSessionData({})" class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)">
                                        <img src="./assets/images/help-white.svg" alt="help-white" class="w14 mr-3">Help</a>
                                </li>
        
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between py-3 px-5">
                    <div>
                        <p class="mb-0 fs12 grey-e fw-600 ls-8">{{getDateValue(currentImage?.created) | date: 'MM/dd/yyyy hh:mm a'}}</p>
                    </div>
                </div>
                <div class="text-center overflow-auto" [ngClass]="{'no-pointer': compltedTagPage}"> 
                    <div id="imageHolder" class="dark-album-img-content">
                        <!-- cover image -->
                        <img #img *ngIf="currentImage?.media?.awskey && photoExtentions.includes(currentImage.media.awskey.split('.')[currentImage.media.awskey.split('.').length -1])" src="{{cloudFrontURL}}{{currentImage?.media?.awskey}}" alt="dark-img"  class="photo-tag-img"  id="myImgId" style="width: 100%;">
                        
                        <video class="br5" class=""  id="" height="300px" *ngIf="currentImage?.media && currentImage?.media?.awskey && videoExtentions.includes(currentImage.media.awskey.split('.')[currentImage.media.awskey.split('.').length -1])" controls>
                            <source src={{cloudFrontURL}}{{currentImage.media.awskey}} alt="profile-video" type="video/mp4">
                        </video>
                        <!-- cover image -->
                </div>
    
                
                    <ng-container *ngIf="!todoListPage">
                        <button type="button" (click)="getPrevImage()" class="position-absolute btn btn-blue btn-sm br25  fs14 fw-600 next-prev-btn left-center"><span class="lnr lnr-chevron-left"></span></button>
                        <button type="button" (click)="getNextImage()" class="position-absolute btn btn-blue btn-sm br25  fs14 fw-600 next-prev-btn right-center"><span class="lnr lnr-chevron-right"></span></button>
                    </ng-container>
                </div>
                


                <div class="pl-3 pb-3 carousel-secondry no-width-items" *ngIf="!todoListPage" [ngClass]="{'slide-up': toggleSliders}">
                   <div class="text-center">
                        <button type="button" class="mt-3 mb-4 btn btn-blue btn-sm br25 py-2 px-4 fs14 fw-600" (click)="toggleSliders = !toggleSliders">{{toggleSliders ? 'Hide' : 'Show'}} Slider</button>
                    </div>
                    <owl-carousel-o [options]="customOptions" class="w-100">
                       
                        <!-- <ng-container *ngFor="let photo of photosList[0]?.photoInfo">
                            <ng-template carouselSlide>
                              <div>
                                <img [src]="photo.media.awskey.endsWith('.mp4') ? './assets/images/video_thumbnail.png' : (cloudFrontURL + photo.media.awskey)"
                                  (click)="getPhotoBanner(photo.photoId)" alt="dark-img"
                                  class="dark-img w100 h100 br10 object-fit-cover c-pointer"
                                  [ngClass]="{'highlighted': photo.photoId === queryData.photoId}">
                              </div>
                            </ng-template>
                          </ng-container> -->

                          <ng-container *ngFor="let photo of photosList[0]?.photoInfo">
                            <ng-template carouselSlide>
                              <div>
                                <ng-container *ngIf="photo.media.awskey.endsWith('.mp4'); else imageTemplate">
                                  <video 
                                        width="130"
                                         (click)="getPhotoBanner(photo.photoId)"
                                         class="dark-img h100 br10 object-fit-cover c-pointer"
                                         [ngClass]="{'highlighted': photo.photoId === queryData.photoId}">
                                    <source [src]="cloudFrontURL + photo.media.awskey" type="video/mp4" />
                                    Browser not supported
                                  </video>
                                </ng-container>
                                <ng-template #imageTemplate>
                                  <img [src]="cloudFrontURL + photo.media.awskey"
                                       (click)="getPhotoBanner(photo.photoId)"
                                       alt="dark-img"
                                       class="dark-img w100 h100 br10 object-fit-cover c-pointer"
                                       [ngClass]="{'highlighted': photo.photoId === queryData.photoId}">
                                </ng-template>
                              </div>
                            </ng-template>
                          </ng-container>
                        
                        
                    </owl-carousel-o>
                </div>
        
        
        
            </div>
        
        </div>
        <div #sidenav class="modal-sidebar" [ngClass]="{'show-modal-sidebar' : tagShow  }">
            <div class="grey-z d-none">
                <em class="lnr lnr-cross text-white modal-sidebar-close fw-600 fs16 p-2" (click)="showTags()"></em>
        
            </div>
            <div *ngIf="getNext">
                <ul class="nav nav-pills pills-modal grey-z px-5 d-flex justify-content-between pt-3 mb-4" id="pills-tab"
                    role="tablist">
                    <li class="nav-item " role="presentation">
                        <a [class.active]="viewMode1=='tagFilter'" (click)="viewMode1='tagFilter'"
                            class="nav-link ls-d fs13 " href="javascript:void(0)">
                            <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg" alt="grid-active">
                            <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg" alt="grid-inactive">
                            Tag Filter</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a [class.active]="viewMode1=='tags'" (click)="viewMode1='tags'" class="ls-d nav-link fs13 "
                            href="javascript:void(0)">
                            <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg" alt="grid-active">
                            <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg" alt="grid-inactive">
                            Tags</a>
                    </li>
        
                </ul>
                <div class="tab-content pb-3" [ngSwitch]="viewMode1">
                    <div class="tab-pane fade show active" *ngSwitchCase="'tagFilter'">
                        <div class="contact-scroll px-4">
        
        
        
                            <div class="d-flex pt10 pb10 ">
        
                                <div class="tag-types w19 h19 pink-a">
        
                                </div>
                                <div class="flex-grow-1 ml-3">
                                    <h3 class="grey-aa ls-d  fs14 mb-1 fw-500">Comments</h3>
                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">Example of a custom tag</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch size="small"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex pt10 pb10 ">
        
                                <div class="tag-types  w19 h19 yellow-a">
        
                                </div>
                                <div class="flex-grow-1 ml-3">
                                    <h3 class="grey-aa ls-d  fs14 mb-1 fw-500">Task</h3>
                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">Example of a custom tag</p>
        
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch size="small"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex pt10 pb10 ">
        
                                <div class="tag-types  w19 h19 blue-f">
        
                                </div>
                                <div class="flex-grow-1 ml-3">
                                    <h3 class="grey-aa  ls-d fs14 mb-1 fw-500">Hot Potato</h3>
                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">Example of a custom tag</p>
        
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch size="small"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex pt10 pb10 ">
        
                                <img src="./assets/images/love-tag.svg" alt="love-tag" class="w19">
                                <div class="flex-grow-1 ml-3">
                                    <h3 class="grey-aa  ls-d fs14 mb-1 fw-500">Love</h3>
                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">Example of a custom tag</p>
        
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch size="small"></ui-switch>
                                </div>
                            </div>
                            <h1 class="mb-0 text-white ls-9 fw-600 fs16">{{currentImage?.albumName}}</h1>
                        </div>

                        <div class="d-flex ">
                            <img src="./assets/images/tags-filter.svg" alt="dark-menu" (click)="showTags()"
                                class="w20 show-modal-sidebar c-pointer mr-3">
                            <div dropdown class="dark-album-menu c-pointer ">

                                <div class="menu-dark-modal c-pointer" id="basic-link" dropdownToggle (click)="false"
                                    aria-controls="basic-link-dropdown">
                                    <img src="./assets/images/Group 8842.svg" alt="dark-menu" class="w17 mb12 ">
                                </div>
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu " role="menu"
                                    aria-labelledby="basic-link">
                                    <li>
                                        <a class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)"
                                            (click)="showPhotoInfo()">
                                            <img src="./assets/images/photo-info.svg" alt="settings"
                                                class="w14 mr-3">Photo
                                            Info</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)"
                                            (click)="openModal1(MapviewModal)">
                                            <img src="./assets/images/map-view.svg" alt="map-view" class="w14 mr-3">Map
                                            View</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item text-white fs13 fw-600 mb-1" href="javascript:void(0)">
                                            <img src="./assets/images/get-pdf.svg" alt="get-pdf" class="w14 mr-3">Get
                                            PDF</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item text-white fs13 fw-600 mb-1 c-pointer"
                                            (click)="downloadImage()" href="javascript:void(0)">
                                            <img src="./assets/images/download-white.svg" alt="download-white"
                                                class="w14 mr-3">Download</a>
                                    </li>
                                    <li>
                                        <a routerLink="/help" [queryParams]="conCadSessionData({})" class="dropdown-item text-white fs13 fw-600 mb-1"
                                            href="javascript:void(0)">
                                            <img src="./assets/images/help-white.svg" alt="help-white"
                                                class="w14 mr-3">Help</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between py-3 px-5">
                        <div>
                            <h2 class="fs15 grey-e ls-g fw-600 mb-1">{{currentImage?.photoName}}</h2>
                            <p class="mb-0 fs12 grey-e fw-600 ls-8">{{getDateValue(currentImage?.created)}}</p>
                        </div>
                        <div>
                            <img src="./assets/images/compass.svg" alt="compass" class="w24">
                        </div>
                    </div>
                    <div class="text-center overflow-auto">
                        <div class="dark-album-img-content" id="imageHolder">
                            <!-- cover image -->
                            <img src="{{cloudFrontURL}}{{currentImage?.media?.awskey}}" alt="dark-img"
                                class="photo-tag-img" id="myImgId">
                            <!-- cover image -->


                        </div>
                    </div>
                </div>

            </div>
            <div #sidenav class="modal-sidebar" [ngClass]="{'show-modal-sidebar' : tagShow  }">
                <div class="grey-z d-none">
                    <em class="lnr lnr-cross text-white modal-sidebar-close fw-600 fs16 p-2" (click)="showTags()"></em>

                </div>
                <div *ngIf="getNext">
                    <ul class="nav nav-pills pills-modal grey-z px-5 d-flex justify-content-between pt-3 mb-4"
                        id="pills-tab" role="tablist">
                        <li class="nav-item " role="presentation">
                            <a [class.active]="viewMode1=='tagFilter'" (click)="!submitted && viewMode1='tagFilter'"
                                class="nav-link ls-d fs13 " href="javascript:void(0)">
                                <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg"
                                    alt="grid-active">
                                <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg"
                                    alt="grid-inactive">
                                Tag Filter</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a [class.active]="viewMode1=='tags'" (click)="!submitted && viewMode1='tags'; getPlotingTags()"
                                class="ls-d nav-link fs13 " href="javascript:void(0)">
                                <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg"
                                    alt="grid-active">
                                <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg"
                                    alt="grid-inactive">
                                Tags</a>
                        </li>

                    </ul>
                    <div class="tab-content pb-3" [ngSwitch]="viewMode1">
                        <div class="tab-pane fade show active" *ngSwitchCase="'tagFilter'">
                            <div class="contact-scroll px-4">

                                <ng-container *ngFor="let photoTags of filteredPlotArr">
                                    <div class="d-flex pt10 pb10" *ngIf="photoTags.id">
                                             <div>
                                                 <div class="tag-types w-22px h-22px text-white d-flex align-items-center justify-content-center fs12"
                                                     [ngStyle]="{'background': '#' + photoTags?.tagtype?.tag?.color, 'color': calcContrastColor(photoTags?.tagtype?.tag?.color), 'border-color': calcContrastColor(photoTags?.tagtype?.tag?.color)}">
                                                 </div>
                                             </div>
                                             <div class="flex-grow-1 ml12">
                                                 <h3 class="grey-aa ls-d fs14 mt-1 fw-500">{{photoTags?.tagtype?.tag?.tagName}}
                                                 </h3>
                                             </div>
                                             <div class="custom-switchtoggle">
                                                 <ui-switch size="small" [checked]="photoTags.active"
                                                     (change)="changeTagToggle(photoTags, $event)"></ui-switch>
                                             </div>
                                     </div>
                                </ng-container>
                                
                                <div *ngIf="(!plotArr || plotArr.length === 0) && !submitted" class="text-white text-center">
                                    It's seems no tags available
                                </div>
                            </div>
                            <div class="px-4" *ngIf="plotArr.length > 0">
                                <div hidden class="range-slider d-flex py-3 px-2 mt-4 align-items-center">
                                    
                                    <div>
                                        <div class="grey-ac w9 h9 border border-white-b br3">
                                        </div>
                                    </div>
                                    <input type="range" min="11" max="45" value="22" (change)="tagSizeChange($event)" class="w-100 mx-2">
                                    <div>
                                        <div class="grey-ac w12 h12 border border-white-b br4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'tags'">
                            <div class="contact-scroll px-4 pb-5 max-h-80vh">
                                <ng-container *ngFor="let tagList of plotArr">
                                    <div class="d-flex pt10 pb10" *ngIf="tagList?.active">
                                        <ng-container *ngIf="tagList.id">
                                            <div>
                                                <div class="tag-types w-22px h-22px d-flex align-items-center justify-content-center fs12"
                                                    [ngStyle]="{'background': '#' + tagList?.tagtype?.tag?.color, 'color': calcContrastColor(tagList?.tagtype?.tag?.color), 'border-color': calcContrastColor(tagList?.tagtype?.tag?.color)}">
                                                    
                                                    {{tagList.label}}
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ml12">
                                                <h3 class="grey-aa ls-d  fs14 mb-1 fw-500">
                                                    {{tagList?.tagtype?.tag?.tagName}}</h3>
                                                <p class="mb-0 grey-e fs11 ls-8 fw-500">{{tagList?.tagtype?.tag?.purpose}}
                                                </p>
                                                <p class="mb-0 grey-e fs11 ls-8 fw-500">
                                                    <img src="./assets/images/message-active.svg" alt="message-active"
                                                        class="w12 mr-1 active-img">
                                                        <span *ngIf="tagList">{{tagList.count}}</span>
                                                        <span *ngIf="!tagList">0</span>
                                                </p>
                                            </div>
                                            <div>
                                                <div class="grey-z h22 w22 br15 text-center c-pointer" (click)="showNext(tagList)">
                                                    <img src="./assets/images/right-chevron.svg" alt="right-chevron"
                                                        class="w5 ml2 mb2">

                                                </div>
                                            </div>
                                        </ng-container>
                                   

                                    </div>
                                </ng-container>
                                <div *ngIf="(!plotArr || plotArr.length === 0) && !submitted" class="text-white text-center">
                                    It's seems no tags available
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!getNext">
                    <!-- this ngif to show the assign to section -->
                    <div *ngIf="!AssignTo">
                        <div class="d-flex align-items-center px-3 py-3 justify-content-between grey-z">
                            <div class="d-flex align-items-center ">
                                <div class="grey-y w22 h22 br15 text-center c-pointer mr-3" *ngIf="!compltedTagPage" (click)="getNext = true">
                                    <img src="./assets/images/white-left-arrow.svg" alt="white-left-arrow"
                                        class="w11 mb6">
                                </div>
                                <h1 class="fs15 text-white fw-600 ls-g mb-0">
                                    
                                    <div class="tag-types w-22px h-22px d-inline-flex align-items-center justify-content-center fs12"
                                    [ngStyle]="{'background':  sidemenuPhotoTagId?.color, 'color': calcContrastColor(sidemenuPhotoTagId?.color), 'border-color': calcContrastColor(sidemenuPhotoTagId?.color)}">
                                                    
                                        {{sidemenuPhotoTagId.label}}
                                    </div>
                                    &nbsp;-&nbsp;
                                    {{selectedTagName}} 
                                   
                                </h1>
                            </div>
                        </div>
                        <ul class="nav nav-pills pills-modal grey-z  d-flex justify-content-between pt-3 mb-4 px-2"
                            id="pills-tab" role="tablist">
                            <li class="nav-item " role="presentation">
                                <a [class.active]="viewMode2=='Comments'" (click)="viewMode2='Comments'"
                                    class="nav-link ls-d fs13 " href="javascript:void(0)">
                                    <img src="./assets/images/message.svg" alt="settings"
                                        class="w12 mr-1 in-active-img">
                                    <img src="./assets/images/message-active.svg" alt="settings"
                                        class="w12 mr-1 active-img">
                                    Comments</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class.active]="viewMode2=='Assign'" (click)="viewMode2='Assign'"
                                    class="ls-d nav-link fs13 " href="javascript:void(0)">
                                    <img src="./assets/images/assign.svg" alt="assign" class="w11 active-img mr-1">
                                    <img src="./assets/images/assign-inactive.svg" alt="assign"
                                        class="w11 in-active-img mr-1">

                                    Assign</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class.active]="viewMode2=='Attach'" (click)="viewMode2='Attach'"
                                    class="ls-d nav-link fs13 " href="javascript:void(0)">
                                    <img src="./assets/images/attach-active.svg" alt="assign"
                                        class="active-img w12 mr-1">
                                    <img src="./assets/images/attach.svg" alt="assign" class="in-active-img w12 mr-1">

                                    Attach</a>
                            </li>

                        </ul>
                        <div class="tab-content pb-3 " [ngSwitch]="viewMode2">
                            <div class="tab-pane fade show  active" *ngSwitchCase="'Comments'">
                                <div #scrollMe [scrollTop]="scrollHeight" class="dark-sidebar-scroll px-3 h-70vh">
                                    <div class="mb-4" *ngFor="let comments of currentCommentsList">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="d-flex align-items-center">                                        
                                                <img  src="./assets/images/empty-img.png"
                                                *ngIf="!comments || !comments.profileImage" alt="comments"
                                                class="w30 h30 br20 object-fit-cover mr-3">
                                                <img  src="{{cloudFrontURL}}{{comments.profileImage}}"
                                                *ngIf="comments && comments.profileImage" alt="comments"
                                                    class="w30 h30 br20 object-fit-cover mr-3">
                                                <h1 class="font-weight-bold fs14 grey-e mb-0">{{comments.firstName}} {{comments.lastName}}</h1>
                                            </div>
                                            <div>
                                                <p *ngIf="datePipe.transform(comments.created, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')" class="mb-0 grey-l fs11">{{getDateValue(comments.created) | amTimeAgo }}</p>
                                                <p *ngIf="datePipe.transform(comments.created, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')" class="mb-0 grey-l fs11">{{getDateValue(comments.created) | amDateFormat: 'MM/DD/YYYY ' }}</p>
                                            </div>
                                        </div>

                                        <pre *ngIf="comments.text" class="fw-500 mb-0 fs14 grey-e pl-5 mb-2 white-space-prewrap">{{comments.text}}</pre>

                                        <div *ngIf="comments.attachment && comments.attachment.awskey" class="audio-div pl-5 pr-2 mt-2">
                                            <audio controls controlsList="nodownload">
                                                <source src="{{cloudFrontURL}}{{comments.attachment.awskey}}" type="audio/mpeg">
                                                Your browser does not support the audio element.
                                              </audio>
                                        </div>
                                       
                                    </div>

                                    <div *ngIf="(!currentCommentsList || currentCommentsList.length === 0) && !submitted" class="text-white text-center">
                                        It's seems no comments available
                                    </div>
                                </div>
                                <div class="dark-input w-100 p-3" *ngIf="!restrictCompletedTag">
                                    <div class="input-group dark-input-rounded mb-0 c-pointer">
                                        <textarea type="text" autosize [minRows]="1" [maxRows]="3" class="form-control fw-500 py-2 border-right-0 resize-none" [(ngModel)]="note"
                                        
                                            placeholder="Type Your Comments"></textarea>
                                        <div class="input-group-append">
                                            <span class="input-group-text border-left-0 " (click)='!submitted && addDescription(sidemenuPhotoTagId.id)'>
                                                <em
                                                class="far fa-paper-plane fs12"></em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" *ngSwitchCase="'Assign'">
                                <div class="dark-sidebar-scroll">

                                    <div class="d-flex align-items-center justify-content-between grey-z p-3 mb-3">
                                        <div class="d-flex align-items-center">
                                            <h1 class="font-weight-bold fs14 grey-e mb-0">Assigned</h1>
                                        </div>
                                    </div>
                                   
                                    <div class="mb-4 px-3" *ngFor="let assignedDataList of assignedUsersList">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="d-flex align-items-center">
                                                <img src="./assets/images/empty-img.png" *ngIf="assignedDataList.profileImage === null" alt="comments"
                                                    class="w30 h30 br20 object-fit-cover mr-3">
                                                <img src="{{cloudFrontURL}}{{assignedDataList.profileImage}}" *ngIf="assignedDataList.profileImage" alt="comments"
                                                class="w30 h30 br20 object-fit-cover mr-3">
                                                
                                                <div>
                                                    <h1 class="font-weight-bold fs14 grey-e mb-0">{{assignedDataList.firstName}} {{assignedDataList.lastName}}</h1>
                                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">{{assignedDataList?.email}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="mb-0 grey-l fs11">{{ assignedDataList.assignedDate | amTimeAgo }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="(!assignedUsersList || assignedUsersList.length === 0) && !submitted" class="text-white text-center">
                                        It's seems there is no assigned users
                                    </div>


                                    <!-- Completed by -->
                                    <ng-container *ngIf="(completedUsersList && completedUsersList.length > 0) && !submitted">
                                    <div class="d-flex align-items-center justify-content-between grey-z p-3 mb-3">
                                        <div class="d-flex align-items-center">
                                            <h1 class="font-weight-bold fs14 grey-e mb-0">Completed</h1>
                                        </div>
                                    </div>
                                    <div class="mb-4 px-3" *ngFor="let completedDataList of completedUsersList">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="d-flex align-items-center">
                                                <img src="./assets/images/empty-img.png" *ngIf="completedDataList.profileImage === null" alt="comments"
                                                    class="w30 h30 br20 object-fit-cover mr-3">
                                                <img src="{{cloudFrontURL}}{{completedDataList.profileImage}}" *ngIf="completedDataList.profileImage !== null" alt="comments"
                                                class="w30 h30 br20 object-fit-cover mr-3">
                                                <div>
                                                    <h1 class="font-weight-bold fs14 grey-e mb-0">{{completedDataList.firstName}} {{completedDataList.lastName}}</h1>
                                                    <p class="mb-0 grey-e fs11 ls-8 fw-500">{{completedDataList?.email}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="mb-0 grey-l fs11">{{ completedDataList.completedDate | amTimeAgo }}</p>
                                            </div>
                                        </div>
                                    </div>
                                 </ng-container>
                                </div>
                                <div class="row dark-btn w-100 mx-auto" *ngIf="!restrictCompletedTag && !completeTaskSection">
                                    <div class="col-9 mx-auto">
                                        <button (click)="showAssign()"
                                            class="btn btn-block py-2 fs12 font-weight-bold btn-blue br25 text-uppercase mb-3">
                                            ASSIGN TO
                                        </button>
                                    </div>
                                </div>

                                <div class="row dark-btn w-100 mx-auto" *ngIf="completeTaskSection">
                                    <div class="col-9 mx-auto">
                                       
                                        <input id="taptocompletes" type="radio" (change)="changeStatusToDo($event)"
                                            [checked]="completedTask" class="d-none" />
                                        <label class="btn btn-block py-2 fs14 font-weight-bold btn-success br25 pointer-events-none mb-3" *ngIf="completedTask" >
                                            Task Completed
                                        </label>
                                        <label  class="btn btn-block py-2 fs14 font-weight-bold btn-blue-outline br25  mb-3" *ngIf="!completedTask" for="taptocompletes">
                                            Click to Complete
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" *ngSwitchCase="'Attach'">
                                <div class="dark-sidebar-scroll">
                                    <div class="row mx-0 px-3">
                                        <div class="col-4 px-2 mb-3" *ngFor="let selectattach of selectedImage; let i=index">
                                            <div class="attach-section">
                                                <div class="w-100 h-100  br5 object-fit-cover default-loader d-flex align-items-center justify-content-center text-center">
                                                    <div>
                                                        <span class="fas fa-spinner fa-spin"></span>
                                                        <h3 class="fs12 font-weight-bold mb-0 text-dark">Uploading - {{roundValue(selectattach.percentage)}}%</h3>
                                                    </div>
                                                    <progressbar *ngIf="selectattach.valid" class="prgrs-bg loading-prog" max="100"
                                                        [value]="selectattach.percentage"> 
                                                    </progressbar>
                                                </div>
                                                <div class="close-img-dark text-center c-pointer" (click)="!submitted && removeSelectImage(i)">
                                                    <img src="./assets/images/cross-white.svg" alt="cross"
                                                        class="w9 mb8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mx-0 px-3">
                                        <div class="col-4 px-2 mb-3" *ngFor="let attachlist of attachmentsList">
                                            <div class="attach-section">
                                                <img src="{{cloudFrontURL}}{{attachlist.attachment.awskey}}" alt="comments"
                                                    class="w-100 h-100  br5 object-fit-cover " loading="lazy" (click)="viewFullImage(attachlist.attachment.awskey)">
                                                <div class="close-img-dark text-center c-pointer" (click)="!submitted && removeattachment(attachlist.noteId)">
                                                    <img src="./assets/images/cross-white.svg" alt="cross"
                                                        class="w9 mb8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="(!attachmentsList || attachmentsList.length === 0) && (!selectattach || selectattach.length === 0) && !submitted" class="text-white text-center">
                                        It's seems there is no attachments 
                                    </div>
                                </div>
                                <div class="dark-btn w-100" *ngIf="!restrictCompletedTag">
                                    <div class="col-9 mx-auto">
                                        <label [ngClass]="{'disabled-label': submitted}" class="btn  btn-block  py-2 fs12 font-weight-bold btn-blue br25 text-uppercase mb-3">
                                            <input multiple  type="file" accept=".png, .jpg, .jpeg" class="d-none" (change)="!submitted && initiateUpload($event)">
                                            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em> ATTACH
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="AssignTo">
                        <div class="d-flex align-items-center px-3 py-3 grey-z">
                            <div class="grey-y w22 h22 br15 text-center c-pointer mr-3" (click)="showAssign()">
                                <img src="./assets/images/white-left-arrow.svg" alt="white-left-arrow" class="w11 mb6">
                            </div>
                            <h1 class="fs15 text-white fw-600 ls-g mb-0">Assign To</h1>
                        </div>
                        <div class="mt-3 px-4">
                            <div class="input-group input-todo ">
                                <input type="text" class="form-control fw-600 pt17 pb17  border-right-0 "  [(ngModel)]="usernamesearch"
                                    placeholder="Search by name">
                                <div class="input-group-append" *ngIf="usernamesearch !== ''">
                                    <span class="input-group-text border-left-0 p-1" (click)="clearUserSearchData()">
                                        <span class="lnr lnr-cross fs16"></span>
                                    </span>
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text border-left-0 ">
                                        <img src="./assets/images/search-todo.svg" alt="search" class="w13">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="contact-scroll mt-3 px-4 ">
                            <div class="tag-list mt-4" *ngFor="let listtag of peopleListsGroup  | searchuser: usernamesearch; let ii = index">
                                <div class="contact-initial-alphabet ml-1 mb-2">
                                    <h3 class="grey-m fw-600 fs12 mb-0">{{listtag.letter}}</h3>
                                </div>                                
                                <ng-container *ngFor="let Tag of listtag.names; let iii = index">
                                    <div
                                        class="d-flex align-items-center justify-content-between border-grey-i py-2">
                                            <div class="d-flex align-items-center w-100">                                            
                                                <div>
                                                    <img src="{{cloudFrontURL}}{{Tag.user.profileImage}}" *ngIf="Tag.user.profileImage" alt="user1"
                                                    class="people-img mr-4 w32 h32">
                                                    <img src="./assets/images/empty-img.png" *ngIf="!Tag || !Tag.user || !Tag.user.profileImage" alt="user1"
                                                        class="people-img mr-4 w32 h32">
                                                </div>
                                                <div>
                                                    <h1 class="grey-e fs12 fw-600 mb-0 text-capitalize">{{Tag.user.userName}}</h1>
                                                    <p class="mb-0 grey-l fs11">{{ Tag.user.email }}</p>
                                                    <p *ngIf="Tag.user.id === userId" class="mb-0 grey-l fs11">You</p>
                                                </div>                                           
                                                <div class="ml-auto mr-1">
                                                    <input type="checkbox"
                                                        (change)="selectUnselectUser($event, Tag.user.id, sidemenuPhotoTagId.id, 'fromsidemenu', ii, iii)"
                                                        [checked]="Tag.isCheck"  name="radioNoLabel" id="radioNoLabel1"
                                                        class="c-pointer">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                            
                                <div *ngIf="peopleListsGroup.length === 0 && !submitted" class="text-white text-center">
                                    It's seems there is no users available 
                                </div>
                              
                            </div>
                        </div>

                </div>


            </div>
        </div>


    </div>

</div>



<div class="modal fade" bsModal #staticModal="bs-modal" [config]="modalBackDrop" (onHide)="modalOnHide($event)" tabindex="-1" role="dialog"
   >
    <div class="modal-dialog modal-sm mx-md-0 lf-ryt-middle"
        [ngStyle]="{'top.px': currentPosition?.top + 200, 'left.px': currentPosition?.left + 350}">
        <div class="modal-content bg-transparent border-0 grabber " [style.width.px]='width'>
            <div>
                <button type="button" class="btn btn-danger mb-1 px-2 py-0 float-right" 
                    (click)="deletePhotoTag()">
                    <span class="lnr lnr-trash fs12"></span>
                </button>
            </div>
            <form name="form" (ngSubmit)="tagForm.form.valid && addPhotoTag()" #tagForm="ngForm" novalidate>

                <div class="modal-body p-0 photo-tag-tabs overflow-hidden bg-white">
                    <tabset [vertical]="true" type="pills" #staticTabs>
                        <tab>
                            <ng-template tabHeading>
                                <img src="./assets/images/modal-icons/comments-active-modal.svg"
                                    class="tab-icons active-icon" alt="comment">
                                <img src="./assets/images/modal-icons/comments-inactive-modal.svg"
                                    class="tab-icons inactive-icon" alt="comment">
                            </ng-template>

                            <!-- add comment section -->
                            <div *ngIf="!editData">
                                <label class="fw-600">Add Comment</label>
                                <textarea class="form-control" name="description" tabindex="1"
                                    [(ngModel)]="tagFormData.description.text" #description="ngModel"
                                    placeholder="Enter your comment here"
                                    rows="5"></textarea>
                            </div>
                            <!-- /add comment section -->

                            <!-- after added comment section -->
                            <ng-container *ngIf="editData">
                                <div class="chat-window">
                                    <div class="d-flex" *ngFor="let comment of currentCommentsList">
                                        <img src="./assets/images/empty-img.png" *ngIf="comment.profileImage === null"
                                            class="user-tag-image rounded-circle fw-600" alt="user-image" />
                                        <img src="{{cloudFrontURL}}{{comment.profileImage}}"
                                            *ngIf="comment.profileImage !== null"
                                            class="user-tag-image rounded-circle fw-600" alt="user-image" />

                                        <div class="pl-2">
                                            <h1 class="fs16 grey-m my-1">{{comment.firstName}} {{comment.lastName}}</h1>
                                            <pre class="fs12 grey-r white-space-prewrap">{{comment.text}}</pre>
                                        </div>                                        
                                    </div>
                                    <div *ngIf="(!currentCommentsList || currentCommentsList.length === 0) && !submitted" class="text-blue text-center">
                                        It's seems no comments available
                                    </div>
                                </div>

                                <div class="input-group send-message-input pt-2" [ngClass]="{'no-pointer': restrictCompletedTag}">
                                    <textarea autosize [minRows]="1" [maxRows]="3" name="text" [(ngModel)]="note"
                                        class="form-control border-right-0" placeholder="Type Your Comments" [readonly]="restrictCompletedTag"></textarea>
                                    <div class="input-group-prepend">
                                        <button type="button" (click)='!submitted && addDescription(currentPosition.id)'
                                            class="btn btn-white border-left-0 h-100" type="button"><em
                                                class="far fa-paper-plane fs12"></em></button>
                                    </div>
                                </div>

                            </ng-container>
                            <!-- after added comment section -->
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <img src="./assets/images/modal-icons/tag-active-modal.svg"
                                    class="tab-icons active-icon" alt="tag">
                                <img src="./assets/images/modal-icons/tag-inactive-modal.svg"
                                    class="tab-icons inactive-icon" alt="tag">
                            </ng-template>
                            <h1 class="fw-600 fs16 mt-0">Add Tags</h1>

                            <div class="my-2">
                                <div class="input-group input-todo ">
                                    <input type="text" class="form-control fw-600 pt17 pb17  border-right-0" [(ngModel)]="searchField" [ngModelOptions]="{standalone: true}"  (keyup)="searchData($event, 'tagsearch')"
                                        placeholder="Search by Tag name">
                                   
                                    <div class="input-group-append" *ngIf="searchField !== ''">
                                        <span class="input-group-text border-left-0 p-1" (click)="clearSearchData()">
                                            <span class="lnr lnr-cross fs16"></span>
                                        </span>
                                    </div>
                                    <div class="input-group-append">
                                        <span class="input-group-text border-left-0 ">
                                            <img src="./assets/images/search-todo.svg" alt="search" class="w13 c-pointer">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="list-of-users chat-window" [ngClass]="{'no-pointer': restrictCompletedTag}">
                            <div class="modal-tag-choose" [ngClass]="{'no-pointer': !tag.active}" *ngFor="let tag of Tags | searchuser: tagsearch; let i = index">
                                <input id="choosetag-{{tag.id}}" type="radio" name="tagTypeId"
                                    [(ngModel)]="tagFormData.tagTypeId" (change)="editTagColor(tag)" #tagTypeId="ngModel" [value]="tag.id"
                                    class="d-none"  />
                                <label for="choosetag-{{tag.id}}" class="d-flex align-items-center my-0 mr-1 p-2" [ngClass]="{'tag-disabled': !tag.active}" style="word-break: break-all;">
                                    <div class="tag-types w19 h19"
                                        [ngStyle]="{'background-color': (tag.color && tag.color.startsWith('#')) ? tag.color : (tag.color ? '#' + tag.color : defaultcolor)}">
                                    </div>
                                    <div class="flex-grow-1 ml-2" style="width: -webkit-fill-available">
                                        <h2 class="fs14 mb-0 fw-500">{{tag.title}}</h2>
                                    </div>
                                </label>
                            </div>
                        </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading tabindex="2">
                                <img src="./assets/images/modal-icons/users-active-modal.svg"
                                    class="tab-icons active-icon" alt="users">
                                <img src="./assets/images/modal-icons/users-inactive-modal.svg"
                                    class="tab-icons inactive-icon" alt="users">
                            </ng-template>

                            <!-- assign users -->
                            <div *ngIf="!completeTaskSection">

                                <h1 class="fw-600 fs16 mt-0">Assign Users</h1>

                                <div class="my-2">
                                    <div class="input-group input-todo ">
                                        <input type="text" class="form-control fw-600 pt17 pb17  border-right-0" [(ngModel)]="searchUserField" [ngModelOptions]="{standalone: true}"   (keyup)="searchData($event, 'usersearch')"
                                            placeholder="Search by name">
                                        <div class="input-group-append" *ngIf="searchUserField !== ''">
                                            <span class="input-group-text border-left-0 p-1" (click)="clearUserSearchData()">
                                                <span class="lnr lnr-cross fs16"></span>
                                            </span>
                                        </div>
                                        <div class="input-group-append">
                                            <span class="input-group-text border-left-0 ">
                                                <img src="./assets/images/search-todo.svg" alt="search" class="w13 c-pointer">
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="list-of-users chat-window" [ngClass]="{'no-pointer': restrictCompletedTag}">
                                    <div class="pt8 pb8 modal-tag-choose" *ngFor="let people of peopleLists  | searchuser: assignusersearch; let p = index">
                                        <input id="checkuser{{people.user.id}}" type="checkbox" name="users"
                                            (change)="selectUnselectUser($event, people.user.id, currentPosition.id, '', p, 0)"
                                            [checked]="people.isCheck" class="d-none" />
                                        <label for="checkuser{{people.user.id}}" class="d-flex align-items-center mb-0">
                                           <img src="{{cloudFrontURL}}{{people.user.profileImage}}"
                                                class="user-tag-image rounded-circle" *ngIf="people.user.profileImage !== null" alt="user-image" />
                                            <img src="./assets/images/empty-img.png" *ngIf="people.user.profileImage === null"
                                            class="user-tag-image rounded-circle" alt="user-image" />
                                            <div class="flex-grow-1 ml-2">
                                                <h2 class="fs14 mb-0 fw-500">{{people.user.firstName}}
                                                    {{people.user.lastName}}</h2>
                                                    <div class="fs12 grey-r mb-0 fw-500">{{people.user.email}}</div>
                                                    <div *ngIf="people.user.id === userId" class="fs12 grey-r mb-0 fw-500">You</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div *ngIf="peopleLists && peopleLists.length === 0" class="text-center">
                                        It's seems there is no users available 
                                    </div>
                                </div>
                            </div>
                            <!-- /assign users -->

                            <div *ngIf="completeTaskSection">
                                <div class="text-center">
                                    <div class="taptocomplete-checkbox mt-3 mb-4">
                                        <input id="taptocomplete" type="radio" (change)="changeStatusToDo($event)"
                                            [checked]="completedTask" class="d-none" />
                                        <label *ngIf="completedTask" for="taptocomplete" class="mb-0 mx-auto rounded-circle c-pointer" style.background-color="{{currentPosition.color}}">
                                            <img src="./assets/images/check-white.svg" alt="check">
                                        </label>
                                        <label *ngIf="!completedTask" for="taptocomplete" class="mb-0 mx-auto rounded-circle" style.background-color="{{currentPosition.color}}">
                                            <span class="text-white">{{currentPosition.label}}</span>
                                        </label>
                                    </div>

                                    <h1 class="fs14 font-weight-bold">Tap to Complete</h1>
                                    <p class="fs14 fw-500">For</p>
                                </div>
    
                                    <ul class="taptocomplete-list list-unstyled">
                                        <li *ngFor="let assignedList of assignedUsersList">
                                            <div class="d-flex align-items-center my-3 px-1">
                                                <img src="./assets/images/empty-img.png" *ngIf="assignedList.profileImage === null" class="user-tag-image rounded-circle" alt="user-image" />
                                                <img src="{{cloudFrontURL}}{{assignedList.profileImage}}" *ngIf="assignedList.profileImage" class="user-tag-image rounded-circle" alt="user-image" />
                                                
                                                <div class="flex-grow-1 ml-2">
                                                    <h2 class="fs16 mb-0 fw-500 mt-1">{{assignedList.firstName}} {{assignedList.lastName}}</h2>
                                                    <p class="grey-r fs12 mb-0">{{assignedList.roleName}} | {{ returnAsDate(assignedList.assignedDate) }}</p>
                                                </div>    
                                            </div>
                                            <p class="fs14 fw-500 text-center mb-3" *ngIf="assignedList.completed">Completed</p>
                                        </li>
            
                                    </ul>
                               
                                
                            </div>

                        </tab>
                    </tabset>


                </div>
                <div class="modal-footer p-2 border-0 bg-white">
                    <button [disabled]="submitted" type="submit" *ngIf="!editData" class="btn btn-blue btn-block br-25 buttonload">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Place Tags
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- photo info -->
<div id="overlay" [ngClass]="{'d-block': PhotoInfoScreen}"></div>

<div #sidenav class="dark-right-sidebar  grey-y" [ngClass]="{'show-side-nav' : PhotoInfoScreen}">
    <!-- for invite -->


    <div class="d-flex brtlr-15 justify-content-between grey-z py-4 px-4">
        <h1 class="text-white fs17 font-weight-bold ls-2 mb-0">{{mediaFormat}} Info</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showPhotoInfo()"></em>
    </div>

    <div class="contact-scroll">
        <form class="mt-4 px-4">
            <div class="form-group dark-input-rounded-sub">
                <h2 class="fs12 grey-aa ls-3 mb-1 fw-500">Album Name</h2>
                <p class="text-white fw-500 fs14 mb-0">{{currentImage?.albumName}}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center my-3">
                <div>
                    <h2 class="fs12 grey-aa ls-3 mb-1 fw-500">Location</h2>
                    <p class="text-white fw-500 fs14 mb-0">{{currentImage?.lattitude}}, {{currentImage?.longitude}}</p>
                </div>
            </div>
            <h2 class="fs12 grey-aa ls-3 mb-1 fw-500 mt-3">Created</h2>
            <p class="text-white fw-500 fs14 mb-3">{{getDateValue(currentImage?.created) | date: 'MMM dd yyyy, hh:mm a'}}
            </p>

            <h2 class="fs12 grey-aa ls-3 mb-1 fw-500 mt-3">Author</h2>
            <p class="text-white fw-500 fs14 mb-3" *ngIf="currentImage && currentImage.author">{{currentImage.author.firstName}} {{currentImage.author.lastName}}</p>

            <h2 class="fs12 grey-aa ls-3 mb-1 fw-500 my-3">Actions</h2>

            <button (click)="openModal3()" class="btn btn-red-h br9 pt7 pb7  btn-block fs13 fw-600 ">Delete</button>
        </form>
    </div>

</div>

<!-- map view Modal -->
<ng-template #MapviewModal>

    <div class="modal-body p-0">
        <div class="album-map ">
            <div class="map-close text-center c-pointer" (click)="modalRef.hide()">
                <img src="./assets/images/cross.svg" alt="cross" class="w11">
            </div>
            <agm-map [latitude]="+currentImage?.lattitude" [longitude]="+currentImage?.longitude" [streetViewControl]="false" class="map-view w-100 br10"
                [disableDefaultUI]="true" [zoom]="7" [usePanning]="true" [zoomControl]="true">
                <agm-marker [latitude]="+currentImage?.lattitude" [longitude]="+currentImage?.longitude"
                    [markerDraggable]="false" iconUrl='./assets/images/red_dot.svg'>
                    <agm-info-window [isOpen]="true">
                        <img src="{{cloudFrontURL}}{{currentImage?.media?.awskey}}" alt="photo" class="w50">
                    </agm-info-window>
                </agm-marker>
                <agm-direction [markerOptions]="markerOptions" [origin]="origin" [renderOptions]="renderOptions"
                    [destination]="destination">
                </agm-direction>
            </agm-map>

            <div class="d-flex justify-content-end get-direction">
                <button (click)="getDirection()" class="btn  btn-blue-secondry px-sm-5 px-4 br25 py-2 fs12 mr-3"><img
                        src="./assets/images/direction.svg" alt="direction" class="mr-1 w11 mb-1">Get
                    direction from my location</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #photodeleteModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete {{queryData?.mediaType ? queryData?.mediaType : 'Photo'}}?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethodDelete()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- invite -->

<div id="overlay" [ngClass]="{'d-block': Invite}"></div>

<div #sidenav class="dark-info-sidebar  grey-y" [ngClass]="{'show-side-nav' : Invite}">


    <div class="d-flex brtlr-15 justify-content-between grey-z py-4 px-4">
        <h1 class="text-white fs17 font-weight-bold ls-2 mb-0">Invite</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showInvite()"></em>
    </div>
    <div class="contact-scroll">
        <form class="mt-3 px-4" [formGroup]='shareForm' novalidate>
            <label class="fs12 grey-j ls-3">Name</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group dark-input-rounded-sub">

                        <input type="text" class="form-control br8 pt22 pb22 fw-600" placeholder="First Name"
                        formControlName="firstName" tabindex=1
                        (keydown.space)="shareForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                        *ngIf="(shareForm.get('firstName').errors && shareForm.get('firstName').errors?.required && Invitesubmitted)">
                        Please enter first name
                    </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group dark-input-rounded-sub">
                        <input type="text" class="form-control br8 pt22 pb22 fw-600" placeholder="Last Name"
                        formControlName="lastName" tabindex=2 
                        (keydown.space)="shareForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                        *ngIf="(shareForm.get('lastName').errors && shareForm.get('lastName').errors?.required && Invitesubmitted)">
                        Please enter last name
                    </div>
                    </div>
                </div>
            </div>
            <div class="form-group dark-input-rounded-sub">
                <label class="fs12 grey-j br8 ls-3">E-Mail</label>
                <input formControlName="email" tabindex=3  type="email" class="form-control pt22 pb22 fw-600" placeholder="Enter E-mail">
                <div class="fs12 text-danger"
                *ngIf=" (shareForm.get('email').errors && shareForm.get('email').errors?.required && Invitesubmitted)">
                Please enter email
            </div>
            <div *ngIf="(shareForm.get('email').errors && shareForm.get('email').errors?.pattern && Invitesubmitted)"
                class="fs12 text-danger">
                Enter a valid email.
            </div>
            </div>
            <div class="form-group dark-input-rounded-sub">
                <label class="fs12 grey-j br8 ls-3">Time Limit</label>
                <input readonly type="text" formControlName="expires" tabindex="4" placeholder="Time limit" class="form-control pt22 pb22 fw-600" bsDatepicker
                    [bsConfig]="datePickerConfig" #dp="bsDatepicker">
                    <div class="fs12 text-danger"
                    *ngIf="(shareForm.get('expires').errors && shareForm.get('expires').errors?.required && Invitesubmitted)">
                   Please choose time limit
                </div>
            </div>
            <div class="row mt50 mb-3 px-3">
                <div class="col-4 pr-1">
                    <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                        (click)="showInvite()">Cancel</button>

                </div>
                <div class="col-8 pl-1">
                    <button [disabled]="submitted" type="button" (click)="shareForm.valid && shareLink(shareForm)" 
                    class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 ">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Send Link</button>

                </div>
            </div>
        </form>
    </div>

</div>