<ul class="icon-bar">
  <li class="facebook" (click)="tryFacebookLogin(userDetailsModal)">
    <em class="fab fa-facebook-f"></em>
  </li>
  <li class="twitter" (click)="tryTwitterLogin(userDetailsModal)">
    <em class="fab fa-twitter"></em> 
  </li>
  <li class="google" (click)="tryGoogleLogin(userDetailsModal)">
    <em class="fab fa-google"></em> 
  </li>
  <li class="linkedin">
    <em class="fab fa-linkedin"></em>
  </li>
</ul>



<ng-template #userDetailsModal>
  <ng-container *ngIf="userDetails">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{userDetails.additionalUserInfo.providerId}} Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

      <img *ngIf="userDetails.additionalUserInfo.providerId === 'facebook.com'" [src]="userDetails.additionalUserInfo.profile.picture.data.url" alt="user-image" class="user-image">
      <img *ngIf="userDetails.additionalUserInfo.providerId === 'google.com'" [src]="userDetails.additionalUserInfo.profile.picture" alt="user-image" class="user-image">
      <img *ngIf="userDetails.additionalUserInfo.providerId === 'twitter.com'" [src]="userDetails.additionalUserInfo.profile.profile_image_url" alt="user-image" class="user-image">
      
      
      <br>

      <pre>
        {{userDetails.additionalUserInfo.profile | json}}
      </pre>
  </div>
</ng-container>
</ng-template>