<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-7 col-md-6 text-center  my-md-auto my-3  order-1 order-md-0">
            <img src="./assets/images/signup-img.svg" alt="signup" class="banner-img-1">
         </div>
         <div class="col-lg-5 col-md-6 my-content text-center text-md-left">
            <h1 class="grey-a fs24">Create Account</h1>
            <p class="fs12 authentication-sub-content">Create a new account by entering your details below</p>
            <form name="signupform" autocomplete="off" class=" mt-4" (ngSubmit)="onSubmit(signupform)"
               #signupform="ngForm" novalidate>
               <div class="row">
                  <div class="col-md-12 col-lg-7 col-sm-10 mx-auto mx-md-0 ">
                     <div class="row mb-2">
                        <div class="col-md-6 pr-md-1">
                           <div class="form-group input-base mb-0">
                              <input type="text" class="form-control pt22 pb22 fw-600 " name="firstName" required
                                 minlength="2" pattern="^[A-Za-z]+$" maxlength="25" [(ngModel)]="Signup.firstName"
                                 #firstName="ngModel" placeholder="First Name">
                           </div>
                           <div *ngIf="firstName.invalid && signupform.submitted">
                              <div *ngIf="firstName.errors.required" class="text-danger fs12">
                                 First Name is required.
                              </div>
                              <div *ngIf="firstName.errors.minlength" class="fs12 text-danger">
                                 First Name must be at least 2 characters long.
                              </div>
                              <div *ngIf="firstName.errors.maxlength" class="fs12 text-danger">
                                 First Name must only be 25 characters long.
                              </div>
                              <div *ngIf="firstName.errors.pattern" class="fs12 text-danger">
                                 First Name can only contain letters.
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 pl-md-1">
                           <div class="form-group input-base mb-0">
                              <input name="lastName" required minlength="1" maxlength="25" pattern="^[A-Za-z]+$"
                                 [(ngModel)]="Signup.lastName" #lastName="ngModel" type="text"
                                 class="form-control pt22 pb22 fw-600 " placeholder="Last Name">
                           </div>
                           <div *ngIf="lastName.invalid && signupform.submitted">
                              <div *ngIf="lastName.errors.required" class="text-danger fs12">
                                 Last Name is required.
                              </div>
                              <div *ngIf="lastName.errors.minlength" class="fs12 text-danger">
                                 Last Name must be at least 1 characters long.
                              </div>
                              <div *ngIf="lastName.errors.maxlength" class="fs12 text-danger">
                                 Last Name must only be 25 characters long.
                              </div>
                              <div *ngIf="lastName.errors.pattern" class="fs12 text-danger">
                                 Last Name can only contain letters.
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="mb-2">
                        <div class="form-group input-top-rounded mb-0">
                           <input name="email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              [(ngModel)]="Signup.email" #email="ngModel" (keyup)='removeTrim(signupform)'
                             type="email"
                              class="form-control pt22 pb22 fw-600 br-10" placeholder="Work Email">
                        </div>
                        <div *ngIf="email.invalid && signupform.submitted">
                           <div *ngIf="email.errors.required" class="text-danger fs12">
                              Email is required.
                           </div>
                           <div *ngIf="email.errors.pattern" class="fs12 text-danger">
                              Please enter a valid email.
                           </div>
                        </div>
                     </div>
                     <div class="mb-2">
                        <div class="input-group input-bottom-rounded custom-input-dial mb-0">
                           <ngx-intl-tel-input class="w-100" [cssClass]="'w-100 form-control pt22 pb22 fw-600'"
                           [preferredCountries]="preferredCountries"
                           [selectedCountryISO]="CountryISO.UnitedStates"
                           [enableAutoCountrySelect]="true"
                           [enablePlaceholder]="false"
                           [searchCountryFlag]="true"
                           [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                           [selectFirstCountry]="false"
                           [maxLength]="15"
                           [phoneValidation]="true"
                           [separateDialCode]="true" [(ngModel)]="Signup.phone"
                           name="phone" #phone="ngModel" required (ngModelChange)="onChange($event)">
                           </ngx-intl-tel-input>
                        </div>
                        <div *ngIf="signupform.submitted">
                           <div *ngIf="phone.invalid && phone.errors.required && !phone.touched" class="text-danger fs12">
                              Both Phone Code and Number are required.
                           </div>
                           <div *ngIf="!phone.valid && phone.touched" class="text-danger fs12">
                              Please enter valid phone number.
                           </div>
                        </div>
                     </div>
                     <div class="mb-2">
                     <div class="input-group input-base  mb-0">
                        <input name="password" required pattern="^[a-zA-Z0-9!@#$%\^&*)(+=._-]\S*.{5,25}$" minlength="6"
                           maxlength="25" [(ngModel)]="Signup.password" #password="ngModel"
                           [type]="togglePassword ? 'text': 'password'" autocomplete="off"
                           class="form-control fw-600 pt22 pb22  border-right-0" placeholder="Create Password">
                        <div class="input-group-append">
                           <span class="input-group-text border-left-0 " (click)="passwordToggle()">
                              <img *ngIf="togglePassword" src="./assets/images/show-pass.svg" alt="show password"
                                 class="c-pointer">
                              <img *ngIf="!togglePassword" src="./assets/images/pass-hide.svg" alt="show password"
                                 class="c-pointer">
                           </span>
                        </div>
                     </div>
                     <div class="fs12 grey-j ls-3 fw-500">
                        Passwords should be at least 6 characters long.
                     </div>
                     <div *ngIf="password.invalid && signupform.submitted">
                        <div *ngIf="password.errors.required" class="text-danger fs12">
                           Password is required.
                        </div>
                        <div *ngIf="password.errors.pattern" class="fs12 text-danger">
                           Password must be a minimum of 6 characters and maximum of 25 characters in length.
                        </div>
                     </div>
                     </div>
                     <div class="row mt50">
                        <div class="col-4 pr-1" *ngIf="this.datas === undefined">
                           <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600  "
                              (click)="goToBack()">Back</button>
                        </div>
                        <div class="col-8 pl-1">
                           <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload " type="submit"
                              [disabled]="submitted">
                              <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Next</button>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</section>
<app-sub-footer></app-sub-footer>

<app-sendotp-sidenav [showToggle]="showNav" [showCreate]="showCreate" (otpSubmit)="getOtpType($event, signupform)"></app-sendotp-sidenav>
