import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-billing-history-filter',
    templateUrl: './billing-history-filter.component.html',
})
export class BillingHistoryFilterComponent implements OnInit {

  @Input('toggleSidemenu') public toggleSidemenu: boolean = false;

  @Output('closesidemenu') public closesidemenu = new EventEmitter();

  @Output('filterSubmite') public filterSubmite = new EventEmitter();

  public datePickerConfig: Partial<BsDatepickerConfig>;

  public submitted = false;

  public filterBilling: FormGroup;

  public constructor(private fb: FormBuilder) { }

  public ngOnChanges(changes: SimpleChanges): void {
      // changes.prop contains the old and the new value...
      this.toggleSidemenu = changes.toggleSidemenu.currentValue;

  }

  public ngOnInit(): void {
      this.createForm();
  }

  public createForm(): void {
      this.filterBilling = this.fb.group({
          fromDate: [null],
          toDate: [null],
          actions: [null],
      });
  }

  public closeFilter(): void {
      this.submitted = false;
      this.filterBilling.reset();
      this.toggleSidemenu = false;
      this.closesidemenu.emit(this.toggleSidemenu);
  }

  public clearfilter(): void {
      this.submitted = false;
      this.filterBilling.reset();
      this.closesidemenu.emit(this.toggleSidemenu);
  }

  public clearFilterFromParent(): void {
      this.submitted = false;
      this.filterBilling.reset();
      // const resetForm = {
      //     fromDate: null,
      //     toDate: null,
      //     actions: null,
      // };
      // this.filterSubmite.emit(resetForm);
  }

  public filterSubmit(): void {
      this.submitted = true;
      this.filterSubmite.emit(this.filterBilling.value);
  }

}
