import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api_base/api.service';

@Injectable({
    providedIn: 'root',
})

export class AlbumserviceService {

    public commonParam = '';
    public constructor(private api: ApiService, private http: HttpClient) {
        // constructor
        this.commonParam = `userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
    }

    public CreateAlbum(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/createalbum', payload);
    }

    public UpdateAlbum(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyAlbum/updatealbum', payload);
    }

    public getAlbumByTeamId(payload): Observable<any> {
        const path = `pixlyAlbum/v2/getalbumsbyteamid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&teamId=${sessionStorage.getItem('projectId')}&sortviewby=${payload.sortviewby}&timeZone=${payload.timeZone}&pagenumber=${payload.pagenumber}&pagerecord=${payload.pagerecord}&sortType=${payload.sortType}&sortParam=${payload.sortParam}&search=${payload.search}`;
        return this.api.getByHeadersBearer(path);
        // return this.api.postByHeadersBearer('pixlyAlbum/getalbumsbyteamid', payload);
    }

    public ShareImg(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/sharepicturelinkweb', payload);
    }

    public getAlbumByAlbumId(params): Observable<any> {
        const path = `pixlyAlbum/getalbumbyalbumid${params}`;
        return this.api.getByHeadersBearer(path);
    }

    public deleteSelectedAlbum(path): Observable<any> {
        return this.api.deleteByHeadersBearer(path);
    }

    public uploadPhotos(data): Observable<any> {
        return this.api.postByHeadersBearer('pixlyMedia/uploadphoto', data);
    }

    public getPhotoByAlbumId(data): Observable<any> {
        const path = `pixlyAlbum/v2/getphotosbyalbumid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&albumId=${data.albumId}&sortby=${data.sortby}&orderby=${data.orderby}&sortviewby=${data.sortviewby}&timeZone=${data.timeZone}&pagenumber=${data.pagenumber}&pagerecord=${data.pagerecord}`;
        return this.api.getByHeadersBearer(path);
        // return this.api.postByHeadersBearer('pixlyAlbum/getphotosbyalbumid', data);
    }

    public getCredential(file): any {
        const credentials = {
            fileExtenstion: this.getFileExtension(file.name),
            fileName: file.name,
        };
        return credentials;
    }

    public getFileExtension(filename): any {
        const ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? '' : ext[1];
    }

    public getS3Credential(): any {
        const S3Client = new S3({
            region: environment.KRegion,
            accessKeyId: environment.kAccessKey,
            secretAccessKey: environment.KsecretKey,
        });
        return S3Client;
    }

    public getS3Params(file): any {
        const params = {
            Bucket: environment.kIMAGEBUCKET,
            Key: `${uuidv4()}.${file.file.target.files[0].name.replace(/^.*\./, '')}`,
            Body: file.croppedImage,
            ACL: 'public-read',
            ContentType: file.file.target.files[0].type,
        };
        return params;     
    }

    public getS3ParamsAttach(file): any {
        const params = {
            Bucket: environment.kIMAGEBUCKET,
            Key: `${uuidv4()}.${file.name.replace(/^.*\./, '')}`,
            Body: file,
            ACL: 'public-read',
            ContentType: file.type,
        };
        return params;
    }

    public getPhotoFeedByTeamId(startingAt): Observable<any> {
        const path = `pixlyAlbum/getphotofeeds?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&teamId=${sessionStorage.getItem('projectId')}&startingAt=` + startingAt;
        return this.api.getByHeadersBearer(path);
    }

    public getPhotoFeedByTeamIdFilter(sortType, orderBy, teamId?): Observable<any> {
        const path = 'pixlyAlbum/getphotofeedsfilter?userId=' + localStorage.getItem('userId') + '&workSpaceName=' + sessionStorage.getItem('workspacename') + '&teamId=' + (sessionStorage.getItem('projectId') ? sessionStorage.getItem('projectId') : teamId) + '&startingAt=0&sortviewby=' + sortType + '&sortType=' + orderBy + '&sortParam=createdOn';
        return this.api.getByHeadersBearer(path);
    }

    public getAllMemberByAlbum(s, id): Observable<any> {
        const path = `pixlyAlbum/getmembersshipbyalbumid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&albumId=${id}&search=${s}`;
        return this.api.getByHeadersBearer(path);
    }

    public removeUserFromAlbum(data): Observable<any> {
        return this.api.putByHeadersBearer('pixlyAlbum/removemembersfromalbum', data);
    }

    public deletePhotoList(data): Observable<any> {
        return this.api.putByHeadersBearer('pixlyMedia/deletephoto', data);
    }

    public addPhotoTagApi(data): Observable<any> {
        // eslint-disable-next-line no-param-reassign
        data.devicewidth = 428;
        return this.api.postByHeadersBearer('pixlyAlbum/addTagToPhoto', data);
    }

    public getPlotTagsApi(photoid): Observable<any> {
        const path = `pixlyAlbum/gettagsbyphotoid?photoId=${photoid}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        return this.api.getByHeadersBearer(path);
    }

    public deletePlotTagApi(tagTypeId): Observable<any> {
        const path = `pixlyAlbum/removetagfromphoto?tagTypeId=${tagTypeId}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getNoteByPhotoTagidApi(photoid): Observable<any> {
        const path = `pixlyAlbum/getnotebyphototagid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&photoTagId=${photoid}`;
        return this.api.getByHeadersBearer(path);
    }

    public addNoteApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/addnote', payload);
    }

    public editAssignUserApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/addtodo', payload);
    }

    public editTagPositionApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/edittag', payload);
    }

    public getCompletedListApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/gettodosbyteamid', payload);
    }

    public changeStatusToDoApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/changetodostatus', payload);
    }

    // added update tag
    public updateTagPhotos(photoId): Observable<any> {
        return this.api.putByHeadersBearer('pixlyAlbum/changetagstatus', photoId);
    }

    public deleteAlbum(albumId): Observable<any> {
        const path = `pixlyMedia/deletealbum?&albumId=${albumId}&${this.commonParam}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getbytodoidApi(todoID, type): Observable<any> {
        //  type = 1 - complete
        //  type = 2 - pending
        const path = `pixlyAlbum/getbytodoid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&todoId=${todoID}&type=${type}`;
        return this.api.getByHeadersBearer(path);
    }

    public getPDFDownload(data): any {
        const path = `pixlyAlbum/v2/albumphotospdf?albumId=${data.albumId}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&urlList=${data.urlList}`;
        return this.api.getByHeadersBearer(path);
        // return this.api.postByHeadersBearer('pixlyAlbum/albumphotospdf', data);
    }

    public removeattachmentApi(noteid): Observable<any> {
        const path = `pixlyAlbum/removeattachment?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&noteid=${noteid}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getmembersshipbyalbumidApi(albumId): Observable<any> {
        const path = `pixlyAlbum/getmembersshipbyalbumid?albumId=${albumId}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&search=`;
        return this.api.getByHeadersBearer(path);
    }

    public updateAlbum(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyAlbum/updatealbum', payload);
    }

    public geFeedsListApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlySearch/globalsearch', payload);
    }

    public geTabsListApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlySearch/applyfilter', payload);
    }

    public getTeam(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlyProfile/getteambyid?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&teamId=${sessionStorage.getItem('projectId')}`);
    }
}
