<div id="overlay" [ngClass]="{'d-block': toggleWS}"></div>
<div #sidenav class="right-sidebar bg-white" style="padding: 15px;" [ngClass]="{'show-side-nav' : toggleWS}">
   <div *ngIf="showCreate && !workspaceOrProject">
      <div class="d-flex justify-content-between mt-5">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Name your Workspace</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="toggleWorkSpaceMethod()"></em>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Workspace?</h2>
      <form class="mt-4" name="WorkspaceForm" #WorkspaceForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input maxlength="50" [validation]="false" type="text" name="workSpaceName" required
               [(ngModel)]="createWorkspace.workSpaceName" #workSpaceName="ngModel"
               [ngClass]="{ 'has-error': WorkspaceForm.submitted && workSpaceName.invalid }"
               class="form-control br8 pt22 pb22 fw-600" placeholder="Workspace name...">
            <div *ngIf="workSpaceName.invalid && (workSpaceName.dirty || workSpaceName.touched)">
               <div *ngIf="workSpaceName.errors.required" class="text-danger fs12">
                  Workspace name is required.
               </div>
            </div>
         </div>

         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                  [disabled]="submitted" (click)="toggleWorkSpaceMethod()">Cancel</button>

            </div>
            <div class="col-8 pl-1" *ngIf="shouldShowButton()">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                  (click)="WorkspaceForm.form.valid && onSubmit(WorkspaceForm)"
                  [disabled]="!WorkspaceForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Workspace</button>
            </div>
         </div>
      </form>
   </div>
   <!-- choose workspace -->
   <div *ngIf="!showCreate && !workspaceOrProject">


      <div class="d-flex justify-content-between border-bottom-a pl15 pt10 pb10 mb-2 mt-5">
         <h1 class="grey-g fs14 font-weight-bold mb-0"><img src="./assets/images/files.svg" alt="projects"
               class="mr-2 w14">Choose your Workspace</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="toggleWorkSpaceMethod()"></em>
      </div>
      <div class="form-group input-rounded">
         <input maxlength="50" type="text" name="search workspace" (keyup)="filterWorkspaces($event.target.value)"
            class="form-control br8 pt22 pb22 fw-600 " placeholder="Search workspaces">
      </div>
      <div class="scroll-content " *ngIf="filteredWrkspace && filteredWrkspace.length > 0; else noRecords" style="max-height: 66vh">
         <div class="workspace-list-item" *ngFor="let workspace of filteredWrkspace; let i = index">
            <input type="radio" name="check-radio" id="check-radio{{i}}" class="d-none">
            <label class=" workspace-list py-2 d-block c-pointer" for="check-radio{{i}}">
               <div class="d-flex">
                  <div class="team-logo grey-i fs12 mr-3" (click)="selectWorkSpace1(workspace)">
                     {{getFirstAndLastLetter(workspace.masterTenant.workSpaceName)}}
                  </div>
                  <div class="flex-grow-1 fs14 fw-600 grey-m my-auto" (click)="selectWorkSpace1(workspace)">
                     {{workspace.masterTenant.workSpaceName}}
                  </div>
                  <div class="d-flex align-items-center">
                     <div>
                        <img src="./assets/images/circle-tick-blue.svg" alt="projects"
                           *ngIf="workspacename === workspace.masterTenant.dbName" class="mr-3 w14">
                     </div>
                  </div>
               </div>
            </label>
         </div>
      </div>
      <ng-template #noRecords>
         <div class="no-records-message">
            <b class="message-title">Uh-oh! No workspaces found.</b>
            <p class="message-suggestion">Why not create a new workspace?</p>
         </div>
      </ng-template>
      <div class="row mt30 px-3">
         <div class="col-4 pr-1">
            <button [disabled]="submitted" type="button"
               class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600  "
               (click)="toggleWorkSpaceMethod()">Cancel</button>
         </div>
         <div class="col-8 pl-1" *ngIf="shouldShowButton()">
            <button type="button" [disabled]="submitted"
               class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-600 buttonload" (click)="ToggleWorkSpace1()">
               <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>New Workspace</button>
         </div>
      </div>
   </div>

   <div *ngIf="showProjectCreate && workspaceOrProject">
      <div class="d-flex justify-content-between mt-5">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Name your Project</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="closeProject()"></em>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Project?</h2>
      <form class="mt-4 " name="projectForm" #projectForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input maxlength="50" type="text" required name="teamName" required [(ngModel)]="createProject.teamName"
               #teamName="ngModel" [ngClass]="{ 'has-error': projectForm.submitted && teamName.invalid }"
               class="form-control br8 pt22 pb22 fw-600 " placeholder="Project name..."
               (keydown.space)="createProject.teamName.trim().length > 0 ? '': $event.preventDefault();">
            <div *ngIf="teamName.invalid && (teamName.dirty || teamName.touched)">
               <div *ngIf="teamName.errors.required" class="text-danger fs12">
                  Project name is required.
               </div>
            </div>
         </div>
         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
         <div class="input-container">
            <h2 class="fs12 grey-j ls-3">Address</h2>
            <input type="text" class="form-control br8 pt22 pb22 fw-600 txt-font" name="selectedPlace"
               placeholder="Address..." (input)="onSearch($event.target.value)" [(ngModel)]="selectedPlaceText"
               autocorrect="off" autocapitalize="off" spellcheck="off" [ngModelOptions]="{ standalone: true }">
            <div class="predictions" *ngIf="predictions.length > 0">
               <div class="prediction-item" *ngFor="let prediction of predictions"
                  (click)="onPlaceSelected(prediction)">
                  {{ prediction.description }}
               </div>
            </div>
         </div>
         <div class="form-group form-check" >
            <input type="checkbox" name="addAllMembers" id="addAllMembers"  class="form-check-input" [(ngModel)]="addAllMembers">
            <input type="checkbox" name="addAllMembers" id="addAllMembers"  class="form-check-input" [(ngModel)]="addAllMembers">
                <label *ngIf="companyName && companyName!=''" for="addAllMembers" class="form-check-label c-pointer">Add {{companyName}} company users to new project</label>
                <label *ngIf="!companyName || companyName==''" for="addAllMembers" class="form-check-label c-pointer">Add workspace users to new project</label></div>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                  [disabled]="submitted" (click)="closeProject()">Back</button>
            </div>
            <div class="col-8 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                  (click)="projectForm.form.valid && createProjectMethod(projectForm)"
                  [disabled]="!projectForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Project</button>
            </div>
         </div>
      </form>
   </div>


   <div *ngIf="!showProjectCreate && workspaceOrProject">
      <div class="d-flex justify-content-between pl15 pt60 pb10 mb-2">
         <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 ">Choose Your Project</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeProject()"></em>
      </div>
      <div class="form-group input-rounded">
         <input maxlength="50" type="text" name="search project" (input)="onSearchInput($event.target.value)"
            class="form-control br8 pt22 pb22 fw-600 " placeholder="Search projects">
      </div>
      <!-- <div class="d-flex justify-content-between border-bottom-a pl15 pt10 pb10 mb-2">
            <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 ">Choose Your Project</h1>
            <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeProject()"></em>
        </div> -->
      <div class="win-height">
         <div class="workspace-list-item" *ngFor="let project of filteredProjects;let i = index"
            (click)="chooseProject(project)">
            <input type="radio" name="check-radio" id="check-radioc{{i}}" class="d-none">

            <label class=" workspace-list py-2 d-block c-pointer" for="check-radioc{{i}}">
               <div class="d-flex">
                  <div class="team-logo grey-i fs12 mr-3">
                     {{getFirstAndLastLetter(project.teamName)}}
                  </div>
                  <div class="flex-grow-1 fs14 fw-600 grey-m my-auto">
                     {{project.teamName}}
                     <div *ngIf="project.addressDetails" style="display: flex; align-items: center;">
                        <i class="fas fa-map-marker-alt" style="font-size: 12px; color: grey; margin-right: 4px;"></i>
                        <p data-toggle="tooltip" [title]="project.addressDetails" style=" white-space: nowrap;
                        width: 170px;
                        overflow: hidden;
                        cursor: pointer;
                        text-overflow: ellipsis;font-size: 10px; color: grey; margin: 0;">{{project.addressDetails}}
                        </p>
                     </div>
                  </div>
                  <div class="check-div my-auto">
                     <em class="fas fa-check-circle"></em>
                  </div>
               </div>
            </label>
         </div>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button [disabled]="submitted" type="button"
                  class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                  (click)="closeProject()">Back</button>
            </div>
            <div class="col-8 pl-1">
               <button *ngIf="tempUserType" [disabled]="submitted"
                  class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-600 buttonload" (click)="ToggleProject()"
                  type="button">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>New Projects</button>
            </div>
         </div>
      </div>
   </div>
</div>