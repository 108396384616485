<section class="main-container">
  <div class="sub-container">
    <div class="content-container">
      <h1 class="grey-g fs18 font-weight-bold " *ngIf="dashboardCounts && dashboardCounts.workspace">
        {{dashboardCounts.workspace}} dashboard</h1>
      <h1 class="grey-g fs18 font-weight-bold " *ngIf="!dashboardCounts  || !dashboardCounts.workspace">Workspace
        dashboard</h1>
      <div class="row">
        <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 pr-md-1">
          <div class="card content-card p15">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img src="./assets/images/dashboard-album.svg" alt="user" class="w23">
              </div>
              <div>
                <h1 *ngIf="!dashboardCounts || !dashboardCounts.projectCount" class="blue-e font-weight-bold fs24 ">0
                </h1>
                <h1 *ngIf="dashboardCounts && dashboardCounts.projectCount" class="blue-e font-weight-bold fs24 ">
                  {{dashboardCounts.projectCount}}</h1>
              </div>
            </div>
            <h2 class="fs15 grey-l fw-600 mb0 mt35">Project Count</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 px-md-1">
          <div class="card content-card p15">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img src="./assets/images/user.svg" alt="user" class="w23">
              </div>
              <div>
                <h1 *ngIf="!dashboardCounts || !dashboardCounts.memberCount" class="blue-e font-weight-bold fs24 ">0
                </h1>
                <h1 *ngIf="dashboardCounts && dashboardCounts.memberCount" class="blue-e font-weight-bold fs24 ">
                  {{dashboardCounts.memberCount}}</h1>
              </div>
            </div>
            <h2 class="fs15 grey-l fw-600 mb0 mt35">Member Count</h2>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 pl-md-1">
          <div class="card content-card p15">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img src="./assets/images/dashboard-photo.svg" alt="user" class="w23">
              </div>
              <div>
                <h1 *ngIf="!dashboardCounts || !dashboardCounts.albumCount" class="blue-e font-weight-bold fs24 ">0</h1>
                <h1 *ngIf="dashboardCounts && dashboardCounts.albumCount" class="blue-e font-weight-bold fs24 ">
                  {{dashboardCounts.albumCount}}</h1>
              </div>
            </div>
            <h2 class="fs15 grey-l fw-600 mb0 mt35">Album Count</h2>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 3%;">
        <div class="col-md-6 col-sm-4 mb-3 mb-sm-0 pr-md-1">
          <div class="card content-card p15">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img src="./assets/images/tag.svg" alt="user" class="w23">
              </div>
              <div>
                <h1 *ngIf="!dashboardCounts || !dashboardCounts.tagCount" class="blue-e font-weight-bold fs24 ">0</h1>
                <h1 *ngIf="dashboardCounts && dashboardCounts.tagCount" class="blue-e font-weight-bold fs24 ">
                  {{dashboardCounts.tagCount}}</h1>
              </div>
            </div>
            <h2 class="fs15 grey-l fw-600 mb0 mt35">Tag Count</h2>
          </div>
        </div>

        <div class="col-md-6 col-sm-4 mb-3 mb-sm-0 pl-md-1">
          <div class="card content-card p15">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img src="./assets/images/task.svg" alt="user" class="w23">
              </div>
              <div>
                <h1 *ngIf="!dashboardCounts || !dashboardCounts.taskCount" class="blue-e font-weight-bold fs24 ">0</h1>
                <h1 *ngIf="dashboardCounts && dashboardCounts.taskCount" class="blue-e font-weight-bold fs24 ">
                  {{dashboardCounts.taskCount}}</h1>
              </div>
            </div>
            <h2 class="fs15 grey-l fw-600 mb0 mt35">Task Count</h2>
          </div>
        </div>
      </div>

      <div class="content-card pl15 pr15 pb10 shadow-none my-3">
        <!-- <h1 class="fs15 pt10 pb10 px-2 fw-600 grey-g mb-0">Project Feed</h1>
        <div class="notification-scroll scroll-content" infiniteScroll [infiniteScrollDistance]="3"
          [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [infiniteScrollContainer]="selector1" [fromRoot]="true"
          [scrollWindow]="false">
          <div class="row mx-0 py-2 border-top-a" *ngFor="let feed of ActivityFeed">
            <div class="d-flex col-sm-8 col-12 mb-2 mb-sm-0 align-items-center">
              <img src="./assets/images/empty-img.png" *ngIf="!feed.receiverImage" alt="ac-repair"
                class="w30 h30 br20 object-fit-cover mr-3">
              <img src="{{cloudFrontURL}}{{feed.receiverImage}}" *ngIf="feed.receiverImage" alt="ac-repair"
                class="w30 h30 br20 object-fit-cover mr-3">
              <h1 class="grey-m fs14 fw-600 mb-0">{{feed.description}}</h1>
            </div>
            <div class="col-sm-2 col-6 d-flex align-items-center justify-content-sm-center justify-content-end">
              <h2 *ngIf="datePipe.transform(feed.createdOn, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')"
                class="grey-l fs12 fw-600 mb-0">{{getDateValue(feed.createdOn) | amTimeAgo }}</h2>
              <h2 *ngIf="datePipe.transform(feed.createdOn, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')"
                class="grey-l fs12 fw-600 mb-0">{{getDateValue(feed.createdOn) | amDateFormat: 'MM/DD/YYYY hh:mm A' }}
              </h2>
            </div>
            <div class="col-sm-2 col-6 d-flex align-items-center justify-content-end c-pointer"
              (click)="navigate(feed)">
              <em class="lnr lnr-chevron-right grey-l fw-600 fs11"></em>
            </div>
          </div>
        </div>


        <div *ngIf="!ActivityFeed || ActivityFeed.length === 0" class="py-4 text-center">
          <p class="fs17 grey-g fw-600 ">Your Feed is Empty</p>
        </div> -->
        <agm-map #gm [latitude]="lat" [longitude]="lng" [disableDefaultUI]="false"
          (mapReady)="mapLoaded($event)" style="height: 400px;" [zoom]="zoom"
         >
        
          <agm-marker *ngFor="let m of latLngArray; let i = index" [latitude]="m.latitude"
            [longitude]="m.longitude" [label]="m.label" [markerDraggable]="m.draggable"
            (markerClick)="goToProject(m)" (mouseOver)="onMouseOver(infoWindow,gm, m.teamId)"
            [iconUrl]="'./assets/images/red_dot.svg'"
            >
        
            <agm-info-window [disableAutoPan]="false" #infoWindow style="max-width: 200px;">
              <div class="info-window-container">
                <div style="font-weight: 500;">{{m.teamName ?  m.teamName : ''}}</div>
                <span *ngFor="let img of photoFeedList">
                  <img *ngIf="photoFeedList.length && !img.endsWith('.mp4')" [src]="img" alt="img" height="60px" width="60px" class="mt-1 mb-1 mr-1" style="border-radius: 20%;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
                </span>
                <div *ngIf="!photoFeedList">
                  No images available
                </div>
              </div>
            </agm-info-window>
          </agm-marker>
        
        </agm-map>
      </div>

    </div>
  </div>
</section>