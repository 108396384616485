<ngx-loading-bar [color]="'#007bff'"></ngx-loading-bar>
<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt8">
            <div>
                <div class="content-card br-blr-10 border-grey shadow-none">
                    <div class="card-header bg-white border-0 pb-0  mt-2  px-3 pt-3">
                        <ul class="list-inline pl50 mb-0">
                            <li class="list-inline-item"><a class="fs11" routerLink="/project/albums" [queryParams]="conCadSessionData({})" ><span
                                        class="grey-l">Albums</span></a></li>
                            <li class="list-inline-item"><a class="fs10 "><em
                                        class="grey-l fas fa-chevron-right"></em></a>
                            </li>
                            <li class="list-inline-item"><a class="fs11"><span
                                        class="grey-l">{{albumEntity.albumName}}</span></a></li>
                        </ul>
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <div class="d-flex mb-3 mb-sm-0 align-items-center">
                                <div class="back-cirle mr-4 c-pointer" routerLink="/project/albums" [queryParams]="conCadSessionData({})">
                                    <img src="./assets/images/back-arrow.svg" alt="back-arrow" class="w13">
                                </div>
                                <div>
                                    <h1 class="fw-600 fs20 ls-9 grey-g mb-0 pt-3">{{albumEntity.albumName}}</h1>
                                    <p class="fs12 grey-l ls-7 fw-600 mb-0">{{albumEntity.albumVisibility}}</p>
                                    <p class="fs12 grey-l ls-7 fw-600 mb-0">{{getDateValue(albumEntity.createdOn) | date: 'MMM dd yyyy'}}</p>
                                </div>

                            </div>

                            <div *ngIf="!mapView" class="d-flex flex-wrap align-items-center">
                                <a (click)="getAlbumsort()" *ngIf="!showPeople">
                                    <p class="mb-0 ls-e fs12 grey-g font-weight-bold c-pointer">{{DateText}}
                                        <img src="./assets/images/up.svg" alt="sort" class="ml-2 w9 mb6 c-pointer">
                                        <img src="./assets/images/down.svg" alt="sort" class=" w9 c-pointer">
                                    </p>
                                </a>
                                <div dropdown class="album-menu c-pointer ml-3">

                                    <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal" class="w23"
                                        id="basic-link" dropdownToggle (click)="false"
                                        aria-controls="basic-link-dropdown">
                                    <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu border-white-b"
                                        role="menu" aria-labelledby="basic-link">
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                (click)="showAlbumSettings()" href="javascript:void(0)">
                                                <img src="./assets/images/album-settings.svg" alt="settings"
                                                    class="w14 mr-3">Album Settings</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item border-grey-k text-black fs13 pb-3 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="viewPeople()">
                                                <img src="./assets/images/album-people.svg" alt="settings"
                                                    class="w14 mr-3">People</a>
                                        </li>
                                        <h2 class="dropdown-item grey-u pt-2 fs10 fw-600 mb-1">Views</h2>
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                (click)="showDirection()" href="javascript:void(0)">Map View</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="changeView('month')">Month View</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="changeView('year')">Year View</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item border-grey-k pb-3 text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="changeView('day')">Date View</a>
                                        </li>
                                        <h2 *ngIf="photoList.length !== 0"
                                            class="dropdown-item grey-u pt-2 fs10 fw-600 mb-1">Manage</h2>
                                        <li *ngIf="photoList.length !== 0 && userType !== '5'">
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="pdfDownload([])">Get PDF</a>
                                        </li>
                                        <!-- <li *ngIf="photoList.length !== 0">
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1" (click)="shareAlbum()"
                                                href="javascript:void(0)">Share Album</a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="!mapView && !showPeople" class="card-body pt-2  px-3">
                        <div class="d-flex justify-content-end pb-2 mb-2 border-bottom-a">
                            <div class="mr-2 c-pointer pt-0" (click)="reportModal()"
                                *ngIf="choosedImage && choosedImage.length > 0 && hidedelete === -1">
                                <button class="btn btn-orange-light br9 h34 pt3" (click)="reportModal()" type="button"> <img src="./assets/images/noun-PDF.svg" alt="pdf" class="mr-2 w15 h15" />Get PDF</button>
                            </div>
                            <!-- <div class="album-menus mr-2 c-pointer" (click)="!submitted && moveImage()"
                                *ngIf="choosedImage && choosedImage.length > 0 && hidedelete === -1">
                                <img src="./assets/images/4-angle.svg" alt="angle" class="w14" title="Move">
                            </div> -->
                            <div class="album-menus mr-2 c-pointer" (click)="!submitted && copyImage()"
                                *ngIf="choosedImage && choosedImage.length > 0">
                                <img src="./assets/images/copy.svg" alt="copy" class="w14" title="Copy">
                            </div>

                            <div class="album-menus mr-2 c-pointer" (click)="!submitted && pasteImage()"
                                *ngIf="choosedImage && choosedImage.length === 0 && datas && datas.copyImageFrom">
                                <em style="color: #007bff;" class="fas fa-paste" title="Paste"></em>
                            </div>
                            <div class="album-menus mr-2 c-pointer" (click)="!submitted && downloadImage()"
                                *ngIf="choosedImage && choosedImage.length > 0">
                                <img src="./assets/images/download.svg" alt="download" class="w14" title="Download">
                            </div>
                            <div class="album-menus c-pointer" (click)="!submitted && openModal3()"
                                *ngIf="choosedImage && choosedImage.length > 0 && hidedelete === -1">
                                <img src="./assets/images/delete.svg" alt="delete" class="w14" title="Delete">
                            </div>
                        </div>

                        <div *ngIf="userType && userType !== '5'">
                            <div class="my-3">
                                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                        <img src="./assets/images/drag-drop.svg" alt="drag-drop" class="w60 mt-2">
                                        <p class="grey-l fs12 fw-600 ls-e mb-0">Add Photo/Video by <br> Drag & Drop</p>
                                        <p class="grey-l fs13 fw-600 ls-e my-1">(OR)</p>
                                        <button [disabled]="submitted" (click)="openFileSelector()"
                                            class="btn btn-blue br25 pt7 pb7 px-3 fs13 fw-600 buttonload" type="button">
                                            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Browse</button>
                                    </ng-template>
                                </ngx-file-drop>
                                <span class="grey-g fs12  ml-4">Requirements: JPEG or PNG or MP4</span>
                            </div>
                            <div class="btn-grey-f d-flex  align-items-center br10 px-3 pt10 pb10"
                                *ngIf="selectedImage.length > 0">
                                <h2 class="grey-g fs14 ls-i fw-600 mr-2 mb-0">Selected Photos/Videos</h2>

                            </div>
                            <!-- class="masonary-container" -->
                            <div id="mas-containter">
                                <div class="row mt-2">
                                     <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let selImage of selectedImage; let i = index">
                                        <div class="item position-relative mb-2">
                                        <div class="d-flex">
                                            <div
                                                class="c-pointer album-edit-delete position-absolute ryt-30 showonhover" style="top: 10px;
                                                right: 10px;">
                                                <div *ngIf="selImage.valid">
                                                    <em class="far fa-trash-alt fs13 red-f grey-s br3 p5"
                                                        title="Remove photo"
                                                        (click)="!submitted && abortS3(i)"></em>
                                                </div>
                                            </div>
                                            <img *ngIf="selImage.valid && !selImage.isVideo"  class="br5" [src]="selImage.base64Format" alt="video thumbnail" style="object-fit: fill;
                                            height: 180px;
                                            width: 100%; max-width: 100%;">
                                            <!-- 
                                            <image-cropper *ngIf="selImage.valid && !selImage.isVideo" class="br5 photos-img" [imageChangedEvent]="selImage.file" 
                                                [maintainAspectRatio]="true" [aspectRatio]="1/1" format="jpeg"
                                                (imageCropped)="imageCropped($event,i)" [resizeToWidth]="500">
                                            </image-cropper> -->
                                            <h2 class="grey-g fs13 fw-600 mr-2 mb-0" *ngIf="!selImage.valid">{{selImage.name |
                                                ngXtruncate:25:'...'}}
                                            </h2>
                                            <em *ngIf="!selImage.valid" class="fa fa-exclamation-circle mr-2 red-f fa-xs"
                                                aria-hidden="true"></em>
                                            <em class="fas fa-times mr-2 red-f fa-xs c-pointer"
                                                *ngIf="!selImage.valid" title="remove photo"
                                                (click)="!submitted && abortS3(i)"></em>
                                        </div>
                                        <!-- <img *ngIf="selImage.valid && selImage.isVideo" class="br5" src="./assets/images/video_thumbnail.png" alt="video thumbnail" style="height: 183px;
                                        width: 318px;
                                        object-fit: fill;"> -->
                                        <div *ngIf="selImage.valid && selImage.isVideo" class="br5 ">
                                            <video controls #videoPlayer  style="object-fit: fill;
                                            height: 180px;
                                            width: 100%; max-width: 100%;">
                                                <source src="{{selImage.base64Format}}" type="video/mp4" />
                                                Browser not supported
                                            </video>
                                        </div>
                                        <progressbar *ngIf="selImage.valid && selImage.percentage > 0" style="height: 7px;" class="prgrs-bg" max="100"
                                        [value]="selImage.percentage">
                                        </progressbar>
                                        <p class="red-f fs10" *ngIf="selImage.errorMessage">{{selImage.errorMessage}}</p>
                                    </div>
                                     </div>
                                </div>
                                

                              

                            </div>

                            <div class="col-12 text-center mb-2" *ngIf="selectedImage.length > 0">
                                <button [disabled]="submitted"
                                    class="btn btn-blue br25 pt7 pb7 px-3 fs13 fw-600 ls-i mb-0 buttonload"
                                    type="button" (click)="imageUpload()">
                                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Save Photo/Video</button>
                            </div>
                        </div>
                        <div>
                            <div *ngFor="let photo of photoList">
                                <div class="btn-grey-f d-flex  align-items-center br10 px-3 pt10 pb10 ">

                                    <h2 class="grey-g fs12 ls-i fw-600 mr-2 mb-0">{{photo.date}}</h2>
                                </div>
                                <div class="row mt-2">
                                    <div #checkbox  class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 album-all-photos-new mb-2" 
                                        *ngFor="let p of photo.photoInfo; let ip = index">
                                        <div *ngIf="p.tagCount > 0" tooltip="Has Tag"
                                            class="circle-tag rounded-circle position-absolute"></div>
                                        <div
                                            class="c-pointer album-edit-delete position-absolute ryt-10 showonhover" style="top: 11px;
                                            right: 25px;">
                                            <div
                                                *ngIf="((p.author && p.author.id === userId && userType === '4') || userType === '3' || userType === '2' || userType === '1')">
                                                <em class="far fa-trash-alt fs13 red-f grey-s br3 p5"
                                                    title="Delete Photo"
                                                    (click)="!submitted && deleteIndividualImage(p)"></em>
                                            </div>
                                        </div>

                                        <!-- <input type="checkbox" name="check-album"
                                            id="check-album{{i}}{{ip}}{{p.photoId}}" class="d-none"
                                            (change)="albumChoose(p, $event.target.checked)"> -->
                                        <div class="" for="check-album{{i}}{{ip}}{{p.photoId}}" (click)="albumChoose(p, !isImageInChoosedImage(p))"
                                        [ngClass]="{'checked': isImageInChoosedImage(p)}">
                                            <input class="c-pointer album-edit-delete position-absolute" style="top: 11px; width: 20px;height: 16px;
                                            left: 25px;" type="checkbox" [checked]="isImageInChoosedImage(p)">

                                            <!-- <img src="./assets/images/tick-rounded.svg"  *ngIf="isImageInChoosedImage(p)" alt="tick"
                                                class="w14 check-img"> -->
                                                <ng-container
                                                    *ngIf="p.media && p.media.awskey && photoExtentions.includes(p.media.awskey.split('.')[p.media.awskey.split('.').length -1])">
                                                    <img [src]="cloudFrontURL + p.media.awskey" [alt]="p.media.awskey ? 'profile-pic' : ''" class=" br5" style="object-fit: fill;
                                                                                                   height: 180px;
                                                                                                   width: 100%; max-width: 100%;">
                                                </ng-container>

                                                
                                                    <video class="br5" style="object-fit: fill;
                                                                                                   height: 180px;
                                                                                                   width: 100%; max-width: 100%;"
                                                        *ngIf="p.media && p.media.awskey && videoExtentions.includes(p.media.awskey.split('.')[p.media.awskey.split('.').length -1])"
                                                        controls>
                                                        <source src={{cloudFrontURL}}{{p.media.awskey}} alt="profile-video" type="video/mp4">
                                                    </video>
                                            <div class="view-photos c-pointer py-2 px-1 text-nowrap">
                                                <p class="fs14 mb-0 text-white px-3" [routerLink]="['/project/photo-tag']"
                                                    [queryParams]="conCadSessionData({albumId: p.albumId, photoId: p.photoId, userLat: this.datas.lat, userLng: this.datas.lng, prevPage: 'project/album-details', mediaType: isVideo(p.media.awskey) ? 'Video' : 'Photo'})">
                                                    View {{isVideo(p.media.awskey) ? 'Video' : 'Photo'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center"
                            *ngIf="(errorMessage) && (!selectedImage || selectedImage.length === 0)">
                            <div class="">
                                <div class="row mt100 mb-3">
                                    <div class="col-sm-5 col-6 mx-auto">
                                        <img src="./assets/images/album-empty.svg" alt="album-empty" class="w-100">

                                    </div>
                                </div>
                                <h1 class="text-black mb-2 fs22 font-weight-bold">Add New Photos/Videos</h1>
                                <p class="fs12 fw-600 text-black mt-3 mb-4">There is no Photos/Videos in this album<br> Please
                                    add some
                                    Photos/Videos by drag&drop or Browse</p>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="mapView && !showPeople">
                        <div class="content-card border-grey br10 shadow-none">
                            <div class="album-map p-4">
                                <span style="margin-left: 7%;font-size: 13px;">Note: Click the photo to find direction
                                    from your location</span>
                                <div class="map-close text-center c-pointer" (click)="showDirection()">
                                    <img src="./assets/images/cross.svg" alt="cross" class="w11">
                                </div>

                                <agm-map [latitude]="lat" [longitude]="lng" [streetViewControl]="false"
                                    class="map-view w-100 br10" [disableDefaultUI]="true" [zoom]="7" [usePanning]="true"
                                    [zoomControl]="true">
                                    <agm-marker [latitude]="mplist.lattitudeNumber" [longitude]="mplist.longitudeNumber"
                                        [markerDraggable]="false" *ngFor="let mplist of mapPhotoList"
                                        iconUrl={{mplist.urlIcon}}>
                                        <agm-info-window [isOpen]="mplist.isOpen">
                                            <img (click)="clickPhoto(mplist)"
                                                *ngIf="mplist.media && mplist.media.awskey && !mplist.myLocation"
                                                src="{{cloudFrontURL}}{{mplist.media.awskey}}" alt="photo" class="w50">
                                            <Strong *ngIf="mplist.myLocation">Your Location</Strong>
                                        </agm-info-window>
                                    </agm-marker>
                                    <agm-direction [markerOptions]="markerOptions" [origin]="origin"
                                        [renderOptions]="renderOptions" [destination]="destination">
                                    </agm-direction>
                                </agm-map>

                                <div class="d-flex justify-content-end get-direction">
                                    <div class="text-center map-pointer c-pointer" (click)="getCurrentLocation()">
                                        <img src="./assets/images/map-pointer.svg" alt="map-pointer" class="w17  mt11">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!mapView && showPeople">
                        <div class="card-header px-4 mb-5 pb-0 bg-white  border-0">
                            <div class="d-sm-flex d-block align-items-center justify-content-between mt20">
                                <div class="mb-2 mb-sm-0">
                                    <h1 class="fw-600 fs20 ls-9 grey-g mb-0">People</h1>
                                </div>
                                <div class=" d-flex justify-content-lg-end align-items-center mb-2"
                                    *ngIf="albumEntity.albumVisibility !== 'PUBLIC'">
                                    <button [disabled]="memberbutton"
                                        class="btn btn-blue-secondry br25 py-2 px-sm-4 mr-sm-3 fs12 buttonload"
                                        (click)="create()">
                                        <em *ngIf="memberbutton" class="fa fa-spinner fa-spin "></em>
                                        <img src="./assets/images/plus.svg" alt="add" class="mr-1 w11 mb-1">
                                        Add Members</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pl30 pr30 py-0">
                            <div class="tag-list mt-2">
                                <div class="d-flex align-items-center justify-content-between border-bottom-a py-3"
                                    *ngFor="let p of peopleList; let i = index">
                                    <div class="d-flex align-items-center">
                                        <img *ngIf="!p.user || !p.user.profileImage" src="./assets/images/empty-img.png"
                                            alt="" class="people-img w40 h40 c-pointer mr-4">
                                        <img *ngIf="p.user && p.user.profileImage"
                                            src={{cloudFrontURL}}{{p.user.profileImage}} alt=""
                                            class="people-img w40 h40 c-pointer mr-4">
                                        <div>
                                            <h1 class="grey-m fs15 mb-0 text-capitalize c-pointer">{{p.user.firstName}}
                                            </h1>
                                            <p class="fs12 grey-l ls-7 fw-600 mb-0">{{p.user.email}}</p>

                                            <p *ngIf="p.user.id === userId" class="fs12 grey-l ls-7 fw-600 mb-0">You</p>
                                        </div>

                                    </div>
                                    <div *ngIf="this.albumEntity.albumVisibility === 'PRIVATE'">
                                        <span *ngIf="userType === '1' || userType === '2'">
                                            <em tooltip="remove" title="remove user" *ngIf="p.user.id !== userId"
                                                class="fas fa-user-times grey-l c-pointer"
                                                (click)="openModal2(p.user.id, i)"></em>
                                        </span>
                                        <span *ngIf="userType === '3'">
                                            <em tooltip="remove" title="remove user"
                                                *ngIf="(p.albumRoleType && p.albumRoleType === 'MEMBER' && p.user.id !== userId)"
                                                class="fas fa-user-times grey-l c-pointer"
                                                (click)="openModal2(p.user.id, i)"></em>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="card-body text-center p-3 "
                            *ngIf="(!peopleList || peopleList.length === 0) && errorMessage">
                            <div class="pending-invites-bg">
                                <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no people<br> Please invite
                                    people by clicking<br> the above button</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- create folder -->
<div id="overlay" [ngClass]="{'d-block': createFolder}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : createFolder}">
    <!-- for invite -->


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Create New Folder</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showFolder()"></em>
    </div>


    <form class="mt-3">
        <div class="form-group input-rounded">
            <label class="fs12 grey-j ls-3">Folder Name</label>
            <input type="text" class="form-control br8 pt22 pb22 fw-600" placeholder="Name of the New Folder">
        </div>
        <div class="row mt50 px-3">
            <div class="col-5 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showFolder()">Cancel</button>

            </div>
            <div class="col-7 pl-1">
                <button class="btn btn-blue br25 pt7 pb7  btn-block fs13 fw-600 ">Create</button>

            </div>


        </div>
    </form>
</div>

<!-- get pdf  -->
<div id="overlay" [ngClass]="{'d-block': GetPdf}"></div>

<div #sidenav class="dark-right-sidebar  grey-y" [ngClass]="{'show-side-nav' : GetPdf}">


    <div class="d-flex brtlr-15 justify-content-between grey-z py-4 px-4">
        <h1 class="text-white fs17 font-weight-bold ls-2 mb-0">Get PDF</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showGetPdf()"></em>
    </div>

    <div class="mt-4 px-4 ">
        <h2 class="fs12 grey-aa ls-3 fw-500">PDF Template</h2>
        <div class="get-pdf mb-3">

            <input class="form-check-input d-none" type="checkbox" value="" id="defaultCheck1">
            <label class="form-check-label d-flex align-items-center justify-content-between p-2 br8   get-pdf-check"
                for="defaultCheck1">
                <div>
                    <em class="far fa-square square-uncheck grey-i"></em>
                    <em class="fas fa-check-square square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">Public</h3>
                    <p class="fs9 mb-0 ls-k get-pdf-subtitle ">Photo summary include all Photos, tags and comments.</p>
                </div>
            </label>
        </div>
        <div class="get-pdf mb-2">

            <input class="form-check-input d-none" type="checkbox" value="" id="defaultCheck2">
            <label class="form-check-label d-flex align-items-center justify-content-between p-2 br8   get-pdf-check"
                for="defaultCheck2">
                <div>
                    <em class="far fa-square square-uncheck grey-i"></em>
                    <em class="fas fa-check-square square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">Private</h3>
                    <p class="fs9 mb-0 ls-k get-pdf-subtitle ">Punch list includes all photos with tags assigned as
                        tasks.</p>
                </div>


            </label>
        </div>

        <p class="fs9 ls-k grey-e text-center">Both formats include a title sheet and a tag summary sheet.</p>
        <div class="row mt50 px-3">
            <div class="col-4 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showGetPdf()">Cancel</button>

            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 ">Download</button>

            </div>
        </div>
    </div>

</div>


<!-- album settings -->
<div id="overlay" [ngClass]="{'d-block': AlbumSettings}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : AlbumSettings}">

    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Album Settings</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showAlbumSettings()"></em>
    </div>

    <div class="contact-scroll pr-2">
        <form class="mt-3" name="albumForm" #albumForm="ngForm" novalidate>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3 fw-500">Name</label>
                <input maxlength="50" name="albumName" required [(ngModel)]="album.albumName" #albumName="ngModel"
                    [ngClass]="{ 'has-error': albumForm.submitted && albumName.invalid }" type="text"
                    class="form-control br8 pt22 pb22 fw-600" placeholder="Test"
                    [readonly]="albumEntity.albumName === 'General'">
                <div class="fs12 grey-j ls-3 fw-500">
                    can not able to modify general album name
                </div>
                <div *ngIf="albumName.invalid && validation">
                    <div *ngIf="albumName.errors.required" class="text-danger fs12">
                        Album name is required.
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3 fw-500">Caption</label>
                <input maxlength="50" type="text" name="description" [(ngModel)]="album.description"
                    #description="ngModel" [ngClass]="{ 'has-error': albumForm.submitted && description.invalid }"
                    class="form-control br8 pt22 pb22 fw-600" placeholder="Test Purpose">
            </div>
            <div class="d-flex justify-content-between align-items-center my-3">
                <div>
                    <h2 class="fs12 grey-j ls-3 mb-1 fw-500">Location</h2>
                    <p class="grey-h fw-500 fs14 mb-0 ">{{lat}}, {{lng}}</p>
                </div>
                <div class="br8 w30 h30 text-center grey-s" (click)="getCurrentLocation()">
                    <img src="./assets/images/location-blue.svg" alt="location-blue" class="w13 mt4">
                </div>
            </div>
            <h2 class="fs12 grey-j ls-3 mb-1 fw-500 mt-3">Created</h2>
            <p class="grey-h fw-500 fs14 mb-3">{{albumEntity.createdOn | amParseZone | amDateFormat: 'MMMM DD yyyy'}}
            </p>

            <h2 class="fs12 grey-j ls-3 mb-1 fw-500 mt-3">Author</h2>
            <p *ngIf="albumEntity.author && albumEntity.author.firstName" class="grey-h fw-500 fs14 mb-3">
                {{albumEntity.author.firstName}}</p>

            <h2 class="fs12 grey-j ls-3 mb-1 fw-500 mt-3 mb-2">Actions</h2>
            <div class="d-flex justify-content-between mb-3">
                <div>
                    <img src="./assets/images/analytics.svg" alt="analytics" class="w40 h40">
                    <p class="mb-0 fs9 grey-h fw-500 mt-1 text-center">Analytics</p>
                </div>
                <div (click)="pdfDownload([])" *ngIf="photoList.length !== 0 && userType !== '5'">
                    <img src="./assets/images/pdf.svg" alt="pdf" class="w40 h40 c-pointer">
                    <p class="mb-0 fs9 grey-h fw-500 mt-1 text-center">Get PDF</p>
                </div>
                <div (click)="comeFromAlbumSetting()">
                    <img src="./assets/images/people.svg" alt="people" class="w40 h40 c-pointer">
                    <p class="mb-0 fs9 grey-h fw-500 mt-1 text-center">People</p>

                </div>
                <div (click)="leftFromAlbum()">
                    <img src="./assets/images/leave.svg" alt="leave" class="w40 h40 c-pointer">
                    <p class="mb-0 fs9 grey-h fw-500 mt-1 text-center">Leave</p>
                </div>
            </div>
            <ng-container *ngIf="userType && userType !== '5'">
                <h2 class="fs12 grey-j ls-3 mb-1 fw-500 mt-3 mb-2">Advanced</h2>
                <button type="button" [disabled]="submitted" (click)="onSubmit(albumForm)"
                    class="btn btn-blue-k br9 pt7 pb7 blue-a btn-block fs13 fw-600 mb-2 buttonload"><em *ngIf="submitted"
                        class="fa fa-spinner fa-spin "></em>Update</button>
                <div class="mb-5">
                    <button *ngIf="userType !== '4'" type="button" (click)="deleteAlbum(albumEntity.albumId)"
                        class="btn btn-red-h br9 pt7 pb7  btn-block fs13 fw-600">Delete</button>
                </div>
            </ng-container>
        </form>
    </div>
</div>

<ng-template #exampleModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to remove user?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethod()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #photodeleteModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete photo?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethodDelete()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #individualphotodeleteModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete {{mediaFormat}}?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesDeleteIndividualPhoto()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide();">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #leaveModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want leave from this album?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesLeave()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deletealbumModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete album?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesDeleteAlbum()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div id="overlay" [ngClass]="{'d-block': showCreate}"></div>
<div #sidenav class="right-sidebar bg-white px-15" [ngClass]="{'show-side-nav' : showCreate}">
    <div class="d-flex justify-content-between pl-3">
        <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3">Add Members</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="create()"></em>
    </div>
    <div class="contact-scroll px-3">
        <form class="mt-2" name="albumForm" #albumForm="ngForm" novalidate>
            <div [ngClass]="{'d-block':addMembers,'d-none':!addMembers}">
                <div class="form-group mb-2 input-rounded">
                    <label class="fs12 grey-j ls-3 fw-500">Search for Members</label>
                    <input type="search" class="form-control br8 pt22 pb22 fw-600" placeholder="Search by email"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="userSearch" (input)="searchFilter()">
                </div>

                <p class="fs10 ls-4 grey-j ml-2">Enter the email ID belonging to the person you'd like to invite in the
                    form
                    above.</p>
                <div *ngIf="tempGetMembers && tempGetMembers.length === 0" class="my-3">
                    <button [disabled]="submitted" class="btn  btn-blue-secondry px-sm-3 px-2 br25 py-2 fs12 buttonload"
                        type="button" (click)="ViewInvite()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>
                        <img src="./assets/images/plus.svg" alt="add" class="mr-1 w11 mb-1">Invite</button>
                </div>
                <div class="mt-2">
                    <h2 class="fs14 grey-m fw-600" *ngIf="PendingMembers.length > 0">Members to be invited in this Album
                    </h2>
                    <div class="tag-list mt-2">
                        <div class="d-flex align-items-center justify-content-between border-bottom-a py-2"
                            *ngFor="let members of PendingMembers; let i = index;">
                            <div class="d-flex align-items-center">
                                <img src="./assets/images/empty-img.png" alt="user1" class=" mr-4 people-img w35 h35">
                                <div>
                                    <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">{{members.firstName}}</h1>
                                    <div class="fs12 grey-l ls-7 fw-600 mb-0">{{members.email}}</div>
                                </div>
                            </div>
                            <div (click)="removePendingMember(i)">
                                <em aria-hidden="true" title="cancel"
                                    class="fas fa-times mr-2 red-f fa-xs c-pointer"></em>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <h2 class="fs14 grey-m fw-600" *ngIf="tempGetMembers && tempGetMembers.length > 0">Project Members
                    </h2>
                    <div class="tag-list mt-1">
                        <div class="text-center" *ngIf="!tempGetMembers || tempGetMembers.length === 0">Member
                            not found, You can invite member to album</div>
                        <div class="d-flex align-items-center justify-content-between border-bottom-a py-3"
                            *ngFor="let members of tempGetMembers; let i = index;">
                            <div class="d-flex align-items-center">
                                <img *ngIf="!members.user || !members.user.profileImage"
                                    src="./assets/images/empty-img.png" alt="" class="mr-4 people-img w35 h35">
                                <img *ngIf="members.user && members.user.profileImage"
                                    src={{cloudFrontURL}}{{members.user.profileImage}} alt=""
                                    class="mr-4 people-img w35 h35">
                                <div>
                                    <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">{{members.user.firstName}}</h1>

                                    <div class="fs12 grey-l ls-7 fw-600 mb-0">{{members.user.email}}</div>
                                    <div class="fs12 grey-l ls-7 fw-600 mb-0"
                                        *ngIf="members.roleType && members.roleType.id === 1 && members.user && members.user.id !== userId">
                                        Owner always has
                                        permission
                                    </div>
                                    <div class="fs12 grey-l ls-7 fw-600 mb-0"
                                        *ngIf="members.roleType && members.roleType.id === 2 && members.user && members.user.id !== userId">
                                        Admin always has
                                        permission
                                    </div>
                                    <div class="fs12 grey-l ls-7 fw-600 mb-0"
                                        *ngIf="members.user && members.user.id === userId">You</div>
                                </div>


                            </div>
                            <div class="get-pdf"
                                *ngIf="members.roleType && (members.roleType.id !== 1) && members.roleType.id !== 2 &&  members.user &&  members.user.id !== userId">
                                <input type="checkbox" class="d-none form-check-input" name="radioNoLabel"
                                    id="radioNoLabel{{members.user.id}}" value="{{members.user.id}}"
                                    [checked]="members.checked === true ? true : false" aria-label="..."
                                    (change)="selectedMembers($event,members.user.id,i)">
                                <label class="mb-0 get-pdf-check bg-white border-0 c-pointer"
                                    for="radioNoLabel{{members.user.id}}">
                                    <em class="far fa-circle square-uncheck grey-i fs12"></em>
                                    <em class="fas fa-circle square-checked blue-a fs12"></em>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt50 px-3 mb-3 mx-0">
                <div class="col-5 pr-1">
                    <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                        type="button" (click)="create()">Cancel</button>

                </div>
                <div class="col-7 pl-1">
                    <button type="button" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                        [disabled]="submitted" (click)="membersAdd()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Submit</button>
                </div>
            </div>

        </form>

    </div>

</div>

<div id="overlay" [ngClass]="{'d-block': showInviteBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showInviteBar}">
    <!-- for invite -->
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Invite</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="ViewInvite()"></em>
    </div>
    <div class="scroll-content">
        <form class="mt-3" name="form" [formGroup]='inviteForm' novalidate>
            <label class="fs12 grey-j ls-3">Name*</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="firstName" tabindex=1 class="form-control pt22 pb22 fw-600"
                            placeholder="First Name"
                            (keydown.space)="inviteForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                            *ngIf="(inviteForm.get('firstName').errors && Invitesubmitted) || (inviteForm.get('firstName').errors?.required && submitted)">
                            Please enter first name
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="lastName" tabindex=2 class="form-control pt22 pb22 fw-600"
                            placeholder="Last Name"
                            (keydown.space)="inviteForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                            *ngIf=" (inviteForm.get('lastName').errors && Invitesubmitted) || (inviteForm.get('lastName').errors?.required && submitted)">
                            Please enter last name
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">E-Mail*</label>
                <input type="text" formControlName="email" tabindex=3 class="form-control pt22 pb22 fw-600"
                    placeholder="Enter E-mail" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    (keydown.space)="inviteForm.get('email').value.trim().length > 0 ? '': $event.preventDefault();">
                <div class="fs12 text-danger"
                    *ngIf=" (inviteForm.get('email').errors && Invitesubmitted) || (inviteForm.get('email').errors?.required && submitted)">
                    Please enter email
                </div>
                <div *ngIf="(inviteForm.get('email').errors && Invitesubmitted) || (inviteForm.get('email').errors?.pattern && submitted)"
                    class="fs12 text-danger">
                    Enter a valid email.
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Role*</label>
                <select class="custom-select input-group-text" name="role" formControlName="roleType" tabindex=4>
                    <option value="" disabled>select</option>
                    <option *ngFor="let user of userRoleList" value={{user.id}}>{{user.roleName}}</option>
                </select>
                <div class="fs12 text-danger"
                    *ngIf=" (inviteForm.get('roleType').errors && Invitesubmitted) || (inviteForm.get('roleType').errors?.required && submitted)">
                    Please choose role type
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Company Name</label>
                <input type="text" formControlName="companyName" tabindex=5 class="form-control pt22 pb22 fw-600"
                    placeholder="Enter Company Name"
                    (keydown.space)="inviteForm.get('companyName').value.trim().length > 0 ? '': $event.preventDefault();">
            </div>

            <div class="row mt50 mb-3 px-3">
                <div class="col-4 pr-1">
                    <button tabindex="6" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600"
                        (click)="ViewInvite()" type="button">Cancel</button>
                </div>
                <div class="col-8 pl-1">
                    <button [disabled]="!inviteForm.valid" tabindex="7"
                        class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600" type="button"
                        (click)="inviteMethod(inviteForm)">
                        Invite to album</button>
                </div>
            </div>
        </form>
    </div>

</div>


<app-share-album-sidemenu [Invite]="currentInvite" [albumId]="currentAlbumId" (trigerInvite)="changeSidemenu($event)">
</app-share-album-sidemenu>


<div id="overlay" [ngClass]="{'d-block': pdfModalSelect}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : pdfModalSelect}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Get PDF</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="cancelPdf()"></em>
    </div>
    <form>
        <div class="get-pdf mb-3 mt-4">
            <label class="fs12 grey-j ls-3 fw-500">Should this album be Public or private</label>
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Photo summary'" class="form-check-input d-none" type="radio" id="inlineRadio3" name="otpType" value="Photo summary">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio3">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary include all Photos, tags and comments.</h2>
                </div>
            </label>
        </div>
    
        <div class="get-pdf my-3">
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Punch List'" class="form-check-input d-none" type="radio" id="inlineRadio4" name="otpType"
                 value="Punch List">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio4">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list includes all photos with tags assigned as tasks.</h2>
                </div>
            </label>
        </div>
        <h2 class="fs10 mb-0 ls-c ml14">Both formats include a title sheet and a tag summary sheet.</h2>
    
       </form>

        <div class="row mt50 px-3 ">
            <div class="col-4 pr-1">
               <button [disabled]="pdfSubmitted" class="btn btn-grey-f text-black br25 pt7 pb7 btn-block fs13 fw-500" type="button"
               (click)="cancelPdf()">Cancel</button>
            </div>
    
            <div class="col-8 pl-1">
                <button [disabled]="pdfSubmitted" class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-500 buttonload" type="button"
                (click)="generatePDF()">
                <em *ngIf="pdfSubmitted" class="fa fa-spinner fa-spin"></em>Download</button>
             </div>       
         </div>

</div>