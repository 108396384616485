import {
    Component, OnInit, ViewChildren, ViewChild, EventEmitter, Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api_base/api.service';
import { stringify } from '@angular/compiler/src/util';

@Component({
    selector: 'app-otp-verify',
    templateUrl: './otp-verify.component.html',

})
export class OtpVerifyComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility

    public formInput = ['input1', 'input2', 'input3', 'input4'];

    @ViewChildren('formRow') public rows: any;

    public form: FormGroup;

    public submitted = false;

    public submitted1 = false;

    public email = '';

    public username = '';

    public showreset: boolean;

    public otp = '';

    public source: string = 'Email and Mobile Number';

    public filterSidemenu: boolean;

    public config = {
        allowNumbersOnly: true,
        length: 4,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: '',
    };

    @ViewChild('ngOtpInput') public ngOtpInputRef: any;


    public constructor(private api: LoginService,
        private toastr: ToastrService,
        private router: Router, private apis: ApiService,private activeRoute: ActivatedRoute,) {
        this.form = this.toFormGroup(this.formInput);
    }

    public ngOnInit(): void {
        this.activeRoute.queryParams.subscribe((params): any => {
            if (params && params.source) {
                if(params.source === '1') {
                    this.source = 'Mobile Number'
                } else {
                    this.source = 'Email'
                }
            }
        });
        if (localStorage.getItem('token') && localStorage.getItem('userId')) {
            this.router.navigate(['/company'], { queryParams: this.apis.getSessionData() });
        } else {
            if (localStorage.getItem('email')) {
                this.email = localStorage.getItem('email');
            }
            if (localStorage.getItem('username')) {
                this.username = localStorage.getItem('username');
            }
        }
    }

    public onOtpChange(otp): void {
        this.otp = otp;
    }


    public ToggleResetpassword(): any {
        this.showreset = !this.showreset;
    }

    public toFormGroup(elements: any): any {
        const group: any = {};
        elements.forEach((key: any): any => {
            group[key] = new FormControl('', Validators.required);
        });
        return new FormGroup(group);
    }


    public onSubmit(): void {
        this.toastr.clear();
        if (this.otp && this.otp.length === 4) {
            if (localStorage.getItem('type') === '2' || localStorage.getItem('type') === '3') {
                this.submitted = true;
                this.api.otpVerify({ otp: this.otp }).subscribe((res): any => {
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success('Please enter your new password.');
                        localStorage.setItem('otp', this.otp);
                        this.ToggleResetpassword();
                    } else if (res && res.status && res.status.status === 229) {
                        this.toastr.error('Please enter valid otp.');
                    }
                }, (err): any => {
                    this.submitted = false;
                    this.toastr.error('Something went wrong, Please login again and try. ');
                    this.router.navigate(['/login']);
                });
            } else {
                this.submitted = true;
                const data = {
                    otp: this.otp,
                    userId: localStorage.getItem('userId'),
                    username: this.email,
                    password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                    workspaceFlag: 'create',
                };
                this.api.verifyOtp(data).subscribe((res): any => {
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success('Thank you for verify your account');
                        if (res.entity && res.entity.login && res.entity.login.access_token) {
                            localStorage.setItem('token', res.entity.login.access_token);
                            localStorage.removeItem('password');
                            localStorage.removeItem('type');
                            // localStorage.removeItem('email');
                            localStorage.removeItem('username');
                            // if (res.entity && res.entity.pendingList && res.entity.pendingList.length > 0) {
                            //     localStorage.setItem('pendinglist', JSON.stringify(res.entity.pendingList));
                            //     this.router.navigate(['/pending-invites']);
                            // } else {
                            // }

                            const wsName = res.entity.workSpaceName ? res.entity.workSpaceName : res.entity.login.userName;
                            setTimeout((): any => {
                                this.router.navigate(['/create-workspace'], { queryParams: { ...this.apis.getSessionData(), ...{ name: wsName } } });
                            }, 300);
                        } else {
                            setTimeout((): any => {
                                this.router.navigate(['/login']);
                            }, 300);
                        }
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                }, (err): any => {
                    this.submitted = false;
                    this.toastr.error('Please enter valid otp');
                });
            }
        } else {
            this.toastr.error('Please enter valid otp.');
        }
    }


    public goToBack(): void {
        window.history.back();
    }

    public reSendOTP(): void {
        this.submitted1 = true;
        if (!localStorage.getItem('email')) {
            return;
        }
        this.toastr.clear();
        const payload: any = { email: localStorage.getItem('email'), type: parseInt(localStorage.getItem('type')) };
        if (localStorage.getItem('otpType')) {
            payload['otpType'] = localStorage.getItem('otpType');
        };
        this.api.sendOtpInLogin(payload).subscribe(
            (res): void => {
                this.submitted1 = false;
                if (res.status.status === 200) {
                    this.toastr.success('An otp is sent successfully');
                    this.ngOtpInputRef.setValue('');
                } else if (res.status.status === 204) {
                    this.toastr.error('Something went wrong, Please try again later');
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted1 = false;
                this.apis.handleError(err);
            },
        );
    }
}
