import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SupportService } from '../services/support.service';
import { UserService } from '../services';
import { ApiService } from '../services/api_base/api.service';
import { BillingHistoryFilterComponent } from '../common-components/billing-history-filter/billing-history-filter.component';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { NgForm } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface billingFilter {
    startDate: Date;
    endDate: Date;
    type: number;
}

@Component({
    selector: 'app-workspace-settings',
    templateUrl: './workspace-settings.component.html',
    styles: [`.input-with-color {
        position: relative;
        display: inline-block;
        width: 100%;
      }
      
      .input-with-color input[type="color"] {
        position: absolute;
        top: 50%;
        right: 12px;
        height: 30px;
        width: 30px;
        background: none;
        border: none;
        transform: translateY(-50%);
      }`]
})
export class WorkspaceSettingsComponent implements OnInit {
    @ViewChild(BillingHistoryFilterComponent) public billingFilterComp: BillingHistoryFilterComponent;

    @ViewChild('addTagModal') public addTagModal: TemplateRef<any>;

    @ViewChild('deleteGlobalTagModel') public deleteGlobalTagModel: TemplateRef<any>;

    public globalTagForm: FormGroup;

    public filterSidemenu = false;

    public addCard = false;

    public Payment = false;

    public viewMode = 'Users';

    public viewProfile: any;

    public userDetails: any;

    public buttontext = '';

    public cardForm: FormGroup;

    public Invitesubmitted = false;

    public submitted = false;

    public customerId = '';

    public counts: any;

    public card: any;

    public subscription: any;

    public trialDifference = 0;

    public nextDueDate: any;

    public modalRef: BsModalRef;

    public billingHistoryList = [];

    public quantity = 0;

    tagslist = [];

    selectedTags = [];

    tagId = ''

    @ViewChild('cancelsubscription') public cancelsubscription: TemplateRef<any>;

    public config = {
        ignoreBackdropClick: true,
    };

    public filterObj: billingFilter = {
        startDate: null,
        endDate: null,
        type: null,
    };

    public pageNumber = 1;

    public pageRecord = 10;

    public stopScroll = true;

    public userList = [];

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public peopleSelector = '.people-scroll';

    public createWorkspace: any = {};

    public constructor(
        private support: SupportService,
        private modalService: BsModalService,
        public userService: UserService,
        private cardBuilder: FormBuilder,
        private toastr: ToastrService,
        private api: ApiService,
        private router: Router,
        private workspaceService: WorkspaceServiceService
    ) {
        this.createBuilderForm();
        this.getProfile();
    }


    public ngOnInit(): void {
        this.getWorkspaceUserList();
        this.getCount();
        this.getCard();
        this.getSubscription();
    }

    public createBuilderForm(): void {
        this.cardForm = this.cardBuilder.group({
            firstName: ['', Validators.required],
            // lastName: [''],
            cardnumber: ['', Validators.required],
            expiry: ['', Validators.required],
            cvv: ['', Validators.required],
        });
    }

    public getCount(): void {
        this.submitted = true;
        this.userService.getCount().subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.counts = res.entity;
                if ((sessionStorage.getItem('subscription') === '1' ? 'BASIC' : 'PRO') !== res.entity.currentPlan) {
                    if (res.entity.currentPlan === 'PRO') {
                        sessionStorage.setItem('subscription', '2');
                        this.support.subscription.next(2);
                    } else {
                        sessionStorage.setItem('subscription', '1');
                        this.support.subscription.next(1);
                    }
                }
            } else {
                this.submitted = false;
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        });
    }

    public getCard(): void {
        this.userService.getCard().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.card = res.entity;
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public getSubscription(): void {
        this.userService.getSubscription().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.subscription = res.entity;
                this.trialDifference = 0;
                if (this.subscription.trialEnd) {
                    const trailEnd = new Date(this.subscription.trialEnd * 1000);
                    const diffTime = (trailEnd.getTime() - new Date().getTime());
                    this.trialDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                }

                if (this.subscription.currentEnd && this.trialDifference <= 0) {
                    this.nextDueDate = new Date(this.subscription.currentEnd * 1000);
                }

                if (this.subscription && this.subscription.status && this.subscription.status === 'active') {
                    this.userService.getSubscriptionItem(this.subscription.customerId).subscribe((sub): any => {
                        if (sub) {
                            if (sub.data[0] && sub.data[0].quantity) {
                                this.quantity = sub.data[0].quantity;
                            }
                        }
                    });
                }
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public saveCard(): void {
        this.addCard = !this.addCard;
    }

    public getAmount(): number {
        let value = 0;
        if (this.quantity) {
            value = (+this.quantity * 12);
        }
        return value;
    }

    public getCardToken(cardForm): void {
        this.Invitesubmitted = true;
        if (!cardForm.valid) {
            return;
        }

        this.submitted = true;
        const mmyy = this.cardForm.get('expiry').value.split('/');
        const cardDetails = {
            id: '',
            number: this.cardForm.get('cardnumber').value,
            exp_month: mmyy[0],
            exp_year: mmyy[1],
            cvc: this.cardForm.get('cvv').value,
        };

        if (this.card) {
            cardDetails.id = this.card.paymentId;
            cardDetails.cvc = '';
        }

        this.userService.paymentMethod(cardDetails).subscribe((res): any => {
            if (res && res.id) {
                this.saveCardToPixly(res);
            } else {
                this.submitted = false;
            }
        }, (err): any => {
            this.submitted = false;
            this.toastr.error(err.error.error.message);
        });
    }

    public saveCardToPixly(response): void {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            paymentId: response.id,
            cardLast4: response.card.last4,
            expMonth: response.card.exp_month,
            expYear: response.card.exp_year,
            customerId: this.card ? this.card.customerId : '',
            firstName: this.cardForm.get('firstName').value,
            // lastName: this.cardForm.get('lastName').value,
            brand: response.card.brand,
            funding: response.card.funding,
        };
        this.userService.saveCardToPixly(data).subscribe((res1): any => {
            if (res1 && res1.status && res1.status.status === 200) {
                if (this.card) {
                    this.submitted = false;
                    this.Invitesubmitted = false;
                    this.getCard();
                    this.showPayment('');
                    this.toastr.success('Card details updated');
                    return;
                }
                this.userService.saveSubscription().subscribe((res2): any => {
                    this.submitted = false;
                    this.Invitesubmitted = false;
                    if (res2 && res2.status && res2.status.status === 200) {
                        this.showPayment('');
                        this.getCount();
                        this.getCard();
                        this.getSubscription();
                        this.createBuilderForm();
                        this.toastr.success('Thanks for Subscription');
                    }
                });
            } else {
                this.submitted = false;
                this.toastr.error(res1.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
        });
    }

    public monthchk(month): any {
        let returnmonth;
        if (month && month.length === 2) {
            returnmonth = month;
        } else {
            returnmonth = `0${month}`;
        }
        return returnmonth;
    }

    public showPayment(type): void {
        this.Payment = !this.Payment;
        if (this.card) {
            if (type === 2) {
                this.buttontext = 'Edit Card';
                this.cardForm.get('firstName').setValue(this.card.firstName);
                // this.cardForm.get('lastName').setValue(this.card.lastName);
                this.cardForm.get('cardnumber').setValue(`XXXX XXXX XXXX ${this.card.cardLast4}`);
                this.cardForm.get('expiry').setValue(`${this.monthchk(this.card.expMonth)}/${this.card.expYear.toString().substring(2, 4)}`);
                this.cardForm.get('cvv').setValue('111');
            }
        } else if (type === 1) {
            this.buttontext = 'Upgrade to Pixly Pro';
        }
    }

    public getProfile(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        this.userService.viewProfile(payload).subscribe(
            (res): any => {
                if (res.entity && res.entity.subscriptionStatus) {
                    this.userDetails = res.entity;
                }
            },
        );
    }

    public cancelPopup(): void {
        this.getCount();
        this.modalRef = this.modalService.show(
            this.cancelsubscription,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public yesMethod(): void {
        if (+this.counts.workspacePhotoCount > 300 || +this.counts.workspaceProjectcount > 3) {
            this.toastr.error('To downgrade Basic plan your workspace should have Photos below 300 and Projects below 3.');
            this.modalRef.hide();
        } else {
            this.submitted = true;
            this.userService.cancelStripePlan(this.subscription, this.trialDifference).subscribe(
                (res1): any => {
                    this.userService.cancelSubscription().subscribe(
                        (res): any => {
                            this.submitted = false;
                            if (res && res.status && res.status.status === 200) {
                                this.getCount();
                                this.toastr.success('Your plan have been cancelled');
                                this.modalRef.hide();
                            } else {
                                this.toastr.error(res.status.msg);
                            }
                        }, (err): any => {
                            this.submitted = false;
                            this.api.handleError(err);
                        },
                    );
                },
            );
        }
    }

    public continueSubscription(): any {
        this.submitted = true;
        this.userService.saveSubscription().subscribe((res2): any => {
            this.submitted = false;
            if (res2 && res2.status && res2.status.status === 200) {
                this.getCount();
                this.getSubscription();
                this.toastr.success('Thanks for Subscription');
            } else {
                this.toastr.error(res2.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        });
    }

    public setBillingHistory(type): any {
        if (this.viewMode !== 'Billinghistory') {
            this.viewMode = type;
            this.getBillingHistory();
        }
    }

    public getBillingHistory(): any {
        this.submitted = true;
        const fDate = this.filterObj.startDate;
        const eDate = this.filterObj.endDate;
        if (this.filterObj.startDate) {
            fDate.setHours(0);
            fDate.setMinutes(0);
            fDate.setSeconds(0);
        }

        if (this.filterObj.endDate) {
            eDate.setHours(23);
            eDate.setMinutes(59);
            eDate.setSeconds(59);
        }

        const strDate = this.filterObj.startDate === null ? '' : (new Date(fDate.toUTCString()).getTime() / 1000);
        const endDate = this.filterObj.endDate === null ? '' : (new Date(eDate.toUTCString()).getTime() / 1000);
        const typ = this.filterObj.type ? this.filterObj.type : '';

        const path = `pixlypayment/getbillinghistory?startDate=${strDate}&endDate=${endDate}&type=${typ}&pageNo=1&pageRecord=${this.pageRecord}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.userService.getBillinghistoryApi(path).subscribe((res2): any => {
            this.submitted = false;
            this.filterSidemenu = false;
            if (res2 && res2.status && res2.status.status === 200) {
                this.billingHistoryList = res2.entity;
            } else {
                this.stopScroll = false;
                this.billingHistoryList = [];
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        });
    }

    public closeFilterSidemenu(event): void {
        this.filterSidemenu = event;
    }

    public getFilterObject(event): void {
        this.filterObj.startDate = new Date(event.fromDate);
        this.filterObj.endDate = new Date(event.toDate);
        this.filterObj.type = Number(event.actions);
        this.getBillingHistory();
    }

    public clearFilterObj(): void {
        this.billingFilterComp.clearFilterFromParent();
        this.filterObj.startDate = null;
        this.filterObj.endDate = null;
        this.filterObj.type = null;
        this.getBillingHistory();
    }

    public convertUtcToDate(utctimestamp): any {
        return new Date(utctimestamp * 1000);
    }

    public onScroll(): any {
        if (this.stopScroll) {
            this.pageNumber += 1;

            const fDate = this.filterObj.startDate;
            const eDate = this.filterObj.endDate;
            if (this.filterObj.startDate) {
                fDate.setHours(0);
                fDate.setMinutes(0);
                fDate.setSeconds(0);
            }

            if (this.filterObj.endDate) {
                eDate.setHours(23);
                eDate.setMinutes(59);
                eDate.setSeconds(59);
            }

            const strDate = this.filterObj.startDate === null ? '' : new Date(fDate.toUTCString()).getTime();
            const endDate = this.filterObj.endDate === null ? '' : new Date(eDate.toUTCString()).getTime();
            const typ = this.filterObj.type ? this.filterObj.type : '';

            const path = `pixlypayment/getbillinghistory?startDate=${strDate}&endDate=${endDate}&type=${typ}&pageNo=${this.pageNumber}&pageRecord=${this.pageRecord}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
            this.userService.getBillinghistoryApi(path).subscribe((res2): any => {
                this.filterSidemenu = false;
                if (res2 && res2.status && res2.status.status === 200) {
                    if (this.billingHistoryList.length > 0) {
                        res2.entity.forEach((element): void => {
                            this.billingHistoryList.push(element);
                        });
                    }
                } else {
                    this.stopScroll = false;
                }
            }, (err): any => {
                this.api.handleError(err);
            });
        }
    }

    public retryInvoice(invoiceId): void {
        if (this.submitted) {
            return;
        }
        this.submitted = true;
        this.api.retryInvoice(invoiceId).subscribe((res): any => {
            this.submitted = false;
            this.getBillingHistory();
        }, (err): any => {
            this.toastr.clear();
            this.submitted = false;
            this.toastr.error(err.error.error.message);
        });
    }

    public getWorkspaceUser(type): void {
        if (this.viewMode !== 'Users') {
            this.viewMode = type;
            this.getWorkspaceUserList();
        }
    }

    public getWorkspaceUserList(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: '',
            sortby: '',
            orderby: '',
            pagenumber: this.pageNumber,
            pagerecord: this.pageRecord,
        };
        this.api.getworkspaceUserList(payload).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                res.entity.forEach((element) => {
                    if (element.id !== localStorage.getItem('userId')) {
                        this.userList.push(element);
                    }
                });
            } else {
                this.stopScroll = false;
            }
        });
    }

    public onScrollUser(): any {
        if (this.stopScroll) {
            this.pageNumber += 1;
            this.getWorkspaceUserList();
        }
    }

    public viewOtherProfile(user): any {
        this.router.navigate(['/people-details'], {
            queryParams: {
                userid: user.id,
                url: 'company',
                prevPage: '/company/workspace-settings'
            },
        });
    }

    getAllTagsById () {
        const userId = localStorage.getItem('userId')
        const tenantId = localStorage.getItem('tenantId');
        if (userId && tenantId) {
            this.userService.getAllTags(userId, tenantId).subscribe(
                (res): any => {
                    if (res && res.length) {
                        this.tagslist = res;
                    } else {
                        // this.toastr.error('No Tags found');
                    }
                },
                (err): any => {
                    this.api.handleError(err);
                },
            );
        }
    }

    getUserTags (type) {
        this.viewMode = type
        this.getAllTagsById();
    }

    public getFirstAndLastLetterForTag(name): string {
        let value = 'TG';
        if (name) {
            const split = Array.from(name);
            value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
        }
        return value;
    }

    selectedTagMethod(tag) {
        const tagIndex = this.selectedTags.findIndex(selectedTag => selectedTag.tagId === tag.tagId);
        if (tagIndex === -1) {
          this.selectedTags.push(tag);
        } else {
          this.selectedTags.splice(tagIndex, 1);
        }
    }

    isSelected(tag) {
        return this.selectedTags.some(selectedTag => selectedTag.tagId === tag.tagId);
    }

    public openTagModal(): void {
        this.modalRef = this.modalService.show(
            this.addTagModal,
            { ...this.config, class: 'modal-md popup-center' },
        );
    }

    createTag (globalTagForm: NgForm) {
        this.submitted = true;
        const payload = {
            tagName: globalTagForm.value.tagName,
            purpose: globalTagForm.value.tagPurpose,
            color: globalTagForm.value.tagColor
        };
        this.toastr.clear();
        const userId = localStorage.getItem('userId')
        const tenantId = localStorage.getItem('tenantId');
        this.workspaceService.addTagsForTenant(userId, tenantId, payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.tagId) {
                    this.toastr.success('Tag created successfully');
                    this.getAllTagsById()
                    globalTagForm.reset()
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    public openModal(val): any {
        this.tagId = val.tagId;
        this.modalRef = this.modalService.show(
            this.deleteGlobalTagModel,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    deleteTag() {
        const userId = localStorage.getItem('userId')
        const tenantId = localStorage.getItem('tenantId');
        this.workspaceService.removeTagForWorkspace(userId, tenantId, this.tagId).subscribe((res): any => {
            if (res && res.status.code == 204) {
                this.toastr.success(res.status.message);
                this.modalRef.hide();
                this.getAllTagsById()
            }
        }, (err) => {
            this.api.handleError(err);
            this.modalRef.hide();
        });
    }
}
