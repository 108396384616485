import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api_base/api.service';
import { environment } from 'src/environments/environment.prod';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupportService } from 'src/app/services/support.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  companyList: any = [];
  public pageNumber = 1;
  public pageRecord = 10;
  public showreset: boolean;
  tagNavSwitch = '';
  public showNav = false;
  public getWorkspace: any = [];
  public showAddCompanyBar: boolean = false;
  public addCompanyForm: FormGroup;
  public submittedForm: boolean = false;
  public submitted: boolean = false;
  public isSuperAdmin: boolean = false;
  public selectedCompanyId: string;
  activeTab: string = 'people';
  companyName: string;
  userDetails: any = {};
  isLoading: boolean = false;

  constructor(
    private apis: ApiService,
    private workspaceService: WorkspaceServiceService,
    private formBuilder: FormBuilder,
    private support: SupportService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.getAllWorkspaces(0, 0);
    this.createBuilderForm();
  }

  public createBuilderForm(): any {
    this.addCompanyForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyProfileImage: [''],
      address: [''],
      addressLine1: [''],
      addressLine2: [''],
      phoneCode: [''],
      phoneNumber: [''],
      email: [''],
      owner: this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}')]]
      })
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((res: any) => {
      this.companyName = res.params['name'];
    });
   this.getProfile();
  }

  preventSpaces(event: KeyboardEvent, controlName: string) {
    if (event.key === ' ') {
      const control = this.addCompanyForm.get(controlName);
      if (control && control.value.trim().length === 0) {
        event.preventDefault();
      }
    }
  }

  getCompanyList(created = false) {
    this.apis.getCompanyList().subscribe((res): any => {
      if (res) {
        this.companyList = res;
        if (created) {
          this.selectedCompanyId = this.companyList[this.companyList.length - 1].companyId;
        } else {
          this.selectedCompanyId = this.companyList[0].companyId;
        }
        this.support.companyProfile.next({ selectedCompanyId: this.selectedCompanyId });
      }
    });
  }

  getCurrentUserCompany() {
    this.apis.getCurrentUserCompany().subscribe((res): any => {
      if (res) {
        this.support.companyProfile.next({ selectedCompanyId: res.companyId });
      }
    });
  }

  public toggleNav(value): any {
    if (value) {
      this.tagNavSwitch = value;
      this.showNav = !this.showNav;
    } else if (value == '' || value == undefined) {
      this.tagNavSwitch = 'workspace';
      this.showNav = false;
    }
  }

  public getAllWorkspaces(pageNumber, pageRecord): void {
    this.workspaceService.getAllWorkspace(pageNumber, pageRecord).subscribe(
      (res): any => {
        if (res && res.status && res.status.status === 200) {
          this.getWorkspace = res.entity;
        }
      },
      (err): any => {
        this.apis.handleError(err);
      },
    );
  }

  createCompany() {
    this.submittedForm = true;
    if (this.addCompanyForm && this.addCompanyForm.status === 'VALID') { 
      this.isLoading = true;
      const data = this.addCompanyForm.value;
      this.apis.addCompany(data).subscribe((res): any => {
        this.isLoading = false;
        this.getCompanyList(true);
      }, err => {
        this.isLoading = false;
        this.apis.handleError(err);
      });
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onCompanyChange() {
    this.support.companyProfile.next({ selectedCompanyId: this.selectedCompanyId });
  }
  public getProfile(): void {
    const payload = {
        userId: localStorage.getItem('userId'),
    };
    this.userService.viewProfile(payload).subscribe(
        (res): any => {
          this.userDetails = res.entity;
          this.isSuperAdmin = this.userDetails.adminFlag;
          if (this.isSuperAdmin) {
            this.getCompanyList();
          } else {
            this.getCurrentUserCompany();
          }
        }, (err): any => {
            this.apis.handleError(err);
        },
    );
  }
}
