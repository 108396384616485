/* eslint-disable no-empty-function */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService, LoginService } from 'src/app/services';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
})
export class SetPasswordComponent implements OnInit {
    public togglePasswordOne = false;

    public togglePasswordTwo = false;

    public submitted = false;

    public datas: any;

    public secondParam: any;

    public thirdParam: any;

    public setPassword: any = {};

    @ViewChild('setPasswordForm') public setPasswordForm;

    public constructor(private router: Router,
        private loginService: LoginService,
        private toastr: ToastrService,
        private activeRoute: ActivatedRoute,
        private workspaceService: WorkspaceServiceService,
        private api: ApiService) {
        this.activeRoute.queryParams.subscribe((params): any => {
            this.datas = params;
        });
    }

    public ngOnInit(): void {
        if (localStorage.getItem('token') && localStorage.getItem('userId')) {
            this.verifyCodeMethod();
        } else {
            this.activeRoute.queryParams.subscribe((params): any => {
                this.datas = params;
                if (this.datas.user !== 'Non_pixly_user') {
                    this.router.navigate(['/login'], { queryParams: this.datas });
                } else if (this.datas.bulkUpload === 'false' || this.datas.bulkUpload === false) {
                    this.router.navigate(['/signup'], { queryParams: this.datas });
                } else {
                    this.verifyCodeMethod();
                }
            });
            this.setPassword = {
                password: '',
            };
        }
    }

    public navigateToWorkspace(): void {
        if (this.datas && this.datas.workSpaceName) {
            sessionStorage.setItem('workspacename', this.datas.workSpaceName);
            if (this.datas.projectId && this.datas.projectId !== 'null') {
                localStorage.removeItem('email');
                localStorage.removeItem('username');
                sessionStorage.setItem('projectId', this.datas.projectId);
                this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
                    (res): any => {
                        if (res && res.entity) {
                            if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                                sessionStorage.setItem('usertype', res.entity.roleId);
                                setTimeout((): any => {
                                    if (res.entity.roleId === 3) {
                                        this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
                                    } else {
                                        this.router.navigate(['/project/feed'], { queryParams: this.api.getSessionData() });
                                    }
                                }, 300);
                            }
                        }
                    },
                );
            } else {
                this.workspaceService.getUserTypeService();
            }
        } else {
            this.router.navigate(['/company'], { queryParams: this.api.getSessionData() });
        }
    }

    public verifyCodeMethod(): void {
        const payload = {
            workSpaceName: this.datas.workSpaceName,
            userId: this.datas.userid,
            verificationCode: this.datas.VERIFY_TEAM_MEMBER,
            workspaceFlag: 'create',
        };

        this.loginService.verifyCode(payload).subscribe(
            (res): any => {
                if (res.status.status === 204) {
                    this.toastr.clear();
                    if (!localStorage.getItem('token') || !localStorage.getItem('userId')) {
                        this.router.navigate(['/login']);
                    } else if (localStorage.getItem('userId') === this.datas.userid) {
                        this.navigateToWorkspace();
                    } else {
                        this.toastr.error('Someother user is logged in. Please logout an existing user before verify your link. ');
                        this.router.navigate(['/company'], { queryParams: this.api.getSessionData() });
                    }
                } else if (res.status.status === 200) {
                    if (localStorage.getItem('token') && localStorage.getItem('userId')) {
                        if (localStorage.getItem('userId') === this.datas.userid) {
                            this.onSubmit('');
                        } else {
                            this.toastr.error('Someother user is logged in. Please logout an existing user before verify your link. ');
                            this.router.navigate(['/company'], { queryParams: this.api.getSessionData() });
                        }
                    } else if (res && res.entity && res.entity.verified) {
                        this.router.navigate(['/login'], {
                            queryParams: {
                                VERIFY_TEAM_MEMBER: this.datas.VERIFY_TEAM_MEMBER,
                                email: this.datas.email,
                                projectId: this.datas.projectId,
                                subscription: this.datas.subscription,
                                user: 'Pixly_user',
                                userid: this.datas.userid,
                                username: this.datas.username,
                                workSpace: this.datas.workSpace,
                                workSpaceName: this.datas.workSpaceName,
                                workSpaceUser: this.datas.workSpaceUser,
                            },
                        });
                    }
                }

            },
        );
    }

    public passwordToggle(id: any): void {
        if (id === 1) {
            this.togglePasswordOne = !this.togglePasswordOne;
        } else {
            this.togglePasswordTwo = !this.togglePasswordTwo;
        }
    }

    public onSubmit(setPasswordForm: any): void {
        this.submitted = true;
        const payload = {
            password: this.setPassword && this.setPassword.password ? this.setPassword.password : '',
            workSpaceName: this.datas.workSpaceName,
            userId: this.datas.userid,
            verificationCode: this.datas.VERIFY_TEAM_MEMBER,

        };
        this.loginService.setPassword(payload).subscribe(
            (res): any => {
                if (res.status.status === 200) {
                    this.toastr.clear();
                    this.toastr.success(res.status.msg);
                    if (res && res.entity && res.entity.login) {
                        this.submitted = false;
                        localStorage.setItem('userId', res.entity.login.userId);
                        localStorage.setItem('token', res.entity.login.access_token);
                        sessionStorage.setItem('subscription', this.datas.subscription.toLowerCase() === 'pro' ? '2' : '1');

                        this.toastr.success(`Welcome ${res.entity.login.userName}`);
                        this.navigateToWorkspaceSetPass();
                    } else if (localStorage.getItem('token')) {
                        this.toastr.success(`Welcome `);
                        this.navigateToWorkspaceSetPass();
                    } else {
                        const Data = `&username=${this.datas.email}&password=${this.setPassword.password}`;
                        this.loginService.login(Data).subscribe(
                            (loginres): any => {
                                this.submitted = false;
                                localStorage.setItem('userId', loginres.userId);
                                localStorage.setItem('token', loginres.access_token);
                                sessionStorage.setItem('subscription', this.datas.subscription.toLowerCase() === 'pro' ? '2' : '1');

                                this.toastr.success(`Welcome ${loginres.userName}`);
                                this.navigateToWorkspaceSetPass();
                            },
                        );
                    }
                } else {
                    this.submitted = false;
                    this.toastr.error('Verification Failed !!');
                }
            },
        );
    }


    public increaseCount(): void {
        const payload = {
            userId: this.datas.userid,
            workSpaceName: this.datas.workSpaceName,
        };
        this.loginService.increaseCount(payload).subscribe((res): any => { });
    }

    public navigateToWorkspaceSetPass(): void {
        if (this.datas.workSpaceName) {
            sessionStorage.setItem('workspacename', this.datas.workSpaceName);
            if (this.datas.projectId && this.datas.projectId !== 'null') {
                sessionStorage.setItem('projectId', this.datas.projectId);
                this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
                    (res): any => {
                        if (res && res.entity) {
                            if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                                sessionStorage.setItem('usertype', res.entity.roleId);
                                if (res.entity.roleId === 3) {
                                    this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
                                } else {
                                    this.router.navigate(['/project/feed'], { queryParams: this.api.getSessionData() });
                                }
                            }
                        }
                    },
                );
            } else {
                // Api call here to get user type
                this.workspaceService.getUserTypeService();
            }
        } else {
            this.router.navigate(['/company'], { queryParams: this.api.getSessionData() });
        }
    }
}
