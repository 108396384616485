<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row  text-center ">
         <div class="col-lg-7 col-md-6 my-content col-sm-10 mx-auto mx-md-0 order-1 order-md-0 d-none d-md-block">
            <owl-carousel-o [options]="customOptions">
               <ng-template carouselSlide >
                  <h1 class="grey-i fs22 ">Projects</h1>
                  <img src="./assets/images/teamwork_transparent.png" alt="teamwork" class="mx-auto authentication-layout-img">

                  <h2 class="grey-a fs16 col-md-12 col-lg-9 fw-600 mx-auto my-3 slider-sub-title">Pixly - Snap, Tag, Share Project Photos & Videos	
                     Document and collaborate with your team 	
                     on projects with photos, videos, tags, tasks (punch lists)	
                     PDF Reports and Dashboards</h2>
                  <!-- <p class="fs14 grey-a fw-500 slider-sub-title">Easily Manage your warranty cards on in phone</p> -->
               </ng-template>
               <ng-template carouselSlide >
                  <h1 class="grey-i fs22 ">Teams</h1>
                  <img src="./assets/images/set-password-img.svg" alt="teamwork" class="mx-auto authentication-layout-img">

                  <h2 class="grey-a fs16 col-md-12 col-lg-9 fw-600 mx-auto my-3 slider-sub-title">Pixly - Snap, Tag, Share Project Photos & Videos	
                     Document and collaborate with your team 	
                     on projects with photos, videos, tags, tasks (punch lists)	
                     PDF Reports and Dashboards</h2>
                  <!-- <p class="fs14 grey-a fw-500 slider-sub-title">Easily Manage your warranty cards on in phone</p> -->
               </ng-template>
            </owl-carousel-o>
         </div>
         <div class="col-lg-5 col-md-6 col-sm-8 mx-auto mx-md-0 my-content text-md-left">
            <h1 class="grey-a fs24">Log In</h1>
            <p class="fs11 authentication-sub-content">Sign in to Pixly using your email address and password.</p>

            <form name="authentication-layoutForm" name="loginform"
               (ngSubmit)="loginform.form.valid && onSubmit(loginform)" #loginform="ngForm" novalidate
               autocomplete="off" class="mt-5 ">
               <div class="row">
                  <div class="col-md-12 col-lg-7 col-sm-10 mx-auto mx-md-0 ">
                     <div class="form-group input-top-rounded mb-0">
                        <input name="email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                           [(ngModel)]="Login.email" #email="ngModel"
                           [ngClass]="{ 'has-error': loginform.submitted && email.invalid }" type="email"
                           class="form-control pt22 pb22 fw-600 " placeholder="Work Email">
                        <div *ngIf="email.invalid && (email.dirty || email.touched)">
                           <div *ngIf="email.errors.required" class="text-danger fs12">
                              Email is required.
                           </div>
                           <div *ngIf="email.errors.pattern" class="fs12 text-danger">
                              Enter a valid email.
                           </div>
                        </div>
                     </div>
                     <div class="input-group input-bottom-rounded ">
                        <input name="password" required [(ngModel)]="Login.password" #password="ngModel"
                           [ngClass]="{ 'has-error': loginform.submitted && password.invalid }" [type]="togglePassword ?  'text' : 'password'"
                           class="form-control fw-600 pt22 pb22  border-right-0 " placeholder="Password">
                        <div class="input-group-append">
                           <span class="input-group-text border-left-0 " (click)="passwordToggle()">
                              <img *ngIf="togglePassword" src="./assets/images/show-pass.svg" alt="show password"
                                 class="c-pointer">
                              <img *ngIf="!togglePassword" src="./assets/images/pass-hide.svg" alt="show password"
                                 class="c-pointer">
                           </span>
                        </div>

                     </div>
                     <div *ngIf="password.invalid && (password.dirty || password.touched)">
                        <div *ngIf="password.errors.required" class="text-danger fs12">
                           Password is required.
                        </div>
                     </div>
                     <div class="mx-auto col-10 mb-3 mt30">
                        <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="submit"
                           [disabled]="!loginform.valid">
                           <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Log In</button>
                     </div>
                  </div>

               </div>

            </form>
            <div class="col-md-12 col-lg-7  text-center ">
               <a class="blue-a fs13 fw-600 " href="javascript:void(0)" (click)="ToggleForgetpassword()">Forgot
                  Password?</a>
               <br>
               <p class="grey-d fw-600 fs13 mt-2 mb-3 o-none">Don't you have account?<span
                     class="blue-a text-uppercase c-pointer" routerLink="/signup"> Create Account</span></p>

               <!-- <p class="grey-d fw-600 fs13 separator-line">Or Sign in With</p> -->
               <!-- <div class="mx-auto col-sm-9 col-md-11">
                  <button class="btn btn-grey-f grey-g br25 pt7 pb7 btn-block fs13 fw-600 mb-3 mt-4 "
                     (click)="tryGoogleLogin()" type="button"><img src="./assets/images/Google__G__Logo.svg" alt="google"
                        class="mr-2">Google</button>

               </div> -->
            </div>


         </div>
      </div>
   </div>

</section>
<app-sub-footer></app-sub-footer>
<app-forgot-password [forgetPasswordToggle]="showforget" (showforgetevent)="ToggleForgetpassword()">
</app-forgot-password>

<ng-template #exampleModal>
   <div class="modal-lg signup-modal">
       <div class="modal-content border-0">
           <div class="modal-header border-bottom-0 pb-0">
               <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

               <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
           </div>
           <div class="modal-body">
               <p class="fs14 fw600 grey-m py-3 text-center">Seems like you have already been invited to a project,
                  please click "Cancel" to join the project via invitation link in your mail.
                  Please click "OK" to register and create your own company in Pixly.</p>
               <div class="modal-btn text-right">
                   <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                       [disabled]="submitted" (click)="yesMethod()">
                       <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>OK</button>
                   <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                       (click)="modalRef.hide()">Cancel</button>
               </div>
           </div>
       </div>
   </div>
</ng-template>

<app-sendotp-sidenav [showToggle]="showNav" [showCreate]="showCreate" [unverified]="unverified" (otpSubmit)="getOtpType($event, loginform)"></app-sendotp-sidenav>
