import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchuser',
})
export class SearchuserPipe implements PipeTransform {
    public transform(value: any, args?: any): any {
        if (!value) {
            return null;
        }
        if (!args) {
            return value;
        }
        // eslint-disable-next-line no-param-reassign
        args = args.toLowerCase();
        return value.filter((item): any => {
            return JSON.stringify(item).toLowerCase().includes(args);
        });
    }
}
