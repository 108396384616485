<section class="main-container help-layout">
    <div class="sub-container">
       <div class="sub-content-container pt15 pb15">
          <div class="content-card  border-grey shadow-none">
                <div class="card-header px-4 mb35 pb-0 bg-white  border-0">
                    <h1 class="fw-600 fs20 ls-9 grey-g mb-3 mb-sm-0">
                       Help
                     </h1>                  
                </div>
                <div class="card-body px-md-4 px-2 py-3">
                     <div class="text-center px-2">
                         <img src="./assets/images/help.svg" class="help-img mx-auto" alt="help">
                         <div class="mb-4 mt-5">
                             <h2 class="fs18 text-black font-weight-bold mb-2">Need Help!</h2>
                             <p class="col-md-6 px-0 fs12 grey-color-y mx-auto">We all need a helping hand now and then. Don't hesitate to get in touch.</p>
                         </div>
                        </div>  
                         <div class="d-flex align-items-center justify-content-sm-center flex-column flex-sm-row mt-4 mb-3">
                            <div class="d-flex mb-3 mb-sm-0 mr-sm-3">
                                <div class="mr-3">
                                    <img src="./assets/images/mail.svg" alt="mail">
                                </div>
                                <div>
                                    <h3 class="fs14 text-black fw-600 mb-0">Email</h3>
                                    <p class="fs14 text-black mb-0"><a href = "mailto: hello@pixly.ai">hello@pixly.ai</a></p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="mr-3">
                                    <img src="./assets/images/phone.svg" alt="phone">
                                </div>
                                <div>
                                    <h3 class="fs14 text-black fw-600 mb-0">Phone</h3>
                                    <p class="fs14 text-black mb-0"> <a href="tel:+16198068424">6198068424</a></p>
                                </div>
                            </div>
                         </div>                          
                </div>
             </div>           
       </div>
    </div>
    <aside aria-label="right-sidemenu" class="dashboard-right-sidemenu"
       [ngClass]="{show: showProject, closed: !showProject}">
       <app-layout-project></app-layout-project>
    </aside>
 </section>
