import { Component, ElementRef, EventEmitter, OnInit, Output, AfterViewInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { ApiService } from '../../services/api_base/api.service';
import { SupportService } from '../../services/support.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit, AfterViewInit {
    public togglePassword = false;

    public submitted = false;

    public phoneMask = '';

    public nonWhitespaceRegExp = new RegExp('\\S');

    public signupform: FormGroup;

    public code = [{ code: '+1', mask: '(000) 0000-000' }, { code: '+91', mask: '00000-00000' }];

    public Signup: any = {};

    public validation = false;

    public datas: any;

    public companyVariable = true;

    public nameobject = false;

    public splitName = [];

    separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    customPlaceholder = "Phone number";

    public showNav = false;

    @Output('otptype') public otptype = new EventEmitter();

    public showCreate: boolean;

    public constructor(
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private api: LoginService, private apis: ApiService, private workspaceService: WorkspaceServiceService,
        private support: SupportService,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.route.queryParams.subscribe((params): any => {
            if (params && params.email) {
                this.datas = params;
            }
        });
    }

    public goToBack(): any {
        window.history.back();
    }

    ngAfterViewInit(): void {
        const telInput = this.elementRef.nativeElement.querySelector('input[type="tel"]');
        this.renderer.setAttribute(telInput, 'placeholder', this.customPlaceholder);
    }

    public ngOnInit(): void {
        if (this.datas && this.datas.bulkUpload) {
            this.verifyCodeMethod();
            this.companyVariable = false;
        }

        if (this.datas && this.datas.username) {
            this.splitName = this.datas.username.split(' ');
            if (this.splitName.length > 1) {
                this.nameobject = true;
            }
        }

        this.Signup = {
            firstName: (this.nameobject) ? this.splitName[0] : '',
            lastName: (this.nameobject) ? this.splitName[1] : '',
            email: (this.datas != undefined && this.datas.email != undefined) ? this.datas.email : '',
            password: '',
            phoneCode: '',
            phoneNumber: '',
            origin: 1,
            phone: '',
            otpType: ''
        };
    }
    
    public verifyCodeMethod(): void {
        const payload = {
            workSpaceName: this.datas.workSpaceName,
            userId: this.datas.userid,
            verificationCode: this.datas.VERIFY_TEAM_MEMBER,
            workspaceFlag: 'create',
        };

        this.api.verifyCode(payload).subscribe(
            (res): any => {
                if (res.status.status === 204) {
                    this.toastr.clear();
                    this.toastr.error(res.status.msg);
                    if (!localStorage.getItem('token') || !localStorage.getItem('userId')) {
                        this.router.navigate(['/login']);
                    }
                } else if (res.status.status === 200) {
                    if (res && res.entity && res.entity.verified) {
                        this.router.navigate(['/login'], {
                            queryParams: {
                                VERIFY_TEAM_MEMBER: this.datas.VERIFY_TEAM_MEMBER,
                                email: this.datas.email,
                                projectId: this.datas.projectId,
                                subscription: this.datas.subscription,
                                user: 'Pixly_user',
                                userid: this.datas.userid,
                                username: this.datas.username,
                                workSpace: this.datas.workSpace,
                                workSpaceName: this.datas.workSpaceName,
                                workSpaceUser: this.datas.workSpaceUser,
                            },
                        });
                    }
                    // else if (this.datas.bulkUpload === 'false' || this.datas.bulkUpload === false) {
                    //     this.router.navigate(['/signup'], { queryParams: this.datas
                    //     });
                    // }
                }
            },
        );
    }

    public changeMask1(phoneCode): void {
        
    }

    public onChange(event) {
        if (event) {
            if (event.dialCode === '+1') {
                this.phoneMask = this.code[0].mask;                
            } else {
                this.phoneMask = this.code[1].mask;
            }  
            this.Signup.phoneCode = event.dialCode;
            this.Signup.phoneNumber = event.number;
        }
    }

    public changeMask(): void {
        if (this.Signup.phoneCode && this.Signup.phoneCode === '+1') {
            this.phoneMask = this.code[0].mask;
        } else {
            this.phoneMask = this.code[1].mask;
        }
    }

    public passwordToggle(): void {
        this.togglePassword = !this.togglePassword;
    }

    public ToggleNav(value): any {
        if (value) {
            this.showCreate = (value === 'create');
        }
        this.showNav = !this.showNav;
        
    }
    
    public removeTrim(signupform) {
        this.Signup.email = signupform.value.email.trim();
    }

    public getOtpType(event, signupform): any {
        this.Signup.otpType = event;

        this.api.signUp(this.Signup).subscribe(
            (res): void => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    signupform.form.reset();
                    // this.toastr.success('Please enter your otp which was send to your email.');
                    if (event === 1 || event === '1') {
                        this.toastr.success('Please enter your otp which was send to your Phone Number.');
                    } else {
                        this.toastr.success('Please enter your otp which was send to your email.');
                    }
                    localStorage.setItem('userId', res.entity.userId);
                    localStorage.setItem('email', res.entity.email);
                    localStorage.setItem('username', res.entity.firstName);
                    localStorage.setItem('type', '1');
                    this.router.navigate(['/verify-otp'], { queryParams: {source: event} });
                } else if (res.status.status === 402) {
                    this.toastr.error(res.status.msg);
                    this.showNav = !this.showNav;
                } else if (res && res.status && res.status.status === 302) {
                    this.toastr.error('Email already exists, Please try with different Email');
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public onSubmit(signupform: any): void {
        this.validation = true;
        if (!signupform.form.valid) {
            return;
        }
        this.submitted = true;        
        if (!this.datas) {
            // this.ToggleNav('');
            localStorage.setItem('password', this.Signup.password);
            this.toastr.clear();
            this.Signup.otpType = '3',
            this.api.signUp(this.Signup).subscribe(
                (res): void => {
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200) {
                        signupform.form.reset();
                        this.toastr.success('Please enter your otp which was send to your email and phone number.');
                        localStorage.setItem('userId', res.entity.userId);
                        localStorage.setItem('email', res.entity.email);
                        localStorage.setItem('username', res.entity.firstName);
                        localStorage.setItem('otpType', '3');
                        localStorage.removeItem('type');
                        this.router.navigate(['/verify-otp']);
                    } else if (res && res.status && res.status.status === 302) {
                        this.toastr.error('Email already exists, Please try with different Email');
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                },
                (err): void => {
                    this.submitted = false;
                    this.apis.handleError(err);
                },
            );
        } else {
            this.verifyUser();
        }
    }

    public verifyUser(): void {       
        const payload = {
            firstName: this.Signup.firstName,
            lastName: this.Signup.lastName,
            phoneCode: this.Signup.phoneCode,
            phoneNumber: this.Signup.phoneNumber,
            password: this.Signup.password,
            workSpaceName: this.datas.workSpaceName,
            userId: this.datas.userid,
            verificationCode: this.datas.VERIFY_TEAM_MEMBER,
        };
        this.api.setPassword(payload).subscribe(
            (res): any => {
                if (res.status.status === 200) {
                    this.submitted = false;
                    if (res && res.entity && res.entity.login && res.entity.login.access_token) {
                        localStorage.setItem('token', res.entity.login.access_token);
                        localStorage.setItem('userId', res.entity.login.userId);
                        this.toastr.success('Welcome Back');
                        this.navigateToWorkspace();
                    } else {
                        this.router.navigate(['/login']);
                    }
                }
            },
            (err): void => {
                this.submitted = false;
                this.toastr.error('Sorry something went wrong.., Please try again later.');
            }
        );
    }

    public navigateToWorkspace(): void {
        if (this.datas && this.datas.workSpaceName) {
            sessionStorage.setItem('workspacename', this.datas.workSpaceName);
            if (this.datas.projectId && this.datas.projectId !== 'null') {
                localStorage.removeItem('email');
                localStorage.removeItem('username');
                sessionStorage.setItem('projectId', this.datas.projectId);
                this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
                    (res): any => {
                        if (res && res.entity) {
                            if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                                sessionStorage.setItem('usertype', res.entity.roleId);
                                this.support.usertype.next(res.entity.roleId);
                                setTimeout((): any => {
                                    if (res.entity.roleId === 3) {
                                        this.router.navigate(['/project/dashboard'], { queryParams: this.apis.getSessionData() });
                                    } else {
                                        this.router.navigate(['/project/feed'], { queryParams: this.apis.getSessionData() });
                                    }
                                }, 300);
                            }
                        }
                    },
                );
            } else {
                this.workspaceService.getUserTypeService();
            }
        } else {
            this.router.navigate(['/company'], { queryParams: this.apis.getSessionData() });
        }
    }

}
