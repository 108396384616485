<div id="overlay" [ngClass]="{'d-block': showToggle}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showToggle}">
   <!-- for workspace -->
   <div *ngIf="currentNav == 'create'">

      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Create your workspace</h1>
         <em [disabled]="submitted" class="lnr lnr-cross close-icon fw-600 fs16 "
            (click)="Toggleworkspacepassword()"></em>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Workspace?</h2>
      <form class="mt-4" name="workspaceForm" #workspaceForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input maxlength="50" [validation]="false" type="text" name="workSpaceName" required
               [(ngModel)]="createWorkspace.workSpaceName" #workSpaceName="ngModel"
               [ngClass]="{ 'has-error': workspaceForm.submitted && workSpaceName.invalid }"
               class="form-control br8 pt22 pb22 fw-600" placeholder="Workspace name...">
            <div *ngIf="workSpaceName.invalid && (workSpaceName.dirty || workSpaceName.touched)">
               <div *ngIf="workSpaceName.errors.required" class="text-danger fs12">
                  Workspace name is required.
               </div>
            </div>
         </div>

         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                  [disabled]="submitted" (click)="Toggleworkspacepassword()">Cancel</button>

            </div>
            <div class="col-8 pl-1" *ngIf="shouldShowButton()">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                  (click)="workspaceForm.form.valid && onSubmit(workspaceForm)"
                  [disabled]="!workspaceForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Workspace</button>
            </div>
         </div>
      </form>
   </div>

   <div *ngIf="currentNav == 'choose'">
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 ">Choose your workspace</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="Toggleworkspacepassword()"></em>
      </div>
      <div class="form-group input-rounded">
         <input maxlength="50" type="text" name="search workspace" [(ngModel)]="searchTerm"
            (keyup)="filterWorkspaces($event.target.value)" class="form-control br8 pt22 pb22 fw-600 "
            placeholder="Search workspaces">
      </div>
      <div class="scroll-content" *ngIf="filteredWrkspace && filteredWrkspace.length > 0; else noRecords">
         <div class="workspace-list-item" *ngFor="let workspace of filteredWrkspace; let i = index">
            <input type="radio" name="check-radio" id="check-radio{{i}}" class="d-none">
            <label class=" workspace-list py-2 d-block c-pointer" for="check-radio{{i}}">
               <div class="d-flex">
                  <div class="team-logo grey-i fs12 mr-3" (click)="selectWorkSpace(workspace)">
                     {{getFirstAndLastLetter(workspace.masterTenant.workSpaceName)}}
                  </div>
                  <div class="flex-grow-1 fs14 fw-600 grey-m my-auto" (click)="selectWorkSpace(workspace)">
                     {{workspace.masterTenant.workSpaceName}}
                  </div>
                  <div class="check-div my-auto">
                     <em class="fas fa-check-circle"></em>
                  </div>
               </div>
            </label>
         </div>
      </div>

      <ng-template #noRecords>
         <div class="no-records-message">
            <b class="message-title">Uh-oh! No workspaces found.</b>
            <p class="message-suggestion">Why not create a new workspace?</p>
          </div>
      </ng-template>

      <div class="row mt50 px-3 ">
         <div class="col-4 pr-1">
            <button [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600  "
               type="button" (click)="Toggleworkspacepassword()">Cancel</button>
         </div>

      </div>
   </div>

   <div *ngIf="currentNav == 'createProject'">
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Name your Project</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="closeProject()"></em>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Project?</h2>
      <form class="mt-4 " name="projectForm" #projectForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input maxlength="50" type="text" required name="teamName" required [(ngModel)]="createProject.teamName"
               #teamName="ngModel" [ngClass]="{ 'has-error': projectForm.submitted && teamName.invalid }"
               class="form-control br8 pt22 pb22 fw-600 " placeholder="Project name..."
               (keydown.space)="createProject.teamName.trim().length > 0 ? '': $event.preventDefault();">
            <div *ngIf="teamName.invalid && (teamName.dirty || teamName.touched)">
               <div *ngIf="teamName.errors.required" class="text-danger fs12">
                  Project name is required.
               </div>
            </div>
         </div>
         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
         <div class="input-container">
            <h2 class="fs12 grey-j ls-3">Address</h2>
            <div class="position-relative">
               <input type="text" class="form-control br8 pt22 pb22 pr30 fw-600" name="selectedPlace"
                  placeholder="Address..." (input)="onSearch($event.target.value)" [(ngModel)]="selectedPlaceText"
                  autocorrect="off" autocapitalize="off" spellcheck="off" [ngModelOptions]="{ standalone: true }">
               <!-- <i *ngIf="!currentLocation" class="fas fa-map-marked-alt location-icon" (click)="getLocation()"></i> -->
               <img *ngIf="!currentLocation" src="../../../assets/images/target.png" class="location-icon"
                  (click)="getLocation()" alt="location">

               <ng-container *ngIf="currentLocation">
                  <em class="fa fa-spinner fa-spin loading-icon"></em>
               </ng-container>
            </div>
            <div class="predictions" *ngIf="predictions.length > 0">
               <div class="prediction-item" *ngFor="let prediction of predictions"
                  (click)="onPlaceSelected(prediction)">
                  {{ prediction.description }}
               </div>
            </div>
         </div>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                  type="button" (click)="closeProject()">Back</button>
            </div>
            <div class="col-8 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600" type="button"
                  (click)="projectForm.form.valid && createProjectMethod(projectForm)"
                  [disabled]="!projectForm.valid || submitted">Create Project</button>
            </div>
         </div>
      </form>
   </div>
   <!-- !showProjectCreate && workspaceOrProject && !showTagsSideNav && !showTagsList -->
   <div *ngIf="currentNav == 'showAllProjects'">

      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 ">Choose Your Project</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeProject()"></em>
      </div>
      <div class="scroll-content">
         <div class="form-group input-rounded">
            <input maxlength="50" type="text" name="search project"
            (input)="onSearchInput($event.target.value)"
                class="form-control br8 pt22 pb22 fw-600 " placeholder="Search projects">
        </div>

         <div class="workspace-list-item" *ngFor="let project of filteredProjects;let i = index">
            <input type="radio" name="check-radio" id="check-radioc{{i}}" class="d-none">

            <label class=" workspace-list py-2 d-block c-pointer" for="check-radioc{{i}}">
               <div class="d-flex">
                  <div class="team-logo grey-i fs12 mr-3" (click)="chooseProject(project)">
                     {{getFirstAndLastLetter(project.teamName)}}
                  </div>
                  <div class="flex-grow-1 fs14 fw-600 grey-m my-auto" (click)="chooseProject(project)">
                     {{project.teamName}}
                  </div>
                  <div class="check-div my-auto">
                     <em class="fas fa-check-circle"></em>
                  </div>
               </div>
            </label>
         </div>
      </div>
      <div class="row mt50 px-3">
         <div class="col-4 pr-1">
            <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
               (click)="closeProject()">Back</button>

         </div>
         <div class="col-8 pl-1" *ngIf="usertype">
            <button class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-600 buttonload" (click)="ToggleProject()"
               type="button">New Project</button>
         </div>
      </div>

   </div>

   <div *ngIf="currentNav == 'createNewTag'">
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Create Global Tag</h1>
         <em [disabled]="submitted" class="lnr lnr-cross close-icon fw-600 fs16 " (click)="closeTagsSideNav()"></em>
      </div>
      <form class="mt-4" name="globalTagForm" #globalTagForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <h2 class="fs16 grey-j ls-3">Tag Name</h2>
            <input maxlength="50" [validation]="false" type="text" name="tagName" required
               [(ngModel)]="createWorkspace.tagName" #tagName="ngModel"
               [ngClass]="{ 'has-error': globalTagForm.submitted && tagName.invalid }"
               class="form-control br8 pt22 pb22 fw-600" placeholder="Type Name Here...">
            <div *ngIf="tagName.invalid && (tagName.dirty || tagName.touched)">
               <div *ngIf="tagName.errors.required" class="text-danger fs12">
                  Tag name is required.
               </div>
            </div>
         </div>
         <div class="form-group input-rounded">
            <h2 class="fs16 grey-j ls-3">Tag Purpose</h2>
            <textarea [validation]="false" name="tagPurpose" style="height: 150px;"
               [(ngModel)]="createWorkspace.tagPurpose" #tagPurpose="ngModel" required
               [ngClass]="{ 'has-error': globalTagForm.submitted && tagPurpose.invalid }"
               class="form-control br8 pt22 pb22 fw-600" placeholder="Enter Tag purpose..."></textarea>
            <div *ngIf="tagPurpose.invalid && (tagPurpose.dirty || tagPurpose.touched)">
               <div *ngIf="tagPurpose.errors.required" class="text-danger fs12">
                  Tag purpose is required.
               </div>
            </div>
         </div>
         <div class="form-group input-rounded">
            <h2 class="fs16 grey-j ls-3">Tag Color</h2>
            <div class="input-with-color">
               <input maxlength="50" [validation]="false" type="text" name="tagColor" required
                  [(ngModel)]="createWorkspace.tagColor" #tagColor="ngModel"
                  [ngClass]="{ 'has-error': globalTagForm.submitted && tagColor.invalid }"
                  class="form-control br8 pt22 pb22 fw-600" placeholder="Tag color..." />
               <input type="color" [(ngModel)]="createWorkspace.tagColor" #tagColor="ngModel"
                  [ngModelOptions]="{ standalone: true }" (input)="updateColor()" id="color" />
            </div>
            <div *ngIf="tagColor.invalid && (tagColor.dirty || tagColor.touched)">
               <div *ngIf="tagColor.errors.required" class="text-danger fs12">
                  Tag color is required.
               </div>
            </div>
         </div>
         <div class="row mt50 px-3">
            <div class="col-4 pr-1">
               <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                  [disabled]="submitted" (click)="closeTagsSideNav()">Cancel</button>

            </div>
            <div class="col-4 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" style="width: 90px;"
                  type="submit" (click)="globalTagForm.form.valid && createTag(globalTagForm)"
                  [disabled]="!globalTagForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create</button>
            </div>
            <div class="col-4 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                  (click)="gotoTagList()">Proceed</button>
            </div>
         </div>
      </form>
   </div>

   <div *ngIf="currentNav == 'showAllTagsList'">
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Choose Your Tags <i class="fas fa-info-circle"
               title="Tags provided will be added to the workspace and can be deleted if not needed."></i></h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="gotoCreateTag()"></em>
      </div>

      <div class="pb15" *ngIf="tagslist.length > 0">
         <div class="scroll-content">
            <div class="workspace-list-item" *ngFor="let tag of tagslist; let i = index">
               <label class="c-pointer workspace-list py-2 d-block mb-0" for="check-radio{{i}}">
                  <div class="d-flex align-items-center">
                     <div class="team-logo grey-i fs12 mr-3 d-flex align-items-center justify-content-center">
                        {{getFirstAndLastLetterForTag(tag.tagName)}}
                     </div>
                     <div class="flex-grow-1 fs14 mr-3 fw-600 grey-m my-auto">
                        <span>{{tag.tagName | ngXtruncate:25:'...'}}</span>
                     </div>
                     <!-- <div *ngIf="isSelected(tag)" class="mr-1">
                           <i class="fas fa-check-circle" style="font-size: 16px;"></i>
                         </div> -->

                     <div class="mr-1">
                        <i class="far fa-trash-alt c-pointer fs12" (click)="removeTag(tag)" style="color: #0e63fc;"></i>
                     </div>
                  </div>

               </label>

            </div>

         </div>

      </div>

      <div class="d-flex justify-content-center" *ngIf="tagslist.length == 0">
         <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3">No Tags Available</h1>
      </div>

      <div class="row mt50 px-3">
         <div class="col-4 pr-1">
            <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
               (click)="gotoCreateTag()">Back</button>
         </div>
         <div class="col-8 pl-1">
            <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
               (click)="gotoCreateProject()">Proceed</button>
         </div>
      </div>

   </div>


</div>