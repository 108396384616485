<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15 pb15">
            <div class="content-card p-0 border-grey shadow-none">
                <div class="card-header bg-white border-0 pb-1  my-2  px-3 pt-3">
                    <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Settings</h1>
                </div>
                <div class="card-body  px-2">
                    <div class="btn-grey-f br10 px-3 pt12 pb12 mb-2">
                        <h2 class="fs12 ls-e grey-g text-uppercase mb-0 font-weight-bold">My Profile</h2>
                    </div>
                    <div class="user-wallpaper mb70">
                        <img *ngIf="editObj && editObj.backgroundImage" src={{cloudFrontURL}}{{editObj.backgroundImage}}
                            alt="nature" class="user-wallpaper-img">
                        <img *ngIf="!editObj || !editObj.backgroundImage" src="./assets/images/photo-none.svg"
                            alt="nature" class="user-wallpaper-img">
                        <img *ngIf="editObj && editObj.profileImage" src={{cloudFrontURL}}{{editObj.profileImage}}
                            alt="profile " class="user-profile-img">
                        <img *ngIf="!editObj || !editObj.profileImage" src="./assets/images/empty-img.png"
                            alt="profile " class="user-profile-img">
                        <button class="btn btn-blue-secondry br25 pt7 px-3 pb7  edit-details fs12"
                            (click)="viewEditprofile()"><img src="./assets/images/edit-white.svg" alt="edit-profile"
                                class="mr-2 w13 mb-1">Edit
                            Profile</button>
                    </div>
                    <div class="mt80 mb-5 text-center">
                        <h1 class="fs13 font-weight-bold grey-g mb6 text-capitalize ls-d">User name</h1>
                        <p *ngIf="editObj && editObj.firstName" class="fs16 font-weight-bold  grey-g ls-c">
                            {{editObj.firstName}} {{editObj.lastName}}</p>
                        <h1 class="fs13 font-weight-bold grey-g mb6 text-capitalize ls-d">Email</h1>
                        <p *ngIf="editObj && editObj.email" class="fs16 font-weight-bold  grey-g ls-c">
                            {{editObj.email}} </p>
                        <h1 class="fs13 font-weight-bold grey-g mb-6 text-capitalize ls-d mt30">Phone Number</h1>
                        <p class="fs16 font-weight-bold  grey-g ls-c" *ngIf="editObj && editObj.phoneCode">
                            {{editObj.phoneCode}}
                            <span *ngIf="editObj && editObj.phoneNumber">{{editObj.phoneNumber}}</span>
                        </p>
                        <button class="btn btn-blue br25  pt7 pb7 px-3 mt-2 fs12" (click)="showChangePassword()">Change
                            Password</button>
                    </div>                 

                    <ul class="nav nav-pills pills-secondry" role="tablist">
                        <li class="nav-item mr-4" role="presentation">
                            <a class="active nav-link fs14 px-4" href="javascript:void(0)">
                                My Workspaces
                            </a>
                        </li>
                    </ul>
                    <div class="form-group input-rounded">
                        <input maxlength="50" type="text" name="search workspace" #searchWorkspace
                        class="form-control br8 pt22 pb22 fw-600 mt-4" placeholder="Search workspaces">
                    </div>              

                    <div class="companies-list my-4">
                        <div class="d-flex px-4 companies-list-item align-items-center justify-content-center " *ngFor="let comp of filteredWorkspace">
                            <div class="mr-3">
                                <img src="./assets/images/buildings.svg" alt="building" style="width:20px">
                            </div>
                            <div class="flex-grow-1">
                                <h3 class="fs14 fw-600 mt-1 grey-g mb-0  ls-10">{{comp.masterTenant.workSpaceName}}</h3>
                                <div *ngIf="tenantId == comp.masterTenant.tenantId" class="grey-a fs10">Active Workspace</div>
                            </div>
                            <div class="custom-switchtoggle" *ngIf="comp.owner === true">
                                <img src="./assets/images/edit-outline.svg" title="Edit Invite" (click)="editCompanySidemenu = true; editWorkspace(comp)" alt="edit-outline" class="w15 mr-3 c-pointer">
                                <img src="./assets/images/delete.svg" *ngIf="tenantId !== comp.masterTenant.tenantId" alt="delete" title="Delete workspace" class="w15 mr-3 c-pointer" (click)="askDeleteWorkspace(comp)">
                            </div>
                        </div>                        
                    </div>
                                       
                    <div class="btn-grey-f br10 px-3 pt12 pb12 mb-2">
                        <h2 class="fs12 ls-e grey-g text-uppercase mb-0 font-weight-bold">NOTIFICATIONS</h2>
                    </div>
                    <ul class="nav nav-pills pills-secondry  mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item mr-4" role="presentation">
                            <a [class.active]="viewMode=='Notification'" (click)="viewMode='Notification'"
                                class="nav-link fs14 px-4" href="javascript:void(0)">
                                Push Notification</a>
                        </li>
                        <li class="nav-item mr-4" role="presentation">
                            <a [class.active]="viewMode=='emailNotification'" (click)="viewMode='emailNotification'"
                                class="nav-link fs14 px-4" href="javascript:void(0)">
                                Email Notification</a>
                        </li>
                    </ul>
                    <div class="tab-content pb-3" [ngSwitch]="viewMode">
                        <div class="tab-pane fade show active" *ngSwitchCase="'Notification'">
                            
                            <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h1 class="fs15 grey-g mb-0 fw-600 ls-10">Toggle All</h1>
                                </div>

                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.allNotification"
                                    (change)="changeTagToggle(9,$event)"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Invite</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when you're Invited to join a team
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.invitationPush"
                                    (change)="changeTagToggle(1,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Album</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when new Albums are Created</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addAlbumPush"
                                    (change)="changeTagToggle(2,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Tags</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when new Tags are Created</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addTagPush"
                                    (change)="changeTagToggle(3,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Photos</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when Photos are added to an Album
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addPhotoPush"
                                    (change)="changeTagToggle(4,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Tag Placed</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when tags are placed on photo</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addTagTypePush"
                                    (change)="changeTagToggle(5,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Comments</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when comments are added to
                                        discussion you're in</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addNotePush"
                                    (change)="changeTagToggle(6,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Assigned Task</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when task are assigned to you</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.assignToTaskPush"
                                    (change)="changeTagToggle(7,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Completed Task</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when you've assigned are completed
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.completeTaskPush"
                                    (change)="changeTagToggle(8,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="btn-grey-f br10 px-3 pt12 pb12 my-4">
                                <h3 class="fs12 ls-e grey-g text-uppercase mb-0 font-weight-bold">Other</h3>
                            </div>
                            <ul class="list-unstyled px-4">
                                <li class="mb-3"><a routerLink="/help" [queryParams]="conCadSessionData({})" href="javascript:void(0)"
                                        class="fs14 fw-600 mb-1 ls-10">Help
                                    </a></li>
                                <li class="mb-3">Build Version: 67</li>
                            </ul>
                        </div>

                        <div class="tab-pane fade show active" *ngSwitchCase="'emailNotification'">
                           
                            <div class="d-flex  px-4  mb-3 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h1 class="fs15 grey-g mb-0 fw-600 ls-10">Toggle All</h1>
                                </div>

                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.allEmailNotification"
                                    (change)="changeTagToggle(18,$event)"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Invite</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when you're Invited to join a team
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.invitation"
                                    (change)="changeTagToggle(10,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Album</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when new Albums are Created</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addAlbum"
                                    (change)="changeTagToggle(11,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Tags</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when new Tags are Created</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addTag"
                                    (change)="changeTagToggle(12,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">New Photos</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when Photos are added to an Album
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addPhoto"
                                    (change)="changeTagToggle(13,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Tag Placed</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when tags are placed on photo</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addTagType"
                                    (change)="changeTagToggle(14,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Comments</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when comments are added to
                                        discussion you're in</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.addNote"
                                    (change)="changeTagToggle(15,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Assigned Task</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when task are assigned to you</p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.assignToTask"
                                    (change)="changeTagToggle(16,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="d-flex  px-4  mb-4 align-items-center justify-content-between">
                                <div class="flex-grow-1">
                                    <h3 class="fs14 fw-600 mb-1 grey-g mb-0  ls-10">Completed Task</h3>
                                    <p class="mb-0 ls-8 grey-l fs11">Receive Alerts when you've assigned are completed
                                    </p>
                                </div>
                                <div class="custom-switchtoggle">
                                    <ui-switch [disabled]="submitted" size="small" [checked]="notificationListSetting.completeTask"
                                    (change)="changeTagToggle(17,'')"></ui-switch>
                                </div>
                            </div>
                            <div class="btn-grey-f br10 px-3 pt12 pb12 my-4">
                                <h3 class="fs12 ls-e grey-g text-uppercase mb-0 font-weight-bold">Other</h3>
                            </div>
                            <ul class="list-unstyled px-4">
                                <li class="mb-3"><a href="javascript:void(0)" class="fs14 fw-600 mb-1 ls-10 grey-g ">
                                        Support</a></li>
                                <li class="mb-3"><a routerLink="/help" [queryParams]="conCadSessionData({})" href="javascript:void(0)"
                                        class="fs14 fw-600 mb-1 ls-10 red-f">Help
                                    </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div id="overlay" [ngClass]="{'d-block': editProfile}"></div>
<div #sidenav class="right-sidebar bg-white px-0 tag-color-picker" [ngClass]="{'show-side-nav' : editProfile}">
    <!-- for workspace -->
    <div *ngIf="!thumbNail && editProfile">
        <div class="d-flex px-4 mb-3 justify-content-between">
            <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Edit Profile</h1>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="viewEditprofile()"></em>
        </div>
        <div class="img-edit">
            <div class="wallpaper-edit text-center">
                <p class="c-pointer mb-0 pt-4 fs12 grey-g ls-8" *ngIf="!userDetails.backgroundImage">
                    <img src='./assets/images/photo-none.svg' alt="photo" class="w10 mr-1">
                    Please upload cover photo
                </p>
                <!-- if image is already present -->
                <img *ngIf="userDetails.backgroundImage" src={{cloudFrontURL}}{{userDetails.backgroundImage}}
                    alt="wallpaper" class="w-100 h-100 object-fit-cover ">
                <!-- if image is already present -->
                <div class="edit-rounded wallpaper-edit-rounded c-pointer" (click)="editThumbnail(1)">
                    <img src="./assets/images/edit-pencil.svg" alt="edit-pencil" class="w10 mb-1">
                </div>
            </div>
            <div class="profile-edit" (click)="editThumbnail(2)">
                <!-- if image is already present -->
                <img *ngIf="!userDetails.profileImage" src="./assets/images/no-user.svg" alt="no-user" class="w25 mt-3">
                <!-- if image is already present -->
                <img *ngIf="userDetails.profileImage" src={{cloudFrontURL}}{{userDetails.profileImage}} alt="wallpaper"
                    class="w-100 h-100 br40 object-fit-cover ">
                <div class="edit-rounded c-pointer profile-edit-rounded">
                    <img src="./assets/images/edit-pencil.svg" alt="edit-pencil" class="w10 mb-1">
                </div>
            </div>
        </div>
        <form class="mt-5 px-4" name="profileForm"
            #profileForm="ngForm" novalidate>
            <label class="grey-j fs12 ">Name</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded mb-3">
                        <input type="text" name="firstName" required [(ngModel)]="editObj.firstName"
                            #firstName="ngModel" [ngClass]="{ 'has-error': profileForm.submitted && firstName.invalid }"
                            class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter First Name">
                    </div>
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                        <div *ngIf="firstName.errors.required" class="text-danger fs12">
                            First Name is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded mb-3">
                        <input type="text" name="lastName" required [(ngModel)]="userDetails.lastName"
                            #lastName="ngModel" [ngClass]="{ 'has-error': profileForm.submitted && lastName.invalid }"
                            class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter Last Name">
                    </div>
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                        <div *ngIf="lastName.errors.required" class="text-danger fs12">
                            Last Name is required.
                        </div>
                    </div>
                </div>
            </div>
            <label class="grey-j fs12 ">Phone Number</label>
            <div class="input-group input-rounded  mb-2">
                <div class="input-group-prepend br8 border-right-0 ">
                    <select class="custom-select br8 input-group-text  border-right-0 " (change)="changeMask()"
                        name="phoneCode" [(ngModel)]="userDetails.phoneCode" #phoneCode="ngModel">
                        <option value="" disabled>select</option>
                        <option *ngFor="let c of code" value={{c.code}}>{{c.code}}</option>
                    </select>
                </div>
                <input [dropSpecialCharacters]="true" [mask]="phoneMask"
                    [readonly]="userDetails.phoneCode!= '' ? false : true" name="phoneNumber"
                    [(ngModel)]="userDetails.phoneNumber" #phoneNumber="ngModel" type="text"
                    autocomplete="off" class="form-control  br8 fw-600 pt22 pb22 border-left-0 "
                    placeholder="Enter Phone Number">
            </div>
            <div *ngIf="phoneCode.invalid">
                <div *ngIf="phoneCode.errors.required" class="text-danger fs12">
                   Phone Code and Phone Number is required.
                </div>
            </div>
            <div *ngIf="phoneNumber.invalid">
                <div *ngIf="!phoneCode.invalid && phoneNumber.invalid" class="text-danger fs12">
                   Please enter valid Phone number.
                </div>
            </div>

            <div class="row mt50 justify-content-center px-2">
                <div class="col-4 pr-1">
                    <button type="button" [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                        (click)="viewEditprofile()">Cancel</button>
                </div>
                <div class="col-6 pl-1">
                    <button class="btn btn-blue-secondry br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                        [disabled]="submitted" (click)="profileForm.form.valid && onSubmit(profileForm)">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>
                        <img src="./assets/images/save.svg" alt="save"
                            class="w10 mr-1 mb-1">
                            Save</button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="thumbNail">
        <div *ngIf="showProfilepic">
            <div class="d-flex px-4 mb-3 justify-content-between">
                <div>
                    <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3"><img src="./assets/images/left-facing-arrow.svg"
                            alt="left-arrow" class="mr-2 w14 mb-1 c-pointer" (click)="editThumbnail()">Edit Thumbnail
                    </h1>
                </div>
            </div>
            <img *ngIf="userDetails.profileImage && !imageChangedEvent"
                src={{cloudFrontURL}}{{userDetails.profileImage}} alt="profile " class="w-100 px-5 br5 h250 object-fit-cover">
            <img *ngIf="!userDetails.profileImage && !imageChangedEvent" src="./assets/images/empty-img.png"
                alt="profile " class="w-100 px-5 br5 h250 object-fit-cover">
            <div class="img-cropper text-center">
                <div class=" br15 red-g profile-close c-pointer" *ngIf="imageChangedEvent" (click)="!okButtonDisabled && closeThumbnailImage()">
                    <img src="./assets/images/cross.svg" alt="cross" class="w10">
                </div>
                <image-cropper class="px-5" [imageChangedEvent]="imageChangedEvent" *ngIf="imageChangedEvent"
                    [maintainAspectRatio]="true" [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4/3"
                    [onlyScaleDown]="true" [roundCropper]="true" [hideResizeSquares]="false" [alignImage]="'center'" (imageCropped)="imageCroppedProfile($event)">
                </image-cropper>
                <input [disabled]="okButtonDisabled" type="file" accept=".png, .jpg, .jpeg" (change)="selectFile($event)" id="profile-img"
                    class="d-none" />
                <label for="profile-img" class="btn c-pointer btn-blue-outline br25 py-2 fs12 px-3 mt-3">Browse
                    Images</label>
                <div class="row mt50 justify-content-center px-4">
                    <div class="col-4 pr-1">
                        <button type="button" [disabled]=okButtonDisabled class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                            (click)="viewEditprofile()">Cancel</button>
                    </div>
                    <div class="col-6 pl-1">
                        <button [disabled]=okButtonDisabled
                            class="btn btn-blue-secondry br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                            (click)="onSubmitProfile(1)">
                            <em *ngIf="okButtonDisabled" class="fa fa-spinner fa-spin"></em>
                            <img src="./assets/images/save.svg" alt="save"
                                class="w10 mr-1 mb-1">
                                Ok</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="wallpaperPic">
            <div class="d-flex px-4 mb-3 justify-content-between">
                <div>
                    <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3"><img src="./assets/images/left-facing-arrow.svg"
                            alt="left-arrow" class="mr-2 w14 mb-1 c-pointer" (click)="editThumbnail()">Edit Cover</h1>
                </div>
            </div>
            <div class="text-center">
                <img *ngIf="userDetails.backgroundImage && !wallpaperChangedEvent"
                    src={{cloudFrontURL}}{{userDetails.backgroundImage}} alt="empty-img" class="w-100 px-3 h250 br5">
                <img *ngIf="!userDetails.backgroundImage && !wallpaperChangedEvent" src=" ./assets/images/nature.png"
                    alt="empty-img" class="w-100 px-3 br5">
                <div class="wallpaper-img-cropper">
                    <div class=" br15 red-g wallpaper-close c-pointer" (click)="!okButtonDisabled && closeBackgroundImg()"
                        *ngIf="wallpaperChangedEvent">
                        <img src="./assets/images/cross.svg" alt="cross" class="w10">
                    </div>
                    <image-cropper class="px-4 w-100" *ngIf="wallpaperChangedEvent"
                        [imageChangedEvent]="wallpaperChangedEvent" [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio" [onlyScaleDown]="true" [aspectRatio]="4 / 2"
                        [roundCropper]="false" [hideResizeSquares]="false" [alignImage]="'center'" (imageCropped)="imageCropped($event)"></image-cropper>
                    <input [disabled]="okButtonDisabled" type="file" accept=".png, .jpg, .jpeg" (change)="selectWallpaper($event)" id="wallpaper-img"
                        class="d-none" />
                    <label for="wallpaper-img" class="btn c-pointer btn-blue-outline br25 py-2 px-3 fs12 mt-3">Browse
                        Images</label>
                    <div class="row mt50 justify-content-center px-4">
                        <div class="col-4 pr-1">
                            <button [disabled]="okButtonDisabled" type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                                (click)="viewEditprofile()">Cancel</button>
                        </div>
                        <div class="col-6 pl-1">
                            <button [disabled]="okButtonDisabled" type="button"
                                class="btn btn-blue-secondry br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                                (click)=onSubmitProfile(2)>
                                <em *ngIf="okButtonDisabled" class="fa fa-spinner fa-spin"></em>
                                <img src="./assets/images/save.svg" alt="save"
                                    class="w10 mr-1 mb-1">
                                    Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- change password -->
<div id="overlay" [ngClass]="{'d-block': changePassword}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : changePassword}">
    <!-- for invite -->


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Change Password</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showChangePassword()"></em>
    </div>
    <p class="fs12 grey-j ls-3 ">Change your Password</p>
    <form class="mt-3" name="changePassForm" autocomplete="off"
        (ngSubmit)="changePassForm.form.valid && onSubmitChange(changePassForm)" #changePassForm="ngForm" novalidate>
        <div class="input-group input-rounded mb-0">
            <input required [(ngModel)]="ChangePassword.oldPassword" maxlength="25" #oldPassword="ngModel" name="oldPassword"
                [ngClass]="{ 'has-error': changePassForm.submitted && oldPassword.invalid }" [type]="togglePasswordOne ? 'password' : 'text'"
                class="form-control brtlr-8 fw-600 pt22 pb22  border-right-0 " placeholder="Old Password">
            <div class="input-group-append">
                <span class="input-group-text brtlr-8 border-left-0 " (click)="passwordToggle(1)">
                    <img *ngIf="!togglePasswordOne" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordOne" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">
            <div *ngIf="oldPassword.errors.required" class="text-danger fs12">
                Old Password is required.
            </div>
        </div>
        <div class="input-group input-rounded my-0">
            <input required name="password" pattern="^[a-zA-Z0-9!@#$%\^&*)(+=._-]\S*.{5,25}$" minlength="6" maxlength="25"
                [(ngModel)]="ChangePassword.password" #password="ngModel"
                [ngClass]="{ 'has-error': changePassForm.submitted && password.invalid }" [type]="togglePasswordTwo ? 'password' : 'text'"
                class="form-control br0 fw-600 pt22 pb22  border-right-0 " placeholder="New Password">
            <div class="input-group-append">
                <span class="input-group-text br0 border-left-0" (click)="passwordToggle(2)">
                    <img *ngIf="!togglePasswordTwo" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordTwo" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="password.invalid && (password.dirty || password.touched)">
            <div *ngIf="password.errors.required" class="text-danger fs12">
                Password is required.
            </div>
            <div *ngIf="password.errors.pattern" class="fs12 text-danger">
                Password must be a minimum of 6 characters and maximum of 25 characters in length.
            </div>
        </div>
        <div class="input-group input-rounded mb-0">
            <input required name="confirmPassword" maxlength="25" pattern="{{ChangePassword.password}}"
                [(ngModel)]="ChangePassword.confirmPassword" #confirmPassword="ngModel"
                [ngClass]="{ 'has-error': changePassForm.submitted && confirmPassword.invalid }"
                [type]="togglePasswordThree ? 'password' : 'text'" class="form-control brblr-8 br8 fw-600 pt22 pb22  border-right-0 "
                placeholder="Confirm New Password">
            <div class="input-group-append">
                <span class="input-group-text brblr-8 border-left-0" (click)="passwordToggle(3)">
                    <img *ngIf="!togglePasswordThree" src="./assets/images/show-pass.svg" alt="show password"
                        class="c-pointer">
                    <img *ngIf="togglePasswordThree" src="./assets/images/pass-hide.svg" alt="show password"
                        class="c-pointer">
                </span>
            </div>
        </div>
        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
            <div *ngIf="confirmPassword.errors.required" class="text-danger fs12">
                Confirm Password is required.
            </div>
            <div *ngIf="confirmPassword.errors.pattern" class="text-danger fs12">
                Confirm Password should be same as Password.
            </div>
        </div>
        <div class="row mt50 px-3">
            <div class="col-4 pr-1">
                <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showChangePassword()">Cancel</button>
            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 "
                    [disabled]="!changePassForm.valid">Change Password</button>
            </div>
        </div>
    </form>
</div>

<!-- <ng-template #deletemodal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs16 fw600 grey-m py-3 text-center">Are you sure you want to remove {{compDataFromModal.masterTenant.workSpaceName}} workspace</p>
                <p class="fs14"><b>Note:</b> Deleting this workspace will permanently remove all associated projects, photos, videos, and tags. Are you certain you want to proceed?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="deleteWorkspace()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #deletemodal>
    <div class="modal-lg signup-modal">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom-0 pb-0 text-center"> <!-- Center align modal heading -->
          <h4 class="modal-title fs20 fw-600 grey-g">Confirm</h4>
          <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="modalRef.hide()"></em>
        </div>
        <div class="modal-body">
          <p class="fs16 fw600 grey-m text-center">Are you sure you want to remove
            <span class="text-primary">{{ compDataFromModal.masterTenant.workSpaceName }}</span> workspace.</p>
          <p class="fs14 text-center mb-4"><b>Note:</b> Deleting this workspace will remove all associated projects, photos, videos, and tags. Are you certain you want to proceed?</p>
          <div class="modal-btn text-right">
            <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                [disabled]="submitted" (click)="deleteWorkspace()">
                <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
            <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                (click)="modalRef.hide()">No</button>
        </div>
        </div>
      </div>
    </div>
  </ng-template>

<app-company-edit-sidebar [toggleSidemenu]="editCompanySidemenu" [editWorkspaceName] ="workspaceName" [editWorkspaceId] ="workspaceId" (closesidemenu)="closeCompanySidemenu($event)"></app-company-edit-sidebar>