<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15 pb15">
            <div class="content-card  border-grey shadow-none">
                <div *ngIf="showPeopleMain">
                    <div class="card-header px-4 mb-2 pb-0 bg-white  border-0">
                        <div class="d-sm-flex d-block align-items-center justify-content-between mt20">
                            <div class="mb-2 mb-sm-0">
                                <h1 class="fw-600 fs20 ls-9 grey-g mb-0">People</h1>
                            </div>
                            <div class=" d-flex justify-content-lg-end align-items-center">
                                <button *ngIf="userType !== '4'" class="btn btn-white red-d br25 py-2 fw-600 px-sm-3 fs12 mr-sm-2 mr-1"
                                    (click)="showPeople()"><img src="./assets/images/pending-invites.svg" alt="add"
                                        class="mr-1 w11 mb-1">Pending Invites</button>
                                <ng-container *ngIf="this.router.url === '/project/people' && userType !== '4' && userType !== '5'">
                                    <span>
                                        <button class="btn btn-blue-secondry br25 py-2 px-sm-4 mr-sm-3 fs12"
                                            (click)="showInvite()"><img src="./assets/images/plus.svg" alt="add"
                                                class="mr-1 w11 mb-1">{{addMemberText}}</button></span>
    
                                    <span>
                                        <button class="btn btn-blue-secondry br25 py-2 px-sm-4 mr-sm-3 fs12"
                                            (click)="showInvite(true)"><img src="./assets/images/plus.svg" alt="add"
                                                class="mr-1 w11 mb-1">Add Guest Member</button></span>
                                </ng-container>

                                <span *ngIf="this.router.url === '/company/people'">
                                    <button *ngIf="userType === '1'"
                                        class="btn btn-blue-secondry br25 py-2 px-sm-4 mr-sm-3 fs12"
                                        (click)="showInvite()"><img src="./assets/images/plus.svg" alt="add"
                                            class="mr-1 w11 mb-1">Add People</button></span>
                                <div dropdown class="tag-menu c-pointer" *ngIf="addMemberText !== 'Add Admin'">

                                    <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal"
                                        class="w23 c-pointer" id="basic-link" dropdownToggle (click)="false"
                                        aria-controls="basic-link-dropdown">
                                    <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                                        aria-labelledby="basic-link">
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="getSort(1)">Z - A</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item text-black fs13 fw-600 mb-1"
                                                href="javascript:void(0)" (click)="getAllPeopleListByProjectId()">A -
                                                Z</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 py-0" [ngSwitch]="viewMode">
                        <div class="table-responsive " *ngSwitchCase="'Company'">
                            <div class="contact-scroll pb-5 px-3 people-scroll" *ngIf="peopleList.length > 0" infiniteScroll 
                                [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollUser()"
                                [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">
                            
                                <table class="table custom-table mt-4" aria-describedby="people-list">
                                    <thead>
                                        <tr >
                                            <th scope="col" class="grey-m fs14 fw-600">Name</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Number</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Mail ID</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Member Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr [ngClass]="{'c-pointer': !isLoggedInUser(people.id)}" *ngFor="let people of peopleList; let mi = index" (click)="viewOtherProfile(people, 'company')">
                                            <td class="grey-i fs15 fw600">
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <img *ngIf="!people.profileImage && (people.profileImage === null || people.profileImage === 'null' || people.profileImage === '')" src="./assets/images/empty-img.png"
                                                            alt="" class="people-img w40 h40 c-pointer mr-2">
                                                        <img *ngIf="people.profileImage && (people.profileImage !== null || people.profileImage !== 'null' || people.profileImage !== '')" src={{cloudFrontURL}}{{people.profileImage}}
                                                            alt="" class="people-img w40 h40 c-pointer mr-2">
                                                    </div>
                                                    <div >{{people.firstName}} {{people.lastName}}</div>
                                                </div>
                                            </td>
                                            <td class="grey-i fs15 fw600" *ngIf="people.phoneCode !== null && people.phoneNumber !== null">{{people.phoneCode}} {{people.phoneNumber}}</td>
                                            <td class="grey-i fs20 fw600" *ngIf="people.phoneCode === null && people.phoneNumber === null">-</td>
                                            <td class="grey-i fs15 fw600">{{people.email}}</td>
                                            <td class="grey-i fs15 fw600">{{people.userType?.roleName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          </div>

                          <div class="table-responsive " *ngSwitchCase="'Project'">
                            <div class="contact-scroll pb-5 px-3 people-scroll" *ngIf="peopleList.length > 0" infiniteScroll 
                                [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" 
                                [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">
                            
                                <table class="table custom-table mt-4" aria-describedby="people-list">
                                    <thead>
                                        <tr >
                                            <th scope="col" class="grey-m fs14 fw-600">Name</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Number</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Mail ID</th>
                                            <th scope="col" class="grey-m fs14 fw-600">Member Type</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr [ngClass]="{'c-pointer': !isLoggedInUser(people.user.id)}" *ngFor="let people of peopleList; let mi = index" (click)="viewOtherProfile(people, 'project')">
                                            <td class="grey-i fs15 fw600">
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <img *ngIf="!people.user.profileImage && (people.user.profileImage === null || people.user.profileImage === 'null' || people.user.profileImage === '')" src="./assets/images/empty-img.png"
                                                            alt="" class="people-img w40 h40 c-pointer mr-2">
                                                        <img *ngIf="people.user.profileImage && (people.user.profileImage !== null || people.user.profileImage !== 'null' || people.user.profileImage !== '')" src={{cloudFrontURL}}{{people.user.profileImage}}
                                                            alt="" class="people-img w40 h40 c-pointer mr-2">
                                                    </div>
                                                    <div>{{people.user.firstName}} {{people.user.lastName}}</div>
                                                </div>
                                            </td>
                                            <td class="grey-i fs15 fw600" *ngIf="people.user.phoneCode !== null && people.user.phoneNumber !== null">{{people.user.phoneCode}} {{people.user.phoneNumber}}</td>
                                            <td class="grey-i fs20 fw600" *ngIf="people.user.phoneCode === null && people.user.phoneNumber === null">-</td>
                                            <td class="grey-i fs15 fw600">{{people.user.email}}</td>
                                            <td class="grey-i fs15 fw600">{{people.roleType?.roleName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="card-body text-center p-3" *ngIf="loadingPage || peopleList.length === 0">
                        <div class="page-loaders text-center" *ngIf="loadingPage">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="45"/>
                            </svg>
                            <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                        </div>

                        <div class="pending-invites-bg" *ngIf="!loadingPage && peopleList.length === 0">
                            <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                            <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no pending invites<br> Please invite
                                members by clicking<br> the below button</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="!showPeopleMain">
                    <div class="card-header bg-white border-0 pb-0  mt-2  px-3 pt-3">
                        <ul class="list-inline pl40">
                            <li (click)="showPeople()" class="list-inline-item c-pointer"><a class="fs11 "><span
                                        class="grey-l">People</span></a></li>
                            <li class="list-inline-item"><a class="fs10 "><em
                                        class="grey-l fas fa-chevron-right"></em></a></li>
                            <li class="list-inline-item"><a class="fs11"><span class="grey-l">Pending Invites</span></a>
                            </li>
                        </ul>
                        <div class="d-flex align-items-center">
                            <div class="back-cirle mr-3 c-pointer" (click)="showPeople()">
                                <img src="./assets/images/back-arrow.svg" alt="back-arrow" class="w13">
                            </div>
                            <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Pending Invites</h1>
                        </div>
                    </div>
                    <div class="card-body pl30">
                        <div class="tag-list mt-4" *ngFor="let pending of pendingUserList">
                            <div class="tag-initial-alphabet ml-2">
                                <h3 class="grey-m fw-600 fs13 mb-0">{{pending.key}}</h3>
                            </div>
                            <div class="d-flex align-items-center border-bottom-a py-3 c-pointer"
                                *ngFor="let u of pending.value">
                                <div class="mr-4">
                                    <img *ngIf="!u.recipient || !u.recipient.profileImage"
                                        src="./assets/images/empty-img.png" alt="" class="people-img w40 h40">
                                    <img *ngIf="u.recipient && u.recipient.profileImage"
                                        src={{cloudFrontURL}}{{u.recipient.profileImage}} alt=""
                                        class="people-img w40 h40">
                                </div>
                                <div class="flex-grow-1 mr-2">
                                    <h1 class="grey-m fs15 mb-0 text-capitalize">{{u.recipient.firstName}} {{u.recipient.lastName}}</h1>
                                    <div>
                                        <p class="fs12 grey-l ls-7 fw-600 mb-0">{{u.recipient.email}}</p>
                                        <p class="fs12 grey-l ls-7 fw-600 mb-0">{{u.recipientRole.roleName}}</p>
                                    </div>
                                </div>
                                <div>
                                    <img src="./assets/images/resend.svg" title="Resend Invite" (click)="resendInvite(u)" alt="resend" class="w18 mr-3 c-pointer">
                                    <img src="./assets/images/edit-outline.svg" title="Edit Invite" (click)="editInvite(u)" alt="edit-outline" class="w15 mr-3 c-pointer">
                                    <img src="./assets/images/delete.svg" title="Delete Invite" (click)="openConfirmationModal(u.id)" alt="delete" class="w15 mr-3 c-pointer">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body text-center p-3 " *ngIf="pendingUserList.length === 0">
                        <div class="pending-invites-bg">
                            <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                            <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no pending invites<br> Please invite
                                members by clicking<br> the below button</p>
                            <button class="btn btn-blue-secondry br25 py-2 px-5 fs12 fw-600" (click)="showInvite()">Send
                                Invite</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- invite side bar -->

<div id="overlay" [ngClass]="{'d-block': showInviteBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showInviteBar}">
    <!-- for invite -->
    <div *ngIf="!showContactBar">

        <div class="d-flex justify-content-between">
            <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">{{editInvites ? 'Update' : ''}} Invite</h1>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="editInvites = false;showInvite()"></em>
        </div>

        <div class="scroll-content">
            <form class="mt-3" name="form" [formGroup]='inviteForm' novalidate>
                <label class="fs12 grey-j ls-3">Name*</label>
                <div class="row">
                    <div class="col-6 pr-1">
                        <div class="form-group input-rounded">
                            <input type="text" formControlName="firstName" tabindex=1 pattern="^[A-Za-z ]+$"
                                class="form-control pt22 pb22 fw-600" placeholder="First Name"
                                (keydown.space)="inviteForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                            <div *ngIf="submittedForm">
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('firstName').errors) && !inviteForm.get('firstName').errors?.pattern">
                                    Please enter first name
                                </div>
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('firstName').errors?.pattern)">
                                    First Name must only have alphabets .
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pl-1">
                        <div class="form-group input-rounded">
                            <input type="text" formControlName="lastName" tabindex=2 pattern="^[A-Za-z ]+$"
                                class="form-control pt22 pb22 fw-600" placeholder="Last Name"
                                (keydown.space)="inviteForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                            <div *ngIf="submittedForm">
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('lastName').errors) && !inviteForm.get('lastName').errors?.pattern">
                                    Please enter last name
                                </div>
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('lastName').errors?.pattern)">
                                    Last Name must only have alphabets .
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3">E-Mail*</label>
                    <input type="text" formControlName="email" tabindex=3 class="form-control pt22 pb22 fw-600"
                        placeholder="Enter E-mail" (keyup)='removeTrim(inviteForm)'
                        (keydown.space)="inviteForm.get('email').value.trim().length > 0 ? '': $event.preventDefault();" [readonly]="editInvites">
                    <div class="fs12 text-danger"
                        *ngIf=" (inviteForm.get('email').errors && submittedForm) || (inviteForm.get('email').errors?.required && submittedForm)">
                        Please enter email
                    </div>
                    <span class="fs12 grey-j" *ngIf="editInvites">Note: you can't edit email</span>
                </div>
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3">Role*</label>
                    <select class="form-control input-group-text h-46px" name="role" formControlName="roleType" tabindex=4>
                        <option value="" disabled>select</option>
                        <option *ngFor="let user of userRoleList" value={{user.id}}>{{user.roleName}}</option>
                    </select>
                    <div class="fs12 text-danger"
                        *ngIf=" (inviteForm.get('roleType').errors && submittedForm) || (inviteForm.get('roleType').errors?.required && submittedForm)">
                        Please choose role type
                    </div>
                </div>
                <div class="form-group input-rounded p-4">
                        <input type="checkbox" formControlName="forceInvite" class="form-check-input"
                            tabindex=5 name="forceInvite" id="forceInvite"/>
                            <label class="form-check-label" for="forceInvite">Force invite</label>
                        <div class="fs12">
                            Directly adds user without verification
                        </div>
                </div>

                <div class="row mt50 mb-3 px-3">
                    <div class="col-4 pr-1">
                        <button [disabled]="submitted" type="button" tabindex="6" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600"
                            (click)="editInvites = false;showInvite()" type="button">Cancel</button>
                    </div>
                    <div class="col-8 pl-1">
                        <button [disabled]="submitted" tabindex="7"
                            class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                            (click)="inviteMethod(inviteForm)">
                            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>{{editInvites ? 'Update Invite' : buttonText}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<ng-template #exampleModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to remove user?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethod()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deleteAdminModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to remove user?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethodAdmin()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deletependinginvite>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete Invite?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="deleteInvite()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click) ="modalRef.hide()">No</button>   
                </div>
            </div>
        </div>
    </div>
</ng-template>