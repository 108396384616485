import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api_base/api.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  public constructor(private api: ApiService, private router: Router) { }

  public submitIntegration(payload): Observable<any> {
    return this.api.postByHeadersBearer('pixlyAlbum/procore/auth/store', payload);
  }

  public addIntegration(payload): Observable<any> {
    return this.api.postByHeadersBearer('pixlyAlbum/procore/integrate', payload);
  }

  public getProjects(): Observable<any> {
    const path = `pixlyAlbum/procore/getprojects?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
    return this.api.getByHeadersBearer(path);
  }

  public getAuth(): Observable<any> {
    const path = `pixlyAlbum/procore/auth?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
    return this.api.getByHeadersBearer(path);
  }

  public forceUpdate(payload): Observable<any> {
    return this.api.postByHeadersBearer('pixlyAlbum/procore/project/forceupdate', payload);
  }

  public autoSync(payload): Observable<any> {
    return this.api.putByHeadersBearer('pixlyAlbum/procore/autosync', payload);
  }

  public deleteIntegration(payload): Observable<any> {
    return this.api.deleteByHeadersBodyBearer('pixlyAlbum/procore/integrate',payload);
  }
}
