<section class="main-container">
    <div class="sub-container">
        <div class="content-container dashboard-chart">
            <h1 *ngIf="projectDash" class="grey-g fs18 font-weight-bold ">{{projectDash.projectName}}
                Dashboard</h1>
            <div class="row mt-3">
                <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 pr-md-1">
                    <div class="card content-card p15 c-pointer" routerLink="/project/people"
                        [queryParams]="conCadSessionData({})">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="./assets/images/user.svg" alt="user" class="w19">
                            </div>
                            <div class="blue-e font-weight-bold fs24 mb-0">
                                <span *ngIf="!projectDash || !projectDash.memberCount">0</span>
                                <span *ngIf="projectDash && projectDash.memberCount">{{projectDash.memberCount}}</span>
                            </div>
                        </div>
                        <p class="fs15 grey-l fw-600 mb0 mt35">Member Count</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 px-md-1">
                    <div class="card content-card p15 c-pointer" routerLink="/project/albums"
                        [queryParams]="conCadSessionData({})">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="./assets/images/dashboard-album.svg" alt="user" class="w19">
                            </div>
                            <div class="blue-e font-weight-bold fs24 mb-0">
                                <span *ngIf="!projectDash || !projectDash.albumCount">0</span>
                                <span *ngIf="projectDash && projectDash.albumCount">{{projectDash.albumCount}}</span>
                            </div>
                        </div>
                        <p class="fs15 grey-l fw-600 mb0 mt35">Album Count</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 mb-3 mb-sm-0 pl-md-1">
                    <div class="card content-card p15 c-pointer" routerLink="/project/albums"
                        [queryParams]="conCadSessionData({type: 'Photos'})">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="./assets/images/dashboard-photo.svg" alt="user" class="w19">
                            </div>
                            <div class="blue-e font-weight-bold fs24 mb-0">
                                <span *ngIf="!projectDash || !projectDash.photoCount">0</span>
                                <span *ngIf="projectDash && projectDash.photoCount">{{projectDash.photoCount}}</span>
                            </div>
                        </div>
                        <p class="fs15 grey-l fw-600 mb0 mt35">Videos/Photos Count</p>
                    </div>
                </div>
            </div>
            <div class="row mt-sm-3">
                <div class="col-md-4  col-sm-6 mb-3 mb-md-0 pr-sm-1">
                    <div class="card content-card p15 c-pointer" routerLink="/project/tag-library"
                        [queryParams]="conCadSessionData({})">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="./assets/images/tag.svg" alt="user" class="w19">
                            </div>
                            <div class="blue-e font-weight-bold fs24 mb-0">
                                <span *ngIf="!projectDash || !projectDash.tagCount">0</span>
                                <span *ngIf="projectDash && projectDash.tagCount">{{projectDash.tagCount}}</span>
                            </div>
                        </div>
                        <p class="fs15 grey-l fw-600 mb0 mt35">Tag Count</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6  mb-3 mb-md-0 pl-sm-1 pr-md-1">
                    <div class="card content-card p15">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="./assets/images/dashboard-calendar.svg" alt="user" class="w19">
                            </div>
                            <div class="blue-e font-weight-bold fs24 mb-0">
                                <span *ngIf="!projectDash || !projectDash.dayCount">0</span>
                                <span *ngIf="projectDash && projectDash.dayCount">{{projectDash.dayCount}}</span>
                            </div>
                        </div>
                        <p class="fs15 grey-l fw-600 mb0 mt35">Days old</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12  mb-3 mb-md-0 pl-md-1">
                    <div class="row">
                        <div class="col-6 pr-1 c-pointer" routerLink="/project/todo-list"
                            [queryParams]="conCadSessionData({type: 'completed'})">
                            <div class="card content-card px-2 pt15 pb15">
                                <div class="border-left-green">
                                    <div class="blue-e font-weight-bold fs24 mb37 ml10">
                                        <span *ngIf="!projectDash || !projectDash.completeTaskCount">0</span>
                                        <span
                                            *ngIf="projectDash && projectDash.completeTaskCount">{{projectDash.completeTaskCount}}</span>
                                    </div>
                                    <p class="fs12 grey-l fw-600 mb0 mt35 ml10">Completed task</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 c-pointer" routerLink="/project/todo-list"
                            [queryParams]="conCadSessionData({})">
                            <div class="card content-card p15 px-2 pt15 pb15">
                                <div class="border-left-yellow">
                                    <div class="blue-e font-weight-bold fs24 mb37 ml10">
                                        <span *ngIf="!projectDash || !projectDash.incompleteTaskCount">0</span>
                                        <span
                                            *ngIf="projectDash && projectDash.incompleteTaskCount">{{projectDash.incompleteTaskCount}}</span>
                                    </div>
                                    <p class="fs12 grey-l fw-600 mb0 mt35 ml10">Incomplete task</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row mt-md-3 ">
                <!-- <div class="col-md-12 col-lg-8 pr-lg-1 mb-3 mb-md-0">
                    <div class="card content-card ">
                        <div class="card-header border-0 py-1 bg-white">
                            <div class="d-sm-flex d-block align-items-center justify-content-between py-2">
                                <div class="mb-2 mb-md-0 ">
                                    <h2 class="fs14 ls-g mb-0 fw-600 grey-l">Track</h2>
                                </div>
                                <div class="row ml-md-0">
                                    <div class="col-md-4 offset-md-1 pl-0">
                                        <input type="text"  [(ngModel)]="filter.start" (ngModelChange)="getChartData();"  placeholder="Start Date"
                                        class="form-control fs12 fw-600" bsDatepicker  [bsConfig]="datePickerConfig">
                                    </div>
                                    <div class="col-md-4 pl-0">
                                        <input type="text"  [(ngModel)]="filter.end" (ngModelChange)="getChartData();"  placeholder="End Date"
                                        class="form-control fs12 fw-600" bsDatepicker [bsValue]="bsEndDate" [bsConfig]="datePickerConfig">
                                    </div>
                                  
                                    <div class="col-md-3 pl-0">
                                    <select class="custom-select fw-600 fs12" [(ngModel)]="type" (change)="getChartData()" id="exampleFormControlSelect1">
                                        <option value="day">Day</option>
                                        <option value="month">Month</option>
                                    </select>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                         <div class="card-body" *ngIf="barChartOne.data[0].data.length > 0">
                            <app-dashboard-chart  [baroptions]="barChartOne"></app-dashboard-chart>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-12 col-md-12 pl-lg-1">
                    <div class="content-card br10">
                        <div class="card-header py-3 bg-white border-0">
                            <h2 class="fs14 ls-g mb-0 fw-600 grey-l">
                                <img src="./assets/images/location-dashboard.svg" alt="location-dashboard"
                                    class="w12 mr-2">Project images
                            </h2>
                        </div>
                        <div class="card-body p-0 br10">
                            <agm-map *ngIf="latLngImgArray.length" [zoom]="18" [latitude]="lattitude"
                                [longitude]="longitude" class="dashboard-map w-100 br10" [disableDefaultUI]="true"
                                [usePanning]="true" [mapTypeId]="'satellite'" style="height: 500px;">
                                <agm-marker *ngFor="let group of latLngImgArray; let i = index"
                                    [latitude]="group.latitude" [longitude]="group.longitude" [markerDraggable]="false"
                                    (markerClick)="toggleInfoWindow(i)" [iconUrl]="'./assets/images/red_dot.svg'">
                                    <agm-info-window [isOpen]="selectedMarker === i" [disableAutoPan]="false"
                                        #infoWindow style="max-width: 200px;">
                                        <div class="info-window-container">
                                            <span *ngFor="let imageUrl of group.images">
                                                <img *ngIf="latLngImgArray.length && !imageUrl.endsWith('.mp4')"
                                                    [src]="imageUrl" alt="img" height="60px" width="60px"
                                                    class="mt-1 mb-1 mr-1"
                                                    style="border-radius: 20%;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
                                            </span>
                                            <div *ngIf="!latLngImgArray.length">
                                                No images available
                                            </div>
                                        </div>
                                    </agm-info-window>
                                </agm-marker>
                            </agm-map>
                            <agm-map *ngIf="latLngImgArray.length === 0" [zoom]="18" [latitude]="lattitude"
                                [longitude]="longitude" class="dashboard-map w-100 br10" [disableDefaultUI]="true"
                                [usePanning]="true" [mapTypeId]="'satellite'" style="height: 500px">
                                <agm-marker [latitude]="lattitude" [longitude]="longitude">
                                    <agm-info-window [disableAutoPan]="true">
                                        <div>
                                            Default location set by the project. Please upload images to view where the
                                            pictures were taken.
                                        </div>
                                    </agm-info-window>
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div (click)='projectIconDetails=!projectIconDetails' class="row mt50 project-button-row i-icon-box">
        <img src="./assets/images/i-icon.svg" alt="add" class="mr-1 mb-1">
    </div>
      <div class="project-icon-box" *ngIf='projectIconDetails'>
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
            <h4 class="modal-title fs20 fw-600 pull-left grey-g">Pixly project</h4>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="projectIconDetails=false"></em>
        </div>
        </div>
        <iframe id="ston-6elYwXOKlw" class="h-100 w-100" frameBorder="0" name="StonlyExplanation" title="Stonly" allowfullscreen src="https://stonly.com/embed/en/6elYwXOKlw/view/679544"> </iframe> 
    </div> -->
</section>