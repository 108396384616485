import { Component, OnInit } from '@angular/core';
import {  WorkspaceServiceService } from '../services/workspace-service.service';
import { ApiService  } from '../services/api_base/api.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shared',
    templateUrl: './shared.component.html',

})
export class SharedComponent implements OnInit {

    public viewMode = 'byme';

    public sharedByMePageRecord = 0;

    public sharedByMePageNo = 0;

    public sharedByMeList = [];

    public sharedWithMeList = [];

    public sharedWithMePageNo = 0;

    public sharedWithMePageRecord = 10;

    public errorMessage = '';

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public items: string[] = [
        'A-Z',
        'Z - A',
        'Tag Count',
        'Analytics',
    ];

    public constructor(private worksacepage: WorkspaceServiceService, private apis: ApiService,
        private toastr: ToastrService) {}

    public ngOnInit(): void {
        this.sharedByMe();
    }

    public triggerMethod(type): any {
        if (type === 1) {
            this.sharedByMe();
        } else {
            this.sharedWithMe();
        }
    }

    public sharedByMe(): any {
        this.errorMessage = '';
        this.worksacepage.getAllSharedByMe(this.sharedByMePageNo, this.sharedByMePageRecord).subscribe(res => {
            if (res && res.status && res.status.status === 200) {
                this.sharedByMeList = res.entity;
            } else if (res && res.status && res.status.status === 204) {
               this.errorMessage = 'It seems no record';
            } else {
                this.toastr.error(res.status.msg);
            }
        }, err => {
            this.apis.handleError(err);
        });
    }

    public sharedWithMe(): any {
        this.errorMessage = '';
        this.worksacepage.getAllSharedWithMe(this.sharedWithMePageNo, this.sharedWithMePageRecord).subscribe(res => {
            if (res && res.status && res.status.status === 200) {
                this.sharedWithMeList = res.entity;
            } else if (res && res.status && res.status.status === 204) {
               this.errorMessage = 'It seems no record';
            } else {
                this.toastr.error(res.status.msg);
            }
        }, err => {
            this.apis.handleError(err);
        });
    }
}
