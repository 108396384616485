<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row text-center  ">
         <div class="col-lg-7 col-md-6 my-content col-sm-10 mx-auto mx-md-0 order-1 order-md-0">
            <img src="./assets/images/set-password-img.svg" alt="setpassword" class="banner-img-2">
         </div>
         <div class="col-lg-5 col-md-6 col-sm-10 mx-auto mx-md-0 my-content text-md-left">
            <h1 class="grey-a  font-weight-bold fs28 mb-4">Hello {{datas.username}} !</h1>
            <h2 class="fs17 grey-a fw-600">Set password</h2>
            <p class="fs11 grey-a ls-1 col-lg-9  pl-0">Your password should be 6 or more characters with no spaces.
               Numbers and special charactes are allowed.</p>
            <form name="setPasswordForm" (ngSubmit)="setPasswordForm.form.valid && onSubmit(setPasswordForm)" #setPasswordForm="ngForm" novalidate autocomplete="off" class=" mt-4 form-input-a">
               <div class="row">
                  <div class="col-md-12 col-lg-7 col-sm-10 mx-auto mx-md-0">
                     <div class="input-group input-top-rounded mb-0">
                        <input [type]="togglePasswordOne ? 'text' : 'password'" name="password" required pattern="^[a-zA-Z0-9!@#$%\^&*)(+=._-]\S*.{5,25}$" minlength="6"
                        maxlength="25" [(ngModel)]="setPassword.password" #password="ngModel"
                        [ngClass]="{ 'has-error': setPasswordForm.submitted && password.invalid }" class="form-control fw-600 pt22 pb22  border-right-0 "
                           placeholder="New Password">
                        <div class="input-group-append">
                           <span class="input-group-text border-left-0 " (click)="passwordToggle(1)">
                              <img *ngIf="togglePasswordOne" src="./assets/images/show-pass.svg" alt="show password"
                                 class="c-pointer">
                              <img *ngIf="!togglePasswordOne" src="./assets/images/pass-hide.svg" alt="show password"
                                 class="c-pointer">
                           </span>
                        </div>
                     </div>
                     <div class="fs12 grey-j ls-3 fw-500">
                        Passwords should be at least 8 characters long.
                     </div>
                     <div *ngIf="password.invalid && (password.dirty || password.touched)">
                        <div *ngIf="password.errors.required" class="text-danger fs12">
                           Password is required.
                        </div>
                        <div *ngIf="password.errors.pattern" class="fs12 text-danger">
                           Password must be a minimum of 6 characters and maximum of 25 characters in length.
                        </div>
                     </div>
                     <div class="input-group input-bottom-rounded mb-0">
                        <input name="conpassword"
                        required pattern="{{setPassword.password}}"
                        [(ngModel)]="setPassword.conpassword" #conpassword="ngModel"
                        [ngClass]="{ 'has-error': setPasswordForm.submitted && conpassword.invalid }" [type]="togglePasswordTwo ? 'text' : 'password'" class="form-control fw-600 pt22 pb22  border-right-0 "
                           placeholder="Confirm Password">
                        <div class="input-group-append">
                           <span class="input-group-text border-left-0" (click)="passwordToggle(2)">
                              <img *ngIf="togglePasswordTwo" src="./assets/images/show-pass.svg" alt="show password"
                                 class="c-pointer">
                              <img *ngIf="!togglePasswordTwo" src="./assets/images/pass-hide.svg" alt="show password"
                                 class="c-pointer">
                           </span>
                        </div>
                     </div>
                     <div *ngIf="conpassword.invalid && (conpassword.dirty || conpassword.touched)">
                        <div *ngIf="conpassword.errors.required" class="text-danger fs12">
                           Confirm password is required.
                        </div>
                        <div *ngIf="conpassword.errors.pattern" class="text-danger fs12">
                           Confirm password must be  same as entered above.
                        </div>
                       </div>
                     <div class="row mt50">
                        
                        <div class="col-8 ">
                           <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 "
                           [disabled]="!setPasswordForm.valid && submitted">
                           <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>
                           Create Password</button>

                        </div>


                     </div>
                  </div>
               </div>

            </form>


         </div>
      </div>
   </div>

</section>
<app-sub-footer></app-sub-footer>