
<div class="admin-layout">
  <!-- Header -->
  <app-header (sidemenuToggle) ="sideMenuChange($event)" ></app-header>
  <!-- Header -->
  
  <!-- Sidemenu -->

      <aside class="own-sidemenu-main"  [ngClass]="{show: toggleClass, closed: !toggleClass}"> 
        <app-sidemenu [projectChange]="layoutPro"></app-sidemenu>
      </aside>
  
  <!-- Sidemenu -->
  
  <!-- Page content -->
  <section class="page-content" [ngClass]="{show_pagecontent: toggleClass}">
    <router-outlet (activate)="activeComponent($event)"></router-outlet>
    <aside aria-label="right-sidemenu" class="dashboard-right-sidemenu">
    <app-layout-project  (applayout)="layoutProject($event)" (projectMenuToggle)="ProjectMenuChange($event)"></app-layout-project>
  </aside>
</section>
  <!-- Page content -->
  
 
  </div>
  
  <div class="sidemenu-overlay d-none"></div>
