import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DatePipe } from '@angular/common';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
More(Highcharts);

@Component({
    selector: 'app-dashboard-chart',
    templateUrl: './dashboard-chart.component.html',
    providers: [DatePipe],
})
export class DashboardChartComponent implements OnInit {

  @Input() public baroptions: any;

  public barChartData: any;

  public constructor(private datePipe: DatePipe) { }

  public ngOnInit(): void {
      this.fetchData();
  }

  public fetchData(): void {

      const startDate = new Date(this.baroptions.data[0].data[0][0]);
      const endDate = new Date(this.baroptions.data[0].data[this.baroptions.data[0].data.length - 1][0]);

      if (this.baroptions) {
          this.barChartData = {
              chart: {
                  type: 'spline',
                  height: '243px',
                  width: null,
                  marginBottom: 30,
              },
              credits: {
                  enabled: false,
              },
              title: {
                  text: this.baroptions.title,
              },
              xAxis: {
                  title: {
                      text: `${this.datePipe.transform(startDate, 'MMM d, y')} - ${this.datePipe.transform(endDate, 'MMM d, y')}`,
                  },
                  crosshair: false,
                  labels: {
                      enabled: false,
                      style: {
                          color: '#43425D',
                      },
                  },
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: '',
                  },
                  labels: {
                      style: {
                          color: '#43425D',
                      },
                  },
              },
              tooltip: {
                  pointFormat: '<tr><td style="color:{series.color};padding:0">Photo count: </td>' +
                                  '<td style="padding:0"><b>{point.y}</b></td></tr>',
                  footerFormat: '</table>',
                  shared: true,
                  useHTML: true,
              },
              plotOptions: {
                  column: {
                      pointPadding: 0.2,
                      borderWidth: 0,
                  },
                  series: {
                      color: '#9ACB34',
                      pointWidth: 6,
                      showInLegend: false,
                  },
              },
              series: this.baroptions.data,
          };
          Highcharts.chart('containers', this.barChartData);
      }
  }
}
