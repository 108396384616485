<section class="main-container workspace-setting-layout">
    <div class="sub-container">
        <div class="sub-content-container pt15 pb15">
            <div class="content-card p-0 border-grey shadow-none">
                <div class="card-header bg-white border-0 pb-1  my-2  px-3 pt-3 d-flex align-items-center justify-content-center">

                    <div class="flex-grow-1">
                    <h1 class="fw-600 fs20 ls-9 grey-g mb-0 d-inline-block">Workspace Settings</h1>
                </div>
                <div *ngIf="viewMode=='Billinghistory'">
                    <button class="btn btn-blue-secondry br25 py-2 px-4 fs12 mr-2" *ngIf="filterObj.startDate !== null || filterObj.endDate !== null || filterObj.type !== null" (click)="clearFilterObj()">
                        Clear Filter
                    </button>
                    <button
                        class="btn btn-white fw-600 ls-7 py-2 px-40 br25  grey-g fs14" (click)="filterSidemenu = true">Filters <em class="fas fa-filter fs9 ml-1"></em></button>
                    </div>
                </div>
                <div class="card-body  px-2 py-1">
                    <ul class="nav nav-pills pills-secondry fs14 mb-3 px-3" id="pills-tab" role="tablist">
                        <li class="nav-item mr-4">
                            <a [class.active]="viewMode=='Users'" (click)="getWorkspaceUser('Users')"
                                class="nav-link px-2" href="javascript:void(0)">
                                Users</a>
                        </li>
                        <li class="nav-item mr-4">
                            <a [class.active]="viewMode=='Tags'" (click)="getUserTags('Tags')"
                                class="nav-link px-2" href="javascript:void(0)">
                                Tags</a>
                        </li>
                        <li class="nav-item mr-4" role="presentation">
                            <a [class.active]="viewMode=='Subscription'" (click)="viewMode='Subscription'"
                                class="nav-link px-2" href="javascript:void(0)">
                                Subscription</a>
                        </li>
                        <!-- <li class="nav-item mr-4">
                            <a href="javascript:void(0)" class="nav-link px-2">Settings</a>
                        </li> -->
                        <li class="nav-item" role="presentation">
                            <a [class.active]="viewMode=='Billinghistory'" (click)="setBillingHistory('Billinghistory')"
                                class="nav-link px-2" href="javascript:void(0)">
                                Billing History</a>
                        </li>
                    </ul>
                    <div class="tab-content pb-3" [ngSwitch]="viewMode">
                        <div class="tab-pane fade show active" *ngSwitchCase="'Users'">
                            <div class="contact-scroll pb-5 px-3 people-scroll" *ngIf="userList.length > 0" infiniteScroll
                            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollUser()"
                            [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">
                                <div class="tag-list mt-4" *ngFor="let user of userList">
                                    <div class="d-flex align-items-center border-bottom-a py-3 c-pointer" (click)="viewOtherProfile(user)">
                                        <div class="mr-4">
                                            <img *ngIf="!user.profileImage"
                                                src="./assets/images/empty-img.png" alt="" class="people-img w40 h40">
                                            <img *ngIf="user.profileImage"
                                                src={{cloudFrontURL}}{{user.profileImage}} alt=""
                                                class="people-img w40 h40">
                                        </div>
                                        <div class="flex-grow-1 mr-2">
                                            <h1 class="grey-m fs15 mb-0 text-capitalize">{{user.firstName}} {{user.lastName}}</h1>
                                            <div>
                                                <p class="fs12 grey-l ls-7 fw-600 mb-0">{{user.email}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body text-center p-3 pending-invites-bg" *ngIf="userList.length === 0">
                                <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no people</p>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'Tags'">
                            <div class="contact-scroll px-3 people-scroll" *ngIf="tagslist.length > 0" infiniteScroll
                            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollUser()"
                            [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">
                            <div class="workspace-list-item" *ngFor="let tag of tagslist; let i = index">
                                <label class="workspace-list py-2 d-block mb-0" for="check-radio{{i}}">
                                    <div class="d-flex align-items-center">
                                        <div class="team-logo grey-i fs12 mr-3 d-flex align-items-center justify-content-center"
                                            >
                                            {{getFirstAndLastLetterForTag(tag.tagName)}}
                                        </div>
                                        <div class="flex-grow-1 fs14 mr-3 fw-600 grey-m my-auto">
                                            <span 
                                                >{{tag.tagName}}</span>
                                        </div>
                                        <div class="mr-1">
                                            <img src="./assets/images/delete.svg" alt="delete" class="w15 mr-3 c-pointer" (click)="openModal(tag)" title="Delete tag">
                                          </div>
                                    </div>
                
                                </label>
                            </div>
                            </div>
                            <div class="card-body text-center p-3 pending-invites-bg" *ngIf="tagslist.length === 0">
                                <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mt-3 mb-5">There are no global tags</p>
                            </div>
                            <!-- <div class="card-body text-center p-3 pending-invites-bg">
                                <div class="col-3 mx-auto">
                                    <button type="button" class="btn btn-blue br25 py-2 btn-block fs14 fw-600 pull-right" (click)="openTagModal()"><em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Add Tag</button>
                                </div>
                            </div> -->
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'Subscription'">
                            <div class="subscription-card text-center d-flex align-items-center justify-content-center">
                                <div>
                                    <h2 class="card-title mt-2">
                                        <img src="./assets/images/diamond.svg" alt="diamond" class="mr-1"> Pixly Pro
                                    </h2>
                                    <p *ngIf="counts?.currentPlan === 'BASIC'" class="pack-info">Try Free for 14 Days, Risk
                                        Free!</p>
                                    <p *ngIf="counts?.currentPlan === 'PRO' && trialDifference > 0" class="pack-info">Your Free Period started, {{trialDifference}} days left!</p>
                                    <ul *ngIf="counts?.currentPlan === 'PRO'" class="list-inline my-4 fs14 text-white">
                                        <li class="list-inline-item">Unlimited Projects</li>
                                        <li class="list-inline-item"><em class="fas fa-circle fs4 mx-3"></em></li>
                                        <li class="list-inline-item">Unlimited Photos</li>
                                        <li class="list-inline-item"><em class="fas fa-circle fs4 mx-3"></em></li>
                                        <li class="list-inline-item">Unlimited Members</li>
                                    </ul>
                                    <button *ngIf="counts?.currentPlan === 'BASIC' && !card" class="btn btn-white-primary rounded-md"
                                        (click)="showPayment(1)">
                                        Upgrade to Pixly Pro
                                    </button>
                                    <button *ngIf="card && counts?.currentPlan !== 'PRO'" [disabled]="submitted" class="btn btn-white-primary rounded-md buttonload"
                                        (click)="continueSubscription()">
                                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Upgrade to Pixly Pro
                                    </button>
                                    <button *ngIf="counts?.currentPlan === 'PRO'" class="btn btn-white-primary rounded-md" (click)="cancelPopup()">
                                        Cancel Subscription
                                    </button>
                                </div>
                            </div>
                            <p *ngIf="counts?.currentPlan === 'PRO' && trialDifference <= 0"
                                class="blue-a fs12 font-weight-bold px-2">Your Next Due date : {{nextDueDate | date: 'MMM dd yyyy'}}</p>
                            <div class="current-subscrition-card mx-2">
                                <div class="d-flex flex-column flex-md-row">
                                    <div class="usage-info flex-grow-1 p-4">
                                        <h5 class="fs14 grey-g font-weight-bold mb-3">Current Usage</h5>
                                        <div class="row">
                                            <div class="col-md-4 px-2">
                                                <div class="card h-100 p-3">
                                                    <div class="d-flex justify-content-between">
                                                        <img src="./assets/images/album-count.svg" alt="photo">
                                                        <p class="blue-e fs18 font-weight-bold mb-0">
                                                            {{counts?.workspaceProjectcount}}</p>
                                                    </div>
                                                    <h6 class="fs12 fw-600 grey-l mt-4 mb-0 text-nowrap">Projects Count</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-4 px-2">
                                                <div class="card h-100 p-3">
                                                    <div class="d-flex justify-content-between">
                                                        <img src="./assets/images/photo-count.svg" alt="photo">
                                                        <p class="blue-e fs18 font-weight-bold mb-0">
                                                            {{counts?.workspacePhotoCount}}</p>
                                                    </div>
                                                    <h6 class="fs12 fw-600 grey-l mt-4 mb-0">Videos/Photos Count</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-4 px-2 mb-3 mb-md-0">
                                                <div class="card h-100 p-3">
                                                    <div class="d-flex justify-content-between">
                                                        <img src="./assets/images/user-subs.svg" alt="photo">
                                                        <p class="blue-e fs18 font-weight-bold mb-0">
                                                            {{counts?.workspaceMemberCount}}</p>
                                                    </div>
                                                    <h6 class="fs12 fw-600 grey-l mt-4 mb-0 text-nowrap">Members Count</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="basic-info pt-4 pb-3 px-3" *ngIf="counts?.currentPlan === 'PRO' && this.subscription && this.subscription.status && this.subscription.status === 'active'">
                                        <h5 class="fs14 grey-g font-weight-bold mb-3">Payment details:</h5>

                                        <h6 class="grey-a fs14 mt-4 pt-3 fw-600">Members</h6>

                                        <div class="d-flex grey-a fw-600">
                                            <div class="flex-fill">
                                                <p class="fs14">{{quantity}} x $12</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="fs14">${{getAmount()}}</p>
                                            </div>
                                        </div>

                                        <hr class="border-hr-grey-p">
                                        <p class="font-weight-bold fs14 mb-0">Total Amount <span class="pl-2">{{getAmount() | currency}}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-details mx-2" *ngIf="card">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                    <h3 class="fw-600 fs16 ls-9 grey-g mb-0 px-2">Card details</h3>
                                </div>
                                <div class="saved-card edit-card">
                                    <div class="d-flex justify-content-between mb-3">
                                        <em *ngIf="card.brand === 'visa'"
                                            class="fab fa-cc-visa f1f2 text-white"></em>
                                        <em *ngIf="card.brand === 'discover'"
                                            class="fab fa-cc-discover f1f2 text-white"></em>
                                        <em *ngIf="card.brand === 'mastercard'"
                                            class="fab fa-cc-mastercard f1f2 text-white"></em>
                                        <img src="./assets/images/pay-edit.svg" class="c-pointer" (click)="showPayment(2)" title="Edit Card" alt="Edit">
                                    </div>
                                    <p class="fs14 text-white f-montserrat fw-500 ls-6">XXXX XXXX XXXX
                                        {{card.cardLast4}}</p>
                                    <p class="fs12 text-white f-montserrat fw-500 ls-6">
                                        <span class="mr-3">{{card.funding}} card</span>
                                        <span>VALID TILL {{card.expMonth}}/{{card.expYear}}</span>
                                    </p>
                                    <p class="fs12 text-white f-montserrat fw-500 ls-6 mb-1">{{card.firstName}}
                                        {{card.lastName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngSwitchCase="'Billinghistory'">


                            <div class="card-body text-center p-3" *ngIf="!submitted && billingHistoryList.length === 0">

                                <div class="page-loaders text-center h-600px my-5" *ngIf="submitted">
                                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50" cy="50" r="45"/>
                                    </svg>
                                    <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                                </div>

                                <div class="pending-invites-bg"  *ngIf="!submitted && billingHistoryList.length === 0">
                                    <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                                    <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no Billing History available</p>
                                </div>
                            </div>

                            <ng-container *ngIf="billingHistoryList.length > 0">
                            <div class="table-responsive billing-tab scroll-content h-450px"  infiniteScroll
                            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                            [infiniteScrollContainer]="'.billing-tab'" [fromRoot]="true" [scrollWindow]="false" >
                                <table class="table primary-table" aria-describedby="Billing">
                                    <thead>
                                        <th>Billing date</th>
                                        <th>Subscription Type</th>
                                        <th>User Count</th>
                                        <th>Billing</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let billingData of billingHistoryList">
                                            <td>{{convertUtcToDate(billingData.billingOnTimestamp) | date : 'longDate'}}</td>
                                            <td *ngIf="billingData.planType  === 'PRO'">Pixly Pro</td>
                                            <td *ngIf="billingData.planType !== 'PRO'">-</td>
                                            <td>{{billingData.userCount}}</td>
                                            <td>${{billingData.amount}}</td>
                                            <td>
                                                <span *ngIf="billingData.status === 'Success'" class="text-green">Sucess</span>
                                                <span *ngIf="billingData.status !== 'Success'"  class="text-red">Failed <em class="fas fa-redo-alt grey-refresh-bg c-pointer" (click)="retryInvoice(billingData.invoiceId)" ></em></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!--Save Card-->
<div id="overlay" [ngClass]="{'d-block': addCard}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : addCard}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Add Card</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="saveCard()"></em>
    </div>
    <form class="mt-3">
        <label class="grey-j fs12 ">Name</label>
        <div class="row">
            <div class="col-12 pr-1">
                <div class="form-group input-rounded mb-3">
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter First Name">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group input-rounded mb-3">
                    <label class="grey-j fs12 ">Card Number</label>
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Number">
                </div>
                <div class="form-group input-rounded mb-3">
                    <label class="grey-j fs12 ">Month/Year</label>
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="MM/YY">
                </div>
                <div class="form-group input-rounded mb-3">
                    <label class="grey-j fs12 ">Cvv</label>
                    <input type="password" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="">
                </div>
            </div>
        </div>
        <div class="row px-3 mt40">
            <div class="col-4 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showPayment(1)">Cancel</button>

            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600" (click)="showPayment(1)">Save
                    Card</button>
            </div>
        </div>
    </form>
</div>

<!-- Make Payment -->
<div id="overlay" [ngClass]="{'d-block': Payment}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : Payment}">

    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Payment</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showPayment(0)"></em>
    </div>
    <form class="mt-3" [formGroup]='cardForm' novalidate>
        <label class="grey-j fs12 ">Name on card</label>
        <div class="row">
            <div class="col-12 pr-1">
                <div class="form-group input-rounded mb-3">
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Enter Name on card"
                        formControlName="firstName" tabindex=1 maxlength="25"
                        (keydown.space)="cardForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();"
                        [readonly]="card">
                    <div class="fs12 text-danger"
                        *ngIf="(cardForm.get('firstName').errors && cardForm.get('firstName').errors?.required && Invitesubmitted)">
                        Please enter name on card
                    </div>
                    <span *ngIf="card" class="fs12 grey-j">Note: you can't edit name</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group input-rounded mb-3">
                    <label class="grey-j fs12 ">Card Number</label>
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Number"
                        formControlName="cardnumber" tabindex=3 [mask]="card?'AAAA AAAA AAAA 0000':'0000 0000 0000 0000'" [validation]="false"
                        (keydown.space)="cardForm.get('cardnumber').value.trim().length > 0 ? '': $event.preventDefault();"
                        [readonly]="card">
                    <div class="fs12 text-danger"
                        *ngIf="(cardForm.get('cardnumber').errors && cardForm.get('cardnumber').errors?.required && Invitesubmitted)">
                        Please enter card number
                    </div>
                    <span *ngIf="card" class="fs12 grey-j">Note: you can't edit card number</span>
                </div>
                <div class="form-group input-rounded mb-3">
                    <label class="grey-j fs12 ">Month/Year</label>
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="MM/YY"
                        formControlName="expiry" tabindex=4 mask="00/00" [validation]="false"
                        [dropSpecialCharacters]="false"
                        (keydown.space)="cardForm.get('expiry').value.trim().length > 0 ? '': $event.preventDefault();">
                    <div class="fs12 text-danger"
                        *ngIf="(cardForm.get('expiry').errors && cardForm.get('expiry').errors?.required && Invitesubmitted)">
                        Please enter expiry date
                    </div>
                </div>
                <div class="form-group input-rounded mb-3" *ngIf="!card">
                    <label class="grey-j fs12 ">Cvv</label>
                    <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" placeholder="Cvv"
                        formControlName="cvv" tabindex=5 mask="0000" [validation]="false"
                        (keydown.space)="cardForm.get('cvv').value.trim().length > 0 ? '': $event.preventDefault();">
                    <div class="fs12 text-danger"
                        *ngIf="(cardForm.get('cvv').errors && cardForm.get('cvv').errors?.required && Invitesubmitted)">
                        Please enter cvv
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-3 sidebtn-bottom">
            <div class="col-4 pr-1">
                <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    (click)="showPayment(0)" [disabled]="submitted">Cancel</button>

            </div>
            <div class="col-8 pl-1">
                <button type="button" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                    (click)="getCardToken(cardForm)" [disabled]="submitted">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>{{buttontext}}</button>

            </div>
        </div>
    </form>
</div>

<ng-template #cancelsubscription>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Once you cancelled, you will be debited an outstanding amount of {{getAmount()}} USD by Today. Are you sure you want to cancel?</p>

                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethod()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click) ="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #addTagModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Add Tag</h1>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <form class="mt-4" name="globalTagForm" #globalTagForm="ngForm" novalidate>
                    <div class="form-group input-rounded">
                       <h2 class="fs12 grey-j ls-3">Tag Name</h2>
                       <input maxlength="50" [validation]="false" type="text" name="tagName" required [(ngModel)]="createWorkspace.tagName"
                          #tagName="ngModel" [ngClass]="{ 'has-error': globalTagForm.submitted && tagName.invalid }"
                          class="form-control br8 pt22 pb22 fw-600" placeholder="Type Name Here...">
                       <div *ngIf="tagName.invalid && (tagName.dirty || tagName.touched)">
                          <div *ngIf="tagName.errors.required" class="text-danger fs12">
                             Tag name is required.
                          </div>
                       </div>
                    </div>
                    <div class="form-group input-rounded">
                       <h2 class="fs12 grey-j ls-3">Tag Purpose</h2>
                       <textarea [validation]="false" name="tagPurpose" style="height: 150px;" [(ngModel)]="createWorkspace.tagPurpose"
                          #tagPurpose="ngModel" required
                          [ngClass]="{ 'has-error': globalTagForm.submitted && tagPurpose.invalid }"
                          class="form-control br8 pt22 pb22 fw-600" placeholder="Enter Tag purpose..."></textarea>
                          <div *ngIf="tagPurpose.invalid && (tagPurpose.dirty || tagPurpose.touched)">
                            <div *ngIf="tagPurpose.errors.required" class="text-danger fs12">
                               Tag purpose is required.
                            </div>
                         </div>
                    </div>
                    <div class="form-group input-rounded">
                       <h2 class="fs12 grey-j ls-3">Tag Color</h2>
                       <div class="input-with-color">
                         <input
                           maxlength="50"
                           [validation]="false"
                           type="text"
                           name="tagColor"
                           required
                           [(ngModel)]="createWorkspace.tagColor"
                           #tagColor="ngModel"
                           [ngClass]="{ 'has-error': globalTagForm.submitted && tagColor.invalid }"
                           class="form-control br8 pt22 pb22 fw-600"
                           placeholder="Type Name Here..."
                         />
                         <input
                           type="color"
                           [(ngModel)]="createWorkspace.tagColor"
                           #tagColor="ngModel"
                           [ngModelOptions]="{ standalone: true }"
                           id="color"
                         />
                       </div>
                       <div *ngIf="tagColor.invalid && (tagColor.dirty || tagColor.touched)">
                         <div *ngIf="tagColor.errors.required" class="text-danger fs12">
                           Tag color is required.
                         </div>
                       </div>
                     </div>
                    <div class="row">
                       <div class="col-12">
                          <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" style="width: 120px; margin: auto;" type="submit"
                             (click)="globalTagForm.form.valid && createTag(globalTagForm)"
                             [disabled]="!globalTagForm.valid || submitted">
                             <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Add Tag</button>
                       </div>
                      
                    </div>
                 </form>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #deleteGlobalTagModel>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete this global tag?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="deleteTag()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<app-billing-history-filter [toggleSidemenu]="filterSidemenu" (closesidemenu)="closeFilterSidemenu($event)" (filterSubmite)="getFilterObject($event)"></app-billing-history-filter>
