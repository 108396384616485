import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private autocompleteService: google.maps.places.AutocompleteService;
  constructor(private mapsAPILoader: MapsAPILoader) {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  searchPlaces(query: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve) => {
      this.autocompleteService.getPlacePredictions({ input: query }, (predictions) => {
        resolve(predictions);
      });
    });
  }

  getPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve) => {
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));
      placesService.getDetails({ placeId }, (placeResult) => {
        resolve(placeResult);
      });
    });
  }

  reverseGeocode(lat: number, lng: number): Promise<string> {
    if (lat && lng) {
    return new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
  
        geocoder.geocode(
          { location: { lat, lng } },
          (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
              resolve(results[0].formatted_address);
            } else {
              reject('Reverse geocode failed');
            }
          }
        );
      });
    });
    }
  }
}
