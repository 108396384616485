import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject} from 'rxjs';
import { ApiService } from './api_base/api.service';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceServiceService {

    allowedClientEmails = ['nvanhook@kitchensaver.com', 'tvanhook@kitchensaver.com', 'jhzaught@kitchensaver.com', 'tsalvati@kitchensaver.com'];

    allowdBathSaverEmails = ['dhemshrodt@bathsaver.com', 'dtribby@bathsaver.com', 'rsahrhage@bathsaver.com', 
    'tsalvati@bathsaver.com', 'ttemple@bathsaver.com', 'chilton@bathsaver.com', 'tvanhook@bathsaver.com', 
    'kralston@bathsaver.com', 'bfadmin@bathsaver.com'];

    public emitAllProjects:Subject<any> = new Subject();

    public constructor(private api: ApiService, private router: Router) { }

    public addWorkspace(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyLogin/addworkspace', payload);
    }

    public addWorkspaceToCompany(companyId, workspaceId): Observable<any> {
        return this.api.postByHeadersBearer(`pixlyProfile/v1/company/${companyId}/workspaces/${workspaceId}/add`, {});
    }

    public addProject(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/createTeam', payload);
    }

    public cloneProject(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/team/clone', payload);
    }

    public getAllWorkspace(pageNumber, pageRecord): Observable<any> {
        return this.api.getByHeadersBearer(`pixlyLogin/getallworkspaces?pageNumber=${pageNumber}&pageRecord=${pageRecord}`);
    }

    public getWorkspacesByCompanyId(companyId: string): Observable<any> {
        return this.api.getByHeadersBearer(`pixlyProfile/v1/company/${companyId}/workspaces`);
    }

    public getAllProject(params): Observable<any> {
        const path = `pixlyProfile/getteams${params}`;
        return this.api.getByHeadersBearer(path);
    }

    public getProjectFeed(params): Observable<any> {
        const path = `pixlyNotification/getnotifcations${params}`;
        return this.api.getByHeadersBearer(path);
    }

    public getNotification(params): Observable<any> {
        const path = `pixlyNotification/getAllNotifcation${params}`;
        return this.api.getByHeadersBearer(path);
    }

    public projectInvite(payload, path): Observable<any> {
        return this.api.postByHeadersBearer(path, payload);
    }

    public forceInviteUser(data: any): Observable<any> {
        delete data.forceInvite;
        return this.api.postByHeadersBearer('pixlyProfile/force-invite', data);
    }

    public getUserType(): Observable<any> {
        return this.api.getByHeaders('pixlyLogin/getusertype');
    }

    public invitePendingUser(): Observable<any> {
        const path = `pixlyProfile/getpendinginvitations?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&teamId=${sessionStorage.getItem('projectId')}`;
        return this.api.getByHeadersBearer(path);
    }

    public getAllPeopleListByProjectId(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getmembersbyteamid', payload);
    }

    public getMembersForAlbum(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getmembersforalbum', payload);
    }

    public getCompanyMembersByProject(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getcompanymembersbyprojectid', payload);
    }

    public updateProjectName(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/updateteamname', payload);
    }

    public getUserRoleType(id, wsName): Observable<any> {
        const path = `pixlyProfile/getuserrole?userId=${localStorage.getItem('userId')}&workSpaceName=${wsName}&projectId=${id}`;
        return this.api.getByHeadersBearer(path);
    }

    public getUserRoleType1(id): Observable<any> {
        const path = `pixlyProfile/getuserrole?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&projectId=${id}`;
        return this.api.getByHeadersBearer(path);
    }

    public getWorkspaceDashboard(): Observable<any> {
        const path = `pixlyProfile/getworkspacedashboard?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        return this.api.getByHeadersBearer(path);
    }

    public getAllAdminList(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getadminuser', payload);
    }

    public inviteAdminUser(): Observable<any> {
        const path = `pixlyProfile/getadminpendinginvitations?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&pageNumber=0&pageRecord=0`;
        return this.api.getByHeadersBearer(path);
    }

    public getProjectDashboard(): Observable<any> {
        const path = `pixlyProfile/getprojectdashboard?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&projectId=${sessionStorage.getItem('projectId')}`;
        return this.api.getByHeadersBearer(path);
    }

    public removeUserFromProject(id): Observable<any> {
        const path = `pixlyProfile/removememberfromteamweb?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&membershipId=${id}`;
        return this.api.deleteByHeadersBearer(path);
    }


    public getNotifications(data): Observable<any> {
        const path = `pixlyNotification/getNotifcations?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&search=${data.search}`;
        return this.api.getByHeadersBearer(path);
    }


    public updateNotification(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyNotification/updatenotification', payload);
    }

    public getAllSharedByMe(pageNo, pageRecord): Observable<any> {
        const path = `pixlyAlbum/pictureShareByMe?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&pagenumber=${pageNo}&pagerecord=${pageRecord}`;
        return this.api.getByHeadersBearer(path);
    }

    public getAllSharedWithMe(pageNo, pageRecord): Observable<any> {
        const path = `pixlyAlbum/pictureShareWithMe?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&pagenumber=${pageNo}&pagerecord=${pageRecord}`;
        return this.api.getByHeadersBearer(path);
    }

    public getPicture(data): Observable<any> {
        const path = `pixlyAlbum/viewalbumByLink?linkId=${data.linkId}&userId=${data.userId}&workSpaceName=${data.workSpaceName}`;
        return this.api.getByHeaders(path);
    }

    public getRecentAlbums(): Observable<any> {
        const path = `pixlyAlbum/getrecentalbum?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&projectId=${sessionStorage.getItem('projectId')}`;
        return this.api.getByHeadersBearer(path);
    }

    public getUserNotificationSetting(): Observable<any> {
        const path = `pixlyNotification/getusernotificationsettings?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        return this.api.getByHeadersBearer(path);
    }

    public updateUserNotification(data): Observable<any> {
        return this.api.putByHeadersBearer('pixlyNotification/updatenotificationsettings', data);
    }

    public albumphotospdfApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/albumphotospdf1', payload);
    }

    public resendInviteApi(data): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/resendinvite', data);
    }

    public deleteInviteApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/deleteinvite', payload);
    }


    public deleteProject(id): Observable<any> {
        const path = `pixlyProfile/${id}/deleteteam?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getCompanyListApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getallcompany', payload);
    }

    public addCompanyApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/addcompany', payload);
    }

    public editCompanyApi(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyProfile/updatecompany', payload);
    }

    public searchCompanyApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getallcompanybysearch', payload);
    }

    public deleteCompanyApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/deletecompany', payload);
    }

    public removeAdminFromProject(id): Observable<any> {
        const path = `pixlyProfile/removeadmin?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&membershipId=${id}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getMemberByCompanyId(payload, path): Observable<any> {
        return this.api.postByHeadersBearer(path, payload);
    }

    public getChartDataApi(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/getphotochartcount', payload);
    }

    public getUserTypeService(): any {
        this.getUserRoleType1('').subscribe((res): any => {
            console.log(res);
            if (res && res.entity) {
                if (res.entity && res.entity.roleId && (res.entity.roleId === 1 || res.entity.roleId === 2)) {
                    console.log('iffffffff');
                    
                    sessionStorage.setItem('usertype', res.entity.roleId);
                    localStorage.removeItem('email');
                    localStorage.removeItem('username');
                    setTimeout((): any => {
                        this.router.navigate(['/company/dashboard'], { queryParams: this.api.getSessionData() });
                    }, 300);
                } else {
                    console.log('else');
                    
                    this.router.navigate(['/company'], { queryParams: this.api.getSessionData() });
                }
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public clearNotificaiton(): Observable<any> {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        return this.api.putByHeadersBearer('pixlyNotification/clearnotification', payload);
    }

    public getWorkspaceNotification(params): Observable<any> {
        const path = `pixlyNotification/getworkspacenotifcation${params}`;
        return this.api.getByHeadersBearer(path);
    }

    public updateWorkspace(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyLogin/updateworkspace', payload);
    }

    public getProjectId(): string {
        return sessionStorage.getItem('projectId') ? sessionStorage.getItem('projectId') : '';
    }

    public generatePdf(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyReport/report', payload);
    }

    public addGlobalTags(payload): Observable<any> {
        return this.api.postByHeadersBearer(`pixlyProfile/v1/tag/global/user/${payload.userId}`, payload);
    }

    public getAllTags(id): Observable<any> {
        const path = `pixlyProfile/v1/tag/global/user/${id}`;
        return this.api.getByHeadersBearer(path);
    }

    public saveGlobalTagsForTenant(userId, tenantId, payload): Observable<any> {
        return this.api.postByHeadersBearer(`pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}`, payload);
    }

    public addTagsForTenant(userId, tenantId, payload): Observable<any> {
        return this.api.postByHeadersBearer(`pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}`, payload);
    }

    public getAllTagsForWorkspace(userId, tenantId): Observable<any> {
        const path = `pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}`;
        return this.api.getByHeadersBearer(path);
    }

    public removeTagForWorkspace(userId, tenantId, id): Observable<any> {
        const path = `pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}/tag/${id}`;
        return this.api.deleteByHeadersBearer(path);
    }

    public getAllImageLocationsForProjects(userId, tenantId, projectId): Observable<any> {
        const path = `pixlyProfile/v2/project/user/${userId}/tenant/${tenantId}/project/${projectId}/map`;
        return this.api.getByHeadersBearer(path);
    }

    isClientUser(userEmail: string): boolean {
        return this.allowedClientEmails.includes(userEmail) || this.allowdBathSaverEmails.includes(userEmail);
    }
    
    isOtherGetCarneraUser(userEmail: string): boolean {
        return userEmail.includes('@kitchensaver.com') && !this.isClientUser(userEmail);
    }
}
