<aside #projectlayout>
    <div class="d-flex justify-content-between border-bottom-a pl15 pt10 pb10 mb-2">
        <h1 class="grey-g fs14 font-weight-bold mb-0"><img src="./assets/images/files.svg" alt="projects"
                class="mr-2 w14">My Projects</h1>
    </div>
    <div class="pl10 pb15 pr10">
        <div class="scroll-content">
            <div class="form-group input-rounded">
                <input maxlength="50" type="text" name="search project" #searchProject
                    class="form-control br8 pt22 pb22 fw-600 " placeholder="Search projects">
            </div>
            <div *ngIf="filteredProjects && filteredProjects.length > 0; else noRecords">
                <div class="workspace-list-item" *ngFor="let project of filteredProjects; let i = index">
                    <label class=" workspace-list py-2 d-block mb-0" [ngClass]="{'selected-project': selectedProjectId === project.teamId}" for="check-radio{{i}}">
                        <div class="d-flex align-items-center">
                            <div class="team-logo grey-i fs12 mr-2 c-pointer d-flex align-items-center justify-content-center"
                                (click)="selectedProjectMethod(project)">
                                {{getFirstAndLastLetter(project.teamName)}}
                            </div>
                            <div class="flex-grow-1 fs14 fw-600 grey-m my-auto">
                                <span class="c-pointer" style="font-size: 15px;" (click)="selectedProjectMethod(project)"
                                    *ngIf="!project.inLineEdit" [title]="project.teamName">{{project.teamName | ngXtruncate:20:'...'}}</span>
                                    <div *ngIf="project.addressDetails" style="display: flex; align-items: center;">
                                        <i class="fas fa-map-marker-alt" style="font-size: 12px; color: grey; margin-right: 4px;"></i>
                                        <p data-toggle="tooltip" [title]="project.addressDetails" style=" white-space: nowrap;
                                        width: 170px;
                                        overflow: hidden;
                                        cursor: pointer;
                                        text-overflow: ellipsis;font-size: 10px; color: grey; margin: 0;">{{project.addressDetails}}</p>
                                    </div>
                                </div>
                            <div class="d-flex align-items-center">
                                <div>
                                    <!-- <img 
                                    src="./assets/images/circle-tick-blue.svg" alt="projects" class="mr-2 w14"> -->
                                    <!-- <i *ngIf="selectedProjectId === project.teamId" class="fas fa-check-circle c-pointer mr-1" style="font-size: 16px;"></i> -->
                                    <!-- <img *ngIf="usertype !== 4" src="./assets/images/project_setting.svg" alt="projects" class="ml-4 w14 c-pointer"
                                        (click)="editInput(i, project)"> -->
                                        <i *ngIf="usertype && usertype !== 4 && usertype !== 5" src="./assets/images/project_setting.svg" style="color: #0e63fc" (click)="editInput(i, project)" class="fas fa-cog c-pointer"></i>

                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <ng-template #noRecords>
                <div class="no-records-message">
                    <b class="message-title">Uh-oh! No projects found.</b>
                    <p class="message-suggestion">Why not create a new project?</p>
                  </div>
             </ng-template>
        </div>
        <div class="row mt50 w-100 project-button-row project-button-container" *ngIf="usertype !== 4 && usertype !== 5">
            <div class="col-10 mx-auto">
                <!-- <div class="d-flex align-items-stretch mb-2">
                    <button type="button" (click)="openToggle(true)" class="btn btn-blue br25 py-2 project-buttons fs14 fw-600 flex-grow-1 mr-2" *ngIf="myProjectList && myProjectList.length && selectedProjectId"> Clone
                    </button>
                    <button type="button" (click)="showInvite()" class="btn btn-blue br25 py-2 project-buttons fs14 fw-600 flex-grow-1" *ngIf="myProjectList && myProjectList.length && selectedProjectId"> Share
                    </button>
                </div> -->
                <button type="button" (click)="openToggle(true)" class="btn btn-blue br25 py-2 btn-block fs14 fw-600" *ngIf="myProjectList && myProjectList.length && selectedProjectId"> Clone
                </button>
                <button type="button" (click)="openToggle(false)" class="btn btn-blue br25 py-2 btn-block fs14 fw-600"> New
                    Projects</button>
            </div>
        </div>
    </div>

</aside>
<div id="overlay" [ngClass]="{'d-block': showCreateProject}"></div>
<div #sidenav class="right-sidebar bg-white top60" [ngClass]="{'show-side-nav' : showCreateProject}">

    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">{{isClone ? 'Clone your project' : 'Name your Project'}}</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="close()"></em>
    </div>
    <p class="fs12 ls-4 grey-g" *ngIf="isClone">You are about to clone all <b>Tags</b> and <b>People</b> from <b *ngIf="selectedProjectName">{{selectedProjectName.teamName}}</b> project.</p>
    <h2 class="fs12 grey-j ls-3">What would you like to call your Project?</h2>
    <form class="mt-4 " name="projectForm" #projectForm="ngForm" novalidate>
        <div class="form-group input-rounded">
            <input maxlength="50" type="text" required name="teamName" required [(ngModel)]="createProject.teamName"
                #teamName="ngModel" [ngClass]="{ 'has-error': projectForm.submitted && teamName.invalid }"
                class="form-control br8 pt22 pb22 fw-600 " placeholder="Project name..."
                (keydown.space)="createProject.teamName.trim().length > 0 ? '': $event.preventDefault();">
            <div *ngIf="teamName.invalid && submitted">
                <div *ngIf="teamName.errors.required" class="text-danger fs12">
                    Project name is required.
                </div>
            </div>
        </div>
        <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p> 
            <div class="form-group input-rounded">
               <h2 class="fs12 grey-j ls-3">Address</h2>
               <div class="position-relative">
                <input type="text" name="address" placeholder="Address..." autocorrect="off" autocapitalize="off"
                    spellcheck="off" [(ngModel)]="address" #searchInput class="form-control br8 pt22 pb22 pr30 fw-600">
                    <!-- <i *ngIf="!currentLocation" class="fas fa-map-marked-alt location-icon" (click)="getLocation()"></i> -->
                    <img *ngIf="!currentLocation" src="../../../assets/images/target.png" class="location-icon" (click)="getLocation()" alt="location">
                    <ng-container *ngIf="currentLocation">
                        <em class="fa fa-spinner fa-spin loading-icon"></em>
                    </ng-container>
                </div>
                <div *ngIf="teamName.invalid && submitted">
                    <div *ngIf="teamName.errors.required" class="text-danger fs12">
                        Project name is required.
                    </div>
                </div>
            </div>
            <div class="form-group form-check" >
                <input type="checkbox" name="addAllMembers" id="addAllMembers"  class="form-check-input" [(ngModel)]="addAllMembers">
                <label *ngIf="companyName && companyName!=''" for="addAllMembers" class="form-check-label c-pointer">Add {{companyName}} company users to new project</label>
                <label *ngIf="!companyName || companyName==''" for="addAllMembers" class="form-check-label c-pointer">Add workspace users to new project</label>
            </div>
        <div class="row mt50 px-3">
            <div class="col-4 pr-1">
                <button [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    type="button" (click)="close()">Cancel</button>
            </div>
            <div class="col-8 pl-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                    (click)="isClone ? cloneProjectMethod(projectForm) : createProjectMethod(projectForm)"
                    [disabled]="!projectForm.valid || submitted">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em> {{ isClone ? 'Clone Project' : 'Create Project'}}</button>
            </div>
        </div>
    </form>

</div>



<div id="overlay" [ngClass]="{'d-block': showProSetting}"></div>
<div #sidenav class="right-sidebar bg-white top60" [ngClass]="{'show-side-nav' : showProSetting}">

    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Project Setting</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="close1()"></em>
    </div>
    <h2 class="fs12 grey-j ls-3">Edit your Project</h2>
        <div class="form-group input-rounded">
            <form>
                <div class="form-group mb-0 mr-2 input-rounded">
                    <input maxlength="50" type="text" name="editTeam" required [(ngModel)]="editTeamName"
                        #editTeam="ngModel" class="form-control br8 pt22 pb22 fw-600 " placeholder="Project name...">
                    <div *ngIf="editTeam.invalid && (editTeam.dirty || editTeam.touched)">
                        <div *ngIf="editTeam.errors.required" class="text-danger fs12">
                            Project name is required.
                        </div>
                    </div>
                </div>
                
                <div class="form-group input-rounded mt-3">
                    <h2 class="fs12 grey-j ls-3">Edit your Address</h2>
                    <div class="position-relative">
                    <input type="text" name="place" placeholder="Address..." [(ngModel)]="editAddress" #editSearchInput class="form-control br8 pt22 pb22 pr30 fw-600"
                        >
                    <!-- <i *ngIf="!currentLocation" class="fas fa-map-marked-alt location-icon" (click)="getLocation()"></i> -->
                    <img *ngIf="!currentLocation" src="../../../assets/images/target.png" class="location-icon" (click)="getLocation()" alt="location">

                    <ng-container *ngIf="currentLocation">
                        <em class="fa fa-spinner fa-spin loading-icon"></em>
                    </ng-container>
                </div>
                </div>
            </form>
        </div>

        <div class="row mt50 px-3">

            <div class="col-12 pr-1 px-8 py-2">
                <button class="btn btn-orange-light br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                    (click)="reportModal()" [disabled]="submitted"> <img src="./assets/images/noun-PDF.svg" alt="pdf" class="mr-2 w17 h18" />Get PDF</button>
            </div>
            <div class="col-4 pr-0">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                *ngIf="((selectedProjectId !== editProjectId && usertype === 3) || usertype === 1 || usertype === 2)"
                (click)="askDeleteProject()" [disabled]="submitted">Delete</button>
            </div>
            <div class="col-4 pr-0">
                <button [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
                    type="button" (click)="close1()">Cancel</button>
            </div>
            <div class="col-4 pr-1">
                <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                    (click)="updateProjectName()" [disabled]="submitted">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Save</button>
            </div>
        </div>

</div>

<ng-template #deletemodal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to remove this project?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="deleteProject()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- pdf settings -->
<div id="overlay" [ngClass]="{'d-block': pdfModalSelect}"></div>
<div #sidenav class="right-sidebar bg-white top60" [ngClass]="{'show-side-nav' : pdfModalSelect}">
    <div>
        <div class="d-flex justify-content-between">
          <div class="mr-4 c-pointer" (click)='showProjectSettings()'>
              <img src="./assets/images/back-side.svg" alt="back-arrow" class="w13 mb4">
          </div>
           <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ml35 side-pdf-layout">Get PDF</h1>
           <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="cancelPdf()"></em>
        </div>
     </div>
    <form>
        <div class="get-pdf mb-3 mt-4">
            <label class="fs12 grey-j ls-3 fw-500">Should this album be public or private?</label>
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Photo summary'" class="form-check-input d-none" type="radio" id="inlineRadio3" name="otpType" value="Photo summary">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio3">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary includes all photos, tags and comments.</h2>
                </div>
            </label>
        </div>

        <div class="get-pdf my-3">
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Punch List'" class="form-check-input d-none" type="radio" id="inlineRadio4" name="otpType"
                 value="Punch List">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio4">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list includes all photos with tags assigned as tasks.</h2>
                </div>
            </label>
        </div>
        <h2 class="fs10 mb-0 ls-c ml14">Both formats include a title sheet and a tag summary sheet.</h2>

       </form>
        <div class="row mt50 px-3 ">
            <div class="col-4 pr-1">
               <button [disabled]="pdfSubmitted" class="btn btn-grey-f text-black br25 pt7 pb7 btn-block fs13 fw-500" type="button"
               (click)="cancelPdf()">Cancel</button>
            </div>

            <div class="col-8 pl-1">
                <button [disabled]="pdfSubmitted" class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-500 buttonload" type="button"
                (click)="generatePDF()">
                <em *ngIf="pdfSubmitted" class="fa fa-spinner fa-spin"></em>Download</button>
             </div>
         </div>
</div>

<!-- invite side bar -->

<div id="overlay" [ngClass]="{'d-block': showInviteBar}"></div>
<div #sidenav class="right-sidebar bg-white top60" [ngClass]="{'show-side-nav' : showInviteBar}">
    <!-- for invite -->
    <div>

        <div class="d-flex justify-content-between">
            <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Invite</h1>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="editInvites = false;showInvite()"></em>
        </div>

        <div class="scroll-content">
            <form class="mt-3" name="form" [formGroup]='inviteForm' novalidate>
                <label class="fs12 grey-j ls-3">Name*</label>
                <div class="row">
                    <div class="col-6 pr-1">
                        <div class="form-group input-rounded">
                            <input type="text" formControlName="firstName" tabindex="11" pattern="^[A-Za-z ]+$"
                                class="form-control pt22 pb22 fw-600" placeholder="First Name"
                                (keydown.space)="inviteForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                            <div *ngIf="submittedForm">
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('firstName').errors) && !inviteForm.get('firstName').errors?.pattern">
                                    Please enter first name
                                </div>
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('firstName').errors?.pattern)">
                                    First Name must only have alphabets .
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pl-1">
                        <div class="form-group input-rounded">
                            <input type="text" formControlName="lastName" tabindex="12" pattern="^[A-Za-z ]+$"
                                class="form-control pt22 pb22 fw-600" placeholder="Last Name"
                                (keydown.space)="inviteForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                            <div *ngIf="submittedForm">
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('lastName').errors) && !inviteForm.get('lastName').errors?.pattern">
                                    Please enter last name
                                </div>
                                <div class="fs12 text-danger"
                                    *ngIf=" (inviteForm.get('lastName').errors?.pattern)">
                                    Last Name must only have alphabets .
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3">E-Mail*</label>
                    <input type="text" formControlName="email" tabindex="13" class="form-control pt22 pb22 fw-600"
                        placeholder="Enter E-mail" (keyup)='removeTrim(inviteForm)'
                        (keydown.space)="inviteForm.get('email').value.trim().length > 0 ? '': $event.preventDefault();" [readonly]="editInvites">
                    <div class="fs12 text-danger"
                        *ngIf=" (inviteForm.get('email').errors && submittedForm) || (inviteForm.get('email').errors?.required && submittedForm)">
                        Please enter email
                    </div>
                    <span class="fs12 grey-j" *ngIf="editInvites">Note: you can't edit email</span>
                </div>
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3">Role*</label>
                    <select class="form-control input-group-text h-46px" name="role" formControlName="roleType" tabindex="14">
                        <option value="" disabled>select</option>
                        <option *ngFor="let user of userRoleList" value={{user.id}}>{{user.roleName}}</option>
                    </select>
                    <div class="fs12 text-danger"
                        *ngIf=" (inviteForm.get('roleType').errors && submittedForm) || (inviteForm.get('roleType').errors?.required && submittedForm)">
                        Please choose role type
                    </div>
                </div>

                <div class="row mt50 mb-3 px-3">
                    <div class="col-4 pr-1">
                        <button [disabled]="submitted" type="button" tabindex="15" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600"
                            (click)="editInvites = false;showInvite()" type="button">Cancel</button>
                    </div>
                    <div class="col-8 pl-1">
                        <button [disabled]="submitted" tabindex="16"
                            class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                            (click)="inviteMethod(inviteForm)">
                            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Invite to Project</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>