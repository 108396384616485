<div id="overlay" [ngClass]="{'d-block': resetPasswordToggle}" ></div>
<div #sidenav class="right-sidebar bg-white show-side-nav" [ngClass]="{'show-side-nav' : resetPasswordToggle}" >
   <!-- for workspace -->
  

      <div class="d-flex justify-content-between mb-4">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Reset Password</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="ToggleResetpassword()"></em>
      </div>


      <h2 class="fs12 grey-j ls-3">Create your New Password</h2>
      <form name="resetForm" autocomplete="off" name="forgetForm"  #resestForm="ngForm" novalidate>
       
              <div class="input-group input-rounded mb-0">
                 <input name="password" required pattern="^[a-zA-Z0-9!@#$%\^&*)(+=._-]\S*.{5,25}$" minlength="6"
                 maxlength="25"
                 [(ngModel)]="resetPassword.password" #password="ngModel"
                 [ngClass]="{ 'has-error': resestForm.submitted && password.invalid }"
                 [type]="togglePasswordOne ? 'text': 'password'" class="form-control fw-600 pt22 pb22 brtlr-8  border-right-0 "
                    placeholder="New Password">
                 <div class="input-group-append">
                    <span class="input-group-text brtlr-8 border-left-0 " (click)="passwordToggle(1)">
                       <img *ngIf="togglePasswordOne" src="./assets/images/show-pass.svg" alt="show password"
                          class="c-pointer">
                       <img *ngIf="!togglePasswordOne" src="./assets/images/pass-hide.svg" alt="show password"
                          class="c-pointer">
                    </span>
                 </div>

               </div>
               <div class="fs12 grey-j ls-3 fw-500">
                  Passwords should be at least 6 characters long.
               </div>
              <div *ngIf="password.invalid && (password.dirty || password.touched)">
               <div *ngIf="password.errors.required" class="text-danger fs12">
                  Password is required.
               </div>
               <div *ngIf="password.errors.pattern" class="fs12 text-danger">
                  Password must be a minimum of 6 characters and maximum of 25 characters in length.
               </div>
            </div>
         
              <div class="input-group input-rounded mb-0">
                 <input name="conpassword"
                 required pattern="{{resetPassword.password}}"
                 [(ngModel)]="resetPassword.conpassword" #conpassword="ngModel"
                 [ngClass]="{ 'has-error': resestForm.submitted && conpassword.invalid }"
                  [type]="togglePasswordTwo ? 'text' : 'password'" class="form-control brblr-8 fw-600 pt22 pb22  border-right-0 "
                    placeholder="Confirm Password">
                 <div class="input-group-append">
                    <span class="input-group-text brblr-8 border-left-0" (click)="passwordToggle(2)">
                       <img *ngIf="togglePasswordTwo" src="./assets/images/show-pass.svg" alt="show password"
                          class="c-pointer">
                       <img *ngIf="!togglePasswordTwo" src="./assets/images/pass-hide.svg" alt="show password"
                          class="c-pointer">
                    </span>
                 </div>
              </div>
              <div *ngIf="conpassword.invalid && (conpassword.dirty || conpassword.touched)">
               <div *ngIf="conpassword.errors.required" class="text-danger fs12">
                  Confirm password is required.
               </div>
               <div *ngIf="conpassword.errors.pattern" class="text-danger fs12">
                  Confirm password must be  same as entered above.
               </div>
              </div>
              <div class="row mt50 px-3">
                <div class="col-4 pr-1">
                   <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                   (click)="ToggleResetpassword()" >Cancel</button>
       
                </div>
                <div class="col-8 pl-1">
                   <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button" (click)="resestForm.form.valid && onSubmit(resestForm)" [disabled]="!resestForm.valid && !submitted">
                     <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Reset Password</button>
       
                </div>
             </div>
     </form>
      

  

  
</div>