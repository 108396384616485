import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { WorkspaceServiceService } from '../services/workspace-service.service';

@Component({
    selector: 'app-view-picture',
    templateUrl: './view-picture.component.html',
})
export class ViewPictureComponent implements OnInit {

    public datas: any;

    public photoUrl: any;

    public noDataFound = false;

    public constructor(
        private workspace: WorkspaceServiceService,
        private activeRoute: ActivatedRoute,
        private toastr: ToastrService,
        private router: Router,
    ) { }

    public ngOnInit(): void {
        this.activeRoute.queryParams.subscribe((params) => {
            this.datas = params;
            this.datas.albumId ? this.getAlbums() : this.getPicture();
        });
    }

    public getPicture(): any {
        this.workspace.getPicture(this.datas).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    this.photoUrl = res.entity.shareUrl[0];
                } else {
                    this.handleNoDataFound();
                }
            },
            (err): any => {
                this.noDataFound = true;
            },
        );
    }

    private handleNoDataFound(): void {
        this.toastr.error('Link got Expired');
        this.router.navigate(['/login']);
        this.noDataFound = true;
    }

    public base64ToArrayBuffer(base64): any {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public getAlbums(): void {
        const payload = {
            userId: this.datas.userId,
            workSpaceName: this.datas.workSpaceName,
            albumId: this.datas.albumId,
            urlList: [],
        };
        this.workspace.albumphotospdfApi(payload).subscribe(
            (res): any => {
                if (res && res.entity && res.entity.pdfData) {
                    const blob = new Blob([this.base64ToArrayBuffer(res.entity.pdfData)], { type: 'application/pdf' });
                    saveAs(blob, `${this.datas.albumId}.pdf`);
                } else {
                    this.handleError();
                }
            },
            (err): any => {
                this.handleError();
            },
        );
    }

    private handleError(): void {
        this.toastr.error('Something Went Wrong');
        this.noDataFound = true;
    }
}
