<section class="main-container">
  <div class="sub-container">
    <div class="sub-content-container pt15">
      <div class="content-card todo-list">
        <div class="card-header bg-white border-0">
          <div class="d-flex align-items-center mt20">
            <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Your Invites</h1>
          </div>
        </div>
        <div class="card-body notification-scroll h-80vh overflow-auto">
          <div class="invite-list">
            <div class="invite-list-item py-4" *ngFor="let pending of workspacePendingList; let i = index">
              <div class="row mx-0">
                <div class="col-md-2">
                  <div class="invite-circle text-uppercase rounded-circle d-flex align-items-center justify-content-center" *ngIf="pending.inviteUsertype !== '2'">
                    {{getFirstAndLastLetter(pending.projectName)}}
                  </div>
                  <div class="invite-circle text-uppercase rounded-circle d-flex align-items-center justify-content-center" *ngIf="pending.inviteUsertype === '2'">
                    {{getFirstAndLastLetter(pending.workSpace)}}
                  </div>
                </div>
                <div class="col-md-10">
                  <h2 class="fs20 mb-3 mt-1">{{pending.projectName}}</h2>

                  <h3 class="fs14 grey-l">Role</h3>
                  <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '2'">Workspace Admin</p>
                  <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '3'">Project Manager</p>
                  <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '4'">Project Member</p>

                  <h3 class="fs14 grey-l">Invited by</h3>
                  <p class="grey-m fs16">{{pending.username}}</p>

                  <div class="mt-3">
                    <p type="button" class="fs16 grey-l" *ngIf="pending.accepted">
                        <img src="./assets/images/check.svg" class="tick-img mr-2" alt="check" />
                        Invite Accepted
                    </p>
                    <p type="button" class="fs16 grey-l" *ngIf="pending.rejected">
                        <img src="./assets/images/cross-red.svg" class="cross-img mr-2" alt="check" />
                        Invite Rejected
                    </p>

                    <div class="text-nowrap" *ngIf="pending.acceptreject">
                      <button (click)="rejectInvite(pending)" class="btn btn-red-h br-25 px-md-4 px-3 py-1 mr-3 d-inlin-flex align-items-center justify-content-center">
                        <img src="./assets/images/cross-red.svg" class="cross-img mr-2" alt="cross" />
                        Reject
                      </button>
                      <button (click)="acceptInvite(pending)" class="btn btn-blue-outline br-25 px-md-4 px-3 py-1 d-inlin-flex align-items-center justify-content-center">
                        <img src="./assets/images/check.svg" class="tick-img mr-2" alt="check" />
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="workspacePendingList && workspacePendingList.length == 0"> There is no pending invites are available </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</section>

<app-your-invites-sidemenu [toggleSidemenu]="filterSidemenu" (closesidemenu)="closeSidemenus()"></app-your-invites-sidemenu>