import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SupportService } from '../services/support.service';
import { TaglibraryserviceService } from '../services/taglibraryservice.service';
import { ApiService } from '../services/api_base/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-tag-library',
    templateUrl: './tag-library.component.html',
    styles: [`.input-with-color {
        position: relative;
        display: inline-block;
        width: 100%;
      }
      
      .input-with-color input[type="color"] {
        position: absolute;
        top: 50%;
        right: 12px;
        height: 30px;
        width: 30px;
        background: none;
        border: none;
        transform: translateY(-50%);
      }`]
})
export class TagLibraryComponent implements OnInit {

    public submitted = false;

    public splitColor: any;

    public config = {
        ignoreBackdropClick: true,
    };

    public modalRef: BsModalRef;

    public validation = false;

    public viewTag: boolean;

    public getsubTag: any;

    public color1 = '#666666';

    public Tags: any;

    public TagLibrary: any = {};

    public value = '';

    public Id = '';

    public items: string[] = [
        'A - Z',
        'Z - A'
    ];

    public showTagMain = true;

    public errorMessage = '';

    public sortType = 'asc';

    public userSearch = '';

    public userType = '';

    public queryData: any;

    public isCreateNewTag = false;

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    public constructor(
        private support: SupportService,
        private api: TaglibraryserviceService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private apis: ApiService,
        private activeRoute: ActivatedRoute,
    ) {
        // constructor
        this.activeRoute.queryParams.subscribe((params): any => {
            this.queryData = params;
        });
    }


    public ngOnInit(): void {

        this.userType = sessionStorage.getItem('usertype');

        this.TagLibrary = {
            title: '',
            purpose: '',
            color: '',
            isGlobalTag: false
        };
        this.getTags();
    }

    public showTag(): void {
        this.showTagMain = !this.showTagMain;
    }

    public getSortlist(value): void {
        this.sortType = (value === 'A - Z') ? 'asc' : 'desc';
        this.getTags();
    }

    // public getTags(): void {
    //     this.errorMessage = '';
    //     this.api.gettags(this.userSearch, this.sortType).subscribe(
    //         (res): any => {
    //             if (res && res.entity) {
    //                 const tags = res.entity;
    //                 const obj = tags.reduce((acc, c): any => {
    //                     const letter = c.title[0].toUpperCase();
    //                     acc[letter] = (acc[letter] || []).concat(c);
    //                     return acc;
    //                 }, {});
    //                 // `map` over the object entries to return an array of objects
    //                 this.Tags = Object.entries(obj).map(([letter, names]): any => ({ letter, names }));
                    
    //             } else {
    //                 this.errorMessage = 'It seems no tags are available';
    //             }
    //         },
    //     );
    // }

    public getTags(): void {
        this.errorMessage = '';
        
        this.api.gettags(this.userSearch, this.sortType).subscribe(
          (res): void => {
            if (res && res.entity) {
              const tags = res.entity;
              const obj = this.groupTagsByLetter(tags);
              this.Tags = this.convertObjectToArray(obj);
            } else {
              this.errorMessage = 'It seems no tags are available';
            }
          },
        );
      }
      
    //   private groupTagsByLetter(tags: any[]): any {
    //     return tags.reduce((acc, tag): any => {
    //       const letter = tag.title[0].toUpperCase();
    //       acc[letter] = (acc[letter] || []).concat(tag);
    //       return acc;
    //     }, {});
    //   }

    private groupTagsByLetter(tags: any[]): any {
        return tags.reduce((acc, tag): any => {
            if (tag && tag.title && tag.title.length > 0) {
                const letter = tag.title[0].toUpperCase();
                acc[letter] = (acc[letter] || []).concat(tag);
            }
            return acc;
        }, {});
    }
      
    private convertObjectToArray(obj: any): any[] {
    return Object.entries(obj).map(([letter, names]): any => ({ letter, names }));
    }

    public editTagById(val): void {
        // tslint:disable-next-line: max-line-length
        this.Id = val.id;
        this.isCreateNewTag = false;
        this.color1 = `#${val.color}`;
        if (this.color1.startsWith("##")) {
            this.color1 = "#" + this.color1.slice(2);
        }
        this.TagLibrary = Object.assign({}, val);
        this.viewTag = true;
    }

    public openModal(val): any {
        if (val && +val.postcount > 0) {
            this.toastr.error('Delete not complete because tag have some post');
            return;
        }
        this.value = val.id;
        this.modalRef = this.modalService.show(
            this.exampleModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public DeleteTag(): void {
        // tslint:disable-next-line: max-line-length
        const queryParams = `pixlyAlbum/removetagtype?tagTypeId=${this.value}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.api.TagDelete(queryParams).subscribe((res): any => {
            if (res) {
                this.toastr.success(res.status.msg);
                this.getTags();
                this.modalRef.hide();
            }
        }, (err) => {
            this.apis.handleError(err);
        });
    }


    public viewAddEditTag(): void {
        this.toggleViewTag(true);
        this.Id = '';
        this.isCreateNewTag = true;
        this.TagLibrary.global = false
    }
    
    public cancleCTA() {
        this.toggleViewTag(false);
    }
    
    private toggleViewTag(show: boolean): void {
        this.validation = false;
        this.viewTag = show;
    }

    public ResetForm(Tagform: any): void {
        Tagform.form.reset();
        this.TagLibrary.isGlobalTag = false;
        this.color1 = '#666666';
    }

    // public onSubmit(Tagform: any): void {
    //     this.validation = true;
    //     if (!Tagform.form.valid || !this.color1.trim()) {
    //         return;
    //     }

    //     this.splitColor = this.color1.substring(1);
    //     if (this.splitColor === '') {
    //         this.toastr.error('Please choose a color');
    //         return;
    //     }
    //     this.submitted = true;
    //     const params = {
    //         userId: localStorage.getItem('userId'),
    //         workSpaceName: sessionStorage.getItem('workspacename'),
    //         tagName: this.TagLibrary.title,
    //         purpose: this.TagLibrary.purpose,
    //         color: this.splitColor,
    //     };
    //     if (this.Id === '') {
    //         if (this.TagLibrary.isGlobalTag) {
    //             delete params.workSpaceName
    //             delete params.userId
    //             this.toastr.clear();
    //             const tenantId = localStorage.getItem('tenantId');
    //             const userId = localStorage.getItem('userId');
    //             this.api.addTagsForTenant(userId, tenantId, params).subscribe(
    //                 (res): any => {
    //                     this.submitted = false;
    //                     if (res && res.tagId) {
    //                         this.getTags();
    //                         Tagform.reset();
    //                         this.toastr.success('Tag added to workspace successfully');
    //                     } else {
    //                         this.toastr.error(res.status.msg);
    //                     }
    //                 },
    //                 (err): any => {
    //                     this.submitted = false;
    //                     this.apis.handleError(err);
    //                 },
    //             );
    //         } else {
    //             this.api.CreateTag(params).subscribe(
    //                 (res): void => {
    //                     if (res && res.status && res.status.status === 200) {
    //                         Tagform.form.reset();
    //                         this.submitted = false;
    //                         this.toastr.success(res.status.msg);
    //                         this.viewTag = false;
    //                         this.getTags();
    //                     } else if (res && res.status && res.status.status === 204) {
    //                         this.toastr.error(res.status.msg);
    //                     }
    //                 },
    //                 (err): void => {
    //                     this.submitted = false;
    //                     this.apis.handleError(err);
    //                 },
    //             );
    //         }
    //     } else {
    //         const params = {
    //             tagTypeId: this.Id,
    //             userId: localStorage.getItem('userId'),
    //             workSpaceName: sessionStorage.getItem('workspacename'),
    //             title: this.TagLibrary.title,
    //             purpose: this.TagLibrary.purpose,
    //             color: this.splitColor,
    //             isGlobalTag: this.TagLibrary.isGlobalTag
    //         };
    //         this.api.EditTag(params).subscribe(
    //             (res): void => {
    //                 if (res && res.status && res.status.status === 200) {
    //                     Tagform.form.reset();
    //                     this.submitted = false;
    //                     this.toastr.success(res.status.msg);
    //                     this.viewTag = false;
    //                     this.Id = '';
    //                     this.getTags();
    //                 } else if (res && res.status && res.status.status === 204) {
    //                     this.toastr.error(res.status.msg);
    //                 }
    //             },
    //             (err): void => {
    //                 this.submitted = false;
    //                 this.apis.handleError(err);
    //             },
    //         );
    //     }
    // }

    public onSubmit(Tagform: any): void {
        this.validation = true;
    
        if (!Tagform.form.valid || !this.color1.trim()) {
            return;
        }
    
        this.splitColor = this.color1.substring(1);
    
        if (this.splitColor === '') {
            this.toastr.error('Please choose a color');
            return;
        }
    
        this.submitted = true;
        const userId = localStorage.getItem('userId');
        const workSpaceName = sessionStorage.getItem('workspacename');
        const isGlobalTag = this.TagLibrary.isGlobalTag;
        let params: any = {
            userId,
            workSpaceName,
            title: this.TagLibrary.title,
            purpose: this.TagLibrary.purpose,
            color: this.splitColor,
        };
    
        if (this.Id === '') {
            if (isGlobalTag) {
                delete params.workSpaceName;
                delete params.userId;
                delete params.title,
                this.toastr.clear();
                params.tagName = this.TagLibrary.title
                const tenantId = localStorage.getItem('tenantId');
                this.api.addTagsForTenant(userId, tenantId, params).subscribe(
                    (res): any => {
                        this.handleTagSubmissionResponse(res, Tagform)
                    },
                    (err): any => this.handleTagSubmissionError(err)
                );
            } else {
                this.api.CreateTag(params).subscribe(
                    (res): void => this.handleTagSubmissionResponse(res, Tagform),
                    (err): void => this.handleTagSubmissionError(err)
                );
            }
        } else {
            params.tagTypeId = this.Id;
            this.api.EditTag(params).subscribe(
                (res): void => {
                    this.handleTagSubmissionResponse(res, Tagform)
                }, (err): void => this.handleTagSubmissionError(err)
            );
        }
    }
    
    private handleTagSubmissionResponse(response: any, Tagform: any): void {
        this.submitted = false;
        if (response) {
            if (response && response.status && response.status.status === 200) {
                if (this.TagLibrary.isGlobalTag) {
                    this.toastr.success('Tag has been updated and added to workspace');
                } else {
                    this.toastr.success(response.status.msg);
                }
            } else if (response && response.tagId) {
                this.toastr.success('Tag added to workspace');
            } else if (response && response.status && response.status.status === 204) {
                this.toastr.error(response.status.msg);
            }
            this.viewTag = false;
            setTimeout(() => {
                Tagform.form.reset();
            }, 1000);
            this.Id = ''; // Reset the tag type ID
            this.getTags();
        }
    }
    
    private handleTagSubmissionError(error: any): void {
        this.submitted = false;
        this.apis.handleError(error);
    }

    updateColor () {
        if (this.color1.match(/^#([0-9a-f]{6})$/)) {
            // Update color picker value
          } else if (this.color1.match(/^#([0-9a-f]{3})$/)) {
            // Convert and update color picker value
          }
    }

    
}
