import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';


import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlbumserviceService } from '../services/albumservice.service';
import { TabsetConfig } from 'ngx-bootstrap/tabs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export function getTabsetConfig(): TabsetConfig {
    return Object.assign(new TabsetConfig(), { type: 'pills', isKeysAllowed: true });
}

@Component({
    selector: 'app-feed-side-filter',
    templateUrl: './feed-side-filter.component.html',
    providers: [{ provide: TabsetConfig, useFactory: getTabsetConfig }]
})
export class FeedSideFilterComponent implements OnInit {

    @Input('toggleSidemenu') public toggleSidemenu: boolean = false;

    @Input('globalSearchStr') public globalSearchStr = '';

    @Output('closesidemenu') public closesidemenu = new EventEmitter();

    @Output('filterSubmite') public filterSubmite = new EventEmitter();

    public searchDelay = new Subject<string>();

    public datePickerConfig: Partial<BsDatepickerConfig>;

    public submitted = false;

    public filterBilling: FormGroup;

    public placeholderlabel = 'Search by tag name';

    public inlinesearch = '';

    public currentTab = 1;

    public pageNo = 1;

    public pageRecord = 8;

    public tags: any[] = [];

    public peoples = [];

    public albums: any[] = [];

    public teamName = '';

    public tagSelector = '.tags-scroll';

    public peopleSelector = '.people-scroll';

    public albumSelector = '.album-scroll';

    public tabValues = {
        tagValues: [],
        peopleValues: [],
        albumValues: [],
    }

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public loading = false;

    public maxDate = new Date();

    public constructor(private fb: FormBuilder, private api: AlbumserviceService) {
        this.searchDelay.pipe(debounceTime(800), distinctUntilChanged()).subscribe((value): void => {
            this.pageNo = 1;
            this.getTabsList();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.toggleSidemenu = changes.toggleSidemenu?.currentValue;

        if (this.toggleSidemenu) {
            this.getTabsList();
            this.getTeam();
        }

    }

    public ngOnInit(): void {
        this.createForm();
    }

    public getTeam(): any {
        this.api.getTeam().subscribe((res): any => {
            if (res && res.entity && res.entity.teamName) {
                this.teamName = res.entity.teamName;
            }
        })
    }

    public createForm(): void {
        this.filterBilling = this.fb.group({
            fromDate: [null],
            toDate: [null],
            actions: [null],
        });
    }

    public closeFilter(): void {
        this.submitted = false;
        this.filterBilling.reset();
        this.toggleSidemenu = false;
        this.closesidemenu.emit(this.toggleSidemenu);
    }

    public clearFilterFromParent(): void {
        this.submitted = false;
        this.filterBilling.reset();
        this.tabValues = {
            albumValues: [],
            peopleValues: [],
            tagValues: [],
        };
        this.getTabsList();
        const filterPayload = { ...this.filterBilling.value };
        filterPayload.filtertabvalues = {
            albumValues: [],
            peopleValues: [],
            tagValues: [],
        };
        this.filterSubmite.emit(filterPayload);
        this.toggleSidemenu = false;
        this.closesidemenu.emit(this.toggleSidemenu);
    }

    public filterSubmit(): void {
        this.submitted = true;
        const filterPayload = { ...this.filterBilling.value };
        filterPayload.filtertabvalues = this.tabValues;
        this.filterSubmite.emit(filterPayload);
        this.closesidemenu.emit(false);
    }

    public clearUserSearchData(): void {
        this.inlinesearch = '';
        this.pageNo = 1;
        this.getTabsList();
    }

    public enterMethod(): void {
        this.pageNo = 1;
        this.getTabsList();
    }

    public getTabsList(): void {
        const payload = {
            search: this.globalSearchStr,
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: [sessionStorage.getItem('projectId')],
            pagenumber: this.pageNo,
            pagerecord: this.pageRecord,
            type: this.currentTab,
            inlineSearch: this.inlinesearch,
        };
        this.loading = true;
        this.api.geTabsListApi(payload).subscribe(
            (res): void => {
                if (this.currentTab === 1) {
                    this.placeholderlabel = 'Search by tag name';
                    if (res && res.status && res.status.status === 200) {
                        if (this.pageNo === 1) {
                            res.entity.tagList.forEach((element): void => {
                                const findSelected = this.tabValues.tagValues.findIndex((x): any => x === element.tagId);
                                // eslint-disable-next-line no-param-reassign
                                element.selected = (findSelected !== -1);
                            });

                            this.tags = res.entity.tagList;
                        } else {
                            res.entity.tagList.forEach((element): void => {
                                const findSelected = this.tabValues.tagValues.findIndex((x): any => x === element.tagId);
                                // eslint-disable-next-line no-param-reassign
                                element.selected = (findSelected !== -1);
                                this.tags.push(element);
                            });
                        }
                    }
                } else if (this.currentTab === 2) {
                    this.placeholderlabel = 'Search by people name';
                    if (this.pageNo === 1) {
                        res.entity.userList.forEach((element): void => {
                            const findSelected = this.tabValues.peopleValues.findIndex((x): any => x === element.id);
                            // eslint-disable-next-line no-param-reassign
                            element.selected = (findSelected !== -1);
                        });
                        this.peoples = res.entity.userList;
                    } else {
                        res.entity.userList.forEach((element): void => {
                            const findSelected = this.tabValues.peopleValues.findIndex((x): any => x === element.id);
                            // eslint-disable-next-line no-param-reassign
                            element.selected = (findSelected !== -1);
                            this.peoples.push(element);
                        });
                    }

                } else if (this.currentTab === 3) {
                    this.tagTask(res.entity.albumList);
                }
                this.loading = false;
            }, (err): any => {
                this.loading = false;
            },
        );
    }

    public tagTask(albumList): void {
        this.placeholderlabel = 'Search by album name';
        if (this.pageNo === 1) {
            albumList.forEach((element): void => {
                const findSelected = this.tabValues.albumValues.findIndex((x): any => x === element.albumId);
                // eslint-disable-next-line no-param-reassign
                element.selected = (findSelected !== -1);
            });
            this.albums = albumList;
        } else {
            albumList.forEach((element): void => {
                const findSelected = this.tabValues.albumValues.findIndex((x): any => x === element.albumId);
                // eslint-disable-next-line no-param-reassign
                element.selected = (findSelected !== -1);
                this.albums.push(element);
            });
        }
    }

    public onTabSelect(tabdata): void {
        this.currentTab = tabdata;
        this.pageNo = 1;
        this.getTabsList();
    }

    public toggleCheckboxFilters(event, id, type): void {
        const { checked } = event.target;
        if (checked) {
            if (type === 'tag') {
                this.tabValues.tagValues.push(id);
            } else if (type === 'people') {
                this.tabValues.peopleValues.push(id);
            } else if (type === 'album') {
                this.tabValues.albumValues.push(id);
            }
        } else if (!checked) {
            if (type === 'tag') {
                this.tabValues.tagValues = this.tabValues.tagValues.filter((x): any => x !== id);
            } else if (type === 'people') {
                this.tabValues.peopleValues = this.tabValues.peopleValues.filter((x): any => x !== id);
            } else if (type === 'album') {
                this.tabValues.albumValues = this.tabValues.albumValues.filter((x): any => x !== id);
            }
        }
    }

    public onScroll(): any {
        this.pageNo += 1;
        this.getTabsList();
    }

}
