<div id="overlay" [ngClass]="{'d-block': showToggle}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showToggle}">
   <div>
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Send OTP to your</h1>
         <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="cancel()"></em>
      </div>
   </div>

   <form>
    <div class="get-pdf mb-3 mt-4">
        <input class="form-check-input d-none" type="radio" id="inlineRadio3" name="otpType"
            [(ngModel)]="otpType" value="1">
        <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
            for="inlineRadio3" (click)="otpType = '1'">
            <div class="mr-2  fs12">
                <em class="far fa-circle square-uncheck grey-i"></em>
                <em class="fas fa-circle square-checked blue-a"></em>
            </div>
            <div>
                <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">Phone Number</h3>
            </div>
        </label>
    </div>

    <div class="get-pdf my-3">
        <input class="form-check-input d-none" type="radio" id="inlineRadio4" name="otpType"
            [(ngModel)]="otpType" value="2">
        <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
            for="inlineRadio4" (click)="otpType = '2'">
            <div class="mr-2  fs12">
                <em class="far fa-circle square-uncheck grey-i"></em>
                <em class="fas fa-circle square-checked blue-a"></em>
            </div>
            <div>
                <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">E-Mail</h3>
            </div>
        </label>
    </div>

    <div class="row mt50 px-3 ">
        <div class="col-4 pr-1">
           <button [disabled]="submitted" class="btn btn-grey-f text-black br25 pt7 pb7 btn-block fs13 fw-500" type="button"
           (click)="cancel()">Cancel</button>
        </div>

        <div class="col-8 pl-1">
            <button [disabled]="submitted" class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-500 buttonload" type="button"
            (click)="sendotp()">
            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Send OTP</button>
         </div>       
     </div>
   </form>
   
</div>