/* eslint-disable default-case */
import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment.prod';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Location } from '@angular/common';
import { AlbumserviceService } from '../services/albumservice.service';
import { SupportService } from '../services/support.service';
import { ApiService } from '../services/api_base/api.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { GeolocationService } from '../services/geolocation.service';

@Component({
    selector: 'app-album-subpage',
    templateUrl: './album-subpage.component.html',
    styles: [`
    .check-img{
        position: absolute;
        top: 7px;
        left: 22px;
      }
      `]
})
export class AlbumSubpageComponent implements OnInit {
    public createFolder = false;

    public validation = false;

    public GetPdf = false;

    public AssignTo = false;

    public AlbumSettings = false;

    public pdfSubmitted = false;

    public pdfModalSelect = false;

    public selectedPDFType = "Photo summary";

    public viewMode = 'pending';

    public viewMode1 = 'tagFilter';

    public viewMode2 = 'Comments';

    public modalRef: BsModalRef;

    public modalRef1: BsModalRef;

    public datePickerConfig: Partial<BsDatepickerConfig>;

    public colorTheme = 'theme-default';

    public lat = 28.644800;

    public lng = 77.216721;

    public tagShow: boolean;

    public getNext = true;

    public albumEntity: any = {};

    public files: NgxFileDropEntry[] = [];

    public selectedImage = [];

    public request: any;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public lattitude = [];

    public longitude = [];

    public direction = [];

    public mapView = false;

    public key = [];

    public errorMessage = '';

    public DateText = 'Most Recent';

    public mapPhotoList = [];

    public selectedPhoto = '';

    public origin = { lat: 0, lng: 0 };

    public destination = { lat: 0, lng: 0 };

    public membershipId = '';

    public index = 0;

    public config = {
        ignoreBackdropClick: true,
    };

    public choosedImage = [];

    public totalFiles: any;

    public albumId: any;

    public uploadPercentage = 0;

    public i = 0;

    public album: any = {};

    public photoList = [];

    public submitted = false;

    public orderBy = 'desc';

    public sortType = 'day';

    public datas: any;

    public search = '';

    public peopleList = [];

    public renderOptions = {
        suppressMarkers: true,
    };

    public showPeople = false;

    public userType = sessionStorage.getItem('usertype');

    public getRequests = [];

    public choosedImageIdList = [];

    public userId = localStorage.getItem('userId');

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    @ViewChild('deletealbumModal') public deletealbumModal: TemplateRef<any>;

    @ViewChild('photodeleteModal') public photodeleteModal: TemplateRef<any>;

    @ViewChild('leaveModal') public leaveModal: TemplateRef<any>;

    @ViewChild('individualphotodeleteModal') public individualphotodeleteModal: TemplateRef<any>;

    @ViewChild('checkbox') public photoSelect;

    public albumVisibility = 'PUBLIC';

    public showCreate = false;

    public addMembers = false;

    public userSearch = '';

    public showInviteBar: boolean;

    public showInvite1: any;

    public PendingMembers = [];

    public getMembers = [];

    public membersarray = [];

    public tempGetMembers = [];

    public memberbutton = false;

    public markerOptions = {
        origin: {
            icon: './assets/images/blue-dot.png',
            draggable: false,
        },
        destination: {
            icon: './assets/images/red_dot.svg',
            draggable: false,
        },
    };

    public Invitesubmitted = false;

    public inviteForm: FormGroup;

    public userRoleList = [];

    public selectedAlbumId = '';

    public list = [];

    public list2 = [];

    public currentPhoto = [];

    public currentInvite = false;

    public currentAlbumId = '';

    public workspacePhotoCount = 0;

    public hidedelete: any;

    public compressedImg: File;

    public photoName: any[] = [];
    
    public photoAlbumId: any;

    public photoExtentions = ['jpg', 'png', 'jpeg'];

    public videoExtentions = ['mp4'];

    mediaFormat = '';

    selectedImages = [];

    latt;

    long;

    public constructor(private support: SupportService, private modalService: BsModalService, private router: Router,
        private activeRoute: ActivatedRoute, private albumService: AlbumserviceService, private toastr: ToastrService,
        private api: ApiService, private workspaceService: WorkspaceServiceService, private inviteBuilder: FormBuilder,
        private loadingBar: LoadingBarService, private location: Location, private geolocationService: GeolocationService) {
        this.datePickerConfig = { dateInputFormat: 'DD.MM.YYYY', containerClass: this.colorTheme };
        this.createBuilderForm();
        this.activeRoute.queryParams.subscribe((params): any => {
            this.datas = params;
        });
        this.getLocation();
    }

    public ngOnInit(): void {
        this.album = {
            albumName: '',
            description: '',

        };
        this.albumId = this.datas.albumId;
        this.lat = +this.datas.lat;
        this.lng = +this.datas.lng;

        if (this.albumId) {
            this.getAllAlbum(this.albumId);
            this.getAllPhotosByAlbum();
            if (!this.lat || !this.lng) {
                this.getCurrentLocation();
            }
        }
        this.userType = sessionStorage.getItem('usertype');
    }

    public create(): void {
        this.showCreate = !this.showCreate;
        if (this.showCreate) {
            this.albumVisibility = 'PRIVATE';
            this.addMembers = true;
            this.getMemberList();
        }
    }

    public searchFilter(): any {
        const searchTerm = this.userSearch.trim().toLowerCase();

        this.tempGetMembers = this.userSearch && searchTerm
            ? this.getMembers.filter((x): any => x.user.email.toLowerCase().includes(searchTerm))
            : this.getMembers;
    }


    public comeFromAlbumSetting(): any {
        this.showPeople = true;
        this.AlbumSettings = false;
        this.mapView = false;
        this.getAllMemberByAlbum();
    }

    public viewPeople(): any {
        this.showPeople = !this.showPeople;
        if (this.showPeople) {
            this.mapView = false;
            this.getAllMemberByAlbum();
        }
    }

    public deleteAlbum(albumId: any): void {
      if (albumId) {
        this.selectedAlbumId = albumId;
        this.modalRef = this.modalService.show(
          this.deletealbumModal,
          { ...this.config, class: 'modal-sm popup-center' },
        );
      }
    }

    public yesDeleteAlbum(): any {
        if (this.selectedAlbumId) {
            this.submitted = true;
            this.albumService.deleteAlbum(this.selectedAlbumId).subscribe(
                (res): any => {
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success(res.status.msg);
                        this.modalRef.hide();
                        this.showAlbumSettings();
                        this.router.navigate(['/project/albums'], { queryParams: this.api.getSessionData() });
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                }, (err): any => {
                    this.commonErrorHandler(err);
                },
            );
        }
    }

    public commonErrorHandler(err): void {
        this.submitted = false;
        this.modalRef.hide();
        this.api.handleError(err);
    }

    public getMemberList(): any {
        const data = {
            userId: localStorage.getItem('userId'),
            teamId: sessionStorage.getItem('projectId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            pagenumber: 0,
            pagerecord: 0,
        };
        this.membersarray = [];
        this.workspaceService.getAllPeopleListByProjectId(data).subscribe((res): any => {
            if (res && res.status.status === 200) {
                if (res.entity.length > 0) {
                    this.getMembers = res.entity;
                    if (this.peopleList && this.peopleList.length > 0) {
                        for (let index = 0; index < this.peopleList.length; index++) {
                            for (let i = 0; i < this.getMembers.length; i++) {
                                if (this.peopleList[index].user.id === this.getMembers[i].user.id) {
                                    this.getMembers[i].checked = true;
                                    this.membersarray.push(this.getMembers[i].user.id);
                                    break;
                                }
                            }
                            if ((index + 1) === this.peopleList.length) {
                                this.tempGetMembers = this.getMembers;
                            }
                        }
                    } else {
                        this.tempGetMembers = this.getMembers;
                    }
                }
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }


    public membersAdd(): void {
        if (this.albumVisibility === 'PRIVATE') {
            if (this.membersarray.length === 0 && this.PendingMembers.length === 0) {
                this.toastr.error('Please choose Member for Private Album');
                return;
            }
        }

        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            albumName: this.albumEntity.albumName,
            description: this.albumEntity.description ? this.albumEntity.description.text : '',
            lattitude: this.albumEntity.lattitude,
            longitude: this.albumEntity.longitude,
            albumMembers: this.albumEntity.albumVisibility === 'PUBLIC' ? [] : this.membersarray,
            albumVisibility: this.albumEntity.albumVisibility,
            albumId: this.albumEntity.albumId,
        };
        this.toastr.clear();
        this.albumService.UpdateAlbum(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res) {
                    if (res.status && res.entity && res.status.status === 200) {
                        this.toastr.success(res.status.msg);
                        for (let index = 0; index < this.PendingMembers.length; index++) {
                            const element = this.PendingMembers[index];
                            const Data = {
                                usertype: 1,
                                userId: localStorage.getItem('userId'),
                                workSpaceName: sessionStorage.getItem('workspacename'),
                                teamId: sessionStorage.getItem('projectId'),
                                albumidList: [res.entity.albumId],
                                email: element.email,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                roleType: element.roleType,
                                companyName: element.companyName,
                            };
                            this.workspaceService.projectInvite(Data, 'pixlyProfile/inviteAsFullMember').subscribe((albumres): any => { });
                            if ((index + 1) === this.PendingMembers.length) {
                                this.PendingMembers = [];
                                this.tempGetMembers = [];
                                this.getMembers = [];
                            }
                        }
                        this.showCreate = false;
                        this.membersarray = [];
                        this.showInviteBar = false;
                        this.getAllMemberByAlbum();
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    public createBuilderForm(): any {
        this.inviteForm = this.inviteBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            roleType: ['4', Validators.required],
            companyName: [''],
            email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
        });
    }

    public ViewInvite(): void {
        this.showInviteBar = !this.showInviteBar;
        if (this.showCreate) {
            this.inviteForm.get('email').setValue(this.userSearch);
        }
        if (this.userRoleList.length === 0 && this.showInviteBar) {
            this.workspaceService.getUserType().subscribe((res): any => {
                if (res && res.entity) {
                    for (let index = 0; index < res.entity.length; index++) {
                        if (sessionStorage.getItem('projectId')) {
                            if (res.entity[index].id === 4) {
                                this.userRoleList.push(res.entity[index]);
                            }
                        }
                    }
                } else {
                    this.userRoleList = [];
                }
            }, (err): any => {
                this.api.handleError(err);
            });
        }
    }

    public inviteMethod(inviteForm): void {
        if (inviteForm && inviteForm.status === 'VALID') {
            const email = inviteForm.value.email.toLowerCase();
            const data = this.PendingMembers.filter((x): any => x.email.toLowerCase().includes(email));
            const data1 = this.getMembers.filter((x): any => x.user.email.toLowerCase().includes(email));

            if (data.length > 0 || data1.length > 0) {
                this.Invitesubmitted = false;
                this.toastr.clear();
                this.toastr.error('The email entered already exists, Please try with different email !');
            } else {
                this.tempGetMembers = this.getMembers;
                this.userSearch = '';
                this.PendingMembers.push(inviteForm.value);
                this.showInviteBar = false;
                this.Invitesubmitted = false;
                this.createBuilderForm();
            }
        }
    }

    public selectedMembers(val, id, i): void {
        const { checked } = val.target; // stored checked value true or false
        if (checked) {
            if (!this.membersarray.includes(id)) {
                this.membersarray.push(id);
            }
        } else {
            const index = this.membersarray.findIndex((list): any => list === id); // Find the index of stored id
            this.membersarray.splice(index, 1);
        }
        this.getMembers[i].checked = checked;
        this.tempGetMembers[i].checked = checked;
    }

    public albumChoose(photo, type): any {
        this.photoAlbumId = photo.albumId;
        const imageData = {
            albumId: photo.photoId,
            photoUrl: this.cloudFrontURL + photo.media.awskey,
            key: photo.media.awskey,
            authorId: photo.author.id
        };

        if (this.choosedImage && this.choosedImage.length > 0) {
            if (type === true) {
                const inarry = this.choosedImage.findIndex((g): any => g.photoId === photo.photoId);
                if (inarry === -1) {
                    this.imagePushToList(imageData);
                }
            } else if (type === false) {
                this.imageSpliceFromList(imageData);
            }
        } else {
            this.choosedImage.push(imageData);
            this.hidedelete = this.choosedImage.findIndex((g): any => g.authorId !== this.userId);
        }
    }

    public isImageInChoosedImage(photo): boolean {
        // Check if the photo is in this.choosedImage based on albumId
        return this.choosedImage.some((image) => image.albumId === photo.photoId);
    }

    public imageSpliceFromList(photo): any {
      const index = this.choosedImage.findIndex(image => image.albumId === photo.albumId);
      if (index > -1) {
        this.choosedImage.splice(index, 1);
        this.hidedelete = this.choosedImage.findIndex(g => g.authorId !== this.userId);
      }
    }

    public imagePushToList(id): any {
        this.choosedImage.push(id);
        this.hidedelete = this.choosedImage.findIndex((g): any => g.authorId !== this.userId);
    }

    public downloadImage(): any {
        this.submitted = true;
        this.loadingBar.start();
        if (this.choosedImage.length) {
            this.downloadImages(this.choosedImage);
        } else {
            this.pdfDownload(this.getImageUrlFromList());
        }
    }
    
    // private downloadSingleImage(): void {
    //     const S3Client = this.albumService.getS3Credential();
    //     const that = this;
    //     S3Client.getObject({ Bucket: environment.kIMAGEBUCKET, Key: this.choosedImage[0].key }, (error, data): any => {
    //         if (error != null) {
    //             console.log(`Failed to retrieve an object: ${error}`);
    //         } else {
    //             const ContentType = 'ContentType';
    //             const link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(new Blob([new Uint8Array(data.Body)], { type: data[ContentType] }));
    //             link.download = that.choosedImage[0].key;
    //             link.click();
    
    //             that.choosedImage = [];
    //             that.submitted = false;
    //             that.loadingBar.complete();
    //             that.uncheckElements();
    //         }
    //     });
    // }

    public downloadImages(images): void {
        this.submitted = true;
        this.loadingBar.start();
      
        const S3Client = this.albumService.getS3Credential();
        const that = this;
        const downloadQueue = Array.isArray(images) ? [...images] : [images]; // Create a copy of the images array
      
        // Function to process and download images sequentially
        function processNextImage() {
          if (downloadQueue.length > 0) {
            const imageData = downloadQueue.shift(); // Get the next image to download
            S3Client.getObject({ Bucket: environment.kIMAGEBUCKET, Key: imageData.key }, (error, data) => {
              if (error != null) {
                console.log(`Failed to retrieve an object: ${error}`);
                // Continue to the next image even if there's an error
                processNextImage();
              } else {
                const ContentType = 'ContentType';
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([new Uint8Array(data.Body)], { type: data[ContentType] }));
                link.download = imageData.key;
                link.click();
      
                // Continue to the next image
                processNextImage();
              }
            });
          } else {
            // All images have been downloaded
            that.choosedImage = [];
            that.submitted = false;
            that.loadingBar.complete();
            that.uncheckElements();
          }
        }
      
        // Start the image download process
        processNextImage();
      }

    public getImageUrlFromList(): any {
        const imageUrlList = this.choosedImage.map(image => image.photoUrl);
        return imageUrlList;
    }

    public uncheckElements(): any {
        const uncheck = document.getElementsByTagName('input');
        for (let i = 0; i < uncheck.length; i++) {
            if (uncheck[i].type === 'checkbox') {
                uncheck[i].checked = false;
            }
        }
    }

    public deleteYesMethod(): any {
      const { choosedImage, datas, choosedImageIdList } = this;
      if (choosedImage && choosedImage.length > 0) {
        const data = {
          userId: localStorage.getItem('userId'),
          workSpaceName: sessionStorage.getItem('workspacename'),
          albumId: datas.albumId,
          photoId: choosedImageIdList,
        };
        this.deletePhotoApi(data);
      }
    }

    public deletePhotoApi(data): void {
        this.albumService.deletePhotoList(data).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.modalRef.hide();
                this.toastr.success(`${this.mediaFormat} Deleted successfully`);
                this.choosedImage = [];
                this.getAllPhotosByAlbum();
            } else {
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }


    public getImageIdList(): any {
        this.list2 = [];
        for (let index = 0; index < this.choosedImage.length; index++) {
            this.list2.push(this.choosedImage[index].albumId);

            if ((index + 1) === this.choosedImage.length) {
                return this.list2;
            }
        }
    }

    public getImageKeyList(): any {
        this.list = [];
        for (let index = 0; index < this.choosedImage.length; index++) {
            this.list.push(this.choosedImage[index].key);

            if ((index + 1) === this.choosedImage.length) {
                return this.list;
            }
        }
    }

    public yesMethod(): any {
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.datas.albumId,
            membershipId: [this.membershipId],
        };

        this.removeUserFromAlbum(data, '');
    }

    public yesLeave(): any {
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.datas.albumId,
            membershipId: [localStorage.getItem('userId')],
        };

        this.removeUserFromAlbum(data, 'self');
    }

    public removeUserFromAlbum(data, type): any {
        this.albumService.removeUserFromAlbum(data).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.toastr.success(res.status.msg);
                this.modalRef.hide();
                if (type === 'self') {
                    this.showAlbumSettings();
                    this.router.navigate(['/project/albums'], { queryParams: this.api.getSessionData() });
                } else {
                    this.peopleList.splice(this.index, 1);
                }
            } else {
                this.modalRef.hide();
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.commonErrorHandler(err);
        });
    }

    public getAllMemberByAlbum(): any {
        this.errorMessage = '';
        this.memberbutton = true;
        this.albumService.getAllMemberByAlbum(this.search, this.datas.albumId).subscribe(
            (res): any => {
                this.memberbutton = false;
                if (res && res.status && res.status.status === 200) {
                    this.peopleList = res.entity;
                } else {
                    this.errorMessage = 'its seems no members are available';
                    this.toastr.error(res.status.msg);
                }
                this.getMemberList();
            }, (err): any => {
                this.memberbutton = false;
                this.errorMessage = 'its seems no members are available';
                this.api.handleError(err);
            },
        );
    }


    public imageCropped(event: ImageCroppedEvent, i): void {
        this.selectedImage[i].croppedImage = base64ToFile(event.base64);
    }

    public dropped(files): void {
        
        if (files && files.length > 0) {
            if (files.length > 10 || (this.selectedImage.length + files.length) > 10) {
                this.toastr.error('Maximum you can upload 10 photos only');
                return;
            }

            for (let index = 0; index < files.length; index++) {
                const droppedFile = files[index];
                if (droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                    fileEntry.file((ev): any => {
                        if(ev.type.startsWith('video')) {
                            const videoBlob = new Blob([ev], { type: ev.type });
                            const videoUrl = URL.createObjectURL(videoBlob);
                            const video = document.createElement('video');
                            video.src = videoUrl;
    
                            video.addEventListener('loadedmetadata', () => {
                                // Check if video duration is at least 15 seconds
                                if (video.duration >= 15) {
                                  // Upload the video file
                                  this.toastr.error('Video duration needs to be less than 15 seconds');
                                } else {
                                    this.saveImageObjectLoad({ target: { files: [ev] } });
                                }
                              });
                        } else if(ev.type.startsWith('image')){
                            this.saveImageObjectLoad({ target: { files: [ev] } });
                        } else {
                            this.toastr.error('Unknown file type:', ev.type);
                        }
                    });
                }
            }
        }
    }

    public async saveImageObjectLoad(imageFile) { 
        const checkValidFile = this.albumService.getCredential(imageFile.target.files[0]);
        if (checkValidFile.fileExtenstion.toLowerCase() === 'jpg'
            || checkValidFile.fileExtenstion.toLowerCase() === 'jpeg' || checkValidFile.fileExtenstion.toLowerCase() === 'png' ) {
            let reader = new FileReader();
            reader.onload = (e) => {
            this.selectedImage.push({
                name: checkValidFile.fileName,
                file: imageFile,
                key: '',
                finish: false,
                valid: true,
                percentage: 0,
                errorMessage: '',
                request: '',
                isVideo: false,
                croppedImage: imageFile.target.files[0],
                base64Format: e.target.result
            });
            };
            reader.readAsDataURL(imageFile.target.files[0]);
            // this.awsUploadFile(imageFile, params, (+this.selectedImage.length - 1));
        } else if(checkValidFile.fileExtenstion.toLowerCase() === "mp4") {
            let reader = new FileReader();
            reader.onload =  (e) => {
                this.selectedImage.push({
                    name: checkValidFile.fileName,
                    file: imageFile,
                    key: '',
                    finish: false,
                    valid: true,
                    percentage: 0,
                    errorMessage: '',
                    request: '',
                    isVideo: true,
                    croppedImage: imageFile.target.files[0],
                    base64Format: e.target.result
                });
            }
            reader.readAsDataURL(imageFile.target.files[0]);
        } else {
            this.selectedImage.push({
                name: checkValidFile.fileName,
                file: '',
                key: '',
                finish: false,
                valid: false,
                percentage: 0,
                errorMessage: `.${checkValidFile.fileExtenstion} Invalid image format.`,
                request: '',
                isVideo: false
            });
        }
        // const elmnt = document.getElementById('mas-containter');
        // elmnt.style.maxHeight = `${this.selectedImage.length > 2 ? this.selectedImage.length * 0 : 200}px`;
    }

    public dataURLtoFile(dataurl, filename): any {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    // eslint-disable-next-line max-lines-per-function
    public imageUpload(): void {
        for (let index = 0; index < this.selectedImage.length; index++) {
            if (!this.selectedImage[index].valid) {
                this.submitted = false;
                this.toastr.error('Please check Photos/Videos in selected photo list');
                return;
            }
        }

        // If all images are valid, check if index is at the end of the array
        if (this.i >= this.selectedImage.length) {
            this.submitted = false;
            this.savePhoto();
            return;
        }

        this.toastr.clear();
        this.submitted = true;
        // Get S3 params for the current image
        const params = this.albumService.getS3Params(this.selectedImage[this.i]);
        // Set key of current image to params
        this.selectedImage[this.i].key = params.Key;
        // Upload file to AWS
        this.awsUploadFile(this.selectedImage[this.i].file, params);
    }

    public abortS3(index): any {
        if (!this.selectedImage[index].finish && this.selectedImage[index].valid) {
            this.selectedImage[index].percentage = 0;
        }
        this.selectedImage.splice(index, 1);
    }

    public roundValue(value): any {
        return Math.round(value);
    }

    public awsUploadFile(file, params): any {
        const S3Client = this.albumService.getS3Credential();
        
        if (file) {
            const that = this;
            return new Promise((res, rej): any => {
                this.selectedImage[this.i].request = S3Client.putObject(params,
                    (err: any, data: any): any => {
                        if (err) {
                            return rej(err);
                        }
                        // eslint-disable-next-line no-param-reassign
                        params.filename = file.name;
                    }).on('httpUploadProgress', (evt): any => {
                    that.uploadPercentage = (evt.loaded * 100) / +evt.total;
                    that.progressBarValue(that.uploadPercentage, this.i);
                });
            });
        }
    }

    public clickPhoto(value): any {
        if (this.datas.lat && this.datas.lng) {
            const { lat, lng } = this.datas;
            this.origin = { lat: +lat, lng: +lng };
            this.destination = { lat: value.lattitudeNumber, lng: value.longitudeNumber };
        }
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position && position.coords.latitude && position.coords.longitude) {
                    this.geolocationService.reverseGeocode(position.coords.latitude, position.coords.longitude).then(address => {
                        this.latt = position.coords.latitude;
                        this.long = position.coords.longitude
                    })
                }
            },
            (error: any) => {
            })
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    public savePhoto(): any {
        this.toastr.clear();
        if (this.selectedImage.length > 0) {
            for (let index = 0; index < this.selectedImage.length; index++) {
                if (this.selectedImage[index].valid) {
                    this.key.push(this.selectedImage[index].key);
                    this.lattitude.push(this.latt ? this.latt : 40.730610);
                    this.longitude.push(this.long ? this.long : -73.935242);
                    this.direction.push('');
                    this.photoName.push(this.selectedImage[index].name);
                } else {
                    this.toastr.error('Please check photos in selected photo list');
                    this.key = [];
                    this.photoName = [];
                    this.lattitude = [];
                    this.longitude = [];
                    this.direction = [];
                    this.submitted = false;
                    return;
                }

                if ((index + 1) === this.selectedImage.length) {
                    const data = {
                        userId: localStorage.getItem('userId'),
                        workSpaceName: sessionStorage.getItem('workspacename'),
                        albumId: this.datas.albumId,
                        photoName: this.key,
                        key: this.key,
                        lattitude: this.lattitude,
                        longitude: this.longitude,
                        direction: this.direction,
                    };

                    this.submitted = true;

                    if (sessionStorage.getItem('subscription') === '1' && ((+data.key.length) + (+this.workspacePhotoCount)) > 300) {
                        this.toastr.error('kindly upgrade your Plan from Basic to Pixly Pro');
                        this.key = [];
                        this.lattitude = [];
                        this.longitude = [];
                        this.direction = [];
                        this.photoName = [];
                        this.submitted = false;
                        return;
                    }

                    this.albumService.uploadPhotos(data).subscribe((res): any => {
                        if (res && res.status && res.status.status === 200) {
                            this.toastr.success('Photos/Videos added to album');
                            this.selectedImage = [];
                            this.key = [];
                            this.lattitude = [];
                            this.longitude = [];
                            this.direction = [];
                            this.photoName = [];
                            this.submitted = false;
                            this.i = 0;
                            this.getAllPhotosByAlbum();
                        } else {
                            this.submitted = false;
                            this.toastr.error(res.status.msg);
                        }
                    }, (err): any => {
                        this.submitted = false;
                        this.api.handleError(err);
                    });
                }
            }
        } else {
            this.toastr.error('Please browse photos to upload to album');
        }
    }

    public getLatListVale(count): any {
        const value = [];
        if (count > 0) {
            for (let index = 0; index < count.length; index++) {
                value.push(+this.lat);
                if ((index + 1) === count) {
                    return value;
                }
            }
        }
    }

    public getLngListVale(count): any {
        const value = [];
        if (count > 0) {
            for (let index = 0; index < count.length; index++) {
                value.push(this.lng);
                if ((index + 1) === count) {
                    return value;
                }
            }
        }
    }

    public getDirListVale(count): any {
        const value = [];
        if (count > 0) {
            for (let index = 0; index < count.length; index++) {
                value.push('');
                if ((index + 1) === count) {
                    return value;
                }
            }
        }
    }

    public getAllPhotosByAlbum(): any {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.datas.albumId,
            pagenumber: 0,
            pagerecord: 0,
            sortby: 'created',
            orderby: this.orderBy,
            sortviewby: this.sortType,
            timeZone: moment().tz(moment.tz.guess()).format('z'),
        };

        this.getPhotoList(data);
    }

    public getAlbumsort(): any {
        this.orderBy = this.orderBy === 'desc' ? 'asc' : 'desc';
        this.DateText = this.orderBy === 'desc' ? 'Most Recent' : 'Oldest';
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.datas.albumId,
            pagenumber: 0,
            pagerecord: 0,
            sortby: 'created',
            orderby: this.orderBy,
            sortviewby: this.sortType,
        };

        this.getPhotoList(data);
    }

    public changeView(type): any {
        this.sortType = type;
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.datas.albumId,
            pagenumber: 0,
            pagerecord: 0,
            sortby: 'created',
            orderby: this.orderBy,
            sortviewby: this.sortType,
        };
        this.mapView = false;
        this.showPeople = false;
        this.getPhotoList(data);
    }

    public getPhotoList(data): any {
        this.errorMessage = '';
        this.choosedImage = [];
        this.albumService.getPhotoByAlbumId(data).subscribe((res): any => {
            this.workspacePhotoCount = res.workspacePhotoCount;
            if (res && res.status && res.status.status === 200) {
                this.photoList = res.entity.listview;
            } else {
                this.photoList = [];
                this.errorMessage = 'no record';
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public progressBarValue(percentage, i): void {
        this.selectedImage[i].percentage = +percentage;
        if (this.selectedImage[i].percentage === 100) {
            this.i += 1;
            this.selectedImage[i].finish = true;
            this.imageUpload();
        }
    }

    public fileOver(event): void {
        console.log(event);
    }

    public fileLeave(event): void {
        console.log(event);
    }

    public getAllAlbum(id): any {
        const path = `?albumId=${id}&workSpaceName=${sessionStorage.getItem('workspacename')}&userId=${localStorage.getItem('userId')}`;
        this.albumService.getAlbumByAlbumId(path).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                if (res.entity && res.entity.album) {
                    this.albumEntity = res.entity.album;
                }
            } else if (res && res.status && res.status.status !== 204) {
                this.toastr.error('Sorry, Album not available, May be someone deleted it');
                window.history.back();
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public showFolder(): void {
        this.createFolder = !this.createFolder;
    }

    public showGetPdf(): void {
        this.GetPdf = !this.GetPdf;
    }

    public showAssign(): void {
        this.AssignTo = !this.AssignTo;
    }

    public pdfDownload(urllist): void {
        if (urllist.length === 0) {
            this.loadingBar.start();
            this.submitted = true;
        }

        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: urllist.length === 0 ? this.datas.albumId : '',
            urlList: urllist,
        };

        this.albumService.getPDFDownload(data).subscribe(
            (res): any => {
                this.choosedImage = [];
                const blob = new Blob([this.base64ToArrayBuffer(res.entity.pdfData)], { type: 'application/pdf' });

                saveAs(blob, `${this.datas.albumId}.pdf`);
                
                if (urllist.length > 0) {
                    this.uncheckElements();
                }
                this.submitted = false;
                this.loadingBar.complete();
                if(this.photoSelect.nativeElement.parentElement.children.length > 0) {
                    for(let  i=0; i< this.photoSelect.nativeElement.parentElement.children.length;i++){
                        this.photoSelect.nativeElement.parentElement.children[i].lastChild.previousSibling.checked = false
                    }
                }
            },
        );
    }

    public base64ToArrayBuffer(base64): any {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }


    public showAlbumSettings(): void {
        this.AlbumSettings = !this.AlbumSettings;
        if (this.AlbumSettings) {
            this.submitted = false;
            this.album.albumName = this.albumEntity.albumName;
            this.album.description = '';
            if (this.albumEntity.description && this.albumEntity.description.text) {
                this.album.description = this.albumEntity.description.text;
            }
            this.lat = +this.albumEntity.lattitude;
            this.lng = +this.albumEntity.longitude;
        }
    }

    public showDirection(): void {
        this.mapView = !this.mapView;
        if (this.mapView) {
            this.showPeople = false;
            this.mapPhotoList = [];
            for (let index = 0; index < this.photoList.length; index++) {
                for (let i = 0; i < this.photoList[index].photoInfo.length; i++) {
                    this.photoList[index].photoInfo[i].lattitudeNumber = +this.photoList[index].photoInfo[i].lattitude;
                    this.photoList[index].photoInfo[i].longitudeNumber = +this.photoList[index].photoInfo[i].longitude;
                    this.photoList[index].photoInfo[i].isOpen = true;
                    this.photoList[index].photoInfo[i].urlIcon = './assets/images/red_dot.svg';
                    this.photoList[index].photoInfo[i].myLocation = false;

                    this.mapPhotoList.push(this.photoList[index].photoInfo[i]);

                    if ((index + 1) === this.photoList.length) {
                        const data = {
                            lattitudeNumber: +this.lat,
                            longitudeNumber: +this.lng,
                            isOpen: false,
                            urlIcon: './assets/images/blue-dot.png',
                            myLocation: true,
                        };
                        this.mapPhotoList.push(data);
                    }
                }
            }
        }
    }


    public openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'custom-modal',
        });
    }

    public openModal1(template: TemplateRef<any>): void {
        this.modalRef1 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'custom-modal',
        });
        this.modalRef.hide();
    }

    public openModal2(membershipId, i): any {
        this.membershipId = membershipId;
        this.index = i;
        this.modalRef = this.modalService.show(
            this.exampleModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public leftFromAlbum(): any {
        this.toastr.clear();

        if (this.albumEntity.albumVisibility === 'PUBLIC') {
            this.toastr.error('Sorry, You cannot able to leave from Public album');
            return;
        }
        this.modalRef = this.modalService.show(
            this.leaveModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public openModal3(): any {
        this.modalRef = this.modalService.show(
            this.photodeleteModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );

        this.choosedImageIdList = this.getImageIdList();
    }

    public showTags(): void {
        this.tagShow = !this.tagShow;
    }

    public showNext(): void {
        this.getNext = !this.getNext;
    }

    public getCurrentLocation(): void {
        this.lat = 0;
        this.lng = 0;
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (showPosition): any => {
                    this.lat = +showPosition.coords.latitude;
                    this.lng = +showPosition.coords.longitude;
                },
            );
        }
    }

    public onSubmit(albumForm: any): void {
        this.validation = true;
        if (!albumForm.form.valid) {
            return;
        }
        this.submitted = true;
        const params = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumName: this.album.albumName,
            description: this.album.description,
            teamId: sessionStorage.getItem('projectId'),
            lattitude: this.lat,
            longitude: this.lng,
            albumId: this.albumId,
        };
        this.albumService.UpdateAlbum(params).subscribe(
            (res): void => {
                if (res && res.status && res.status.status === 200) {
                    albumForm.form.reset();
                    this.submitted = false;
                    this.toastr.success(res.status.msg);
                    this.AlbumSettings = false;
                    this.getAllAlbum(this.albumId);
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                this.toastr.error('Something went wrong, Please login again and try. ');
                this.router.navigate(['/login']);
            },
        );
    }

    public moveImage(): any {
        this.submitted = true;
        localStorage.setItem('imageKeyList', JSON.stringify(this.getImageKeyList()));
        localStorage.setItem('imageIdList', JSON.stringify(this.getImageIdList()));

        this.submitted = false;
        this.router.navigate(['/project/albums'], { queryParams: { ...this.api.getSessionData(), ...{ albumId: this.datas.albumId } } });
    }

    public copyImage(): any {
        this.submitted = true;
        this.toastr.success('copied selected image');
        localStorage.setItem('imageKeyList', JSON.stringify(this.getImageKeyList()));

        this.submitted = false;
        this.router.navigate(['/project/albums'], { queryParams: { ...this.api.getSessionData(), ...{ albumId: this.datas.albumId } } });
    }

    public pasteImage(): any {
        this.toastr.clear();
        if (this.datas.albumId === this.datas.copyImageFrom) {
            this.toastr.error('Sorry, We are not allowed to paste with in same album');
            return;
        }
        this.loadingBar.complete();
        const imageKeyList = JSON.parse(localStorage.getItem('imageKeyList'));
        if (imageKeyList.length > 0) {
            this.submitted = true;
            this.loadingBar.start();
            const key = [];
            const lattitude = [];
            const longitude = [];
            const direction = [];
            for (let index = 0; index < imageKeyList.length; index++) {
                key.push(imageKeyList[index]);
                lattitude.push(+this.lat);
                longitude.push(+this.lng);
                direction.push('');

                if ((index + 1) === imageKeyList.length) {
                    const data = {
                        userId: localStorage.getItem('userId'),
                        workSpaceName: sessionStorage.getItem('workspacename'),
                        albumId: this.datas.albumId,
                        photoName: key,
                        key,
                        lattitude,
                        longitude,
                        direction,
                    };

                    this.toSavePhoto(data);
                }
            }
        } else {
            this.toastr.error('Sorry there is no photos to paste');
        }
    }

    public toSavePhoto(data): any {
        this.albumService.uploadPhotos(data).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.toastr.success('Photos added to album');
                this.submitted = false;
                this.loadingBar.complete();
                localStorage.removeItem('imageKeyList');
                const deleteImageId = JSON.parse(localStorage.getItem('imageIdList'));
                if (deleteImageId && deleteImageId.length > 0) {
                    this.photoDelete(this.datas.copyImageFrom, deleteImageId);
                }

                this.getAllPhotosByAlbum();
                this.router.navigate([], {
                    queryParams: {
                        copyImageFrom: null,
                    },
                    queryParamsHandling: 'merge',
                });
            } else {
                this.submitted = false;
                this.loadingBar.complete();
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.loadingBar.complete();
            this.api.handleError(err);
        });
    }

    public photoDelete(albumId, list): any {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId,
            photoId: list,
        };

        this.albumService.deletePhotoList(data).subscribe(
            (res): any => {
                localStorage.removeItem('imageIdList');
            },
            (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public deleteIndividualImage(img): void {
        this.currentPhoto = [img.photoId];
        this.mediaFormat = (img.media.extension == 'MP4') ? 'Video' : 'Photo';
        this.modalRef = this.modalService.show(
            this.individualphotodeleteModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }, {format: img.media.extension}),
        );
    }

    public yesDeleteIndividualPhoto(): void {
        if (this.currentPhoto && this.currentPhoto.length > 0) {
            const data = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                albumId: this.datas.albumId,
                photoId: this.currentPhoto,
            };
            this.deletePhotoApi(data);
        }
    }

    public shareAlbum(): void {
        this.currentInvite = true;
        this.currentAlbumId = this.datas.albumId;
    }

    public changeSidemenu(event): void {
        this.currentInvite = event;
        if (!event) {
            this.currentAlbumId = '';
        }
    }

    // public getDateValue(value): any {
    //     if (value) {
    //         const datobj = value.split(' ');
    //         return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
    //     }
    //     return '';
    // }

    public getDateValue(value): any {
        if (value) {
            const dateParts = value.split(' ')[0].split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1; // Month in JavaScript is 0-indexed
            const day = parseInt(dateParts[2]);
            
            return new Date(year, month, day);
        }
        return null;
    }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }

    public reportModal(){
        if(this.choosedImage.length === 0) {
            this.toastr.error('Please choose photos');
            return;
        }
        this.pdfModalSelect = true;
    }

    public cancelPdf() {
        this.pdfModalSelect = false;
    }

    public generatePDF(): any {
        if(this.choosedImage.length === 0) {
            this.toastr.error('Please choose photos');
            return;
        }
        const timeZoneFormat = moment.tz(moment.tz.guess()).zoneAbbr();
        this.pdfSubmitted = true;
        const photoIds = [];
        this.choosedImage.forEach((id)=>{
            photoIds.push(id.albumId);
        });
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: sessionStorage.getItem('projectId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                reportType: this.selectedPDFType,
                timeZone:timeZoneFormat,
                albumIds: [this.photoAlbumId],
                photoIds: photoIds
            };
            this.toastr.clear();
            this.workspaceService.generatePdf(data).subscribe((res): any => {
                this.pdfSubmitted = false;
                this.pdfModalSelect = false;
                if (res && res.status && res.status.status === 200) {
                    this.toastr.success(res.status.msg);
                    this.choosedImage = [];
                    this.uncheckElements();
                } else {
                    this.toastr.error(res.status.msg);
                }
            }, (err): any => {
                this.pdfSubmitted = false;
                this.pdfModalSelect = false;
                if (err && err.status && err.status.status === 302) {
                    this.toastr.error(err.status.msg);
                } else {
                }
            });
    }

    isVideo(awskey: string): boolean {
        const extension = awskey.split('.').pop();
        return this.videoExtentions.includes(extension);
    }

    toggleImageSelection(image: any, photoId: string): void {
        const index = this.selectedImages.findIndex((selectedImage) => selectedImage.photoId === photoId);
    
        if (index === -1) {
            // Image is not selected, so add it to the selected images list
            this.selectedImages.push({ ...image, media: { ...image.media, awskey: `http://d4381dmmtr1wk.cloudfront.net/${image.media.awskey}` } });
        } else {
            // Image is already selected, so remove it from the selected images list
            this.selectedImages.splice(index, 1);
        }
    }
    
    public yesMethodDelete(): any {
        if (this.choosedImage && this.choosedImage.length > 0) {
            const data = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                albumId: this.datas.albumId,
                photoId: this.choosedImageIdList,
            };

            this.deletePhotoApi(data);
        }
    }
}
