<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-7 col-md-6 col-sm-10 mx-auto mx-md-0 text-center my-content order-1 order-md-0">
            <img src="./assets/images/workspace.svg" alt="setpassword" class="banner-img-2">
         </div> 
         <div class="col-lg-5 col-md-6 col-sm-10 mx-auto mx-md-0 my-auto text-center text-md-left">
            <h1  class="grey-a  font-weight-bold fs28 mb-4">Hello {{userName}} !</h1>
            <h2 class="fs22 grey-a fw-600">Create Your Workspace</h2>
            <p class="fs11 grey-a ls-1 ">This will be your Pixly Workspace where you and others can collaborate.</p>
            <div class="row mt50 justify-content-center justify-content-md-start" *ngIf="shouldShowButton()">
               <div class="col-lg-4 col-md-4 col-5 pr-1">
                  <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload" (click)="workspaceToggle=true;toggleWS= true">Create Workspaces</button>
               </div>
            </div>
         </div>
      </div>
   </div>

</section>  
<app-sub-footer></app-sub-footer>
<app-your-invites-sidemenu [toggleSidemenu]="filterSidemenu" [workspacePending]="workspacePendingList"></app-your-invites-sidemenu>

<div id="overlay" [ngClass]="{'d-block': toggleWS}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : toggleWS}">
   <div *ngIf='workspaceToggle'>
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Name your Workspace</h1>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Workspace?</h2>
      <form class="mt-4" name="workspaceForm"
         #workspaceForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input  maxlength="50" [validation]="false" type="text" name="workSpaceName" required [(ngModel)]="createWorkspace.workSpaceName"
               #workSpaceName="ngModel" [ngClass]="{ 'has-error': workspaceForm.submitted && workSpaceName.invalid }"
               class="form-control br8 pt22 pb22 fw-600" placeholder="Type Name Here...">
            <div *ngIf="workSpaceName.invalid && (workSpaceName.dirty || workSpaceName.touched)">
               <div *ngIf="workSpaceName.errors.required" class="text-danger fs12">
                  Workspace name is required.
               </div>
            </div>
         </div>
      
         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
            <div class="row mt50 px-3">
               <div class="col-12 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" 
                  type="button" (click)="workspaceForm.form.valid && workspaceSubmit()" [disabled]="!workspaceForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Workspace</button>
            </div> 
            <div class="col-12 pt-4" [ngClass]="{'pointer-events-none': submitted}" >
                <p class="pt7 pb7 fs13 fw-600 pl115" (click)="skipWorkspace();">Skip</p>
             </div> 
         </div>
      </form>
   </div>
<!-- choose workspace -->  
   
   <div *ngIf='projectToggle'>
      <div class="d-flex justify-content-between">
         <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Name your Project</h1>
      </div>
      <h2 class="fs12 grey-j ls-3">What would you like to call your Project?</h2>
      <form class="mt-4 " name="projectForm" #projectForm="ngForm" novalidate>
         <div class="form-group input-rounded">
            <input maxlength="50" type="text" required name="teamName" required [(ngModel)]="createProject.teamName"
            #teamName="ngModel" [ngClass]="{ 'has-error': projectForm.submitted && teamName.invalid }" class="form-control br8 pt22 pb22 fw-600 " placeholder="Type Name Here..."
            (keydown.space)="createProject.teamName.trim().length > 0 ? '': $event.preventDefault();">
            <div *ngIf="teamName.invalid && (teamName.dirty || teamName.touched)">
               <div *ngIf="teamName.errors.required" class="text-danger fs12">
                  Project name is required.
               </div>
            </div>
         </div>
         <p class="fs12 ls-4 grey-g">Need a suggestion? Company names work great. If it's a BIG company, try naming your
            team after the division you work in. Don't worry, you can change this later if you need to.</p>
            <div class="row mt50 px-3">
               <div class="col-12 pl-1">
               <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" 
                  type="button" (click)="projectForm.form.valid && createProjectMethod(projectForm)" [disabled]="!projectForm.valid || submitted">
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Project</button>
            </div> 
            <div class="col-12 pt-4" [ngClass]="{'pointer-events-none': submitted}">
                <p class="pt7 pb7 fs13 fw-600 pl115" (click)="skipProjectMethod();">Skip</p>
             </div> 
         </div>
      </form>
   </div>
</div>