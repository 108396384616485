<section class="main-container company-layout">
    <div class="sub-container">
       <div class="sub-content-container pt15 pb15">
          <div class="content-card  border-grey shadow-none">
             <div *ngIf="!companyDetails">
                <div class="card-header px-4 mb35 pb-0 bg-white  border-0">
                   <div class="d-sm-flex align-items-center justify-content-between flex-column flex-sm-row mt-md-4 mt-2">
                      <div class="flex-grow-1">
                      <h1 class="fw-600 fs20 ls-9 grey-g mb-3 mb-sm-0">
                         <img (click)="gotoBack()" src="./assets/images/back-arrow.svg" class="c-pointer mr-sm-3 mr-2" alt="Back">
                         Workspace Management
                      </h1>
                     </div>
                     <div class="pr-3">
                     <button class="btn btn-white br25 py-2 fw-600 pr-sm-3 fs12 mr-sm-2 mr-1" (click)="viewCompany()">
                        <img src="./assets/images/independent-users.svg" alt="add" class="mr-1 w24"> Independent Users
                     </button>

                      <button class="btn btn-blue-secondry br25 py-2 px-4 fs12" (click)="openCompanySidepanel()">
                      <img src="./assets/images/plus.svg" alt="add"
                         class="mr-1 w11 mb-1">Add Workspace
                      </button>
                     </div>
                      <div dropdown class="tag-menu c-pointer">
                        <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal" class="w23"
                            id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
                        <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                            aria-labelledby="basic-link">
                            <li *ngFor="let sortChoice of sortItem">
                                <a (click)="getSortinglist(sortChoice)" class="dropdown-item text-black fs13 fw-600 mb-1"
                                    href="javascript:void(0)">{{sortChoice}}</a>
                            </li>
                        </ul>
                    </div>
                   </div>
                </div>
                <div class="card-body px-md-4 px-2 pt-2">

                  <form class="mb30  px-2">
                     <div class="input-group input-todo">
                         <div class="input-group-prepend">
                             <span class="input-group-text pr-1" id="basic-addon3"><img
                                     src="./assets/images/search-todo.svg" alt="search" class="w13"></span>
                         </div>
                         <input type="text" class="form-control fw-600 px-1" placeholder="Search Company"
                         [(ngModel)]="companySearch" (ngModelChange)="companySearchDelay.next($event)" [ngModelOptions]="{standalone: true}">
                         <div class="input-group-append" *ngIf="companySearch !== ''">
                             <span class="input-group-text border-left-0 p-1" (click)="companySearch = '';getAllCompanyList()">
                                 <span class="lnr lnr-cross fs16"></span>
                             </span>
                         </div>
                     </div>
                 </form>

                 <div class="page-loaders text-center h-600px my-5" *ngIf="loading && (companyList.length === 0)">
                  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="45"/>
                  </svg>
                  <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
              </div>

                 <div class="company-list-div scroll-content" infiniteScroll
                 [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollCompany()"
                 [infiniteScrollContainer]="'.company-list-div'" [fromRoot]="true" [scrollWindow]="false">
                     <div class="d-flex  align-items-center mb-4" *ngFor="let company of companyList">
                        <div class="company-img mr-3 c-pointer" (click)="viewCompany(company)">
                           <img *ngIf="!company.companyProfileImage" src="./assets/images/no-image.jpg" alt="company">
                           <img *ngIf="company.companyProfileImage" src="{{cloudFrontURL}}{{company.companyProfileImage}}" alt="company">
                        </div>
                        <div class="flex-grow-1 c-pointer pr-3" (click)="viewCompany(company)">
                           <h2 class="grey-m fs17 fw600 mb-2">{{company.companyName}}</h2>
                           <address class="grey-m fs12 mb-0">Address : {{company.address}}</address>
                           <p class="grey-m fs12 mb-0">Phone number :  {{company.phoneCode}} {{company.phoneNumber}}</p>
                        </div>
                        <div>
                        <img src="./assets/images/edit-outline.svg" title="Edit Company" (click)="editCompany(company)" alt="edit-outline" class="w15 mr-3 c-pointer">
                        <img src="./assets/images/delete.svg" title="Delete Company" (click)="openConfirmationModal(company.id)" alt="delete" class="w15 mr-3 c-pointer">
                     </div>
                  </div>
               </div>


                   <div class="card-body text-center p-3 "
                     *ngIf="(!companyList || companyList.length === 0)">
                     <div class="pending-invites-bg">
                        <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                        <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no company<br> Please create
                              company by clicking<br> the above button</p>
                     </div>

                  </div>
               </div>

             </div>
             <!--company details-->
             <div *ngIf="companyDetails">
                <div class="card-header px-4 mb35 pb-0 bg-white  border-0">
                   <h1 class="fw-600 fs20 ls-9 grey-g mb-3 mt-md-4 mt-2 mb-sm-0">
                      <img src="./assets/images/back-arrow.svg" (click)="back()" class="c-pointer mr-sm-3 mr-2" alt="Back">
                      {{this.companyNameInUserList}}
                   </h1>
                </div>
                <div class="card-body px-0 pt-0 pb-2 independedt-user-list">


                  <div class="page-loaders text-center h-600px my-5" *ngIf="independetUserLoad && (companyUserList.length === 0)">
                     <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                         <circle cx="50" cy="50" r="45"/>
                     </svg>
                     <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                 </div>

                    <!--User Start-->
                    <div class="scroll-content"  infiniteScroll
                    [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                    [infiniteScrollContainer]="'.independedt-user-list'" [fromRoot]="true" [scrollWindow]="false">
                    <div class="company-user px-3 d-flex c-pointer"  *ngFor="let user of companyUserList">
                     <div class="user-img mr-3">
                        <img *ngIf="!user.profileImage" src="./assets/images/empty-img.png" class="rounded-circle" alt="user">
                        <img *ngIf="user.profileImage" src={{cloudFrontURL}}{{user.profileImage}} class="rounded-circle" alt="user">
                     </div>
                     <div>
                        <h2 class="grey-m fs16 font-weight-bold mb-1">{{user.firstName}} {{user.lastName}}</h2>
                        <p *ngIf="user.roleName" class="$grey-r fs12 mb-2">{{user.roleName}} in {{user.projectName}}</p>
                        <span *ngIf="user.phoneNumber" class="badge contact-info mr-2 mb-md-0 mb-2">{{user.phoneCode}} {{user.phoneNumber}}</span>
                        <span class="badge contact-info">{{user.email}}</span>
                     </div>
                  </div>
                  </div>

                    <!--User End-->

                    <div class="card-body text-center p-3 "
                    *ngIf="(!companyUserList || companyUserList.length === 0)">
                    <div class="pending-invites-bg">
                       <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                       <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no user
                    </div>

                 </div>
                </div>
             </div>
             <!--company details-->
          </div>
       </div>
    </div>
 </section>
 <!--Add Company-->
 <div id="overlay" [ngClass]="{'d-block': addCompany}"></div>
 <div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : addCompany}">
 <div class="d-flex justify-content-between">
    <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">{{editCompanyDetails === '' ? 'Add' : 'Edit'}} Company</h1>
    <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="closeSidebar()"></em>
 </div>
 <form class="mt-3" name="createCompanyform" autocomplete="off"
 (ngSubmit)="onSubmit(createCompanyform)" #createCompanyform="ngForm" novalidate>
    <div class="row">
       <div class="col-md-12">


          <div class="form-group input-rounded mb-3">
             <label class="grey-j fs12 ">Name</label>
             <div class="position-relative">
               <input
                  type="text"
                  class="form-control br8 br8 pt22 pb22 fw-600"
                  name="companyName"
                  #companyName="ngModel"
                  [(ngModel)]="companyForm.companyName"
                  [typeahead]="alreadyPresentList"
                  typeaheadOptionField="companyName"
                  (ngModelChange)="searchDelay.next($event)"
                  placeholder="Enter Company name"
                  autocomplete="new-password"
                  (typeaheadOnSelect)="onCompanySelect($event)"
                  (blur)="checkSuggestions()"
                  required
               />
            </div>

             <div *ngIf="companyName.invalid && formSubmitted">
               <div *ngIf="companyName.errors.required" class="text-danger fs12">
                  Company name is required.
               </div>
            </div>

         </div>
          <div class="form-group input-rounded mb-3">
             <label class="grey-j fs12 ">Address</label>
             <input type="text" class="form-control br8 br8 pt22 pb22 fw-600" name="address" #address="ngModel" [(ngModel)]="companyForm.address" placeholder="Enter Address" required>
             <div *ngIf="address.invalid && formSubmitted">
               <div *ngIf="address.errors.required" class="text-danger fs12">
                  Address is required.
               </div>
            </div>
         </div>

          <div class="form-group input-rounded mb-3">
            <label class="grey-j fs12 ">Phone Number</label>
            <div class="input-group input-rounded">
               <div class="input-group-prepend  border-right-0 ">
                  <select class="custom-select input-group-text  border-right-0 " (change)="changeMask()" name="phoneCode" [(ngModel)]="companyForm.phoneCode" #phoneCode="ngModel" required>
                     <option value="" disabled>Select</option>
                     <option *ngFor="let c of pcode" value={{c.code}} >{{c.code}}</option>
                  </select>
               </div>
               <input [dropSpecialCharacters]="true" [mask]="phoneMask"  [readonly]="companyForm.phoneCode!= '' ? false : true" name="phoneNumber" [(ngModel)]="companyForm.phoneNumber" #phoneNumber="ngModel"
               [ngClass]="{ 'has-error': formSubmitted && phoneNumber.invalid }" type="text"
               autocomplete="off" class="form-control fw-600 pt22 pb22 border-left-0 "
               placeholder="Enter Phone Number" required>
            </div>

            <div *ngIf="phoneCode.invalid && formSubmitted">
               <div *ngIf="phoneCode.errors.required" class="text-danger fs12">
                  Phone code is required.
               </div>
            </div>

            <div *ngIf="!phoneCode.invalid && phoneNumber.invalid && formSubmitted">
               <div *ngIf="phoneNumber.errors.required" class="text-danger fs12">
                  Phone number is required.
               </div>
            </div>

         </div>
       </div>
    </div>
    <div class="row px-3 mt40">
       <div class="col-4 pr-1">
          <button [disabled]="loading" type="button" (click)="closeSidebar()" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
             >Cancel</button>
       </div>
       <div class="col-8 pl-1">
          <button type="submit" [disabled]="loading" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600"> <em
            *ngIf="loading" class="fa fa-spinner fa-spin"></em> Submit</button>
       </div>
    </div>
 </form>
 </div>


 <ng-template #deletecompany>
   <div class="modal-lg signup-modal">
       <div class="modal-content border-0">
           <div class="modal-header border-bottom-0 pb-0">
               <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

               <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
           </div>
           <div class="modal-body">
               <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete company?</p>
               <div class="modal-btn text-right">
                   <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                       [disabled]="loading" (click)="deleteCompanyFunc()">
                       <em *ngIf="loading" class="fa fa-spinner fa-spin"></em>Yes</button>
                   <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="loading"
                       (click) ="modalRef.hide()">No</button>
               </div>
           </div>
       </div>
   </div>
</ng-template>
