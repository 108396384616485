import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IntegrationsService } from '../services/integrations.service';
import { ApiService, UserService } from '../services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  public showIntegrationSideLayout: boolean = false;

  public showIntegrationSettingsSideLayout: boolean = false;

  public updateIntegration: boolean = false;
  
  public selectedIntegrationName: string = ''

  public integrationList: any = [{
    id:1, integrationName: 'Procore'
  }]

  public integrationForm: FormGroup;

  public integrationFormInput: any = {};

  public submitted = false;

  public passwordTypeOne = 'password';

  public passwordTypeTwo = 'password';

  public passwordTypeThree = 'password';

  public togglePasswordOne = true;

  public togglePasswordTwo = true;

  public togglePasswordThree = true;

  public viewMode = 'Projects';

  public integrationMenuShow: boolean = false;
  
  public procoreIntegrationDone: boolean = false;
  
  public validation: boolean = false;
  
  public selectedCompanyId: any;

  @ViewChild('integrationdeleteModal') public integrationdeleteModal: TemplateRef<any>;
  
  public modalRef: BsModalRef;
  
  public config = {
    ignoreBackdropClick: true,
  };
  
  public projectList: any = [];

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private integrationApi: IntegrationsService,
    public userService: UserService,
    private api: ApiService,
  ) {
    this.getProfile();
  }

  ngOnInit(): void {
    this.integrationFormInput = {
        companyId: '',
        clientId: '',
        secretId: '',
    };
  }

  public getProfile(): void {
    const payload = {
        userId: localStorage.getItem('userId'),
        workSpaceName: sessionStorage.getItem('workspacename'),
    };
    this.userService.viewProfile(payload).subscribe(
        (res): any => {
          this.procoreIntegrationDone = res.entity.procore;
        }, (err): any => {
          localStorage.clear();
          setTimeout(() => {
              this.router.navigate(['/login']);
          }, 1000);
        },
    );
}
  public showTabsIntegration() {
    this.getProjects();
  }

  public addIntegrationClick(){
    this.showIntegrationSideLayout = true;
  }

  public getFirstAndLastLetter(name): string {
    let value = 'WP';
    if (name) {
        const split = Array.from(name);
        value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
    }
    return value;
  }

  public updateIntegrationMethod() {
    this.updateIntegration = true;
    this.integrationApi.getAuth().subscribe(
      (res): any => {
        if(res && res.status.status === 200) {
          this.projectList = res.entity;
          this.integrationMenuShow = true;
          this.showIntegrationSettingsSideLayout = true;
          this.selectedCompanyId = res.entity.procoreCompanyId;
          this.integrationFormInput.companyId = res.entity.procoreCompanyId;
          this.integrationFormInput.clientId = res.entity.clientId;
          this.integrationFormInput.secretId = res.entity.clientSecret;
        }
      }, (err) =>{
        this.api.handleError(err);
      })
  }

  public selectedIntegrationMethod(integration) {
    this.selectedIntegrationName = integration.integrationName;
    this.showIntegrationSettingsSideLayout = true;
  }

  public passwordToggle(id: any): void {
    if (id === 1) {
        this.togglePasswordOne = !this.togglePasswordOne;
    } else if (id === 2) {
        this.togglePasswordTwo = !this.togglePasswordTwo;
    } else {
        this.togglePasswordThree = !this.togglePasswordThree;
    }
}

  public onIntegrationSubmit(integrationForm: any): void {
    this.validation = true;
    if (!integrationForm.form.valid) {
        return;
    }
    this.submitted = true;
    const payload = {
      procoreCompanyId:integrationForm.form.value.companyId,
      clientId: integrationForm.form.value.clientId,
      clientSecret: integrationForm.form.value.secretId,
      userId: localStorage.getItem('userId'),
      workSpaceName: sessionStorage.getItem('workspacename')
    }
    this.integrationApi.submitIntegration(payload).subscribe(
        (res): any => {
            this.submitted = false;
            this.validation = false;
            if (res.status.status === 230) {
                this.toastr.clear();
                this.toastr.error(res.status.msg);
            } else if (res.status.status === 200) {
              const integratePayload = {
                procoreCompanyId:integrationForm.form.value.companyId,
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename')
              }
              this.toastr.clear();
              integrationForm.form.reset();
              this.showIntegrationSettingsSideLayout=false;
              this.showIntegrationSideLayout=false;
              this.procoreIntegrationDone = true;
              this.integrationMenuShow = false;
              if(!this.selectedCompanyId) {
                this.integrationApi.addIntegration(integratePayload).subscribe(
                  (res): any => {
                    this.toastr.success('Procore integrated successfully'); 
                  }, (err) =>{
                    this.showIntegrationSettingsSideLayout=false;
                    this.showIntegrationSideLayout=false;
                    this.api.handleError(err);
                  })
              } else {
                this.updateIntegration = false;
                this.viewMode='Projects'
                this.selectedCompanyId = '';
                this.toastr.success('Procore updated successfully');
              }
            }
        },
        (err) => {
          this.api.handleError(err);
          this.submitted = false;
          this.validation = false;
        }
    );
  }

  public deleteIntegrationClick() {
    this.submitted = true;
    const payload = {
      procoreCompanyId:this.selectedCompanyId,
      userId: localStorage.getItem('userId'),
      workSpaceName: sessionStorage.getItem('workspacename')
    }
    this.integrationApi.deleteIntegration(payload).subscribe(
      (res): any => {
        this.submitted = false;
        this.updateIntegration = false;
        this.toastr.clear();
        this.toastr.success('Procore Deleted successfully');
        this.viewMode='Projects'
        this.selectedCompanyId = '';
        this.modalRef.hide();
        this.showIntegrationSettingsSideLayout=false;
        this.showIntegrationSideLayout=false;
        this.procoreIntegrationDone = false;
        this.integrationMenuShow = false;
        this.integrationFormInput = {
          companyId: '',
          clientId: '',
          secretId: '',
        };
      }, (err) =>{
        this.submitted = false;
        this.modalRef.hide();
        this.api.handleError(err);
      })
  }

  public forceUpdate(project) {
    this.submitted = true;
    const payload = {
      procoreProjectId:project.procoreProjectId,
      userId: localStorage.getItem('userId'),
      workSpaceName: sessionStorage.getItem('workspacename')
    }
    this.integrationApi.forceUpdate(payload).subscribe(
      (res): any => {
        this.getProjects();
        this.submitted = false;
        this.toastr.clear();
        this.toastr.success('Force updated successfully');
      }, (err) =>{
        this.submitted = false;
        this.api.handleError(err);
      })
  }

  public importPhotos(project, checked) {
    const payload = {
      procoreProjectId:project.procoreProjectId,
      userId: localStorage.getItem('userId'),
      workSpaceName: sessionStorage.getItem('workspacename'),
      importPhoto:checked
    }
    this.integrationApi.autoSync(payload).subscribe(
      (res): any => {
        this.toastr.clear();
        this.getProjects();
        if(checked) {
          this.toastr.success('Photos imported successfully');
        } else {
          this.toastr.success('Updated successfully');
        }
      }, (err) =>{
        this.api.handleError(err);
      })
  }

  public getProjects() {
    this.integrationApi.getProjects().subscribe(
      (res): any => {
        if(res && res.status.status === 200) {
          this.projectList = res.entity;
          this.integrationMenuShow = true;
          this.toastr.clear();
        }
      }, (err) =>{
        this.api.handleError(err);
      })
  }

  public openModal(): any {
    this.modalRef = this.modalService.show(
      this.integrationdeleteModal,
      Object.assign(this.config, { class: 'modal-sm popup-center' }),
    );
  }
}
