import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    OnDestroy,
    Input,
} from '@angular/core';
import { LoginService, UserService, ApiService } from 'src/app/services';
import { Router } from '@angular/router';
import { SupportService } from 'src/app/services/support.service';

import { environment } from '../../../environments/environment';
import { WorkspaceServiceService } from '../../services/workspace-service.service';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit, OnDestroy {
    public showSearchBar: boolean;

    public viewMode = 'Tags';

    public username = 'User';

    public profileSrc: any;

    public headerUserName = '';

    public headerProfileImage: any;

    public unsub: any;

    public unsub2: any;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public projectId: string;

    public getWorkspace: any = [];

    public chooseWS = false;

    public unReadCount = 0;

    public usertype = '';

    public usertypesub: any;

    public queryData = {};

    public workspacePendingList: any = [];

    public pending = false;

    public unsubpending: any;

    public ownerwscount = 0;

    @Input()
    set projectChange(projectChange: any) {
        this.projectId = projectChange;
        this.getNotificationsUnReadList();
    }

    @Output() private sidemenuToggle = new EventEmitter<boolean>();

    public constructor(private router: Router, public userService: UserService, private support: SupportService,
        private loginService: LoginService, private workspaceService: WorkspaceServiceService, private api: ApiService) {
        this.unsub = this.support.headerProfileImage.subscribe((res): any => {
            if (res) { this.headerProfileImage = res; }
        });

        this.unsub2 = this.support.notiunreadcount.subscribe((res): any => {
            this.unReadCount = res;
        });

        this.usertypesub = this.support.usertype.subscribe((res): any => {
            if (res) {
                this.usertype = res.toString();
            } else {
                this.usertype = sessionStorage.getItem('usertype');
            }
        });

        this.unsubpending = this.support.pendinginvite.subscribe((res): any => {
            this.pending = res;
            this.getAllWorkspacePendingInvites();
        });
    }

    public getNotificationsUnReadList(): void {
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&search=${''}&projectId=${sessionStorage.getItem('projectId') !== null ? sessionStorage.getItem('projectId') : ''}&pageNumber=${1}&pageRecord=${1}&sortBy=`;
        this.workspaceService.getProjectFeed(queryParams).subscribe((res): any => {
            if (res && res.status && res.status.status === 200 && res.entity) {
                this.unReadCount = +res.wscount;
            } else {
                this.unReadCount = 0;
            }
        });
    }

    public ngOnInit(): void {
        // this.api.checkUrlData();
        this.queryData = this.api.getSessionData();
        this.projectId = sessionStorage.getItem('projectId');
        this.headerProfileImage = './assets/images/empty-img.png';

        this.getProfile();
        this.getallWorkspace();
        this.getCount();
        if (this.usertype && (this.usertype === '1' || this.usertype === '2')) {
            this.getNotificationsUnReadList();
        }
        // this.getAllWorkspacePendingInvites();
    }

    public getallWorkspace(): void {
        this.workspaceService.getAllWorkspace(0, 0).subscribe(
            (res): any => {
                if (res && res.entity) {
                    this.getWorkspace = res.entity;
                    this.ownerwscount = res.wscount;
                }
            },
            (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public chooseWSToogle(): any {
        this.getAllWorkspacePendingInvites();
        this.chooseWS = !this.chooseWS;
        if (this.chooseWS) {
            this.getallWorkspace();
        }
    }

    public sidebarToggle(): void {
        this.sidemenuToggle.emit(true);
    }

    public showSearch(): void {
        this.showSearchBar = !this.showSearchBar;
    }

    public ngOnDestroy(): any {
        this.unsub.unsubscribe();
        this.unsub2.unsubscribe();
        this.usertypesub.unsubscribe();
        // this.unsubpending.unsubscribe();
    }

    public logout(): any {
        this.loginService.logout('').subscribe(
            (res): any => { },
        );
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    public getProfile(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        this.userService.viewProfile(payload).subscribe(
            (res): any => {
                if (res.entity.profileImage) {
                    this.support.headerProfileImage.next(this.cloudFrontURL + res.entity.profileImage);
                } else {
                    this.support.headerProfileImage.next(this.headerProfileImage);
                }
                if (res.entity.firstName) {
                    this.headerUserName = `${res.entity.firstName} ${res.entity.lastName}`;
                }
            }, (err): any => {
                this.someThing();
            },
        );
    }

    public someThing(): void {
        localStorage.clear();
        setTimeout(() => {
            this.router.navigate(['/login']);
        }, 1000);
    }

    public getCount(): void {
        this.userService.getCount().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                if (res.entity.currentPlan === 'PRO') {
                    sessionStorage.setItem('subscription', '2');
                    this.support.subscription.next(2);
                } else {
                    sessionStorage.setItem('subscription', '1');
                    this.support.subscription.next(1);
                }
            } else {
                sessionStorage.setItem('subscription', '1');
                this.support.subscription.next(1);
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public getAllWorkspacePendingInvites(): void {
        this.userService.getWorkspacePendingList().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.pending = true;
                this.workspacePendingList = res.entity;
            } else {
                this.workspacePendingList = [];
                this.pending = false;
                // this.support.pendinginvite.next(this.pending);
            }
        })
    }
}
