<div class="btn-group" dropdown placement="bottom right" #searchdropdown="bs-dropdown" [insideClick]="true" >    
    <div class="input-group rounded-search-input mb-0 c-pointer" >
      <input type="text" class="form-control fw-600 py-2  border-right-0" (focus) = "clickingEvent()" [(ngModel)]="globalSearch" placeholder="Search"
        (keyup.enter)="searchGlobal()">
      <div class="input-group-append" *ngIf="globalSearch !== ''">
        <span class="input-group-text border-left-0 p-1" (click)="globalSearch = '';searchGlobal()">
          <span class="lnr lnr-cross fs16"></span>
        </span>
      </div>
      <div class="input-group-append">
        <span class="input-group-text border-left-0" (click)="searchGlobal()">
          <img src="./assets/images/Search-icon.svg" alt="search" class="w14">
        </span>
      </div>
    </div>

    <ng-container *ngIf="searchHistoryList.length > 0">
        <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu custom-drops-dows dropdown-menu-right py-2"
            role="menu" aria-labelledby="button-triggers-manual">
            <li role="menuitem"><a class="dropdown-item text-right red-d" href="javascript:void(0)" (click)="deleteHistory('')">Clear all</a></li>
            <li class="divider dropdown-divider my-1"></li>
            <li role="menuitem" *ngFor="let history of searchHistoryList">
            <a class="dropdown-item d-flex align-items-center text-truncate fs14" href="javascript:void(0)">
                <span class="w-80 d-inline-block text-truncate" (click)="selectHistory(history)">{{history.searchData}}</span>
                <span class="lnr lnr-cross ml-auto red-d" (click)="deleteHistory(history)"></span>
            </a>
            </li>
        </ul>    
    </ng-container>
  </div>