<header class="main-nav">
  <nav class="navbar  navbar-expand-lg fixed-top">
    <span class="mx-3  fs22 lnr lnr-menu header-lnr-icon" (click)="sidebarToggle()"></span>
    <a class="navbar-brand c-pointer" (click)="navigateToHome()"><img src="./assets/images/Logo_PNG.png" alt="logo"></a>

    
    <ul class="list-inline header-list ml-auto my-auto" *ngIf="projectId">
       
      <li class="list-inline-item px-2 px-sm-4 px-md-4 mx-lg-4 py-1" routerLinkActive="active">
        <a routerLink='/project/feed' [queryParams]="conCadSessionData({})">
          <div class="header-list-icon">
            <span class="active-img">
              <img src="./assets/images/feed-active.svg" alt="feed">
            </span>
            <span class="inactive-img">
              <img src="./assets/images/feed.svg" alt="feed">
            </span>
          </div>
          <span class="fw-600 fs14">Feed</span>
        </a>
      </li>
      <li class="list-inline-item px-2 px-sm-4 px-md-4 mx-lg-4 py-1" routerLinkActive="active">
        <a routerLink="/project/albums" [queryParams]="conCadSessionData({})">
          <div class="header-list-icon">
            <span class="active-img">
              <img src="./assets/images/album-active.svg" alt="album">
            </span>
            <span class="inactive-img">
              <img src="./assets/images/album.svg" alt="album">
            </span>
          </div>
          <span class="fw-600 fs14">Album</span>
        </a>
      </li>
      <!-- <li class="list-inline-item px-2 px-sm-4 px-md-4 mx-lg-4 py-1" routerLinkActive="active">
        <a routerLink="/project/shared">
          <div class="header-list-icon">
            <span class="active-img">
              <img src="./assets/images/shared-active.svg" alt="shared">
            </span>
            <span class="inactive-img">
              <img src="./assets/images/shared.svg" alt="shared">
            </span>
          </div>
          <span class="fw-600 fs14">Shared</span>
        </a>
      </li> -->





    </ul>
    <a class="project-show ml-auto">

      <em class="fas fa-project-diagram " (click)="projectBarToggle()"></em>

    </a>
    <ul class="list-inline ml-auto  header-sub-list my-auto ">
      <li class="list-inline-item pr-2" [class.invisible]="cheking(router.url)">
        <app-search-field></app-search-field>
      </li>
      <li class="list-inline-item ">
        <a>
          <div dropdown class="header-profile header-menu c-pointer">
            <img src="{{headerProfileImage}}" class="header-profile-img" alt="profile-header" id="basic-link"
              dropdownToggle (click)="false" aria-controls="basic-link-dropdown">

            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
              <li>
                <a class="dropdown-item text-black fs12 fw-600 mb-1 mr-4 " routerLink="/settings" [queryParams]="conCadSessionData({})">
                  <img src="./assets/images/settings-dropdown.svg" class="mr-3 w14" alt="settings">Settings</a>
                <a class="dropdown-item text-black fs12 fw-600 mb-1 mr-4" routerLink="/help"[queryParams]="conCadSessionData({})" href="javascript:void(0)">
                  <img src="./assets/images/help-dropdown.svg" class="mr-3 w15" alt="help">Help</a>
                <a class="dropdown-item red-e fs12 fw-600 mb-1 mr-4 c-pointer" (click)="logout()">
                  <img src="./assets/images/logout-dropdown.svg" class="mr-3 w13" alt="logout">Logout</a>
              </li>
            </ul>
          </div>
          <div>
          </div>
        </a>
      </li>
    </ul>

  </nav>
</header>
<div id="overlay" [ngClass]="{'d-block': showSearchBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showSearchBar}">
  <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 "><img src="./assets/images/left-facing-arrow.svg" alt="left-arrow"
      class="mr-2 w14 mb-1 c-pointer" (click)="showSearch()">Search</h1>

  <form class="mt-5">
    <div class="input-group input-todo ">
      <input type="text" class="form-control fw-600 pt17 pb17  border-right-0 " placeholder="Search">
      <div class="input-group-append">
        <span class="input-group-text border-left-0 ">
          <img src="./assets/images/search-todo.svg" alt="search" class="w13 c-pointer">

        </span>
      </div>
    </div>
  </form>
  <ul class="nav nav-pills pills-secondry  my-3" id="pills-tab" role="tablist">
    <li class="nav-item " role="presentation">
      <a [class.active]="viewMode=='Posts'" (click)="viewMode='Posts'" class="nav-link fs13 px-2 px-sm-3"
        href="javascript:void(0)">
        Posts</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Albums'" (click)="viewMode='Albums'" class="nav-link  fs13 px-2 px-sm-3"
        href="javascript:void(0)">
        Albums</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Memebers'" (click)="viewMode='Memebers'" class="nav-link  px-2 px-sm-3 fs13"
        href="javascript:void(0)">
        Members</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Tags'" (click)="viewMode='Tags'" class="nav-link px-2 px-sm-3 fs13"
        href="javascript:void(0)">
        Tags</a>
    </li>

  </ul>
  <div class="tab-content pb-3" [ngSwitch]="viewMode">
    <div class="tab-pane fade show active" *ngSwitchCase="'Posts'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Albums'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Memebers'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Tags'">
      <div class="contact-scroll">



        <div class="d-flex pt10 pb10 align-items-center" (click)="showTag()">

          <div class="tag-types w19 h19 pink-a">

          </div>
          <div class="flex-grow-1 ml-3">
            <p class="grey-g ls-9 fs14 mb-0 fw-500">Comments</p>
          </div>
        </div>
        <div class="d-flex pt10 pb10 align-items-center" (click)="showTag()">

          <div class="tag-types  w19 h19 yellow-a">

          </div>
          <div class="flex-grow-1 ml-3">
            <p class="grey-g ls-9 fs14 mb-0 fw-500">Task</p>
          </div>
        </div>
        <div class="d-flex pt10 pb10 align-items-center" (click)="showTag()">

          <div class="tag-types  w19 h19 blue-f">

          </div>
          <div class="flex-grow-1 ml-3">
            <p class="grey-g ls-9 fs14 mb-0 fw-500">Hot Potato</p>
          </div>
        </div>
        <div class="d-flex pt10 pb10 align-items-center" (click)="showTag()">

          <img src="./assets/images/love-tag.svg" alt="love-tag" class="w19">
          <div class="flex-grow-1 ml-3">
            <p class="grey-g ls-9 fs14 mb-0 fw-500">Love</p>
          </div>
        </div>
      </div>

      <!-- if content is empty -->
      <div class="px-3 text-center mt100">
        <img src="./assets/images/search-empty.svg" class="w-100" alt="search-empty">
        <h1 class="grey-g fs15 fw-600 ls-f mb-0">We couldn't find result for<br> your search</h1>
      </div>

    </div>
  </div>
</div>