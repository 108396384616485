<aside class="sidemenu-main">
  <span class="lnr lnr-cross text-white fs24 ml-auto mr-3 d-lg-none" (click)="sidebarToggle()"></span>
  <div class="d-flex ml-4 my-2">
    <div class="header-profile mr-2">
      <img src="{{headerProfileImage}}" alt="profile-header" class="header-profile-img">

    </div>
    <div class="flex-grow-1 my-auto">
      <h1 title={{headerUserName}} class="fs13 grey-g mb-0 fw-600">{{headerUserName | ngXtruncate:18:'...'}}</h1>
      <p class="fs10 green-a mb-0 fw-600">Online</p>
    </div>
  </div>
  <ul class="list-group sidemenu-list  pt-1">

    <span *ngIf="!projectId">
    <h2 class="fs11 grey-g fw-600 text-uppercase ml-3 ls-5 my-2">Manage Workspace</h2>
    <li class="list-group-item" routerLinkActive="active" >
      <a class="menu-icon" href="javascript:void(0)" routerLink='/company/dashboard'
      [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/analytics.svg" alt="analytics">
        <span class="menu-txt">Workspace Dashboard</span>
      </a>
    </li>

    <li class="list-group-item d-flex justify-content-between pr-2  align-items-center" routerLinkActive="active" >
      <a class="menu-icon" href="javascript:void(0)" routerLink='/company/notification' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/notification.svg" alt="notification">
        <span class="menu-txt">Notification</span>
      </a>
      <div class="notification-content" *ngIf="unReadCount">
        <p class="mb-0 fs9 ls-a py-1 px-2">{{unReadCount}} Unread</p>
      </div>
    </li>
    <li class="list-group-item" routerLinkActive="active" >
      <a class="menu-icon" href="javascript:void(0)" routerLink='/company/people' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/people.svg" alt="people">
        <span class="menu-txt">People</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active" *ngIf="usertype && usertype === '1'">
      <a routerLink="/company/workspace-settings" [queryParams]="queryData" href="javascript:void(0)" class="menu-icon">
        <img src="./assets/images/sidemenu-icons/workspace-settings.svg" class="work-icon" alt="workspace-settings">
        <span class="menu-txt">Workspace Settings</span>
      </a>
    </li>
  </span>

  <span *ngIf="projectId">
    <h2 class="fs11 grey-g fw-600 text-uppercase ml-3 ls-5 my-2">Project Navigation</h2>
    <li class="list-group-item" routerLinkActive="active" >
      <a class="menu-icon" href="javascript:void(0)" routerLink='/project/dashboard' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/analytics.svg" alt="analytics">
        <span class="menu-txt">Project Dashboard</span>
      </a>
    </li>
    <li class="list-group-item d-flex justify-content-between pr-2  align-items-center" routerLinkActive="active" *ngIf="usertype && usertype !== '5'">
      <a class="menu-icon" href="javascript:void(0)" routerLink='/project/notification' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/notification.svg" alt="notification">
        <span class="menu-txt">Notifications</span>
      </a>
      <div class="notification-content" *ngIf="unReadCount > 0">
        <p class="mb-0 fs9 ls-a py-1 px-2">{{unReadCount}} Unread</p>
      </div>
    </li>
    <li class="list-group-item" routerLinkActive="active" *ngIf="usertype && usertype !== '5'">
      <a class="menu-icon" href="javascript:void(0)" routerLink='/project/people' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/people.svg" alt="people">
        <span class="menu-txt">People</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active">
      <a class="menu-icon" href="javascript:void(0)" routerLink="/project/tag-library" [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/tag-library.svg" alt="tag-library">
        <span class="menu-txt">Tag Library</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active" *ngIf="usertype && usertype !== '5'">
      <a class="menu-icon" href="javascript:void(0)" routerLink="/project/todo-list" [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/todo.svg" alt="todo">
        <span class="menu-txt">To-do</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active">
      <a class="menu-icon" href="javascript:void(0)" routerLink='/your-invites' *ngIf="pending">
        <img src="./assets/images/sidemenu-icons/your-invite.svg" alt="app-settings">
        <span class="menu-txt">Pending Invites</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active" *ngIf="usertype && (usertype === '1' || usertype === '2')">
      <a routerLink="/company/dashboard" href="javascript:void(0)" [queryParams]="queryData" class="menu-icon">
        <img src="./assets/images/sidemenu-icons/logout.svg" alt="Back to company">
        <span class="menu-txt">Back to Workspace</span>
      </a>
    </li>
  </span>
    <h2 class="fs11 grey-g fw-600 ml-3 ls-5 my-2 text-uppercase">PIXLY</h2>

    <li class="list-group-item" routerLinkActive="active">
      <a class="menu-icon" href="javascript:void(0)" routerLink='/settings' [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/settings.svg" alt="app-settings">
        <span class="menu-txt">Settings</span>
      </a>
    </li>

    <li class="list-group-item" routerLinkActive="active" >
      <a class="menu-icon c-pointer" (click)="chooseWSToogle()">
        <img src="./assets/images/sidemenu-icons/switch.svg" alt="switch">
        <span class="menu-txt">Switch Workspace</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active">
      <a class="menu-icon" href="javascript:void(0)" routerLink="/help" [queryParams]="queryData">
        <img src="./assets/images/sidemenu-icons/help.svg" alt="help">
        <span class="menu-txt">Help</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active" *ngIf="usertype && (usertype === '1' || usertype === '2')">
      <a class="menu-icon" href="javascript:void(0)" routerLink="/project/integrations">
        <img src="./assets/images/sidemenu-icons/integrations.svg" alt="integrations">
        <span class="menu-txt">Integrations</span>
      </a>
    </li>
    <li class="list-group-item" routerLinkActive="active" >
      <a class="menu-icon c-pointer" (click)="logout()">
        <img src="./assets/images/sidemenu-icons/logout.svg" alt="logout">
        <span class="menu-txt">Logout</span>
      </a>
    </li>

  </ul>

</aside>
<div id="overlay" [ngClass]="{'d-block': showSearchBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showSearchBar}">
  <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 "><img src="./assets/images/left-facing-arrow.svg" alt="left-arrow"
      class="mr-2 w14 mb-1 c-pointer" (click)="showSearch()">Search</h1>

  <form class="mt-5">
    <div class="input-group input-todo ">
      <input type="text" class="form-control fw-600 pt17 pb17  border-right-0 " placeholder="Search">
      <div class="input-group-append">
        <span class="input-group-text border-left-0 ">
          <img src="./assets/images/search-todo.svg" alt="search" class="w13 c-pointer">

        </span>
      </div>
    </div>
  </form>
  <ul class="nav nav-pills pills-secondry  my-3" id="pills-tab" role="tablist">
    <li class="nav-item " role="presentation">
      <a [class.active]="viewMode=='Posts'" (click)="viewMode='Posts'" class="nav-link fs13 px-2 px-sm-3"
        href="javascript:void(0)">
        Posts</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Albums'" (click)="viewMode='Albums'" class="nav-link  fs13 px-2 px-sm-3"
        href="javascript:void(0)">
        Albums</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Memebers'" (click)="viewMode='Memebers'" class="nav-link  px-2 px-sm-3 fs13"
        href="javascript:void(0)">
        Members</a>
    </li>
    <li class="nav-item" role="presentation">
      <a [class.active]="viewMode=='Tags'" (click)="viewMode='Tags'" class="nav-link px-2 px-sm-3 fs13"
        href="javascript:void(0)">
        Tags</a>
    </li>

  </ul>
  <div class="tab-content pb-3" [ngSwitch]="viewMode">
    <div class="tab-pane fade show active" *ngSwitchCase="'Posts'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Albums'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Memebers'">
    </div>
    <div class="tab-pane fade show active" *ngSwitchCase="'Tags'">
      <div class="contact-scroll">



        <div class="d-flex py-2 align-items-center" (click)="showTag()">

          <div class="tag-types w19 h19 pink-a">

          </div>
          <div class="flex-grow-1 ml-3">
            <h1 class="grey-g ls-9 fs14 mb-0 fw-500">Comments</h1>
          </div>
        </div>
        <div class="d-flex py-2 align-items-center" (click)="showTag()">

          <div class="tag-types  w19 h19 yellow-a">

          </div>
          <div class="flex-grow-1 ml-3">
            <h1 class="grey-g ls-9 fs14 mb-0 fw-500">Task</h1>
          </div>
        </div>
        <div class="d-flex py-2 align-items-center" (click)="showTag()">

          <div class="tag-types  w19 h19 blue-f">

          </div>
          <div class="flex-grow-1 ml-3">
            <h1 class="grey-g ls-9 fs14 mb-0 fw-500">Hot Potato</h1>
          </div>
        </div>
        <div class="d-flex py-2 align-items-center" (click)="showTag()">

          <img src="./assets/images/love-tag.svg" alt="love-tag" class="w19">
          <div class="flex-grow-1 ml-3">
            <h1 class="grey-g ls-9 fs14 mb-0 fw-500">Love</h1>
          </div>
        </div>
      </div>

      <!-- if content is empty -->
      <div class="px-3 text-center mt100">
        <img src="./assets/images/search-empty.svg" class="w-100" alt="search-empty">
        <h1 class="grey-g fs15 fw-600 ls-f mb-0">We couldn't find results for<br> your search</h1>
      </div>

    </div>
  </div>
</div>
<app-layout-workspace *ngIf="chooseWS" [toggleWS]="chooseWS" [ownerworkspace]="ownerwscount" [workspaceObject]="getWorkspace" (workspacetoggle)="chooseWSToogle()"></app-layout-workspace>
