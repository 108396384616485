import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    SimpleChanges,
    Output,
    } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from '../../services/workspace-service.service';
import { SupportService } from '../../services/support.service';
import { ApiService } from '../../services/api_base/api.service';
import { GeolocationService } from '../../services/geolocation.service';
import { NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
    selector: 'app-sidenav-right',
    templateUrl: './sidenav-right.component.html',
    styles: [`
      
    .input-container {
      position: relative;
    }
    
    .predictions {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      font-size: 13px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
    }
    
    .prediction-item {
      padding: 8px;
      cursor: pointer;
    }
    
    .prediction-item:hover {
      background-color: #f0f0f0;
    }
    .position-relative {
        position: relative;
      }
      
      .location-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        color: green; /* Customize the color of the check icon */
        cursor: pointer;
      }

      .loading-icon {
        position: absolute;
        top: 35%;
        right: 8px;
        transform: translateY(-50%);
        color: green; /* Customize the color of the check icon */
        cursor: pointer;
      }

      .input-with-color {
        position: relative;
        display: inline-block;
        width: 100%;
      }
      
      .input-with-color input[type="color"] {
        position: absolute;
        top: 50%;
        right: 12px;
        height: 30px;
        width: 30px;
        background: none;
        border: none;
        transform: translateY(-50%);
      }
      `]
})

export class SidenavRightComponent implements OnInit {

    @Input() public showToggle: boolean;

    @Input() public pageFrom = '';

    @Output() public showworkspaceevent = new EventEmitter<boolean>();

    public submitted = false;

    public showWorkspace: boolean;

    @Input() public showCreate: any;

    public showWorkspaceSub = true;

    public userId = localStorage.getItem('userId');

    public workspaceForm: FormGroup;

    public globalTagForm: FormGroup;

    public createWorkspace: any = {};

    public createProject: any = {};

    public projectForm: FormGroup;

    public showChooseWorkspace: boolean;

    public getWorkspace = [];

    // public workspaceOrProject: boolean;

    // public showProjectCreate = false;

    public getProject = [];

    public selectedWorkspaceName: any;

    public usertype = '';

    public latitude: any;

    public longitude: any;

    public address: any;

    predictions: any[] = [];
    selectedPlace: any = null;
    selectedPlaceText: string = '';

    currentLocation = false;

    // showTagsSideNav = false;

    selectedColor: any;

    // showTagsList = false;

    tagslist = [];

    currentNav = ''

    filteredWrkspace = []

    searchTerm = ''

    searchProject = ''

    filteredProjects = []

    @Input()
    public set workSpaceEntity(workSpaceEntity: any) {
        this.setValue(workSpaceEntity);
    }

    public constructor(private toastr: ToastrService, private router: Router, private workspaceService: WorkspaceServiceService,
        private support: SupportService, private api: ApiService, private geolocationService: GeolocationService) {
        this.createWorkspace = { workSpaceName: '', tagColor: '' };
        this.createProject = { teamName: '' };
    }

    public ngOnInit(): void {
        this.selectedWorkspaceName = sessionStorage.getItem('workspacename');
        this.getLocation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const showCreateChange = changes.showCreate;
      
        if (showCreateChange) {
          const currentValue = showCreateChange.currentValue;
      
          if (currentValue === 'choose' || currentValue === 'create') {
            this.currentNav = currentValue;
            this.searchTerm = null
            this.filteredWrkspace = this.getWorkspace;
            this.createWorkspace = {}
          } else if (currentValue === 'workspace') {
            this.showworkspaceevent.emit(this.showWorkspace);
          }
        }
      }

    public Toggleworkspacepassword(): any {
        this.showworkspaceevent.emit(this.showWorkspace);
    }

    public Toggleworkspacepassword1(): any {
        this.showworkspaceevent.emit(this.showWorkspace);
    }

    public ToggleChooseWorkspace(): any {
        this.showChooseWorkspace = !this.showChooseWorkspace;
    }

    public ToggleWorkSpace(): any {
        this.showCreate = true;
        this.getLocation();
    }

    public ToggleProject(): any {
        // this.showProjectCreate = true;
        // this.showTagsList = false
        // this.showTagsSideNav = true
        // this.showTagsSideNav = false;
    }

    public setValue(data): void {
        // this.workspaceOrProject = false;
        if (data && data.length > 0) {
            this.getWorkspace = data;
            this.filteredWrkspace = this.getWorkspace;
        }
    }

    public closeProject(): void {
        // this.currentNav = 'showAllTagsList'
        this.showworkspaceevent.emit(this.showWorkspace);
        this.getallWorkspace();
    }

    public onSubmit(workspaceForm: any): void {
        this.submitted = true;
        const payload = {
            userId: this.userId,
            workSpaceName: this.createWorkspace.workSpaceName,
        };
        this.toastr.success(`We're working on your workspace. Please hang on.`);
        this.workspaceService.addWorkspace(payload).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    sessionStorage.setItem('workspacename', res.entity.dbName);
                    localStorage.setItem('tenantId', res.entity.tenantId);
                    this.toastr.success('Workspace created');
                    this.currentNav = 'createProject'
                    this.submitted = false;
                    this.getUserType('create');
                } else {
                    this.submitted = false;
                    // this.showTagsSideNav = false;
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    public getallWorkspace(): void {
        this.workspaceService.getAllWorkspace(0, 0).subscribe(
            (res): any => {
                if (res && res.entity) {
                    this.getWorkspace = res.entity;
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public selectWorkSpace(workspace): void {       
        if (workspace && workspace.masterTenant && workspace.masterTenant.workspaceLock === false && !this.submitted) {
            this.submitted = true;
            this.createWorkspace.workSpaceName = workspace.masterTenant.dbName;
            sessionStorage.setItem('workspacename', workspace.masterTenant.dbName);
            this.getUserType('select');
        }
        if (workspace && workspace.masterTenant && workspace && workspace.masterTenant.tenantId) {
            localStorage.setItem('tenantId', workspace.masterTenant.tenantId);
        }
        localStorage.removeItem('companyName');
        if (workspace.masterTenant.companyName) {
            localStorage.setItem('companyName', workspace.masterTenant.companyName);
        }
        if (workspace && workspace.masterTenant && workspace.masterTenant.workspaceLock === true) {
            this.toastr.error('Your workspace has been locked due to failed payment. Please upgrade your billing details to access your workspace');
        }
    }

    public getUserType(value): any {
        this.workspaceService.getUserRoleType1('').subscribe((res): any => {
            if (res && res.entity) {
                this.usertype = res.entity.roleId;
                if (res.entity && res.entity.roleId && (res.entity.roleId === 1 || res.entity.roleId === 2)) {
                    sessionStorage.setItem('usertype', res.entity.roleId);
                    if (value === 'create') {
                        this.getAllProjects();
                    } else {
                        this.submitted = false;
                        this.createWorkspace.workSpaceName = '';
                        this.support.usertype.next(res.entity.roleId);
                        this.router.navigate(['/company/dashboard'], { queryParams: this.api.getSessionData() });
                    }
                } else {
                    sessionStorage.removeItem('usertype');
                    this.getAllProjects();
                }
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public getAllProjects(): void {
        const queryParams = `?userId=${this.userId}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.workspaceService.getAllProject(queryParams).subscribe((res): any => {
            this.createWorkspace.workSpaceName = '';
            // this.workspaceOrProject = true;
            this.submitted = false;
            this.showCreate = false;
            if (res.entity === null) {
                // this.showProjectCreate = true;
                this.filteredProjects = []
            } else {
                this.currentNav = 'showAllProjects';
                this.getProject = res.entity;
                this.filteredProjects = this.getProject
                // this.showProjectCreate = false;
            }
        }, (err): any => {
            this.submitted = false;
            // this.workspaceOrProject = true;
            this.api.handleError(err);
        });
    }


    public getFirstAndLastLetter(name): string {
        let value = 'Ws';
        if (name) {
            const split = Array.from(name);
            value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
        }
        return value;
    }

    public chooseProject(project): void {
        if (project) {
            sessionStorage.setItem('projectId', project.teamId);
            this.support.projectChange.next(project.teamId);
            this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
                (res): any => {
                    if (res && res.entity) {
                        if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                            sessionStorage.setItem('usertype', res.entity.roleId);
                            if (res.entity.roleId === 3) {
                                this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
                            } else {
                                this.router.navigate(['/project/feed'], { queryParams: this.api.getSessionData() });
                            }
                        }
                    }
                },
            );
        }
    }

    public createProjectMethod(projectForm: any): void {
        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamName: this.createProject.teamName,
            lattitude: this.latitude,
            longitude: this.longitude,
            address: this.selectedPlaceText
        };
        this.toastr.clear();
        this.workspaceService.addProject(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.createProject.teamName = '';
                    this.toastr.success('Project created successfully');
                    sessionStorage.setItem('projectId', res.entity.teamId);
                    if (sessionStorage.getItem('usertype')) {
                        this.support.usertype.next(+sessionStorage.getItem('usertype'));
                        this.router.navigate(['/company/dashboard'], { queryParams: this.api.getSessionData() });
                    } else {
                        this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    async onSearch(query: string) {
        if (query.trim().length > 0) {
          try {
            this.predictions = await this.geolocationService.searchPlaces(query);
          } catch (error) {
            console.error('Error fetching predictions:', error);
          }
        } else {
          this.predictions = [];
        }
      }

    getLocation() {
        if (navigator.geolocation) {
            this.currentLocation = true;
            navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    const { latitude, longitude } = position.coords;
                    if (latitude && longitude) {
                        this.geolocationService
                            .reverseGeocode(latitude, longitude)
                            .then((address) => {
                                this.latitude = latitude;
                                this.longitude = longitude;
                                this.selectedPlaceText = address;
                                this.currentLocation = false;
                            });
                    }
                },
                (error: any) => {
                    this.currentLocation = false;
                } 
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }
    
    async onPlaceSelected(prediction: any) {
        try {
            
            this.selectedPlace = await this.geolocationService.getPlaceDetails(prediction.place_id);
            this.selectedPlaceText = prediction.description;
            this.latitude = this.selectedPlace.geometry?.location?.lat();
            this.longitude = this.selectedPlace.geometry?.location?.lng();
            this.predictions = [];
        } catch (error) {
            console.error('Error fetching place details:', error);
        }
    }

    updateColor () {
        if (this.createWorkspace.tagColor.match(/^#([0-9a-f]{6})$/)) {
            // Update color picker value
          } else if (this.createWorkspace.tagColor.match(/^#([0-9a-f]{3})$/)) {
            // Convert and update color picker value
          }
    }

    createTag (globalTagForm: NgForm) {
        this.submitted = true;
        const payload = {
            tagName: globalTagForm.value.tagName,
            purpose: globalTagForm.value.tagPurpose,
            color: globalTagForm.value.tagColor
        };
        this.toastr.clear();
        const userId = localStorage.getItem('userId')
        const tenantId = localStorage.getItem('tenantId');
        this.workspaceService.addTagsForTenant(userId, tenantId, payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.tagId) {
                    this.toastr.success('Tag created successfully');
                    this.getAllTagsById()
                    globalTagForm.reset()
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    getAllTagsById () {
        const userId = localStorage.getItem('userId')
        const tenantId = localStorage.getItem('tenantId');
        this.tagslist = [];
        if (userId && tenantId) {
            this.workspaceService.getAllTagsForWorkspace(userId, tenantId).subscribe(
                (res): any => {
                    if (res) {
                        this.tagslist = res;
                    } else {
                        // this.toastr.error('No Tags found');
                    }
                },
                (err): any => {
                    this.api.handleError(err);
                },
            );
        }
    }

    gotoTagList () {
        // this.currentNav = 'showAllTagsList';
        this.getAllTagsById();
    }

    closeTagsSideNav () {
        // this.getAllTagsById()
        this.showworkspaceevent.emit(this.showWorkspace);
        // this.currentNav = 'showAllTagsList';
    }

    gotoCreateTag () {
        this.currentNav = 'createNewTag';
    }

    public getFirstAndLastLetterForTag(name): string {
        let value = 'TG';
        if (name) {
            const split = Array.from(name);
            value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
        }
        return value;
    }

    // selectedTagMethod(tag) {
    //     const tagIndex = this.selectedTags.findIndex(selectedTag => selectedTag.tagId === tag.tagId);
    //     if (tagIndex === -1) {
    //       this.selectedTags.push(tag);
    //     } else {
    //       this.selectedTags.splice(tagIndex, 1);
    //     }
    // }

    // isSelected(tag) {
    //     return this.selectedTags.some(selectedTag => selectedTag.tagId === tag.tagId);
    // }

    gotoCreateProject() {
        // this.submitted = true;
        // if (this.tagslist && this.tagslist.length) {
        //     const tagIds = this.tagslist.map(selectedTag => selectedTag.tagId);
        //     const userId = localStorage.getItem('userId');
        //     const tenantId = localStorage.getItem('tenantId');
        //     const payload = { globalTagIds: tagIds };
        //     if (userId && tenantId && payload) {
        //         this.workspaceService.saveGlobalTagsForTenant(userId, tenantId, payload).subscribe(
        //             (res): void => {
        //                 this.submitted = false;
        //                 if (res && res.length) {
        //                     this.toastr.success('Tags added successfully');
        //                     this.currentNav = 'createProject';
        //                 } else {
        //                     this.toastr.error(res.status.msg);
        //                 }
        //             },
        //             (err): void => {
        //                 this.submitted = false;
        //                 this.api.handleError(err);
        //             }
        //         );
        //     }
        // } else {
        //     this.submitted = false;
        //     this.currentNav = 'createProject';
        // }
        this.currentNav = 'createProject';
    }

    removeTag(tag) {
        if (tag) {
            const userId = localStorage.getItem('userId');
            const tenantId = localStorage.getItem('tenantId');
    
            // Assuming workspaceService.removeTagForWorkspace is the method to remove a tag
            this.workspaceService.removeTagForWorkspace(userId, tenantId, tag.tagId).subscribe(
                (res): any => {
                    // Remove the tag from the tagslist array
                    const index = this.tagslist.findIndex(t => t.tagId === tag.tagId);
                    if (index !== -1) {
                        this.tagslist.splice(index, 1);
                    }
                },
                (err): any => {
                    this.api.handleError(err);
                }
            );
        }
    }

    filterWorkspaces(searchTerm: string): void {
        if (!this.searchTerm) {
          this.filteredWrkspace = this.getWorkspace;
        } else {
          this.filteredWrkspace = this.getWorkspace.filter(workspace =>
            workspace.masterTenant.workSpaceName.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
    }
    
    filterProjects() {
        if (this.searchProject === '') {
            this.filteredProjects = this.getProject; // Show all projects when the search is empty
        } else {
            this.filteredProjects = this.getProject.filter(project =>
                project.teamName.toLowerCase().includes(this.searchProject.toLowerCase())
            );
        }
    }

    onSearchInput(searchValue: string): void {
        from([searchValue])
        .pipe(debounceTime(300))
        .subscribe((debouncedValue: string) => {
            this.searchProject = debouncedValue;
            this.filterProjects();
        });
    }

    // shouldShowButton(): boolean {
    //     const userEmail = localStorage.getItem('email');
    
    //     return (
    //         (this.isGetCarneraUser() && !this.isClientUser()) ||
    //         (this.isClientUser() && !this.isGetCarneraUser() && (this.isAllowedClientUser() || this.isKitchenSaverUser() || this.isBathSaverUser(userEmail)))
    //     );
    // }

    shouldShowButton(): boolean {
        const userEmail = localStorage.getItem('email');
    
        const isAllowedClientUser = this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    
        return (
            isAllowedClientUser ||   // If the user is in the allowed lists
            (userEmail && !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !userEmail.toLowerCase().endsWith('@bathsaver.com'))
            // If the user's domain is not kitchensaver.com or bathsaver.com
        );
    }
    
    isClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.isClientUser(userEmail) || this.isBathSaverUser(userEmail);
    }
    
    isGetCarneraUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !this.isBathSaverUser(userEmail);
    }
    
    isKitchenSaverUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return userEmail.toLowerCase().endsWith('@kitchensaver.com');
    }
    
    isBathSaverUser(userEmail: string): boolean {
        return userEmail.toLowerCase().endsWith('@bathsaver.com');
    }
    
    isAllowedClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    }
    
    

}
