<app-sub-header></app-sub-header>
<section class="main-container" id="company-profile">
    <div>
        <div class="sub-content-container pt15 pb15">
            <div class="content-card border-grey shadow-none">
                <div>
                    <div class="card-header px-4 mb-2 pb-0 bg-white border-0">
                        <div class="d-sm-flex d-block align-items-center justify-content-between mt10">
                            <div class="mb-2 mb-sm-0">
                                <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Manage {{companyName ? companyName : 'Companies'}}</h1>
                            </div>
                            <div>
                                <button class="btn btn-blue br25 py-2 px-sm-4 mr-sm-3 fs12" *ngIf="isSuperAdmin" (click)="showAddCompanyBar = true">Add
                                    Company</button>
                                <button class="btn btn-blue br25 py-2 px-sm-4 mr-sm-3 fs12"
                                    (click)="toggleNav('choose')">Go to Workspace</button>
                            </div>
                        </div>
                        <div *ngIf="isSuperAdmin">
                            <div class="form-group input-rounded"
                                style="width: 15%; margin-bottom: 0px; margin-top: 12px;">
                                <select class="form-control input-group-text"
                                    style="height: 40px; margin: 0px; cursor: pointer;" [(ngModel)]="selectedCompanyId"
                                    name="companyName" (change)="onCompanyChange()">
                                    <option *ngFor="let company of companyList" value={{company.companyId}}>
                                        {{company.name}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Tab Navigation -->
                        <div class="tab-navigation mt20">
                            <button class="tab-button" [ngClass]="{ 'active': activeTab === 'workspace' }" (click)="setActiveTab('workspace')">Workspace</button>
                            <button class="tab-button" [ngClass]="{ 'active': activeTab === 'people' }" (click)="setActiveTab('people')">People</button>
                        </div>
                    </div>

                    <!-- Tab Content -->
                    <div class="tab-content">
                        <!-- Workspace Tab Content -->
                        <div *ngIf="activeTab === 'workspace'">
                            <app-company-workspace></app-company-workspace>
                        </div>
                        <!-- People Tab Content -->
                        <div *ngIf="activeTab === 'people'">
                            <app-company-people></app-company-people>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-sub-footer></app-sub-footer>
<app-sidenav-right [showToggle]="showNav" [showCreate]="tagNavSwitch" [workSpaceEntity]="getWorkspace"
    (showworkspaceevent)="toggleNav('')"></app-sidenav-right>

<div id="overlay" [ngClass]="{'d-block': showAddCompanyBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav': showAddCompanyBar}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Add Company</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="showAddCompanyBar = false"></em>
    </div>

    <div class="scroll-content">
        <form class="mt-3" [formGroup]="addCompanyForm" (ngSubmit)="createCompany()" novalidate style="margin-right: 8px;">
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Company Name*</label>
                <input type="text" formControlName="companyName" class="form-control pt22 pb22 fw-600"
                    placeholder="Company Name" (keydown.space)="preventSpaces($event, 'companyName')">
                <div *ngIf="submitted && addCompanyForm.get('companyName').errors" class="fs12 text-danger">
                    Please enter company name
                </div>
            </div>

            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Address</label>
                <input type="text" formControlName="address" class="form-control pt22 pb22 fw-600"
                    placeholder="Address">
            </div>

            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Phone Code</label>
                <input type="text" formControlName="phoneCode" class="form-control pt22 pb22 fw-600"
                    placeholder="Phone Code">
            </div>

            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Phone Number</label>
                <input type="text" formControlName="phoneNumber" class="form-control pt22 pb22 fw-600"
                    placeholder="Phone Number">
            </div>

            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Email</label>
                <input type="email" formControlName="email" class="form-control pt22 pb22 fw-600" placeholder="Email">
            </div>

            <div formGroupName="owner">
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3">Owner Email*</label>
                    <input type="email" formControlName="email" class="form-control pt22 pb22 fw-600"
                        placeholder="Owner Email" (keydown.space)="preventSpaces($event, 'owner.email')">
                    <div *ngIf="submitted && addCompanyForm.get('owner.email').errors" class="fs12 text-danger">
                        <div *ngIf="addCompanyForm.get('owner.email').errors.required">Please enter owner email</div>
                        <div *ngIf="addCompanyForm.get('owner.email').errors.pattern">Invalid email format</div>
                    </div>
                </div>
            </div>

            <div class="row mt50 mb-3 px-3">
                <div class="col-4 pr-1">
                    <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7 btn-block fs13 fw-600"
                        (click)="showAddCompanyBar = false">Cancel</button>
                </div>
                <div class="col-8 pl-1">
                    <button type="submit" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload">
                        <em *ngIf="isLoading" class="fa fa-spinner fa-spin"></em>Create
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>