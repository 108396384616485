<div id="overlay" [ngClass]="{'d-block': toggleSidemenu}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : toggleSidemenu}">

    <div class="d-flex justify-content-between  align-items-center mb-3">
        <h1 class="grey-g fs17 ls-2 mb-0">
            <strong>Your Invites</strong>
        </h1>

        <button class="btn btn-grey-f br-25 float-right px-3 fs14 font-weight-bold" (click)="closeFilter()">
          Skip
        </button>

    </div>
    <p class="grey-i">You have got some Invites. You can either accept or reject them. You can also skip to check later.</p>
    <div class="position-relative">

    <div class="invite-list contact-scroll h-60vh pb100 overflow-x-hidden">
        <div class="invite-list-item py-4" *ngFor="let workspace of workspacePendingList; let i = index">
            <h3 class="grey-m fs16">{{workspace.workSpaceName}} Workspace</h3>

            <div class="d-flex mx-0" *ngFor="let pending of workspace.pendingList; let ii = index">
              <div>
                  <div class="invite-circle sm-circle text-uppercase rounded-circle d-flex align-items-center justify-content-center" *ngIf="pending.inviteUsertype !== '2'">
                    {{getFirstAndLastLetter(pending.projectName)}}
                  </div>
                  <div class="invite-circle sm-circle text-uppercase rounded-circle d-flex align-items-center justify-content-center" *ngIf="pending.inviteUsertype === '2'">
                    {{getFirstAndLastLetter(pending.workSpace)}}
                  </div>
              </div>
              <div class="flex-grow-1">
                     <h2 class="fs16 mb-3 mt-1" *ngIf="pending.inviteUsertype !== '2'">{{pending.projectName}}</h2>
                     <h2 class="fs16 mb-3 mt-1" *ngIf="pending.inviteUsertype === '2'">{{pending.workSpace}}</h2>
                     <h3 class="fs14 grey-l">Role</h3>
                     <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '2'">Workspace Admin</p>
                     <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '3'">Project Manager</p>
                     <p class="grey-m fs16" *ngIf="pending.inviteUsertype === '4'">Project Member</p>

                     <h3 class="fs14 grey-l">Invited by</h3>
                     <p class="grey-m fs16">{{pending.username}}</p>

                     <div class="mt-3">
                        <p type="button" class="fs16 grey-l" *ngIf="pending.accepted">
                            <img src="./assets/images/check.svg" class="tick-img mr-2" alt="check" />
                            Invite Accepted
                        </p>
                        <p type="button" class="fs16 grey-l" *ngIf="pending.rejected">
                            <img src="./assets/images/cross-red.svg" class="cross-img mr-2" alt="check" />
                            Invite Rejected
                        </p>

                        <div class="text-nowrap" *ngIf="pending.acceptreject">
                          <button (click)="rejectInvite(pending,i,ii)" class="btn btn-red-h br-25 px-md-4 px-3 py-1 mr-3 d-inlin-flex align-items-center justify-content-center">
                            <img src="./assets/images/cross-red.svg" class="cross-img mr-2" alt="cross" />
                            Reject
                          </button>
                          <button (click)="acceptInvite(pending)" class="btn btn-blue-outline br-25 px-md-4 px-3 py-1 d-inlin-flex align-items-center justify-content-center">
                            <img src="./assets/images/check.svg" class="tick-img mr-2" alt="check" />
                            Accept
                          </button>
                        </div>
                      </div>

              </div>
            </div>

         </div>
    </div>
    <div class="row mx-0 w-100 project-button-row bg-white pt-4 pb-2  position-absolute position-botom-0">
        <div class="col-11 mx-auto">
            <button type="button" class="btn btn-blue br25 py-2 btn-block fs14 fw-600 buttonload" [disabled]="submitted || !this.pendingObject" (click)="proceedMethod()">
                <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Proceed
            </button>
        </div>
    </div>

</div>
</div>


