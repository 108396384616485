<ngx-loading-bar [color]="'#007bff'" *ngIf="startingAt === 1"></ngx-loading-bar>
<section class="main-container">
    <div class="sub-container">
        
        <div class="sticks-header bg-white pt20">

            <div class="border-bottom">
                <div class="d-flex float-right">
                    <div role="presentation" class="mr-4 px-4 py-3" *ngIf="(filterCount > 0 || globalSearchStrings) && !submitted">
                        Search Result: {{searchCount}} 
                    </div>
                    <div>
                        <button class="btn btn-white br25 py-2 fw-600 px-sm-4 fs14 mr-sm-2 mr-1 mt-1" (click)="filterSidemenu = true"><img src="./assets/images/filter.svg" alt="add" class="mr-2 w12"> Filter 
                            <span class="count-badge rounded-circle d-inline-flex align-items-center justify-content-center ml-1" *ngIf="filterCount > 0">{{filterCount}}</span></button>
                    </div>
                    <div class="d-flex justify-content-end pb-2 border-bottom-a mt-2">
                        <div class="album-menus mr-2 c-pointer" (click)="!submitted && downloadImage()"
                            *ngIf="choosedImage && choosedImage.length > 0">
                            <img src="./assets/images/download.svg" alt="download" class="w14" title="Download">
                        </div>
                        <div class="album-menus mr-2 c-pointer" (click)="openModal3()"
                            *ngIf="choosedImage && choosedImage.length > 0 && (usertype !== '5')">
                                <img src="./assets/images/delete.svg" alt="delete" class="w14" title="Delete">
                        </div>
                    </div>                    
                </div>             
  
                <ul  class="nav nav-pills pills-primary px-4 custom-feeds-tab border-0">
                    <li role="presentation" class="nav-item mr-4" (click)="changeTab('List')">
                        <a href="javascript:void(0)" class="nav-link px-4 py-3" [class.active]="currentTab == 'List'">
                            <em class="fas fa-list pr-1"></em>
                            List</a>
                    </li> 
                    <li role="presentation" class="nav-item mr-4" (click)="changeTab('Grid')">
                        <a href="javascript:void(0)" class="nav-link px-4 py-3" [class.active]="currentTab == 'Grid'">
                            <em class="fas fa-th pr-1"></em>
                            Grid</a>
                    </li> 
                </ul>
            </div> 
            
        </div>

        <div class="sub-content-container" *ngIf="currentTab == 'List'">
            <div class="notification-scroll h-80vh overflow-auto bg-white px-2" *ngIf="photoFeedList.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                [infiniteScrollContainer]="selector1" [fromRoot]="true" [scrollWindow]="false">
                <div class="card content-card  border-grey my-2" *ngFor="let feed of photoFeedList">
                    <div class="card-header bg-white border-0 d-flex justify-content-between">
                        <div class="profile-photo">
                            <img *ngIf="!feed.profileImage" src="./assets/images/empty-img.png"
                                alt="user-img">
                            <img *ngIf="feed.profileImage"
                                src={{cloudFrontURL}}{{feed.profileImage}} alt="user-img">
                        </div>
                        <div class="flex-grow-1 ml-2">
                            <h1 class="mb-0 grey-g fs14 fw-600">{{feed.firstName}} {{feed.lastName}}</h1>
                            <p *ngIf="datePipe.transform(feed.created, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')" class="grey-r mb-0 fs10 ">{{feed.created | amTimeAgo }}</p>
                            <p *ngIf="datePipe.transform(feed.created, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')" class="grey-r mb-0 fs10 ">{{feed.created | amDateFormat: 'MM/DD/YYYY hh:mm A' }}</p>
                        </div>
                    </div>
                    <div class="card-body px-2 py-0" *ngIf="!feed.awskey.endsWith('.mp4')" >
                        <img src={{cloudFrontURL}}{{feed.awskey}} alt="feed-img"
                        [routerLink]="['/project/photo-tag']"
                        [queryParams]="conCadSessionData({albumId: feed.albumId, photoId: feed.photoId, userLat: feed.lattitude, userLng: feed.longitude, prevPage: 'project/feed'})"
                        class="br10 posted-img c-pointer">
                    </div>
                    <div *ngIf="feed.awskey.endsWith('.mp4')" class="br5">
                        <video controls #videoPlayer  style="object-fit: fill;
                        height: 350px;
                        width: 100%; max-width: 100%;">
                            <source src="{{cloudFrontURL}}{{feed.awskey}}" type="video/mp4" />
                            Browser not supported
                        </video>
                    </div>
                    <div class="d-flex px-2 border-bottom-grey justify-content-between py-2 align-items-center">
                        <div class="general-info d-flex pl15 pr15 py-2 align-items-center c-pointer"
                            [routerLink]="['/project/album-details']" [queryParams]="conCadSessionData({albumId: feed.albumId})">
                            <img src="./assets/images/album-small.svg" alt="album-small" class="mr-2">
                            <h3 class="blue-e ls-8 fs13 mb-0 font-weight-bold">{{feed.albumName}}</h3>
                        </div>
                        <div class="d-flex ">
                            <!-- <img src="./assets/images/Share.svg" alt="share" class="mr-3 mt-1 w17 c-pointer"
                                                    (click)="showInvite();getDataforShare(feed)"> -->
                            <div class="tag-count c-pointer mr-1">
                                <a *ngIf="feed && feed.tagCount" class="mb-0 tag text-white" [routerLink]="['/project/photo-tag']"
                                    [queryParams]="conCadSessionData({albumId: feed.albumId, photoId: feed.photoId, userLat: feed.lattitude, userLng: feed.longitude, prevPage: 'project/feed'})">{{feed.tagCount}}</a>
                                <a *ngIf="!feed || !feed.tagCount" class="mb-0 tag text-white">0</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card content-card pl18 pr18 py-3 border-grey" *ngIf="errorMessage">
                <div class="card-body mt60 mb60">
                    <div class="col-8 mx-auto text-center">
                        <img src='./assets/images/feed-empty.svg' alt="feed-empty" class="w-75">
        
                        <h1 class="text-black fs20 font-weight-bold mt-5">Seems Empty</h1>
                        <p class="fs12 grey-g fw-600">No post in the feed</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="sub-content-container" *ngIf="currentTab == 'Grid'">       
            <div class="row notification-scroll-grid h-80vh overflow-auto bg-white px-2" *ngIf="photoFeedList.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onGridScroll()"
            [infiniteScrollContainer]="selector2" [fromRoot]="true" [scrollWindow]="false">
            <div class="col-4 my-2" *ngFor="let feed of photoFeedList">
                <div class="card" [ngClass]="{'selected-card': isImageInChoosedImage(feed)}">
                    <div class="card-header bg-white border-0 d-flex justify-content-between" >
                        <div class="profile-photo">
                            <img *ngIf="!feed.profileImage" src="./assets/images/empty-img.png" alt="user-img">
                            <img *ngIf="feed.profileImage" src={{cloudFrontURL}}{{feed.profileImage}} alt="user-img">
                        </div>
                        <div class="flex-grow-1 ml-2">
                            <span class="mb-0 grey-g fs14 fw-600">{{feed.firstName}} {{feed.lastName}}</span>
                            <span *ngIf="datePipe.transform(feed.created, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')"
                                class="grey-r mb-0 fs10 d-block">{{feed.created | amTimeAgo }}</span>
                            <span *ngIf="datePipe.transform(feed.created, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')"
                                class="grey-r mb-0 fs10 d-block">{{feed.created | amDateFormat: 'MM/DD/YYYY hh:mm A' }}</span>
                                <input type="checkbox" [checked]="isImageInChoosedImage(feed)" (click)="albumChoose(feed)" class="w14 check-img c-pointer" style="position: absolute; right: 6px; top: 6px; height: 18px; width: 18px;">
                        </div>

                    </div>
                    <div class="" *ngIf="!feed.awskey.endsWith('.mp4')">
                        <img src={{cloudFrontURL}}{{feed.awskey}} alt="feed-img" [routerLink]="['/project/photo-tag']"
                            [queryParams]="conCadSessionData({albumId: feed.albumId, photoId: feed.photoId, userLat: feed.lattitude, userLng: feed.longitude, prevPage: 'project/feed'})"
                            class="br10 posted-img c-pointer">
                    </div>
                    <div *ngIf="feed.awskey.endsWith('.mp4')" class=" br5">
                        <video #videoPlayer style="object-fit: fill;
                                                cursor: pointer;
                                                height: 350px;
                                                width: 100%; max-width: 100%;"
                                                [routerLink]="['/project/photo-tag']"
                                                [queryParams]="conCadSessionData({albumId: feed.albumId, photoId: feed.photoId, userLat: feed.lattitude, userLng: feed.longitude, prevPage: 'project/feed'})">
                            <source src="{{cloudFrontURL}}{{feed.awskey}}" type="video/mp4"/>
                            Browser not supported
                        </video>
                    </div>
                    <div class="d-flex px-2 border-bottom-grey justify-content-between py-2 align-items-center">
                        <div class="general-info d-flex pl15 pr15 py-2 align-items-center c-pointer"
                            [routerLink]="['/project/album-details']" [queryParams]="conCadSessionData({albumId: feed.albumId})">
                            <img src="./assets/images/album-small.svg" alt="album-small" class="mr-2">
                            <h3 class="blue-e ls-8 fs13 mb-0 font-weight-bold">{{feed.albumName}}</h3>
                        </div>
                        <div class="d-flex ">
                            <!-- <img src="./assets/images/Share.svg" alt="share" class="mr-3 mt-1 w17 c-pointer"
                                        (click)="showInvite();getDataforShare(feed)"> -->
                            <div class="tag-count c-pointer mr-1">
                                <a *ngIf="feed && feed.tagCount" class="mb-0 tag text-white" [routerLink]="['/project/photo-tag']"
                                    [queryParams]="conCadSessionData({albumId: feed.albumId, photoId: feed.photoId, userLat: feed.lattitude, userLng: feed.longitude, prevPage: 'project/feed'})">{{feed.tagCount}}</a>
                                <a *ngIf="!feed || !feed.tagCount" class="mb-0 tag text-white">0</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="card content-card pl18 pr18 py-3 border-grey" *ngIf="errorMessage">
                <div class="card-body mt60 mb60">
                    <div class="col-8 mx-auto text-center">
                        <img src='./assets/images/feed-empty.svg' alt="feed-empty" class="w-75">

                        <h1 class="text-black fs20 font-weight-bold mt-5">Seems Empty</h1>
                        <p class="fs12 grey-g fw-600">No post in the feed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div (click)='feedIconDetails=!feedIconDetails' class="row mt50 project-button-row i-icon-box">
        <img src="./assets/images/i-icon.svg" alt="add" class="mr-1 mb-1 i-icon-box">
    </div>
    <div class="feed-i-icon-box" *ngIf='feedIconDetails'>
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
            <h4 class="modal-title fs20 fw-600 pull-left grey-g">Pixly feed</h4>
            <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="feedIconDetails=false"></em>
        </div>
        </div>
        <iframe id="ston-n3YiFhMCbq" class="w-100 h-100" frameBorder="0" name="StonlyExplanation" title="Stonly" src="https://stonly.com/embed/en/n3YiFhMCbq/view/684372"> </iframe> 
    </div> -->
</section>

<div id="overlay" [ngClass]="{'d-block': Invite}"></div>

<div #sidenav class="right-sidebar bg-white " [ngClass]="{'show-side-nav' : Invite}">


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Invite</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showInvite();ResetForm(ShareForm)"></em>
    </div>
    <div class="contact-scroll">
        <form class="mt-3 " autocomplete="off" name="ShareForm" (ngSubmit)="onSubmit(ShareForm)" #ShareForm="ngForm">
            <label class="fs12 grey-j ls-3">Name</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded">
                        <input type="text" name="firstName" pattern="^[A-Za-z]+$" required maxlength="10"
                            [(ngModel)]="Share.firstName" #firstName="ngModel"
                            [ngClass]="{ 'has-error': ShareForm.submitted && firstName.invalid }"
                            class="form-control br8 pt22 pb22 fw-600" placeholder="First Name" 
                            (keydown.space)="Share.firstName.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="firstName.invalid && validation">
                            <div *ngIf="firstName.errors.required" class="text-danger fs12">
                                First name is required.
                            </div>
                            <div *ngIf="firstName.errors.pattern" class="fs12 text-danger">
                                First name must contain only alphabets.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded">
                        <input type="text" name="lastName" pattern="^[A-Za-z]+$" required maxlength="10"
                            [(ngModel)]="Share.lastName" #lastName="ngModel"
                            [ngClass]="{ 'has-error': ShareForm.submitted && lastName.invalid }"
                            class="form-control br8 pt22 pb22 fw-600" placeholder="Last Name"
                            (keydown.space)="Share.lastName.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="lastName.invalid && validation">
                            <div *ngIf="lastName.errors.required" class="text-danger fs12">
                                Last name is required.
                            </div>
                            <div *ngIf="lastName.errors.pattern" class="fs12 text-danger">
                                Last name must contain only alphabets.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j br8 ls-3">E-Mail</label>

                <input type="email" name="email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    [(ngModel)]="Share.email" #email="ngModel"
                    [ngClass]="{ 'has-error': ShareForm.submitted && email.invalid }"
                    class="form-control pt22 pb22 fw-600" placeholder="Enter E-mail"
                    (keydown.space)="Share.email.trim().length > 0 ? '': $event.preventDefault();">
                <div *ngIf="email.invalid && validation">
                    <div *ngIf="email.errors.required" class="text-danger fs12">
                        Email is required.
                    </div>
                    <div *ngIf="email.errors.pattern" class="fs12 text-danger">
                        Enter a valid email.
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j br8 ls-3">Time Limit</label>
                <input type="text" name="expires" required [(ngModel)]="Share.expires" #expires="ngModel"
                    [ngClass]="{ 'has-error': ShareForm.submitted && expires.invalid }" placeholder="Time limit"
                    class="form-control pt22 pb22 fw-600" bsDatepicker [bsConfig]="datePickerConfig" #dp="bsDatepicker">
                <div *ngIf="expires.invalid && validation">
                    <div *ngIf="expires.errors.required" class="text-danger fs12">
                        Expiry date is required.
                    </div>
                </div>
            </div>
            <div class="row mt50 mb-3 px-4">
                <div class="col-5 pr-1">
                    <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                        (click)="showInvite();ResetForm(ShareForm)">Cancel</button>

                </div>
                <div class="col-7 pl-1">
                    <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" [disabled]="submitted"><em
                        *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Send Link</button>
                </div>
            </div>
        </form>
    </div>

</div>

<ng-template #photodeleteModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete photo?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethodDelete()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<app-feed-side-filter [toggleSidemenu]="filterSidemenu" [globalSearchStr] ="globalSearchStrings" (closesidemenu)="closeFilterSidemenu($event)" (filterSubmite)="getFilterObject($event)"></app-feed-side-filter>