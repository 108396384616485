<div id="overlay" [ngClass]="{'d-block': toggleSidemenu}"></div>
<div #sidenav class="right-sidebar bg-white " [ngClass]="{'show-side-nav' : toggleSidemenu}">


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17  ls-2 mb-3 "><strong>Filters</strong> <span class="px-1"> | </span>
            <img src="./assets/images/files.svg" alt="projects" class="mr-2 w14" [title]="teamName">{{teamName |
            ngXtruncate:16:'...'}}
        </h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeFilter()"></em>
    </div>

    <a href="javascript:void(0)" (click)="clearFilterFromParent()" class="blue-a fs14 float-right my-2">Clear Filter
        <span class="fas fa-filter fs11 pl-2 font-weight-bold"></span></a>
    <div class="clearfix"></div>

    <form [formGroup]="filterBilling" class="position-relative h-100" novalidate>
        <div class="contact-scroll pb100 overflow-x-hidden">
            <h2 class="mb-3 color-grey-a fs16 font-weight-bold">Timeline :</h2>
            <div class="row form-group">
                <div class="col-md-6">
                    <label class="color-grey-j fs14">From</label>
                    <input type="text" formControlName="fromDate" [maxDate]="maxDate" placeholder="Select Date"
                        class="form-control fw-600 fs14 text-center blue-a blue-a-placeholder" bsDatepicker
                        [bsConfig]="datePickerConfig" #dp="bsDatepicker">


                </div>
                <div class="col-md-6 todatefilter">
                    <label class="color-grey-j fs14">To</label>
                    <input type="text" formControlName="toDate" placeholder="Select Date"
                        class="form-control fs14 fw-600 text-center blue-a blue-a-placeholder"
                        [minDate]="filterBilling.get('fromDate').value" bsDatepicker [bsConfig]="datePickerConfig"
                        #dp="bsDatepicker"
                        [isDisabled]="filterBilling.get('fromDate').value === '' || filterBilling.get('fromDate').value === null">

                </div>
            </div>
            <p class="color-grey-j fs12">Note: Please choose From date first then To date</p>

            <hr class="mb-4">
            <div class="feed-filter-tab">
                <tabset [justified]="true">
                    <tab (selectTab)="onTabSelect(1)">
                        <ng-template tabHeading>
                            <em class="far fa-circle pr-1"></em> Tags
                            <span
                                class="count-badge rounded-circle d-inline-flex align-items-center justify-content-center ml-1"
                                *ngIf="tabValues.tagValues.length > 0">{{tabValues.tagValues.length}}</span>
                        </ng-template>

                        <!-- search for tags -->
                        <ng-template [ngTemplateOutlet]="searchRef"></ng-template>


                        <div class="pb-5 feed-scroll tags-scroll" infiniteScroll [infiniteScrollDistance]="3"
                            [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                            [infiniteScrollContainer]="tagSelector" [fromRoot]="true" [scrollWindow]="false">
                            <div class="pending-invites-bg text-center mt20" *ngIf="!loading && tags.length === 0">
                                <img src="./assets/images/search-empty.svg" class="w-180px" alt="search-empty">
                                <h1 class="fs15 font-weight-bold text-black my-2">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mb-5">There is no tags available</p>
                            </div>



                            <div class="list-of-users pt-3">
                                <div class="pt8 pb8 modal-tag-choose " *ngFor="let t of tags">
                                    <input type="checkbox" name="tagTypeId" class="d-none"
                                        (change)="toggleCheckboxFilters($event, t.tagId, 'tag')" [checked]="t.selected"
                                        [id]="t.tagId">
                                    <label class="d-flex align-items-center my-1 c-pointer" [for]="t.tagId">
                                        <div class="tag-types w19 h19" style.background-color="#{{t.tagColor}}"></div>
                                        <div class="flex-grow-1 ml-2">
                                            <h2 class="fs14 mb-0 fw-500">{{t.tagName}}</h2>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </tab>
                    <tab (selectTab)="onTabSelect(2)">
                        <ng-template tabHeading>
                            <img src="./assets/images/tab-users-active.svg" alt="users-active"
                                class="users-active  w24">
                            <img src="./assets/images/tab-users-inactive.svg" alt="users-inactive"
                                class="users-inactive w24">
                            People
                            <span
                                class="count-badge rounded-circle d-inline-flex align-items-center justify-content-center ml-1"
                                *ngIf="tabValues.peopleValues.length > 0">{{tabValues.peopleValues.length}}</span>
                        </ng-template>

                        <!-- Peoples section -->

                        <!-- search for Peoples -->
                        <ng-template [ngTemplateOutlet]="searchRef"></ng-template>


                        <div class="contact-scroll pb-5 feed-scroll people-scroll" infiniteScroll
                            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                            [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">
                            <div class="pending-invites-bg text-center mt20" *ngIf="!loading && peoples.length === 0">
                                <img src="./assets/images/search-empty.svg" class="w-180px" alt="search-empty">
                                <h1 class="fs15 font-weight-bold text-black my-2">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mb-5">There is no Peoples available</p>
                            </div>

                            <ng-container *ngFor="let p of peoples">
                                <div class="d-flex align-items-center justify-content-between border-bottom-grey-p">

                                    <div class="pt8 pb8 modal-tag-choose w-100">
                                        <input type="checkbox" name="peopleId" class="d-none"
                                            (change)="toggleCheckboxFilters($event, p.id, 'people')"
                                            [checked]="p.selected" [id]="p.id">
                                        <label class="d-flex align-items-center my-1 " [for]="p.id">

                                            <div>
                                                <img src="./assets/images/empty-img.png"
                                                    *ngIf="!p.profileImage || p.profileImage === null" alt="user1"
                                                    class="people-img mr-3 w32 h32">

                                                <img src="{{cloudFrontURL}}{{p.profileImage}}"
                                                    *ngIf="p.profileImage && p.profileImage !== null" alt="user1"
                                                    class="people-img mr-3 w32 h32">
                                            </div>
                                            <div class="flex-grow-1">
                                                <h1 class="fs12 fw-600 mb-0 text-capitalize">{{p.firstName}} {{p.lastName}}</h1>
                                                <p class="mb-0 grey-l fs11">{{p.email}}</p>
                                            </div>

                                        </label>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                        <!-- / Peoples section -->
                    </tab>
                    <tab (selectTab)="onTabSelect(3)">
                        <ng-template tabHeading>
                            <img src="./assets/images/album-active.svg" alt="users-active"
                                class="users-active mr-2 w14">
                            <img src="./assets/images/album.svg" alt="users-inactive" class="users-inactive mr-2 w14">
                            Albums
                            <span
                                class="count-badge rounded-circle d-inline-flex align-items-center justify-content-center ml-1"
                                *ngIf="tabValues.albumValues.length > 0">{{tabValues.albumValues.length}}</span>
                        </ng-template>


                        <!-- search for albums -->
                        <ng-template [ngTemplateOutlet]="searchRef"></ng-template>


                        <div class="contact-scroll pb-5 album-scroll" infiniteScroll
                        [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                        [infiniteScrollContainer]="albumSelector" [fromRoot]="true" [scrollWindow]="false">


                            <div class="pending-invites-bg text-center mt20" *ngIf="!loading && albums.length === 0">
                                <img src="./assets/images/search-empty.svg" class="w-180px" alt="search-empty">
                                <h1 class="fs15 font-weight-bold text-black my-2">It's a bit empty here</h1>
                                <p class="grey-g fw-600 fs13 mb-5">There is no albums available</p>
                            </div>

                            <ng-container *ngFor="let a of albums">
                                <div class="d-flex align-items-center justify-content-between border-bottom-grey-p">

                                    <div class="pt8 pb8 modal-tag-choose w-100">
                                        <input type="checkbox" name="albumTypeId" class="d-none"
                                            (change)="toggleCheckboxFilters($event, a.albumId, 'album')"
                                            [checked]="a.selected" [id]="a.albumId">
                                        <label class="d-flex align-items-center my-1" [for]="a.albumId">

                                            <div>

                                                <img src="./assets/images/house1.jpg"
                                                    *ngIf="!a.photoUrl || a.photoUrl === null" alt="user1"
                                                    class="mr-3 album-img">
                                                <img src="{{cloudFrontURL}}{{a.photoUrl}}"
                                                    *ngIf="a.photoUrl && a.photoUrl !== null" alt="user1"
                                                    class="mr-3 album-img">
                                            </div>
                                            <div class="flex-grow-1">
                                                <h1 class="fs14 grey-g fw-600 mb-0 text-capitalize">{{a.albumName}}
                                                </h1>
                                                <p class="mb-0 grey-g fs12">{{a.photoCount}} items - {{a.createdOn |
                                                    amParseZone | amDateFormat: 'MMMM Do'}}</p>
                                            </div>

                                        </label>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </tab>
                </tabset>
            </div>

        </div>

        <div class="row bg-white py-4 pl-4 position-absolute w-100 positioning-bottom">
            <div class="col-5 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600" type="button"
                    (click)="closeFilter()">Cancel</button>

            </div>
            <div class="col-7 pl-1">
                <button type="button" (click)="filterSubmit()" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload">Apply
                    Filters</button>
            </div>
        </div>
    </form>

</div>



<!-- search for common -->
<ng-template #searchRef>
    <div class="mt-3">
        <div class="input-group input-todo">
            <input type="text" class="form-control fw-600 pt17 pb17  border-right-0 " [(ngModel)]="inlinesearch"
                (ngModelChange)="searchDelay.next($event)" [placeholder]="placeholderlabel" (keyup.enter)="enterMethod()">
            <div class="input-group-append" *ngIf="loading">
                <span class="input-group-text border-left-0 buttonload pl-4 pr-2">
                    <span  class="fa fa-spinner fa-spin fs16  "></span>
                </span>
            </div>

            <div class="input-group-append" *ngIf="inlinesearch !== '' && !loading">
                <span class="input-group-text border-left-0 buttonload p-1" (click)="clearUserSearchData()">
                    <span class="lnr lnr-cross fs16"></span>
                </span>
            </div>

            
            <div class="input-group-append">
                <span class="input-group-text border-left-0 ">
                    <img src="./assets/images/search-todo.svg" alt="search" class="w13">

                </span>
            </div>
        </div>
    </div>
</ng-template>