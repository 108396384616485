import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { SupportService } from '../services/support.service';
import { UploadService } from '../services/uploadservice.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';

@Component({
    selector: 'app-company-management',
    templateUrl: './company-management.component.html',
})
export class CompanyManagementComponent implements OnInit {
    @ViewChild('deletecompany') public deletecompany: TemplateRef<any>;

    @ViewChild('createCompanyform') public createCompanyform;

    public formSubmitted = false;

    public searchDelay = new Subject<string>();

    public companySearchDelay = new Subject<string>();

    public addCompany = false;

    public companyDetails = false;

    public pcode = [{ code: '+1', mask: '(000) 0000-000' }, { code: '+91', mask: '00000-00000' }];

    public alreadyPresentList = [];

    public companyForm = {
        userId: localStorage.getItem('userId'),
        projectId: sessionStorage.getItem('projectId'),
        workSpaceName: sessionStorage.getItem('workspacename'),
        companyName: '',
        address: '',
        phoneCode: '',
        phoneNumber: '',
        companyProfileImage: '',
    };

    public checkavailableloading = false;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public loading = false;

    public phoneMask = '';

    public companyList = [];

    public currentDeleteCompanyId = '';

    public modalRef: BsModalRef;

    public configs = {
        ignoreBackdropClick: true,
    };

    public sortType = 'asc';

    public sortItem: string[] = [
        'A - Z',
        'Z - A',
    ];

    public companySearch = '';

    public editCompanyDetails = '';

    public companyNameInUserList = '';

    public companyUserList = [];

    public loadMoreOnScroll = true;

    public independetUserLoad = false;

    public pageRecordIndependedMem = 10;

    public pageNumberIndependedMem = 1;

    public loadMoreOnScrollCompy = true;

    public pageNumberCompy = 1;

    public constructor(
        private support: SupportService,
        private workspace: WorkspaceServiceService,
        private toastr: ToastrService,
        private api: ApiService,
        private uploadService: UploadService,
        private modalService: BsModalService,
    ) {
        // Debounce search.
        this.searchDelay.pipe(debounceTime(1250), distinctUntilChanged()).subscribe((value): any => {
            this.checkavailableloading = false;
            if (this.companyForm.companyName !== '') {
                this.checkNameAvailable();
            }
        });

        this.companySearchDelay.pipe(debounceTime(1250), distinctUntilChanged()).subscribe((value): any => {
            this.pageNumberCompy = 1;
            this.getAllCompanyList();
        });
    }


    public ngOnInit(): void {
        this.getAllCompanyList();
    }


    public openCompanySidepanel(): void {
        this.addCompany = true;
    }

    public viewCompany(company?): void {
        this.companyDetails = !this.companyDetails;
        this.companyNameInUserList = company ? company.companyName : 'Indepenedent Users';
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            companyId: company ? company.id : '',
            search: '',
            sortParam: 'companyName',
            sortType: 'asc',
            pagenumber: this.pageNumberIndependedMem,
            pagerecord: this.pageRecordIndependedMem,
        };
        this.independetUserLoad = true;
        this.workspace.projectInvite(payload, 'pixlyProfile/getcompanymembers').subscribe((res): any => {
            this.independetUserLoad = false;
            if (res && res.status && res.status.status === 200) {
                if (this.companyUserList.length > 0) {
                    res.entity.forEach((ele): void => {
                        this.companyUserList.push(ele);
                    });
                } else {
                    this.companyUserList = res.entity;
                }
            } else {
                this.loadMoreOnScroll = false;
            }
        },
        (err): void => {
            this.independetUserLoad = false;
        });
    }

    public back(): void {
        this.companyDetails = false;
        this.companyNameInUserList = '';
    }

    public getAllCompanyList(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: this.companySearch,
            pagenumber: this.pageNumberCompy,
            pagerecord: 10,
            sortParam: 'companyName',
            sortType: this.sortType,
        };
        this.loading = true;
        this.workspace.searchCompanyApi(payload).subscribe(
            (res): any => {
                this.loading = false;
                if (res && res.status && res.status.status === 200) {
                    if (this.companyList && this.companyList.length > 0) {
                        res.entity.forEach((element): void => {
                            this.companyList.push(element);
                        });
                    } else {
                        this.companyList = res.entity;
                    }
                } else {
                    this.loadMoreOnScrollCompy = false;
                }
                if (this.companySearch !== '') {
                    this.companyList = res.entity;
                }
            },
            (err): any => {
                this.loading = false;
                this.companyList = [];
                this.api.handleError(err);
            },
        );
    }

    public changeMask(): void {
        if (this.companyForm.phoneCode && this.companyForm.phoneCode === '+1') {
            this.phoneMask = this.pcode[0].mask;
        } else {
            this.phoneMask = this.pcode[1].mask;
        }
    }

    public closeSidebar(): void {
        this.createCompanyform.reset();
        this.addCompany = false;
        this.alreadyPresentList = [];
    }

    public checkNameAvailable(): void {
        this.checkavailableloading = true;
        this.loading = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: this.companyForm.companyName,
            pagenumber: '',
            pagerecord: '',
        };
        this.workspace.searchCompanyApi(payload).subscribe(
            (res): any => {
                this.loading = false;
                // this.checkavailableloading = false;
                if (res && res.entity) {
                    this.alreadyPresentList = res.entity;
                }

                // const companyName = 'companyName';
                // if (this.editCompanyDetails && (this.editCompanyDetails[companyName].toLowerCase() === this.companyForm.companyName.toLowerCase())) {
                //     this.alreadyPresentList = false;
                // }
            },
            (err): any => {
                this.loading = false;
                // this.checkavailableloading = false;
                // this.alreadyPresentList = false;
            },
        );
    }

    public onSubmit(formdata): void {
        this.formSubmitted = true;
        this.companyForm.companyName = this.companyForm.companyName ? this.companyForm.companyName.trim() : this.companyForm.companyName;
        if (formdata.valid) {
            this.loading = true;
            if (this.editCompanyDetails === '') {
                this.workspace.addCompanyApi(this.companyForm).subscribe(
                    (res): any => {
                        this.formSubmitted = false;
                        this.commonSubmitResposne(res);
                    },
                    (err): any => {
                        this.loading = false;
                    },
                );
            } else {
                const companyId = 'companyId';
                const id = 'id';
                this.companyForm[companyId] = this.editCompanyDetails[id];
                this.workspace.editCompanyApi(this.companyForm).subscribe(
                    (res): any => {
                        this.formSubmitted = false;
                        this.commonSubmitResposne(res);
                    },
                    (err): any => {
                        this.loading = false;
                    },
                );
            }
        }
    }

    public commonSubmitResposne(res): void {
        this.loading = false;
        if (res && res.status && res.status.status === 200) {
            this.editCompanyDetails = '';
            this.pageNumberCompy = 1;
            this.companyList = [];
            this.toastersMethod('success', res.status.msg);
            this.closeSidebar();
            this.getAllCompanyList();
        }

        if (res && res.status && res.status.status === 302) {
            this.toastersMethod('error', res.status.msg);
        }
    }


    public selectFile(event: any): void {
        if (!event.target.files.item(0)) {
            return;
        }

        const file = event.target.files.item(0);
        this.loading = true;
        const that = this;

        let fileType = file.type.slice(0, file.type.indexOf('/'));
        // const fileExtension = file.name.replace(/^.*\./, '');
        if (fileType === 'application') { fileType = 'document'; }
        this.uploadService
            .uploadProfile(file)
            .then((data: any): any => {
                // const fileData = {
                //     filePath: data.Key, name: file.name, type: fileType, format: fileExtension,
                // };

                // this.getFile = `${environment.KsecretKey}${fileData.filePath}`;
                // eslint-disable-next-line no-param-reassign

                that.companyForm.companyProfileImage = data.Key;
                // that.companyForm.upload.filename = file.name;
                setTimeout((): any => {
                    this.loading = false;
                }, 100);
            })
            .catch((err): any => {
                this.loading = false;
                console.error(err);
            });
    }

    public toastersMethod(type, message): void {
        this.toastr.clear();
        if (type === 'success') {
            this.toastr.success(message);
        } else {
            this.toastr.error(message);
        }
    }

    public editCompany(data): void {
        this.editCompanyDetails = data;
        this.commonPatchUp(data);
        this.openCompanySidepanel();
    }

    public commonPatchUp(data): void {
        this.companyForm.companyName = data.companyName;
        this.companyForm.address = data.address;
        this.companyForm.phoneCode = data.phoneCode;
        this.companyForm.phoneNumber = data.phoneNumber;
        this.companyForm.companyProfileImage = data.companyProfileImage;
    }

    public getSortinglist(sortChoice): void {
        if (sortChoice === 'A - Z') {
            this.sortType = 'asc';
            this.getAllCompanyList();
        } else {
            this.sortType = 'desc';
            this.getAllCompanyList();
        }
    }

    public onCompanySelect(data): void {
        this.commonPatchUp(data.item);
    }

    public checkSuggestions(): void {
        const inArray = this.alreadyPresentList.find((r): any => r.companyName.toLowerCase() === this.companyForm.companyName.toLowerCase());
        if (inArray) {
            this.commonPatchUp(inArray);
        }
    }

    public deleteCompanyFunc(): void {
        this.loading = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            companyId: this.currentDeleteCompanyId,
            projectId: '',
        };

        this.workspace.deleteCompanyApi(payload).subscribe(
            (res): any => {
                this.loading = false;
                this.toastersMethod('success', res.status.msg);
                this.pageNumberCompy = 1;
                this.companyList = [];
                this.getAllCompanyList();
                this.modalRef.hide();
            },
            (err): any => {
                this.loading = false;
            },
        );
    }


    public openConfirmationModal(currentDeleteCompanyId): void {
        this.currentDeleteCompanyId = currentDeleteCompanyId;
        this.modalRef = this.modalService.show(
            this.deletecompany,
            Object.assign(this.configs, { class: 'modal-sm popup-center' }),
        );
    }

    public gotoBack(): void {
        window.history.back();
    }

    public onScroll(): void {
        if (this.loadMoreOnScroll) {
            this.pageNumberIndependedMem += 1;
        }
    }

    public onScrollCompany(): void {
        if (this.loadMoreOnScrollCompy) {
            this.pageNumberCompy += 1;
        }
    }
}
