/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import {
    Component, OnInit, OnDestroy, TemplateRef, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SupportService } from '../services/support.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { ApiService } from '../services/api_base/api.service';
import { AlbumserviceService } from '../services/albumservice.service';

enum CheckBoxType { PUBLIC, PRIVATE, NONE }

@Component({
    selector: 'app-albums',
    templateUrl: './albums.component.html',
})
export class AlbumsComponent implements OnInit, OnDestroy {
    public searchDelay = new Subject<string>();

    public showProject: boolean;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public unsub: any;

    public DateText = 'Most Recent';

    public viewMode = 'Folders';

    public viewTab = 'projectmembers';

    public errorMessage = '';

    public byGrid = true;

    public showCreate = false;

    public inviteForm: FormGroup;

    public userRoleList = [];

    public checked = true;

    public byList = false;

    public addMembers = false;

    public albumForm: FormGroup;

    public submitted = false;

    public Invitesubmitted = false;

    public albumData: any;

    public membersarray = [];

    public companyarray = [];

    public PendingMembers = [];

    public getMembers: any;

    public album: any = {};

    public check_box_type = CheckBoxType;

    public currentlyChecked = 0;

    public albumVisibility = 'PUBLIC';

    public albumEdit = false;

    public modalRef: BsModalRef;

    public albumunsub: any;

    public albumId: any;

    public index: any;

    public index1: any;

    public showInviteBar: boolean;

    public showInvite: any;

    public config = {
        ignoreBackdropClick: true,
    };

    public lat = 0;

    public lng = 0;

    public orderBy = 'desc';

    public sortType = '';

    public photoFeedList = [];

    public userSearch = '';

    public tempGetMembers = [];

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    public recentAlbumList = [];

    public copyDatas: any;

    public userId: any;

    public userType: any;

    public getCompany = [];

    public openPanel: number = null;

    public loading = false;

    public loadingPage = false;
    
    public albumIdList: any = [];
    
    public isPdfReport: boolean = false;

    public feedCreateIconDetails: boolean = false;

    public albumIconDetails = false;

    public pdfSubmitted = false;

    public pdfModalSelect = false;

    public selectedPDFType = "Photo summary";

    public photoExtentions = ['jpg', 'png', 'jpeg'];

    public videoExtentions = ['mp4'];
    
    public constructor(
        private support: SupportService,
        private api: AlbumserviceService,
        private toastr: ToastrService,
        private inviteBuilder: FormBuilder,
        private workspaceService: WorkspaceServiceService,
        private modalService: BsModalService,
        private apis: ApiService,
        private activeRoute: ActivatedRoute,
        private location: Location,
        private loadingBar: LoadingBarService,
    ) {
        // Debounce search.
        this.searchDelay.pipe(debounceTime(1250), distinctUntilChanged()).subscribe((value) => {
            this.getMemberList('', true);
        });

        this.userId = localStorage.getItem('userId');
        this.userType = sessionStorage.getItem('usertype');
        this.createBuilderForm();
        this.showProject = !!sessionStorage.getItem('projectId');
        this.userRoleList = [];
        this.albumunsub = this.support.projectChange.subscribe((res): any => {
            if (res) {
                if (res !== 'empty') {
                    this.getAlbum();
                }
            }
        });

        this.activeRoute.queryParams.subscribe((params): any => {
            if (params && params.type) {
                this.viewMode = params.type;
            }
        });

        this.activeRoute.queryParams.subscribe((params): any => {
            this.copyDatas = params;
        });
    }

    public ngOnInit(): void {
        this.album = {
            albumName: '',
            description: '',
            albumId: '',
        };

        this.showProject = !!sessionStorage.getItem('projectId');

        this.viewMode === 'Photos' ? this.getListList(2) : this.getAlbum();
        this.getCurrentLocation();
        this.getRecentAlbum();
    }

    public getRecentAlbum(): void {
        this.workspaceService.getRecentAlbums().subscribe(res => {
            this.recentAlbumList = (res && res.status && res.status.status) && res.status.status === 200 ? res.entity : [];
        });
    }

    public getListList(type): any {
        switch (type) {
            case 1:
                this.viewMode = 'Folders';
                this.getAlbum();
                break;
            case 2:
                this.viewMode = 'Photos';
                this.getPhotoFeed();
                break;
        }
    }

    public getTabList(type): any {
        this.viewTab = type === 1 ? 'projectmembers' : 'companys';
        this.getMemberList('');
        this.openPanel = null;
    }

   public getPhotoFeed(): any {
        this.errorMessage = '';
        this.loadingPage = true;

        this.api.getPhotoFeedByTeamIdFilter(this.sortType, this.orderBy).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    this.photoFeedList = res.entity.listview;
                } else {
                    this.errorMessage = 'It seems no photos/videos are available';
                }
            },
            (err): any => {
                this.apis.handleError(err);
            }
        );
        this.loadingPage = false;
    }


    public ngOnDestroy(): any {
        this.albumunsub.unsubscribe();
    }

    public yesMethod(): any {
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        const path = `pixlyMedia/deletealbum?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&albumId=${this.albumId}`;
        this.api.deleteSelectedAlbum(path).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.toastr.success('Album Deleted successfully');
                this.albumData[+this.index1].albumInfo.splice(+this.index, 1);
                this.modalRef.hide();
            } else if (res && res.status && res.status.status === 400) {
                this.submitted = false;
                this.modalRef.hide();
                this.toastr.error(res.status.msg);
            } else {
                this.submitted = false;
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.apis.handleError(err);
        });
    }



    public getCurrentLocation(): void {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (showPosition): any => {
                    this.lat = +showPosition.coords.latitude;
                    this.lng = +showPosition.coords.longitude;
                },
            );
        }
    }

    public viewByGrid(): void {
        this.byGrid = true;
        this.byList = false;
    }

    public Create(value): void {
        this.currentlyChecked = +value;
        this.albumVisibility = this.currentlyChecked === 0 ? 'PUBLIC' : 'PRIVATE';
        this.showCreate = this.currentlyChecked === 0;
        this.addMembers = this.currentlyChecked !== 0;
    }

    public ResetForm(albumForm): void {
        this.currentlyChecked = null;
        this.showCreate = false;
        this.addMembers = false;
        this.membersarray = [];
        this.albumEdit = false;
        albumForm.form.reset();
    }


    public selectCompanyMembers(val, id, j, company, i): void {
        this.selectedMembers(val, id, j, i, company);
    }

    public selectedMembers(val, id, j, i?, company?): void {
        const { checked } = val.target; // stored checked value true or false
        if (checked) {
            if (!this.membersarray.includes(id)) {
                this.membersarray.push(id);
            }
        } else {
            const index = this.membersarray.findIndex((list): any => list === id); // Find the index of stored id
            this.membersarray.splice(index, 1);
        }
        this.getMembers[j].checked = checked;
        this.tempGetMembers[j].checked = checked;

        if (this.getCompany && this.getCompany.length > 0) {
            this.getCompany[i].companyMembers[j].checked = checked;
            const curCompanyObj = this.getCompany.find((t): any => company.companyId === t.companyId);
            const allEqual = this.getCompany[i].companyMembers.every((vals): any => vals.checked === true);
            curCompanyObj.checked = allEqual;
        }
    }

    public selectedCompany(val, company, i): void {
        const { checked } = val.target; // stored checked value true or false
        if (checked) {
            if (!this.companyarray.includes(company.companyId)) {
                this.companyarray.push(company.companyId);
            }
        } else {
            const index = this.companyarray.findIndex((list): any => list === company.companyId); // Find the index of stored id
            this.companyarray.splice(index, 1);
        }
        this.getCompanyMembersList(company, i, val);
        // this.getMembers[i].checked = checked;
        // this.tempGetMembers[i].checked = checked;
    }

    public getAlbumById(id): void {
        const queryParams = `?albumId=${id}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.membersarray = [];
        this.api.getAlbumByAlbumId(queryParams).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.albumEdit = true;
                this.album = res.entity.album;
                this.album.description = res.entity.album.description && res.entity.album.description.text ? res.entity.album.description.text : '';
                this.albumVisibility = res.entity.album.albumVisibility;
                this.currentlyChecked = res.entity.album.albumVisibility === 'PRIVATE' ? 1 : 0;
                this.membersarray = res.entity.memberId;
                this.getMemberList(res.entity.memberId);
            } else {
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
        this.showCreate = true;
    }

    public selectCheckBox(targetType: CheckBoxType): void {
        // Clear the currentlyChecked variable if the checkbox was already checked
        if (this.currentlyChecked === targetType) {
            this.currentlyChecked = CheckBoxType.NONE;
            return;
        }

        this.currentlyChecked = targetType;

        // Set album visibility
        this.albumVisibility = this.currentlyChecked === 0 ? 'PUBLIC' : 'PRIVATE';

        // Get member list if the album is set to private
        if (this.albumVisibility === 'PRIVATE') {
            this.getMemberList('');
        }
    }

    public showCollapsePanel(i): void {
        this.openPanel = i;
    }

    public getCompanyMembersList(details, i, checkedVal?): void {
        const { projectId, companyId } = details;
        const data = {
            userId: localStorage.getItem('userId'),
            teamId: sessionStorage.getItem('projectId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            companyId,
            projectId,
            pagenumber: 0,
            pagerecord: 0,
        };

        this.loading = true;
        this.workspaceService.getCompanyMembersByProject(data).subscribe((res): any => {
            res.entity.forEach((element): void => {
                element.checked = this.membersarray.includes(element.userId);
            });
            this.getCompany[i].companyMembers = res.entity;
            this.loading = false;
        },
            (err): any => {
                this.loading = false;
                this.getCompany[i].companyMembers = [];
            });

        if (checkedVal) {
            const { checked } = checkedVal.target;
            if (checked) {
                this.getCompany[i].companyMembers.forEach((element): void => {
                    element.checked = true;
                    this.membersarray.push(element.userId);
                });
            } else {
                this.getCompany[i].companyMembers.forEach((element): void => {
                    const getPosition = this.membersarray.findIndex((x): any => x === element.userId);
                    if (getPosition !== -1) {
                        element.checked = false;
                        this.membersarray.splice(getPosition, 1);
                    }
                });
            }
        }
    }

    public getMemberList(memberList, searchInput?): any {
        const data = {
            userId: localStorage.getItem('userId'),
            teamId: sessionStorage.getItem('projectId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: this.userSearch,
            pagenumber: 0,
            pagerecord: 0,
        };
        // this.workspaceService.getAllPeopleListByProjectId(data).subscribe((res): any => {
        this.workspaceService.getMembersForAlbum(data).subscribe((res): any => {
            if (res && res.status.status === 200) {
                if (res.length !== 0) {
                    this.getMembers = res.entity.userList;


                    if (res.entity.companyList !== null) {
                        if (this.getCompany.length === 0 || searchInput) {
                            this.openPanel = null;
                            this.getCompany = res.entity.companyList;
                        } else {
                            res.entity.companyList.forEach((ele): any => {
                                const alreadyAvailable = this.getCompany.find((r): any => r.companyId === ele.companyId);
                                if (alreadyAvailable) {
                                    alreadyAvailable.companyName = ele.companyName;
                                    alreadyAvailable.email = ele.email;
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    ele.companyMembers = [];
                                    this.getCompany.push(ele);
                                }
                            });
                        }
                    } else {
                        this.getCompany = [];
                    }

                    if (this.membersarray && this.membersarray.length > 0) {
                        for (let index = 0; index < this.membersarray.length; index++) {
                            for (let i = 0; i < this.getMembers.length; i++) {
                                if (this.getMembers[i].user.id === this.membersarray[index]) {
                                    this.getMembers[i].checked = true;
                                    break;
                                }
                            }
                            if ((index + 1) === this.membersarray.length) {
                                this.tempGetMembers = this.getMembers;
                            }
                        }
                    } else {
                        for (let i = 0; i < this.getMembers.length; i++) {
                            if (this.getMembers[i].roleType.id === 1 || this.getMembers[i].roleType.id === 2 || this.getMembers[i].roleType.id === 3) {
                                this.getMembers[i].checked = true;
                                if (this.userId !== this.getMembers[i].user.id) {
                                    this.membersarray.push(this.getMembers[i].user.id);
                                }
                            }

                            if ((i + 1) === this.getMembers.length) {
                                this.tempGetMembers = this.getMembers;
                            }
                        }
                    }
                }
            }
        });
    }

    public hideMembers(): void {
        this.addMembers = false;
    }

    public showMembers(): void {
        this.addMembers = true;
    }

    public viewByList(): void {
        this.byGrid = false;
        this.byList = true;
    }

    public getAlbum(): void {
        this.sortType = 'day';
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            search: '',
            sortParam: 'createdOn',
            sortType: 'desc',
            pagenumber: 0,
            pagerecord: 0,
            sortviewby: 'day',
            timeZone: moment().tz(moment.tz.guess()).format('z'),
        };

        this.getAlbumList(payload);
    }

    public getAlbumList(data): any {
      this.errorMessage = '';
      this.loadingPage = true;
      this.api.getAlbumByTeamId(data).subscribe(
        (res): any => {
          this.albumData = res?.entity?.listview ?? [];
          this.errorMessage = this.albumData.length > 0 ? '' : 'It seems no albums are available';
          this.loadingPage = false;
        },
      );
    }

    public getAlbumsort(): void {
            this.toggleOrderBy();
            this.updateDateText();
    
            if (this.viewMode === 'Folders') {
                const payload = this.getPayload();
                this.getAlbumList(payload);
            } else {
                this.getPhotoFeed();
            }
        }
    
    private toggleOrderBy(): void {
        this.orderBy = this.orderBy === 'desc' ? 'asc' : 'desc';
    }
    
    private updateDateText(): void {
        this.DateText = this.orderBy === 'desc' ? 'Most Recent' : 'Oldest';
    }
    
    private getPayload(): any {
        return {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            search: '',
            sortParam: 'createdOn',
            sortviewby: this.sortType,
            sortType: this.orderBy,
            pagenumber: 0,
            pagerecord: 0,
        };
    }

    public getAlbumMonthOrYear(type): void {
        this.sortType = type;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            search: '',
            sortParam: 'createdOn',
            sortviewby: this.sortType,
            sortType: this.orderBy,
            pagenumber: 0,
            pagerecord: 0,
        };
        if (this.viewMode === 'Folders') {
            this.getAlbumList(payload);
        } else {
            this.getPhotoFeed();
        }
    }

    public openModal(albumId: any, index: any, index1: any): void {
        this.albumId = albumId;
        this.index = index;
        this.index1 = index1;
        this.modalRef = this.modalService.show(
            this.exampleModal,
            { ...this.config, class: 'modal-sm popup-center' },
        );
    }

    public removePendingMember(index: number): void {
      if (this.PendingMembers && this.PendingMembers.length > 0) {
        this.PendingMembers.splice(index, 1);
      }
    }

    public onSubmit(albumForm: any): void {
        // if (this.albumVisibility === 'PRIVATE') {
        //     if (this.membersarray.length === 0 && this.PendingMembers.length === 0) {
        //         this.toastr.error('Please choose Member for Private Album');
        //         return;
        //     }
        // }
        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            albumName: this.album.albumName,
            description: this.album.description,
            lattitude: this.lat,
            longitude: this.lng,
            albumMembers: this.albumVisibility === 'PUBLIC' ? [] : this.membersarray.length > 0 ? this.membersarray : [],
            albumVisibility: this.albumVisibility,
            albumId: this.album.albumId,
        };
        this.toastr.clear();

        if (!this.albumEdit) {
            this.saveAlbum(payload, albumForm);
        } else {
            this.updateAlbum(payload, albumForm);
        }
    }


    public saveAlbum(payload, albumForm): void {
        this.api.CreateAlbum(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res) {
                    if (res.status && res.entity && res.status.status === 200) {
                        albumForm.form.reset();
                        this.toastr.success(res.status.msg);
                        for (let indexs = 0; indexs < this.PendingMembers.length; indexs++) {
                            const element = this.PendingMembers[indexs];
                            const DataPayload = {
                                usertype: 1,
                                userId: localStorage.getItem('userId'),
                                workSpaceName: sessionStorage.getItem('workspacename'),
                                teamId: sessionStorage.getItem('projectId'),
                                albumidList: [res.entity.albumId],
                                email: element.email,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                companyName: element.companyName,
                                roleType: element.roleType,
                            };
                            this.workspaceService.projectInvite(DataPayload, 'pixlyProfile/inviteAsFullMember').subscribe((albumres): any => { });
                            if ((indexs + 1) === this.PendingMembers.length) {
                                this.tempGetMembers = [];
                                this.PendingMembers = [];
                                this.getMembers = [];
                            }
                        }

                        this.getAlbum();
                        this.currentlyChecked = null;
                        this.addMembers = false;
                        this.showCreate = false;
                        this.membersarray = [];
                        this.albumEdit = false;
                        this.showInviteBar = false;
                        this.createBuilderForm();
                        this.viewTab = 'projectmembers';
                        this.openPanel = null;
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.apis.handleError(err);
                this.submitted = false;
            },
        );
    }

    public updateAlbum(payload, albumForm): void {
        this.api.updateAlbum(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res) {
                    if (res.status && res.entity && res.status.status === 200) {
                        albumForm.form.reset();
                        this.toastr.success(res.status.msg);
                        for (let index = 0; index < this.PendingMembers.length; index++) {
                            const element = this.PendingMembers[index];
                            const Data = {
                                usertype: 1,
                                userId: localStorage.getItem('userId'),
                                workSpaceName: sessionStorage.getItem('workspacename'),
                                teamId: sessionStorage.getItem('projectId'),
                                albumidList: [res.entity.albumId],
                                email: element.email,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                roleType: element.roleType,
                                companyName: element.companyName,
                            };
                            this.workspaceService.projectInvite(Data, 'pixlyProfile/inviteAsFullMember').subscribe((albumres): any => { });
                            if ((index + 1) === this.PendingMembers.length) {
                                this.PendingMembers = [];
                                this.tempGetMembers = [];
                                this.getMembers = [];
                            }
                        }

                        this.getAlbum();
                        this.currentlyChecked = null;
                        this.showCreate = false;
                        this.addMembers = false;
                        this.membersarray = [];
                        this.albumEdit = false;
                        this.showInviteBar = false;
                        this.createBuilderForm();
                        this.openPanel = null;
                        this.viewTab = 'projectmembers';
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public createBuilderForm(): any {
        this.inviteForm = this.inviteBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            roleType: ['4', Validators.required],
            companyName: [''],
            email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
        });
    }

    public inviteMethod(inviteForm): void {
        if (!inviteForm || inviteForm.status !== 'VALID') {
            return;
        }
    
        const data = this.PendingMembers.filter(x => x.email.toLowerCase().includes(inviteForm.value.email.toLowerCase()));
        const data1 = this.getMembers.filter(x => x.user.email.toLowerCase().includes(inviteForm.value.email.toLowerCase()));
    
        if (data.length > 0 || data1.length > 0) {
            this.Invitesubmitted = false;
            this.toastr.clear();
            this.toastr.error('The email entered already exists, Please try with different email !');
        } else {
            this.tempGetMembers = this.getMembers;
            this.userSearch = '';
            this.Invitesubmitted = true;
            this.PendingMembers.push(inviteForm.value);
            this.showInviteBar = false;
            this.showInvite = false;
            this.Invitesubmitted = false;
            this.createBuilderForm();
        }
    }

    public searchFilter(): any {
        // if (this.userSearch && this.userSearch.trim()) {
        //     // tslint:disable-next-line:max-line-length
        //     // this.tempGetMembers = this.getMembers.filter((x): any => x.user.email.toLowerCase().includes(this.userSearch.trim().toLowerCase()));
        // } else {
        //     this.tempGetMembers = this.getMembers;
        // }
    }

    public ViewInvite(): void {
        this.showInviteBar = !this.showInviteBar;
        if (this.showCreate) {
            this.inviteForm.get('email').setValue(this.userSearch);
        }
        if (this.userRoleList.length === 0 && this.showInviteBar) {
            this.getUserType();
        }
    }

    private getUserType(): void {
        this.workspaceService.getUserType().subscribe((res): any => {
            if (res && res.entity) {
                this.userRoleList = res.entity.filter(role => (sessionStorage.getItem('projectId') && role.id === 4));
            } else {
                this.userRoleList = [];
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    public savePhoto(albumId): any {
        this.toastr.clear();
        const imageKeyList = JSON.parse(localStorage.getItem('imageKeyList'));
        if (imageKeyList.length > 0) {
            this.submitted = true;
            this.loadingBar.start();
            const data: any = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                albumId: albumId,
                photoName: [],
                key: [],
                lattitude: [],
                longitude: [],
                direction: []
            };
            imageKeyList.forEach((key, index) => {
                data.photoName.push(key);
                data.key.push(key);
                data.lattitude.push(+this.lat);
                data.longitude.push(+this.lng);
                data.direction.push('');

                if (index === imageKeyList.length - 1) {
                    this.toSavePhoto(data);
                }
            });
        } else {
            this.toastr.error('Sorry there is no photos to paste');
        }
    }

    public toSavePhoto(data): any {
        this.api.uploadPhotos(data).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.toastr.success('Photos added to album');
                this.submitted = false;
                this.loadingBar.complete();
                const deleteImageId = JSON.parse(localStorage.getItem('imageIdList'));
                if (deleteImageId && deleteImageId.length > 0) {
                    this.photoDelete(this.copyDatas.albumId, deleteImageId);
                }


                this.copyDatas = { albumId: '' };
                localStorage.removeItem('imageKeyList');
                this.location.replaceState('/project/albums');
                this.getAlbumListWithFilter();
                this.getRecentAlbum();
            } else {
                this.submitted = false;
                this.loadingBar.complete();
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.loadingBar.complete();
            this.apis.handleError(err);
        });
    }

    public photoDelete(albumIds, list): any {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: albumIds,
            photoId: list,
        };

        this.api.deletePhotoList(data).subscribe((res): any => {
            localStorage.removeItem('imageIdList');
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    public getAlbumListWithFilter(): any {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: sessionStorage.getItem('projectId'),
            search: '',
            sortParam: 'createdOn',
            sortviewby: this.sortType,
            sortType: this.orderBy,
            pagenumber: 0,
            pagerecord: 0,
        };

        this.getAlbumList(payload);
    }

    public getDateValue(value): any {
        if (value) {
            const datobj = value.split(' ');
            return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
        }
        return '';
    }

    public conCadSessionData(obj): any {
        return { ...this.apis.getSessionData(), ...obj };
    }

    public reportModal() {
      if (!this.albumIdList.length) {
        this.toastr.error('Please choose albumns');
        return;
      }
      this.pdfModalSelect = true;
    }

    public cancelPdf() {
        this.pdfModalSelect = false;
    }

    public generatePDF(): any {
        if(this.albumIdList.length === 0) {
            this.toastr.error('Please choose albumns');
            return;
        }
        const timeZoneFormat = moment.tz(moment.tz.guess()).zoneAbbr();
        this.pdfSubmitted = true;
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: sessionStorage.getItem('projectId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                reportType: this.selectedPDFType,
                timeZone:timeZoneFormat,
                albumIds: this.albumIdList,
            };
            this.toastr.clear();
            this.workspaceService.generatePdf(data).subscribe((res): any => {
                this.isPdfReport = false;
                this.pdfModalSelect = false;
                this.pdfSubmitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.albumIdList= [];
                    this.toastr.success(res.status.msg);
                } else {
                    this.toastr.error(res.status.msg);
                }
            }, (err): any => {
                this.pdfSubmitted = false;
                this.isPdfReport = false;
                if (err && err.status && err.status.status === 302) {
                    this.toastr.error(err.status.msg);
                }
            });
    }

    public albumChoose(data, type): any {
      const findAlbumIndex = this.albumIdList.findIndex(x => x === data.albumId);
      if (type) {
        if (data.photoId.length !== 0) {
          this.albumIdList.push(data.albumId);
          this.isPdfReport = true;
        }
      } else {
        if (findAlbumIndex > -1) {
          this.albumIdList.splice(findAlbumIndex, 1);
          if (this.albumIdList.length === 0) {
            this.isPdfReport = false;
          }
        }
      }
    }

    isVideo(awskey: string): boolean {
        const extension = awskey.split('.').pop();
        return this.videoExtentions.includes(extension);
    }
}
