import {
    Component, EventEmitter, Output, OnDestroy, ViewChild, TemplateRef,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SupportService } from 'src/app/services/support.service';
import { ApiService, LoginService, UserService } from 'src/app/services';



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy {

    public shouldShow = true;

    public showProject = true;

    public showSearchBar: boolean;

    public viewMode = 'Tags';

    public profileSrc: any;

    public headerProfileImage = '';

    public headunsub: any;

    public headunsub1: any;

    public projectId: string;

    public ownerOrAdmin = sessionStorage.getItem('usertype') === '1' || sessionStorage.getItem('usertype') === '2';

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public searchHistoryList = []

    public globalSearch = '';

    public result = false;

    @Output() private sidemenuToggle = new EventEmitter<boolean>();

    @Output() private projectMenuToggle = new EventEmitter<boolean>();

    


    public constructor(private support: SupportService, public userService: UserService,
        private loginService: LoginService, public router: Router, private api: ApiService) {
        // this.api.checkUrlData();
        this.projectId = sessionStorage.getItem('projectId');
        this.headunsub = this.support.headerProfileImage.subscribe((res): any => {
            if (res) {
                this.headerProfileImage =  res;
            } else {
                this.headerProfileImage = './assets/images/empty-img.png';
            }
        });

        this.headunsub1 = this.support.projectChange.subscribe((res): any => {
            if (res) {
                if (res === 'empty') {
                    this.projectId = '';
                } else {
                    this.projectId = res;
                }
            }
        });
    }

    public ngOnInit(): void {
        if (this.headerProfileImage === './assets/images/empty-img.png' || this.headerProfileImage === '') {
            this.getProfile();
        }
    }

    public ngOnDestroy(): any {
        this.headunsub.unsubscribe();
        this.headunsub1.unsubscribe();
    }

    public navigateToHome(): any {
        if (sessionStorage.getItem('usertype') && (sessionStorage.getItem('usertype') === '1' || sessionStorage.getItem('usertype') === '2')) {
            this.router.navigate(['/company/dashboard'], { queryParams: this.api.getSessionData() });
        } else if (sessionStorage.getItem('usertype')) {
            this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
        }
    }

    public sidebarToggle(): void {
        this.sidemenuToggle.emit((this.shouldShow = !this.shouldShow));
    }

    public showSearch(): void {
        this.showSearchBar = !this.showSearchBar;
    }

    public projectBarToggle(): void {
        this.projectMenuToggle.emit((this.showProject = !this.showProject));
    }

    public logout(): any {
        this.loginService.logout('').subscribe(
            (res): any => { },
        );
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
    }

    public cheking(value): any {
        let flag = true;
        if (value === '/project/feed') {
            this.globalSearch = '';
            flag = false;
        }
        return flag;
    }

    public getProfile(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        this.userService.viewProfile(payload).subscribe(
            (res): any => {
                if (res.entity.profileImage) {
                    this.headerProfileImage = this.cloudFrontURL + res.entity.profileImage;
                } else {
                    this.headerProfileImage = './assets/images/empty-img.png';
                }
            }, (err): any => {
                this.headerProfileImage = './assets/images/empty-img.png';
            },
        );
    }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }
}
