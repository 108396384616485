import { Component } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SocialAuthService } from '../../services/index';


@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
})
export class SocialLoginComponent {
    public modalRef: BsModalRef;

    public userDetails: any = {};

    public constructor(
        private service: SocialAuthService,
        private modalService: BsModalService,
    ) {
        // constructor
    }

    public tryFacebookLogin(template): void {
        this.service.doFacebookLogin()
            .then((res): void => {
                this.viewUserDetails(res, template);
            }, (err): void => {
                /* eslint-disable-next-line */
            });
    }

    public tryTwitterLogin(template): void {
        this.service.doTwitterLogin()
            .then((res): void => {
                this.viewUserDetails(res, template);
            }, (err): void => {
                /* eslint-disable-next-line */
            });
    }

    public tryGoogleLogin(template): void {
        this.service.doGoogleLogin()
            .then((res): void => {
                this.viewUserDetails(res, template);
            }, (err): void => {
                /* eslint-disable-next-line */
            });
    }

    public viewUserDetails(data, template): void {
        this.userDetails = '';
        this.modalRef = this.modalService.show(
            template,
            { class: 'modal-xl' },
        );
        this.userDetails = data;
    }
}
