/* eslint-disable no-unused-expressions */
import {
    Component, OnInit, Input, Output, EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoginService } from '../../services/auth/login/login.service';
import { ApiService } from '../../services/api_base/api.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnChanges {
    @Input() public resetPasswordToggle: boolean;

    @Output() public showresetevent = new EventEmitter<boolean>();

    @Input() isCompanyProfile = false;

    public togglePasswordOne = false;

    public togglePasswordTwo = false;

    public submitted = false;

    public resestForm: FormGroup;

    public resetPassword: any = {};

    public showreset: boolean ;

    public constructor(private toastr: ToastrService,
        private router: Router,
        private loginService: LoginService, private apis: ApiService) {
        // constructor
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resetPasswordToggle && changes.resetPasswordToggle.currentValue) {
            this.resetPassword = {
                password: '',
                conpassword: '',
            };
            this.submitted = false;
            this.showreset = true;
        }else if (changes.resetPasswordToggle && !changes.resetPasswordToggle.currentValue) {
            this.showreset = false;
        }
    }

    public ngOnInit(): void {
        this.resetPassword = {
            password: '',
            conpassword: '',
        };
    }

    public ToggleResetpassword(): any {
        this.showresetevent.emit(this.showreset);
    }

    public passwordToggle(id: any): void {
        if (id === 1) {
            this.togglePasswordOne = !this.togglePasswordOne;
            // this.togglePasswordOne === false
            //     ? this.passwordTypeOne = 'password'
            //     : this.passwordTypeOne = 'text';
        } else {
            this.togglePasswordTwo = !this.togglePasswordTwo;
            // this.togglePasswordTwo === false
            //     ? this.passwordTypeTwo = 'password'
            //     : this.passwordTypeTwo = 'text';
        }
    }

    public onSubmit(resestForm: any): void {
        if (resestForm && resestForm.form && resestForm.form.status === 'VALID') {
            this.submitted = true;
            const data = {
                verificationCode: localStorage.getItem('otp'),
                password: resestForm.form.value.password,
            };
            this.toastr.clear();
            if (this.isCompanyProfile) {
                this.apis.resetPassword({
                    userId: localStorage.getItem('company-userid'),
                    password: resestForm.form.value.password
                }).subscribe(res => {
                    localStorage.removeItem('company-userid');
                    this.toastr.success('Successfully resetted the password.');
                    this.ToggleResetpassword();
                }, err => {
                    this.apis.handleError(err);
                });
            } else {
                this.loginService.resetPwd(data).subscribe((res): any => {
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200 && res.entity && res.entity.login && res.entity.login.access_token) {
                        this.toastr.success('Thank you for verify your account');
                        localStorage.setItem('token', res.entity.login.access_token);
    
                        localStorage.removeItem('password');
                        localStorage.removeItem('otp');
                        localStorage.removeItem('type');
                        setTimeout((): any => {
                            this.router.navigate(['/company'], { queryParams: this.apis.getSessionData() });
                        }, 300);
                    } else if (res && res.status && res.status.status === 200 && res.entity &&
                        res.entity.login && !res.entity.login.access_token) {
                        this.toastr.error(res.entity.login.error_description);
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                }, (err): any => {
                    this.submitted = false;
                    this.apis.handleError(err);
                });
            }
        }
    }
}
