import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from '../services/support.service';
import { AlbumserviceService } from '../services/albumservice.service';
import { ApiService } from '../services/api_base/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-add-photo',
    templateUrl: './add-photo.component.html',
    providers: [DatePipe],
    styles: [`
        .selected-card {
            border: 1px solid blue;
        }
    `]
})
export class AddPhotoComponent implements OnInit, OnDestroy {
    public filterSidemenu = false;

    public errorMessage = false;

    public photoFeedList = [];

    public photoId: any;

    public albumId: any;

    public value: any;

    public colorTheme = 'theme-default';

    public Share: any = {};

    public datePickerConfig: Partial<BsDatepickerConfig>;

    public Invite = false;

    public validation = false;

    public submitted = false;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public date: any;

    public selector1 = '.notification-scroll';

    public selector2 = '.notification-scroll-grid';

    public startingAt = 1;

    public pageRecord = 10;

    public globalSearchStrings = '';

    public fromDate = '';

    public endDate = '';

    public tagFilter: string[] = [];

    public peopleFilter: string[] = [];

    public albumFilter: string[] = [];

    public unsub: any;

    public searchCount = 0;

    public filterCount = 0;

    public feedIconDetails = false;
    
    public currentTab = 'List';

    public choosedImage = [];

    public hidedelete: any;

    public userId = localStorage.getItem('userId');

    public modalRef: BsModalRef;

    public choosedImageIdList = [];

    @ViewChild('photodeleteModal') public photodeleteModal: TemplateRef<any>;

    public list2 = [];

    public config = {
        ignoreBackdropClick: true,
    };

    private hasCalledGetPhotoFeed: boolean = false;

    public usertypesub: any;

    usertype = '';

    public constructor(private support: SupportService,
        private datePipe: DatePipe,
        private api: AlbumserviceService, private toastr: ToastrService,
        private apis: ApiService, private loadingBar: LoadingBarService,
        private modalService: BsModalService) {

        this.unsub = this.support.globalSearch.subscribe((res): void => {
            this.getGlobalSerchData(res);
        });

        this.usertypesub = this.support.usertype.subscribe((res): any => {
            if (res) {
              if (typeof res === 'number') {
                this.usertype = res.toString();
              } else {
                this.usertype = res;
              }
            } else {
              this.usertype = sessionStorage.getItem('usertype');
            }
        });

        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.datePickerConfig = {
            dateInputFormat: 'YYYY-MM-DD', minDate: date,
            adaptivePosition: true, containerClass: this.colorTheme
        };
    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.Share = {
            email: '',
            firstName: '',
            lastName: '',
            expires: '',
        };
    }

    public ngOnDestroy(): any {
        this.unsub.unsubscribe();
        this.support.globalSearch.next('');
    }

    public onScroll(): any {
        this.startingAt += 1;
        this.getPhotoFeed();
    }

    public onGridScroll(): any {
        this.startingAt += 1;
        this.getPhotoFeed();
    }

    public getPhotoFeed(): any {
        this.loadingBar.start();
        this.errorMessage = false;
        // this.searchCount = 0;
        const payload = {
            search: this.globalSearchStrings,
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamId: [sessionStorage.getItem('projectId')],
            pagenumber: this.startingAt,
            pagerecord: this.pageRecord,
            tagId: this.tagFilter,
            albumId: this.albumFilter,
            userFilterId: this.peopleFilter,
            fromDate: this.fromDate,
            endDate: this.endDate,
        };
        this.api.geFeedsListApi(payload).subscribe((res): any => {
            this.loadingBar.stop();
            this.submitted = false;
            // this.searchCount = 0;
            if (res && res.status && res.status.status === 200) {
                if (this.startingAt === 1) {
                    this.photoFeedList = res.entity.photoList;
                    this.searchCount = res.entity.count;
                } else {
                    res.entity.photoList.forEach((element): void => { this.photoFeedList.push(element); });
                }
            } else if (this.startingAt === 1) {
                this.photoFeedList = [];
                this.searchCount = 0;
                this.errorMessage = true;
            }
        }, (err): any => {
            this.submitted = false;
            this.loadingBar.stop();
        });
    }

    public showInvite(): void {
        this.validation = false;
        this.Invite = !this.Invite;
    }

    public ResetForm(ShareForm): void {
        ShareForm.form.reset();
        this.photoId = '';
        this.albumId = '';
    }

    public getDataforShare(val: any): void {
        this.photoId = val.photoId;
        this.albumId = val.albumId;
    }

    public groupBy(collection: any, property: string): any {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current): any => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }
            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map((key): any => ({ key, value: groupedCollection[key] }));
    }

    public closeFilterSidemenu(event): void {
        this.filterSidemenu = event;
    }

    public onSubmit(ShareForm: any): void {
        this.validation = true;
        if (!ShareForm.form.valid) {
            return;
        }
        this.value = this.datePipe.transform(this.Share.expires, 'yyyy-MM-dd');
        if (this.value < this.date) {
            this.toastr.error('Please dont choose a prior date');
            return;
        }
        this.submitted = true;
        const params = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            email: this.Share.email,
            firstName: this.Share.firstName,
            lastName: this.Share.lastName,
            expires: this.value,
            albumId: this.albumId,
            photoId: this.photoId,
            shareType: 1
        };
        this.api.ShareImg(params).subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    ShareForm.form.reset();
                    this.submitted = false;
                    this.toastr.success(res.status.msg);
                    this.photoId = '';
                    this.albumId = '';
                    this.Invite = false;
                    this.validation = false;
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public getFilterObject(event): void {
        this.searchCount = 0;
        this.filterCount = event.filtertabvalues.peopleValues.length + event.filtertabvalues.albumValues.length + event.filtertabvalues.tagValues.length;
        this.startingAt = 1;
        this.fromDate = '';
        this.endDate = '';
        this.tagFilter = event.filtertabvalues.tagValues;
        this.peopleFilter = event.filtertabvalues.peopleValues;
        this.albumFilter = event.filtertabvalues.albumValues;

        if (event.fromDate !== null && event.endDate !== null) {
            event.fromDate.setHours(0);
            event.fromDate.setMinutes(0);
            event.fromDate.setSeconds(0);
            event.toDate.setHours(23);
            event.toDate.setMinutes(59);
            event.toDate.setSeconds(59);
            this.fromDate = moment.utc(event.fromDate).format('yyyy-MM-DD HH:mm:ss');
            this.endDate = moment.utc(event.toDate).format('yyyy-MM-DD HH:mm:ss');
        }
        this.getPhotoFeed();
    }

    public getGlobalSerchData(searchStr): void {
        // this.searchCount = 0;
        this.submitted = true;
        this.globalSearchStrings = searchStr;
        this.startingAt = 1;
        this.getPhotoFeed();
    }

    public conCadSessionData(obj): any {
        return { ...this.apis.getSessionData(), ...obj };
    }

    changeTab (tab) {
        this.currentTab = tab
    }

    public albumChoose(photo): any {
        const imageData = {
            albumId: photo.albumId,
            photoUrl: this.cloudFrontURL + photo.awskey,
            key: photo.awskey,
            authorId: photo.author && photo.author.id,
            photoId: photo.photoId
        };
        const index = this.choosedImage.findIndex((image) => image.photoId === photo.photoId);
        if (index !== -1) {
            this.choosedImage.splice(index, 1);
        } else {
            this.choosedImage.push(imageData);
        }
    }
    
    // public imagePushToList(photo): any {
    //     this.choosedImage.push(photo);
    //     this.hidedelete = this.choosedImage.findIndex((g): any => g.authorId !== this.userId);
    // }
    
    // public imageSpliceFromList(photo): any {
    //     const index = this.choosedImage.findIndex(image => image.albumId === photo.albumId && image.photoId === photo.photoId);
    //     if (index > -1) {
    //         this.choosedImage.splice(index, 1);
    //         this.hidedelete = this.choosedImage.findIndex(g => g.authorId !== this.userId);
    //     }
    // }
    

    // public albumChoose(photo, type): any {
    //     const imageData = {
    //         albumId: photo.albumId,
    //         photoUrl: this.cloudFrontURL + photo.awskey,
    //         key: photo.awskey,
    //         authorId: photo.author && photo.author.id,
    //         photoId: photo.photoId
    //     };

    //     if (this.choosedImage && this.choosedImage.length > 0) {
    //         if (type === true) {
    //             const inarry = this.choosedImage.findIndex((g): any => g.photoId === photo.photoId);
    //             if (inarry === -1) {
    //                 this.imagePushToList(imageData);
    //             }
    //         } else if (type === false) {
    //             this.imageSpliceFromList(imageData);
    //         }
    //     } else {
    //         this.choosedImage.push(imageData);
    //         this.hidedelete = this.choosedImage.findIndex((g): any => g.authorId !== this.userId);
    //     }
    //     console.log(this.choosedImage)
    // }

    // public imagePushToList(id): any {
    //     this.choosedImage.push(id);
    //     this.hidedelete = this.choosedImage.findIndex((g): any => g.authorId !== this.userId);
    // }

    // public imageSpliceFromList(photo): any {
    //     console.log(this.choosedImage)
    //     const index = this.choosedImage.findIndex(image => image.albumId === photo.albumId);
    //     console.log(index)
    //     if (index > -1) {
    //       this.choosedImage.splice(index, 1);
    //       this.hidedelete = this.choosedImage.findIndex(g => g.authorId !== this.userId);
    //     }
    //   }

    //   public isImageInChoosedImage(photo): boolean {
    //     // Check if the photo is in this.choosedImage based on albumId
    //     return this.choosedImage.some((image) => image.albumId === photo.photoId);
    //   }

      public toggleImageSelection(photo): void {
        // Toggle the selection of the photo based on albumId
        const index = this.choosedImage.findIndex((image) => image.albumId === photo.photoId);
    
        if (index !== -1) {
            // If the photo is already in choosedImage, remove it
            this.choosedImage.splice(index, 1);
        } else {
            // If the photo is not in choosedImage, add it
            this.choosedImage.push(photo);
        }
    }
    
    public isImageInChoosedImage(photo): boolean {
        return this.choosedImage.some((image) => image.photoId === photo.photoId);
    }

      public downloadImage(): any {
        this.submitted = true;
        this.loadingBar.start();
        if (this.choosedImage.length) {
            this.downloadImages(this.choosedImage);
        }
    }

    public downloadImages(images): void {
        this.submitted = true;
        this.loadingBar.start();
      
        const S3Client = this.api.getS3Credential();
        const that = this;
        const downloadQueue = Array.isArray(images) ? [...images] : [images]; // Create a copy of the images array
      
        // Function to process and download images sequentially
        function processNextImage() {
          if (downloadQueue.length > 0) {
            const imageData = downloadQueue.shift(); // Get the next image to download
            S3Client.getObject({ Bucket: environment.kIMAGEBUCKET, Key: imageData.key }, (error, data) => {
              if (error != null) {
                console.log(`Failed to retrieve an object: ${error}`);
                // Continue to the next image even if there's an error
                processNextImage();
              } else {
                const ContentType = 'ContentType';
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([new Uint8Array(data.Body)], { type: data[ContentType] }));
                link.download = imageData.key;
                link.click();
      
                // Continue to the next image
                processNextImage();
              }
            });
          } else {
            // All images have been downloaded
            that.choosedImage = [];
            that.submitted = false;
            that.loadingBar.complete();
            that.uncheckElements();
          }
        }
      
        // Start the image download process
        processNextImage();
      }

      public uncheckElements(): any {
        const uncheck = document.getElementsByTagName('input');
        for (let i = 0; i < uncheck.length; i++) {
            if (uncheck[i].type === 'checkbox') {
                uncheck[i].checked = false;
            }
        }
    }

    public openModal3(): any {
        this.modalRef = this.modalService.show(
            this.photodeleteModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );

        this.choosedImageIdList = this.getImageIdList();
    }

    public getImageIdList(): any {
        this.list2 = [];
        for (let index = 0; index < this.choosedImage.length; index++) {
            this.list2.push(this.choosedImage[index].albumId);

            if ((index + 1) === this.choosedImage.length) {
                return this.list2;
            }
        }
    }

    public yesMethodDelete(): any {
        if (this.choosedImage && this.choosedImage.length > 0) {
            this.choosedImage.forEach((photo) => {
                const data = {
                    userId: localStorage.getItem('userId'),
                    workSpaceName: sessionStorage.getItem('workspacename'),
                    albumId: photo.albumId,
                    photoId: [photo.photoId],
                };
                
                this.deletePhotoApi(data);
            });
            // this.getPhotoFeed();
        }
    }

    public deletePhotoApi(data): void {
        const deletedPhotoIds = [];
    
        this.api.deletePhotoList(data).subscribe(
            (res) => {
                if (res && res.status && res.status.status === 200) {
                    this.choosedImage.forEach((deletedPhoto) => {
                        deletedPhotoIds.push(deletedPhoto.photoId);
                    });
                } else {
                    this.toastr.error(res?.status?.msg || 'Deletion failed');
                }
            },
            (err) => {
                this.apis.handleError(err);
            },
            () => {
                this.photoFeedList = this.photoFeedList.filter(photo => !deletedPhotoIds.includes(photo.photoId));
    
                if (deletedPhotoIds.length > 0) {
                    this.modalRef.hide();
                    this.toastr.success(`Deleted successfully`);
                    this.choosedImage = [];
                }
            }
        );
    }
    
    

    
}
