/* eslint-disable max-len */
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { ApiService } from '../../services/api_base/api.service';
import { LoginService, SocialAuthService } from '../../services/index';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    public togglePassword = false;

    public showforget: boolean;

    public submitted = false;

    public loginform: FormGroup;

    public Login: any = {};

    public showChooseWorkspace: boolean;

    public config = {
        ignoreBackdropClick: true,
    };

    public modalRef: BsModalRef;

    public customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            740: {
                items: 1,
            },
            940: {
                items: 1,
            },
        },
        nav: true,
    };

    public userEntity: any;

    public datas: any;

    public partialRegData: any;

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    public unverified = false;

    public showNav = false;

    @Output('otptype') public otptype = new EventEmitter();

    public showCreate: boolean;

    public constructor(
        private service: SocialAuthService, private toastr: ToastrService, private modalService: BsModalService,
        private apiService: ApiService, private router: Router, private loginService: LoginService,
        private activeRoute: ActivatedRoute, private workspaceService: WorkspaceServiceService,
    ) {
        this.activeRoute.queryParams.subscribe((params): any => {
            console.log(params);
            this.datas = params;
        });
    }

    public ngOnInit(): void {

        if (!localStorage.getItem('token') || !localStorage.getItem('userId')) {
            this.router.navigate(['/login']);
        } else if (localStorage.getItem('userId') === this.datas.userid) {
            this.navigateToWorkspace();
        } else {
            this.toastr.error('Someother user is logged in. Please logout an existing user before you enter through your link. ');
            localStorage.clear();
            this.router.navigate(['/login']);
            // this.router.navigate(['/login']);
        }

        // if (localStorage.getItem('token') && localStorage.getItem('userId')) {
        //     console.log('tokennn');
        //     this.navigateToWorkspace();
        // } else {
        //     console.log('no token');
        //     localStorage.clear();
        // }

        this.Login = {
            email: '',
            password: '',
        };

        if (this.datas && this.datas.email) {
            this.Login.email = this.datas.email;
        }

        if (this.datas['VERIFY_TEAM_MEMBER']) {
            this.verifyUserInvite();
        }
    }

    public verifyUserInvite(): any {
        const { VERIFY_TEAM_MEMBER, userid, workSpaceName } = this.datas;
        this.loginService.verifyUserInvite({ verificationCode: VERIFY_TEAM_MEMBER, userId: userid, workSpaceName }).subscribe(
            (res): any => {
                this.toastr.success('Successfully verified your account.');
            },
            (err): any => {
                this.toastr.error('Failed to verify account.');
            },
        );
    }

    public getOtpType(event, loginform): any {
        console.log(event);
    }

    public onSubmit(loginform: any): void {
        if (this.submitted) {
            return;
        }
        this.toastr.clear();
        this.submitted = true;
        localStorage.setItem('email', this.Login.email);
        const payload = `&username=${this.Login.email}&password=${this.Login.password}`;
        this.loginService.login(payload).subscribe(
            (res): any => {
                this.toastr.clear(); // Clear any existing toastr messages
                if (res.userName) {
                    const splitvalue = res.userName.toString().split(' ');
                    if (splitvalue.length > 1) {
                        localStorage.setItem('username', splitvalue[0]);
                    } else {
                        localStorage.setItem('username', res.userName);
                    }
                }
                if (res.error_code === 3) {
                    console.log('errorrr 333333333');

                    this.toastr.error(res.error_description);
                    localStorage.setItem('userId', res.userid);
                    this.showNav = true;
                    this.unverified = true;

                    //     this.router.navigate(['/sendotp-sidenav'], { queryParams: {
                    //         email: localStorage.getItem('email')
                    //     } 
                    //  });
                    // this.router.navigate(['/verify-otp']);
                } else if (res.error_code === 4) {
                    this.toastr.error('Its seems you are not register with us, Please register with us');
                    this.router.navigate(['/signup']);
                } else if (res.error_code === 1) {
                    this.submitted = false;
                    this.toastr.error(res.error);
                } else if (res.error_code === 7) {
                    this.submitted = false;
                    this.toastr.error(res.error_description);
                } else if (res.error_code === 5) {
                    this.submitted = false;
                    this.openModal();
                    this.partialRegData = res;
                } else {
                    localStorage.removeItem('password');
                    localStorage.setItem('userId', res.userId);
                    localStorage.setItem('token', res.access_token);

                    if (this.datas && this.datas.subscription) {
                        sessionStorage.setItem('subscription', this.datas.subscription.toLowerCase() === 'pro' ? '2' : '1');
                    }

                    if (this.datas && this.datas.user === 'Pixly_user') {
                        console.log('invite ');
                        this.verifyUser();
                    } else {
                        this.submitted = false;
                        console.log('else   something   ');
                        setTimeout((): any => {
                            this.toastr.success('Welcome Back');
                            this.navigateToWorkspace();
                        }, 300);
                    }
                }
            },
            (err): any => {
                this.submitted = false;
                this.toastr.error('We apologize for the inconvenience, but our service is temporarily unavailable due to maintenance. Please check back later.');
            },
        );
    }

    public verifyUser(): void {
        const payload = {
            password: this.Login.password,
            workSpaceName: this.datas.workSpaceName,
            userId: this.datas.userid,
            verificationCode: this.datas.VERIFY_TEAM_MEMBER,

        };
        this.loginService.setPassword(payload).subscribe(
            (res): any => {
                if (res.status.status === 200) {
                    this.submitted = false;
                    if (res && res.entity && res.entity.login && res.entity.login.access_token) {
                        localStorage.setItem('token', res.entity.login.access_token);
                        this.toastr.success('Welcome Back');
                        this.navigateToWorkspace();
                    } else {
                        this.router.navigate(['/login']);
                    }
                }
            },
            (err): void => {
                this.submitted = false;
                this.toastr.error('Sorry something went wrong.., Please try again later.');
            }
        );
    }

    public increaseCount(): void {
        const payload = {
            userId: this.datas.userid,
            workSpaceName: this.datas.workSpaceName,
        };
        this.loginService.increaseCount(payload).subscribe((res): any => { });
    }

    public tryGoogleLogin(): void {
        this.service.doGoogleLogin()
            .then((res): void => {
                if (res && res.additionalUserInfo && res.additionalUserInfo.profile) {
                    this.registerUser(res.additionalUserInfo.profile);
                } else {
                    this.toastr.error('Sorry something went wrong.., Please try again later.');
                }
            }, (err): void => {
                /* eslint-disable-next-line */
            });
    }

    public registerUser(user): void {
        if (user) {
            const data = {
                origin: 3,
                email: user.email,
                firstName: user.given_name,
                lastName: user.family_name,
                gmailid: user.id,
                password: user.id,
                companyName: '',
                phoneCode: '',
                phoneNumber: '',
            };

            this.apiService.post(`${environment.loginContext}/register`, data).subscribe((res): any => {
                if (res && res.status && (res.status.status === 200 || res.status.status === 302)) {
                    this.userEntity = res.entity;
                    if (this.userEntity && this.userEntity.login && this.userEntity.login.access_token) {
                        localStorage.setItem('token', this.userEntity.login.access_token);
                        localStorage.setItem('userId', this.userEntity.userId);
                        localStorage.setItem('username', this.userEntity.firstName);
                        localStorage.setItem('email', this.userEntity.email);

                        setTimeout((): any => {
                            this.toastr.success('Welcome Back');
                            this.navigateToWorkspace();
                        }, 300);
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            });
        }
    }

    public ToggleForgetpassword(): any {
        this.showforget = !this.showforget;
    }

    public passwordToggle(): void {
        this.togglePassword = !this.togglePassword;
    }

    public navigateToWorkspace(): void {
        if (this.datas && this.datas.workSpaceName) {
            sessionStorage.setItem('workspacename', this.datas.workSpaceName);
            if (this.datas.projectId && this.datas.projectId !== 'null') {
                // localStorage.removeItem('email');
                localStorage.removeItem('username');
                sessionStorage.setItem('projectId', this.datas.projectId);
                if (this.datas.type && this.datas.type > 0) {
                    console.log('not invite ');
                    this.viewNowButtonflow();
                } else {
                    this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
                        (res): any => {
                            if (res && res.entity) {
                                if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                                    sessionStorage.setItem('usertype', res.entity.roleId);
                                    setTimeout((): any => {
                                        if (res.entity.roleId === 3) {
                                            this.router.navigate(['/project/dashboard'], { queryParams: this.apiService.getSessionData() });
                                        } else {
                                            this.router.navigate(['/project/feed'], { queryParams: this.apiService.getSessionData() });
                                        }
                                    }, 300);
                                }
                            }
                        },
                    );
                }
            } else {
                this.workspaceService.getUserTypeService();
            }
        } else {
            this.router.navigate(['/company'], { queryParams: this.apiService.getSessionData() });
        }
    }

    public openModal(): any {
        this.modalRef = this.modalService.show(
            this.exampleModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    yesMethod() {
        this.router.navigate(['/signup'], {
            queryParams: {
                email: this.partialRegData.email,
                firstname: this.partialRegData.firstname,
                lastname: this.partialRegData.lastname
            },
        });
        this.modalRef.hide();
    }

    public viewNowButtonflow(): any {
        console.log(this.datas);
        if (this.datas.type === '1') {
            console.log('yesssssssssssssss');
            this.router.navigate(['/project/albums'], { queryParams: { ...this.apiService.getSessionData(), ...{ albumId: this.datas.albumId } } });
        }
        if (this.datas.type === '4') {
            this.router.navigate(['/project/album-details'], { queryParams: { ...this.apiService.getSessionData(), ...{ albumId: this.datas.albumId } } });
        }
        if (this.datas.type === '10') {
            this.router.navigate(['/project/tag-library'], { queryParams: { ...this.apiService.getSessionData(), ...{ tagid: this.datas.type.tagTypeId } } });
        }
        if (this.datas.type === '7') {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: this.datas.albumId,
                    photoId: this.datas.photoId,
                    photoTagId: this.datas.photoTagId,
                    prevPage: '/project/album-details',
                },
            });
        }
        if (this.datas.type === '16') {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: this.datas.albumId,
                    photoId: this.datas.photoId,
                    photoTagId: this.datas.photoTagId,
                    photoTagid: this.datas.photoTagId,
                    prevPage: '/project/album-details',
                    todo: true,
                },
            });
        }
        if (this.datas.type === '12') {
            this.router.navigate(['/project/photo-tag'], {
                queryParams: {
                    albumId: this.datas.albumId,
                    photoId: this.datas.photoId,
                    photoTagId: this.datas.photoTagId,
                    photoTagid: this.datas.photoTagId,
                    prevPage: '/project/album-details',
                    comment: true,
                },
            });
        }
        if (this.datas.type === '11' || this.datas.type === '13') {
            this.router.navigate(['/project/todo-list'], {
                queryParams: {
                    albumId: this.datas.albumId,
                    photoId: this.datas.photoId,
                    todoId: this.datas.refId,
                    type: 'completed',
                    photoTagId: this.datas.photoTagId,
                    photoTagid: this.datas.photoTagId,
                    prevPage: '/project/feed',
                },
            });
        }
    }
}
