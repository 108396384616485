import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api_base/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-company-people',
  templateUrl: './company-people.component.html',
  styleUrls: ['./company-people.component.scss']
})
export class CompanyPeopleComponent implements OnInit {
  user: any = {};
  peopleList: any = [];
  public loadingPage: boolean = false;
  public addPersonForm: FormGroup;
  public cloudFrontURL = environment.KInsourceBucketUrl;
  public showreset: boolean;
  public userRoleList = [];
  public submittedForm: boolean = false;
  public submitted: boolean = false;
  public showAddPersonBar: boolean = false;
  public selectedCompanyId: any;
  public stopScroll = true;
  public pageNumber = 1;
  public pageRecord = 10;
  public isActivating = false;
  public isVerifying = false;
  public isEdit = false;

  constructor(
    private apis: ApiService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private workspaceService: WorkspaceServiceService,
    private support: SupportService
  ) { }

  ngOnInit(): void {
    this.createBuilderForm();
    this.getAllRoles();
    this.support.companyProfile.subscribe(res => {
      this.selectedCompanyId = res.selectedCompanyId;
      if (this.selectedCompanyId) {
        this.getCompanyUsers();
      }
    });
  }

  public createBuilderForm(): any {
    this.addPersonForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneCode: [''],
      phoneNumber: [''],
      roleType: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
    });
  }

  public getCompanyUsers(): void {
    this.peopleList = [];
    this.loadingPage = true;
    this.apis.getCompanyUsers(this.selectedCompanyId).subscribe((res): any => {
      if (res) {
        this.peopleList = res;
        this.loadingPage = false;
      } else {
        this.loadingPage = false;
      }
    });
  }

  toggleActivation(user) {
    const isActive = !user.active;
    this.apis.toggleActivation(isActive, { userId: user.userId }).subscribe((res): any => {
      this.toastr.success(`Successfully ${isActive ? 'activated' : 'inactivated'} your account.`);
      this.getCompanyUsers();
    },
      (err): any => {
        this.apis.handleError(err);
      });
  }

  resetPasswordAction(user) {
    this.showreset = !this.showreset;
    localStorage.setItem('company-userid', user.userId);
  }

  resetPassword() {
    this.showreset = !this.showreset;
  }

  verifyUser(user) {
    this.apis.verifyUser({ userId: user.userId }).subscribe(
      (res): any => {
        this.toastr.success('Successfully verified your account.');
        this.getCompanyUsers();
      },
      (err): any => {
        this.apis.handleError(err);
      },
    );
  }

  getAllRoles() {
    this.workspaceService.getUserType().subscribe((res): any => {
      if (res && res.entity) {
        for (let index = 0; index < res.entity.length; index++) {
          if (res.entity[index].id !== 1) {
            this.userRoleList.push(res.entity[index]);
          }
        }
      } else {
        this.userRoleList = [];
      }
    }, (err): any => {
      this.apis.handleError(err);
    });
  }

  public removeTrim(inviteForm) {
    if(this.isEdit){
      return;
    }
    this.addPersonForm.get('email').setValue(inviteForm.value.email.trim());
  }

  createPerson() {
    this.submittedForm = true;
    if (this.addPersonForm && this.addPersonForm.status === 'VALID') {
      this.submitted = true;
      this.addPersonForm.controls['email'].enable();
      const data = this.addPersonForm.value;
      this.apis.addUser(this.selectedCompanyId, data).subscribe((res): any => {
        this.toastr.success('Successfully '+(this.isEdit?'updated':'added')+' user');
        this.setUseraccessToAllProjects(data);
      }, err => {
        this.apis.handleError(err);
      });
    }
  }
  fixAccessIssues(user: any) {
    if(!user.userType || !user.userType.id) {
      this.toastr.warning('User does not have role. Please set a role and try again');
      this.editUserSidenav(user);
      return;
    }
    this.setUseraccessToAllProjects(user);
  }

  setUseraccessToAllProjects(user: any) {
    this.submitted = false;
    this.showAddPersonBar = false;
    const roleId = user.roleType? user.roleType: user.userType.id;
    if(!roleId) {
      console.log('User does not have role. Please set a role and try again');
    }
    this.apis.setUseraccessToAllProjects(                           
      this.selectedCompanyId,
      user.email,
      roleId)
      .subscribe((res): any => {
        this.toastr.success('Provisioning access is currently in progress and will be completed in a few minutes.');
        this.getCompanyUsers();
      }, (err): any => {
        this.apis.handleError(err);
      });
  }

  openSidenav() {
    this.showAddPersonBar = true;
    this.isEdit = false;
    this.addPersonForm.reset();
  }
  editUserSidenav(user: any) {
    this.showAddPersonBar = true;
    this.isEdit = true;
    this.addPersonForm
    .setValue({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneCode: user.phoneCode,
      phoneNumber: user.phoneNumber,
      roleType: user.userType? user.userType.id:0,
      email: user.email
    });
    this.addPersonForm.controls['email'].disable();
  }

  public onScrollUser(): any {
    if (this.stopScroll) {
      this.pageNumber += 1;
    }
  }
}
