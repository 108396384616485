
import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    TemplateRef,
    ElementRef,
    AfterViewInit,
    NgZone,
    Renderer2,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { SupportService } from '../services/support.service';
import { ApiService } from '../services/api_base/api.service';
import * as moment from 'moment-timezone';
import { GeolocationService } from '../services/geolocation.service';
import { environment } from 'src/environments/environment.prod';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'app-layout-project',
    templateUrl: './layout-project.component.html',
    styles: [`.position-relative {
        position: relative;
      }
      
      .location-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        color: green; /* Customize the color of the check icon */
        cursor: pointer;
      }
      .loading-icon {
        position: absolute;
        top: 35%;
        right: 8px;
        transform: translateY(-50%);
        color: green; /* Customize the color of the check icon */
        cursor: pointer;
      }
      `]
})
export class LayoutProjectComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('searchProject', { static: true }) searchInputElement: ElementRef;

    public projectForm: FormGroup;

    public errorMessage = '';

    public myProjectList: any;

    public selectedProjectId: any;

    public selectedProjectName: any;

    public showCreateProject = false;

    public submitted = false;

    public pdfSubmitted = false;

    public createProject: any = {};

    public layunsub: any;

    public projsub: any;

    public usertypesub: any;

    public subscribesub: any;

    public subscriptionType = 0;

    public workspaceProjectCount = 0;

    public showProSetting = false;

    public editTeamName = '';

    public edtiTeamIndex = 0;

    public editProjectId = '';

    public latitude: any;

    public longitude: any;

    @Output() public applayout = new EventEmitter<string>();

    @ViewChild('deletemodal') public deletemodal: TemplateRef<any>;

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    @ViewChild('editSearchInput') editSearchInput: ElementRef<HTMLInputElement>;

    public pdfModalSelect = false;

    public usertype: any;

    public selectedPDFType = "Photo summary";

    public config = {
        ignoreBackdropClick: true,
    };

    public modalRef: BsModalRef;

    public address: any;

    public editAddress: any;

    currentLocation = false;

    searchProject = ''

    filteredProjects = []

    isClone: boolean = false;

    public inviteForm: FormGroup;

    public editInvites = false;

    public showInviteBar: boolean;

    public submittedForm = false;

    public userRoleList = [];

    public addAllMembers: boolean = true;

    public companyName='';

    public constructor(private toastr: ToastrService, private router: Router, private renderer: Renderer2,
        private workspaceService: WorkspaceServiceService, private api: ApiService, private cdr: ChangeDetectorRef,
        private support: SupportService, private modalService: BsModalService, private ngZone: NgZone, private geolocationService: GeolocationService,
        private inviteBuilder: FormBuilder) {
        this.layunsub = this.support.projectChange.subscribe((res): any => {
            if (res) {
                if (res === 'empty') {
                    this.selectedProjectId = '';
                } else {
                    this.selectedProjectId = res;
                }
            }
        });

        this.projsub = this.support.allProject.subscribe((res): any => {
            if (res) {
                this.getAllProjects();
            }
        });

        this.usertypesub = this.support.usertype.subscribe((res): any => {
            if (res) {
                this.usertype = +res;
            } else {
                this.usertype = +sessionStorage.getItem('usertype');
            }
        });


        this.subscribesub = this.support.subscription.subscribe((res): any => {
            if (res) {
                this.subscriptionType = +res;
            } else {
                this.subscriptionType = +sessionStorage.getItem('subscription');
            }
        });

        this.createBuilderForm();
        this.userRoleList = [];
    }

    ngAfterViewInit() {
        this.loadGoogleMapsAPI().then(() => {
            this.ngZone.run(() => {
                this.initAutocomplete();
            });
        });
    }

    loadGoogleMapsAPI(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (typeof google === 'undefined') {
                document.addEventListener('gMapsLoaded', () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    public ngOnDestroy(): any {
        this.layunsub.unsubscribe();
        this.projsub.unsubscribe();
        this.usertypesub.unsubscribe();
        this.subscribesub.unsubscribe();
    }


    public ngOnInit(): void {
        this.createProject = { teamName: '' };
        this.selectedProjectId = sessionStorage.getItem('projectId');
        this.getAllProjects();


        if (this.searchInputElement) {
            fromEvent(this.searchInputElement.nativeElement, 'input')
                .pipe(
                    map((event: Event) => (event.target as HTMLInputElement).value),
                    debounceTime(300), // Waits for 300ms of inactivity after user stops typing
                    distinctUntilChanged() // Filters only on distinct search queries
                )
                .subscribe((searchValue: string) => {
                    this.searchProject = searchValue;
                    this.filterProjects();
                });
        }
    }

    filterProjects() {
        if (this.searchProject === '') {
            this.filteredProjects = this.myProjectList; // Show all projects when the search is empty
        } else {
            this.filteredProjects = this.myProjectList.filter(project =>
                project.teamName.toLowerCase().includes(this.searchProject.toLowerCase())
            );
        }
    }

    public close(): any {
        this.showCreateProject = false;
        this.createProject.teamName = '';
        this.searchInputElement.nativeElement.value = ''
        this.filteredProjects = this.myProjectList;
    }

    public close1(): any {
        this.showProSetting = false;
        this.edtiTeamIndex = 0;
        this.editProjectId = '';
    }

    public openToggle(isClone): any {
        this.showCreateProject = true;
        this.isClone = isClone;
        this.getLocation();
        if (localStorage.getItem('companyName')) {
            this.companyName = localStorage.getItem('companyName');
        }
    }

    public editInput(index, project): any {
        this.showProSetting = true;
        this.submitted = false;
        this.editTeamName = '';
        if (this.filteredProjects && this.filteredProjects.length > 0) {
            this.edtiTeamIndex = +index;
            this.editProjectId = project.teamId;
            this.editTeamName = this.filteredProjects[index].teamName;
            this.editAddress = this.filteredProjects[index].addressDetails
            if (!this.editAddress) {
                this.getLocation();
            }
        }
    }

    public updateProjectName(): any {
        if (!this.editTeamName) {
            return;
        }

        this.submitted = true;

        if (this.editTeamName && this.editTeamName.toLowerCase() !== this.myProjectList[this.edtiTeamIndex].teamName.toLowerCase() || this.editAddress || this.editAddress == '') {
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: this.editProjectId,
                workSpaceName: sessionStorage.getItem('workspacename'),
                teamName: this.editTeamName,
                lattitude: this.latitude,
                longitude: this.longitude,
                address: this.editAddress
            };
            this.toastr.clear();
            this.workspaceService.updateProjectName(data).subscribe((res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.myProjectList[this.edtiTeamIndex].teamName = this.editTeamName;
                    this.getAllProjects();
                    // Clear the input fields
                    this.editAddress = '';
                    this.latitude = null;
                    this.longitude = null;
                    this.close1();
                } else {
                    this.toastr.error(res.status.msg);
                }
            }, (err): any => {
                this.submitted = false;
                if (err && err.status && err.status.status === 302) {
                    this.toastr.error(err.status.msg);
                } else {
                    this.someThing();
                }
            });
        } else {
            this.close1();
        }
    }

    public reportModal() {
        this.pdfModalSelect = true;
    }

    public showProjectSettings() {
        this.pdfModalSelect = false;
    }

    public cancelPdf() {
        this.pdfModalSelect = false;
        this.close1();
    }

    public generatePDF(): any {
        const timeZoneFormat = moment.tz(moment.tz.guess()).zoneAbbr();
        this.pdfSubmitted = true;
        const data = {
            userId: localStorage.getItem('userId'),
            teamId: this.editProjectId,
            workSpaceName: sessionStorage.getItem('workspacename'),
            reportType: this.selectedPDFType,
            timeZone: timeZoneFormat
        };
        this.toastr.clear();
        this.workspaceService.generatePdf(data).subscribe((res): any => {
            this.pdfSubmitted = false;
            if (res && res.status && res.status.status === 200) {
                this.pdfModalSelect = false;
                this.close1();
                this.toastr.success(res.status.msg);
            } else {
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.pdfSubmitted = false;
            if (err && err.status && err.status.status === 302) {
                this.toastr.error(err.status.msg);
            } else {
                this.someThing();
            }
        });
    }

    public selectedProjectMethod(project): void {
        if (project) {
            if (project.teamId === this.selectedProjectId) {
                return;
            }
            sessionStorage.setItem('projectId', project.teamId);
            // localStorage.setItem('selectedProjectName', project.teamName);
            this.selectedProjectName = this.filteredProjects.find(p => p.teamId === project.teamId);

            this.selectedProjectId = project.teamId;
            this.applayout.emit(project.teamId);
            this.support.projectChange.next(this.selectedProjectId);
            if (this.router.url !== '/project/dashboard') {
                this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
            }
        }
    }


    public getAllProjects(): void {
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
        this.errorMessage = '';
        this.workspaceService.getAllProject(queryParams).subscribe((res): any => {
            if (res.entity === null) {
                this.workspaceProjectCount = 0;
                this.myProjectList = [];
                this.filteredProjects = [];
                this.errorMessage = 'Your Project seems empty';
            } else {
                this.myProjectList = res.entity;
                this.filteredProjects = this.myProjectList;
                this.selectedProjectName = this.filteredProjects.find(project => project.teamId === this.selectedProjectId);

                // if (this.myProjectList && this.myProjectList.length) {
                //     this.myProjectList.forEach((element, index) => {
                //         // get reverse geo location address
                //         if (element.lattitude && element.longitude) {
                //             this.geolocationService.reverseGeocode(Number(element.lattitude), Number(element.longitude)).then(address => {
                //                 this.myProjectList[index].address = address
                //             })
                //         }
                //     });
                // }
                this.workspaceProjectCount = res.workspaceProjectCount;
                this.workspaceService.emitAllProjects.next({ projects: true })
            }
        }, (err): any => {
            this.someThing();
        });
    }

    public getFirstAndLastLetter(name): string {
        let value = 'WP';
        if (name) {
            const split = Array.from(name);
            value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
        }
        return value;
    }

    public someThing(): void {
        localStorage.clear();
        this.toastr.error('Something went wrong, Please login again and try. ');
        this.router.navigate(['/login']);
    }

    public createProjectMethod(projectForm: any): void {
        if (this.subscriptionType === 1 && this.workspaceProjectCount >= 3) {
            this.toastr.error('Kindly Upgrade your Plan from Basic to Pixly Pro');
            return;
        }

        this.submitted = true;

        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            teamName: this.createProject.teamName,
            lattitude: this.latitude,
            longitude: this.longitude,
            address: this.address,
            addAllMembers: this.addAllMembers
        };
        this.toastr.clear();
        this.workspaceService.addProject(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.createProject.teamName = '';
                    // sessionStorage.setItem('projectId', res.entity.teamId);
                    this.close();
                    this.getAllProjects();
                    this.workspaceService.emitAllProjects.next({ projects: true })
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    cloneProjectMethod(projectForm: any) {
        this.submitted = true;
        const payload = {
            teamId: this.selectedProjectId,
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            proposedTeamName: projectForm.form.value['teamName']
        }
        this.workspaceService.cloneProject(payload).subscribe((data: any) => {
            if (data && data.status && data.status.status === 200) {
                this.submitted = false;
                projectForm.reset();
                this.getAllProjects();
                this.showCreateProject = false;
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        })
    }

    public askDeleteProject(): void {
        if (this.editProjectId) {
            this.modalRef = this.modalService.show(
                this.deletemodal,
                Object.assign(this.config, { class: 'modal-sm popup-center' }),
            );
        }
    }

    public deleteProject(): void {
        this.submitted = true;
        this.workspaceService.deleteProject(this.editProjectId).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.myProjectList.splice(this.edtiTeamIndex, 1);
                    this.modalRef.hide();
                    this.showProSetting = false;
                    this.getAllProjects();
                    if (sessionStorage.getItem('projectId') && this.editProjectId === sessionStorage.getItem('projectId')) {
                        sessionStorage.removeItem('projectId');
                        this.edtiTeamIndex = 0;
                        this.editProjectId = '';
                        this.router.navigate(['company/dashboard'], { queryParams: this.api.getSessionData() });
                    } else {
                        this.edtiTeamIndex = 0;
                        this.editProjectId = '';
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    initAutocomplete() {
        this.latitude = null;
        this.longitude = null;
        const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement);
        const editAutocomplete = new google.maps.places.Autocomplete(this.editSearchInput.nativeElement);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();
                this.address = place.formatted_address; // Update the editAddress value
                // Manually trigger change detection
                this.cdr.detectChanges();
                // this.getLocationByLatLng(this.latitude, this.longitude)
            }
        });
        editAutocomplete.addListener('place_changed', () => {
            const place = editAutocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();
                this.editAddress = place.formatted_address; // Update the editAddress value
                // Manually trigger change detection
                this.cdr.detectChanges();
                // this.getLocationByLatLng(this.latitude, this.longitude, true)
            }
        });

        // Add an event listener to handle input changes in the address field
        this.searchInput.nativeElement.addEventListener('input', () => {
            if (!this.searchInput.nativeElement.value.trim()) {
                // If the input is empty, clear the latitude, longitude, and address fields
                this.latitude = null;
                this.longitude = null;
                this.address = '';
            }
        });

        this.editSearchInput.nativeElement.addEventListener('input', () => {
            if (!this.editSearchInput.nativeElement.value.trim()) {
                // If the input is empty, clear the latitude, longitude, and address fields
                this.latitude = null;
                this.longitude = null;
                this.editAddress = '';
            }
        });
    }

    getLocation() {
        if (navigator.geolocation) {
            this.currentLocation = true;
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position && position.coords.latitude && position.coords.longitude) {
                    this.geolocationService.reverseGeocode(position.coords.latitude, position.coords.longitude).then(address => {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude
                        this.address = address
                        this.editAddress = address;
                        this.currentLocation = false;
                    })
                }
            },
                (error: any) => {
                    this.currentLocation = false;
                })
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    getLocationByLatLng(lat, lng, isEditing: boolean = false) {
        this.geolocationService.reverseGeocode(lat, lng).then(address => {
            if (isEditing) {
                this.editAddress = address;
            } else {
                this.address = address;
            }
            this.currentLocation = false;
        });
    }

    public createBuilderForm(): any {
        this.inviteForm = this.inviteBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            roleType: [{ value: null, disabled: true }, Validators.required],
            companyId: [''],
            email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
        });
    }

    public removeTrim(inviteForm) {
        if (inviteForm.value.email?.length > 0) {
            this.inviteForm.get('email').setValue(inviteForm.value.email.trim());
        }
    }

    public showInvite(): void {
        this.showInviteBar = !this.showInviteBar;
        this.submittedForm = false;
        this.inviteForm.reset();
        if (this.userRoleList.length === 0 && this.showInviteBar) {
            this.workspaceService.getUserType().subscribe((res): any => {
                if (res && res.entity) {
                    this.userRoleList = res.entity;
                    this.inviteForm.get('roleType').setValue(5);
                } else {
                    this.userRoleList = [];
                }
            }, (err): any => {
                this.api.handleError(err);
            });
        } else {
            this.inviteForm.get('roleType').setValue(5);
        }
    }

    public inviteMethod(inviteForm): any {
        this.submittedForm = true;
        if (inviteForm && inviteForm.status === 'VALID') {
            this.submitted = true;
            const data = inviteForm.getRawValue();
            data.userId = localStorage.getItem('userId');
            data.usertype = 1;
            data.workSpaceName = sessionStorage.getItem('workspacename');
            data.teamId = this.workspaceService.getProjectId();
            data.albumidList = [];

            this.workspaceService.projectInvite(data, 'pixlyProfile/inviteAsFullMember').subscribe((res): any => {
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success('Invite mail sent');
                        this.showInviteBar = false;
                    } else {
                        this.toastr.error(res.status.msg);
                    }

                    this.submitted = false;
                }, (err): any => {
                    this.submitted = false;
                    this.api.handleError(err);
                });
        }
    }
}
