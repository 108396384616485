<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-10 mx-auto mx-md-0 text-center my-content order-1 order-md-0">
            <img src="./assets/images/workspace.svg" alt="setpassword" class="banner-img-2">
         </div>
         <div class="col-lg-6 col-md-6 col-sm-10 mx-auto mx-md-0 my-auto text-center text-md-center">
            <h1 *ngIf="projectExist" class="grey-a  font-weight-bold fs28 mb-4">Welcome Back {{userName}} !</h1>
            <h1 *ngIf="!projectExist" class="grey-a  font-weight-bold fs28 mb-4">Hello {{userName}} !</h1>
            <h2 *ngIf="projectExist && (!this.datas || !this.datas.name || !filterSidemenu)" class="fs22 grey-a fw-600">Choose Your Workspace</h2>
            <h2 *ngIf="!projectExist && !firstTime && (!this.datas || !this.datas.name || !filterSidemenu)" class="fs22 grey-a fw-600">Create Your Workspace</h2>
            <p *ngIf="!this.datas || !this.datas.name" class="fs11 grey-a ls-1 ">This will be your Pixly Workspace where you and others can collaborate.</p>
            <div *ngIf="!this.datas || !this.datas.name || !filterSidemenu" class="mt50 justify-content-center justify-content-md-start">
               <div class="d-flex justify-content-center">
                  <div class="pr-3" *ngIf="projectExist && getWorkspace.length > 0">
                     <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload workspace-btn" (click)="ToggleNav('choose')" [disabled]="submitted">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Choose Workspace</button>
                  </div>
                  <!-- <div class="col-lg-4 col-md-4 col-5 pr-1" *ngIf="ownerwscount === 0 && (!this.datas || !this.datas.name || !filterSidemenu) && !firstTime">
                     <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload" (click)="ToggleNav('create')" [disabled]="submitted">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Workspace</button>
                  </div> -->
                  <div [ngClass]="{'pr-3': companyData?.companyId}" *ngIf="shouldShowButton()">
                     <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload workspace-btn" (click)="ToggleNav('create')" [disabled]="submitted">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Create Workspace</button>
                  </div>
                  <div class="pr-3" *ngIf="companyData?.companyId">
                     <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload workspace-btn" (click)="navigateCompanyProfile()" [disabled]="submitted">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Manage {{adminFlag ? 'Companies' : companyData?.name}}</button>
                  </div>
               </div>
            </div>
            <h2 *ngIf="this.datas && this.datas.name && workspacePendingList && workspacePendingList.length === 0 && firstTime"  class="fs22 grey-a fw-600">Hang on! We are setting up your account</h2>
            <div class="row mt50 justify-content-center justify-content-md-start" *ngIf="this.datas && this.datas.name && workspacePendingList && workspacePendingList.length === 0 && firstTime">
               <div class="col-lg-4 col-md-4 col-5 pr-1">
                  <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload" type="button" [disabled]="submitted" routerLink='/company/dashboard' [queryParams]="conCadSessionData({})" >
                  <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Let's Go</button>
               </div>
            </div>
         </div>
      </div>
   </div>

</section>
<app-sub-footer></app-sub-footer>
<app-sidenav-right [showToggle]="showNav" [showCreate]="tagNavSwitch" [workSpaceEntity]="getWorkspace" (showworkspaceevent)="ToggleNav('')"></app-sidenav-right>
<app-your-invites-sidemenu [toggleSidemenu]="filterSidemenu" (closesidemenu)="closeSidemenus()" [workspacePending]="workspacePendingList"></app-your-invites-sidemenu>
