import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './auth/signup/signup.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { AddPhotoComponent } from './add-photo/add-photo.component';
import { SharedComponent } from './shared/shared.component';
import { NotificationComponent } from './notification/notification.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { TagLibraryComponent } from './tag-library/tag-library.component';
import { PeopleComponent } from './people/people.component';
import { PeopleDetailsComponent } from './people-details/people-details.component';
import { SettingsComponent } from './settings/settings.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AlbumsComponent } from './albums/albums.component';
import { AlbumSubpageComponent } from './album-subpage/album-subpage.component';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { PhotoTagComponent } from './photo-tag/photo-tag.component';
import { WorkspaceSettingsComponent } from './workspace-settings/workspace-settings.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { HelpComponent } from './help/help.component';
import { ViewPictureComponent } from './view-picture/view-picture.component';
import { PendingInvitesComponent } from './pending-invites/pending-invites.component';
import { YourInvitesComponent } from './your-invites/your-invites.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { WorkspaceProjectComponent } from './layout/workspace-project/workspace-project.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'verify-otp', component: OtpVerifyComponent },
    { path: 'set-password', component: SetPasswordComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'company', component: CreateWorkspaceComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'view-picture', component: ViewPictureComponent },
    { path: 'pending-invites', component: PendingInvitesComponent },
    { path: 'create-workspace', component: WorkspaceProjectComponent },
    { path: 'project/photo-tag', component: PhotoTagComponent },
    { path: 'company/profile', component: CompanyProfileComponent },
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: 'project/dashboard', component: ProjectDashboardComponent },
            { path: 'company/dashboard', component: DashboardComponent },
            { path: 'project/feed', component: AddPhotoComponent },
            { path: 'project/shared', component: SharedComponent },
            { path: 'company/notification', component: NotificationComponent },
            { path: 'project/notification', component: NotificationComponent },
            { path: 'project/todo-list', component: TodoListComponent },
            { path: 'project/tag-library', component: TagLibraryComponent },
            { path: 'company/people', component: PeopleComponent },
            { path: 'project/people', component: PeopleComponent },
            { path: 'people-details', component: PeopleDetailsComponent },
            { path: 'settings', component: SettingsComponent },
            { path: 'project/albums', component: AlbumsComponent },
            { path: 'project/album-details', component: AlbumSubpageComponent },
            { path: 'company/workspace-settings', component: WorkspaceSettingsComponent },
            { path: 'company-management', component: CompanyManagementComponent },
            { path: 'help', component: HelpComponent },
            { path: 'your-invites', component: YourInvitesComponent },
            { path: 'project/integrations', component: IntegrationsComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

