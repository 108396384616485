<div class="card-body px-0 py-0">
    <div class="d-sm-flex d-block align-items-center justify-content-end px-4">
        <button class="btn btn-blue br25 py-2 px-sm-4 mr-sm-3 fs12" (click)="openSidenav()">Add Person</button>
    </div>
    <div class="table-responsive">
        <div class="contact-scroll pb-5 px-3 people-scroll" *ngIf="peopleList.length > 0" infiniteScroll
            [infiniteScrollDistance]="3" [infiniteScrollThrottle]="50" (scrolled)="onScrollUser()"
            [infiniteScrollContainer]="peopleSelector" [fromRoot]="true" [scrollWindow]="false">

            <table class="table custom-table" aria-describedby="people-list">
                <thead>
                    <tr>
                        <th scope="col" class="grey-m fs14 fw-600">Name</th>
                        <th scope="col" class="grey-m fs14 fw-600">Number</th>
                        <th scope="col" class="grey-m fs14 fw-600">Mail ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let people of peopleList; let mi = index">
                        <td class="grey-i fs15 fw600">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img *ngIf="!people.profileImage && (people.profileImage === null || people.profileImage === 'null' || people.profileImage === '')"
                                        src="./assets/images/empty-img.png" alt=""
                                        class="people-img w40 h40 c-pointer mr-2">
                                    <img *ngIf="people.profileImage && (people.profileImage !== null || people.profileImage !== 'null' || people.profileImage !== '')"
                                        src={{cloudFrontURL}}{{people.profileImage}} alt=""
                                        class="people-img w40 h40 c-pointer mr-2">
                                </div>
                                <div>
                                    <div class="fs16">{{people.firstName}} {{people.lastName}}</div>
                                    <div class="fs12" *ngIf="people.userType">{{people.userType?.roleName}}</div>
                                    <a class="fs12 text-danger" 
                                        *ngIf="!people.userType">User role not set</a>
                                </div>
                            </div>
                        </td>
                        <td class="grey-i fs15 fw600">
                            <ng-container *ngIf="people.phoneCode || people.phoneNumber; else noPhoneNo">
                                {{people.phoneCode}} {{people.phoneNumber}}
                            </ng-container>
                            <ng-template #noPhoneNo>
                                -
                            </ng-template>
                        </td>
                        <td class="grey-i fs15 fw600">{{people.email}}</td>
                        <td class="d-flex justify-content-end">
                            <div class="btn-group" role="group" aria-label="Basic example">
                            <button class="btn fs12"
                                [ngClass]="{'btn-outline-primary': !people.active, 'btn-outline-danger': people.active}"
                                (click)="toggleActivation(people)" [disabled]="isActivating">
                                <em *ngIf="isActivating" class="fa fa-spinner fa-spin"></em>
                                {{people.active ? 'Deactivate' : 'Activate'}}</button>
                            <button class="btn btn-outline-primary fs12"
                                (click)="resetPasswordAction(people)">Reset Password</button>
                                <button class="btn btn-outline-primary fs12"
                                    (click)="editUserSidenav(people)">Edit</button>
                                    <button class="btn btn-outline-primary fs12"
                                        (click)="fixAccessIssues(people)">Reset access</button>
                    
                            <button class="btn btn-outline-primary fs12" (click)="verifyUser(people)"
                                [disabled]="isVerifying || people.verified">
                                <em *ngIf="isVerifying" class="fa fa-spinner fa-spin"></em>
                                {{people.verified ? 'Verified' : 'Verify User'}}</button>
                            
                                  </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="card-body text-center p-5 m-5" *ngIf="loadingPage || peopleList.length === 0">
    <div class="page-loaders text-center m-5 p-2" *ngIf="loadingPage">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45" />
        </svg>
        <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
    </div>

    <div class="pending-invites-bg" *ngIf="!loadingPage && peopleList.length === 0">
        <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
        <p class="grey-g fw-600 fs13 mt-3 mb-5">Looks like there are no users available at the
            moment. <br /> Stay tuned for updates or try again later!</p>
    </div>
</div>

<app-reset-password [resetPasswordToggle]="showreset" (showresetevent)="resetPassword()"
    [isCompanyProfile]="'true'"></app-reset-password>

<div id="overlay" [ngClass]="{'d-block': showAddPersonBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showAddPersonBar}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">{{isEdit?'Edit User':'Add User'}}</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="showAddPersonBar = false"></em>
    </div>

    <div class="scroll-content">
        <form class="mt-3" name="form" [formGroup]='addPersonForm' novalidate>
            <label class="fs12 grey-j ls-3">Name*</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="firstName" tabindex=1 pattern="^[A-Za-z ]+$"
                            class="form-control pt22 pb22 fw-600" placeholder="First Name"
                            (keydown.space)="addPersonForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="submittedForm">
                            <div class="fs12 text-danger"
                                *ngIf=" (addPersonForm.get('firstName').errors) && !addPersonForm.get('firstName').errors?.pattern">
                                Please enter first name
                            </div>
                            <div class="fs12 text-danger" *ngIf=" (addPersonForm.get('firstName').errors?.pattern)">
                                First Name must only have alphabets .
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="lastName" tabindex=2 pattern="^[A-Za-z ]+$"
                            class="form-control pt22 pb22 fw-600" placeholder="Last Name"
                            (keydown.space)="addPersonForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="submittedForm">
                            <div class="fs12 text-danger"
                                *ngIf=" (addPersonForm.get('lastName').errors) && !addPersonForm.get('lastName').errors?.pattern">
                                Please enter last name
                            </div>
                            <div class="fs12 text-danger" *ngIf=" (addPersonForm.get('lastName').errors?.pattern)">
                                Last Name must only have alphabets .
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Phone Code</label>
                <input type="text" formControlName="phoneCode" tabindex=3 class="form-control pt22 pb22 fw-600"
                    placeholder="Phone Code">
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Phone Number</label>
                <input type="text" formControlName="phoneNumber" tabindex=4 class="form-control pt22 pb22 fw-600"
                    placeholder="Phone Number">
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">E-Mail*</label>
                <input type="text" formControlName="email" tabindex=5 class="form-control pt22 pb22 fw-600"
                    placeholder="Enter E-mail" (keyup)='removeTrim(addPersonForm)' [disabled]="isEdit"
                    (keydown.space)="addPersonForm.get('email').value.trim().length > 0 ? '': $event.preventDefault();">
                <div class="fs12 text-danger"
                    *ngIf=" (addPersonForm.get('email').errors && submittedForm) || (addPersonForm.get('email').errors?.required && submittedForm)">
                    Please enter email
                </div>
                <div class="fs12 text-black"
                    *ngIf="isEdit">
                    Email can't be updated
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Role*</label>
                <select class="form-control input-group-text h-46px" name="role" 
                formControlName="roleType" tabindex=6>
                    <option value="0" disabled>select user role</option>
                    <option *ngFor="let user of userRoleList" value={{user.id}}>{{user.roleName}}</option>
                </select>
                <div class="fs12 text-danger"
                    *ngIf=" (addPersonForm.get('roleType').errors && submittedForm) || (addPersonForm.get('roleType').errors?.required && submittedForm)">
                    Please choose role type
                </div>
            </div>

            <div class="row mt50 mb-3 px-3">
                
                <div class="col-4 pr-1">
                    <button [disabled]="submitted" type="button" tabindex="7"
                        class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600"
                        (click)="showAddPersonBar = false" type="button">Cancel</button>
                </div>
                <div class="col-8 pl-1">
                    <button [disabled]="submitted" tabindex="8"
                        class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"
                        (click)="createPerson()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>{{isEdit?'Update':'Create User'}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 fs12 py-2">Note: User account will be {{isEdit?'updated':'created'}} without verification </div>
            </div>
        </form>
    </div>
</div>