import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { SupportService } from '../services/support.service';
import { ApiService } from '../services/api_base/api.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { LoginService } from '../services';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    providers: [DatePipe],
})
export class NotificationComponent implements OnInit {

    public items: string[] = [
        'Date Created',
        'Date Updated',
    ];

    public stopScroll1 = true;

    public stopScroll2 = true;

    public selector1 = '.notification-scroll';

    public selector2 = '.notification-scroll1';

    public unReadPageNo = 1;

    public unReadPageRecord = 10;

    public readPageNo = 1;

    public readPageRecord = 20;

    public search: any = '';

    public unReadCount = 0;

    public notificationReadList = [];

    public notificationUnReadList = [];

    public errorMessage = '';

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public submitted = false;

    public notificationSearchDelay = new Subject<string>();

    public date: any;

    public projId: any;
    
    public downloadElement: HTMLAnchorElement;

    public constructor(private datePipe: DatePipe, private support: SupportService, private workspaceService: WorkspaceServiceService, 
        private apis: ApiService, private router: Router, private api: LoginService, private toastr: ToastrService,) {

        this.notificationSearchDelay.pipe(debounceTime(1250), distinctUntilChanged()).subscribe((value): any => {
            this.searchNotification();
        });

    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.getNotificationsUnReadList(this.unReadPageNo, this.unReadPageRecord, '');
        // this.getNotificationsReadList(this.readPageNo, this.readPageRecord, '');
    }

    public getNotificationsUnReadList(pageNo, pageRecord, type): void {
        this.submitted = true;
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&search=${this.search}&projectId=${sessionStorage.getItem('projectId') !== null ? sessionStorage.getItem('projectId') : ''}&pageNumber=${pageNo}&pageRecord=${pageRecord}&sortBy=`;
        this.workspaceService.getProjectFeed(queryParams).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200 && res.entity) {
                this.unReadCount = +res.wscount;
                this.support.notiunreadcount.next(this.unReadCount);
                if (this.notificationUnReadList.length > 0) {
                    for (let index = 0; index < res.entity.length; index++) {
                        this.notificationUnReadList.push(res.entity[index]);
                    }
                } else {
                    this.notificationUnReadList = res.entity;
                }
            } else {
                this.unReadCount = +res.unreadCount;
                this.stopScroll1 = false;
            }
        }, (err): any => {
            this.submitted = false;
            this.apis.handleError(err);
        });
    }

    public onScroll1(): any {
        if (this.stopScroll1) {
            this.unReadPageNo += 1;
            this.getNotificationsUnReadList(this.unReadPageNo, this.unReadPageRecord, 'scroll');
        }
    }

    public searchNotification(): void {
        this.readPageNo = 1;
        this.unReadPageNo = 1;
        this.notificationUnReadList = [];
        this.notificationReadList = [];
        this.getNotificationsUnReadList(this.unReadPageNo, this.unReadPageRecord, 'search');
    }

    public clearNotification(): void {
        this.workspaceService.clearNotificaiton().subscribe(res=>{
            this.notificationUnReadList = [];
            this.support.notiunreadcount.next(0);
        })
    }


    public readNotificationMethod(id, i): void {   
        if (this.router.url === '/company/notification') {
            this.projId = '';
        } else {
            this.projId = sessionStorage.getItem('projectId');
        }
        const data = {
            workSpaceName: sessionStorage.getItem('workspacename'),
            userId: localStorage.getItem('userId'),
            id: id ? id : '',
            projectId: this.projId,
        };
        
        this.submitted = true;
        this.workspaceService.updateNotification(data).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    if (id) {
                        this.unReadCount = this.unReadCount - 1;
                        this.notificationUnReadList[i].isread = true;
                        this.support.notiunreadcount.next(this.unReadCount);
                    } else {
                        this.notificationUnReadList = [];
                        this.readPageNo = 1;
                        this.unReadCount = 0;
                        this.support.notiunreadcount.next(0);
                        this.unReadPageNo = 1;
                        this.getNotificationsUnReadList(1, this.unReadPageRecord, '');
                    }
                }
            },
            (err): any => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public getDateValue(value): any {      
        if (value) {
            const datobj = value.split(' ');         
            return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
        }
        return '';
    }

    public navigate(feed, unri): any {
        sessionStorage.setItem('projectId', feed.projectId);
        if (feed && feed.notificationType && feed.notificationType.notid === 1) {
            this.router.navigate(['/project/albums'], { queryParams: { ...this.apis.getSessionData(), ...{ albumId: feed.albumId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 4) {
            this.router.navigate(['/project/album-details'], { queryParams: { ...this.apis.getSessionData(), ...{ albumId: feed.albumId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 10) {
            this.router.navigate(['/project/tag-library'], { queryParams: { ...this.apis.getSessionData(), ...{ tagid: feed.refId } } });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 7) {
            this.router.navigate(['/project/photo-tag'], { queryParams: {
                albumId: feed.albumId,
                photoId: feed.photoId,
                photoTagId: feed.photoTagId,
                prevPage: this.router.url,
            },
            });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 16) {
            this.router.navigate(['/project/photo-tag'], { queryParams: {
                albumId: feed.albumId,
                photoId: feed.photoId,
                photoTagId: feed.photoTagId,
                photoTagid: feed.photoTagId,
                prevPage: this.router.url,
                todo: true,
            },
            });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 12) {
            this.router.navigate(['/project/photo-tag'], { queryParams: {
                albumId: feed.albumId,
                photoId: feed.photoId,
                photoTagId: feed.photoTagId,
                photoTagid: feed.photoTagId,
                prevPage: this.router.url,
                comment: true,
            },
            });
        }
        if (feed && feed.notificationType && (feed.notificationType.notid === 11 || feed.notificationType.notid === 13)) {
            this.router.navigate(['/project/todo-list'], { queryParams: {
                albumId: feed.albumId,
                photoId: feed.photoId,
                todoId: feed.refId, 
                type: 'completed',
                photoTagId: feed.photoTagId,
                photoTagid: feed.photoTagId,
                prevPage: this.router.url,
            },
            });
        }
        if (feed && feed.notificationType && feed.notificationType.notid === 42) {
            this.downloadElement = document.createElement("a");
            this.downloadElement.href = feed.sharedLinkId;
            this.downloadElement.download = feed.sharedLinkId.split("/").pop();
            document.body.appendChild(this.downloadElement);
            if(this.downloadElement) {
                const downloadClick = this.downloadElement;
                this.downloadElement.remove();
                downloadClick.click();
            }
        }
        if (feed && !feed.isread) {
            this.readNotificationMethod(feed.id, unri);
        }       
    }
   
}
