import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api_base/api.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public constructor(private api: ApiService, private http: HttpClient) {
        // constructor
    }

    public getCommonDetails(): any {
        return `userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`;
    }

    public viewProfile(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyProfile/viewprofile', payload);
    }

    public editProfile(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyProfile/updateprofile', payload);
    }

    public changePassword(payload): Observable<any> {
        return this.api.putByHeadersBearer('pixlyLogin/changepassword', payload);
    }

    public usersList(payload): Observable<any> {
        return this.api.get('user', payload);
    }

    public deleteUser(payload, id): Observable<any> {
        return this.api.delete(`user/${id}`, payload);
    }

    public userDetails(payload, id): Observable<any> {
        return this.api.get(`user/${id}`, payload);
    }

    public updateUser(payload, id): Observable<any> {
        return this.api.put(`user/${id}`, payload);
    }

    public addUser(payload): Observable<any> {
        return this.api.post('user', payload);
    }

    public createCustomerToStripe(data): Observable<any> {
        return this.api.createCustomerToStripe(data);
    }

    public saveCardToPixly(data): Observable<any> {
        return this.api.postByHeadersBearer1('pixlypayment/savecardmethod', data);
    }

    public saveSubscription(): Observable<any> {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        return this.api.postByHeadersBearer('pixlypayment/savesubscription', data);
    }

    public paymentMethod(cardDetails): Observable<any> {
        return this.api.paymentMethod(cardDetails);
    }

    public getCount(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlypayment/getallcounts?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`);
    }

    public getCard(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlypayment/getcarddetails?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`);
    }

    public getSubscription(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlypayment/getsubscription?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`);
    }

    public cancelSubscription(): Observable<any> {
        return this.api.deleteByHeadersBearer(`pixlypayment/cancelsubscription?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`);
    }

    public cancelStripePlan(subscription, timediffer): Observable<any> {
        const path = `https://api.stripe.com/v1/subscriptions/${subscription.subscription}`;
        return this.api.cancelStripePlan(path, timediffer);
    }

    public getBillinghistoryApi(path): Observable<any> {
        return this.api.getByHeadersBearer(path);
    }

    public getSubscriptionItem(id): Observable<any> {
        const path = `https://api.stripe.com/v1/invoices/upcoming/lines?customer=${id}`;
        return this.api.getSubscriptionItem(path);
    }

    public getingTokens(): any {
        const workspaceHeader = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        });
        return workspaceHeader;
    }

    public getsearchHistory(body): Observable<any> {
        return this.api.postByHeadersBearerForSearch(body);
    }

    public deleteSearchHistory(data: any): Observable<any> {
        return this.api.deleteByHeadersBearer(data);
    }

    public getWorkspacePendingList(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlyLogin/getworkspacependinginvites?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}`);
    }

    public rejectInvite(payload): Observable<any> {
        return this.api.postByHeaders1('pixlyProfile/rejectinvite', payload);
    }

    public getAllPendingList(): Observable<any> {
        return this.api.getByHeadersBearer(`pixlyLogin/getallpendinginvites?userId=${localStorage.getItem('userId')}`);
    }

    public getAllTags(userId, tenantId): Observable<any> {
        const path = `pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}`;
        return this.api.getByHeadersBearer(path);
    }
}
