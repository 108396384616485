import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, UserService } from 'src/app/services';
import { SupportService } from 'src/app/services/support.service';

@Component({
    selector: 'app-search-field',
    templateUrl: './search-field.component.html',
})
export class SearchFieldComponent implements OnInit {

  @ViewChild('searchdropdown') public searchdropdown;

  public globalSearch = '';

  public searchHistoryList = [];

  public grabber = false;

  public some_text = 'Click Here';

  public inside = false;

  constructor(private support: SupportService, public userService: UserService,
    private loginService: LoginService, public router: Router) { }

    @HostListener("click")
    clicked() {
      this.inside = true;
    }

    @HostListener("document:click")
    clickedOut() {
      this.inside
        ? this.searchdropdown.show()
        : this.searchdropdown.hide();
      this.inside = false;
    }  

  ngOnInit() {
    this.searchHistoryFetch();
  }

  public searchGlobal(): void {
    this.searchHistoryFetch();
    this.support.globalSearch.next(this.globalSearch);
  }

  public searchHistoryFetch(): void {
    const queryParams = `pixlySearch/searchhistory?userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&pageNumber=${1}&pageRecord=${5}`;
    this.userService.getsearchHistory(queryParams).subscribe((res): void => {
        if(res && res.status && res.status.status === 200) {
            this.searchHistoryList = res.entity;
        } else {
            this.searchHistoryList = [];
        }
    });
  }

  public deleteHistory(history): void {
    let payload: any = {};
    // this.searchdropdown.hide();
    if (history) {
        payload = {
            userId: history.userId,
            workSpaceName: sessionStorage.getItem('workspacename'),
            historyId: history.id,
        }; 
    } else {
        payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            historyId: ''
        };
    }
    const queryParams = `pixlySearch/deletehistory?userId=${payload.userId}&workSpaceName=${sessionStorage.getItem('workspacename')}&historyId=${payload.historyId}`;
    this.userService.deleteSearchHistory(queryParams).subscribe((res): void => {
        if(res && res.status && res.status.status === 200) {
            this.searchHistoryFetch();
        } 
    });
  }

  public clickingEvent(): void {
    this.searchdropdown.show();
    this.searchGlobal();     
  }

  public selectHistory(history) : void {
    this.globalSearch = history.searchData;
    this.support.globalSearch.next(this.globalSearch);
  }

}
