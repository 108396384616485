import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api_base/api.service';

@Injectable({
    providedIn: 'root',
})
export class TaglibraryserviceService {
    public constructor(private api: ApiService) {
    // constructor
    }

    public CreateTag(payload): Observable<any> {
        const path = `pixlyAlbum/${sessionStorage.getItem('projectId')}/createtag`;
        return this.api.postByHeadersBearer(path, payload);
    }

    public EditTag(payload): Observable<any> {
        return this.api.postByHeadersBearer('pixlyAlbum/editagtype', payload);
    }

    public gettags(userSearch, sortType): Observable<any> {
        // tslint:disable-next-line: max-line-length
        const path = `pixlyAlbum/gettagsbyteamid?teamId=${sessionStorage.getItem('projectId')}&userId=${localStorage.getItem('userId')}&workSpaceName=${sessionStorage.getItem('workspacename')}&search=${userSearch}&sortby=${sortType}`;
        return this.api.getByHeadersBearer(path);
    }

    public getTagbyTagId(path): Observable<any> {
        return this.api.getByHeadersBearer(path);
    }

    public TagDelete(path): Observable<any> {
        return this.api.deleteByHeadersBearer(path);
    }

    public addTagsForTenant(userId, tenantId, payload): Observable<any> {
        return this.api.postByHeadersBearer(`pixlyProfile/v1/tag/global/user/${userId}/tenant/${tenantId}`, payload);
    }
}
