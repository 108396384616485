import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SupportService {
    public showProject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public headerUserName: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public headerProfileImage: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public projectChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public workspaceChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public allProject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public notiunreadcount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public usertype: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public subscription: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public globalSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public pendinginvite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public companyProfile: BehaviorSubject<any> = new BehaviorSubject<any>({});
}
