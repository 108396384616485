import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class ApiService {
    public token: any;

    public stripeToken = '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';

    public AuthrizationHeader: HttpHeaders = new HttpHeaders();

    public setHeader: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    public setHeader1: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    public workspaceHeader: HttpHeaders;

    public constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, private route: ActivatedRoute) {
        // constructor
    }

    public getingTokens(): any {
        // eslint-disable-next-line no-return-assign
        return this.workspaceHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `bearer ${localStorage.getItem('token')}`,
        });
    }

    public getingOnlyTokens(): any {
        // eslint-disable-next-line no-return-assign
        return this.workspaceHeader = new HttpHeaders({
            Authorization: `bearer ${localStorage.getItem('token')}`,
        });
    }

    public responseCheck(res): any {
        if (res && res.status && res.status.status === 246) {
            this.toastr.error('Project got deleted by someone');
            if (sessionStorage.getItem('usertype') === '1' || sessionStorage.getItem('usertype') === '2') {
                this.router.navigate(['/company/dashboard'], { queryParams: this.getSessionData() });
            } else {
                this.router.navigate(['/company'], { queryParams: this.getSessionData() });
            }
        }
        return res;
    }

    public get(
        path: string,
        params: HttpParams = new HttpParams(),
    ): Observable<any> {
        return this.http
            .get(`${environment.apiBaseUrl}${path}`, { params })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public post(path: string, body: any): Observable<any> {
        return this.http
            .post(`${environment.apiBaseUrl}${path}`, body)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public put(path: string, body: any): Observable<any> {
        return this.http
            .put(`${environment.apiBaseUrl}${path}`, body)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public delete(path, body: any): Observable<any> {
        return this.http
            .delete(`${environment.apiBaseUrl}${path}`, body)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public getheaders(): void {
        const currentToken = localStorage.getItem('userAccessToken');
        if (currentToken !== null || currentToken !== undefined) {
            this.AuthrizationHeader = this.AuthrizationHeader.set(
                'Authorization',
                `bearer ${localStorage.getItem('userAccessToken')}`,
            );
        } else {
            this.AuthrizationHeader = this.AuthrizationHeader.set(
                'Authorization',
                '',
            );
        }
    }

    public postByHeaders(path: string, body: any): Observable<any> {
        this.getheaders();
        return this.http
            .post(`${environment.apiBaseUrl}${path}`, body, { headers: this.setHeader })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public postByHeaders1(path: string, body: any): Observable<any> {
        this.getheaders();
        return this.http
            .post(`${environment.apiBaseUrl}${path}`, body, { headers: this.setHeader1 })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public postByHeadersBearer(path: string, body: any): Observable<any> {
        this.getheaders();
        return this.http
            .post(`${environment.apiBaseUrl}${path}`, body, { headers: this.getingTokens() })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public postByHeadersBearerForSearch(body: any): Observable<any> {
        // this.getheaders();
        return this.http
            .post(`${environment.apiBaseUrl}${body}`, '', { headers: this.getingOnlyTokens() })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public getByHeaders(path: string): Observable<any> {
        this.getheaders();
        return this.http
            .get(`${environment.apiBaseUrl}${path}`)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public getByHeadersBearer(path: string): Observable<any> {
        return this.http
            .get(`${environment.apiBaseUrl}${path}`, { headers: this.getingTokens() })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public putByHeadersBearer(path: string, body: any): Observable<any> {
        return this.http
            .put(`${environment.apiBaseUrl}${path}`, body, { headers: this.getingTokens() })
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public deleteByHeadersBearer(path: string): Observable<any> {
        return this.http
            .delete(`${environment.apiBaseUrl}${path}`, {
                headers: this.getingTokens(),
            }).pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public deleteByHeadersBearerLoginWorkspace(path: string): Observable<any> {
        return this.http
            .delete(`${environment.apiBaseUrl}${path}`, {
                headers: this.getingTokens(),
            }).pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public deleteByHeadersBodyBearer(path: string, payload: any): Observable<any> {
        const options = {
            headers: this.getingTokens(),
            body: payload
          };
        return this.http
            .delete(`${environment.apiBaseUrl}${path}`, options)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public handleError(err): any {
        this.toastr.clear();
        if (err && err.status === 401) {
            localStorage.clear();
            this.toastr.error('Session expired, Please try to login again');
            this.router.navigate(['/login']);
        } else if (err && err.error.status && err.error.status.msg) {
            this.toastr.error(err.error.status.msg);
        } else if (err && err.error.status && err.error.message) {
            this.toastr.error(err.error.message);
        } else {
            console.log(err);
            this.toastr.error('Something went wrong, Please login again and try. ');
            this.router.navigate(['/login']);
        }
    }

    public postByHeadersBearer1(paths: string, bodys: any): Observable<any> {
        return this.http
            .post(`${environment.apiBaseUrl}${paths}`, bodys)
            .pipe(map((res): any => this.responseCheck(res)), catchError(this.formatErrors));
    }

    public paymentMethod(data): any {
        let retundata;
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer  ${this.stripeToken}`,
        });
        const body = new URLSearchParams();
        if (data && data.id) {
            body.set('card[exp_month]', data.exp_month);
            body.set('card[exp_year]', data.exp_year);
            retundata = this.http.post(`https://api.stripe.com/v1/payment_methods/${data.id}`, body.toString(), { headers: header });
        } else {
            body.set('type', 'card');
            body.set('card[number]', data.number);
            body.set('card[exp_month]', data.exp_month);
            body.set('card[exp_year]', data.exp_year);
            body.set('card[cvc]', data.cvc);
            retundata = this.http.post('https://api.stripe.com/v1/payment_methods', body.toString(), { headers: header });
        }

        return retundata;
    }

    public addCardToStripeCustomer(data, id): any {
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer  ${this.stripeToken}`,
        });
        const body = new URLSearchParams();
        body.set('source', data);

        return this.http.post(`https://api.stripe.com/v1/customers/${id}/sources`, body.toString(), { headers: header }).pipe(
            catchError(
                (error): any => this.handleError(error),
            ),
        );
    }

    public createCustomerToStripe(data): any {
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${this.stripeToken}`,
        });
        const body = new URLSearchParams();
        body.set('source', data.source);
        body.set('email', data.email);

        return this.http.post('https://api.stripe.com/v1/customers', body.toString(), { headers: header }).pipe(
            catchError(
                (error): any => this.handleError(error),
            ),
        );
    }

    public cancelStripePlan(path, trialDifference): any {
        let response;
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${this.stripeToken}`,
        });
        if (+trialDifference <= 0) {
            response = this.http.delete(`${path}?invoice_now=true`, { headers: header });
        } else {
            return this.http.delete(`${path}`, { headers: header });
        }
        return response;
    }

    public getSubscriptionItem(path): any {
        const header = new HttpHeaders({
            Authorization: `bearer ${this.stripeToken}`,
        });
        return this.http.get(path, { headers: header });
    }

    public retryInvoice(id): any {
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${this.stripeToken}`,
        });
        const path = `https://api.stripe.com/v1/invoices/${id}/pay`;
        return this.http.post(path, '', { headers: header });
    }

    public checkUrlData(): void {
        // workspacename: sessionStorage.getItem('workspacename'),
        // projectId: sessionStorage.getItem('projectId'),
        // usertype: sessionStorage.getItem('usertype'),
        // subscription: sessionStorage.getItem('subscription'),


        this.route.queryParams.subscribe((params): any => {
            const {
                workspacename, projectId, usertype, subscription,
            } = params;
            this.setSessionValue('workspacename', workspacename);
            if (!this.router.url.includes('company')) {
                this.setSessionValue('projectId', projectId);
            } else {
                sessionStorage.removeItem('projectId');
            }
            this.setSessionValue('usertype', usertype);
            this.setSessionValue('subscription', subscription);
        });
    }

    public setSessionValue(varName: string, value: string): void {
        if (sessionStorage.getItem(varName) === null) {
            sessionStorage.setItem(varName, value);
        }
    }

    public getSessionData(): any {
        // console.log(this.router.url.includes('company'));
        // console.log(sessionStorage.getItem('projectId'));
        // return {
        //     workspacename: sessionStorage.getItem('workspacename'),
        //     projectId: this.router.url.includes('company') ? '' : sessionStorage.getItem('projectId'),
        //     usertype: sessionStorage.getItem('usertype'),
        //     subscription: sessionStorage.getItem('subscription'),
        // };
        return '';
    }

    public getworkspaceUserList(data: any): Observable<any> {
        return this.postByHeadersBearer('pixlyProfile/getallworkspaceusers', data);
    }

    public getOtherProfile(data: any): Observable<any> {
        return this.postByHeadersBearer('pixlyProfile/viewotherprofile', data);
    }

    private formatErrors(error: any): any {
        return throwError(error);
    }

    public getCompanyUsers(companyId: string): Observable<any> {
        return this.getByHeadersBearer(`pixlyProfile/v1/company/${companyId}/users`);
    }

    public verifyUser(payload): Observable<any> {
        return this.putByHeadersBearer(`pixlyProfile/v1/company/verify-user/true`, payload);
    }
    
    public toggleActivation(isActive, payload): Observable<any> {
        return this.putByHeadersBearer(`pixlyProfile/v1/company/activate-user/${isActive}`, payload);
    }

    public resetPassword(payload): Observable<any> {
        return this.putByHeadersBearer(`pixlyProfile/v1/company/reset-password`, payload);
    }

    public getCurrentUserCompany(): Observable<any> {
        return this.getByHeadersBearer(`pixlyProfile/v1/company/get-current-user-company`);
    }

    public getIsUserIsCompanyOwner(): Observable<any> {
        return this.getByHeadersBearer(`pixlyProfile/v1/company/get-if-user-is-company-owner`);
    }

    public getCompanyList(): Observable<any> {
        return this.getByHeadersBearer('pixlyProfile/v1/company/all');
    }

    public addCompany(data: any): Observable<any> {
        return this.putByHeadersBearer(`pixlyProfile/v1/company`, data);
    }

    public addUser(companyId: string, data: any): Observable<any> {
        return this.postByHeadersBearer(`pixlyProfile/v1/company/${companyId}/users`, data);
    }

    public setUseraccessToAllProjects(companyId: string, email: string, roleId: number): Observable<any> {
        return this.postByHeadersBearer(`pixlyLogin/company/${companyId}/setUserAccessToAllProjects?email=${email}&roleId=${roleId}`, {});
    }
}
