/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import {
    Component, OnInit, ViewChild,
    TemplateRef,
    ElementRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services';
import { SupportService } from '../services/support.service';
import { environment } from '../../environments/environment';
import { UploadService } from '../services/uploadservice.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { ApiService } from '../services/api_base/api.service';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AlbumserviceService } from '../services/albumservice.service';
import { LoginService } from '../services/index';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {

    public modalRef: BsModalRef;

    @ViewChild('deletemodal') public deletemodal: TemplateRef<any>;

    @ViewChild('searchWorkspace', { static: true }) searchInputElement: ElementRef;

    public config = {
        ignoreBackdropClick: true,
    };

    public editCompanySidemenu = false;

    public editProfile = false;

    public changePassword = false;

    public thumbNail: boolean;

    public passwordTypeOne = 'password';

    public passwordTypeTwo = 'password';

    public passwordTypeThree = 'password';

    public togglePasswordOne = true;

    public togglePasswordTwo = true;

    public togglePasswordThree = true;

    public imageChangedEvent: any;

    public wallpaperChangedEvent: any;

    public showProfilepic: boolean;

    public wallpaperPic: boolean;

    public croppedImage: any = '';

    public editObj: any;

    public userDetails: any = { profileImage: null, backgroundImage: null };

    public unsub: any;

    public viewMode = 'Notification';

    public userId = localStorage.getItem('userId');

    public workSpaceName = sessionStorage.getItem('workspacename');

    public submitted = false;

    public selectedFiles: FileList;

    public getFile: any;

    public getWallpaper: any;

    public imageUrl: any;

    public phoneMask = '';

    public profileSrc: any;

    public wallpaperSrc: any;

    public getname: string;

    public newFileChosen = false;

    public profileForm: FormGroup;

    public code = [{ code: '+1', mask: '(000) 0000-000' }, { code: '+91', mask: '00000-00000' }];

    public EditProfile: any = {};

    public changePassForm: FormGroup;

    public ChangePassword: any = {};

    public viewProfile: any;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public uploadedObj: any;

    public uploadedObj2: any;

    public okButtonDisabled = false;

    public notificationListSetting = [];

    public tempThumbNail = '';

    public tempBackgroundImage = '';

    public workspaceList: any = [];

    public peopleSelector = '.people-scroll';

    public stopScroll = true;

    public pageNumber = 1;

    public pageRecord = 5;

    public workspaceId: any;

    public workspaceName: any;

    public compDataFromModal: any;

    tenantId: any;

    searchWorkspace = '';

    filteredWorkspace = [];

    public constructor(
        private support: SupportService,
        public userService: UserService,
        private toastr: ToastrService,
        private uploadService: UploadService,
        private workspaecservice: WorkspaceServiceService,
        private api: ApiService,
        private albumService: AlbumserviceService,
        private loginService: LoginService,
        private modalService: BsModalService,
    ) {
        // constructor
    }

    public ngOnInit(): void {
        this.getProfile();
        this.getUserNotificationSetting();
        this.tenantId = localStorage.getItem('tenantId');
        this.ChangePassword = {
            oldPassword: '',
            password: '',
            confirmPassword: '',
        };
        this.getallWorkspace();

        if (this.searchInputElement) {
            fromEvent(this.searchInputElement.nativeElement, 'input')
                .pipe(
                    map((event: Event) => (event.target as HTMLInputElement).value),
                    debounceTime(300), // Waits for 300ms of inactivity after user stops typing
                    distinctUntilChanged() // Filters only on distinct search queries
                )
                .subscribe((searchValue: string) => {
                    this.searchWorkspace = searchValue;
                    this.filterWorkspaces();
                });
        }
    }

    public getUserNotificationSetting(): any {
        this.workspaecservice.getUserNotificationSetting().subscribe(
            (res): any => {
                if (res && res.status && res.status.status === 200) {
                    this.notificationListSetting = res.entity;
                    this.submitted = false;
                }
            },
            (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public getProfile(): void {
        const payload = {
            userId: this.userId,
            workSpaceName: this.workSpaceName,
        };
        this.userService.viewProfile(payload).subscribe(
            (res): any => {
                this.userDetails = res.entity;
                this.editObj = { ...this.userDetails };
                this.tempThumbNail = this.userDetails.profileImage;
                this.tempBackgroundImage = this.userDetails.backgroundImage;
                if (this.editObj.phoneCode && this.editObj.phoneCode === '+1') {
                    this.phoneMask = this.code[0].mask;
                } else {
                    this.phoneMask = this.code[1].mask;
                }
            },
        );
    }

    public closeBackgroundImg(): void {
        this.wallpaperChangedEvent = false;
        this.uploadedObj2.key = '';
        this.userDetails.backgroundImage = this.tempBackgroundImage;
    }

    public closeThumbnailImage(): void {
        this.imageChangedEvent = false;
        this.uploadedObj.key = '';
        this.userDetails.profileImage = this.tempThumbNail;
    }

    public imageCroppedProfile(event: ImageCroppedEvent, i): void {
        this.imageChangedEvent.croppedImage = base64ToFile(event.base64);
        this.selectFile(this.imageChangedEvent);    
    }

    public selectFile(event: any): void {
        if (!event.target.files.item(0)) {
            return;
        }

        this.imageChangedEvent = event;
        const file = this.imageChangedEvent;
        this.okButtonDisabled = true;
        const that = this;
        this.uploadedObj = '';

        let fileType = file.target.files.item(0).type.slice(0, file.type.indexOf('/'));
        const fileExtension = file.target.files.item(0).name.replace(/^.*\./, '');
        if (fileType === 'application') { fileType = 'document'; }
        this.uploadService
            .uploadProfile(file)
            .then((data: any): any => {
                const fileData = {
                    filePath: data.Key, name: file.target.files.item(0).name, type: fileType, format: fileExtension,
                };
                this.getname = file.name;
                if (this.getname) {
                    this.newFileChosen = true;
                }
                this.getFile = `${environment.KsecretKey}${fileData.filePath}`;
                data.filename = file.name;

                that.uploadedObj = data;
                setTimeout((): any => {
                    this.okButtonDisabled = false;
                }, 100);
            })
            .catch((err): any => {
                console.error(err);
            });
    }

    public imageCropped(event: ImageCroppedEvent, i): void {
        this.wallpaperChangedEvent.croppedImage = base64ToFile(event.base64);
        this.selectWallpaper(this.wallpaperChangedEvent);    
    }  

    public selectWallpaper(event: any): void {        
        this.wallpaperChangedEvent = event;
        const file = this.wallpaperChangedEvent;
        if (!file) {
            return;
        }

        const that = this;
        this.uploadedObj2 = '';
        this.okButtonDisabled = true;

        let fileType = file.target.files.item(0).type.slice(0, file.type.indexOf('/'));
        if (fileType === 'application') { fileType = 'document'; }
        this.uploadService
            .uploadProfile(file)
            .then((data: any): any => {
                this.getname = file.name;
                if (this.getname) {
                    this.newFileChosen = true;
                }
                that.uploadedObj2 = data;
                setTimeout((): any => {
                    this.okButtonDisabled = false;
                }, 500);
            })
            .catch((err): any => {
                console.error(err);
            });
    }

    public viewEditprofile(): void {
        this.editProfile = !this.editProfile;
        this.thumbNail = false;
    }

    public showChangePassword(): void {
        this.changePassword = !this.changePassword;
    }

    public changeMask(): void {
        if (this.userDetails.phoneCode && (this.userDetails.phoneCode === '+1' || this.userDetails.phoneCode === +1)) {
            this.phoneMask = this.code[0].mask;
        } else {
            this.phoneMask = this.code[1].mask;
        }
    }

    public onSubmit(profileForm: any): void {
        this.submitted = true;
        const payload = {
            userId: this.userId,
            workSpaceName: this.workSpaceName,
            firstName: profileForm.value.firstName,
            lastName: profileForm.value.lastName,
            phoneCode: profileForm.value.phoneCode,
            phoneNumber: profileForm.value.phoneNumber,
            imageurl: this.uploadedObj && this.uploadedObj.key ? this.uploadedObj.key : this.userDetails.profileImage,
            backgroundimageurl: this.uploadedObj2 && this.uploadedObj2.key ? this.uploadedObj2.key : this.userDetails.backgroundImage,
        };
        this.userService.editProfile(payload).subscribe(
            (res): any => {
                if (res.status.status === 200) {
                    this.toastr.clear();
                    this.toastr.success(res.status.msg);
                    if (res.entity.firstName) {
                        this.support.headerUserName.next(
                            `${res.entity.firstName}`,
                        );
                    }
                    if (res.entity.profileImage) {
                        this.support.headerProfileImage.next(
                            `${this.cloudFrontURL + res.entity.profileImage}`,
                        );
                    }
                    this.thumbNail = false;
                    this.editProfile = false;
                    this.getProfile();
                } else {
                    this.toastr.clear();
                    this.toastr.error('Please check you are logged in ');
                }

                this.submitted = false;
            }, err => {
                this.submitted = false;
                this.api.handleError(err);
            }
        );
    }

    public onSubmitChange(changePassForm: any): void {
        this.submitted = true;
        this.userService.changePassword(changePassForm.value).subscribe(
            (res): any => {
                if (res.status.status === 230) {
                    this.toastr.clear();
                    this.toastr.error(res.status.msg);
                } else if (res.status.status === 200) {
                    this.toastr.clear();
                    changePassForm.form.reset();
                    this.toastr.success(res.status.msg);
                    this.changePassword = false;
                }
            },
        );
    }

    public onSubmitProfile(type): void {
        this.thumbNail = false;
        if (this.uploadedObj && this.uploadedObj.key) {
            this.userDetails.profileImage = this.uploadedObj.key;
        }
        if (this.uploadedObj2 && this.uploadedObj2.key) {
            this.userDetails.backgroundImage = this.uploadedObj2.key;
        }
    }

    public editThumbnail(id: any): void {
        this.thumbNail = !this.thumbNail;
        if (id === 1) {
            this.showProfilepic = false;
            this.wallpaperPic = true;
        } else if (id === 2) {
            this.showProfilepic = true;
            this.wallpaperPic = false;
        }
    }

    public passwordToggle(id: any): void {
        if (id === 1) {
            this.togglePasswordOne = !this.togglePasswordOne;
        } else if (id === 2) {
            this.togglePasswordTwo = !this.togglePasswordTwo;
        } else {
            this.togglePasswordThree = !this.togglePasswordThree;
        }
    }

    public changeTagToggle(type, value): any {
        if (type) {
            if (type === 9 || type === 18) {
                this.submitted = true;
            }
            const data = {
                allNotification: type === 9 || type === 18 ? value : '',
                genericNumber: type,
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
            };
            this.toastr.clear();
            this.workspaecservice.updateUserNotification(data).subscribe(
                (res): any => {
                    if (res && res.status === 200) {
                        this.getUserNotificationSetting();
                        if (type !== 9) {
                            this.submitted = false;
                        }
                    }
                },
                (err): any => {
                    this.submitted = false;
                    this.api.handleError(err);
                },
            );
        }
    }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }

    public getallWorkspace(): void {        
        this.workspaecservice.getAllWorkspace(0, 0).subscribe(
            (res): any => {
                if (res && res.entity && res.status.status === 200) {
                    this.workspaceList = [];
                    res.entity.forEach(element => {
                        if (element.masterTenant.owner.userId === localStorage.getItem('userId')) {
                            element.owner = true;
                            this.workspaceList.push(element);
                        } else {
                            element.owner = false;
                            this.workspaceList.push(element); 
                        }
                    });
                }
                this.filteredWorkspace = this.workspaceList
            },
            (err): any => {
                this.workspaceList = [];
                this.api.handleError(err);
            },
        );
    }

    public closeCompanySidemenu(event): void {
        this.editCompanySidemenu = event;
        this.getallWorkspace();
    }

    public editWorkspace(company): void {
        this.workspaceId = company.masterTenant.tenantId;
        this.workspaceName = company.masterTenant.workSpaceName;
    }

    public askDeleteWorkspace(comp): void {
        const initialState = {
            compData: comp, // Pass the comp data as compData in initialState
        };
        this.modalRef = this.modalService.show(
            this.deletemodal,
            Object.assign({}, this.config, { class: 'modal-md popup-center', initialState })
        );
        this.compDataFromModal = initialState.compData;
    }

    public deleteWorkspace() {
        const compData = this.compDataFromModal;
        this.submitted = true
        this.loginService.deleteWorkspace(compData).subscribe((data) => {
            this.submitted = false;
            this.modalRef.hide()
            this.toastr.success('Workspace Deleted successfully');
            this.getallWorkspace();
        },  (err): any => {
            this.submitted = false;
            this.modalRef.hide()
            this.api.handleError(err);
        },)
    }

    filterWorkspaces() {
        if (this.searchWorkspace === '') {
            this.filteredWorkspace = this.workspaceList; // Show all projects when the search is empty
        } else {
            this.filteredWorkspace = this.workspaceList.filter(comp =>
                comp.masterTenant.workSpaceName.toLowerCase().includes(this.searchWorkspace.toLowerCase())
            );
        }
    }
}
