<app-sub-header></app-sub-header>
<section class="authentication-layout">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-7 col-md-6 col-sm-10 mx-auto mx-md-0 text-center my-content order-1 order-md-0">
            <img src="./assets/images/workspace.svg" alt="setpassword" class="banner-img-2">
         </div>
         <div class="col-lg-5 col-md-6">
            <div>
                <div class="card-header bg-white border-0 pb-0  mt-2  px-3 pt-3">
                    <div class="d-flex align-items-center">
                        <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Pending Invites</h1>
                    </div>
                </div>
                <div class="card-body pl30">
                    <div class="tag-list mt-4" *ngFor="let pending of pendingList" >
                        <div class="tag-initial-alphabet ml-0">
                            <h3 class="grey-m fw-600 fs13 mb-0">{{pending.workSpaceName}}</h3>
                        </div>
                        <div class="d-flex align-items-center border-bottom-a py-3" *ngFor="let p of pending.pendingList">
                            <div class="flex-grow-1 mr-2">
                                <h1 class="grey-m fs15 mb-0 text-capitalize" [innerHtml]="p.maildescription"></h1>
                            </div>
                            <div>
                                <button class="btn btn-blue br25  btn-block pt7 pb7 fs13 fw-600 buttonload" type="button" routerLink='/company/dashboard' [queryParams]="conCadSessionData({})">
                                    <em class="fa fa-spinner fa-spin"></em>Accept</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </div>
   </div>

</section>  
<app-sub-footer></app-sub-footer>