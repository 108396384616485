<div id="overlay" [ngClass]="{'d-block': forgetPasswordToggle}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : forgetPasswordToggle}">
   <div class="d-flex justify-content-between">
      <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 ">Forgot Password?</h1>
      <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="ToggleForgetpassword()"></em>
   </div>

   <p class="fs12 grey-j ls-3 ">Please type your E-Mail</p>
   <form class="mt-4 " name="forgetForm" #forgetForm="ngForm" novalidate>
      <div class="form-group input-rounded">
         <input name="email" required (keyup.enter)="forgetForm.form.valid && onSubmit(forgetForm)"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"  [(ngModel)]="ForgetPassword.email" #email="ngModel"
         [ngClass]="{ 'has-error': forgetForm.submitted && email.invalid }" type="email" class="form-control br8 pt22 pb22 fw-600" placeholder="E-Mail">
         <div *ngIf="email.invalid && (email.dirty || email.touched)">
            <div *ngIf="email.errors.required" class="text-danger fs12">
               Email is required.
            </div>
            <div *ngIf="email.errors.pattern" class="fs12 text-danger">
               Enter a valid email.
            </div>
         </div>
      </div>
      <div class="row mt50 px-3">
         <div class="col-4 pr-1">
            <button type="button" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 "
               (click)="ToggleForgetpassword()" type="button">Cancel</button>   
         </div>
         <div class="col-8 pl-1">
            <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" type="button"  
            (click)="forgetForm.form.valid && onSubmit(forgetForm)" [disabled]="!forgetForm.valid">
            <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Next</button>
         </div>   
      </div>
   </form>   
</div>

<ng-template #exampleModal>
   <div class="modal-lg signup-modal">
       <div class="modal-content border-0">
           <div class="modal-header border-bottom-0 pb-0">
               <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

               <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
           </div>
           <div class="modal-body">
               <p class="fs14 fw600 grey-m py-3 text-center">Seems like you have already been invited to a project, 
                  please click "Cancel" to join the project via invitation link in your mail. 
                  Please click "OK" to register and create your own company in Pixly.</p>
               <div class="modal-btn text-right">
                   <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                       [disabled]="submitted" (click)="yesMethod()">
                       <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>OK</button>
                   <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                       (click)="modalRef.hide()">Cancel</button>
               </div>
           </div>
       </div>
   </div>
</ng-template>

<app-sendotp-sidenav [showToggle]="showNav" [showCreate]="showCreate" (otpSubmit)="getOtpType($event, forgetForm)"></app-sendotp-sidenav>