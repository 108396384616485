import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api_base/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-company-workspace',
  templateUrl: './company-workspace.component.html',
  styleUrls: ['./company-workspace.component.scss']
})
export class CompanyWorkspaceComponent implements OnInit {
  user: any = {};
  workspaceList: any = [];
  companyWorkspaceList: any = [];
  public loadingPage: boolean = false;
  public userRoleList = [];
  public submittedForm: boolean = false;
  public submitted: boolean = false;
  public selectedCompanyId: any;
  public stopScroll = true;
  public pageNumber = 1;
  public pageRecord = 10;
  public showNav = false;
  public selectedWorkspaceId: any;

  constructor(
    private apis: ApiService,
    private toastr: ToastrService,
    private workspaceService: WorkspaceServiceService,
    private support: SupportService
  ) { }

  ngOnInit(): void {
    this.support.companyProfile.subscribe(res => {
      this.selectedCompanyId = res.selectedCompanyId;
      if (this.selectedCompanyId) {
        this.getWorkspacesByCompanyId();
      }
    });
  }
  public getWorkspacesByCompanyId(): void {
    this.workspaceService.getWorkspacesByCompanyId(this.selectedCompanyId).subscribe(
      (res): any => {
        if (res) {
          this.companyWorkspaceList = res;
          this.getAllWorkspaces(0, 10);
        }
      },
      (err): any => {
        this.apis.handleError(err);
      },
    );
  }

  public getAllWorkspaces(pageNumber, pageRecord): void {
    this.loadingPage = true;
    this.workspaceService.getAllWorkspace(pageNumber, pageRecord).subscribe(
      (res): any => {
        if (res && res.status && res.status.status === 200) {
          const companyWorkspaceNames = this.companyWorkspaceList.map(workspace => workspace.workSpaceName);
          this.workspaceList = res.entity.filter(workspace => !companyWorkspaceNames.includes(workspace.masterTenant.workSpaceName));
          this.loadingPage = false;
        }
      },
      (err): any => {
        this.apis.handleError(err);
        this.loadingPage = false;
      },
    );
  }

  createWorkspace() {
    this.submitted = true;
    this.workspaceService.addWorkspaceToCompany(this.selectedCompanyId, this.selectedWorkspaceId).subscribe(
      (res): any => {
        if (res) {
          this.toastr.success('Workspace successfully associated with company.');
          this.submitted = false;
          this.selectedWorkspaceId = null;
          this.showNav = false;
          this.getWorkspacesByCompanyId();
        } else {
          this.submitted = false;
          this.toastr.error(res.status.msg);
        }
      },
      (err): any => {
        this.submitted = false;
        this.apis.handleError(err);
      },
    );
  }

  public onScrollUser(): any {
    if (this.stopScroll) {
      this.pageNumber += 1;
    }
  }

}
