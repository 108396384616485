import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services';

@Component({
  selector: 'app-pending-invites',
  templateUrl: './pending-invites.component.html',
})
export class PendingInvitesComponent implements OnInit {

  pendingList: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.pendingList = JSON.parse(localStorage.getItem('pendinglist'));
  }

  public conCadSessionData(obj): any {
    return { ...this.api.getSessionData(), ...obj };
  }
}
