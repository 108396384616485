/* eslint-disable @typescript-eslint/indent */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-footer',
  templateUrl: './sub-footer.component.html',
})
export class SubFooterComponent implements OnInit {
  public dateNow = new Date();

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  ngOnInit(): void {
    }
}
