import { Injectable, EventEmitter } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as uuid from 'uuid';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public progress: EventEmitter<any> = new EventEmitter<any>();
    kAccessKey='';
    KsecretKey= '';
    KRegion='';
    kIMAGEBUCKET='';
    KInsourceBucketUrl='';

  constructor() {
    this.kAccessKey = environment.kAccessKey;
    this.KsecretKey = environment.KsecretKey;
    this.KRegion = environment.KRegion;
    this.kIMAGEBUCKET = environment.kIMAGEBUCKET;
    this.KInsourceBucketUrl = environment.KInsourceBucketUrl;
  }
  //   const poolData = { UserPoolId: this.UserPoolId, ClientId: this.ClientId };
  //   const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  //   const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
  //     Username: this.Username,
  //     Password: this.Password,
  //   });
  //   const userData = { Username: this.Username, Pool: userPool };

  //   const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  //   const { IdentityPoolId, defaultRegion, UserPoolId } = this;
  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     onSuccess(result) {
  //       AWS.config.update({
  //         region: defaultRegion,
  //         credentials: new AWS.CognitoIdentityCredentials({
  //           IdentityPoolId,
  //           Logins: {
  //             [`cognito-idp.${defaultRegion}.amazonaws.com/${UserPoolId}`]: result.getIdToken().getJwtToken(),
  //           },
  //         }),
  //       });
  //     },
  //     onFailure(err) {
  //       console.error(err);
  //     },
  //     newPasswordRequired(userAttributes) {
  //       const userAttr = userAttributes;

  //       delete userAttr.email_verified;

  //       // unsure about this field, but I don't send this back
  //       delete userAttr.phone_number_verified;

  //       // Get these details and call
  //       cognitoUser.completeNewPasswordChallenge(this.Password, userAttr, this);
  //     },
  //   });
  // }

   public getS3Credential(): any {
        const S3Client = new S3({
            region: environment.KRegion,
            accessKeyId: environment.kAccessKey,
            secretAccessKey: environment.KsecretKey,
        });
        return S3Client;
    }

   
    // public getS3Params(file): any {
    //     const params = {
    //         Bucket: environment.kIMAGEBUCKET,
    //         Key: `${uuidv4()}.${file.name.replace(/^.*\./, '')}`,
    //         Body: file,
    //         ACL: 'public-read',
    //         ContentType: file.type,
    //     };
    //     return params;
    // }

    public getS3Params(file): any {
      const params = {
          Bucket: environment.kIMAGEBUCKET,
          Key: `${uuidv4()}.${file.target.files[0].name.replace(/^.*\./, '')}`,
          Body: file.croppedImage,
          ACL: 'public-read',
          ContentType: file.target.files[0].type,
      };
      return params;     
  }


    public uploadProfile(file): any {
        const S3Client = this.getS3Credential();
        const params = this.getS3Params(file);        
        return new Promise((ress, rejs): any => {
            S3Client.upload(params, (err: any, data: any): any => {
              
                if (err) {
                    rejs(err);
                }
                ress(data);
            }).on('httpUploadProgress', (events): any => {
            // change here            
                const progresss = Math.round((events.loaded / events.total) * 100);
                this.progress.emit({ progresss });
            });
        });
    }

  uploadWallpaper(file) {
    const uniquename = uuid.v4();
    const fileExtension = file.name.replace(/^.*\./, '');
    const filename = `${uniquename}.${fileExtension}`;

    const params = {
      Bucket: this.kIMAGEBUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type,
    };

    const S3Client = new AWS.S3();

    return new Promise((res, rej) => {
      S3Client.upload(params, (err: any, data: any) => {
        if (err) {
          rej(err);
        }
        res(data);
      }).on('httpUploadProgress', (event) => {
        // change here
        const progress = Math.round((event.loaded / event.total) * 100);

        this.progress.emit({ progress });
      });
    });
  }

  
}
