<div id="overlay" [ngClass]="{'d-block': toggleSidemenu}"></div>
<div #sidenav class="right-sidebar bg-white " [ngClass]="{'show-side-nav' : toggleSidemenu}">


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Filters</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeFilter()"></em>
    </div>

    <a href="javascript:void(0)" (click)="clearfilter()" class="blue-a fs14 float-right">Clear Filter
        <span class="fas fa-filter fs11 pl-2 font-weight-bold"></span></a>
    <div class="clearfix"></div>

    <form [formGroup]="filterBilling" (ngSubmit)="filterSubmit()" class="position-relative h-100" novalidate>
        <div class="contact-scroll pb100 overflow-x-hidden">
            <h2 class="mb-3 mt-4 color-grey-a fs16 font-weight-bold">Date :</h2>
            <div class="row form-group">
                <div class="col-md-6">
                    <label class="color-grey-j fs14">From</label>
                    <input type="text" formControlName="fromDate"
                        placeholder="Select Date" class="form-control fw-600 fs14 text-center" bsDatepicker
                        [bsConfig]="datePickerConfig" #dp="bsDatepicker">

                    
                </div>
                <div class="col-md-6">
                    <label class="color-grey-j fs14">To</label>
                    <input type="text" formControlName="toDate"
                        placeholder="Select Date" class="form-control fs14 fw-600 text-center" [minDate]="filterBilling.get('fromDate').value" bsDatepicker
                        [bsConfig]="datePickerConfig" #dp="bsDatepicker" [isDisabled]="filterBilling.get('fromDate').value === '' || filterBilling.get('fromDate').value === null">

                 
                </div>
            </div>
            <p class="color-grey-j fs12 mb-5">Note: Please choose From date first then To date</p>

            <h2 class="mb-3 color-grey-a fs16 font-weight-bold">Action :</h2>
            <div class="row form-group mb-5">
                <div class="col-md-6">
                    <div class="filter-action-radio">
                        <input type="radio" id="success" formControlName="actions" value="1" class="d-none">
                        <label for="success">Success</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="filter-action-radio">
                        <input type="radio" id="failure" formControlName="actions" value="2" class="d-none">
                        <label for="failure">Failure</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row bg-white py-4 pl-4 position-absolute w-100 positioning-bottom">
            <div class="col-5 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600" type="button"
                    (click)="closeFilter()">Cancel</button>

            </div>
            <div class="col-7 pl-1">
                <button type="submit" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload">Apply Filters</button>
            </div>
        </div>
    </form>

</div>