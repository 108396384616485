import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { SupportService } from '../services/support.service';
import { ApiService } from '../services/api_base/api.service';
import { AlbumserviceService } from '../services/albumservice.service';
import { DashboardChartComponent } from '../dashboard-chart/dashboard-chart.component';
import { UserService } from '../services';

@Component({
    selector: 'app-project-dashboard',
    templateUrl: './project-dashboard.component.html',
    styles: [`.info-window-container {
        max-width: 320px;
        max-height: 90px;
      }`]
})
export class ProjectDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DashboardChartComponent) public child: DashboardChartComponent;

    public albumDetails: [];

    public showProject: boolean;

    public prodashunsub: any;

    public lattitude = 0;

    public longitude = 0;

    public projectDash: any;

    public bsStartDate = new Date();

    public bsEndDate = new Date();

    public formatDate = (date) => date.toISOString().slice(0, 19).replace('T', ' ');

    public datePickerConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'YYYY-MM-DD',
    };

    public filter = {
        start: this.formatDate(new Date(this.bsStartDate.setMonth(this.bsStartDate.getMonth() - 1))),
        end: this.formatDate(new Date(this.bsEndDate)),
    }

    public barChartOne = {
        title: '',
        data: [{

            data: [],
        },
        ],
    };

    public type = 'day';

    public pending = false;

    public workspacePendingList: any = [];

    public projectIconDetails = false;

    projectImgs = [];

    latLngImgArray = [];

    selectedMarker: number | null = null;

    mapCenter;

    initialZoom;

    public constructor(private support: SupportService, private workspace: WorkspaceServiceService,
        private toastr: ToastrService, private router: Router, private apis: ApiService, private albumService: AlbumserviceService,
        public userService: UserService) {
        this.showProject = !!sessionStorage.getItem('projectId');
        this.prodashunsub = this.support.projectChange.subscribe((res): any => {
            if (res && res !== 'empty') {
                    this.latLngImgArray = [];
                    this.getProjectDashboard();
                    this.getUserTypeByProjectId();
                    this.getAllWorkspacePendingInvites();
                    this.getAllImageLocationsForProjects();
                }
        });
    }

    public ngOnInit(): void {
        // this.apis.checkUrlData();
        this.getProjectDashboard();
        this.getUserTypeByProjectId();
        this.albumDisplayLocation();
        this.getChartData();
    }

    ngAfterViewInit(): void {
        this.getAllImageLocationsForProjects();
    }

    public ngOnDestroy(): any {
        this.prodashunsub.unsubscribe();
    }

    // album location display method in project dashboard
    public albumDisplayLocation(): void {
        const albDetails = {
            pagenumber: 0,
            pagerecord: 0,
            search: '',
            sortParam: 'createdOn',
            sortType: 'desc',
            sortviewby: 'day',
            teamId: sessionStorage.getItem('projectId'),
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
        };
        this.albumService.getAlbumByTeamId(albDetails).subscribe((albumPostRes): void => {
            if (albumPostRes && albumPostRes.entity && albumPostRes.entity.listview) {
                this.albumDetails = albumPostRes.entity.listview.map((child): any => child.albumInfo).flat();
            }
        });
    }

    public getUserTypeByProjectId(): any {
        this.workspace.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
            (res): any => {
                if (res && res.entity) {
                    if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                        sessionStorage.setItem('usertype', res.entity.roleId);
                        this.support.usertype.next(res.entity.roleId);
                    }
                }
            },
        );
    }

    public getProjectDashboard(): any {
        this.workspace.getProjectDashboard().subscribe(
            (res): any => {
                if (res && res.entity) {
                    this.projectDash = res.entity;
                }
            }, (err): any => {
                this.apis.handleError(err);
            },
        );
    }

    public getChartData(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            projectId: sessionStorage.getItem('projectId'),
            fromDate: this.filter.start,
            toDate: this.filter.end,
            type: this.type,
        }
        this.workspace.getChartDataApi(payload).subscribe(
            (res): any => {
                if (res && res.entity) {
                    if (this.type === 'month') {
                        this.barChartOne.data[0].data = [];
                        res.entity.forEach(
                            (element): void => {
                                const cDate = new Date(element.month);
                                this.barChartOne.data[0].data.push([Date.UTC(cDate.getFullYear(), cDate.getMonth(), cDate.getDate()), element.photocount]);
                            },
                        );
                    } else {
                        this.barChartOne.data[0].data = [];
                        res.entity.forEach(
                            (element): void => {
                                const cDate = new Date(element.date);
                                this.barChartOne.data[0].data.push([Date.UTC(cDate.getFullYear(), cDate.getMonth(), cDate.getDate()), element.photocount]);
                            },
                        );
                    }

                    if (this.child) {
                        this.child.fetchData();
                    }
                }
            }, (err): any => {
                this.apis.handleError(err);
            },
        );
    }

    public conCadSessionData(obj): any {
        return { ...this.apis.getSessionData(), ...obj };
    }

    public getAllWorkspacePendingInvites(): void {
        this.userService.getWorkspacePendingList().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.pending = true;
                this.support.pendinginvite.next(this.pending);
                this.workspacePendingList = res.entity;
            }
        })
    }

    public getAllImageLocationsForProjects(): void {
        const userId = localStorage.getItem('userId');
        const tenantId = localStorage.getItem('tenantId');
        const projectId = sessionStorage.getItem('projectId');
        this.workspace.getAllImageLocationsForProjects(userId, tenantId, projectId).subscribe((res): any => {
            if (res.entity === null || res.entity.length == 0) {
                this.lattitude = 33.037830
                this.longitude = -117.293990
            } else {
                this.projectImgs = res.entity;
                if (this.projectImgs && this.projectImgs.length) {
                    const filteredData = this.projectImgs.filter(item => item.latitude && item.longitude && item.media && item.media.awsKey);
                    const coordinateMap = {};
                    filteredData.forEach(item => {
                        const imageUrl = `http://d4381dmmtr1wk.cloudfront.net/${item.media.awsKey}`;
                        const coordinateKey = `${item.latitude},${item.longitude}`;
    
                        // Check if the coordinates exist in the coordinate map
                        if (coordinateMap[coordinateKey]) {
                            coordinateMap[coordinateKey].push({
                                imageUrl: imageUrl,
                                latitude: Number(item.latitude),
                                longitude: Number(item.longitude)
                            });
                        } else {
                            coordinateMap[coordinateKey] = [{
                                imageUrl: imageUrl,
                                latitude: Number(item.latitude),
                                longitude: Number(item.longitude)
                            }];
                        }
                    });
                    if(filteredData.length === 0){
                        const projectLocation = this.projectImgs.filter(item => item.latitude && item.longitude);
                        if(projectLocation.length>0){
                            this.lattitude=projectLocation[0].latitude;
                            this.longitude=projectLocation[0].longitude;
                        }
                    }
                    const mappedData = Object.values(coordinateMap).map((coordinate: any) => {
                        return {
                            latitude: coordinate[0].latitude,
                            longitude: coordinate[0].longitude,
                            images: coordinate.map(image => image.imageUrl)
                        };
                    });
                    
                    this.latLngImgArray = this.latLngImgArray.concat(mappedData);
                    if (this.latLngImgArray && this.latLngImgArray.length > 0) {
                        this.lattitude = this.latLngImgArray[0]['latitude'];
                        this.longitude = this.latLngImgArray[0]['longitude'];
                      }
                } else {
                    this.latLngImgArray = []
                    this.lattitude = 40.730610
                    this.longitude = -73.935242
                }
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    toggleInfoWindow(index: number): void {
        if (this.selectedMarker === index) {
            this.selectedMarker = null;
        } else {
            this.selectedMarker = index;
        }
    }
}
