<div id="overlay" [ngClass]="{'d-block': toggleSidemenu}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : toggleSidemenu}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Edit Workspace Name</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16" (click)="closeFilter()"></em>
    </div>

    <form  novalidate>
        <div class="form-group mt-3">
            <label class="color-grey-j fs14">Edit Workspace Name</label>
            <input type="text" maxlength="50" placeholder="Enter workspace name" name="company" required [(ngModel)]="companyname"
            #company="ngModel" class="form-control fw-600 fs14">
        </div>

        <div class="row py-4">
            <div class="col-5 pr-1">
                <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600" type="button"
                    (click)="closeFilter()">Cancel</button>
            </div>
            <div class="col-7 pl-1">
                <button type="submit" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" (click)="editCompany()">
                    <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Save</button>
            </div>
        </div>
    </form>

</div>
