import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, LoginService, UserService } from '../services';
import { SupportService } from '../services/support.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';

@Component({
  selector: 'app-your-invites',
  templateUrl: './your-invites.component.html',
})
export class YourInvitesComponent implements OnInit {

  public filterSidemenu: boolean;

  public workspacePendingList: any = [];

  public pending = false;

  public accepted = false;

  public rejected = false;

  public acceptreject = true;

  constructor(private router: Router, public userService: UserService, private loginService: LoginService,
    private workspaceService: WorkspaceServiceService, private apiService: ApiService, private support: SupportService) { }

  ngOnInit(): void {
    this.getAllWorkspacePendingInvites();
  }

  public closeSidemenus(): void {
      this.filterSidemenu = !this.filterSidemenu;
  }

  public getAllWorkspacePendingInvites(): void {
    this.userService.getWorkspacePendingList().subscribe((res): any => {
        if (res && res.status && res.status.status === 200) {
            this.pending = true;
            this.workspacePendingList = res.entity[0].pendingList;
            this.workspacePendingList.forEach(element => {
              element.rejected = false;
              element.accepted = false;
              element.acceptreject = true;
            });          
        } else {
          this.workspacePendingList = [];
          this.pending = false;
          this.support.pendinginvite.next(this.pending);
          this.router.navigate(['/project/dashboard']);
        }
    })
  }

  public getFirstAndLastLetter(name): string {
    let value = 'Ws';
    if (name) {
        const split = Array.from(name);
        value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
    }
    return value;
  }

  public acceptInvite(pending): void {
    pending.accepted = true;
    pending.acceptreject = false;
    const payload = {
      password: '',
      workSpaceName: sessionStorage.getItem('workspacename'),
      userId: localStorage.getItem('userId'),
      verificationCode: pending.verifyTeamMember,
    };
    this.loginService.setPassword(payload).subscribe(
      (res): any => {
        if (res.status.status === 200) {
          if (pending.projectId && pending.projectId !== 'null') {
            sessionStorage.setItem('projectId', pending.projectId);
            this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
              (res): any => {
                if (res && res.entity) {
                  if (res.entity && res.entity.roleId && (res.entity.roleId === 3 || res.entity.roleId === 4 || res.entity.roleId === 5)) {
                    sessionStorage.setItem('usertype', res.entity.roleId);
                    setTimeout((): any => {
                      if (res.entity.roleId === 3) {
                          this.router.navigate(['/project/dashboard'], { queryParams: this.apiService.getSessionData() });
                      } else {
                          this.router.navigate(['/project/feed'], { queryParams: this.apiService.getSessionData() });
                      }
                    }, 300);
                    this.support.allProject.next('all');
                    this.support.projectChange.next(pending.projectId);
                  }
                }
              },
            );
          } else {
            this.workspaceService.getUserTypeService();
          }
        }
      },
    );
  }

  public rejectInvite(pending): void {
    pending.rejected = true;
    pending.acceptreject = false;
    const payload = {
      userId: localStorage.getItem('userId'),
      workSpaceName: sessionStorage.getItem('workspacename'),
      verificationCode: pending.verifyTeamMember,
    }
    this.userService.rejectInvite(payload).subscribe((res): any => {
      if (res && res.status && res.status.status === 200) {
        this.getAllWorkspacePendingInvites();
      }
    })
  }

}
