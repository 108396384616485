<ngx-loading-bar [color]="'#007bff'"></ngx-loading-bar>
<section class="main-container">
    <div class="sub-container">
        <div class="album-content-container pt8 pb-2">

            <div class="album-card br-blr-10 border-grey shadow-none">
                <div class="card-header bg-white border-0 pt30 px-4">
                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                        <div>
                            <h2 *ngIf="recentAlbumList.length !== 0" class="grey-g fs14 fw-600 ls-d pl-2"> Most Recent
                                albums</h2>
                        </div>
                        <div class="d-flex align-items-center">
                            <!-- <div (click)='feedCreateIconDetails=!feedCreateIconDetails'>
                                <img src="./assets/images/i-icon.svg" alt="add" class="mr-1 mb-1 h60 pt6">
                            </div> -->
                            <!-- <div class="album-create-i-icon-box" *ngIf='feedCreateIconDetails'>
                                <div class="modal-content border-0">
                                    <div class="modal-header border-bottom-0 pb-0">
                                        <h4 class="modal-title fs20 fw-600 pull-left grey-g">Pixly create album</h4>
                                        <em class="lnr lnr-cross close-icon fw-600 fs16 "
                                            (click)="feedCreateIconDetails=false"></em>
                                    </div>
                                </div>
                                <iframe #createFrame id="ston-3rQmQeQqbI"  class="w-100 h-100" frameBorder="0" name="StonlyExplanation" title="Stonly" src="https://stonly.com/embed/en/3rQmQeQqbI/view/684155"> </iframe>  
                            </div> -->
                            <button *ngIf="userType && userType !== '5'"
                                class="btn  btn-blue-secondry px-sm-3 px-2 br25 py-2 fs12 d-flex align-items-center justify-content-center"
                                (click)="Create(0)"><img src="./assets/images/plus.svg" alt="add"
                                    class="mr-2 w11 mb-0">Create Album</button>
                        </div>
                    </div>
                </div>
                <div class="card-body pb-0 pt-0 px-3" *ngIf="recentAlbumList && recentAlbumList.length > 0">
                    <div class="d-flex flex-wrap align-items-center ">

                        <div class="mb-2 mr-3" *ngFor="let recent of recentAlbumList">
                            <div class="">
                                <img *ngIf="recent.album.photoCount > 0 && !recent.album.photoName[0].endsWith('.mp4')"
                                    style="height: 150px;width: 150px;object-fit: fill;"
                                    [routerLink]="['/project/album-details']"
                                    [queryParams]="conCadSessionData({albumId: recent.album.albumId,lat: lat,lng: lng, copyImageFrom: copyDatas.albumId})"
                                    src={{cloudFrontURL}}{{recent.album.photoName[0]}} alt="user1"
                                    class="w100 h100 grid-view-img highlighting object-fit-cover br10 mb-2 c-pointer"
                                    [ngClass]="{'highlighted': recent.album.albumId === copyDatas.albumId}">

                                <video
                                    *ngIf="recent && recent.album && recent.album.photoName[0] && recent.album.photoName[0].endsWith('.mp4')"
                                    class="dark-img br10 object-fit-cover c-pointer" height="150" width="150">
                                    <source [src]="cloudFrontURL + recent.album.photoName[0]" type="video/mp4" />
                                    Browser not supported
                                </video>

                                <img *ngIf="recent.album.photoCount === 0" src="./assets/images/no-image.jpg"
                                    style="height: 150px;width: 150px;object-fit: fill;" alt="user1"
                                    class="w100 h100 grid-view-img object-fit-cover br10 mb-2">
                            </div>
                            <a [routerLink]="['/project/album-details']"
                                [queryParams]="conCadSessionData({albumId: recent.album.albumId,lat: lat,lng: lng})">
                                <h2 class="fs14 grey-g ls-d fw-600 my-2 pl-2" title={{recent.album.albumName}}>
                                    {{recent.album.albumName | ngXtruncate:12:'...'}}</h2>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-card border-grey shadow-none br10 my-3 pb-3 mb-5">
                <div class="card-header br10 bg-white border-0">
                    <div class="btn-grey-f d-flex justify-content-between align-items-center br10 px-3 pt8 pb8 ">
                        <div>
                            <h2 class="fs12 ls-e grey-g text-uppercase mb-0 font-weight-bold">Albums</h2>

                        </div>
                        <div *ngIf="isPdfReport" class="d-flex align-items-center">
                            <button class="btn btn-orange-light br9 h24 pt3" (click)="reportModal()" type="button"> <img
                                    src="./assets/images/noun-PDF.svg" alt="pdf" class="mr-2 w15 h15" />Get PDF</button>
                        </div>
                        <div *ngIf="!isPdfReport" class="d-flex align-items-center">
                            <a (click)="getAlbumsort()">
                                <p class="mb-0 ls-e fs12 grey-g font-weight-bold c-pointer">{{DateText}}
                                    <img src="./assets/images/up.svg" alt="sort" class="ml-2 w9 mb6 c-pointer">
                                    <img src="./assets/images/down.svg" alt="sort" class=" w9 c-pointer">
                                </p>
                            </a>

                            <div dropdown class="tag-menu c-pointer ml-4">

                                <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal" class="w23"
                                    id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="basic-link">
                                    <li>
                                        <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)"
                                            [ngClass]="sortType === 'day'? 'blue-href': ''"
                                            (click)="getAlbumMonthOrYear('day')">View by date</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)"
                                            [ngClass]="sortType === 'month'? 'blue-href': ''"
                                            (click)="getAlbumMonthOrYear('month')">View by Month</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)"
                                            [ngClass]="sortType === 'year'? 'blue-href': ''"
                                            (click)="getAlbumMonthOrYear('year')">View by Year</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0">
                    <ul class="nav nav-pills pills-primary px-4 mb-2" id="pills-tab" role="tablist">
                        <li class="nav-item mr-4" role="presentation">
                            <a [class.active]="viewMode=='Folders'" (click)="getListList(1)" class="nav-link  px-4"
                                href="javascript:void(0)">Folders</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a [class.active]="viewMode=='Photos'" (click)="getListList(2)" class="nav-link px-4"
                                href="javascript:void(0)">Photos/Videos</a>
                        </li>
                    </ul>
                    <div class="tab-content " [ngSwitch]="viewMode">
                        <div class="tab-pane fade show active " *ngSwitchCase="'Folders'">
                            <div *ngIf="byGrid" class="pl30 pr30">
                                <div class="row c-pointer" *ngFor="let albumList of albumData; let a = index">

                                    <div class="col-12 mb-2 mt-4">
                                        <h2 class="grey-g fs14  ls-i font-weight-bold mr-2">{{albumList.date}}</h2>
                                    </div>
                                    <div class="col-4 col-sm-3 grid-col album-grid pr4  pl4 mb-4"
                                        *ngFor="let album of albumList.albumInfo; let ii = index">
                                        <div class="position-relative album-all-photos">
                                            <div class="c-pointer album-edit-delete " style="z-index:999"
                                                *ngIf="((album.author && album.author.id === userId && userType === '4') || userType === '3' || userType === '2' || userType === '1')">
                                                <div>
                                                    <em class="fas fa-edit fs13 blue-a grey-s br3 p5" title="Edit Album"
                                                        (click)="getAlbumById(album.albumId)"></em>
                                                </div>
                                                <div class="ml-1">
                                                    <em class="far fa-trash-alt fs13 red-f grey-s br3 p5"
                                                        title="Delete Album"
                                                        (click)="!submitted && openModal(album.albumId,ii, a)"></em>
                                                </div>
                                                <div class="ml-2"
                                                    *ngIf="copyDatas && copyDatas.albumId && copyDatas.albumId !== album.albumId"
                                                    (click)="!submitted && savePhoto(album.albumId)">
                                                    <em style="color: #007bff;" class="fas fa-paste fs13 grey-s br3 p5"
                                                        title="Paste"></em>
                                                </div>
                                            </div>
                                            <input type="checkbox" name="check-album" id="check-album{{album.albumId}}"
                                                class="d-none" [checked]="albumIdList.includes(album.albumId)"
                                                (change)="albumChoose(album, $event.target.checked)">
                                            <label class="album-check mb-0" for="check-album{{album.albumId}}"
                                                [ngClass]="{'embed-responsive embed-responsive-1by1': (album && album.photoName[0] && album.photoName[0].endsWith('.mp4'))}">
                                                <img src="./assets/images/tick-rounded.svg"
                                                    *ngIf="album.photoCount !== 0" alt="tick" class="w14 check-img">
                                                <div class="view-album c-pointer py-2 px-1 text-nowrap">
                                                    <p class="fs14 mb-0 text-white"
                                                        [routerLink]="['/project/album-details']"
                                                        [queryParams]="conCadSessionData({albumId: album.albumId,lat: lat,lng: lng, copyImageFrom: copyDatas.albumId})">
                                                        View
                                                        Album</p>
                                                </div>
                                                <div class="resp-img"
                                                    *ngIf="album.photoCount > 0 && !album.photoName[0].endsWith('.mp4')">
                                                    <img src={{cloudFrontURL}}{{album.photoName[0]}} alt="user1"
                                                        class="w-100 grid-view-img highlighting-img object-fit-cover br10"
                                                        [ngClass]="{'highlighted': album.albumId === copyDatas.albumId}">
                                                </div>
                                                <video
                                                    *ngIf="album && album.photoName[0] && album.photoName[0].endsWith('.mp4')"
                                                    class="dark-img br10 object-fit-cover c-pointer"
                                                    style="height: 100%; width: 100%;"
                                                    [ngClass]="{'highlighted': album.albumId === copyDatas.albumId}">
                                                    <source [src]="cloudFrontURL + album.photoName[0]"
                                                        type="video/mp4" />
                                                    Browser not supported
                                                </video>
                                                <img *ngIf="album.photoCount === 0" src="./assets/images/no-image.jpg"
                                                    alt="user1" class="w-100 grid-view-img object-fit-cover br10">
                                            </label>
                                        </div>

                                        <h1 class="grey-g fs15 fw-600 ls-g mb-0" title={{album.albumName}}>
                                            {{album.albumName | ngXtruncate:20:'...'}}</h1>
                                        <div>
                                            <span class="grey-g ls-h fs10 fw-600 ">{{album.albumVisibility}}</span>
                                        </div>
                                        <div>
                                            <span class="grey-g ls-h fs10 fw-600 ">{{album.photoCount}}
                                                items</span><span
                                                class="fw-600 grey-g ls-h fs10 task-doneby">{{getDateValue(album.createdOn)
                                                | date: 'MMM dd yyyy'}}</span>
                                        </div>

                                    </div>


                                    <div class="pr4  pl4 mb-4" *ngIf="!loadingPage && albumData.length === 0">
                                        {{errorMessage}}</div>
                                </div>
                            </div>
                            <div *ngIf="byList" class="mt-2">
                                <div class="border-bottom-grey px-3 mt-3"
                                    *ngFor="let album of albumData; let a = index">
                                    <h1 class="fs15 grey-g fw-600 ls-g mb-0 pl-3">{{album.albumName}}</h1>
                                    <div class="d-flex pl-3 align-items-center">
                                        <div>
                                            <span class="grey-g ls-7 fs12 fw-600 ">{{album.photoCount}}
                                                items</span><span
                                                class="fw-600 grey-g ls-7 fs12 task-doneby">{{album.sortViewBy}}</span>
                                        </div>
                                        <div class=" c-pointer album-edit-delete" *ngIf="userType !== '4'">
                                            <em class="far fa-trash-alt fs13 red-f grey-s br3 p5" title="delete album"
                                                (click)="openModal(album.albumId,ii,a)"></em>
                                        </div>
                                    </div>


                                    <img src="./assets/images/no-image.jpg" alt="user1"
                                        [routerLink]="['/project/album-details', album.albumId]"
                                        [queryParams]="conCadSessionData({})" class=" br10 mb-2 posted-img mb-3 mt-2">
                                </div>

                            </div>

                            <div class="page-loaders text-center h-600px my-5" *ngIf="loadingPage">
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50" cy="50" r="45" />
                                </svg>
                                <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                            </div>

                        </div>
                        <div class="tab-pane fade show active px-4" *ngSwitchCase="'Photos'">
                            <div *ngFor="let photo of photoFeedList">
                                <div class="d-flex mb-2 mt-4">
                                    <h2 class="grey-g fs14 ls-i font-weight-bold mr-2">{{photo.date !== 'null' ? photo.date : ''}}</h2>
                                </div>

                                <div class="row mx-0">
                                    <div class="col-6 col-md-4 px-1 pt-1 album-all-photos position-relative"
                                        *ngFor="let p of photo.photoInfo">
                                        <img *ngIf="p.media && p.media.awskey && photoExtentions.includes(p.media.awskey.split('.')[p.media.awskey.split('.').length -1])"
                                            src={{cloudFrontURL}}{{p.media.awskey}} style="object-fit: fill;
                                        height: 180px;
                                        width: 100%; max-width: 100%;" alt="user1" class="br5">


                                        <!-- <video *ngIf="recent && recent.album && recent.album.photoName[0] && recent.album.photoName[0].endsWith('.mp4')"
                                            class="dark-img br10 object-fit-cover c-pointer"
                                            height="150" width="150"
                                            >
                                               <source [src]="cloudFrontURL + recent.album.photoName[0]" type="video/mp4" />
                                                   Browser not supported
                                           </video> -->

                                        <video class="br5" style="object-fit: fill;
                                                   height: 180px;
                                                   width: 100%; max-width: 100%;"
                                            *ngIf="p.media && p.media.awskey && videoExtentions.includes(p.media.awskey.split('.')[p.media.awskey.split('.').length -1])"
                                            controls>
                                            <source src={{cloudFrontURL}}{{p.media.awskey}} alt="profile-video"
                                                type="video/mp4">
                                        </video>


                                        <div class="view-photos c-pointer py-3 px-1 text-nowrap">
                                            <p class="fs14 mb-0 text-white px-3" [routerLink]="['/project/photo-tag']"
                                                [queryParams]="conCadSessionData({albumId: p.albumId, photoId: p.photoId, prevPage: 'project/albums'})">
                                                View {{isVideo(p.media.awskey) ? 'Video' : 'Photo'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pr4 pl4 mb-4 ml-5 mt-2" *ngIf="photoFeedList.length === 0">{{errorMessage}}
                            </div>

                            <div class="page-loaders text-center h-600px my-5" *ngIf="loadingPage">
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50" cy="50" r="45" />
                                </svg>
                                <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div (click)='albumIconDetails=!albumIconDetails' class="row mt50 project-button-row i-icon-box">
        <img src="./assets/images/i-icon.svg" alt="add" class="mr-1 mb-1">
    </div> -->
    <!-- <div class="album-i-icon-box" *ngIf='albumIconDetails'>
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Pixly album</h4>
                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="albumIconDetails=false"></em>
            </div>
        </div>
        <iframe id="ston-ewwrTNQmUx" class="w-100 h-100" frameBorder="0" name="StonlyExplanation" title="Stonly"
            src="https://stonly.com/embed/en/ewwrTNQmUx/view/684261"> </iframe>
    </div> -->
</section>

<div id="overlay" [ngClass]="{'d-block': showCreate}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showCreate}">

    <div class="d-flex justify-content-between pl-3">
        <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3 " *ngIf="!addMembers">
            <span *ngIf="!albumEdit">Create Album</span>
            <span *ngIf="albumEdit">Update Album</span>
        </h1>
        <h1 class="grey-g fs16 font-weight-bold ls-2 mb-3" *ngIf="addMembers">Add Members</h1>

        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="Create(0);ResetForm(albumForm)"></em>
    </div>
    <div class="contact-scroll px-2">
        <form class="mt-2 " name="albumForm" #albumForm="ngForm" novalidate>
            <div [ngClass]="{'d-block':!addMembers,'d-none':addMembers}">
                <div class="form-group mb-4 input-rounded">
                    <label class="fs12 grey-j ls-3 fw-500">What should this album be called?</label>
                    <input maxlength="50" type="text" name="albumName" required [(ngModel)]="album.albumName"
                        #albumName="ngModel" [ngClass]="{ 'has-error': albumName.submitted && albumName.invalid }"
                        class="form-control br8 pt22 pb22 fw-600" placeholder="Album Name"
                        (keydown.space)="album.albumName.trim().length > 0 ? '': $event.preventDefault();">
                    <div *ngIf="albumName.invalid && (albumName.dirty || albumName.touched)">
                        <div *ngIf="albumName.errors.required" class="text-danger fs12">
                            Album name is required.
                        </div>
                    </div>
                </div>

                <label class="fs12 grey-j ls-3  fw-500">Should this album be Public or private</label>

                <div class="get-pdf mb-3">
                    <input class="form-check-input d-none" name="public" type="checkbox" value="PUBLIC"
                        id="defaultCheck1" [checked]="currentlyChecked === check_box_type.PUBLIC"
                        (click)="selectCheckBox(check_box_type.PUBLIC)">
                    <label class="form-check-label d-flex align-items-center  p-2 br8   get-pdf-check c-pointer"
                        for="defaultCheck1">
                        <div class="mr-2  fs12">
                            <em class="far fa-circle square-uncheck grey-i"></em>
                            <em class="fas fa-circle square-checked blue-a"></em>
                        </div>
                        <div>
                            <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">Public</h3>
                            <p class="fs9 mb-0 ls-k get-pdf-subtitle ">Any project member can access this album</p>
                        </div>
                    </label>
                </div>
                <div class="get-pdf  mb-4">

                    <input class="form-check-input d-none" name="private" type="checkbox" value="PRIVATE"
                        id="defaultCheck2" [checked]="currentlyChecked === check_box_type.PRIVATE"
                        (click)="selectCheckBox(check_box_type.PRIVATE)">
                    <label class="form-check-label d-flex align-items-center  p-2 br8 get-pdf-check c-pointer"
                        for="defaultCheck2">
                        <div class="mr-2 fs12">
                            <em class="far fa-circle square-uncheck grey-i"></em>
                            <em class="fas fa-circle square-checked blue-a"></em>
                        </div>
                        <div>
                            <h3 class="fs14 ged-pdf-heading font-weight-bold mb-0 ls-c">Private</h3>
                            <p class="fs9 mb-0 ls-k get-pdf-subtitle ">This album is only viewable by you or
                                People you invite</p>
                        </div>


                    </label>
                </div>
                <div class="form-group input-rounded">
                    <label class="fs12 grey-j ls-3 fw-500">What's this album all about? Type a note</label>
                    <input maxlength="50" type="text" name="description" [(ngModel)]="album.description"
                        #description="ngModel" [ngClass]="{ 'has-error': description.submitted && description.invalid }"
                        class="form-control br8 pt22 pb22 fw-600" placeholder="Album Purpose"
                        (keydown.space)="album.description.trim().length > 0 ? '': $event.preventDefault();">
                    <div *ngIf="description.invalid && (description.dirty || description.touched)">
                        <div *ngIf="description.errors.required" class="text-danger fs12">
                            Album purpose is required.
                        </div>
                    </div>
                </div>

            </div>
            <div [ngClass]="{'d-block':addMembers,'d-none':!addMembers}">
                <div class="form-group mb-2 input-rounded">
                    <label class="fs12 grey-j ls-3 fw-500">Search for Members</label>
                    <input type="search" class="form-control br8 pt22 pb22 fw-600" placeholder="Search for Members"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="userSearch"
                        (ngModelChange)="searchDelay.next($event)">
                    <!-- ViewInvite -->
                </div>

                <p class="fs10 ls-4 grey-j ml-2">Enter the email ID belonging to the person you'd like to invite in the
                    form above.</p>
                <div *ngIf="tempGetMembers && tempGetMembers.length === 0" class="my-3">
                    <button [disabled]="submitted" class="btn  btn-blue-secondry px-sm-3 px-2 br25 py-2 fs12 buttonload"
                        type="button" (click)="ViewInvite()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>
                        <img src="./assets/images/plus.svg" alt="add" class="mr-1 w11 mb-1">Invite</button>
                </div>
                <div class="mt-2">
                    <h2 class="fs14 grey-m fw-600" *ngIf="PendingMembers.length > 0">Members to be invited in this Album
                    </h2>
                    <div class="tag-list mt-2">
                        <div class="d-flex align-items-center justify-content-between border-bottom-a py-2"
                            *ngFor="let members of PendingMembers; let i = index;">
                            <div class="d-flex align-items-center">
                                <img src="./assets/images/empty-img.png" alt="user1" class=" mr-4 people-img w35 h35">
                                <div>
                                    <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">{{members.firstName}}</h1>
                                    <div class="fs12 grey-l ls-7 fw-600 mb-0">{{members.email}}</div>
                                </div>
                            </div>
                            <div (click)="removePendingMember(i)">
                                <em aria-hidden="true" title="cancel"
                                    class="fas fa-times mr-2 red-f fa-xs c-pointer"></em>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="mt-2">

                    <ul class="nav nav-pills pills-primary mb-2" id="pills-tab" role="tablist">
                        <li class="nav-item mr-4" role="presentation">
                            <a [class.active]="viewTab=='projectmembers'" (click)="getTabList(1)"
                                class="nav-link fs12 px-3" href="javascript:void(0)">Project Members</a>
                        </li>
                    </ul>
                    <div class="tab-content " [ngSwitch]="viewTab">
                        <div class="tab-pane fade show active " *ngSwitchCase="'projectmembers'">
                            <!-- Project members -->
                            <div class="tag-list mt-1">

                                <div class="pending-invites-bg" *ngIf="!tempGetMembers || tempGetMembers.length === 0">
                                    <div class="card-body text-center p-3">
                                        <div class="pending-invites-bg">
                                            <img src="./assets/images/no-notification.svg" alt="no-notification"
                                                class="notification-img mt60 w-100">
                                            <h1 class="fs20 font-weight-bold text-black mt40">It's a bit empty here</h1>
                                            <p class="grey-g fw-600 fs13 my-3">
                                                Member not found, You can invite member to album
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngFor="let members of tempGetMembers; let i = index;">
                                    <div class="d-flex align-items-center justify-content-between border-bottom-a py-3"
                                        *ngIf="members.roleType && members.roleType.id === 4 &&  members.user &&  members.user.id !== userId">
                                        <div class="d-flex align-items-center">
                                            <img *ngIf="!members.user || !members.user.profileImage"
                                                src="./assets/images/empty-img.png" alt=""
                                                class="mr-3 people-img w35 h35">
                                            <img *ngIf="members.user && members.user.profileImage"
                                                src={{cloudFrontURL}}{{members.user.profileImage}} alt=""
                                                class="mr-3 people-img w35 h35">
                                            <div>
                                                <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">
                                                    {{members.user.firstName}} {{members.user.lastName}}</h1>
                                                <div class="fs12 grey-l ls-7 fw-600 mb-0">{{members.user.email}}</div>
                                                <div class="fs12 grey-l ls-7 fw-600 mb-0"
                                                    *ngIf="members.user && members.user.id === userId">You</div>
                                            </div>
                                        </div>
                                        <div class="get-pdf">
                                            <input type="checkbox" class="d-none form-check-input" name="radioNoLabel"
                                                id="radioNoLabel{{members.user.id}}" value="{{members.user.id}}"
                                                [checked]="members.checked === true ? true : false" aria-label="..."
                                                (change)="selectedMembers($event,members.user.id,i)">
                                            <label class="mb-0 get-pdf-check bg-white border-0 c-pointer"
                                                for="radioNoLabel{{members.user.id}}">
                                                <em class="far fa-circle square-uncheck grey-i fs12"></em>
                                                <em class="fas fa-circle square-checked blue-a fs12"></em>
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="tempGetMembers.length === 1 && tempGetMembers.user && tempGetMembers.user.id === userId && !submitted"
                                    class=" text-center">
                                    It's seems there is no users available
                                </div>
                            </div>
                            <!-- / Project members  -->
                        </div>
                        <div class="tab-pane fade show active " *ngSwitchCase="'companys'">
                            <!-- companys -->
                            <div class="tag-list mt-1">

                                <div class="pending-invites-bg" *ngIf="!getCompany || getCompany.length === 0">
                                    <div class="card-body text-center p-3">
                                        <div class="pending-invites-bg">
                                            <img src="./assets/images/no-notification.svg" alt="no-notification"
                                                class="notification-img mt60 w-100">
                                            <h1 class="fs20 font-weight-bold text-black mt40">It's a bit empty here</h1>
                                            <p class="grey-g fw-600 fs13 my-3">
                                                Company not found
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let compy of getCompany; let i = index;">
                                    <div class="d-flex align-items-center justify-content-between border-bottom py-3 px-1"
                                        (click)="getCompanyMembersList(compy, i);showCollapsePanel(i)">
                                        <div>
                                            <img *ngIf="!compy.companyProfileImage" src="./assets/images/no-image.jpg"
                                                alt="" class="mr-3 people-img w35 h35">
                                            <img *ngIf="compy.companyProfileImage"
                                                src={{cloudFrontURL}}{{compy.companyProfileImage}} alt=""
                                                class="mr-3 people-img w35 h35">
                                        </div>
                                        <div class="flex-grow-1">
                                            <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">{{compy.companyName}}
                                            </h1>
                                            <p class="fs12 grey-l ls-7 fw-600 mb-0">{{compy.companyEmail}}</p>
                                        </div>
                                        <div class="get-pdf">
                                            <input type="checkbox" class="d-none form-check-input" name="radioNoLabel"
                                                id="radioNoLabel{{compy.companyId}}" value="{{compy.companyId}}"
                                                [checked]="compy.checked === true ? true : false" aria-label="..."
                                                (change)="selectedCompany($event,compy,i);showCollapsePanel(i)">
                                            <label class="mb-0 get-pdf-check bg-white border-0 c-pointer"
                                                for="radioNoLabel{{compy.companyId}}">
                                                <em class="far fa-circle square-uncheck grey-i fs12"></em>
                                                <em class="fas fa-circle square-checked blue-a fs12"></em>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="company-members p-2" *ngIf="openPanel === i">
                                        <div class="text-center"
                                            *ngIf="!loading && (!compy.companyMembers || compy.companyMembers.length === 0)">
                                            Company Members not found</div>
                                        <div class="text-center"
                                            *ngIf="loading && (!compy.companyMembers || compy.companyMembers.length === 0)">
                                            <em class="fa fa-spinner fa-spin "></em> Loading</div>
                                        <ng-container *ngFor="let compMemb of compy.companyMembers; let j = index;">
                                            <div
                                                class="d-flex align-items-center justify-content-between border-bottom p-2">
                                                <div>
                                                    <img *ngIf="!compMemb.profileImage"
                                                        src="./assets/images/empty-img.png" alt=""
                                                        class="mr-2 people-img w35 h35">
                                                    <img *ngIf="compMemb.profileImage"
                                                        src={{cloudFrontURL}}{{compMemb.profileImage}} alt=""
                                                        class="mr-4 people-img w35 h35">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h1 class="grey-m fs13 fw-600 mb-0 text-capitalize">
                                                        {{compMemb.firstName}}</h1>
                                                    <div class="fs12 grey-l ls-7 fw-600 mb-0">{{compMemb.email}}</div>
                                                </div>
                                                <div class="get-pdf">
                                                    <input type="checkbox" class="d-none form-check-input"
                                                        name="radioNoLabel" id="radioNoLabel{{compMemb.userId}}"
                                                        value="{{compMemb.userId}}"
                                                        [checked]="compMemb.checked === true ? true : false"
                                                        aria-label="..."
                                                        (change)="selectCompanyMembers($event,compMemb.userId,j, compy, i)">
                                                    <label class="mb-0 get-pdf-check bg-white border-0 c-pointer"
                                                        for="radioNoLabel{{compMemb.userId}}">
                                                        <em class="far fa-circle square-uncheck grey-i fs12"></em>
                                                        <em class="fas fa-circle square-checked blue-a fs12"></em>
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <!-- / companys -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt50 px-3 mb-3 mx-0">
                <div class="col-5 pr-1">
                    <button *ngIf="!addMembers" type="button"
                        class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                        (click)="Create(0);ResetForm(albumForm);">Cancel</button>
                    <button *ngIf="addMembers" type="button"
                        class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                        (click)="Create(currentlyChecked);">Back</button>

                </div>
                <div class="col-7 pl-1">
                    <button type="button" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload"
                        [disabled]="!albumForm.form.valid || submitted" *ngIf="currentlyChecked === 0 || addMembers"
                        (click)="albumForm.form.valid && onSubmit(albumForm)">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Submit</button>
                    <button type="button" class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600"
                        [disabled]="!albumForm.form.valid || submitted" (click)="albumForm.form.valid && showMembers()"
                        *ngIf="currentlyChecked === 1 && !addMembers">Next</button>
                </div>
            </div>

        </form>

    </div>

</div>

<ng-template #exampleModal>
    <div class="modal-lg signup-modal">
        <div class="modal-content border-0">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title fs20 fw-600 pull-left grey-g">Confirm</h4>

                <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="modalRef.hide()"></em>
            </div>
            <div class="modal-body">
                <p class="fs14 fw600 grey-m py-3 text-center">Are you sure you want to delete album?</p>
                <div class="modal-btn text-right">
                    <button type="button" class="btn btn-blue pt7 pb7 fw-600 fs13 px-4  mr-2 buttonload"
                        [disabled]="submitted" (click)="yesMethod()">
                        <em *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Yes</button>
                    <button type="button" class="btn btn-red-h pt7 px-4 pb7 fs13 fw-600" [disabled]="submitted"
                        (click)="modalRef.hide()">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- invite side bar -->

<div id="overlay" [ngClass]="{'d-block': showInviteBar}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : showInviteBar}">
    <!-- for invite -->
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Invite</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="ViewInvite()"></em>
    </div>
    <div class="scroll-content">
        <form class="mt-3" name="form" [formGroup]='inviteForm' novalidate>
            <label class="fs12 grey-j ls-3">Name*</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="firstName" tabindex=1 class="form-control pt22 pb22 fw-600"
                            placeholder="First Name"
                            (keydown.space)="inviteForm.get('firstName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                            *ngIf=" (inviteForm.get('firstName').errors && Invitesubmitted) || (inviteForm.get('firstName').errors?.required && submitted)">
                            Please enter first name
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded">
                        <input type="text" formControlName="lastName" tabindex=2 class="form-control pt22 pb22 fw-600"
                            placeholder="Last Name"
                            (keydown.space)="inviteForm.get('lastName').value.trim().length > 0 ? '': $event.preventDefault();">
                        <div class="fs12 text-danger"
                            *ngIf=" (inviteForm.get('lastName').errors && Invitesubmitted) || (inviteForm.get('lastName').errors?.required && submitted)">
                            Please enter last name
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">E-Mail*</label>
                <input type="text" formControlName="email" tabindex=3 class="form-control pt22 pb22 fw-600"
                    placeholder="Enter E-mail" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    (keydown.space)="inviteForm.get('email').value.trim().length > 0 ? '': $event.preventDefault();">
                <div class="fs12 text-danger"
                    *ngIf=" (inviteForm.get('email').errors && Invitesubmitted) || (inviteForm.get('email').errors?.required && submitted)">
                    Please enter email
                </div>
                <div *ngIf="(inviteForm.get('email').errors && Invitesubmitted) || (inviteForm.get('email').errors?.pattern && submitted)"
                    class="fs12 text-danger">
                    Enter a valid email.
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Role*</label>
                <select class="custom-select input-group-text" name="role" formControlName="roleType" tabindex=4>
                    <option value="" disabled>select</option>
                    <option *ngFor="let user of userRoleList" value={{user.id}}>{{user.roleName}}</option>
                </select>
                <div class="fs12 text-danger"
                    *ngIf=" (inviteForm.get('roleType').errors && Invitesubmitted) || (inviteForm.get('roleType').errors?.required && submitted)">
                    Please choose role type
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j ls-3">Workspace Name</label>
                <input type="text" formControlName="companyName" tabindex=5 class="form-control pt22 pb22 fw-600"
                    placeholder="Enter Workspace Name"
                    (keydown.space)="inviteForm.get('companyName').value.trim().length > 0 ? '': $event.preventDefault();">
            </div>

            <div class="row mt50 mb-3 px-3">
                <div class="col-4 pr-1">
                    <button tabindex="6" class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600"
                        (click)="ViewInvite()" type="button">Cancel</button>
                </div>
                <div class="col-8 pl-1">
                    <button [disabled]="!inviteForm.valid" tabindex="7"
                        class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600" type="button"
                        (click)="inviteMethod(inviteForm)">
                        Invite to album</button>
                </div>
            </div>
        </form>
    </div>

</div>

<div id="overlay" [ngClass]="{'d-block': pdfModalSelect}"></div>
<div #sidenav class="right-sidebar bg-white" [ngClass]="{'show-side-nav' : pdfModalSelect}">
    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3">Get PDF</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="cancelPdf()"></em>
    </div>
    <form>
        <div class="get-pdf mb-3 mt-4">
            <label class="fs12 grey-j ls-3 fw-500">Should this album be Public or private</label>
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Photo summary'"
                class="form-check-input d-none" type="radio" id="inlineRadio3" name="otpType" value="Photo summary">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio3">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Photo summary include all Photos, tags
                        and comments.</h2>
                </div>
            </label>
        </div>

        <div class="get-pdf my-3">
            <input [(ngModel)]="selectedPDFType" (click)="selectedPDFType = 'Punch List'"
                class="form-check-input d-none" type="radio" id="inlineRadio4" name="otpType" value="Punch List">
            <label class="form-check-label d-flex align-items-center p-3 br8 get-pdf-check c-pointer"
                for="inlineRadio4">
                <div class="mr-2  fs12">
                    <em class="far fa-circle square-uncheck grey-i"></em>
                    <em class="fas fa-circle square-checked blue-a"></em>
                </div>
                <div>
                    <h3 class="fs16 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list</h3>
                    <h2 class="fs10 ged-pdf-heading font-weight-bold mb-0 ls-c">Punch list includes all photos with tags
                        assigned as tasks.</h2>
                </div>
            </label>
        </div>
        <h2 class="fs10 mb-0 ls-c ml14">Both formats include a title sheet and a tag summary sheet.</h2>

    </form>

    <div class="row mt50 px-3 ">
        <div class="col-4 pr-1">
            <button [disabled]="pdfSubmitted" class="btn btn-grey-f text-black br25 pt7 pb7 btn-block fs13 fw-500"
                type="button" (click)="cancelPdf()">Cancel</button>
        </div>

        <div class="col-8 pl-1">
            <button [disabled]="pdfSubmitted" class="btn btn-blue br25 pt7 pb7 fs13 btn-block fs14 fw-500 buttonload"
                type="button" (click)="generatePDF()">
                <em *ngIf="pdfSubmitted" class="fa fa-spinner fa-spin"></em>Download</button>
        </div>
    </div>

</div>
