import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AlbumserviceService } from '../services/albumservice.service';
import { SupportService } from '../services/support.service';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../services';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-todo-list',
    templateUrl: './todo-list.component.html',
    providers: [DatePipe],
})
export class TodoListComponent implements OnInit {

    public modalRef: BsModalRef;

    public modalRef1: BsModalRef;

    public viewMode = 'pending';

    public viewMode1 = 'tagFilter';

    public viewMode2 = 'Comments';

    public itemsPerSlide = 10;

    public tagShow: boolean;

    public getNext = true;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public usertype = '';

    public order = 'desc';

    public assignto = '';

    public DateText = 'Most Recent';

    public date: any;

    public customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['<i class="fas fa-chevron-left text-white mr1 fs11"></i>', '<i class="fas fa-chevron-right text-white fs11 ml1"></i>'],
        responsive: {
            0: {
                items: 2,
            },
            320: {
                items: 3,
            },
            400: {
                items: 4,
            },
            740: {
                items: 7,
            },
            940: {
                items: 9,
            },
        },
        nav: true,
    }

    public completedList = [];

    public pendingList = [];

    public loadingPage: boolean = false;

    public constructor(private datePipe: DatePipe, private support: SupportService, private albumServices: AlbumserviceService,
        private modalService: BsModalService, private activeRoute: ActivatedRoute, private api: ApiService) {
        this.usertype = sessionStorage.getItem('usertype');
        this.activeRoute.queryParams.subscribe((params): any => {
            if (params && params.type) {
                this.viewMode = params.type;
            }
        });
    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.getListOfPendingCompletedList();
    }

    public openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'custom-modal',
        });
    }

    public openModal1(template: TemplateRef<any>): void {
        this.modalRef1 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'custom-modal',
        });
    }

    public showTags(): void {
        this.tagShow = !this.tagShow;
    }

    public showNext(): void {
        this.getNext = !this.getNext;
    }

    public getListOfPendingCompletedList(): void {        
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            projectId: sessionStorage.getItem('projectId'),
            assignedTo: this.assignto,
            sortby: 'created',
            orderby: this.order,
        };        
        this.loadingPage = true;
        this.albumServices.getCompletedListApi(payload).subscribe(
            (res): any => {
                if (res && res.entity) {
                    this.pendingList = (res.entity.pendingToDoList === null) ? [] : res.entity.pendingToDoList;
                    this.completedList = res.entity.completedToDoList;
                    this.loadingPage = false;
                } else {
                    this.pendingList = [];
                    this.completedList = [];
                    this.loadingPage = false;
                }
            },
        );
    }

    public getTodoListSort(): void {
        this.order = this.order === 'desc' ? 'asc' : 'desc';
        this.DateText = this.order === 'desc' ? 'Most Recent' : 'Oldest';
        this.getListOfPendingCompletedList();
    }

    public getTodoListViewMode(type): void {
        this.assignto = type;
        this.getListOfPendingCompletedList();
    }

    public getDateValue(value): any {
        if (value) {
            const datobj = value.split(' ');
            return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
        }
        return '';
    }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }
}
