import { Component, OnInit } from '@angular/core';
import { SupportService } from '../services/support.service';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  public showProject: any = true;

    public companyDetails: boolean = false;

    public unsub: any;



    public constructor(
        private support: SupportService,
    ) { }


    public ngOnInit(): void {
        this.unsub = this.support.showProject.subscribe((res): any => {
            this.showProject = res;
        });
    }
}