import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
    HashLocationStrategy,
    LocationStrategy,
} from '@angular/common';

// Plugins
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { MomentModule } from 'ngx-moment';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgXtruncateModule } from 'ngx-truncate';
import { AgmDirectionModule } from 'agm-direction';
// import { NgxDraggableDomModule } from 'ngx-draggable-dom';


import { NgxPaginationModule } from 'ngx-pagination';
import { ColorPickerModule } from 'ngx-color-picker';
import { UiSwitchModule } from 'ngx-ui-switch';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SearchFieldComponent } from './common-components/search-field/search-field.component';

// Services
import {
    ApiService,
    LoginService,
    ChangePasswordService,
    UserService,
    SocialAuthService,
    GeolocationService
} from './services/index';

// Page Components
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidemenuComponent } from './layout/sidemenu/sidemenu.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { SocialLoginComponent } from './auth/social-login/social-login.component';
import { SubHeaderComponent } from './layout/sub-header/sub-header.component';
import { SubFooterComponent } from './layout/sub-footer/sub-footer.component';
import { SignupComponent } from './auth/signup/signup.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { SidenavRightComponent } from './layout/sidenav-right/sidenav-right.component';
import { SharedComponent } from './shared/shared.component';
import { LayoutProjectComponent } from './layout-project/layout-project.component';
import { AddPhotoComponent } from './add-photo/add-photo.component';
import { NotificationComponent } from './notification/notification.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { TagLibraryComponent } from './tag-library/tag-library.component';
import { PeopleComponent } from './people/people.component';
import { PeopleDetailsComponent } from './people-details/people-details.component';
import { SettingsComponent } from './settings/settings.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AlbumsComponent } from './albums/albums.component';
import { AlbumSubpageComponent } from './album-subpage/album-subpage.component';

import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { LayoutWorkspaceComponent } from './layout-workspace/layout-workspace.component';

import { PhotoTagComponent } from './photo-tag/photo-tag.component';
import { WorkspaceSettingsComponent } from './workspace-settings/workspace-settings.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { HelpComponent } from './help/help.component';
import { ViewPictureComponent } from './view-picture/view-picture.component';
import { SearchuserPipe } from './custom-pipes/searchuser/searchuser.pipe';
import { ShareAlbumSidemenuComponent } from './common-components/share-album-sidemenu/share-album-sidemenu.component';


import { BillingHistoryFilterComponent } from './common-components/billing-history-filter/billing-history-filter.component';
import { FeedSideFilterComponent } from './feed-side-filter/feed-side-filter.component';
import { PendingInvitesComponent } from './pending-invites/pending-invites.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { YourInvitesComponent } from './your-invites/your-invites.component';
import { YourInvitesSidemenuComponent } from './your-invites-sidemenu/your-invites-sidemenu.component';
import {AutosizeModule} from 'ngx-autosize';
import { CompanyEditSidebarComponent } from './common-components/company-edit-sidebar/company-edit-sidebar.component';
import { SendotpSidenavComponent } from './layout/sendotp-sidenav/sendotp-sidenav.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { WorkspaceProjectComponent } from './layout/workspace-project/workspace-project.component';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyPeopleComponent } from './company-profile/company-people/company-people.component';
import { CompanyWorkspaceComponent } from './company-profile/company-workspace/company-workspace.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        AppLayoutComponent,
        HeaderComponent,
        SidemenuComponent,
        LoginComponent,
        ForgotPasswordComponent,
        DashboardComponent,
        SocialLoginComponent,

        SubHeaderComponent,

        SubFooterComponent,

        SignupComponent,

        OtpVerifyComponent,

        SetPasswordComponent,

        CreateWorkspaceComponent,

        SidenavRightComponent,
        SharedComponent,
        LayoutProjectComponent,

        AddPhotoComponent,

        NotificationComponent,
        TodoListComponent,

        TagLibraryComponent,

        PeopleComponent,

        PeopleDetailsComponent,

        SettingsComponent,

        ResetPasswordComponent,

        AlbumsComponent,

        AlbumSubpageComponent,

        ProjectDashboardComponent,
        DashboardChartComponent,
        LayoutWorkspaceComponent,
        PhotoTagComponent,
        WorkspaceSettingsComponent,
        CompanyManagementComponent,
        HelpComponent,
        ViewPictureComponent,
        SearchuserPipe,
        ShareAlbumSidemenuComponent,
        BillingHistoryFilterComponent,
        FeedSideFilterComponent,
        PendingInvitesComponent,
        YourInvitesComponent,
        YourInvitesSidemenuComponent,
        SearchFieldComponent,
        CompanyEditSidebarComponent,
        SendotpSidenavComponent,
        IntegrationsComponent,
        WorkspaceProjectComponent,
        CompanyProfileComponent,
        CompanyPeopleComponent,
        CompanyWorkspaceComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ImageCropperModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        BrowserAnimationsModule,
        MomentModule,
        CollapseModule.forRoot(),
        CarouselModule,
        // AIzaSyDcFUqEsIGoBULKu72DAAzmhJfDqMIZF24
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBK7QzHx0SiEg0b2aOdu_6JfyFbNx7DvQE',
            libraries: ['places']
        }),
        AgmDirectionModule,
        ProgressbarModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot(),
        NgxMaskModule.forRoot(),
        CarouselModule,
        NgxPaginationModule,
        NgOtpInputModule,
        UiSwitchModule,
        HttpClientModule,
        ColorPickerModule,
        NgxIntlTelInputModule,
        // Firebase
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule,
        NgXtruncateModule,
        NgxFileDropModule,
        InfiniteScrollModule,
        TabsModule.forRoot(),
        LoadingBarModule,
        TypeaheadModule.forRoot(),
        AutosizeModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        ApiService,
        LoginService,
        ChangePasswordService,
        UserService,
        SocialAuthService,
        GeolocationService,
        GoogleMapsAPIWrapper
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
