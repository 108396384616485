import { Component } from '@angular/core';

@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent {
    public toggleClass = true;

    public toggleProject = true;

    public layoutPro = '';

    public sideMenuChange(val): void {
        this.toggleClass = val;
    }

    public layoutProject(value): any {
        this.layoutPro = value;
    }

    public ProjectMenuChange(val): void{
        this.toggleProject = val;
    }

    public activeComponent(value): any {
        if (value && value.showProject === false) {
            this.layoutPro = '';
        } else if (sessionStorage.getItem('projectId')) {
            this.layoutPro = sessionStorage.getItem('projectId');
        }
    }
}
