<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15">
            <div>
                <div class="content-card tag-library shadow-none border-grey">
                    <div class="card-header pb-0 bg-white  border-0" >
                        <div class="d-flex align-items-center mt20">
                            <h1 class="fw-600 fs20 ls-9 grey-g mb-0">Notifications</h1>
                            <div class="flex-grow-1 text-right mr-3" *ngIf="notificationUnReadList.length > 0">
                                <button class="btn btn-white blue-a br25 py-2 px-3 fs13 fw-600" (click)="readNotificationMethod('',0)">
                                    Mark all as read
                                </button>
                            </div>
                            <div class="flex-grow-1 text-right mr-3" *ngIf="notificationUnReadList.length > 0">
                                <button class="btn btn-white red-d br25 py-2 px-3 fs13 fw-600"
                                    (click)="clearNotification()">
                                    <img src="./assets/images/cross.svg" alt="cross" class="w10 mb1 mr-1">Clear
                                    All</button>
                            </div>
                        </div>

                        <form class="mt30 mb10 px-2">
                            <div class="input-group input-todo">
                                <div class="input-group-prepend">
                                    <span class="input-group-text pr-1" id="basic-addon3"><img
                                            src="./assets/images/search-todo.svg" alt="search" class="w13"></span>
                                </div>
                                <input [(ngModel)]="search" [ngModelOptions]="{standalone: true}" type="text"
                                (ngModelChange)="notificationSearchDelay.next($event)"
                                 class="form-control px-1 fw-600"
                                    placeholder="Search Notification" aria-describedby="basic-addon3">
                                <div class="input-group-append" *ngIf="search !== ''">
                                    <span class="input-group-text border-left-0 p-1" (click)="search = '';searchNotification()">
                                        <span class="lnr lnr-cross fs16"></span>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body pl31 pr31">
                        <div class="notification-scroll1 scroll-content" infiniteScroll [infiniteScrollDistance]="3"
                            [infiniteScrollThrottle]="50" (scrolled)="onScroll1()" [infiniteScrollContainer]="selector2"
                            [fromRoot]="true" [scrollWindow]="false">

                            <div *ngFor="let unReadNotification of notificationUnReadList; let unri = index"
                                class="d-flex border-bottom-a p-3" [ngClass]="{'blue-href': !unReadNotification.isread}">
                                <div class="c-pointer">
                                    <div class="profile-with-status ">
                                        <img *ngIf="!unReadNotification.senderImage" class="notify-primary-img"
                                            src="./assets/images/empty-img.png" alt="notify-people">

                                        <img *ngIf="unReadNotification.senderImage" class="notify-primary-img"
                                            src={{cloudFrontURL}}{{unReadNotification.senderImage}} alt="user-img">
                                    </div>
                                </div>
                                <div class="ml-3 flex-grow-1" (click)="navigate(unReadNotification, unri)">
                                    <p class="mb-0 fs16 fw-600 grey-m">{{unReadNotification.description}}</p>
                                    <p *ngIf="datePipe.transform(unReadNotification.createdOn, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')" class="fs12 fw-600 gre-m mb-0">{{getDateValue(unReadNotification.createdOn) | amTimeAgo }}</p>
                                    <p *ngIf="datePipe.transform(unReadNotification.createdOn, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')" class="fs12 fw-600 gre-m mb-0">{{getDateValue(unReadNotification.createdOn) | amDateFormat: 'MM/DD/YYYY hh:mm A' }}</p>
                                </div>
                                <div *ngIf="unReadNotification.notificationType.notid !== 23 && unReadNotification.notificationType.notid !== 40" class="col-sm-2 col-6 d-flex align-items-center justify-content-end c-pointer" (click)="navigate(unReadNotification, unri)">
                                    <em class="lnr lnr-chevron-right grey-l fw-600 fs11"></em>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="!submitted && notificationUnReadList.length === 0">
                <div class="card content-card pl18 pr18 py-3 border-grey mt5">
                    <div class="card-body">
                        <div class="row mb70">
                            <div class="col-lg-8 col-sm-8 text-center mx-auto">
                                <img src="./assets/images/no-notification.svg" alt="no-notification"
                                    class="notification-img mt60 mb40">
                                <h2 class="text-black font-weight-bold fs22 mb-3">No Notifications!</h2>
                                <p class="fs12 grey-g fw-600">You don't have any Notifications right now</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
