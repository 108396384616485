import {
    Component, OnInit, ElementRef, ViewChild, TemplateRef, HostListener, ChangeDetectorRef, Renderer2,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlbumserviceService } from '../services/albumservice.service';
import { ApiService } from '../services';
import 'jquery-ui-dist/jquery-ui';
import { TaglibraryserviceService } from '../services/taglibraryservice.service';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { Subject } from 'rxjs';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as AWS from 'aws-sdk';
import { SupportService } from '../services/support.service';


const $ = require('jquery');

@Component({
    selector: 'app-photo-tag',
    templateUrl: './photo-tag.component.html',
    providers: [DatePipe],
})
export class PhotoTagComponent implements OnInit {
    // Image zoomer
    // public zoom=2;

    // public lenSize=40;

    // public imgWidth;

    // public imgHeigth;

    // public divZoomed: ElementRef;

    // public posX: number=0;

    // public posY: number=0;

    // public cx: number=1;

    // public cy: number=1;

    // public yet: boolean=false;

    // public factorX: number;

    // public factorY: number;

    // private mouseMovement = new Subject();

    // @ViewChild('img', { static:false,read:ElementRef}) img;

    // @ViewChild('len',{ static:false,read:ElementRef}) lens;

    // Image zoomer
    @ViewChild('staticTabs', { static: false }) public staticTabs: TabsetComponent;

    @ViewChild('staticModal') public staticModal;

    @ViewChild('tagForm') public tagForm;

    @ViewChild('img') public imgTag;

    @ViewChild('photodeleteModal') public photodeleteModal: TemplateRef<any>;

    @ViewChild(ModalDirective, { static: false }) public modal: ModalDirective;

    @ViewChild('scrollMe') public myScrollContainer: ElementRef;

    public modalRef: BsModalRef;

    public tagShow: boolean;

    public AssignTo = false;

    public viewMode1 = 'tagFilter';

    public viewMode2 = 'Comments';

    public counter = 1;

    public showseconddesign = false;

    public defaultcolor = '#666666';

    public currentCommentsList = [];

    public completedTask = false;

    public customOptions: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['<em class="fas fa-chevron-left text-white mr1 fs11"></em>', '<em class="fas fa-chevron-right text-white fs11 ml1"></em>'],
        responsive: {
            0: {
                items: 2,
            },
            320: {
                items: 3,
            },
            400: {
                items: 4,
            },
            740: {
                items: 7,
            },
            940: {
                items: 9,
            },
        },
        nav: true,
    };

    public getNext = true;

    public plotArr = [];

    public currentPosition: any = {};

    public photosList = [];

    public orderBy = 'desc';

    public sortType = 'day';

    public queryData: any;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public currentImage: any = {};

    public note: string = '';

    public tagFormData = {
        userId: localStorage.getItem('userId'),
        workSpaceName: sessionStorage.getItem('workspacename'),
        top: null,
        left: null,
        tagTypeId: null,
        albumId: null,
        photoId: null,
        label: null,
        description: {
            text: '',
            attachment: {},
        },
        membershipIds: [],
    };

    public Tags = [];

    public peopleLists = [];

    public editData = false;

    public completeTaskSection = false;

    public toDoPendingList = [];

    public toDoCompletedList = [];

    public PhotoInfoScreen = false;

    public config = {
        ignoreBackdropClick: true,
    };

    public submitted = false;

    public origin = { lat: 0, lng: 0 };

    public destination = { lat: 0, lng: 0 };

    public renderOptions = {
        suppressMarkers: true,
    };

    public markerOptions = {
        origin: {
            icon: './assets/images/blue-dot.png',
            draggable: false,
        },
        destination: {
            icon: './assets/images/red_dot.svg',
            draggable: false,
        },
    };

    public Invite = false;

    public datePickerConfig: Partial<BsDatepickerConfig>;

    public assignedUsersList = [];

    public todoListPage = false;

    public shareForm: FormGroup;

    public Invitesubmitted = false;

    public sidemenuPhotoTagId = null;

    public selectedTagName = '';

    public peopleListsGroup = [];

    public usernamesearch = '';

    public attachmentsList = [];

    public selectedImage = [];

    public totalFiles = [];

    public i = 0;

    public uploadPercentage = 0;

    public files = [];


    public width = 300;

    public x = 100;

    public oldX = 0;

    public grabber = false;

    public compltedTagPage = false;

    public completedUsersList = [];

    public assignusersearch = null;

    public tagsearch = null;

    public selectAttachmentList = [];

    public list = [];

    public toggleSliders = false;

    public userId = '';

    public searchField: string = '';

    public searchUserField: string = '';

    public restrictCompletedTag = false;

    public filteredPlotArr: any[] = [];

    public modalBackDrop: any = {};

    public date: any;

    public photoExtentions = ['jpg', 'png', 'jpeg'];

    public videoExtentions = ['mp4'];

    mediaFormat = ''

    usertype = ''

    usertypesub: any;

    public constructor(
        private el: ElementRef,
        private activeRoute: ActivatedRoute,
        private albumService: AlbumserviceService,
        private api: ApiService,
        private tagLib: TaglibraryserviceService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private router: Router,
        private modalService: BsModalService,
        private shareBuilder: FormBuilder,
        private loadingBar: LoadingBarService,
        private changedet: ChangeDetectorRef,
        private render: Renderer2,
        private support: SupportService,
    ) {
        this.createBuilderForm();
    }


    @HostListener('document:mousemove', ['$event'])

    // public mouseMove(event: any): void {
    //     const result = this.moveLens(event);
    //     this.render.setStyle(this.divZoomed, 'background-position', result);
    // }

    public onMouseMove(event: MouseEvent): any {
        if (!this.grabber) {
            return;
        }
        const currentWidth = event.clientX - this.oldX;

        this.resizer(currentWidth);

        this.oldX = event.clientX;
    }

    @HostListener('document:mouseup', ['$event'])
    public onMouseUp(event: MouseEvent): any {
        this.grabber = false;
    }

    public resizer(offsetX: number): any {
        this.width += offsetX;
    }

    @HostListener('document:mousedown', ['$event'])
    public onMouseDown(event: MouseEvent): any {
        this.grabber = true;
        this.oldX = event.clientX;
    }



    public createBuilderForm(): any {
        this.shareForm = this.shareBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
            expires: ['', Validators.required],
        });
    }

    public ngAfterViewInit(): void {
        this.RegisterMouseDown();
        this.RegisterDrag();
        this.dblClickEdit();
    }

    public ngOnInit(): void {
        this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.userId = localStorage.getItem('userId');
        this.callWhilePageInit();
    }

    public callWhilePageInit(): void {

        this.usertypesub = this.support.usertype.subscribe((res): any => {
            if (res) {
              if (typeof res === 'number') {
                this.usertype = res.toString();
              } else {
                this.usertype = res;
              }
            } else {
              this.usertype = sessionStorage.getItem('usertype');
            }
        });

        this.activeRoute.queryParams.subscribe((params): any => {
            this.queryData = params;
        });

        this.compltedTagPage = (Number(this.queryData.type) === 1);
        this.getPhotoLists();
        this.getTags();
        this.getPlotingTags();
        this.getAllPeopleList();

        if (this.queryData.todo === true || this.queryData.todo === 'true') {
            this.getPlotingTags();
            this.getNext = false;
            this.AssignTo = false;
            this.viewMode2 = 'Assign';
        }
        if (this.queryData.comment === true || this.queryData.comment === 'true') {
            this.getPlotingTags();
            this.getNext = false;
            this.AssignTo = false;
            this.viewMode2 = 'Comments';
        }

        if (this.queryData.todoId) {
            this.todoListPage = true;
            // this.getbytodoid(this.queryData.todoId, this.queryData.type);
        }
    }

    public showTags(): void {
        this.tagShow = !this.tagShow;
    }

    public goBack(): any {
        if (this.queryData.prevPage === 'project/feed') {
            this.router.navigate([this.queryData.prevPage], { queryParams: this.api.getSessionData() });
        } else if (this.queryData.prevPage === '/company/notification') {
            
            sessionStorage.removeItem('projectId');
            this.support.projectChange.next('empty');
            this.router.navigate([this.queryData.prevPage], { queryParams: this.api.getSessionData() });
        }  else if (this.queryData.prevPage === '/project/notification') {            
            // sessionStorage.removeItem('projectId');
            // this.support.projectChange.next('empty');
            this.router.navigate([this.queryData.prevPage], { queryParams: this.api.getSessionData() });
        } else {
            this.router.navigate([this.queryData.prevPage], { queryParams: { ...this.api.getSessionData(), ...{ albumId: this.queryData.albumId } } });
        }
    }

    onCarouselInitialized( ) {
        this.changedet.detectChanges();
    }

    public showNext(tagList): void {
        this.getNext = false;
        if (tagList) {
            this.sidemenuPhotoTagId = tagList;
            this.completedTask = false;
            this.completeTaskSection = false;
            this.getEditDatas(tagList.id);
            this.selectedTagName = tagList?.tagtype?.tag?.tagName;
        }
    }

    public showAssign(): void {
        this.AssignTo = !this.AssignTo;
    }

    public RegisterMouseDown(): void {
        this.el.nativeElement.removeEventListener('mousedown', this.OnMouseDown.bind(this));
        this.el.nativeElement.addEventListener('mousedown', this.OnMouseDown.bind(this));
    }

    public dblClickEdit(): void {
        this.el.nativeElement.removeEventListener('dblclick', this.OnMouseDoubleDown.bind(this));
        this.el.nativeElement.addEventListener('dblclick', this.OnMouseDoubleDown.bind(this));
    }

    public RegisterDrag(): void {
        this.el.nativeElement.addEventListener('mouseup', this.OnMouseDrag.bind(this));
    }

    public OnMouseDown(event: any): void {
        if (this.usertype && this.usertype === '5') {
            return;
        }
        const isTagPresent = event.target.getAttribute('data-popover');
        if (isTagPresent) {
            this.openInSidebar(event);
        }

        if (event.target != null && event.target.id === 'myImgId') {
            this.modalBackDrop = { backdrop: 'static' };
            this.editData = false;
            this.clearFormData();
            const { x, y } = this.xyPositionLogic(event);
            const position = {
                label: this.counter,
                color: this.defaultcolor,
                left: x,
                top: y,
            };
            this.tagFormData.left = position.left;
            this.tagFormData.top = position.top;
            this.tagFormData.label = position.label;
            this.plotArr.push(position);
            this.openPopover(position);
            setTimeout((): any => {
                this.modalBackDrop = { };
            }, 500);
            const append = $(`<div class="tag" id='tag-${this.counter}'><div class="box" style="background-color:${position.color}" data-popover="${this.counter}"></div></div>`).draggable({ containment: 'parent' })
                .css({
                    position: 'absolute',
                    top: `${(Number(position.top) * 100) - 2.5}%`,
                    left: `${(Number(position.left) * 100) - 2.5}%`,
                });
            $('#imageHolder').append(append);
            this.counter += 1;
        }
    }

    public OnMouseDoubleDown(event): void {
        if (event.target != null && (event.target.className === 'box' || event.target.className === 'box blink')) {
            const tag = this.plotArr.find((x): any => x.label == event.target.dataset.popover);
            this.currentPosition = tag;
            this.clearVariableDatas();
            this.getEditDatas(tag.id);
            this.openPopover(tag);
            this.getAllPeopleList();
        }
    }

    public openPopover(position): void {
        this.width = 300;
        this.getNext = true;
        this.selectTab(0);
        this.sidemenuPhotoTagId = null;
        this.selectedTagName = null;
        this.currentPosition = position;
        this.staticModal.show();
        this.tagActivateDeactivateInitial();
    }

    public OnMouseDrag(event): void {
        const tag = this.plotArr.find((x): any => x.label == event.target.dataset.popover);
        if (tag && tag !== -1) {
            const { x, y } = this.xyPositionLogic(event);
            const temp = {
                x,
                y,
                photoTagId: tag.id,
            };
            this.editTagPosition(temp);
        }
    }

    public calcContrastColor(color): any {
        if (color) {
            const factor = 15728639;
            const hexInt = parseInt(color.replaceAll('#', ''), 16);
            if (hexInt > factor) {
                return '#000000';
            }
            return '#ffffff';
        }
    }

    processColor(color: string): string {
        if (!color) {
          return '#ffffff00'; // Use your default color here
        }
      
        return color.replace(/##/g, '#');
    }

    public plotOptions(): void {
        this.removeAllElemetsFromDom();
        this.plotArr.forEach((ray): void => {
            const textcolor = this.calcContrastColor(ray.color);
            const currentTag = ray.active ? '' : 'd-none';
            const append = $(`
                <div class='tag ${currentTag}' id='tag-${ray.label}'>
                  <div class='box' id='${ray.id}' style='background-color:${this.processColor(ray.color)};color:${textcolor};border-color:${textcolor};' data-popover='${ray.label}'>
                    ${ray.label}
                  </div>
                </div>`)
                .draggable({ containment: 'parent' }).css({ position: 'absolute', top: `${ray.top - 2.5}%`, left: `${ray.left - 2.5}%` });
            $('#imageHolder').append(append);
        });
        if (this.queryData.photoTagId) {
            const photoTagId = document.getElementById(this.queryData.photoTagId);
            photoTagId.classList.add('blink');
        }
        // found larget in array
        this.counter = Math.max.apply(Math, this.plotArr.map((o) => o.label)) + 1;
    }

    public removeAllElemetsFromDom(): void {
        $('.tag').remove();
    }

    public deletePhotoTag(): void {
        const currentTag = this.plotArr.find((x): any => x.label === this.currentPosition.label);
        if (this.plotArr) {
            this.albumService.deletePlotTagApi(currentTag?.id).subscribe(
                (res): any => {
                    if (res && res.status.status === 200) {
                        this.toasterMethod('success', res.status.msg);
                    }
                    this.staticModal.hide();
                    this.getPlotingTags();
                },
                (err): any => {
                    this.api.handleError(err);
                },
            );
        }
    }


    // integration part

    public getPhotoLists(): void {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.queryData.albumId,
            pagenumber: 0,
            pagerecord: 0,
            sortby: 'created',
            orderby: this.orderBy,
            sortviewby: '',
        };
        console.log('data ' , data);
        

        this.getPhotosList(data);
    }

    public getPrevImage(): void {
        const findCurrentIndex = this.photosList[0].photoInfo.findIndex((x): any => x.photoId === this.currentImage.photoId);
        const prevImage = this.photosList[0].photoInfo[findCurrentIndex - 1];
        if (prevImage) {
            this.getPhotoBanner(prevImage.photoId);
        } else {
            const lastIndex = this.photosList[0].photoInfo[this.photosList[0].photoInfo.length - 1];
            this.getPhotoBanner(lastIndex.photoId);
        }
    }

    public getNextImage(): void {
        if (this.photosList && this.photosList.length) {
            const findCurrentIndex = this.photosList[0].photoInfo.findIndex((x): any => x.photoId === this.currentImage.photoId);
            const nextImage = this.photosList[0].photoInfo[findCurrentIndex + 1];
            if (nextImage) {
                this.getPhotoBanner(nextImage.photoId);
            } else {
                const firstIndex = this.photosList[0].photoInfo[0];
                this.getPhotoBanner(firstIndex.photoId);
            }
        }
    }

    public getPhotosList(data): any {
        this.albumService.getPhotoByAlbumId(data).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.photosList = res.entity.listview;
                this.currentImage = this.photosList[0].photoInfo.find((x): any => { 
                    if (x.photoId === this.queryData.photoId) {
                        const newObject = { ...this.queryData, mediaType: x.media.extension == 'MP4' ? 'Video' : 'Photo' };
                        this.mediaFormat = x.media.extension == 'MP4' ? 'Video' : 'Photo'
                        this.queryData = newObject
                    } 
                    return x.photoId === this.queryData.photoId
                });
                if (!this.currentImage) {
                    this.toastr.clear();
                    setTimeout(() => {
                        this.toastr.error('This Photo is no longer available');
                    }, 100);
                    window.history.back();
                }
            } else {
                this.toastr.clear();
                setTimeout(() => {
                    this.toastr.error('This Photo is no longer available');
                }, 100);
                window.history.back();
                this.photosList = [];
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public getPlotingTags(): void {
        this.submitted = true;
        this.albumService.getPlotTagsApi(this.queryData.photoId).subscribe(
            (res): any => {
                this.submitted = false;
                if (res) {
                    this.plotArr = [];
                    if (res.entity) {
                        res.entity.forEach((ele): void => {
                            const plottemp = {
                                active: ele.active,
                                color: `#${ele.tagtype ? ele.tagtype.tag?.color : '666666'}`,
                                label: Number(ele.label),
                                left: Number(ele.leftvalue) * 100,
                                top: Number(ele.topvalue) * 100,
                                tagtype: ele.tagtype,
                                id: ele.id,
                                count: ele.commentCount,
                            };
                            this.plotArr.push(plottemp);
                        });
                        this.filteredPlotArr = [];
                        const filteringPlot = [...this.plotArr];

                        filteringPlot.forEach((x): void => {
                            if (this.filteredPlotArr.length === 0) {
                                this.filteredPlotArr.push(x);
                            } else {
                                const inarry = this.filteredPlotArr.findIndex((y): any => y.color === x.color);
                                if (inarry === -1) {
                                    this.filteredPlotArr.push(x);
                                }
                            }
                        });
                        this.openCompletedTagSidemenu();
                        this.plotOptions();
                    } else {
                        this.removeAllElemetsFromDom();
                        this.filteredPlotArr = [];
                        this.counter = (this.plotArr.length > 0) ? Math.max.apply(Math, this.plotArr.map((o) => o.label)) + 1 : 1;
                    }
                }
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    // added changedtoggle function here
    public changeTagToggle(photoIds: any, event): void {
        // eslint-disable-next-line no-param-reassign
        photoIds.active = event;
        this.tagActivateDeactivate(photoIds, event);
        this.albumService.updateTagPhotos({
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            tagTypeId: photoIds.id,
        }).subscribe((): any => {
            this.getPlotingTags();
        });
    }
    // end

    public tagActivateDeactivate(photoIds: any, event: boolean): void {
        if (photoIds && photoIds.tagtype && photoIds.tagtype.id) {
            const findInside = this.Tags.find((x): any => x.id === photoIds.tagtype.id);
            if (findInside) {
                findInside.active = event;
            }
        }
    }

    public tagActivateDeactivateInitial(): void {
        this.filteredPlotArr.forEach((element): void => {
            this.tagActivateDeactivate(element, element.active);
        });
    }

    public getTags(): void {
        this.tagLib.gettags('', 'asc').subscribe(
            (res): any => {
                if (res && res.entity) {
                    res.entity.forEach((element): any => {
                        // eslint-disable-next-line no-param-reassign
                        element.active = true;
                    });
                    this.Tags = res.entity;
                }
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public getAllPeopleList(): any {
        this.submitted = true;
        this.albumService.getmembersshipbyalbumidApi(this.queryData.albumId).subscribe((res): any => {
            if (res && res.entity) {
                this.submitted = false;
                // const currentuser = res.entity.findIndex((x): any => x.user.id === this.userId);
                // if (currentuser !== -1) {
                //     res.entity.splice(currentuser, 1);
                // }

                this.peopleLists = res.entity;
                this.peopleListsGroup = res.entity;

                this.peopleLists.forEach((element): void => {
                    // eslint-disable-next-line no-param-reassign
                    element.isCheck = false;
                });
                this.peopleListsGroup.forEach((element): void => {
                    // eslint-disable-next-line no-param-reassign
                    element.isCheck = false;
                });

                // Grouping by alphabetical order
                const obj = this.peopleListsGroup.reduce((acc, c): any => {
                    const letters = c.user.userName[0].toUpperCase();
                    acc[letters] = (acc[letters] || []).concat(c);
                    return acc;
                }, {});
                // `map` over the object entries to return an array of objects
                this.peopleListsGroup = Object.entries(obj).map(([letter, names]): any => ({ letter, names }));
                console.log(this.peopleListsGroup)
            } else {
                this.submitted = false;
                this.peopleLists = [];
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        });
    }

    public selectUnselectUser(event, id, phototagid, fromsidemenu, index, index1): void {
        // if (!fromsidemenu && id === this.userId) {
        //     this.toastr.clear();
        //     this.peopleLists[index].isCheck = true;
        //     this.toastr.error('Sorry, You can not assign task by your self.');
        //     setTimeout((): any => {
        //         this.peopleLists[index].isCheck = false;
        //         this.changedet.detectChanges();
        //     }, 500);
        //     return;
        // }

        const spliceIndex = this.tagFormData.membershipIds.findIndex((y): any => y === id);
        if ((spliceIndex === -1) && event.target.checked) {
            this.tagFormData.membershipIds.push(id);
        }

        if ((spliceIndex !== -1) && !event.target.checked) {
            this.tagFormData.membershipIds.splice(spliceIndex, 1);
            if (fromsidemenu) {
                this.peopleListsGroup[index].names[index1].isCheck = false;
            }
        }

        if (this.editData || fromsidemenu) {
            this.editAssignUser(phototagid, this.peopleListsGroup[index].names[index1].user.id);
        }
    }

    public selectTab(tabId: number): void {
        this.staticTabs.tabs[tabId].active = true;
    }

    public addPhotoTag(): void {
        this.tagFormData.photoId = this.queryData.photoId;
        this.tagFormData.albumId = this.queryData.albumId;
        this.tagFormData.description.text = (this.tagFormData.description.text === null) ? '' : this.tagFormData.description.text;

        const leftValue = Number(this.tagFormData.left);
        const topValue = Number(this.tagFormData.top);
        this.tagFormData.left = leftValue;
        this.tagFormData.top = topValue;

        if (this.tagFormData.tagTypeId) {
            this.submitted = true;
            this.albumService.addPhotoTagApi(this.tagFormData).subscribe(
                (res): void => {
                    this.submitted = false;
                    if (res && res.status) {
                        this.toasterMethod('success', res.status.msg);
                        this.staticModal.hide();
                        this.clearFormData();
                        this.getPlotingTags();
                    }
                },
                (err): void => {
                    this.submitted = false;
                    this.api.handleError(err);
                },
            );
        } else {
            this.toasterMethod('error', 'Please choose tag color before creating tag');
            this.selectTab(1);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public getEditDatas(id): void {
        this.submitted = true;
        this.albumService.getNoteByPhotoTagidApi(id).subscribe((res): any => {
            this.submitted = false;
            if (res && res.entity) {
                this.editData = true;
                this.tagFormData.membershipIds = [];
                if (this.peopleLists.length === 0) {
                    return;
                }

                if (!this.sidemenuPhotoTagId) {
                    this.peopleLists.forEach((element): any => {
                        const inarray = res.entity.assignToList.find((z): any => z.userId === element.user.id);
                        if (inarray && inarray !== -1) {
                            // eslint-disable-next-line no-param-reassign
                            element.isCheck = true;
                            this.tagFormData.membershipIds.push(inarray.userId);
                        }
                    });
                } else {
                    res.entity.assignToList.forEach((element): any => {
                        this.peopleListsGroup.forEach((ele): any => {
                            ele.names.forEach((options): any => {
                                if (element.userId === options.user.id) {
                                    // eslint-disable-next-line no-param-reassign
                                    options.isCheck = true;
                                    this.tagFormData.membershipIds.push(options.user.id);
                                }
                            });
                        });
                    });
                }

                const tagedUsersInarray = res.entity.assignToList.find((y): any => y.userId === localStorage.getItem('userId'));
                if (tagedUsersInarray && tagedUsersInarray !== -1) {
                    this.completeTaskSection = true;
                } else {
                    this.completeTaskSection = false;
                }

                this.currentCommentsList = res.entity.commentList;
                this.scrollToBottom();
                if (this.currentPosition && this.currentPosition.tagtype && this.currentPosition.tagtype.id) {
                    this.tagFormData.tagTypeId = this.currentPosition.tagtype.id;
                }
                this.assignedUsersList = res.entity.assignToList;
                this.completedTask = res.entity.completed;
                this.attachmentsList = res.entity.attachmentList;
                this.completedUsersList = res.entity.completedByList;

                if (this.completedUsersList.length > 0) {
                    this.restrictCompletedTag = true;
                } else {
                    this.restrictCompletedTag = false;
                }

                this.assignedUsersList.forEach((m): any => {
                    if (res.entity.completedByList.length > 0) {
                        if (m.userId === res.entity.completedByList[0].userId) {
                            // eslint-disable-next-line no-param-reassign
                            m.completed = true;
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            m.completed = false;
                        }
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        m.completed = false;
                    }
                });
            } else {
                this.editData = false;
            }
        }, (err): any => {
            this.api.handleError(err);
        });
    }

    public modalOnHide($event: ModalDirective): void {
        this.editData = false;
        this.getPlotingTags();
    }

    public addDescription(photoTagId): void {
        this.submitted = true;
        if (this.note !== '') {
            const payload = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                photoTagId,
                type: 1,
                text: this.note.trim(),
                attachment: {
                    awskey: null,
                    extension: null,
                },
            };

            this.callAddNoteApi(payload, photoTagId, 'comment');
        } else {
            this.toasterMethod('error', 'please enter your comment');
        }
    }

    public editAssignUser(photoTagId, userId): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            photoTagId,
            membershipIds: this.tagFormData.membershipIds,
        };
        this.albumService.editAssignUserApi(payload).subscribe(
            (res): any => {
                this.toastr.clear();
                if (this.tagFormData.membershipIds.length > 0) {
                    setTimeout((): any => {
                        if (this.tagFormData.membershipIds.indexOf(userId) !== -1) {
                            this.toasterMethod('success', 'User assigned successfully.');
                        } else {
                            this.toasterMethod('success', 'User removed successfully.');
                        }
                    }, 200);
                }
                // this.getAllPeopleList();
                this.getEditDatas(photoTagId);
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public editTagPosition(position): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            photoTagId: position.photoTagId,
            top: JSON.stringify(position.y),
            left: JSON.stringify(position.x),
            tagTypeId: '',
        };
        this.albumService.editTagPositionApi(payload).subscribe(
            (res): any => {
                // this.toasterMethod('success', res.status.msg);
                this.getPlotingTags();
            }, (err): any => {
                this.api.handleError(err);
            },
        );
    }

    public clearVariableDatas(): void {
        this.completeTaskSection = false;
        this.tagForm.form.reset();
        this.tagFormData.membershipIds = [];
    }

    public clearFormData(): void {
        this.clearVariableDatas();
        this.getAllPeopleList();
    }

    public toasterMethod(type, message): void {
        this.toastr.clear();
        if (type === 'success') {
            this.toastr.success(message);
        } else {
            this.toastr.error(message);
        }
    }

    // public getListOfCompleted(): void {
    //     const payload = {
    //         userId: localStorage.getItem('userId'),
    //         workSpaceName: sessionStorage.getItem('workspacename'),
    //         projectId: sessionStorage.getItem('projectId'),
    //         assignedTo: '',
    //         sortby: 'created',
    //         orderby: 'asc',
    //     };
    //     this.albumService.getCompletedListApi(payload).subscribe(
    //         (res): any => {
    //             console.log(res);
    //             this.completedTask = false;
    //             if (res && res.entity) {
    //                 this.toDoPendingList = res.entity.pendingToDoList;
    //                 this.toDoCompletedList = res.entity.completedToDoList;

    //                 // console.log(this.toDoPendingList);
    //                 // console.log(this.peopleLists, this.toDoCompletedList);
    //                 if (res.entity.completedToDoList.length > 0) {
    //                     this.completedTask = true;
    //                 }
    //             }
    //         }, (err): any => {
    //             this.api.handleError(err);
    //         },
    //     );
    // }

    public changeStatusToDo(event): void {
        const currentTodoId = this.assignedUsersList.find((z): any => z.userId === localStorage.getItem('userId'));
        if (currentTodoId) {
            const payload = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                todoId: currentTodoId.todoId,
                status: 'complete',
            };

            this.albumService.changeStatusToDoApi(payload).subscribe(
                (res): any => {
                    this.toasterMethod('success', res.status.msg);
                    const photoTagId = this.currentPosition.id ? this.currentPosition.id : this.sidemenuPhotoTagId.id;
                    this.getEditDatas(photoTagId);
                    this.completedTask = true;
                }, (err): any => {
                    this.api.handleError(err);
                },
            );
        }
    }

    public returnAsDate(date): any {
        return this.datePipe.transform(new Date(date), 'MM-dd-yyyy, h:mm a');
    }

    public getPhotoBanner(photoId): void {
        if (photoId === this.queryData.photoId) {
            return;
        }
        const tempParam = {
            albumId: this.queryData.albumId,
            photoId,
            userLat: this.queryData.userLat,
            userLng: this.queryData.userLng,
            prevPage: this.queryData.prevPage,
        };
        this.router.navigateByUrl('/', { skipLocationChange: true }).then((): any => {
            this.router.navigate(['/project/photo-tag'], { queryParams: { ...this.api.getSessionData(), ...tempParam } });
        });
    }

    // public getbytodoid(todoid, type): void {
    //     this.submitted = true;
    //     this.albumService.getbytodoidApi(todoid, type).subscribe(
    //         (res): any => {
    //             this.submitted = false;
    //             console.log(res);
    //             if (res && res.entity) {
    //                 this.completedUsersList = res.entity.completedBy;
    //             }
    //         },
    //     );
    // }

    public downloadImage(): any {
        this.loadingBar.start();
        const S3Client = this.albumService.getS3Credential();
        const that = this;
        S3Client.getObject(
            { Bucket: environment.kIMAGEBUCKET, Key: that.currentImage.media.awskey },
            (error, data): any => {
                if (error != null) {
                    console.log(`Failed to retrieve an object: ${error}`);
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([new Uint8Array(data.Body)], { type: data.ContentType }));
                    link.download = that.currentImage.media.awskey;
                    link.click();
                    this.loadingBar.complete();
                }
            },
        );
    }

    public showPhotoInfo(): void {
        this.PhotoInfoScreen = !this.PhotoInfoScreen;
    }

    public openModal1(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'custom-modal',
        });
    }

    public openModal3(): any {
        this.modalRef = this.modalService.show(
            this.photodeleteModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public yesMethodDelete(): any {
        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: this.queryData.albumId,
            photoId: [this.queryData.photoId],
        };

        this.submitted = true;
        this.albumService.deletePhotoList(data).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.modalRef.hide();
                this.toastr.success(`${this.mediaFormat} Deleted successfully`);
                window.history.back();
            } else {
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.api.handleError(err);
        });
    }

    public getDirection(): any {
        if (this.currentImage.lattitude && this.currentImage.longitude) {
            this.origin = { lat: +this.currentImage.lattitude, lng: +this.currentImage.longitude };
            if (this.queryData.userLat && this.queryData.userLng) {
                this.destination = { lat: +this.queryData.userLat, lng: +this.queryData.userLng };
            } else {
                this.getCurrentLocation();
            }
        }
    }

    public getCurrentLocation(): void {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (showPosition): any => {
                    this.destination = { lat: +showPosition.coords.latitude, lng: +showPosition.coords.longitude };
                },
            );
        }
    }


    public showInvite(): void {
        this.Invite = !this.Invite;
        if (this.Invite) {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            this.datePickerConfig = { dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', minDate: date };
        }
    }

    public shareLink(shareForm): any {
        this.Invitesubmitted = true;
        if (shareForm.value) {
            this.submitted = true;
            const params = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                email: shareForm.value.email,
                firstName: shareForm.value.firstName,
                lastName: shareForm.value.lastName,
                expires: this.datePipe.transform(shareForm.value.expires, 'yyyy-MM-dd'),
                albumId: this.queryData.albumId,
                photoId: this.queryData.photoId,
                shareType: 1,
            };

            this.albumService.ShareImg(params).subscribe(
                (res): any => {
                    this.Invitesubmitted = false;
                    this.submitted = false;
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success(res.status.msg);
                        this.Invite = false;
                        this.createBuilderForm();
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                },
                (err): void => {
                    this.Invitesubmitted = false;
                    this.submitted = false;
                    this.api.handleError(err);
                },
            );
        }
    }

    public removeattachment(noteid): any {
        this.submitted = true;
        this.albumService.removeattachmentApi(noteid).subscribe(
            (res): any => {
                this.toastr.clear();
                this.submitted = false;
                this.getEditDatas(this.sidemenuPhotoTagId.id);
                if (res && res.status && res.status.status === 200) {
                    this.toastr.success(res.status.msg);
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                this.api.handleError(err);
            },
        );
    }

    // ------------------

    public addPhoto(photoTagId): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            photoTagId,
            type: 2,
            text: null,
            attachmentList: this.getAttachementObj(),
        };
        setTimeout((): any => {
            this.callAddNoteApi(payload, photoTagId, 'photo');
        }, 2000);
    }

    public callAddNoteApi(payload, photoTagId, type): void {
        this.albumService.addNoteApi(payload).subscribe(
            (res): any => {
                this.submitted = false;
                this.selectedImage = [];
                if (type === 'photo') {
                    this.toasterMethod('success', res.status.msg);
                }
                this.note = '';
                this.getEditDatas(photoTagId);
            }, (err): any => {
                this.selectedImage = [];
                this.api.handleError(err);
            },
        );
    }

    public initiateUpload(event): void {
        this.files = event.target.files;
        this.i = 0;
        this.imageUpload(this.i, this.files);
    }

    public imageUpload(index, files): void {
        this.totalFiles = files;

        if ((this.i) >= this.totalFiles.length) {
            this.submitted = false;
            this.addPhoto(this.sidemenuPhotoTagId.id);
            return;
        }

        this.toastr.clear();
        const fileEntry = this.totalFiles[index];

        const checkValidFile = this.albumService.getCredential(fileEntry);
        if (checkValidFile.fileExtenstion.toLowerCase() === 'jpg'
            || checkValidFile.fileExtenstion.toLowerCase() === 'jpeg' || checkValidFile.fileExtenstion.toLowerCase() === 'png') {
            this.submitted = true;
            const params = this.albumService.getS3ParamsAttach(fileEntry);
            if (params.Key) {
                this.selectedImage.push({
                    name: checkValidFile.fileName,
                    awskey: params.Key,
                    extension: checkValidFile.fileExtenstion,
                    finish: false,
                    valid: true,
                    percentage: 0,
                    errorMessage: '',
                    request: '',
                });
                this.awsUploadFile(fileEntry, params, (+this.selectedImage.length - 1));
            }
        } else {
            this.selectedImage.push({
                name: checkValidFile.fileName,
                awskey: '',
                finish: false,
                valid: false,
                percentage: 0,
                errorMessage: `.${checkValidFile.fileExtenstion} Invalid image format.`,
                request: '',
            });
            this.i += 1;
            this.imageUpload(this.i, this.totalFiles);
        }
    }

    public awsUploadFile(file, params, index): any {
        const S3Client = this.albumService.getS3Credential();

        if (file) {
            const that = this;
            return new Promise((res, rej): any => {
                this.selectedImage[index].request = S3Client.putObject(params, (err: any, data: any): any => {
                    if (err) {
                        return rej(err);
                    }
                    // eslint-disable-next-line no-param-reassign
                    params.filename = file.name;
                }).on('httpUploadProgress', (evt): any => {
                    that.uploadPercentage = (evt.loaded * 100) / +evt.total;
                    that.progressBarValue(that.uploadPercentage, index);
                });
            });
        }
        return null;
    }

    public progressBarValue(percentage, i): void {
        this.selectedImage[i].percentage = +percentage;
        if (this.selectedImage[i].percentage === 100) {
            this.i += 1;
            this.selectedImage[i].finish = true;
            this.imageUpload(this.i, this.totalFiles);
        }
    }

    public tagSizeChange(event): void {
        $('.ui-draggable .box').height(event.target.value);
        $('.ui-draggable .box').width(event.target.value);
    }

    public openInSidebar(event): any {
        const label = event.target.getAttribute('data-popover');
        if (label) {
            const tag = this.plotArr.find((x): any => Number(x.label) === Number(label));
            this.showNext(tag);
        }
    }

    public openCompletedTagSidemenu(): void {
        if (this.queryData.photoTagid) {
            this.submitted = false;
            const tag = this.plotArr.find((t): any => t.id === this.queryData.photoTagid);
            this.showNext(tag);
            this.plotArr = [tag];
            this.plotOptions();
        }
    }

    public editTagColor(tag): void {
        if (this.editData) {
            this.submitted = true;
            const payload = {
                userId: localStorage.getItem('userId'),
                workSpaceName: sessionStorage.getItem('workspacename'),
                photoTagId: this.currentPosition.id,
                tagTypeId: tag.id,
                // top: JSON.stringify(this.currentPosition.top),
                // left: JSON.stringify(this.currentPosition.left),
            };
            this.albumService.editTagPositionApi(payload).subscribe(
                (res): void => {
                    this.submitted = false;
                    this.toasterMethod('success', res.status.msg);
                },
                (err): void => {
                    this.submitted = false;
                    this.api.handleError(err);
                },
            );
        }
    }

    public searchData(e, type): void {
        if (type === 'usersearch') {
            this.assignusersearch = e.target.value;
        } else {
            this.tagsearch = e.target.value;
        }
    }

    public pdfDownload(urllist): void {
        this.loadingBar.start();
        this.submitted = true;

        const data = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            albumId: '',
            urlList: [this.cloudFrontURL + urllist],
        };
        this.albumService.getPDFDownload(data).subscribe(
            (res): any => {
                const blob = new Blob([this.base64ToArrayBuffer(res.entity.pdfData)], { type: 'application/pdf' });
                saveAs(blob, `${urllist}.pdf`);
                this.submitted = false;
                this.loadingBar.complete();
            },
        );
    }

    public base64ToArrayBuffer(base64): any {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public roundValue(value): any {
        return Math.round(value);
    }

    public getAttachementObj(): any {
        this.list = [];
        for (let index = 0; index < this.selectedImage.length; index++) {
            if (this.selectedImage[index].awskey) {
                this.list.push(
                    { awskey: this.selectedImage[index].awskey, extension: this.selectedImage[index].extension.toUpperCase() },
                );
            }

            if ((index + 1) === this.selectedImage.length) {
                return this.list;
            }
        }
    }

    public removeSelectImage(index): any {
        this.selectedImage[index].request.abort();
        this.selectedImage.splice(index, 1);
    }

    public clearSearchData(): void {
        this.searchField = '';
        this.tagsearch = '';
    }

    public clearUserSearchData(): void {
        this.searchUserField = '';
        this.assignusersearch = '';
        this.usernamesearch = '';
    }

    public getDateValue(value): any {
        if (value) {
            const datobj = value.split(' ');
            return new Date(`${datobj[0]}T${datobj[1]}.000Z`);
        }
        return '';
    }

    // ---------------------------------- image zoomer -----------------------
    // public onLoad(): void {
    //   this.render.setStyle(this.divZoomed,'background-image',"url('" + this.cloudFrontURL +''+ this.currentImage?.media?.awskey+ "')");
    //   this.render.setStyle(this.divZoomed,'background-size',(this.img.nativeElement.width * this.zoom) + "`px` " + (this.img.nativeElement.height * this.zoom) + "px")
    //   this.render.setStyle(this.divZoomed,'background-repeat', 'no-repeat')
    //   this.render.setStyle(this.divZoomed,'transition','background-position .2s ease-out');
    //   this.factorX=this.img.nativeElement.width;
    //   this.factorY=this.img.nativeElement.height;

    //    this.yet=true;
    //    setTimeout(()=>{
    //       this.factorX=this.imgWidth || this.imgHeigth?this.factorX/this.img.nativeElement.width:1
    //       this.factorY=this.imgWidth || this.imgHeigth?this.factorY/this.img.nativeElement.height:1
    //   const dim=(this.divZoomed as any).getBoundingClientRect()
    //   this.cx=(dim.width-this.img.nativeElement.width*this.zoom*this.factorX)/(this.img.nativeElement.width - this.lens.nativeElement.offsetWidth);
    //   this.cy=(dim.height-this.img.nativeElement.height*this.zoom*this.factorY)/(this.img.nativeElement.height -
    //    this.lens.nativeElement.offsetHeight);



    //    })


    // }
    // moveLens(e:any)
    // {
    //   let pos
    //   let x
    //   let y;
    //   /*prevent any other actions that may occur when moving over the image:*/
    //   e.preventDefault();
    //   /*get the cursor's x and y positions:*/
    //   pos = this.getCursorPos(e);
    //   /*calculate the position of the lens:*/
    //   x = pos.x - (this.lens.nativeElement.offsetWidth / 2);
    //   y = pos.y - (this.lens.nativeElement.offsetHeight / 2);
    //   /*prevent the lens from being positioned outside the image:*/
    //   if (x > this.img.nativeElement.width - this.lens.nativeElement.offsetWidth) {x = this.img.nativeElement.width - this.lens.nativeElement.offsetWidth;}
    //   if (x < 0) {x = 0;}
    //   if (y > this.img.nativeElement.height - this.lens.nativeElement.offsetHeight) {y = this.img.nativeElement.height - this.lens.nativeElement.offsetHeight;}
    //   if (y < 0) {y = 0;}
    //   /*set the position of the lens:*/
    //   this.posX = x;
    //   this.posY = y;
    //   /*display what the lens "sees":*/

    //   let result = (x * this.cx) + "px "+(y * this.cy) + "px"

    //   return result;


    // }
    // getCursorPos(e) {
    //   let a, x = 0, y = 0;
    //   e = e || window.event;
    //   /*get the x and y positions of the image:*/
    //   a = this.img.nativeElement.getBoundingClientRect();
    //   /*calculate the cursor's x and y coordinates, relative to the image:*/
    //   x = e.pageX - a.left;
    //   y = e.pageY - a.top;
    //   /*consider any page scrolling:*/
    //   x = x - window.pageXOffset;
    //   y = y - window.pageYOffset;
    //   return {x : x, y : y};
    // }

    public xyPositionLogic(event): any {
        // started
        const position = {
            left: event.clientX,
            top: event.clientY,
        };
        const x2 = event.clientX;
        const y2 = event.clientY;
        const offsets = this.getPosition(this.imgTag.nativeElement);
        const x1 = offsets.x;
        const y1 = offsets.y;

        const minusXval = ((x2 - x1) < 0) ? (x2 - x1) * (-1) : (x2 - x1);
        const minusYval = ((y2 - y1) < 0) ? (y2 - y1) * (-1) : (y2 - y1);

        const leftDelta = minusXval / this.imgTag.nativeElement.width;
        const topDelta = minusYval / this.imgTag.nativeElement.height;

        // if (Math.abs(leftDelta) > 0.05 || Math.abs(topDelta) > 0.05) {
        position.top = topDelta;
        position.left = leftDelta;
        // this.left = Number(leftDelta) * 100;
        // this.top = Number(topDelta) * 100;
        // }

        if (position.top > 1) {
            position.top = 1;
        }
        if (position.top < 0) {
            position.top = 0;
        }
        if (position.left > 1) {
            position.left = 1;
        }
        if (position.left < 0) {
            position.left = 0;
        }
        // ended
        return { x: position.left, y: position.top };
    }

    public getPosition(element): any {
        let x = 0;
        let y = 0;
        while (element) {
            x += (element.offsetLeft - element.scrollLeft + element.clientLeft);
            y += (element.offsetTop - element.scrollTop + element.clientTop);
            // eslint-disable-next-line no-param-reassign
            element = element.offsetParent;
        }

        return { x, y };
    }

    // public async modalPlacement(value, direction: string): any {
    //     let temp = await this.imgTag?.nativeElement?.offsetHeight.toString();
    //     if (direction === 'top') {
    //         return temp * value;
    //     }
    //     temp = await this.imgTag?.nativeElement?.offsetWidth.toString();
    //     return temp * value;
    // }

    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }

    public viewFullImage(attachurl): void {
        window.open(this.cloudFrontURL + attachurl, '_blank');
    }

    scrollHeight: any = 0;
    lastScrollHeight: any = 0;
    scrollToBottom(): void {
        try {
          setTimeout(() => {
            if (this.myScrollContainer && this.myScrollContainer.nativeElement) {
              this.scrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
              this.lastScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
            }
          }, 500);
        } catch (err) {
          // Handle any potential errors
        }
      }
}
