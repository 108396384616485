import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services';

@Component({
    selector: 'app-sub-header',
    templateUrl: './sub-header.component.html',
})
export class SubHeaderComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public isFocused = false;

    public loginUser: boolean;

    public btntext = 'Create Account';

    public constructor(private router: Router, private loginService: LoginService) { }

    public ngOnInit(): void {
        if (localStorage.getItem('token') && localStorage.getItem('userId')) {
            this.loginUser = false;
        } else {
            this.loginUser = true;
        }

        if (this.router.url === '/signup') {
            this.btntext = 'LogIn';
        } else {
            this.btntext = 'Create Account';
        }
    }

    public navigateLink(): any {
        if (this.btntext === 'LogIn') {
            this.router.navigate(['/login']);
        } else {
            this.router.navigate(['/signup']);
        }
    }

    public logout(): any {
        this.loginService.logout('').subscribe(
            (res): any => { },
        );
        localStorage.clear();
        this.loginUser = true;
        this.router.navigate(['/login']);
    }


    public showNav(): any {
        this.isFocused = !this.isFocused;
    }
}
