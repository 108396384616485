<div *ngIf="photoUrl" class="card-body px-2 py-0" style="text-align: center">
    <img src={{photoUrl}} alt="user-img" class="br10 posted-img">
</div>


<div class="card content-card pl18 pr18 py-3 border-grey" *ngIf="noDataFound">
    <div class="card-body d-flex align-items-center justify-content-center h-100vh">
        <div class="col-3 mx-auto text-center">
            <img src='./assets/images/feed-empty.svg' alt="feed-empty" class="w-100">

            <h1 class="text-black fs20 font-weight-bold mt-5">Seems Empty</h1>
            <p class="fs12 grey-g fw-600">No post in the list</p>
        </div>
    </div>
</div>