import {
    Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    @Input() forgetPasswordToggle: boolean;

    @Output() showforgetevent = new EventEmitter<boolean>();


    public submitted = false;

    public forgetForm: FormGroup;

    public ForgetPassword: any = {};


    public showforget: boolean ;
    ngForm: any;

    public partialRegData: any;

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    public config = {
        ignoreBackdropClick: true,
    };

    public modalRef: BsModalRef;

    public showNav = false;

    @Output('otptype') public otptype = new EventEmitter();

    public showCreate: boolean;

    public constructor(
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private api: LoginService, private modalService: BsModalService,
    ) { }

    public ngOnInit(): void {
        this.ForgetPassword = {
            email: '',
        };
        this.api.closeTab.subscribe(val => {
            this.showNav = val;
        })
    }

    public ToggleForgetpassword(): any {
        this.showforgetevent.emit(this.showforget);
    }

    public ToggleNav(value): any {
        console.log(this.showCreate);
        if (value) {
            this.showCreate = (value === 'create');
        }
        this.showNav = !this.showNav;
        console.log(this.showNav);
    }

    public getOtpType(event, forgetForm): any {
        console.log(event);
        console.log(this.showNav);

        this.ForgetPassword.otpType = event;
        console.log(this.ForgetPassword);

        this.api.forgetpass(this.ForgetPassword).subscribe(
            (res): void => {
                this.submitted = false;
                if (res.status.status === 200) {
                    console.log(res);

                    if (event === 1 || event === '1') {
                        this.toastr.success('An otp is sent to Phone number');
                    } else {
                        this.toastr.success('An otp is sent to mail');
                    }
                    localStorage.setItem('email', this.ForgetPassword.email);
                    localStorage.setItem('userId', res.entity.userId);
                    localStorage.setItem('username', res.entity.firstName);
                    localStorage.setItem('type', '2');
                    this.router.navigate(['/verify-otp'], { queryParams: {source: event} });
                } else if (res.status.status === 402) {
                    this.ForgetPassword = {};
                    this.toastr.error(res.status.msg);
                    this.showforgetevent.emit(false);
                    this.showNav = !this.showNav;
                } else if (res.status.status === 204) {
                    this.toastr.error('It seems you are not register with us');
                } else if (res.status.status === 238) {
                    this.toastr.error('Your account is not verified');
                    localStorage.setItem('email', this.ForgetPassword.email);
                    localStorage.setItem('userId', res.entity.userId);
                    localStorage.setItem('username', res.entity.firstName);
                    localStorage.setItem('type', '1');
                    this.router.navigate(['/verify-otp'], { queryParams: {source: event} });
                } else {
                    this.openModal();
                    this.partialRegData = res;
                    // this.toastr.error(res.status.msg);
                }
            },
            (err): void => {
                this.submitted = false;
                if (err && err.status && err.status.status === 401) {
                    this.toastr.error('Inactive user, Please activate your account');
                }
            },
        );
    }

    public onSubmit(forgetForm: any): void {
        if (this.submitted) {
            return;
        }
        const payload = {
            email : forgetForm.value.email,
            otpType: '3',
            type: 3
        };
        this.api.forgetpass(payload).subscribe((res) => {
            if (res && res.status && res.status.status === 200) {
                localStorage.setItem('userId', res.entity.userId);
                localStorage.setItem('email', this.ForgetPassword.email);
                localStorage.setItem('type', '3');
                localStorage.setItem('otpType', '3');
                this.toastr.success('An otp is sent to email and mobile number');
                this.router.navigate(['/verify-otp']);
            }
        });
        // this.submitted = true;
        // this.ToggleNav('');
        // this.toastr.clear();
        // this.api.forgetpass(this.ForgetPassword).subscribe(
        //     (res): void => {
        //         this.submitted = false;
        //         if (res.status.status === 200) {
        //             this.toastr.success('An otp is sent to mail');
        //             localStorage.setItem('email', this.ForgetPassword.email);
        //             localStorage.setItem('userId', res.entity.userId);
        //             localStorage.setItem('username', res.entity.firstName);
        //             localStorage.setItem('type', '2');
        //             this.router.navigate(['/verify-otp']);
        //         } else if (res.status.status === 204) {
        //             this.toastr.error('It seems you are not register with us');
        //         } else if (res.status.status === 238) {
        //             this.toastr.error('Your account is not verified');
        //             localStorage.setItem('email', this.ForgetPassword.email);
        //             localStorage.setItem('userId', res.entity.userId);
        //             localStorage.setItem('username', res.entity.firstName);
        //             localStorage.setItem('type', '1');
        //             this.router.navigate(['/verify-otp']);
        //         } else {
        //             this.openModal();
        //             this.partialRegData = res;
        //             // this.toastr.error(res.status.msg);
        //         }
        //     },
        //     (err): void => {
        //         this.submitted = false;
        //         if (err && err.status && err.status.status === 401) {
        //             this.toastr.error('Inactive user, Please activate your account');
        //         }
        //     },
        // );
    }

    public openModal(): any {
        this.modalRef = this.modalService.show(
            this.exampleModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    yesMethod() {
        this.router.navigate(['/signup'], { queryParams: {
                email :this.partialRegData.entity.email,
                firstname : this.partialRegData.entity.firstName,
                lastname : this.partialRegData.entity.lastName
            },
        });
        this.modalRef.hide();
    }
}
