import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';

@Component({
  selector: 'app-company-edit-sidebar',
  templateUrl: './company-edit-sidebar.component.html',
})
export class CompanyEditSidebarComponent implements OnInit {
  @Input('toggleSidemenu') public toggleSidemenu: boolean = false;

  @Output('closesidemenu') public closesidemenu = new EventEmitter();

  @Input('editWorkspaceId') public workspaceId = '';

  @Input('editWorkspaceName') public workspaceName: any;

  public submitted = false;

  public companyname = '';

  public constructor(private toastr: ToastrService, private workspaecservice: WorkspaceServiceService) { }

  public ngOnChanges(changes: SimpleChanges): void {
      // changes.prop contains the old and the new value...
      this.toggleSidemenu = changes.toggleSidemenu.currentValue;
      this.companyname = this.workspaceName;
  }

  public ngOnInit(): void {
  }

  public closeFilter(): void {
      this.toggleSidemenu = false;
      this.submitted = false;
      this.closesidemenu.emit(this.toggleSidemenu);
  }

  public editCompany(): void {
    if (!this.companyname) {
      return;
    }

    this.submitted = true;

    if (this.companyname && this.companyname.toLowerCase() !== this.workspaceName.toLowerCase()) {
      const payload = {
        workSpaceId: this.workspaceId,
        workSpaceName: this.companyname,
      }
      this.workspaecservice.updateWorkspace(payload).subscribe((res): any => {
        this.submitted = false;
        if (res && res.status && res.status.status === 200) {
          this.closeFilter();
        } else {
            this.toastr.error(res.status.msg);
        }
      }, (err): any => {
        this.submitted = false;
        if (err && err.status && err.status.status === 302) {
            this.toastr.error(err.status.msg);
        }
      })
    } else {
      this.closeFilter();
    }
  }

}
