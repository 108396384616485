<section class="main-container">
    <div class="sub-container">
        <div class="sub-content-container pt15">

            <div class="card content-card shadow-none  border-grey my-2">
                <ul class="nav nav-pills pills-primary px-sm-3 mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item mr-sm-4 " role="presentation">
                        <a [class.active]="viewMode=='byme'" (click)="viewMode='byme'" class="nav-link  px-4"
                            href="javascript:void(0)" (click)="triggerMethod(1)">Shared by me</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a [class.active]="viewMode=='withme'" (click)="viewMode='withme'" class="nav-link px-4"
                            href="javascript:void(0)" (click)="triggerMethod(2)">Shared with me</a>
                    </li>

                </ul>
                <div class="tab-content px-2 mb-4" [ngSwitch]="viewMode">
                    <div class="tab-pane fade show active" *ngSwitchCase="'byme'">
                        <div *ngIf="!errorMessage">
                        <span *ngFor="let me of sharedByMeList">
                        <div class="card-header border-0 bg-white d-flex justify-content-between">

                            <div class="profile-photo">
                                <img *ngIf="!me.profileImage" src="./assets/images/empty-img.png" alt="user-img">
                                <img *ngIf="me.profileImage" src={{cloudFrontURL}}{{me.profileImage}} alt="user-img">
                            </div>


                            <div class="flex-grow-1 ml-2">
                                <h1 class="mb-0 grey-g fs14 fw-600">{{me.firstName}}</h1>
                                <p class="grey-r mb-0 fs10">{{me.sharedOn | amParseZone | amDateFormat: 'MMMM DD yyyy'}}</p>
                            </div>
                        </div>
                        <div class="card-body px-2 py-0">
                            <img src={{cloudFrontURL}}{{me.photoUrl}} alt="user-img" class="br10 posted-img">
                        </div>
                        </span>
                    </div>
                    </div>
                    <div class="tab-pane fade show active" *ngSwitchCase="'withme'">
                        <div *ngIf="!errorMessage">
                            <span *ngFor="let we of sharedWithMeList">
                            <div class="card-header border-0 bg-white d-flex justify-content-between">
    
                                <div class="profile-photo">
                                    <img *ngIf="!we.profileImage" src="./assets/images/empty-img.png" alt="user-img">
                                    <img *ngIf="we.profileImage" src={{cloudFrontURL}}{{we.profileImage}} alt="user-img">
                                </div>
    
    
                                <div class="flex-grow-1 ml-2">
                                    <h1 class="mb-0 grey-g fs14 fw-600">{{we.firstName}}</h1>
                                    <p class="grey-r mb-0 fs10 ">{{we.sharedOn | amParseZone | amDateFormat: 'MMMM DD yyyy'}}</p>
                                </div>
                            </div>
                            <div class="card-body px-2 py-0">
                                <img src={{cloudFrontURL}}{{we.photoUrl}} alt="user-img" class="br10 posted-img">
                            </div>
                            </span>
                        </div>
                    </div>

                    <div class="card content-card pl18 pr18 py-3 border-grey" *ngIf="errorMessage">
                        <div class="card-body mt60 mb60">
                            <div class="col-8 mx-auto text-center">
                                <img src='./assets/images/feed-empty.svg' alt="feed-empty" class="w-100">
        
                                <h1 class="text-black fs20 font-weight-bold mt-5">Seems Empty</h1>
                                <p class="fs12 grey-g fw-600">No post in the list</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>