  
export const environment = {
    production: true,
    apiBaseUrl: 'https://gateway.pixly.ai/pixlyGateway/',
    kAccessKey: 'AKIAQFEFVNT5NGM77PUZ',
    KsecretKey: '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',
    KRegion: 'us_west_2',
    kIMAGEBUCKET: 'pixlyprod',
    KInsourceBucketUrl: 'http://d4381dmmtr1wk.cloudfront.net/',

    loginContext: 'pixlyLogin',
    emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    firebase: {
        apiKey: 'AIzaSyC6jduF_2sFOCy12lPAby1nmB5XLaK7FV8',
        authDomain: 'angular-admin-fcb8b.firebaseapp.com',
        databaseURL: 'https://angular-admin-fcb8b.firebaseio.com',
        projectId: 'angular-admin-fcb8b',
        storageBucket: 'angular-admin-fcb8b.appspot.com',
        messagingSenderId: '935327598299',
        appId: '1:935327598299:web:489ea772ad3845d5486efe',
        measurementId: 'G-KP2Y7TS4H8',
    },
    
};
