import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services';

@Component({
  selector: 'app-sendotp-sidenav',
  templateUrl: './sendotp-sidenav.component.html',
})
export class SendotpSidenavComponent implements OnInit { 

  @Input() public showToggle: boolean;

  @Input() public showCreate: boolean;

  @Input() public unverified: boolean;

  @Output('otpSubmit') public otpSubmit = new EventEmitter();

  public currentlyChecked = 0;

  public otpType = "1";

  public cancel_associated_session: any = false;

  public submitted = false;

  public datas: any;

  constructor(private router: Router, private loginService: LoginService, private toastr: ToastrService) {
   }

  ngOnInit(): void {
    
  }

  public sendotp(): any {
    this.toastr.clear();
    console.log(this.otpType);
    if (this.unverified) {
      this.submitted = true;
      this.showToggle = false;
      this.otpSubmit.emit(this.otpType);
      const payload = {
        email : localStorage.getItem('email'),
        otpType: this.otpType
      }
      console.log(payload);
      this.loginService.sendOtpInLogin(payload).subscribe( (res) => {
        if (res && res.status && res.status.status === 200) {
          this.showToggle = false;
          console.log(res);
          
          if (this.otpType === '1') {
            this.toastr.success('An otp is sent to Phone number');
          } else {
              this.toastr.success('An otp is sent to mail');
          }
          this.router.navigate(['/verify-otp'], { queryParams: {source: this.otpType} });
        }
      });
    } else {
      console.log('elseeeeeeeeeeeee');
      this.showToggle = false;
      this.otpSubmit.emit(this.otpType);
    }
  }

  cancel() {
    this.showToggle = false;
    this.loginService.closeFPTab(false)
  }

}
