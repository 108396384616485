import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService, UserService } from '../services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from '../services/workspace-service.service';

@Component({
    selector: 'app-people-details',
    templateUrl: './people-details.component.html',
})
export class PeopleDetailsComponent implements OnInit {
    public showProject: boolean;

    public EditProfile = false;

    public unsub: any;

    public viewMode = 'grid';

    public datas: any;

    public userDetails: any;

    public modalRef: BsModalRef;

    public code = [{ code: '+1', mask: '(000) 0000-000' }, { code: '+91', mask: '00000-00000' }];

    public photoList: any;

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public viewMode1 = 'company';

    public projectList: any;

    public profileForm: FormGroup;

    public editObj: any;

    public phoneMask = '';

    public submitted = false;

    public membershipId = '';

    public members = '';

    public index = 0;

    @Output() public applayout = new EventEmitter<string>();

    public constructor(private modalService: BsModalService, private activeRoute: ActivatedRoute, private api: ApiService, 
        private router: Router, public userService: UserService, private toastr: ToastrService, private workspaceService: WorkspaceServiceService) {
        this.showProject = sessionStorage.getItem('projectId') !== null;
        this.activeRoute.queryParams.subscribe((params): any => {
            console.log(params);
            this.datas = params;
            if (this.datas.url === 'project') {
                this.viewMode1 = 'project';
            } else {
                this.viewMode = 'project';
            }
            console.log(this.viewMode1);
            
        });
    }

    public ngOnInit(): void {
        this.getOtherProfile();
    }

    public getOtherProfile(): void {
        let payload: any = {};
        if (this.datas.url === 'project') {
            payload = {
                userId: this.datas.userid,
                workSpaceName: sessionStorage.getItem('workspacename'),
                teamId: sessionStorage.getItem('projectId')
            };
        } else {
            payload = {
                userId: this.datas.userid,
                workSpaceName: sessionStorage.getItem('workspacename')
            };
        }
        this.api.getOtherProfile(payload).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.userDetails = res.entity;
                this.editObj = { ...this.userDetails };
                console.log(this.editObj);
                if (this.editObj.phoneCode && this.editObj.phoneCode === '+1') {
                    this.phoneMask = this.code[0].mask;
                } else {
                    this.phoneMask = this.code[1].mask;
                }

                if (res.entity.photoList && res.entity.photoList.length > 0) {
                    this.photoList = res.entity.photoList;
                } 
                if (res.entity.projectDetails && res.entity.projectDetails.length > 0) {
                    this.projectList = res.entity.projectDetails;
                }
                console.log(this.photoList);
                console.log(this.photoList);
            }
        })
    }

    public goBack(): any {
        this.router.navigate([this.datas.prevPage], { queryParams: this.api.getSessionData() });
    }
    
    public conCadSessionData(obj): any {
        return { ...this.api.getSessionData(), ...obj };
    }

    public showEditProfile(): void {
        this.EditProfile = !this.EditProfile;
    }

    public changeMask(): void {
        if (this.userDetails.phoneCode && (this.userDetails.phoneCode === '+1' || this.userDetails.phoneCode === +1)) {
            this.phoneMask = this.code[0].mask;
        } else {
            this.phoneMask = this.code[1].mask;
        }
    }

    public onSubmit(profileForm: any): void {
        this.submitted = true;
        const payload = {
            userId: this.datas.userid,
            workSpaceName: sessionStorage.getItem('workspacename'),
            firstName: profileForm.value.firstName,
            lastName: profileForm.value.lastName,
            phoneCode: profileForm.value.phoneCode,
            phoneNumber: profileForm.value.phoneNumber,
        };
        console.log(payload);
        this.userService.editProfile(payload).subscribe(
            (res): any => {
                if (res.status.status === 200) {
                    this.toastr.clear();
                    this.toastr.success(res.status.msg);
                    this.EditProfile = !this.EditProfile;
                    this.getOtherProfile();
                } else {
                    this.toastr.clear();
                    this.toastr.error('Please check you are logged in ');
                }
                this.submitted = false;
            }, err => {
                this.submitted = false;
                this.api.handleError(err);
            }
        );
    }

    public openModal(template: TemplateRef<any>, project, index): void {
        console.log(project);
        this.index = index;
        if (project.roleName === 'Workspace Admin') {
            this.members = 'admin';
            this.membershipId = this.datas.userid;
        } else {
            this.members = 'member';
            this.membershipId = project.memberShipId;
        }

        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-sm popup-center',
        });
    } 

    public yesMethod(): any {
        this.submitted = true;
        if (this.members === 'admin') {
            this.removeAdmin();
        } else {
            this.removeUserFromProject();
        }
    }

    public removeAdmin(): any {
        console.log('admin');
        
        this.submitted = true;
        this.toastr.clear();
        this.workspaceService.removeAdminFromProject(this.membershipId).subscribe((ress): any => {
            this.submitted = false;
            if (ress && ress.status && ress.status.status === 200) {
                this.toastr.success(ress.status.msg);
                if (this.projectList[this.index].length > 1) {
                    this.projectList[this.index].splice(this.index, 1);
                } else {
                    this.projectList.splice(this.index, 1);
                }
                this.modalRef.hide();
            } else {
                this.modalRef.hide();
                this.toastr.error(ress.status.msg);
            }
        }, (errs): any => {
            this.toastr.error(errs.status.msg);
            this.submitted = false;
        });
    }

    public removeUserFromProject(): any {
        console.log('member');
        
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        this.workspaceService.removeUserFromProject(this.membershipId).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.toastr.success(res.status.msg);
                if (this.projectList[this.index].length > 1) {
                    this.projectList[this.index].splice(this.index, 1);
                } else {
                    this.projectList.splice(this.index, 1);
                }
                this.modalRef.hide();
            } else {
                this.modalRef.hide();
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.toastr.error(err.status.msg);
        });
    }

    public goToPhotoTag(photo): any {
        this.router.navigate(['/project/photo-tag'], {
            queryParams: {
                albumId: photo.albumId,
                photoId: photo.photoId,
                userLat: photo.lattitude,
                userLng: photo.longitude,
                prevPage: 'project/people'
            }
        });
    }

}
