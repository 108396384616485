/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import {
    Component, OnInit, Input, OnDestroy, TemplateRef, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from '../services/workspace-service.service';
import { SupportService } from '../services/support.service';

import { ApiService } from '../services/api_base/api.service';

@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',

})
export class PeopleComponent implements OnInit, OnDestroy {
    public submittedForm = false;

    public editInvites = false;

    public currentEditData: any;

    public companysList: any[];

    public loadingPage: boolean = false;

    @Input()
    public set routerMethod(routerMethod: any) {
        console.log(routerMethod);
    }

    public cloudFrontURL = environment.KInsourceBucketUrl;

    public showProject: boolean;

    public showPeopleMain = true;

    public showInviteBar: boolean;

    public showContactBar: boolean;

    public inviteForm: FormGroup;

    public submitted = false;

    public userRoleList = [];

    public pendingUserListTemp = [];

    public pendingUserList = [];

    public peopleListTemp = [];

    public peopleList = [];

    public userType = '';

    public buttonText = '';

    public addMemberText = '';

    public config = {
        ignoreBackdropClick: true,
    };

    public modalRef: BsModalRef;

    public membershipId = '';

    public index = 0;

    public mIndex = 0;

    @ViewChild('exampleModal') public exampleModal: TemplateRef<any>;

    @ViewChild('deleteAdminModal') public deleteAdminModal: TemplateRef<any>;

    @ViewChild('deletependinginvite') public deletependinginvite: TemplateRef<any>;

    public currentDeletePendingId: string;

    public pageNumber = 1;

    public pageRecord = 10;

    public stopScroll = true;

    public peopleSelector = '.people-scroll';

    viewMode = '';

    public constructor(private support: SupportService, private inviteBuilder: FormBuilder, private modalService: BsModalService,
        private toastr: ToastrService, private router: Router, private workspaceService: WorkspaceServiceService,
        private apis: ApiService) {
        this.createBuilderForm();
        this.userRoleList = [];

        this.userType = sessionStorage.getItem('usertype');
        if (this.router.url === '/company/people') {
            sessionStorage.removeItem('projectId');
            this.support.projectChange.next('empty');
            this.buttonText = 'Invite to Company';
            this.addMemberText = 'Add Admin';
            this.viewMode = 'Company';
            this.getWorkspaceUserList();
            this.showProject = false;
        } else {
            this.buttonText = 'Invite to Project';
            this.addMemberText = 'Add Member';
            this.viewMode = 'Project';
            this.showProject = true;
            this.getAllPeopleListByProjectId();
        }
    }

    public ngOnInit(): void {
        
    }

    public ngOnDestroy(): any {}

    public getAllAdminList(): any {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: '',
            sortby: '',
            orderby: '',
        };
        this.loadingPage = true;
        this.workspaceService.getAllAdminList(payload).subscribe((res): any => {
            if (res && res.entity && res.entity.length > 0) {
                this.peopleListTemp = res.entity;
                for (let index = 0; index < this.peopleListTemp.length; index++) {
                    this.peopleListTemp[index].user = { firstName: '', profileImage: '' };
                    this.peopleListTemp[index].user.firstName = this.peopleListTemp[index].firstName;
                    this.peopleListTemp[index].user.profileImage = this.peopleListTemp[index].profileImage;
                    this.peopleListTemp[index].user.userStatus = this.peopleListTemp[index].userStatus;
                    this.peopleListTemp[index].user.email = this.peopleListTemp[index].email;
                    this.peopleListTemp[index].roleType = this.peopleListTemp[index].userType;

                    const groupBy = this.peopleListTemp[index].user.firstName;
                    this.peopleListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

                    if ((index + 1) === this.peopleListTemp.length) {
                        //this.peopleList = this.groupBy(this.peopleListTemp, 'groupBy');
                    }
                }
            } else {
                //this.peopleList = [];
            }
            this.loadingPage = false;
        }, (err): any => {
            this.loadingPage = false;
            this.peopleList = [];
            this.apis.handleError(err);
        });
    }


    public commonApiCalls(data): void {
        this.workspaceService.getAllPeopleListByProjectId(data).subscribe((res): any => {
            if (res && res.entity) {
                this.peopleList = []
                res.entity.forEach(element => {
                    if (!this.peopleList.find(item => item.user.id == element.user.id)) this.peopleList.push(element);
                });
                for (let index = 0; index < this.peopleListTemp.length; index++) {
                    const groupBy = this.peopleListTemp[index].user.firstName;
                    this.peopleListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

                    if ((index + 1) === this.peopleListTemp.length) {
                        // this.peopleList = this.groupBy(this.peopleListTemp, 'groupBy');
                    }
                }
            } else {
                this.peopleList = [];
            }
        });

        // this.workspaceService.getAllPeopleListByProjectId(data).subscribe((res): any => {
        //     if (res && res.entity) {
        //         this.peopleListTemp = res.entity;
        //         for (let index = 0; index < this.peopleListTemp.length; index++) {
        //             const groupBy = this.peopleListTemp[index].user.firstName;
        //             this.peopleListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

        //             if ((index + 1) === this.peopleListTemp.length) {
        //                 this.peopleList = this.groupBy(this.peopleListTemp, 'groupBy');
        //                 console.log(this.peopleList);
        //             }
        //         }
        //     } else {
        //         this.peopleList = [];
        //     }
        // });

        // this.workspaceService.getAllPeopleListByProjectId(data).subscribe((res): any => {
        //     if (res && res.entity) {
        //         this.peopleListTemp = res.entity;
        //         for (let index = 0; index < this.peopleListTemp.length; index++) {
        //             const groupBy = this.peopleListTemp[index].user.firstName;
        //             this.peopleListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

        //             if ((index + 1) === this.peopleListTemp.length) {
        //                 this.peopleList = this.groupBy(this.peopleListTemp, 'groupBy');
        //                 console.log(this.peopleList);
        //             }
        //         }
        //     } else {
        //         this.peopleList = [];
        //     }
        // });
    }

    public getSort(val): any {
        if (val === 1) {
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: this.workspaceService.getProjectId(),
                workSpaceName: sessionStorage.getItem('workspacename'),
                pagenumber: 0,
                pagerecord: 0,
                orderby: 'desc',
                sortby: 'firstName',
            };
            this.commonApiCalls(data);
        } else if (val === 2) {
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: this.workspaceService.getProjectId(),
                workSpaceName: sessionStorage.getItem('workspacename'),
                pagenumber: 0,
                pagerecord: 0,
                sortby: 'createdOn',
                orderby: 'desc',

            };
            this.commonApiCalls(data);
        } else {
            const data = {
                userId: localStorage.getItem('userId'),
                teamId: this.workspaceService.getProjectId(),
                workSpaceName: sessionStorage.getItem('workspacename'),
                pagenumber: 0,
                pagerecord: 0,
                sortby: 'createdOn',
                orderby: 'asc',

            };
            this.commonApiCalls(data);
        }
    }

    public getAllPeopleListByProjectId(): any {
        const currentdata = {
            userId: localStorage.getItem('userId'),
            teamId: this.workspaceService.getProjectId(),
            workSpaceName: sessionStorage.getItem('workspacename'),
            pagenumber: 0,
            pagerecord: 0,
        };
        this.workspaceService.getAllPeopleListByProjectId(currentdata).subscribe((res): any => {
            if (res && res.entity && res.entity.length > 0) {
                this.peopleList = []
                // this.peopleListTemp = res.entity;
                // console.log(this.peopleListTemp);
                res.entity.forEach(element => {
                    if (!this.peopleList.find(item => item.user.id == element.user.id)) this.peopleList.push(element);
                    // else {
                    //     this.peopleListTemp.forEach((element) => {
                    //         if (element.id !== localStorage.getItem('userId')) {
                    //             this.peopleList.push(element);
                    //         }
                    //     });
                    //     console.log(this.peopleList);                        
                    // }
                });
                // for (let indexs = 0; indexs < this.peopleListTemp.length; indexs++) {
                //     const groupBy = this.peopleListTemp[indexs].user.firstName;
                //     this.peopleListTemp[indexs].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

                //     if ((indexs + 1) === this.peopleListTemp.length) {
                //         this.peopleList = this.groupBy(this.peopleListTemp, 'groupBy');
                //     }
                // }
                console.log(this.peopleList);
                
            } else {
                this.peopleList = [];
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    public createBuilderForm(): any {
        this.inviteForm = this.inviteBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            roleType: ['', Validators.required],
            companyId: [''],
            email: ['', [Validators.required, Validators.pattern(environment.emailPattern)]],
            forceInvite: [true],
        });
    }

    public removeTrim(inviteForm) {
        this.inviteForm.get('email').setValue(inviteForm.value.email.trim());
    }

    public inviteMethod(inviteForm): any {
        this.submittedForm = true;
        if (inviteForm && inviteForm.status === 'VALID') {
            this.submitted = true;
            const data = inviteForm.getRawValue();
            data.userId = localStorage.getItem('userId');
            data.usertype = 1;
            data.workSpaceName = sessionStorage.getItem('workspacename');
            data.teamId = this.router.url === '/company/people'
                ? '' : this.workspaceService.getProjectId();
            if(data.forceInvite === true){
                this.forceInviteUser(data);
                this.submitted = false;
                return;
            }
            data.albumidList = [];

            if (!this.editInvites) {
                delete data.forceInvite;
                this.workspaceService.projectInvite(data, this.router.url === '/company/people'
                    ? 'pixlyProfile/inviteAsAdmin' : 'pixlyProfile/inviteAsFullMember').subscribe((res): any => {
                    if (res && res.status && res.status.status === 200) {
                        this.toastr.success('Invite mail sent');
                        this.commonSuccessFun();
                    } else {
                        this.toastr.error(res.status.msg);
                    }

                    this.submitted = false;
                }, (err): any => {
                    this.submitted = false;
                    this.apis.handleError(err);
                });
            } else {
                data.verificationId = this.currentEditData.id;
                this.workspaceService.projectInvite(data, 'pixlyProfile/editinvite').subscribe((res): any => {
                    if (res && res.status && res.status.status === 200) {
                        this.editInvites = false;
                        this.toastr.success(res.status.msg);
                        this.commonSuccessFun();
                    } else {
                        this.toastr.error(res.status.msg);
                    }
                    this.submitted = false;
                }, (errs): any => {
                    this.apis.handleError(errs);
                    this.submitted = false;
                });
            }
        }
    }
    forceInviteUser(data: any) {
        this.workspaceService.forceInviteUser(data).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                this.toastr.success(res.status.msg);
                this.showInviteBar = false;
                this.showPeopleMain = true;
                this.peopleListTemp = [];
                this.pendingUserList = [];
                this.peopleList = [];
                this.pendingUserListTemp = [];
                this.refreshUserList();
            } else {
                this.toastr.error(res.status.msg);
            }
            this.submitted = false;
        }, (errs): any => {
            this.apis.handleError(errs);
            this.submitted = false;
        });
    }

    public commonSuccessFun(): void {
        this.showInviteBar = false;
        this.showPeopleMain = false;
        this.peopleListTemp = [];
        this.pendingUserList = [];
        this.peopleList = [];
        this.pendingUserListTemp = [];
        if (this.router.url === '/company/people') {
            this.inviteAdminList();
        } else {
            this.invitePeopleList();
        }
    }

    public showPeople(): void {
        this.showPeopleMain = !this.showPeopleMain;
        if (!this.showPeopleMain) {
            if (this.router.url === '/company/people') {
                this.inviteAdminList();
            } else {
                this.invitePeopleList();
            }
        } else if (this.router.url === '/company/people') {
            this.getAllAdminList();
        } else {
            this.getAllPeopleListByProjectId();
        }
    }

    public refreshUserList(): void {
        if (this.router.url === '/company/people') {
            this.getWorkspaceUserList();
        } else {
            this.getAllPeopleListByProjectId();
        }
    }

    public inviteAdminList(): any {
        this.workspaceService.inviteAdminUser().subscribe((res): any => {
            if (res && res.entity) {
                this.pendingUserListTemp = res.entity;
                for (let index = 0; index < this.pendingUserListTemp.length; index++) {
                    const groupBy = this.pendingUserListTemp[index].recipient.firstName;
                    this.pendingUserListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

                    if ((index + 1) === this.pendingUserListTemp.length) {
                        this.pendingUserList = this.groupBy(this.pendingUserListTemp, 'groupBy');
                    }
                }
            } else {
                this.pendingUserList = [];
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    public invitePeopleList(): any {
        this.workspaceService.invitePendingUser().subscribe((res): any => {
            if (res && res.entity && res.entity.length > 0) {
                this.pendingUserListTemp = res.entity;
                for (let index = 0; index < this.pendingUserListTemp.length; index++) {
                    const groupBy = this.pendingUserListTemp[index].firstName;
                    this.pendingUserListTemp[index].groupBy = groupBy.toString().toUpperCase().substring(0, 1);

                    if ((index + 1) === this.pendingUserListTemp.length) {
                        this.pendingUserList = this.groupBy(this.pendingUserListTemp, 'groupBy');
                    }
                }
            } else {
                this.pendingUserList = [];
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    public groupBy(collection: any, property: string): any {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current): any => {
            if (!previous[current[property]]) {
                // eslint-disable-next-line no-param-reassign
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }
            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map((key): any => ({ key, value: groupedCollection[key] }));
    }

    public editInvite(u): void {
        this.editInvites = true;
        this.currentEditData = u;
        this.showInvite();
        this.inviteForm.get('firstName').patchValue(u.recipient.firstName);
        this.inviteForm.get('lastName').patchValue(u.recipient.lastName);
        this.inviteForm.get('email').patchValue(u.recipient.email);
        this.inviteForm.get('roleType').patchValue(u.recipientRole.id);
        this.inviteForm.get('companyId').patchValue(u.company !== null ? u.company.id : '');
    }

    public showInvite(isGuest = false): void {
        this.showInviteBar = !this.showInviteBar;
        this.submittedForm = false;
        if (this.showInviteBar) {
            this.getAllCompanyList();
            this.createBuilderForm();
        }
        if (this.userRoleList.length === 0 && this.showInviteBar) {
            this.workspaceService.getUserType().subscribe((res): any => {
                if (res && res.entity) {
                    for (let index = 0; index < res.entity.length; index++) {
                        const currentUrl = this.router.url.split('?')[0];
                        if (currentUrl === '/company/people') {
                            if (res.entity[index].id != 1) {
                                this.userRoleList.push(res.entity[index]);
                                this.inviteForm.get('roleType').setValue(res.entity[index].id);
                            }
                        } else if (currentUrl === '/project/people') {
                            if ((res.entity[index].id === 3 && this.userType !== '3') || res.entity[index].id === 4 || res.entity[index].id === 5) {
                                this.userRoleList.push(res.entity[index]);
                            }
                        }
                    }
                    this.setProjectViewerRole(isGuest);
                } else {
                    this.userRoleList = [];
                }
            }, (err): any => {
                this.apis.handleError(err);
            });
        } else {
            this.setProjectViewerRole(isGuest);
        }
    }

    setProjectViewerRole(isGuest: boolean) {
        if (isGuest) {
            this.inviteForm.get('roleType').setValue(5);
            setTimeout(() => {
                this.inviteForm.get('roleType').disable();
            }, 0);
        } else {
            setTimeout(() => {
                this.inviteForm.get('roleType').enable();
            }, 0);
        }
    }

    public showContact(): void {
        this.showContactBar = !this.showContactBar;
    }

    public openModal(membershipId, mi, i): any {
        this.membershipId = membershipId;
        this.index = i;
        this.mIndex = mi;
        this.modalRef = this.modalService.show(
            this.exampleModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public openAdminModal(membershipId, mi, i): any {
        this.membershipId = membershipId;
        this.index = i;
        this.mIndex = mi;
        this.modalRef = this.modalService.show(
            this.deleteAdminModal,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public yesMethod(): any {
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        this.workspaceService.removeUserFromProject(this.membershipId).subscribe((res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.toastr.success(res.status.msg);
                if (this.peopleList[this.mIndex].value.length > 1) {
                    this.peopleList[this.mIndex].value.splice(this.index, 1);
                } else {
                    this.peopleList.splice(this.mIndex, 1);
                }
                this.modalRef.hide();
            } else {
                this.modalRef.hide();
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.submitted = false;
            this.toastr.error(err.status.msg);
        });
    }

    public yesMethodAdmin(): any {
        this.submitted = true;
        // tslint:disable-next-line: max-line-length
        this.toastr.clear();
        this.workspaceService.removeAdminFromProject(this.membershipId).subscribe((ress): any => {
            this.submitted = false;
            if (ress && ress.status && ress.status.status === 200) {
                this.toastr.success(ress.status.msg);
                if (this.peopleList[this.mIndex].value.length > 1) {
                    this.peopleList[this.mIndex].value.splice(this.index, 1);
                } else {
                    this.peopleList.splice(this.mIndex, 1);
                }
                this.modalRef.hide();
            } else {
                this.modalRef.hide();
                this.toastr.error(ress.status.msg);
            }
        }, (errs): any => {
            this.toastr.error(errs.status.msg);
            this.submitted = false;
        });
    }

    public resendInvite(user): void {
        this.submitted = true;
        this.toastr.clear();
        const payload = {
            usertype: 1,
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            verificationId: user.id,
        };
        this.workspaceService.resendInviteApi(payload).subscribe(
            (res): any => {
                this.submitted = false;
                this.toastr.success(res.status.msg);
            },
            (err): any => {
                this.submitted = false;
                this.toastr.error(err.status.msg);
            },
        );
    }

    public openConfirmationModal(currentDeletePendingId): void {
        this.currentDeletePendingId = currentDeletePendingId;
        this.modalRef = this.modalService.show(
            this.deletependinginvite,
            Object.assign(this.config, { class: 'modal-sm popup-center' }),
        );
    }

    public deleteInvite(): void {
        this.submitted = true;
        this.toastr.clear();
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            verificationId: this.currentDeletePendingId,
        };
        this.workspaceService.deleteInviteApi(payload).subscribe(
            (res): any => {
                this.submitted = false;
                this.toastr.success(res.status.msg);
                this.modalRef.hide();
                this.invitePeopleList();
            },
            (err): any => {
                this.submitted = false;
                this.toastr.error(err.status.msg);
            },
        );
    }

    public getAllCompanyList(): void {
        this.companysList = [];
        this.toastr.clear();
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: '',
            pagenumber: '',
            pagerecord: '',
            sortParam: '',
            sortType: '',
        };
        this.submitted = true;
        this.workspaceService.searchCompanyApi(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.entity) {
                    this.companysList = res.entity;
                }
            },
            (err): any => {
                this.submitted = false;
                this.companysList = [];
                this.toastr.error(err.status.msg);
            },
        );
    }

    public getWorkspaceUserList(): void {
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: sessionStorage.getItem('workspacename'),
            search: '',
            sortby: '',
            orderby: '',
            pagenumber: this.pageNumber,
            pagerecord: this.pageRecord,
        };
        this.apis.getworkspaceUserList(payload).subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                res.entity.forEach((element) => {
                    this.peopleList.push(element);
                });
                console.log(this.peopleList);
            } else {
                this.stopScroll = false;
            }
        });
    }

    public isLoggedInUser(userId) {
        return userId === localStorage.getItem('userId');
    }

    public onScrollUser(): any {
        if (this.stopScroll) {
            this.pageNumber += 1;
            this.getWorkspaceUserList();
        }
    }

    public viewOtherProfile(people, page): any {
        if (!this.isLoggedInUser(this.getUserId(people, page))) {
            const queryParams = {
                userid: this.getUserId(people, page),
                url: page === 'company' ? 'company' : 'project',
                prevPage: `/${page}/people`
            };
            this.router.navigate(['/people-details'], { queryParams });
        }
    }

    public getUserId(people: any, page: string): string {
        return page === 'company' ? people.id : people.user.id;
    }

}