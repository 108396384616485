
<div id="overlay" [ngClass]="{'d-block': Invite}"></div>

<div #sidenav class="right-sidebar bg-white " [ngClass]="{'show-side-nav' : Invite}">


    <div class="d-flex justify-content-between">
        <h1 class="grey-g fs17 font-weight-bold ls-2 mb-3 ">Invite</h1>
        <em class="lnr lnr-cross close-icon fw-600 fs16 " (click)="ResetForm(ShareForm)"></em>
    </div>
    <div class="contact-scroll">
        <form class="mt-3 " autocomplete="off" name="ShareForm" (ngSubmit)="onSubmit(ShareForm)" #ShareForm="ngForm">
            <label class="fs12 grey-j ls-3">Name</label>
            <div class="row">
                <div class="col-6 pr-1">
                    <div class="form-group input-rounded">
                        <input type="text" name="firstName" pattern="^[A-Za-z]+$" required maxlength="20"
                            [(ngModel)]="Share.firstName" #firstName="ngModel"
                            [ngClass]="{ 'has-error': ShareForm.submitted && firstName.invalid }"
                            class="form-control br8 pt22 pb22 fw-600" placeholder="First Name" 
                            (keydown.space)="Share.firstName.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="firstName.invalid && validation">
                            <div *ngIf="firstName.errors.required" class="text-danger fs12">
                                First name is required.
                            </div>
                            <div *ngIf="firstName.errors.pattern" class="fs12 text-danger">
                                First name must contain only alphabets.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1">
                    <div class="form-group input-rounded">
                        <input type="text" name="lastName" pattern="^[A-Za-z]+$" required maxlength="20"
                            [(ngModel)]="Share.lastName" #lastName="ngModel"
                            [ngClass]="{ 'has-error': ShareForm.submitted && lastName.invalid }"
                            class="form-control br8 pt22 pb22 fw-600" placeholder="Last Name"
                            (keydown.space)="Share.lastName.trim().length > 0 ? '': $event.preventDefault();">
                        <div *ngIf="lastName.invalid && validation">
                            <div *ngIf="lastName.errors.required" class="text-danger fs12">
                                Last name is required.
                            </div>
                            <div *ngIf="lastName.errors.pattern" class="fs12 text-danger">
                                Last name must contain only alphabets.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j br8 ls-3">E-Mail</label>

                <input type="email" name="email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    [(ngModel)]="Share.email" #email="ngModel"
                    [ngClass]="{ 'has-error': ShareForm.submitted && email.invalid }"
                    class="form-control pt22 pb22 fw-600" placeholder="Enter E-mail"
                    (keydown.space)="Share.email.trim().length > 0 ? '': $event.preventDefault();">
                <div *ngIf="email.invalid && validation">
                    <div *ngIf="email.errors.required" class="text-danger fs12">
                        Email is required.
                    </div>
                    <div *ngIf="email.errors.pattern" class="fs12 text-danger">
                        Enter a valid email.
                    </div>
                </div>
            </div>
            <div class="form-group input-rounded">
                <label class="fs12 grey-j br8 ls-3">Time Limit</label>
                <input type="text" name="expires" required [(ngModel)]="Share.expires" #expires="ngModel"
                    [ngClass]="{ 'has-error': ShareForm.submitted && expires.invalid }" placeholder="Time limit"
                    class="form-control pt22 pb22 fw-600" bsDatepicker [bsConfig]="datePickerConfig" #dp="bsDatepicker">
                <div *ngIf="expires.invalid && validation">
                    <div *ngIf="expires.errors.required" class="text-danger fs12">
                        Expiry date is required.
                    </div>
                </div>
            </div>
            <div class="row mt50 mb-3 px-4">
                <div class="col-5 pr-1">
                    <button class="btn btn-grey-f text-black br25 pt7 pb7  btn-block fs13 fw-600 " type="button"
                        (click)="ResetForm(ShareForm)">Cancel</button>

                </div>
                <div class="col-7 pl-1">
                    <button class="btn btn-blue br25 pt7 pb7 btn-block fs13 fw-600 buttonload" [disabled]="submitted"><em
                        *ngIf="submitted" class="fa fa-spinner fa-spin"></em>Send Link</button>
                </div>
            </div>
        </form>
    </div>

</div>