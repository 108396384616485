<section class="main-container">
  <div class="sub-container">
    <div class="sub-content-container pt15">

      <div class="content-card todo-list">
        <div class="card-header bg-white border-0">
          <div class="d-flex align-items-center mt20">
            <h1 class="fw-600 fs20 ls-9 grey-g mb-0">To-do List</h1>
            <div class="flex-grow-1 text-right mr-2 mr-lg-3">
              <a (click)="getTodoListSort()">
                <p class="mb-0 ls-e fs12 grey-g font-weight-bold c-pointer"> {{DateText}}
                  <img src="./assets/images/up.svg" alt="sort" class="ml-2 w9 mb6 c-pointer">
                  <img src="./assets/images/down.svg" alt="sort" class=" w9 c-pointer">
                </p>
              </a>
            </div>

            <div dropdown class="tag-menu c-pointer">

              <img src="./assets/images/menu-horizontal.svg" alt="menu-horizontal" class="w23" id="basic-link"
              dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
              aria-labelledby="basic-link">
              <li>
                <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)"
                  [ngClass]="assignto === ''? 'blue-href': ''" (click)="getTodoListViewMode('')">All</a>
              </li>
              <li>
                <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)"
                  [ngClass]="assignto === 'onlyme'? 'blue-href': ''"
                  (click)="getTodoListViewMode('onlyme')">MyTask</a>
              </li>
            </ul>
            </div>
        </div>
        </div>
        <div class="card-body p-0">

          <ul class="nav nav-pills pills-primary px-3" id="pills-tab" role="tablist">
            <li class="nav-item mr-4" role="presentation">
              <a [class.active]="viewMode=='pending'" (click)="viewMode='pending'" class="nav-link  px-4"
                href="javascript:void(0)">Pending</a>
            </li>
            <li class="nav-item" role="presentation">
              <a [class.active]="viewMode=='completed'" (click)="viewMode='completed'" class="nav-link px-4"
                href="javascript:void(0)">Completed</a>
            </li>
          </ul>
          <div class="tab-content px-4" [ngSwitch]="viewMode">
            <div class="tab-pane fade show active" *ngSwitchCase="'pending'">
              <div 
                class="d-sm-flex  justify-content-between py-3  border-bottom-a text-center text-sm-left c-pointer" 
                *ngFor="let pending of pendingList" 
                [routerLink]="['/project/photo-tag']" 
                [queryParams]="conCadSessionData({albumId: pending.albumId, 
                                photoId: pending.photoId,
                                photoTagId: pending.photoTagid,
                                todoId: pending.toDoId, 
                                type: 2,
                                prevPage: 'project/todo-list'
                               })">
                <div class=" d-sm-flex align-items-center  mb-2 mb-sm-0">
                  <div>
                    <img *ngIf="pending && pending.photoUrl" src="{{cloudFrontURL}}{{pending.photoUrl}}" alt="todo" class="todo-img mr-3 my-3 my-sm-0">
                    <img *ngIf="!pending || !pending.photoUrl" src="./assets/images/empty-img.png" alt="todo" class="todo-img mr-3 my-3 my-sm-0">
                  </div>
                  <div>
                    <h2 class="grey-m fw-600 fs15">{{pending.tagName}}</h2>

                    <div class="status-common pb-1 d-inline-block mr-md-2 mx-sm-0">
                      <span class="grey-m fs11 fw-600 ">Assigned to</span><span
                        class="fw-600 grey-g  fs11 task-doneby">{{pending.assignedTo}}</span>
                    </div>
                    <div class="status-common pb-1 d-inline-block mx-auto mx-sm-0">
                      <span class="grey-m fs11 fw-600 ">Assigned by</span><span
                        class="fw-600 grey-g  fs11 task-doneby">{{pending.assignedBy}}</span>
                    </div>
                  </div>
                  
                </div>
                
                <div class="text-sm-right py-2">
                  <p *ngIf="datePipe.transform(pending.createdOn, 'yyyy-MM-dd') === datePipe.transform(date, 'yyyy-MM-dd')" class="grey-r mb-0 fs10 ">{{getDateValue(pending.createdOn) | amTimeAgo }}</p>
                  <p *ngIf="datePipe.transform(pending.createdOn, 'yyyy-MM-dd') !== datePipe.transform(date, 'yyyy-MM-dd')" class="grey-r mb-0 fs10 ">{{getDateValue(pending.createdOn) | amDateFormat: 'MM/DD/YYYY hh:mm A' }}</p>
                </div>

              </div>

              <div class="card-body text-center p-3 " *ngIf="loadingPage || pendingList.length === 0">

                <div class="page-loaders text-center h-600px my-5" *ngIf="loadingPage">
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="45"/>
                    </svg>
                    <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
                </div>

                  <div class="pending-invites-bg"  *ngIf="!loadingPage && pendingList.length === 0">
                      <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                      <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no pending Todo List</p>
                  </div>
              </div>
            </div>
            <div class="tab-pane fade show active" *ngSwitchCase="'completed'">
              <div 
                class="d-sm-flex  justify-content-between py-3  border-bottom-a text-center text-sm-left c-pointer" 
                *ngFor="let completed of completedList" 
                [routerLink]="['/project/photo-tag']" 
                [queryParams]="conCadSessionData({
                                albumId: completed.albumId, 
                                photoId: completed.photoId,
                                todoId: completed.toDoId, 
                                type: 1,
                                photoTagid: completed.photoTagid,
                                prevPage: 'project/todo-list'
                              })">

                <div class="d-sm-flex align-items-center mb-2 mb-sm-0">
                  <div class="todo-img-completed mx-auto  mr-md-3">
                    <img src="{{cloudFrontURL}}{{completed.photoUrl}}" alt="todo" class="todo-primary-img">

                    <img src="./assets/images/tick-rounded.svg" alt="tick-todo" class="completed-tick ">

                  </div>
                  <div>
                    <h2 class="grey-m fw-600 fs15">{{completed.tagName}}</h2>


                    <div class="status-common pb-1 d-inline-block mx-auto mx-sm-0">
                      <span class="grey-m fs11 fw-600 ">Assigned by</span><span
                        class="fw-600 grey-g  fs11 task-doneby">{{completed.assignedBy}}</span>
                    </div>

                    <div class="status-common pb-1 d-inline-block mx-auto mx-sm-0" *ngIf="completed.completed">
                      <span class="grey-m fs11 fw-600 ">Completed by</span><span
                        class="fw-600 grey-g  fs11 task-doneby">{{ completed.completedBy}}</span>
                    </div>

                  </div>

                </div>
                
                <div class="py-2 text-sm-right">
                  <p class="grey-r fs10 fw-600 mb-0">{{getDateValue(completed.updatedOn) | date: 'MMMM d, y h:mm a'}}</p>

                </div>

              </div>

              <div class="card-body text-center p-3 " *ngIf="loadingPage || completedList.length === 0">

                <div class="page-loaders text-center h-600px my-5" *ngIf="loadingPage">
                  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="45"/>
                  </svg>
                  <p class="my-3 fw-600 fs20 ls-9 grey-g mb-0 ml-2">Please Wait...</p>
              </div>

                <div class="pending-invites-bg" *ngIf="!loadingPage && completedList.length === 0">
                    <h1 class="fs20 font-weight-bold text-black mt60">It's a bit empty here</h1>
                    <p class="grey-g fw-600 fs13 mt-3 mb-5">There is no completed Todo List</p>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- modal -->
<ng-template #template>

  <div class="modal-body p-0">

    <div class="modal-main-content">


      <div class="d-flex align-items-center py-3 px-3 justify-content-between">
        <div class="bg-white br15 w22 h22 text-center c-pointer" (click)="modalRef.hide()">
          <img src='./assets/images/arrow-right-dark.svg' alt="arrow-right-dark" class="w10 mb3">
        </div>
        <div class="d-flex">
          <img src="./assets/images/tags-filter.svg" alt="dark-menu" (click)="showTags()"
            class="w20 show-modal-sidebar c-pointer mr-3">
          
         
          <div dropdown class="tag-menu c-pointer">
            <div class="menu-dark-modal c-pointer" id="basic-link"
            dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
              <img src="./assets/images/Group 8842.svg" alt="dark-menu" class="w17 mb12 ">
            </div>
            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
              <li >
                <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)">Todolist</a>
                <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)">Todolist</a>
    
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dark-img-content">
        <img src="./assets/images/user1.png" alt="dark-img" class="dark-img">

      </div>
      <div class="pl-3 my-4 carousel-secondry">
        <owl-carousel-o [options]="customOptions" class="w-100">
          <ng-template carouselSlide>
            <img src="./assets/images/user1.png" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house1.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">

          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house2.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/user1.png" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house1.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">

          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house2.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/user1.png" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house1.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">

          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house2.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/user1.png" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house1.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">

          </ng-template>
          <ng-template carouselSlide>
            <img src="./assets/images/house2.jpg" alt="dark-img" class="dark-img w100 h100 br10 object-fit-cover">
          </ng-template>
        </owl-carousel-o>
      </div>



    </div>

  </div>
  <div #sidenav class="modal-sidebar" [ngClass]="{'show-modal-sidebar' : tagShow  }">
    <div class="grey-z">
      <em class="lnr lnr-cross text-white modal-sidebar-close fw-600 fs16 p-2" (click)="showTags()"></em>

    </div>
    <div *ngIf="getNext">
      <ul class="nav nav-pills pills-modal grey-z px-5 d-flex justify-content-between pt-3 mb-4" id="pills-tab"
        role="tablist">
        <li class="nav-item " role="presentation">
          <a [class.active]="viewMode1=='tagFilter'" (click)="viewMode1='tagFilter'" class="nav-link ls-d fs13 "
            href="javascript:void(0)">
            <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg" alt="grid-active">
            <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg" alt="grid-inactive">
            Tag Filter</a>
        </li>
        <li class="nav-item" role="presentation">
          <a [class.active]="viewMode1=='tags'" (click)="viewMode1='tags'" class="ls-d nav-link fs13 "
            href="javascript:void(0)">
            <img class="active-img w14 mr-1" src="./assets/images/tags-filter.svg" alt="grid-active">
            <img class="in-active-img w14 mr-1" src="./assets/images/tags-inactive.svg" alt="grid-inactive">
            Tags</a>
        </li>

      </ul>
      <div class="tab-content pb-3 px-4" [ngSwitch]="viewMode1">
        <div class="tab-pane fade show active" *ngSwitchCase="'tagFilter'">
          <div class="contact-scroll">



            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types w19 h19 pink-a">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa ls-d  fs14 mb-0 fw-500">Comments</p>
              </div>
              <div class="custom-switchtoggle">
                <ui-switch size="small"></ui-switch>
              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types  w19 h19 yellow-a">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa ls-d  fs14 mb-0 fw-500">Task</p>
              </div>
              <div class="custom-switchtoggle">
                <ui-switch size="small"></ui-switch>
              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types  w19 h19 blue-f">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa  ls-d fs14 mb-0 fw-500">Hot Potato</p>
              </div>
              <div class="custom-switchtoggle">
                <ui-switch size="small"></ui-switch>
              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <img src="./assets/images/love-tag.svg" alt="love-tag" class="w19">
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa  ls-d fs14 mb-0 fw-500">Love</p>
              </div>
              <div class="custom-switchtoggle">
                <ui-switch size="small"></ui-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active" *ngSwitchCase="'tags'">
          <div class="contact-scroll">



            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types w19 h19 pink-a">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa ls-d  fs14 mb-0 fw-500">Comments</p>
              </div>
              <div class="grey-z h22 w22 br15 text-center" (click)="showNext()">
                <img src="./assets/images/right-chevron.svg" alt="right-chevron" class="w5 ml2 mb2">

              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types  w19 h19 yellow-a">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa ls-d  fs14 mb-0 fw-500">Task</p>
              </div>
              <div class="grey-z h22 w22 br15 text-center">
                <img src="./assets/images/right-chevron.svg" alt="right-chevron" class="w5 ml2 mb2">

              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <div class="tag-types  w19 h19 blue-f">

              </div>
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa  ls-d fs14 mb-0 fw-500">Hot Potato</p>
              </div>
              <div class="grey-z h22 w22 br15 text-center">
                <img src="./assets/images/right-chevron.svg" alt="right-chevron" class="w5 ml2 mb2">

              </div>
            </div>
            <div class="d-flex pt10 pb10 align-items-center">

              <img src="./assets/images/love-tag.svg" alt="love-tag" class="w19">
              <div class="flex-grow-1 ml-3">
                <p class="grey-aa  ls-d fs14 mb-0 fw-500">Love</p>
              </div>
              <div class="grey-z h22 w22 br15 text-center">
                <img src="./assets/images/right-chevron.svg" alt="right-chevron" class="w5 ml2 mb2">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!getNext">
      <div class="d-flex align-items-center px-3 py-3 justify-content-between grey-z">
        <div class="grey-y w22 h22 br15 text-center c-pointer" (click)="showNext()">
          <img src="./assets/images/white-left-arrow.svg" alt="white-left-arrow" class="w11 mb6">
        </div>
        <div>
          <img src="./assets/images/white-edit.svg" alt="white-edit" class="w13 mr-3">
          <img src="./assets/images/white-delete.svg" alt="white-delete" class="w13 ">
        </div>
      </div>

      <ul class="nav nav-pills pills-modal grey-z  d-flex justify-content-between pt-3 mb-4" id="pills-tab"
        role="tablist">
        <li class="nav-item " role="presentation">
          <a [class.active]="viewMode2=='Comments'" (click)="viewMode2='Comments'" class="nav-link ls-d fs13 "
            href="javascript:void(0)">
            Comments</a>
        </li>
        <li class="nav-item" role="presentation">
          <a [class.active]="viewMode2=='To-do'" (click)="viewMode2='To-do'" class="ls-d nav-link fs13 "
            href="javascript:void(0)">
            To-do</a>
        </li>
        <li class="nav-item" role="presentation">
          <a [class.active]="viewMode2=='Photos'" (click)="viewMode2='Photos'" class="ls-d nav-link fs13 "
            href="javascript:void(0)">
            Photos</a>
        </li>

      </ul>
      <div class="tab-content pb-3 " [ngSwitch]="viewMode2">
        <div class="tab-pane fade show px-3 active" *ngSwitchCase="'Comments'">
          <div class="dark-sidebar-scroll">
            <div class="border-grey-i mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
                  <h2 class="font-weight-bold fs14 grey-e mb-0">Jessie</h2>
                </div>
                <div>
                  <p class="mb-0 grey-l fs11">4d ago</p>
                </div>
              </div>
              <p class="fw-500 mb-0 fs14 grey-e pl-5 my-2">The plaster is completely ruined. Find is wherewater is
                infiltrating before start repairing the wall.</p>
            </div>
            <div class="border-grey-i mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
                  <h2 class="font-weight-bold fs14 grey-e mb-0">Jessie</h2>
                </div>
                <div>
                  <p class="mb-0 grey-l fs11">4d ago</p>
                </div>
              </div>
              <p class="fw-500 mb-0 fs14 grey-e pl-5 my-2">The plaster is completely ruined. Find is wherewater is
                infiltrating before start repairing the wall.</p>
            </div>
          </div>
          <form class="dark-input mb-3">
            <div class="input-group dark-input-rounded mb-0 c-pointer">
              <input type="text" class="form-control fw-500 py-2 border-right-0" placeholder="Type Your Comments">
              <div class="input-group-append">
                <span class="input-group-text border-left-0">
                  <img src="./assets/images/mike.svg" alt="search" class="w10">
                </span>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane fade show active" *ngSwitchCase="'To-do'">

        </div>
        <div class="tab-pane fade show active" *ngSwitchCase="'Photos'">

        </div>
      </div>
    </div>


  </div>
</ng-template>

<!-- completed to-dolist modal -->
<ng-template #completedModal>

  <div class="modal-body p-0">

    <div class="modal-main-content">


      <div class="d-flex align-items-center py-3 px-3 justify-content-between">
        <div class="bg-white br15 w22 h22 text-center c-pointer" (click)="modalRef1.hide()">
          <img src='./assets/images/arrow-right-dark.svg' alt="arrow-right-dark" class="w10 mb3">
        </div>
        <div class="d-flex">
          <img src="./assets/images/tags-filter.svg" alt="dark-menu" (click)="showTags()"
            class="w20 show-modal-sidebar c-pointer mr-3">
          
        </div>
      </div>
      <div class="dark-img-content mb-5">
        <img src="./assets/images/user1.png" alt="dark-img" class="dark-img">

      </div>
    </div>

  </div>
  <div #sidenav class="modal-sidebar" [ngClass]="{'show-modal-sidebar' : tagShow  }">
    <div class="grey-z">
      <em class="lnr lnr-cross text-white modal-sidebar-close fw-600 fs16 p-2" (click)="showTags()"></em>

    </div>
    <div class="d-flex justify-content-between grey-z align-items-center px-3 py-2">
      <div>
        <h2 class="text-white ls-g fs15 fw-500 mb-0">To-do List</h2>

      </div>
      <div dropdown class="tag-menu c-pointer">
        <img src="./assets/images/dark-menu.svg" alt="dark-menu" class="w23 c-pointer" id="basic-link"
          dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
        <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
          <li >
            <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)">Todolist</a>
            <a class="dropdown-item text-black fs13 fw-600 mb-1" href="javascript:void(0)">Todolist</a>

          </li>
        </ul>
      </div>
    </div>
    

    
    <div class="d-flex grey-z align-items-center py-3 px-4">
      <div class="tag-types w19 h19 pink-a">
   
      </div>
      <div class="flex-grow-1 ml-3">
        <p class="grey-aa fs14 mb-0 fw-600">Massive Repair</p>
      </div>
    </div>
    <p class="fs12 fw-600 grey-e mb-0 py-3 px-4 ">The plaster is completely ruined.<br>
       Find where water is infiltrating before start repairing the wall</p>
       <div class="dark-completed-scroll">
    <div class="grey-z py-3 px-4 ">
      <h2 class="fw-600 grey-aa fs14 mb-0">Assigned</h2>
    </div>
    <div class="mb-2">
      <div class="d-flex align-items-center py-2 px-4">
        <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
        <h2 class="fw-600 fs12 grey-e mb-0">Jessie</h2>
      </div>
      <div class="d-flex align-items-center py-2 px-4">
        <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
        <h2 class="fw-600 fs12 grey-e mb-0">Jessie</h2>
      </div>
    </div>
    <div class="grey-z py-3 px-4 ">
      <h2 class="fw-600 grey-aa fs14 mb-0">Completed</h2>
    </div>
    <div class="mb-2">
      <div class="d-flex align-items-center py-2 px-4">
        <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
        <h2 class="fw-600 fs12 grey-e mb-0">Jessie</h2>
      </div>
      <div class="d-flex align-items-center py-2 px-4">
        <img src="./assets/images/user1.png" alt="comments" class="w30 h30 br20 object-fit-cover mr-3">
        <h2 class="fw-600 fs12 grey-e mb-0">Jessie</h2>
      </div>
    </div>
  </div>
  </div>
</ng-template>