<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 col-lg-2 col-sm-4">
             <p class="grey-h fs16 fw-600 mb-1">Help</p>
             <ul class="list-inline mb-0">
                 <li class="list-inline-item footer-text fs12 pr-2">Documentation</li>
                 <li class="list-inline-item footer-text fs12">Contact us</li>
             </ul>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-3 mt-3 mt-sm-0 mt-md-0">
                <p class="grey-h fs16 fw-600 mb-1">Legal</p>
                <ul class="list-inline mb-0">
                    <li class="list-inline-item footer-text fs12">Pixly Policies</li>
                </ul>
            </div>
            <div class="col-md-7 col-lg-8 text-md-right mt-md-4 col-sm-5 mt-sm-4 mt-3 ">
                <p class="footer-text fs12">Copyright {{dateNow | date:'yyyy'}} All rights reserved</p>
            </div>
        </div>
    </div>
    

</footer>