<header class="secondry-header"> 
<nav class="navbar  navbar-expand-lg sticky-top">
    <a class="navbar-brand" routerLink="/login"><img src="./assets/images/Logo_PNG.png" alt="logo"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="showNav()"  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <em class="fa fa-bars"></em>
        </button>
    <div class="collapse  navbar-collapse" [ngClass]="{'show' : isFocused}">
      <ul class=" ml-md-auto my-auto navbar-nav "  >
        <li class="nav-item pr-md-5 cross-show">
        <em class="lnr lnr-cross close-icon fw-600 fs16 ml-auto nav-link" (click)="showNav()"></em>
        </li>
        <li *ngIf="loginUser" class="nav-item pr-md-4 my-auto">
          <a class="create-acc fs12 px-3 nav-link text-center c-pointer" (click)="navigateLink()">{{btntext}}</a>
        </li>
       
        <li *ngIf="!loginUser" class="nav-item pr-md-4 my-auto">
          <a class="create-acc fs12 px-3 nav-link text-center c-pointer" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</header>