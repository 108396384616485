import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { SupportService } from '../services/support.service';
import { ApiService } from '../services/api_base/api.service';
import { UserService } from '../services/user.service';
import { GeolocationService } from '../services/geolocation.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
    selector: 'app-layout-workspace',
    templateUrl: './layout-workspace.component.html',
    styles: [`
      
      .input-container {
        position: relative;
      }
      
      .predictions {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        font-size: 13px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
      }
      
      .prediction-item {
        padding: 8px;
        cursor: pointer;
      }
      
      .prediction-item:hover {
        background-color: #f0f0f0;
      }
      .txt-font {
        font-size: 13px;
      }
      .win-height {
        height: 75vh;
        overflow: auto;
      }
      }
      .scroll-content {
        max-height: 72vh !important;
      }
      `]
})

export class LayoutWorkspaceComponent implements OnInit {

    @Input() public toggleWS: boolean;

    @Output() public workspacetoggle = new EventEmitter<boolean>();

    public submitted = false;

    public showCreate = false;

    public workspaceOrProject = false;

    public getWorkspace = [];

    public workspacename = '';

    public workspaceForm: FormGroup;

    public createWorkspace: any = {};

    public createProject: any = {};

    public projectForm: FormGroup;

    public showProjectCreate = false;

    public getProject = [];

    @Input() public workspaceObject: any;

    public tempUserType = '';

    public tempWorkspace = '';

    @Input() public ownerworkspace: any;

    public latitude: any;

    public longitude: any;

    public address: any;

    predictions: any[] = [];

    selectedPlace: any = null;
    
    selectedPlaceText: any = '';

    filteredWrkspace = []

    searchProject = ''

    filteredProjects = [];

    public companyName ='';

    public addAllMembers: boolean = false;

    public constructor(private toastr: ToastrService, private router: Router,
        private workspaceService: WorkspaceServiceService, private support: SupportService, private apis: ApiService,
        private userService: UserService, private geolocationService: GeolocationService) {}

    public ngOnInit(): void {
        this.workspacename = sessionStorage.getItem('workspacename');
        this.getLocation();
        if (this.workspaceObject) {
            this.filteredWrkspace = this.workspaceObject
        }
    }

    filterProjects() {
        if (this.searchProject === '') {
            this.filteredProjects = this.getProject; // Show all projects when the search is empty
        } else {
            this.filteredProjects = this.getProject.filter(project =>
                project.teamName.toLowerCase().includes(this.searchProject.toLowerCase())
            );
        }
    }

    public setValue(data): void {
        this.workspaceOrProject = false;
        if (data && data.length > 0) {
            this.getWorkspace = data;
            this.showCreate = false;
        } else {
            this.showCreate = true;
        }
    }

    public toggleWorkSpaceMethod(): any {
        this.workspacetoggle.emit(false);
    }

    public ToggleWorkSpace1(): any {
        this.showCreate = true;
        this.getLocation()
    }

    public getFirstAndLastLetter(name): string {
        let value = 'WS';
        if (name) {
            const split = Array.from(name);
            value = `${split[0].toString().toUpperCase()}${split[split.length - 1].toString().toUpperCase()}`;
        }
        return value;
    }

    public selectWorkSpace1(workspace): void {
        if (workspace.masterTenant.dbName === sessionStorage.getItem('workspacename')) {
            return;
        }
        if (workspace.masterTenant.tenantId) {
            localStorage.setItem('tenantId', workspace.masterTenant.tenantId)
        }
        localStorage.removeItem('companyName');
        if (workspace.masterTenant.companyName) {
            localStorage.setItem('companyName', workspace.masterTenant.companyName);
        }
        if (workspace && workspace.masterTenant && !this.submitted) {
            this.submitted = true;
            this.createWorkspace.workSpaceName = workspace.masterTenant.dbName;
            this.tempWorkspace = workspace.masterTenant.dbName;
            this.getUserType('select');
        }
    }

    public getUserType(value): any {
        this.workspaceService.getUserRoleType('', this.tempWorkspace).subscribe((res): any => {
            if (res && res.entity) {
                if (res.entity && res.entity.roleId && (res.entity.roleId === 1 || res.entity.roleId === 2)) {
                    if (value === 'create') {
                        this.tempUserType = res.entity.roleId;
                        this.getAllProjects();
                    } else {
                        this.submitted = false;
                        sessionStorage.setItem('usertype', res.entity.roleId);
                        sessionStorage.setItem('workspacename', this.tempWorkspace);
                        this.getCount();
                        this.createWorkspace.workSpaceName = '';
                        this.tempWorkspace = '';
                        this.support.usertype.next(res.entity.roleId);
                        if (this.router.url === '/company/dashboard') {
                            this.support.workspaceChange.next(sessionStorage.getItem('workspacename'));
                            this.support.allProject.next('all');
                        } else {
                            this.router.navigate(['company/dashboard'], { queryParams: this.apis.getSessionData() });
                            this.support.allProject.next('all');
                        }
                        this.toggleWorkSpaceMethod();
                    }
                } else {
                    this.tempUserType = '';
                    this.getAllProjects();
                }
            }
        });
    }

    public ToggleProject(): any {
        this.showProjectCreate = true;
        if (localStorage.getItem('companyName')) {
            this.companyName = localStorage.getItem('companyName');
        }
    }

    public chooseProject(project): void {
        if (project) {
            this.submitted = true;
            sessionStorage.setItem('projectId', project.teamId);
            sessionStorage.setItem('workspacename', this.tempWorkspace);
            this.getCount();
            if (this.router.url !== '/project/dashboard') {
                this.router.navigate(['/project/dashboard'], { queryParams: this.apis.getSessionData() });
            }

            this.support.allProject.next('all');
            this.support.projectChange.next(project.teamId);
            this.toggleWorkSpaceMethod();

            this.submitted = false;
        }
    }

    public getAllProjects(): void {
        const queryParams = `?userId=${localStorage.getItem('userId')}&workSpaceName=${this.tempWorkspace}`;
        this.workspaceService.getAllProject(queryParams).subscribe((res): any => {
            this.workspaceOrProject = true;
            this.submitted = false;
            this.showCreate = false;
            if (res.entity === null) {
                this.showProjectCreate = true;
                this.filteredProjects = [];
            } else {
                this.getProject = res.entity;
                this.filteredProjects = this.getProject;
                this.showProjectCreate = false;
            }
        }, (err): any => {
            this.submitted = false;
            this.workspaceOrProject = true;
            this.apis.handleError(err);
        });
    }

    public onSubmit(WorkspaceForm: any): void {
        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: this.createWorkspace.workSpaceName
        };
        this.toastr.clear();
         // Flag to track whether the API response is received within 2 seconds
         let responseReceivedWithin2Seconds = false;

         // Show the "hold on" message after 2 seconds
         const holdOnMessageTimeout = setTimeout(() => {
             if (!responseReceivedWithin2Seconds) {
                 this.toastr.success('Your workspace is being created. Please hold on!');
             }
         }, 1000);
        this.workspaceService.addWorkspace(payload).subscribe(
            (res): any => {
                responseReceivedWithin2Seconds = true; // API response received within 2 seconds
                clearTimeout(holdOnMessageTimeout); // Clear the "hold on" message timeout
                this.toastr.clear(); // Clear any existing toastr messages
                if (res && res.status && res.status.status === 200) {
                    this.tempWorkspace = res.entity.dbName;
                    localStorage.setItem('tenantId', res.entity.tenantId)
                    this.toastr.success('Workspace created');
                    this.getUserType('create');
                } else {
                    this.submitted = false;
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public closeProject(): void {
        this.workspaceOrProject = false;
        this.getallWorkspace();
        this.filteredProjects = this.getProject;

    }

    public getallWorkspace(): void {
        this.workspaceService.getAllWorkspace(0, 0).subscribe(
            (res): any => {
                if (res && res.entity) {
                    this.getWorkspace = res.entity;
                    this.filteredWrkspace = this.getWorkspace;
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.apis.handleError(err);
            },
        );
    }

    public createProjectMethod(projectForm: any): void {
        this.submitted = true;
        const payload = {
            userId: localStorage.getItem('userId'),
            workSpaceName: this.tempWorkspace,
            teamName: this.createProject.teamName,
            lattitude: this.latitude,
            longitude: this.longitude,
            address: this.selectedPlaceText,
            addAllMembers: this.addAllMembers
        };
        this.toastr.clear();
        this.workspaceService.addProject(payload).subscribe(
            (res): any => {
                this.submitted = false;
                if (res && res.status && res.status.status === 200) {
                    this.createProject.teamName = '';
                    this.toastr.success('Project created successfully');
                    sessionStorage.setItem('projectId', res.entity.teamId);
                    this.toggleWorkSpaceMethod();
                    if (this.tempUserType) {
                        sessionStorage.setItem('usertype', this.tempUserType);
                        this.support.usertype.next(+this.tempUserType);
                        sessionStorage.setItem('workspacename', this.tempWorkspace);
                        this.getCount();
                        if (this.router.url !== '/company/dashboard') {
                            this.router.navigate(['/company/dashboard'], { queryParams: this.apis.getSessionData() });
                        }

                        this.support.workspaceChange.next(sessionStorage.getItem('workspacename'));
                        this.support.allProject.next('all');

                    } else {
                        if (this.router.url !== '/project/dashboard') {
                            this.router.navigate(['/project/dashboard'], { queryParams: this.apis.getSessionData() });
                        }
                        this.support.projectChange.next(res.entity.teamId);
                    }
                } else {
                    this.toastr.error(res.status.msg);
                }
            },
            (err): any => {
                this.submitted = false;
                this.apis.handleError(err);
            },
        );
    }

    public getCount(): void {
        this.userService.getCount().subscribe((res): any => {
            if (res && res.status && res.status.status === 200) {
                if ((sessionStorage.getItem('subscription') === '1' ? 'BASIC' : 'PRO') !== res.entity.currentPlan) {
                    if (res.entity.currentPlan === 'PRO') {
                        sessionStorage.setItem('subscription', '2');
                        this.support.subscription.next(2);
                    } else {
                        sessionStorage.setItem('subscription', '1');
                        this.support.subscription.next(1);
                    }
                }
            } else {
                this.toastr.error(res.status.msg);
            }
        }, (err): any => {
            this.apis.handleError(err);
        });
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                if (position && position.coords.latitude && position.coords.longitude) {
                    this.geolocationService.reverseGeocode(position.coords.latitude, position.coords.longitude).then(address => {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude
                        this.selectedPlaceText = address
                    })
                }
            },
                (error: any) => console.log(error));
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    async onSearch(query: string) {
        if (query.trim().length > 0) {
          try {
            this.predictions = await this.geolocationService.searchPlaces(query);
          } catch (error) {
            console.error('Error fetching predictions:', error);
          }
        } else {
          this.predictions = [];
        }
      }
    
      async onPlaceSelected(prediction: any) {
        try {
          this.selectedPlace = await this.geolocationService.getPlaceDetails(prediction.place_id);
          this.selectedPlaceText = prediction.description;
          // Get latitude and longitude
          if (this.selectedPlace.geometry && this.selectedPlace.geometry.location) {
            this.latitude = this.selectedPlace.geometry.location.lat();
            this.longitude = this.selectedPlace.geometry.location.lng();
          }
          this.predictions = [];
        } catch (error) {
          console.error('Error fetching place details:', error);
        }
      }

    filterWorkspaces(searchTerm: string): void {
        if (!searchTerm) {
          this.filteredWrkspace = this.workspaceObject;
        } else {
          this.filteredWrkspace = this.workspaceObject.filter(workspace =>
            workspace.masterTenant.workSpaceName.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
    }  

    // shouldShowButton(): boolean {
    //     const userEmail = localStorage.getItem('email');
    
    //     return (
    //         (this.isGetCarneraUser() && !this.isClientUser() && this.isBathSaverUser()) ||
    //         (this.isClientUser() && !this.isGetCarneraUser() && (this.isAllowedClientUser() || this.isKitchenSaverUser() || this.isBathSaverUser()))
    //     );
    // }

    shouldShowButton(): boolean {
        const userEmail = localStorage.getItem('email');
        const userType = sessionStorage.getItem('usertype');
        if (userType === '5') {
            return false;
        }
    
        const isAllowedClientUser = this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    
        return (
            isAllowedClientUser ||   
            (userEmail && !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !userEmail.toLowerCase().endsWith('@bathsaver.com'))
        );
    }
    
    isClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.isClientUser(userEmail);
    }
    
    isGetCarneraUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !userEmail.toLowerCase().endsWith('@bathsaver.com');
    }
    
    isKitchenSaverUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return userEmail.toLowerCase().endsWith('@kitchensaver.com');
    }
    
    isBathSaverUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return userEmail.toLowerCase().endsWith('@bathsaver.com');
    }
    
    isAllowedClientUser(): boolean {
        const userEmail = localStorage.getItem('email');
        return this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
    }

      onSearchInput(searchValue: string): void {
        from([searchValue])
        .pipe(debounceTime(300))
        .subscribe((debouncedValue: string) => {
            this.searchProject = debouncedValue;
            this.filterProjects();
        });
    }
}
