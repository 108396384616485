// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiBaseUrl: 'https://gateway.pixly.ai/pixlyGateway/',
    // apiBaseUrl: 'http://52.55.86.111:8001/pixlyGateway/',
    // apiBaseUrl: 'http://localhost:9991/',
    // apiBaseUrl: 'http://44.241.33.220:8001/pixlyGateway',
    kAccessKey: 'AKIAQFEFVNT5NGM77PUZ',
    KsecretKey: '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',
    KRegion: 'us_west_2',
    kIMAGEBUCKET: 'pixlyprod',
    KInsourceBucketUrl: 'http://d4381dmmtr1wk.cloudfront.net/',

    loginContext: 'pixlyLogin',
    emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    firebase: {
        apiKey: 'AIzaSyC6jduF_2sFOCy12lPAby1nmB5XLaK7FV8',
        authDomain: 'angular-admin-fcb8b.firebaseapp.com',
        databaseURL: 'https://angular-admin-fcb8b.firebaseio.com',
        projectId: 'angular-admin-fcb8b',
        storageBucket: 'angular-admin-fcb8b.appspot.com',
        messagingSenderId: '935327598299',
        appId: '1:935327598299:web:489ea772ad3845d5486efe',
        measurementId: 'G-KP2Y7TS4H8',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.