import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceServiceService } from 'src/app/services/workspace-service.service';
import { ApiService } from '../../services/api_base/api.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-workspace-project',
  templateUrl: './workspace-project.component.html',
  styleUrls: ['./workspace-project.component.scss']
})
export class WorkspaceProjectComponent implements OnInit {

  public toggleWS: boolean = false;

  public projectToggle: boolean = false;

  public workspaceToggle: boolean = false;

  public submitted : boolean = false;

  public userName = localStorage.getItem('username');

  public workspacename = '';

  public workspaceForm: FormGroup;

  public createWorkspace: any = {};

  public createProject: any = {};

  public projectForm: FormGroup;

  public tempWorkspace = '';

  public datas: any;

  public workspacePendingList = [];

  public filterSidemenu : boolean = false;

  constructor(private toastr: ToastrService, private router: Router, private workspaceService: WorkspaceServiceService,
    private api: ApiService, private userService: UserService, private activeRoute: ActivatedRoute, private apis: ApiService,) { 
      this.getAllPendingInvites();
      this.activeRoute.queryParams.subscribe((params): any => {
      this.userName = params.name
    });
  }

  ngOnInit(): void {
  }

  public getAllPendingInvites(): void {
    this.userService.getAllPendingList().subscribe((res): any => {
        if (res && res.status && res.status.status === 200 && res.entity && res.entity.length > 0) {
            this.workspacePendingList = res.entity;
            for (let i = 0; i < this.workspacePendingList.length; i++) {
                for (let j = 0; j < this.workspacePendingList[i].pendingList.length; j++) {
                    this.workspacePendingList[i].pendingList[j].rejected = false;
                    this.workspacePendingList[i].pendingList[j].accepted = false;
                    this.workspacePendingList[i].pendingList[j].acceptreject = true;
                }
                if (this.workspacePendingList.length === (i + 1)) {
                  this.filterSidemenu = !this.filterSidemenu;
                }
            }
        }
    });
}

  public workspaceSubmit(): void {
    this.submitted = true;
    const payload = {
        userId: localStorage.getItem('userId'),
        workSpaceName: this.createWorkspace.workSpaceName,
    };
    this.toastr.clear();
    this.workspaceService.addWorkspace(payload).subscribe(
        (res): any => {
            if (res && res.status && res.status.status === 200) {
              this.tempWorkspace = res.entity.dbName;
                this.toastr.success('Workspace created');
                sessionStorage.setItem('workspacename', res.entity.dbName);
                localStorage.setItem('tenantId', res.entity.tenantId);
                this.projectToggle = true;
                this.workspaceToggle=false
                this.submitted = false;
            } else {
                this.submitted = false;
                this.toastr.error(res.status.msg);
            }
        },
        (err): any => {
            this.submitted = false;
            this.apis.handleError(err);
        },
    );
}

public skipWorkspace(): any {
  this.submitted = true;
  const payload = {
      userId: localStorage.getItem('userId'),
      workSpaceName: this.userName,
  };
  this.toastr.clear();
  this.workspaceService.addWorkspace(payload).subscribe(
      (res): any => {
          this.submitted = false;
          if (res && res.status && res.status.status === 200) {
            this.toastr.success('Workspace created');
              sessionStorage.setItem('workspacename', res.entity.dbName);
              this.tempWorkspace = res.entity.dbName;
              this.projectToggle = true;
              this.workspaceToggle=false
          } else {
              this.toastr.error(res.status.msg);
          }
      },
      (err): any => {
          this.submitted = false;
          this.api.handleError(err);
      },
  );
}

public closeSidemenus(): void {
  this.workspaceToggle = true;
}

public getUserTypeRole(): void {
    this.workspaceService.getUserRoleType1(sessionStorage.getItem('projectId')).subscribe(
        (res): any => {
            if (res && res.entity) {
                sessionStorage.setItem('usertype', res.entity.roleId);
                this.createProject.teamName = '';
                this.router.navigate(['/project/dashboard'], { queryParams: this.api.getSessionData() });
            }
        },
    );
}

public createProjectMethod(): void {
    this.submitted = true;
    const payload = {
        userId: localStorage.getItem('userId'),
        workSpaceName: this.tempWorkspace,
        teamName: this.createProject.teamName,
    };
    this.toastr.clear();
    this.workspaceService.addProject(payload).subscribe(
        (res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.toastr.success('Project created successfully');
                sessionStorage.setItem('projectId', res.entity.teamId);
                this.getUserTypeRole();
            } else {
                this.toastr.error(res.status.msg);
            }
        },
        (err): any => {
            this.submitted = false;
            this.apis.handleError(err);
        },
    );
  }
  public skipProjectMethod(): void {
    this.submitted = true;
    const payload = {
        userId: localStorage.getItem('userId'),
        workSpaceName: this.tempWorkspace,
        teamName: 'My First Project',
    };
    this.toastr.clear();
    this.workspaceService.addProject(payload).subscribe(
        (res): any => {
            this.submitted = false;
            if (res && res.status && res.status.status === 200) {
                this.createProject.teamName = '';
                this.toastr.success('Project created successfully');
                sessionStorage.setItem('projectId', res.entity.teamId);
                this.router.navigate(['/project/feed'], { queryParams: this.api.getSessionData() });
            } else {
                this.toastr.error(res.status.msg);
            }
        },
        (err): any => {
            this.submitted = false;
            this.apis.handleError(err);
        },
    );
  }

    //   shouldShowButton(): boolean {
    //     const userEmail = localStorage.getItem('email');

    //     return (
    //         (this.isGetCarneraUser() && !this.isClientUser() && this.isBathSaverUser(userEmail)) ||
    //         (this.isClientUser() && !this.isGetCarneraUser() && (this.isAllowedClientUser() || this.isKitchenSaverUser() || this.isBathSaverUser(userEmail)))
    //     );
    // }

    shouldShowButton(): boolean {
        const userEmail = localStorage.getItem('email');

        const isAllowedClientUser = this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);

        return (
            isAllowedClientUser ||   // If the user is in the allowed lists
            (userEmail && !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !userEmail.toLowerCase().endsWith('@bathsaver.com'))
            // If the user's domain is not kitchensaver.com or bathsaver.com
        );
    }

isClientUser(): boolean {
    const userEmail = localStorage.getItem('email');
    return this.workspaceService.isClientUser(userEmail) || this.isBathSaverUser(userEmail);
}

isGetCarneraUser(): boolean {
    const userEmail = localStorage.getItem('email');
    return !userEmail.toLowerCase().endsWith('@kitchensaver.com') && !this.isBathSaverUser(userEmail);
}

isKitchenSaverUser(): boolean {
    const userEmail = localStorage.getItem('email');
    return userEmail.toLowerCase().endsWith('@kitchensaver.com');
}

isBathSaverUser(userEmail: string): boolean {
    return userEmail.toLowerCase().endsWith('@bathsaver.com');
}

isAllowedClientUser(): boolean {
    const userEmail = localStorage.getItem('email');
    return this.workspaceService.allowedClientEmails.includes(userEmail) || this.workspaceService.allowdBathSaverEmails.includes(userEmail);
}


}
